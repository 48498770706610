import React from "react"
import { connect } from "react-redux"
import * as ProjectsActions from "../Actions/ProjectsActions"
import DunningConfiguration from "./DunningConfiguration"
import Loader from "./Loader"
import type { $DunningConfiguration, $DunningSettingsState } from "^/stores/Reducers/DunningReducer"
import type { $ProjectsState } from "^/stores/Reducers/ProjectsReducer"
const MAX_CONFIGURATIONS = 10
type Props = {
  dunning: $DunningSettingsState
  project: $ProjectsState
}
type State = {
  configurations: Array<$DunningConfiguration>
}
export class Dunning extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      configurations: [],
    }
  }

  componentDidMount() {
    if (this.props.project.fetched) this.fetchDunning()
  }

  fetchDunning() {
    this.props.dispatch(ProjectsActions.fetchDunning(this.props.project.project.id))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.dunning.fetched && !nextProps.dunning.fetching && nextProps.project.fetched) {
      this.fetchDunning()
    }

    if (nextProps.dunning.fetched)
      this.setState({
        configurations: nextProps.dunning.dunning_configurations,
      })
  }

  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  saveDunning(event) {
    event.preventDefault()
    const config = this.state.configurations
    const { project } = this.props.project
    project.dunning_configuration = config
    project.api_version_name = project.api_version.name
    this.props.dispatch(
      ProjectsActions.updateDunning(this.props.project.project.id, JSON.stringify(project)),
    )
  }

  addConfiguration(id) {
    const configs = this.state.configurations
    if (configs.length >= MAX_CONFIGURATIONS) return
    configs.splice(id, 0, {
      action: "retry",
      delay_in_days: 1,
    })
    this.setState({
      configurations: configs,
    })
  }

  updateConfiguration(id, event) {
    const configs = this.state.configurations
    configs[id].delay_in_days = parseInt(event.target.value)
    this.setState({
      configurations: configs,
    })
  }

  removeConfiguration(id) {
    const configs = this.state.configurations
    configs.splice(id, 1)
    this.setState({
      configurations: configs,
    })
  }

  render() {
    let count = -1

    if (this.props.dunning.fetching || !this.props.dunning.fetched) {
      return (
        <div className="row margin-bottom">
          <div className="large-3 columns">
            <h5>Subscriptions</h5>
          </div>
          <div className="large-9 columns">
            <Loader />
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="medium-12 columns">
          <form onSubmit={this.saveDunning.bind(this)}>
            <div className="row margin-bottom">
              <div className="large-3 columns">
                <h5>Subscriptions</h5>
              </div>
              <div className="large-7 end columns">
                When a subscription payment fails, you might want to dynamically attempt a new
                capture at a later date according to the settings you set below.
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-offset-4 medium-offset-4 medium-8 columns">
                <div className="row margin-bottom">
                  <div className="large-12 columns">
                    <div id="dunning-actions">
                      {this.state.configurations.map(configuration => {
                        count++
                        return (
                          <DunningConfiguration
                            id={count}
                            removeConfiguration={this.removeConfiguration.bind(this)}
                            updateConfiguration={this.updateConfiguration.bind(this)}
                            addConfiguration={this.addConfiguration.bind(this)}
                            key={count}
                            ref={`config-${count}`}
                            isFirstAction={count === 0}
                            isLastAction={count === this.state.configurations.length - 1}
                            configuration={configuration}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="large-9 large-offset-3 columns clearfix">
                <input type="submit" className="button border small round" value="Save" />
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  dunning: store.dunning,
  project: store.currentProject,
}))(Dunning)
