import React from "react"
import Select, { components } from "react-select"
import { connect } from "react-redux"
import moment from "moment"
import { TIMESPAN_OPTIONS, UPDATE_TELESCOPE_TIMESPAN } from "../consts"
import type { $Dispatcher } from "../../../util/Types"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import { REQUEST_SURFACES_FETCH } from "../Surface/consts"
type Props = {
  value: string
  onChange: (arg0: { value: string }) => any
  telescope: $TelescopeState
} & $Dispatcher

class TimespanSelector extends React.Component<Props> {
  onChange = (newValue: { value: string }) => {
    this.props.dispatch({
      type: UPDATE_TELESCOPE_TIMESPAN,
      payload: {
        timespan: newValue.value,
      },
    })
    this.props.dispatch({
      type: REQUEST_SURFACES_FETCH,
      payload: {
        timespan: newValue.value,
      },
    })
  }

  render() {
    const { telescope } = this.props
    const selectedTimespan = TIMESPAN_OPTIONS.filter(
      option => option.value === telescope.standard.timespan,
    )
    return (
      <div className="row">
        <div className="medium-6 medium-offset-6 columns text-left">
          <div className="row">
            <div
              className="medium-5 columns medium-offset-1"
              style={{
                paddingTop: ".5em",
              }}
            >
              <span>Viewing data from</span>
            </div>
            {telescope.standard.selectedTab === "char" ? (
              <div
                className="medium-6 columns text-right"
                style={{
                  position: "relative",
                  right: "1em",
                  zIndex: 15,
                }}
              >
                <div className="initial-date-indicator greyed">
                  {moment()
                    .set("date", 1)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .calendar()}
                </div>
              </div>
            ) : (
              <div
                className="medium-6 columns"
                style={{
                  position: "relative",
                  right: "1em",
                  zIndex: 15,
                }}
              >
                <Select
                  className="react-select"
                  options={TIMESPAN_OPTIONS}
                  value={selectedTimespan}
                  isSearchable={false}
                  components={{
                    ValueContainer,
                  }}
                  isClearable={false}
                  onChange={this.onChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <span className="greyed">the past </span> {children}
    </div>
  </components.ValueContainer>
)

export default connect(store => ({
  telescope: store.telescope,
}))(TimespanSelector)
