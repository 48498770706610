import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import { Link, withRouter } from "react-router"
import GeoChart from "../../../components/Analytics/GeoChart"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import { computeFeesColor } from "../Recommendation/utils"
import * as ISOCountries from "../../../util/ISOCountries"
import BarChart from "../../analytics/Charts/BarChart"
import Empty from "../../../components/Empty"
import Warning from "../Components/Warning"
import Error from "../../../components/Error"
import type { $ProviderCompareOption } from "../Components/ProviderCompareSelector"
import ProviderCompareSelector from "../Components/ProviderCompareSelector"
import getColor from "../../analytics/Boards/charts/colors"
import FeesRecommendations from "../Components/FeesRecommendations"
type Props = {
  telescope: $TelescopeState
  params: {
    project: string
  }
}
type State = {
  pspCompare: $ProviderCompareOption
}

class CapturesContent extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      pspCompare: {
        value: "general",
        label: "general",
      },
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextProps.telescope.standard.capturesReady !== this.props.telescope.standard.capturesReady ||
      this.state !== nextState
    )
  }

  render() {
    const { telescope } = this.props
    return (
      <div className="row text-left">
        <div className="large-12 columns">
          <div className="row margin-bottom">
            <div className="large-5 columns">
              <div className="row small-margin-bottom">
                <div className="large-12 columns">
                  <div className="chart-title">
                    Fees percent per country
                    {!telescope.standard.data.gateway_fees &&
                    telescope.standard.data.transactions_captured ? (
                      <Warning text="Fee data is missing" />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <div
                    style={{
                      height: "250px",
                    }}
                  >
                    <GeoChart
                      key={uniqid()}
                      data={telescope.standard.data.gateway_fees_percent_per_country}
                      color={computeFeesColor.bind(
                        null,
                        telescope.standard.data.captured_amount,
                        telescope.standard.data.transactions_captured,
                        telescope.standard.data.fees_recommendations,
                      )}
                      tip={function (d) {
                        const c = ISOCountries.isoCountries[d.key]
                        const feesDisplay =
                          d.country.fees !== null
                            ? `${(d.country.fees * 100).toLocaleString(undefined, {
                                maximumFractionDigit: 2,
                              })}%`
                            : "--"
                        return (
                          `${c.full_name}<br />` +
                          `<span class="greyed">Fees percent:</span> ${feesDisplay}<br />`
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="large-6 large-offset-1 columns">
              <div className="row">
                <div className="large-12 columns">
                  <div className="row">
                    <div className="large-7 columns">
                      <div className="chart-title">
                        Fees percent
                        {(() => {
                          if (!telescope.standard.capturesReady) {
                            return null
                          }

                          const providersZeroFees =
                            telescope.standard.data.gateway_fees_per_psp_bar[0].datapoints.filter(
                              bar => bar.value === 0,
                            )
                          const providersMissingFees = []

                          for (const provider of providersZeroFees) {
                            const authRate =
                              telescope.standard.data.authorization_rate_per_psp_bar[0].datapoints.find(
                                bar => bar.key === provider.key,
                              )

                            if (authRate && authRate.value > 0) {
                              providersMissingFees.push(provider)
                            }
                          }

                          if (providersMissingFees.length > 0) {
                            return (
                              <Warning
                                text={`Fee data is missing for ${providersMissingFees.reduce(
                                  (value, provider, index, array) =>
                                    `${value}${provider.key}${
                                      index < array.length - 1 ? ", " : ""
                                    }`,
                                  "",
                                )}`}
                              />
                            )
                          }
                        })()}
                      </div>
                    </div>
                    <div className="large-5 columns">
                      <ProviderCompareSelector
                        value={this.state.pspCompare}
                        onChange={(newValue: $ProviderCompareOption) => {
                          this.setState({
                            pspCompare: newValue,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">
                      <div
                        style={{
                          height: "250px",
                        }}
                      >
                        {(() => {
                          if (!this.props.telescope.standard.capturesReady) return null
                          const data =
                            this.state.pspCompare.value === "general"
                              ? telescope.standard.data.gateway_fees_per_psp_bar
                              : this.state.pspCompare.value === "card_scheme"
                              ? telescope.standard.data.gateway_fees_per_psp_per_card_scheme_bar
                              : telescope.standard.data.gateway_fees_per_psp_per_card_type_bar
                          if (!data)
                            return (
                              <div
                                style={{
                                  marginTop: "2em",
                                }}
                              >
                                <Error />
                              </div>
                            )
                          if (data.length === 0 || data[0].datapoints.length === 0)
                            return <Empty text="No data to display" />
                          return (
                            <div key={uniqid()}>
                              <BarChart
                                type="percentage"
                                data={data}
                                colors={data.map((entry, index) => ({
                                  key: entry.key,
                                  color: getColor(index),
                                }))}
                                comparison
                              />
                            </div>
                          )
                        })()}
                      </div>
                    </div>
                  </div>
                  <FeesRecommendations />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns text-center">
              <Link
                class="round main button small"
                to={`/projects/${this.props.params.project}/boards`}
              >
                <div>Understand your fees</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
}))(withRouter(CapturesContent))
