import type { $Action } from "../../util/Types"
import { FETCH_TRSN_PROBA } from "./consts"
import * as ProcessOut from "../../util/ProcessOut"
export function fetchProbabilities(transaction: string): $Action {
  return {
    type: FETCH_TRSN_PROBA,
    payload: ProcessOut.APIcallPromise(`/transactions/${transaction}/probabilities`, "GET"),
  }
}
export function fetchRecommendations(transaction: string): $Action {
  return {
    type: FETCH_TRSN_PROBA,
    payload: ProcessOut.APIcallPromise(`/transactions/${transaction}/recommendations`, "GET"),
  }
}
