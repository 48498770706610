import type { $Surfaces } from "./consts"
import { REQUEST_SURFACES_FETCH } from "./consts"
import type { $Action, $State } from "../../../util/Types"
import { RESET_TELESCOPE_STATE, SELECT_SURFACES_TYPE } from "../actions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $SurfacesState = $Surfaces & $State
const defaultState: $SurfacesState = {
  surface_explained: 0,
  total_failed: 0,
  surfaces: [],
  fetched: false,
  fetching: false,
  error: null,
  generating: false,
  validated: true,
  type: "authorization-rate",
}
export default function (state: $SurfacesState = defaultState, action: $Action): $SurfacesState {
  switch (action.type) {
    case RESET_TELESCOPE_STATE: {
      return { ...state, ...defaultState }
    }

    case typePending(REQUEST_SURFACES_FETCH): {
      return { ...state, ...defaultState, type: state.type }
    }

    case typeFulfilled(REQUEST_SURFACES_FETCH): {
      return { ...state, fetched: true, fetching: false, ...action.payload }
    }

    case typeFailed(REQUEST_SURFACES_FETCH): {
      return { ...state, error: action.payload, fetched: true, fetching: false }
    }

    case SELECT_SURFACES_TYPE: {
      return { ...state, type: action.payload.type }
    }
  }

  return state
}
