import * as ProcessOut from "../util/ProcessOut"
import * as CouponsActions from "./CouponsActions"
import { segmentAnalytics } from "^/vNext/Segment"
export const FETCH_INVOICES = "FETCH_INVOICES"
export function loadInvoices(length?, after?, id?, filter?) {
  filter = filter || ""
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_INVOICES,
    payload: ProcessOut.APIcallPromise(
      `/products?order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
      "GET",
      null,
    ),
  }
}
export const FETCH_RECURRING_INVOICES = "FETCH_RECURRING_INVOICES"
export const FETCH_RECURRING_INVOICES_NORELOAD = "FETCH_RECURRING_INVOICES_NORELOAD"
export function loadRecurringInvoices(id?, filter?, length?, after?, itemId?) {
  if (!filter) {
    filter = ""
  }

  const bound = after ? "start_after" : "end_before"

  if (!itemId) {
    itemId = ""
  }

  return {
    type: filter !== "" ? FETCH_RECURRING_INVOICES_NORELOAD : FETCH_RECURRING_INVOICES,
    payload: ProcessOut.APIcallPromise(
      `/customers/${id}/subscriptions?expand=customer&${bound}=${itemId}&order=desc&limit=${length}&filter=${filter}`,
      "GET",
      null,
    ),
  }
}
export const FETCH_INVOICE_DETAILS = "FETCH_INVOICE_DETAILS"
export function loadInvoice(id) {
  return {
    type: FETCH_INVOICE_DETAILS,
    payload: ProcessOut.APIcallPromise(`/products/${id}`, "GET", null),
  }
}
export const CREATE_INVOICE = "CREATE_INVOICE"
export function createInvoice(invoice, resolve) {
  return function (dispatch) {
    const invoiceData = JSON.parse(invoice)
    dispatch({
      type: CREATE_INVOICE,
      payload: createInvoicePromise(invoice, resolve, dispatch).catch(error => {
        resolve(error.details)
      }),
    })
    segmentAnalytics?.track(CREATE_INVOICE, {
      name: invoiceData.name,
      amount: invoiceData.amount,
      currency: invoiceData.currency,
    })
  }
}
export const CREATE_SINGLE_INVOICE = "CREATE_SINGLE_INVOICE"
export function createSingleInvoice(invoice, resolve, error) {
  return {
    type: CREATE_SINGLE_INVOICE,
    payload: ProcessOut.APIcallPromise("/invoices", "POST", invoice).then(response => {
      if (response.data.success) resolve(response.data.invoice)
      else error()
      return response
    }),
  }
}
export const CHARGE_SINGLE_INVOICE = "CHARGE_SINGLE_INVOICE"
export function chargeSingleInvoice(invoice, source, resolve, error) {
  return {
    type: CHARGE_SINGLE_INVOICE,
    payload: ProcessOut.APIcallPromise(
      `/invoices/${invoice}/capture`,
      "POST",
      JSON.stringify({
        source,
      }),
    ).then(response => {
      if (response.data.success) resolve()
      else error()
      return response
    }),
  }
}

function createInvoicePromise(invoice, resolve, dispatch) {
  return ProcessOut.APIcallPromise("/products", "POST", invoice).then(response => {
    if (response.data.success) {
      dispatch(loadInvoices())
    }

    resolve(response.data)
    return response
  })
}

export const CREATE_RECURRING_INVOICE = "CREATE_RECURRING_INVOICE"
export function createRecurringInvoice(invoice, couponId, resolve) {
  const invoiceData = JSON.parse(invoice)
  return function (dispatch) {
    dispatch({
      type: CREATE_RECURRING_INVOICE,
      payload: ProcessOut.APIcallPromise("/subscriptions", "POST", invoice).then(response => {
        if (response.data.success) {
          dispatch(loadRecurringInvoices(invoiceData.customer_id))

          if (couponId) {
            dispatch(CouponsActions.applyCoupon(response.data.subscription.id, couponId))
          }

          resolve(response.data)
        }

        return response
      }),
    })
  }
}
export const DELETE_RECURRING_INVOICE = "DELETE_RECURRING_INVOICE"
export function deleteRecurringInvoice(customer, invoice) {
  return function (dispatch) {
    dispatch({
      type: DELETE_RECURRING_INVOICE,
      payload: ProcessOut.APIcallPromise(`/subscriptions/${invoice}`, "DELETE", null).then(
        response => {
          if (response.data.success) {
            Promise.all([dispatch(loadRecurringInvoices(customer))])
          }

          return response
        },
      ),
    })
  }
}
export const EDIT_INVOICE = "EDIT_INVOICE"
export function editInvoice(invoice, id, resolve) {
  return function (dispatch) {
    dispatch({
      type: EDIT_INVOICE,
      payload: ProcessOut.APIcallPromise(`/products/${id}`, "PUT", invoice).then(response => {
        if (response.data.success) {
          Promise.all([dispatch(loadInvoices())])
        }

        resolve(response.data)
        return response
      }),
    })
  }
}
export const DELETE_INVOICE = "DELETE_INVOICE"
export function deleteInvoice(id) {
  return function (dispatch) {
    dispatch({
      type: DELETE_INVOICE,
      payload: ProcessOut.APIcallPromise(`/products/${id}`, "DELETE", null).then(response => {
        if (response.data.success) {
          Promise.all([dispatch(loadInvoices())])
        }

        return response
      }),
    })
  }
}
export const START_INVOICE_CREATION = "START_INVOICE_CREATION"
