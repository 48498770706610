import moment from "moment"
import React from "react"
import { FormattedNumber } from "react-intl"
import getColor from "../../Boards/charts/colors"
import type { $ChartPoint } from "../Consts"

const PercentTooltip = (props: {
  datapoints: Array<$ChartPoint>
  colors: Array<{
    color: string
    key: string
  }>
}) => {
  const { datapoints, colors } = props
  const totalCount = datapoints.reduce((count, point) => count + point._count, 0)
  return (
    <div
      style={{
        fontSize: ".8em",
        maxWidth: "400px",
      }}
    >
      {datapoints
        .filter(entry => entry._count > 0)
        .map(entry => {
          const color = colors.find(color => color.key === entry.key)
          const key = entry.key instanceof Date ? moment(entry.key).format() : entry.key
          return (
            <div
              data-testid="percent-tooltip"
              className="small-margin-bottom"
              key={key}
              style={{
                float: "left",
                width: "200px",
              }}
            >
              <div
                data-testid="key-transactions"
                style={{
                  fontSize: "1.1em",
                  marginBottom: 0,
                  color: color ? color.color : getColor(0),
                }}
              >
                {key}{" "}
                <span
                  style={{
                    display: "inline-block",
                  }}
                  className="greyed"
                >
                  {" "}
                  — <FormattedNumber value={entry._count / totalCount} style="percent" />
                  {" of transactions"}
                </span>
              </div>
              <FormattedNumber
                value={entry.value}
                style="percent"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />{" "}
              <div
                data-testid="formatted-count"
                className="greyed"
                style={{
                  display: "inline-block",
                }}
              >
                — <FormattedNumber value={entry._count} /> transactions
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default PercentTooltip
