import * as ProcessOut from "../util/ProcessOut"
import { segmentAnalytics } from "^/vNext/Segment"
import { typeFulfilled } from "^/util/ActionUtils"

// Replace all `-` by spaces and capitalize the first letter
function formatTagName(tag) {
  tag = tag.replace(/-/g, " ")
  return tag.charAt(0).toUpperCase() + tag.slice(1)
}

export const FETCH_GATEWAYS = "FETCH_GATEWAYS"
export function loadGateways() {
  return {
    type: FETCH_GATEWAYS,
    payload: ProcessOut.APIcallPromise("/gateways", "GET", null).then(response => {
      if (!response.data.success) return response
      // Create an object of tags containing arrays of gateways
      const { gateways } = response.data
      const tags = []

      for (let i = 0; i < gateways.length; i++) {
        for (let j = 0; j < gateways[i].tags.length; j++) {
          if (!tags[gateways[i].tags[j]]) tags[gateways[i].tags[j]] = [gateways[i]]
          else tags[gateways[i].tags[j]].push(gateways[i])
        }
      }

      // Formats the array so that each child is { tagName: [gateways]}
      const keys = Object.keys(tags)
      const tagsArray = keys.map(v => ({
        name: formatTagName(v),
        value: tags[v],
      }))
      // Add the array to the response body
      response.data.tags = tagsArray
      return response
    }),
  }
}
export const FETCH_GATEWAYS_CONFIGURATIONS = "FETCH_GATEWAYS_CONFIGURATIONS"
export function loadGatewaysConfigurations() {
  return {
    type: FETCH_GATEWAYS_CONFIGURATIONS,
    payload: ProcessOut.APIcallPromise(
      `/gateway-configurations?expand=gateway&with_disabled=true`,
      "GET",
      null,
      null,
      true,
    ),
  }
}
export const DISABLING_GATEWAY_CONFIG = "DISABLING_GATEWAY_CONFIG"
export function disableGateway(gateway, id, resolve) {
  return function (dispatch) {
    const configBody = {
      enabled: false,
    }
    const body = JSON.stringify(configBody, null, 2)
    return {
      type: DISABLING_GATEWAY_CONFIG,
      payload: ProcessOut.APIcallPromise(
        `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
        "PUT",
        body,
      ).then(response => {
        dispatch(loadGatewaysConfigurations())
        resolve(response.data.success)
      }),
    }
  }
}
export const ENABLING_GATEWAY_CONFIG = "ENABLING_GATEWAY_CONFIG"
export function enableGateway(gateway, id, resolve) {
  return function (dispatch) {
    const configBody = {
      enabled: true,
    }
    const body = JSON.stringify(configBody, null, 2)
    return {
      type: ENABLING_GATEWAY_CONFIG,
      payload: ProcessOut.APIcallPromise(
        `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
        "PUT",
        body,
      ).then(response => {
        dispatch(loadGatewaysConfigurations())
        resolve(response.data.success)
      }),
    }
  }
}
export const UPDATE_GATEWAY_CONFIGURATION = "UPDATE_GATEWAY_CONFIGURATION"
export function updateGatewayConfig(gateway, id, config, settings, resolve) {
  const configBody = { ...settings, enabled: true, settings: config }
  const body = JSON.stringify(configBody, null)
  return {
    type: UPDATE_GATEWAY_CONFIGURATION,
    payload: ProcessOut.APIcallPromise(
      `/gateways/${gateway}/gateway-configurations/${id}?expand=gateway`,
      "PUT",
      body,
    ).then(response => {
      resolve(response.data.success)
    }),
  }
}
export const CREATE_GATEWAY_CONFIGURATION = "CREATE_GATEWAY_CONFIGURATION"
export function createGatewayConfig(gateway, config, settings, resolve) {
  return function (dispatch) {
    const body = { ...settings, enabled: true, name: config.name, settings: config }
    const configBody = JSON.stringify(body, null)
    dispatch({
      type: CREATE_GATEWAY_CONFIGURATION,
      payload: ProcessOut.APIcallPromise(
        `/gateways/${gateway}/gateway-configurations?expand=gateway`,
        "POST",
        configBody,
      ).then(response => {
        if (response.data.success) {
          dispatch(enableGateway(gateway, response.data.gateway_configuration.id, resolve))
          segmentAnalytics?.track(typeFulfilled(CREATE_GATEWAY_CONFIGURATION), {
            processor: gateway,
          })
        }
      }),
    })
  }
}
export const DELETE_GATEWAY_CONFIGURATION = "DELETE_GATEWAY_CONFIGURATION"
export function deleteGatewayConfig(gateway, id, optResolve) {
  return {
    type: DELETE_GATEWAY_CONFIGURATION,
    payload: ProcessOut.APIcallPromise(
      `/gateways/${gateway}/gateway-configurations/${id}`,
      "DELETE",
    ).then(response => {
      if (response.data.success && optResolve) {
        optResolve()
      }
    }),
  }
}
export const ENABLE_GATEWAY = "ENABLE_GATEWAY"
export const DISABLE_GATEWAY = "DISABLE_GATEWAY"
