import React from "react"
import { FormattedDate, FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import MetadataDisplay from "../MetadataDisplay"
import "./Operation.scss"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import { track } from "^/vNext/tracking"
import { getDaysSinceToday } from "^/util/DateUtils"
export class Operation extends React.Component {
  constructor() {
    super()
  }

  openDetails(event) {
    event.preventDefault()
    const { operation } = this.props
    const header = "Operation details"
    let gwayId
    let gwayConfName
    let gwayMessage

    if (operation.gateway_operation_id !== "") {
      gwayId = (
        <div className="row small-margin-bottom">
          <div className="large-3 columns greyed">Processor ID</div>
          <div
            className="large-9 columns text-right small-font font-console"
            style={{
              wordWrap: "break-word",
            }}
          >
            {operation.gateway_operation_id}
          </div>
        </div>
      )
    }

    if (operation.gateway_configuration) {
      gwayConfName = (
        <div className="row small-margin-bottom">
          <div className="large-3 columns greyed">Gateway configuration</div>
          <div
            className="large-9 columns text-right"
            style={{
              wordWrap: "break-word",
            }}
          >
            {operation.gateway_configuration.name}
          </div>
        </div>
      )
    }

    if (
      operation.gateway_variable !== "" &&
      operation.gateway_variable !== operation.gateway_operation_id
    ) {
      gwayMessage = (
        <div className="row small-margin-bottom">
          <div className="large-3 columns greyed">Data</div>
          <div
            className="large-9 columns text-right small-font font-console"
            style={{
              wordWrap: "break-word",
            }}
          >
            {operation.gateway_variable || <span className="greyed">——</span>}
          </div>
        </div>
      )
    }

    const content = (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6
                className="capitalize greyed font-console"
                style={{
                  marginBottom: "0px",
                }}
              >
                Details
              </h6>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Identifier</div>
            <div
              className="large-9 columns small-font font-console text-right"
              style={{
                wordWrap: "break-word",
              }}
            >
              {operation.id}
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Name</div>
            <div className="large-9 columns text-right">{operation.type}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Created on</div>
            <div className="large-9 columns text-right">
              <FormattedDate
                value={operation.created_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Status</div>
            <div className="large-3 columns text-right">
              <div
                className={`log-level ${
                  operation.is_attempt
                    ? "warning blinking"
                    : operation.has_failed
                    ? "failed"
                    : "success"
                }`}
                style={{
                  marginTop: "5px",
                  marginRight: "0px",
                  marginLeft: "0px",
                }}
              >
                {operation.is_attempt ? "pending" : operation.has_failed ? "failed" : "success"}
              </div>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Error code</div>
            <div className="large-9 columns text-right font-console">
              {operation.error_code || <span className="greyed">—</span>}
            </div>
          </div>
          {operation.error_message && (
            <div className="row">
              <div className="large-12 columns">
                <div className="row small-margin-bottom">
                  <div className="large-12 greyed columns">Error message</div>
                </div>
                <div className="row small-margin-bottom">
                  <div className="large-12 columns text-right small-font greyed">
                    <input type="text" disabled="true" defaultValue={operation.error_message} />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6 className="capitalize greyed font-console">Processor</h6>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Name</div>
            <div
              className="large-9 columns text-right"
              style={{
                wordWrap: "break-word",
              }}
            >
              {operation.gateway_configuration ? (
                <img
                  alt={`${operation.gateway_configuration.gateway.name}'s logo`}
                  className="operation-gateway-logo"
                  src={operation.gateway_configuration.gateway.logo_url}
                />
              ) : (
                <span className="greyed">—</span>
              )}
            </div>
          </div>
          {gwayConfName}
          {gwayId}
          {gwayMessage}
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Acquirer</div>
            <div className="large-9 columns text-right">
              {operation.acquirer_name || <span className="greyed">—</span>}
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Amount</div>
            <div className="large-9 columns text-right">
              <FormattedNumber
                value={operation.amount}
                style="currency"
                currency={this.props.transaction.currency}
              />
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6 className="capitalize greyed font-console">Metadata</h6>
            </div>
          </div>
          <MetadataDisplay metadata={operation.metadata} displayTaxes displayShipping />
        </div>
      </div>
    )
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header,
        content,
        footer: (
          <div className="row">
            <div className="large-12 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Close
              </a>
            </div>
          </div>
        ),
      },
    })
    track("Transaction Details", "Click", "Operations History", "Item", {
      operationType: operation.type,
      operationGateway: operation.gateway_configuration
        ? operation.gateway_configuration.gateway?.display_name ||
          operation.gateway_configuration.name
        : "none",
      operationStatus: operation.is_attempt ? "pending" : operation.has_failed ? "failed" : "ok",
      daysSinceOperationCreated: getDaysSinceToday(operation.created_at),
    })
  }

  render() {
    const { transaction } = this.props
    const { operation } = this.props
    let logLevel = "success"
    if (operation.has_failed) logLevel = "failed"
    if (operation.is_attempt) logLevel = "warning blinking"

    if (operation.type == "request") {
      if (this.props.request) logLevel = "success"
      else logLevel = "warning"
    }

    return (
      <a onClick={this.openDetails.bind(this)}>
        <div
          className="row small-margin-bottom box-row"
          style={{
            marginBottom: "0px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <div className="large-2 columns operation-type">{operation.type}</div>
          <div className="large-1 columns">
            {operation.gateway_configuration ? (
              <img
                alt={`${operation.gateway_configuration.gateway.name}'s logo`}
                className="operation-gateway-logo"
                src={operation.gateway_configuration.gateway.logo_url}
              />
            ) : (
              <span className="greyed">—</span>
            )}
          </div>
          <div className="large-2 columns">
            <span className="right">
              <FormattedNumber
                class="operation-amount"
                value={
                  operation.currency ? operation.amount : operation.amount_local // If the operation has a currency we use the amount, otherwise defaulting to transaction's currency and amount_local
                }
                style="currency"
                currency={operation.currency || transaction.currency}
              />
            </span>
          </div>
          <div className="large-3 columns">
            <span className="right">
              <FormattedDate
                value={operation.created_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            </span>
          </div>
          <div className="large-1 columns">
            <div
              className={`right log-level ${logLevel}`}
              style={{
                width: "fit-content",
                marginTop: "0px",
                marginRight: "0",
              }}
            >
              <span
                style={{
                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                {operation.is_attempt ? "pending" : operation.has_failed ? "failed" : "ok"}
              </span>
            </div>
          </div>
          <div className="large-2 columns">{operation.error_code}</div>
          <div className="large-1 columns">
            <div className="right">↗</div>
          </div>
        </div>
      </a>
    )
  }
}
export default connect()(Operation)
