import type { $Action } from "../../util/Types"
import { REQUEST_DECLINE_EXPLANATION_FETCH } from "./sagas"
import { RESET_DECLINE_EXPLAINER } from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $DeclineExplainerState = {
  description: string
  tag: "" | "ml" | "knowledge" | "unknown"
} & {
  fetching: boolean
  fetched: boolean
  error?: boolean
  notPermitted?: true
}
const defaultState: $DeclineExplainerState = {
  fetching: false,
  fetched: false,
  error: false,
  description: "",
  tag: "",
}
export default function (
  state: $DeclineExplainerState = defaultState,
  action: $Action,
): $DeclineExplainerState {
  switch (action.type) {
    case REQUEST_DECLINE_EXPLANATION_FETCH: {
      return { ...state, fetching: true, fetched: false, error: false }
    }

    case typeFulfilled(REQUEST_DECLINE_EXPLANATION_FETCH): {
      return { ...state, fetching: false, fetched: true, ...action.payload }
    }

    case typeFailed(REQUEST_DECLINE_EXPLANATION_FETCH): {
      return { ...state, fetched: true, fetching: false, error: true }
    }

    case RESET_DECLINE_EXPLAINER: {
      return { ...state, ...defaultState }
    }
  }

  return state
}
