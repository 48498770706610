import React from "react"
import { $RoutingRule } from "../../features/RoutingRules/consts"
import editIconPath from "../RoutingRules/routes/routelane-edit.svg"
import DropDown from "../DropDown/DropDown"
import { SwitchToggle } from "../SwitchToggle/SwitchToggle"

type Props = {
  onClone: () => void
  onRemove: () => void
  onUpdateRule: (rule: $RoutingRule) => void
  routingRule: $RoutingRule
}

const RoutingRulesDropDown: React.FC<Props> = ({
  onClone,
  onRemove,
  routingRule,
  onUpdateRule,
}) => {
  const onCloneCallback = React.useCallback(() => {
    if (onClone) {
      onClone()
    }
  }, [onClone])

  const triggerCardVerification = () => {
    const newRule = structuredClone(routingRule)
    newRule.run_for_card_verifications = !newRule.run_for_card_verifications
    onUpdateRule(newRule)
  }

  let dropdownOptions = []
  if (routingRule.declaration === "trigger_3ds") {
    dropdownOptions.push({
      label: "Card verification",
      render: () => (
        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
          <span style={{ fontSize: "12px" }}>Card verification</span>
          <SwitchToggle
            onChange={triggerCardVerification}
            checked={routingRule?.run_for_card_verifications}
            labelText="Trigger on card verification"
            style={{ fontSize: 14 }}
          />
        </div>
      ),
      isSeparate: true,
    })
  }
  dropdownOptions = [
    ...dropdownOptions,
    {
      label: "Clone rule",
      render: () => <button onClick={onCloneCallback}>Duplicate rule</button>,
    },
    {
      label: "Remove rule",
      render: () => (
        <button onClick={onRemove} style={{ color: "#A32B33" }}>
          Remove rule
        </button>
      ),
    },
  ]

  return (
    <DropDown
      options={dropdownOptions}
      customIcon={<img alt="dropdown icon" src={editIconPath} />}
    />
  )
}

export default RoutingRulesDropDown
