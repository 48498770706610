import React from "react"
import { connect } from "react-redux"
import { segmentAnalytics } from "^/vNext/Segment"

class MonitoringPlusPopup extends React.Component<{
  close: () => void
  user: {
    details: {
      email: string
    }
  }
}> {
  componentDidMount() {
    segmentAnalytics?.track("OPENED_ADVANCED_MONITORING_POPUP")
  }

  render() {
    return (
      <div className="monitoring-plus-popup">
        <div className="monitoring-plus-popup-background" onClick={this.props.close} />
        <div className="monitoring-plus-popup-content">
          <div className="row">
            <div className="large-5 columns text-right">
              <div className="row margin-bottom">
                <div className="large-12 columns">
                  <img
                    alt="Monitoring screenshot"
                    src="/images/monitoring-plus-1.jpeg"
                    className="screenshot"
                  />
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <img
                    alt="Monitoring screenshot"
                    src="/images/monitoring-plus-2.jpeg"
                    className="screenshot"
                  />
                </div>
              </div>
            </div>
            <div className="large-6 large-offset-1 columns">
              <div className="row margin-bottom">
                <div className="large-12 columns">
                  <p className="title">Get started with advanced monitoring</p>
                </div>
              </div>
              <Bullet
                logoUrl="//dashboard.processout.com/images/loop.png"
                content="Dive into your payments data"
              />
              <Bullet
                logoUrl="//dashboard.processout.com/images/custom-chart.png"
                content="Create customizable boards & charts"
              />
              <Bullet
                logoUrl="//dashboard.processout.com/images/compare-chart.png"
                content="Easily cross check your data"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  user: store.user,
}))(MonitoringPlusPopup)

class Bullet extends React.PureComponent<{
  logoUrl: string
  content: string
}> {
  render() {
    return (
      <div className="row margin-bottom">
        <div className="large-12 columns">
          <img
            alt={`${this.props.logoUrl} icon`}
            src={this.props.logoUrl}
            className="bullet-logo"
          />
          <div className="bullet-content">{this.props.content}</div>
        </div>
      </div>
    )
  }
}
