import * as React from "react"
import { useCallback, useRef } from "react"
import ContentLayout from "^/pages/ContentLayout"
import TabPanel from "./TabPanel"
import type { $Tabs } from "./Tabs"
import Tabs from "./Tabs"
import "./TabsPicker.scss"
import { trackTarget } from "^/vNext/tracking"

type Props = {
  tabs: $Tabs[]
  initalTabIndex: number
  onTabsChange: (value: number) => void
}
export function TabsPicker({ tabs, onTabsChange, initalTabIndex }: Props) {
  const ref = useRef()
  const onChange = useCallback(
    (index: number) => {
      onTabsChange(index)
      trackTarget(ref.current, "Click", "Tab", { value: tabs[index].label })
    },
    [onTabsChange, ref, tabs],
  )

  return (
    <div ref={ref}>
      <ContentLayout noBackground noPadding>
        <div className="tabs-picker">
          <Tabs value={initalTabIndex} onChange={onChange} aria-label="basic tabs" tabs={tabs} />
        </div>
      </ContentLayout>
      <ContentLayout>
        {tabs.map((tab, index) => (
          <TabPanel value={initalTabIndex} index={index} key={index}>
            {tab.content}
          </TabPanel>
        ))}
      </ContentLayout>
    </div>
  )
}
export default TabsPicker
