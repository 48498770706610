import { call } from "redux-saga/effects"
import {
  CAPTURE_AMOUNT_FORMULA,
  CAPTURED_TRANSACTIONS_FORMULA,
  FEES_PER_COUNTRY_FORMULA,
  FEES_PER_PSP_PER_CARD_TYPE_BAR_FORMULA,
  GATEWAY_FEES_FORMULA,
  GATEWAY_FEES_PER_PSP_BAR_FORMULA,
  GATEWAY_FEES_PER_PSP_PER_CARD_SCHEME_BAR_FORMULA,
  GLOBAL_FEES_FORMULA,
} from "../consts"
import { datadogRum } from "@datadog/browser-rum"
import { fetchTelescopeChartData, formatBarChart } from "^/features/Telescope/Sagas/common"
export function* fetchGlobalFees(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, GLOBAL_FEES_FORMULA, timespan)
    return {
      global_fees_percent: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchCaptureAmount(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, CAPTURE_AMOUNT_FORMULA, timespan)
    return {
      captured_amount: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchCapturedTransactions(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, CAPTURED_TRANSACTIONS_FORMULA, timespan)
    return {
      transactions_captured: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchGatewayFees(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, GATEWAY_FEES_FORMULA, timespan)
    return {
      gateway_fees: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchFeesPercentPerCountry(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, FEES_PER_COUNTRY_FORMULA, timespan)
    return {
      gateway_fees_percent_per_country: chartData.map(country => ({
        ...country,
        fees: country.value,
      })),
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchGatewayFeesPerPspBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    let chartData = yield call(fetchTelescopeChartData, GATEWAY_FEES_PER_PSP_BAR_FORMULA, timespan)
    chartData = chartData.map(point => {
      const gatewayConfig = gatewayConfigurations.find(config => config.id === point.key)

      if (!gatewayConfig) {
        return null
      }

      return { ...point, key: gatewayConfig.name }
    })
    if (chartData.includes(null)) return null
    return {
      gateway_fees_per_psp_bar: formatBarChart(chartData),
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchFeesPerPspPerCardSchemeBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    let chartData = yield call(
      fetchTelescopeChartData,
      GATEWAY_FEES_PER_PSP_PER_CARD_SCHEME_BAR_FORMULA,
      timespan,
    )
    chartData = chartData.map(line => {
      line.datapoints = line.datapoints.filter(point => point.value > 0)
      const gatewayConfig = gatewayConfigurations.find(
        config => config.id === line.key.match(new RegExp(/gway_conf_.*/))[0],
      )
      if (!gatewayConfig) return null
      return { ...line, key: line.key.replace(/gway_conf_.*/, gatewayConfig.name) }
    })
    if (chartData.includes(null)) return null
    return {
      gateway_fees_per_psp_per_card_scheme_bar: chartData,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchFeesPerPspPerCardTypeBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    let chartData = yield call(
      fetchTelescopeChartData,
      FEES_PER_PSP_PER_CARD_TYPE_BAR_FORMULA,
      timespan,
    )
    chartData = chartData.map(line => {
      line.datapoints = line.datapoints.filter(point => point.value > 0)
      const gatewayConfig = gatewayConfigurations.find(
        config => config.id === line.key.match(new RegExp(/gway_conf_.*/))[0],
      )
      if (!gatewayConfig) return null
      return { ...line, key: line.key.replace(/gway_conf_.*/, gatewayConfig.name) }
    })
    if (chartData.includes(null)) return null
    return {
      gateway_fees_per_psp_per_card_type_bar: chartData,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
