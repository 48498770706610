import { select, takeEvery, takeLatest } from "redux-saga/effects"
import { SET_PROJECT_ID } from "^/Actions/ProjectsActions"
import { FETCH_USER } from "^/Actions/UserActions"
import { isInternalUser } from "^/util/Auth"
import { segmentAnalytics } from "^/vNext/Segment"
import { LOCATION_CHANGE } from "react-router-redux"
import { typeFulfilled } from "^/util/ActionUtils"

export function* identifyUser(): Generator<any, any, any> {
  const { userDetails, projectId } = yield select<any>(store => ({
    userDetails: store.user.details,
    projectId: store.projects.current_project_id,
  }))

  if (userDetails.uniq_id && userDetails.email && projectId) {
    segmentAnalytics?.identify(userDetails.uniq_id, {
      projectId: projectId,
      isInternalUser: isInternalUser(userDetails.email),
    })
  }
}

function trackLocationChange({ payload }) {
  segmentAnalytics?.page({
    path: payload.pathname,
  })
}

export default function* watchSegment(): Generator<any, any, any> {
  yield takeLatest(SET_PROJECT_ID, identifyUser)
  yield takeLatest(typeFulfilled(FETCH_USER), identifyUser)
  yield takeEvery(LOCATION_CHANGE, trackLocationChange)
}
