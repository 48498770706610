import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import ContentLayout from "./ContentLayout"
import * as VaultActions from "../Actions/VaultActions"
import Loader from "../components/Loader"
import Empty from "../components/Empty"
import Card from "../components/Card"
import Error from "../components/Error"
import Pagination from "../components/Pagination"
import type { $VaultState } from "../stores/Reducers/VaultReducer"
import type { $Dispatcher } from "../util/Types"
import NotPermitted from "../features/Permissions/NotPermitted"
import * as Actions from "../features/SearchBar/actions"
import { track } from "../vNext/tracking"
const cardsPerPage = 30
type Props = {
  vault: $VaultState
} & $Dispatcher
export class Vault extends React.Component {
  props: Props

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Vault | ProcessOut"
    // we check that there is an afterItem parameter
    // if not then we are going backward and there should be a before item
    const afterItem = this.props.location.query.after_item
    const beforeItem = this.props.location.query.before_item
    const { filter } = this.props.location.query
    this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem)
    // Set the document for filter button
    this.props.dispatch(Actions.setFilteringDocument("cards"))
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const oldFilter = this.props.location.query.filter
    const newFilter = nextProps.location.query.filter

    if (oldFilter !== newFilter) {
      this.applyFilter(newFilter, true)
      return
    }

    let firstItem = this.props.location.query.after_item
    if (!firstItem) firstItem = ""

    if (
      (!nextProps.vault.fetching && !nextProps.vault.fetched && !nextProps.vault.error) ||
      nextProps.vault.cardAnonymized
    ) {
      this.props.dispatch(
        VaultActions.loadCards(this.props.location.query.filter, cardsPerPage, true, firstItem),
      )
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentWillUnmount() {
    // Set document to null to hide filter button
    this.props.dispatch(Actions.setFilteringDocument(null))
  }

  applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
    this.props.dispatch(
      VaultActions.loadCards(
        filter,
        cardsPerPage,
        forward !== null ? forward : true,
        reset ? "" : firstItem,
      ),
    )
  }

  render() {
    const { cards } = this.props.vault
    let content
    let header

    if ((this.props.vault.fetching || !this.props.vault.fetched) && !this.props.vault.error) {
      content = <Loader />
    } else if (this.props.vault.error) {
      if (this.props.vault.error.notPermitted) content = <NotPermitted />
      else content = <Error />
    } else if (cards.length === 0) {
      var emptyText = "You haven't stored any card in your vault yet."
      if (this.props.location.query.filter !== "") var emptyText = "No cards match your search."
      content = <Empty text={emptyText} />
    } else {
      content = (
        <div>
          <ReactTooltip effect="solid" />
          {cards.map(card => (
            <Card key={card.id} card={card} />
          ))}
        </div>
      )
      header = (
        <div
          className="row capitalize greyed bold small-margin-bottom"
          style={{
            fontSize: "0.8em",
          }}
        >
          <div className="medium-2 columns">
            <div className="row">
              <div className="medium-8 medium-offset-4 columns">Last 4</div>
            </div>
          </div>
          <div className="medium-2 columns">Expiration</div>
          <div className="medium-3 columns">Name</div>
          <div className="medium-5 columns text-right">Date</div>
        </div>
      )
    }

    const title = (
      <div className="row">
        <div className="large-12 columns">
          <h4>Vault</h4>
        </div>
      </div>
    )
    return (
      <ContentLayout title={title}>
        {header}
        {content}
        <Pagination
          reloadFunction={VaultActions.loadCards.bind(
            null,
            this.props.location.query.filter,
            cardsPerPage,
          )}
          itemsArray={this.props.vault.fetched ? cards : []}
          hasMore={this.props.vault.fetched ? this.props.vault.has_more : false}
          hide={!this.props.vault.fetched || this.props.vault.fetching}
          location={this.props.location}
          onPrevious={() => track("Vault", "Click", "Pagination", "Previous")}
          onNext={() => track("Vault", "Click", "Pagination", "Next")}
        />
      </ContentLayout>
    )
  }
}
export default connect(store => ({
  vault: store.vault,
}))(Vault)
