import cloneDeep from "lodash/cloneDeep"
import type { $Action, $State } from "^/util/Types"
import {
  $RoutingRulesSettings,
  SELECT_TAB,
  ADD_RULE,
  CLONE_RULE,
  PREPARE_ROUTING_RULES_SETTINGS,
  REMOVE_RULE,
  RESET_ROUTING_RULES,
  RULES_SET_TOTAL_TRANSACTIONS,
  SAVE_ROUTING_RULES,
  UPDATE_ROUTING_RULES_FORMULA,
  UPDATE_RULE,
} from "./consts"
import { CHANGE_ROUTING_RULES_ORDER } from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type RoutingRulesState = $RoutingRulesSettings & $State
const defaultState: RoutingRulesState = {
  fetched: false,
  fetching: false,
  error: undefined,
  // FIXME: should be BUILDER?
  // @ts-ignore
  routingRulesMode: "builder",
  rules: [],
  rulesRaw: "",
  totalTransactions: 0,
  saving: false,
  // FIXME: wrong format, initialRules should be an array of $RoutingRule
  // @ts-ignore
  initialRules: "",
  initialRulesRaw: "",
  rulesHaveBeenEdited: false,
  // Persist any ui state here
  ui: {
    selectedTabIndex: 3,
  },
}
export default function (
  state: RoutingRulesState = defaultState,
  action: $Action,
): RoutingRulesState {
  switch (action.type) {
    case PREPARE_ROUTING_RULES_SETTINGS: {
      return {
        ...state,
        ...defaultState,
        fetching: true,
        fetched: false,
        ui: state.ui,
      }
    }

    case typeFulfilled(PREPARE_ROUTING_RULES_SETTINGS): {
      if (!action.payload) return state
      return {
        ...state,
        fetched: true,
        fetching: false,
        rules: action.payload.rules,
        rulesRaw: action.payload.rulesRaw,
        initialRules: cloneDeep(action.payload.rules),
        initialRulesRaw: action.payload.rulesRaw,
      }
    }

    case typeFailed(PREPARE_ROUTING_RULES_SETTINGS): {
      return {
        ...state,
        ...defaultState,
        fetched: true,
        error: action.payload,
        ui: state.ui,
      }
    }

    case typeFulfilled(ADD_RULE): {
      if (!action.payload || !action.payload.rule) return state
      const newRules = state.rules.slice()
      newRules.push(action.payload.rule)
      return { ...state, rules: newRules, rulesHaveBeenEdited: true }
    }

    case typeFulfilled(UPDATE_RULE):
    case typeFulfilled(REMOVE_RULE): {
      if (!action.payload || !action.payload.rules) return state
      return { ...state, rules: action.payload.rules, rulesHaveBeenEdited: true }
    }

    case RULES_SET_TOTAL_TRANSACTIONS: {
      if (!action.payload) return state
      return { ...state, totalTransactions: action.payload.totalTransactions }
    }

    case SAVE_ROUTING_RULES: {
      return { ...state, saving: true }
    }

    case typeFulfilled(SAVE_ROUTING_RULES): {
      return { ...state, rulesHaveBeenEdited: false }
    }

    case typeFailed(SAVE_ROUTING_RULES):
    case typeFulfilled(SAVE_ROUTING_RULES): {
      return { ...state, saving: false }
    }

    case CHANGE_ROUTING_RULES_ORDER: {
      const { payload } = action
      if (!payload || !payload.rules) return state
      const newBlockRules =
        payload.type === "block"
          ? payload.rules
          : state.rules.filter(rule => rule.declaration === "block")
      const newTriggerRules =
        payload.type === "trigger_3ds"
          ? payload.rules
          : state.rules.filter(rule => rule.declaration === "trigger_3ds")
      const newDynamicRules =
        payload.type === "dynamic_3ds"
          ? payload.rules
          : state.rules.filter(rule => rule.declaration === "dynamic_3ds")
      const newRouteRules =
        payload.type === "route"
          ? payload.rules
          : state.rules.filter(rule => rule.declaration === "route")
      return {
        ...state,
        rules: [].concat(
          newBlockRules.concat(newTriggerRules).concat(newDynamicRules).concat(newRouteRules),
        ),
        rulesHaveBeenEdited: true,
      }
    }

    case UPDATE_ROUTING_RULES_FORMULA: {
      return { ...state, rulesRaw: action.payload, rulesHaveBeenEdited: true }
    }

    case RESET_ROUTING_RULES: {
      return {
        ...state,
        rules: structuredClone(state.initialRules),
        rulesRaw: state.initialRulesRaw,
        rulesHaveBeenEdited: false,
      }
    }

    case CLONE_RULE: {
      const cloneRules = [...state.rules]
      cloneRules.splice(action.payload.index, 0, action.payload.rule)
      return { ...state, rules: cloneRules, rulesHaveBeenEdited: true }
    }

    case SELECT_TAB: {
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedTabIndex: action.payload.selectedTabIndex,
        },
      }
    }
  }

  return state
}
