import React, { useContext, useEffect, useRef, useState } from "react"
import { $RoutingRule } from "^/features/RoutingRules/consts"
import { RoutingContext } from "../RoutingContext"
import { CriteriaMenu } from "./CriteriaMenu"
import ReactDOM from "react-dom"
import { useClickAway } from "../utils"

const THRESHOLD = 32
/**
 * Find out the position to display the menu
 * @param selectedCardId Selected card id.
 * @returns Menu position
 */
function usePosition(selectedCardId: string | null) {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const scrollPane = document.querySelector(".scroll-pane")

    function layout() {
      if (selectedCardId) {
        const selectedCard = document.querySelector(`.card[data-card-id='${selectedCardId}']`)
        const menu = document.querySelector(".CriteriaMenu")

        if (scrollPane && menu) {
          const menuRect = menu.getBoundingClientRect()
          const cardRect = selectedCard.getBoundingClientRect()
          const bound = scrollPane.getBoundingClientRect()

          const pos = {
            x: cardRect.x + cardRect.width + 16,
            y: cardRect.y - menuRect.height / 2 + cardRect.height / 2,
          }

          if (pos.y < bound.y) {
            pos.y = bound.y + 16
          } else if (pos.y + menuRect.height > bound.y + bound.height) {
            pos.y = bound.y + bound.height - menuRect.height - THRESHOLD
          }

          if (pos.x + menuRect.width + THRESHOLD > bound.x + bound.width) {
            pos.x = bound.x + bound.width - menuRect.width - THRESHOLD
          } else if (pos.x < bound.x + THRESHOLD) {
            pos.x = bound.x + THRESHOLD
          }

          setPosition(pos)
        }
      }
    }

    scrollPane.addEventListener("scroll", layout)
    layout()

    return () => {
      scrollPane.removeEventListener("scroll", layout)
    }
  }, [selectedCardId])

  return position
}

export type Props = {
  routingRules: Array<$RoutingRule>
  onUpdateRule: (rule: $RoutingRule) => void
  editing: boolean
  container?: HTMLElement | null
}

export const CriteriaMenuWithContext = ({
  routingRules,
  onUpdateRule,
  editing,
  container = document.body,
}: Props) => {
  const [state, dispatch] = useContext(RoutingContext)

  const selectedRule = routingRules[state.selectedRuleIndex]

  const ref = useRef<HTMLDivElement | null>(null)
  useClickAway(ref, () => {
    dispatch({
      type: "select.routing.card",
      id: null,
    })
  })

  const position = usePosition(state.selectedCardId)

  // if not in editing mode do not render menu
  if (!editing) return null
  // if nothing is selected do not render menu
  if (!state.selectedCardId) return null

  // Because we render the menu to the body, we need to manually set the routing-v2 class flag for criteria menu
  return ReactDOM.createPortal(
    <div className="routing-v2">
      <CriteriaMenu
        ref={ref}
        position={position}
        onClose={() => {
          dispatch({
            type: "select.routing.card",
            id: null,
          })
        }}
        rule={selectedRule}
        onUpdateRule={onUpdateRule}
        onDeletion={filterId => {
          // Close menu if the card is deleted
          if (state.selectedCardId === filterId) {
            dispatch({
              type: "select.routing.card",
              id: null,
            })
          }

          const newRule = structuredClone(selectedRule)
          newRule.conditions[0].filters = newRule.conditions[0].filters.filter(
            item => item.id !== filterId,
          )
          onUpdateRule(newRule)
        }}
        number={state.selectedRuleIndex + 1}
      ></CriteriaMenu>
    </div>,
    container,
  )
}
