export const apiValuesDictionary = {
  "3ds_authentication": "3DS Authentication",
  "3ds-required": "3DS required",
  "user-input": "User input",
  sogenactif: "Société générale",
  sogenactif_3ds2: "Société générale 3DS2",
  mercanet_3ds2: "Mercanet 3DS2",
  ebt: "EBT",
  "three-d-s": "3DS",
  "three-d-s-2": "3DS2",
  "elo/discover": "Elo discover",
  "apple-pay": "ApplePay",
  ipay_bosnia: "Ipay Bosnia",
  adyencellulant: "Adyen Cellulant",
  adyendotpay: "Adyen Dotpay",
  ipay_siavpos: "Ipay SIAVPos",
  halykbank: "Halyk bank",
  paypalexpresscheckout: "Paypal express CKO",
  checkoutcom: "Checkout.com",
  pagobancomat: "PageBancomat",
  jcb: "JCB",
  cmi: "CMI",
  cartesbancaires: "Cartes bancaires",
  "diners club international": "Diners club Int.",
}
export const displayValueFromDictionary = (key: string) => apiValuesDictionary[key] || key
