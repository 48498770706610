import * as ProcessOut from "../../util/ProcessOut"
import type { $Period, $TelescopeV2Page } from "./consts"
import {
  FETCH_LATEST_RUN,
  LOAD_RECOMMENDATIONS_FROM_LATEST_RUN,
  LOAD_RECOMMENDATIONS_FROM_PERIOD,
  REQUEST_PREPARE_TELESCOPE_V2_PAGE,
  SELECT_CHARGEBACK_GATEWAY_CONFIGURATION,
} from "./consts"
import { getTimespanMetricFromPeriod } from "./util"

// If you want recommendations file for a historical run at a previous date in that period
export function fetchLatestRun(period: $Period) {
  return {
    type: FETCH_LATEST_RUN,
    payload: ProcessOut.APIcallPromise(`/analytics/telescope_v2/latest_runs/${period}`, "GET")
      .then(response => (response as any).data)
      .catch(err => {
        throw err
      }),
  }
}
// use the runID of any of the runs you want to specifically fetch
export function loadRecommendationFromLatestRun(latestRun: string) {
  return {
    type: LOAD_RECOMMENDATIONS_FROM_LATEST_RUN,
    payload: ProcessOut.APIcallPromise(`/analytics/telescope_v2?${latestRun}`, "GET")
      .then(response => (response as any).data)
      .catch(err => {
        throw err
      }),
  }
}

export type ApiPeriod = "week" | "month" | "quarter"

const PeriodToApiPeriodMapping: Record<$Period, ApiPeriod> = {
  "1w": "week",
  "1m": "month",
  "3m": "quarter",
}

// If you want the recommendations file for the latest run for that period
export function loadRecommendationFromPeriod(period: $Period = "1w") {
  return {
    type: LOAD_RECOMMENDATIONS_FROM_PERIOD,
    payload: ProcessOut.APIcallPromise(
      `/analytics/telescope_v2?period=${PeriodToApiPeriodMapping[period]}`,
      "GET",
    )
      .then(response => (response as any).data)
      .catch(err => {
        throw err
      }),
  }
}
export function prepareTelescopeV2Page(page: $TelescopeV2Page, period: $Period) {
  return {
    type: REQUEST_PREPARE_TELESCOPE_V2_PAGE,
    payload: {
      page,
      period: getTimespanMetricFromPeriod(period),
    },
  }
}

export const selectChargebackGatewayConfiguration = config => ({
  type: SELECT_CHARGEBACK_GATEWAY_CONFIGURATION,
  payload: {
    config,
  },
})
