import { datadogRum } from "@datadog/browser-rum"

const initDatadog = env => {
  if (["production", "pre-prod", "staging"].includes(env)) {
    const config = {
      applicationId: "eca70da1-6bac-45ca-a81f-9a9d0fe97e67",
      clientToken: "pub40496529ca2c940c80ae2404ac74777e",
      service: "dashboard",
      env: env,
      version: process.env.VERSION,
      sampleRate: env === "production" ? 100 : 50,
      premiumSampleRate: 50,
      useSecureSessionCookie: true,
    }
    datadogRum.init(config)
  }
}

export default initDatadog
