import {
  AnimatedAxis,
  AnimatedLineSeries,
  buildChartTheme,
  Tooltip,
  TooltipData,
  XYChart,
} from "@visx/xychart"
import { ThemeConfig } from "@visx/xychart/lib/theme/buildChartTheme"
import { TextProps } from "@visx/text/lib/Text"
import * as React from "react"
import { useEffect, useRef } from "react"
import { FormattedDate } from "react-intl"
import { TelescopeCardValue } from "../components/TelescopeCardValue"
import { COLORS } from "./LegendChart"
import "./LineChart.scss"
import { ChartDatum, XAccessor, YAccessor } from "^/features/TelescopeV2/visualisations/util"
import { ValueStyle } from "^/features/TelescopeV2/consts"
import { trackTarget } from "^/vNext/tracking"

type Props = {
  datas: ChartDatum[][]
  name: string
  valueStyle: "percent" | "currency" | "decimal"
  showFullNumberTooltip?: boolean
}
export default function LineChart({
  datas,
  valueStyle,
  name,
  showFullNumberTooltip = false,
}: Props) {
  const ref = useRef<HTMLDivElement>()
  const xAccessor: XAccessor = d => d.x
  const yAccessor: YAccessor = d => d.y

  const axisStyle = {
    fill: "#100C3A",
    fontSize: 15,
    textAnchor: "middle",
  } as TextProps

  const AxBottom = () => {
    return (
      <AnimatedAxis
        orientation="bottom"
        hideAxisLine={true}
        hideTicks={true}
        tickFormat={value =>
          new Date(value).toLocaleDateString("en-us", {
            day: "numeric",
            month: "short",
          })
        }
        tickLabelProps={() => axisStyle}
      />
    )
  }

  const AxLeft = () => {
    return (
      <AnimatedAxis
        hideAxisLine={true}
        hideTicks={true}
        tickFormat={value => {
          if (valueStyle === "percent") {
            return (value * 100).toFixed(0) + "%"
          } else {
            return Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(value)
          }
        }}
        orientation="left"
        tickLabelProps={() => ({ ...axisStyle, textAnchor: "end" })}
      />
    )
  }

  const customTheme = buildChartTheme({
    colors: COLORS,
  } as ThemeConfig)

  return (
    <div ref={ref}>
      <XYChart
        height={275}
        // magic numbers - XYChart does not calculate margin for you based on your axes sizes. These numbers were manually
        // tested to fit our current use cases for Telescope KPI page charts
        margin={{
          top: 20,
          left: 55,
          right: 20,
          bottom: 40,
        }}
        xScale={{
          type: "band",
        }}
        yScale={{
          type: "linear",
          zero: false,
        }}
        theme={customTheme}
        onPointerUp={() => trackTarget(ref.current, "Click", "Line Chart", { chartName: name })}
      >
        <AxBottom />
        <AxLeft />
        {datas.map((el, index) => (
          <AnimatedLineSeries
            key={`${index}-key`}
            dataKey={`${index}-line`}
            stroke={COLORS[index]}
            data={el}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
          />
        ))}
        <Tooltip<ChartDatum>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showDatumGlyph
          renderTooltip={({ tooltipData }) => (
            <LineChartTooltip
              tooltipData={tooltipData}
              valueStyle={valueStyle}
              showFullNumberTooltip={showFullNumberTooltip}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
              chartRef={ref}
              chartName={name}
            />
          )}
        />
      </XYChart>
    </div>
  )
}

type LineChartTooltipProps = {
  tooltipData: TooltipData<ChartDatum>
  valueStyle: ValueStyle
  showFullNumberTooltip: boolean
  xAccessor: XAccessor
  yAccessor: YAccessor
  chartRef: React.MutableRefObject<HTMLDivElement>
  chartName: string
}

function LineChartTooltip({
  tooltipData,
  valueStyle,
  showFullNumberTooltip,
  xAccessor,
  yAccessor,
  chartRef,
  chartName,
}: LineChartTooltipProps) {
  // depend upon the datum updating, as this limits the track event to each time a tooltip with new data is rendered
  useEffect(() => {
    chartRef.current && trackTarget(chartRef.current, "Hover", "Line Chart", { chartName })
  }, [chartRef, chartName, tooltipData.nearestDatum.datum])

  return (
    <>
      {tooltipData.nearestDatum.key &&
        [tooltipData.nearestDatum.key]
          .filter(k => k)
          .map(el => (
            <div key={el} className="tooltip-container">
              <div className="tooltip-value">
                {valueStyle === "percent" && showFullNumberTooltip ? (
                  yAccessor(tooltipData.datumByKey[el].datum) * 100 + "%"
                ) : (
                  <TelescopeCardValue
                    value={yAccessor(tooltipData.datumByKey[el].datum)}
                    style={valueStyle}
                  />
                )}
              </div>
              <div className="tooltip-date">
                <FormattedDate
                  value={xAccessor(tooltipData.datumByKey[el].datum)}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              </div>
            </div>
          ))}
    </>
  )
}
