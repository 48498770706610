import React from "react"
import PeriodSelector from "./PeriodSelector"
import "./TelescopeHeader.scss"

type Props = {
  title: string
}

const TelescopeHeader: React.FC<Props> = ({ title }) => (
  <div className="telescope-heading">
    <div className="telescope-heading-left" data-testid="telescope-header-title">
      <h4>{title}</h4>
    </div>
    <div className="telescope-heading-right" data-tracking-location="Analysis Period">
      Analysis period
      <div className="xsmall-margin-left">
        <PeriodSelector />
      </div>
    </div>
  </div>
)

export default TelescopeHeader
