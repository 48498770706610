import { AnalyticsBrowser } from "@segment/analytics-next"

export type Context = {
  subPageName?: string
  actionName?: string
  locationName?: string
  objectName?: string
  [key: string]: any
}

export interface Tracker {
  identify: (userId: string, userAttributes: any) => void
  page: (page: any) => void
  track: (eventName: string, context?: Context) => void
}

export let segmentAnalytics: Tracker

export const init = env => {
  if (["production", "pre-prod", "staging", "preview"].includes(env)) {
    segmentAnalytics = AnalyticsBrowser.load({
      writeKey: process.env.SEGMENT_WRITE_KEY,
    }) as any
  } else if (env.startsWith("local")) {
    segmentAnalytics = consoleLoggingTracker
  }
}

const consoleLoggingTracker: Tracker = {
  identify: (userId, userAttributes) =>
    console.log(
      `ANALYTICS - IDENTIFY - userId: ${userId}, userAttributes: ${JSON.stringify(userAttributes)}`,
    ),
  page: page => console.log(`ANALYTICS - PAGE - page: ${JSON.stringify(page)}`),
  track: (eventName, eventContext: Context) =>
    console.log(
      `ANALYTICS - TRACK - eventName: ${eventName}, eventContext: ${JSON.stringify(eventContext)}`,
    ),
}
