import React from "react"
import Select, { components } from "react-select"
import type { $DataPoint } from "../consts"
import getColor from "../charts/colors"
type State = {
  value: Array<$Option> | null | undefined
}
type Props = {
  points: Array<$DataPoint>
  colors: Array<{
    key: string
    color: string
  }>
  onChange: (arg0: Array<string>) => void
}
type $Option = {
  label: string
  value: string
  color: string
  count: number
}

const MultiValueLabel = props => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.label} ({props.data.count})
    </components.MultiValueLabel>
  )
}

const CustomOption = props => {
  return (
    <components.Option {...props}>
      {props.data.label} ({props.data.count})
    </components.Option>
  )
}

class ComparableSelector extends React.Component {
  props: Props
  state: State

  constructor() {
    super()
    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    const { points } = this.props
    const value = points.map(point => {
      const correspondingColor = this.props.colors.find(entry => entry.key === point.key)
      return {
        label: point.key,
        value: point.key,
        color: correspondingColor ? correspondingColor.color : getColor(0),
        count: point.value,
      }
    })
    this.setState({
      value,
      option: value,
    })
  }

  handleSelectChange = (newValue: Array<$Option>) => {
    this.setState({
      value: newValue,
    })
    this.props.onChange(newValue.map(option => option.value))
  }
  renderValue = (option: $Option) => (
    <strong
      style={{
        color: option.color,
      }}
    >
      {option.label}
    </strong>
  )

  render() {
    const { points, colors } = this.props
    const { value } = this.state
    return (
      <div
        style={{
          display: "inline-block",
          width: "100%",
        }}
      >
        <Select
          className="react-select"
          closeMenuOnSelect={false}
          isDisabled={false}
          isMulti={true}
          onChange={this.handleSelectChange}
          options={this.state.option}
          components={{
            MultiValueLabel,
            Option: CustomOption,
          }}
          placeholder="Select your favourite(s)"
          isRtl={false}
          value={value}
          styles={{
            multiValue: (base, props) => {
              return {
                ...base,
                textAlign: "left",
                padding: ".2em",
                backgroundColor: props.data.color,
              }
            },
          }}
        />
      </div>
    )
  }
}

export default ComparableSelector
