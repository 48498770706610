import React from "react"
import { connect } from "react-redux"
import Customer from "../components/Customer"
import ContentLayout from "./ContentLayout"
import * as CustomerActions from "../Actions/CustomerActions"
import Loader from "../components/Loader"
import Error from "../components/Error"
import Empty from "../components/Empty"
import Pagination from "../components/Pagination"
import CustomerModal from "../components/CustomerDetails/DetailsModal"
import type { $Dispatcher, $RouterLocation, $State } from "../util/Types"
import NotPermitted from "../features/Permissions/NotPermitted"
import * as Actions from "../features/SearchBar/actions"
import { track } from "../vNext/tracking"

const customersPerPage = 30
type Props = {
  customers: {} & $State
  location: $RouterLocation
} & $Dispatcher

class Customers extends React.Component<Props> {
  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Customers | ProcessOut"
    const { filter } = this.props.location.query
    // we check that there is an afterItem parameter
    // if not then we are going backward and there should be a before item
    const afterItem = this.props.location.query.after_item
    const beforeItem = this.props.location.query.before_item
    this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem)
    // Set the document for filter button
    this.props.dispatch(Actions.setFilteringDocument("customers"))
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const oldFilter = this.props.location.query.filter
    const newFilter = nextProps.location.query.filter

    if (oldFilter !== newFilter) {
      this.applyFilter(newFilter, true)
      return
    }

    let firstItem = this.props.location.query.after_item
    if (!firstItem) firstItem = ""

    if (
      !nextProps.customers.fetching &&
      !nextProps.customers.fetched &&
      !nextProps.customers.error
    ) {
      this.props.dispatch(
        CustomerActions.loadCustomers(
          this.props.location.query.filter,
          customersPerPage,
          true,
          firstItem,
        ),
      )
    }
  }

  componentWillUnmount() {
    // Remove document to hide filter
    this.props.dispatch(Actions.setFilteringDocument(null))
  }

  openModal(event: Event) {
    event.preventDefault()
    track("Customers", "Click", "New customer", "New customer button")

    this._modal.getWrappedInstance().openModal()
  }

  applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
    this.props.dispatch(
      CustomerActions.loadCustomers(
        filter,
        customersPerPage,
        forward !== null ? forward : true,
        reset ? "" : firstItem,
      ),
    )
  }

  render() {
    const { customers } = this.props.customers
    let header
    let content
    let empty

    if (
      (this.props.customers.fetching || !this.props.customers.fetched) &&
      !this.props.customers.error
    ) {
      content = <Loader />
    } else if (this.props.customers.error) {
      if (this.props.customers.error.notPermitted) content = <NotPermitted />
      else content = <Error />
    } else {
      if (customers.length === 0) {
        let emptyText = "You haven't created any customer yet."

        if (this.props.location.query.filter !== "") {
          emptyText = "No customer matches your search."
        }

        empty = (
          <div>
            <div className="row small-margin-bottom">
              <Empty text={emptyText} />
            </div>
            <div className="row">
              <div className="large-12 columns text-center">
                <a onClick={this.openModal.bind(this)} className="button round small border">
                  New customer
                </a>
              </div>
            </div>
          </div>
        )
      }

      if (customers.length > 0) {
        header = (
          <div
            className="row capitalize greyed bold small-margin-bottom"
            style={{
              fontSize: "0.8em",
            }}
          >
            <div className="medium-offset-1 medium-11 columns">Name</div>
          </div>
        )
      }

      content = (
        <div>
          {empty}
          {customers.map(customer => (
            <div key={customer.id}>
              <Customer customer={customer} />
            </div>
          ))}
        </div>
      )
    }

    const createButton = (
      <a onClick={this.openModal.bind(this)} className="button round border small">
        New customer
      </a>
    )
    const pageHeader = (
      <div className="row">
        <div className="medium-9 columns">
          <h4>Customers</h4>
        </div>
        <div className="medium-3 columns text-right">{createButton}</div>
      </div>
    )
    return (
      <ContentLayout title={pageHeader}>
        <CustomerModal ref={m => (this._modal = m)} creating />
        <div className="row">
          <div className="large-12 columns">
            {header}

            {content}

            <Pagination
              reloadFunction={CustomerActions.loadCustomers.bind(
                null,
                this.props.location.query.filter,
                customersPerPage,
              )}
              hasMore={this.props.customers.fetched ? this.props.customers.has_more : false}
              hide={this.props.customers.fetching || !this.props.customers.fetched}
              location={this.props.location}
              itemsArray={this.props.customers.fetched ? customers : []}
              onPrevious={() => track("Customers", "Click", "Pagination", "Previous")}
              onNext={() => track("Customers", "Click", "Pagination", "Next")}
            />
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  customers: store.customers,
  projects: store.projects,
}))(Customers)
