import { MapChart as PicassoMapChart } from "@processout/picasso"
import qs from "qs"
import React from "react"
import * as ReactDOMServer from "react-dom/server"
import { IntlProvider } from "react-intl"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { push } from "react-router-redux"
import uniqid from "uniqid"
import Loading from "../../../../components/Loader"
import * as ISOCountries from "../../../../util/ISOCountries"
import type { $Dispatcher } from "../../../../util/Types"
import ChartPopup from "../../Boards/charts/Popup/ChartPopup"
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from "../../Boards/charts/Popup/consts"
import type { $ChartType } from "../../Boards/consts"
import type { $CountryPoint, $MapChartTip } from "../Consts"
import { computeMapChartColor } from "../Utils"
import AmountMultiDimTooltip from "./AmountMultiDimTooltip"
import AmountToolTip from "./AmountToolTip"
import DefaultMultiDimTooltip from "./DefaultMultiDimTooltip"
import DefaultTooltip from "./DefaultTooltip"
import PercentMultiDimTooltip from "./PercentMultiDimTooltip"
import PercentTooltip from "./PercentTooltip"
import { segmentAnalytics } from "../../../../vNext/Segment"
import { ANALYTICS_FILTER_CHANGE } from "../../../../Actions/AnalyticsActions"
type Props = {
  data: Array<any>
  currency: string | null | undefined
  type: $ChartType
  location: any
  popup: {
    chartId: string
    onfilter: (() => void) | null | undefined
  }
} & $Dispatcher
type State = {
  id: string
  map: any
  _countTotal: number
  _valueTotal: number
  filter: string
}
export class MapChart extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      id: uniqid(),
      map: null,
      _countTotal: 0,
      _valueTotal: 0,
      filter: "",
    }
  }

  componentDidMount(): void {
    const { data } = this.props
    let countTotal = 0
    let valueTotal = 0

    for (const entry of data) {
      countTotal += entry._count
      valueTotal += entry.value
    }

    this.setState(
      {
        _countTotal: countTotal,
        _valueTotal: valueTotal,
      },
      () => {
        this.draw()
      },
    )
  }

  componentDidUpdate() {
    this.draw()
  }

  tip = (d: $MapChartTip) => {
    const { type, currency } = this.props
    const { _countTotal, _valueTotal } = this.state
    const barKey = d.key
    let content

    switch (type as string) {
      case "percentage": {
        if (d.country.value !== null && d.country.value !== undefined) {
          content = (
            <PercentTooltip point={d.country} _countTotal={_countTotal} _valueTotal={_valueTotal} />
          )
        } else {
          content = <PercentMultiDimTooltip point={d.country} />
        }

        break
      }

      case "amount": {
        if (d.country.value !== null && d.country.value !== undefined) {
          content = (
            <AmountToolTip
              point={d.country}
              _countTotal={_countTotal}
              _valueTotal={_valueTotal}
              currency={currency || "USD"}
            />
          )
        } else {
          content = <AmountMultiDimTooltip point={d.country} currency={currency || "USD"} />
        }

        break
      }

      default: {
        if (d.country.value !== null && d.country.value !== undefined) {
          content = (
            <DefaultTooltip point={d.country} _countTotal={_countTotal} _valueTotal={_valueTotal} />
          )
        } else {
          content = <DefaultMultiDimTooltip point={d.country} />
        }
      }
    }

    return ReactDOMServer.renderToString(
      <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
        <div className="row">
          <div className="large-12 columns">
            <div className="row">
              <div className="large-12 columns">
                <h4>{d.country.key}</h4>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">{content}</div>
            </div>
          </div>
        </div>
      </IntlProvider>,
    )
  }

  draw() {
    const { data, dispatch, location } = this.props
    const { id, map } = this.state
    if (!data || !document.getElementById(`chart${id}`)) return

    if (!map) {
      this.setState({
        map: new PicassoMapChart(`#chart${id}`, {
          tip: this.tip,
          onclick: (data: { country: $CountryPoint }) => {
            this.setState({
              filter: data.country._corresponding_filter,
            })
            const { country } = data

            if (country.value !== null && country.value !== undefined) {
              this.state.map.cleanupTip()

              const onFilter = () => {
                const filter = country._corresponding_filter
                if (!filter) return
                const splits = filter.split(";")
                const filterIndex = splits.findIndex(
                  entry => !entry.includes("start:") && !entry.includes("end:"),
                )
                const filterValue = splits[filterIndex] || ""
                const filterParam = qs.parse(location.query.search)
                filterParam.filter = filterValue
                const newParam = qs.stringify(filterParam)
                dispatch(push(`${location.pathname}?${newParam}`))
                dispatch({
                  type: ANALYTICS_FILTER_CHANGE,
                  payload: {
                    filter: filterValue,
                  },
                })
                segmentAnalytics?.track("BAR_CHART_CLICK_FILTER", {
                  filterValue,
                })
              }

              dispatch({
                type: OPEN_CHART_POPUP,
                payload: {
                  chartId: id,
                  onFilter,
                },
              })
            }
          },
        }),
      })
      return
    }

    let min = 0
    let max = 0

    for (const entry of data) {
      if (entry.value !== null && entry.value !== undefined) {
        if (entry.value < Math.log(min)) min = entry.value
        else if (entry.value > max) max = entry.value
      } else {
        const currentValue = entry.datapoints.reduce((count, p) => count + p._count, 0)
        if (currentValue < min) min = currentValue
        else if (currentValue > max) max = currentValue
      }
    }

    for (const country of data) {
      const c = ISOCountries.isoCountries[country._original_key]
      if (!c) continue
      map.addCountry({
        key: country._original_key,
        name: c.alpha3,
        country,
        color: data => computeMapChartColor(min, max, data),
      })
    }

    map.resetSVG()
    map.draw()
  }

  render() {
    const { id, filter } = this.state
    const { data, dispatch, popup } = this.props
    if (!data) return <Loading />
    return (
      <div
        onClick={e => {
          if (popup.chartId === id) {
            if (!(e.target as any).classList.contains("popup-button")) {
              dispatch({
                type: CLOSE_CHART_POPUP,
              })
            }
          }
        }}
      >
        <svg
          className={`chart line-chart chart-popup ${
            id === popup.chartId ? "chart-popup-open" : ""
          }`}
          id={`chart${id}`}
          width="100%"
          height="500px"
        />
        <ChartPopup
          correspondingFilter={filter}
          correspondingChartId={id}
          canUpdateAnalyticsFilter
        />
      </div>
    )
  }
}
export default connect(store => ({
  popup: store.analytics_v2.popup,
}))(withRouter(MapChart))
