import type { DragEvent } from "react"
import React, { useContext } from "react"
import type { Dispatch } from "./DragDropContext"
import { DragDropContext } from "./DragDropContext"
import type { DragDropState } from "./types"
export type Props = {
  style?: React.CSSProperties
  children: React.ReactNode
  onDragLeave?: (state: DragDropState, dispatch: Dispatch, evt: DragEvent<HTMLElement>) => void
  onDragOver?: (state: DragDropState, dispatch: Dispatch, evt: DragEvent<HTMLElement>) => void
  onDrop?: (state: DragDropState, dispatch: Dispatch, evt: DragEvent<HTMLElement>) => void
}

/**
 * Defines extra area for dropping action
 */
export const DropArea = ({ children, onDragOver, onDrop, onDragLeave, ...props }: Props) => {
  const [state, dispatch] = useContext(DragDropContext)
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      {...props}
      className="drop-area"
      onDragOver={evt => {
        evt.preventDefault()
        if (onDragOver) onDragOver(state, dispatch, evt)
      }}
      onDragLeave={evt => {
        if (onDragLeave) onDragLeave(state, dispatch, evt)
      }}
      onDrop={evt => {
        if (onDrop) onDrop(state, dispatch, evt)
      }}
    >
      {children}
    </div>
  )
}
