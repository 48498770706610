import type { DetailsState } from "./DetailsReducer"
import Details from "./DetailsReducer"
import type { TransactionsState } from "./TransactionsReducer"
import Transactions from "./TransactionsReducer"
import { combineReducers } from "redux"
import Tokens from "./TokensReducer"
export type CustomerDetailsState = {
  details: DetailsState
  transactions: TransactionsState
}
const dataReducer = combineReducers({
  details: Details,
  transactions: Transactions,
  tokens: Tokens,
})
export default dataReducer
