import React from "react"
import Select from "react-select"
import * as CouponsActions from "../../Actions/CouponsActions"
import { connect } from "react-redux"

class CouponSelect extends React.Component {
  constructor() {
    super()
    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    this.props.dispatch(CouponsActions.fetchCoupons())
  }

  couponSelected(value) {
    this.setState({
      value,
    })
  }

  getSelected() {
    return this.state.value
  }

  render() {
    const { coupons } = this.props.couponsDetails
    const fetching = !this.props.couponsDetails.fetched && this.props.couponsDetails.fetching
    let options = []

    if (!fetching && this.props.couponsDetails.fetched) {
      options = coupons.map(coupon => ({
        value: coupon.id,
        label: coupon.id,
      }))
    }

    return (
      <Select
        className="react-select"
        id="coupon-selector"
        name="coupon_id"
        placeholder="Apply a coupon"
        options={options}
        isLoading={fetching}
        value={this.state.value}
        onChange={this.couponSelected.bind(this)}
      />
    )
  }
}

export default connect(store => ({
  couponsDetails: store.coupons,
}))(CouponSelect)
