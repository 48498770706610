import clsx from "clsx"
import * as React from "react"
import { useState } from "react"
import "./Aside.scss"
export type Props = {
  editing: boolean
  collapsed: boolean
  onChange?: (collapsed: boolean) => void
  children?: React.ReactNode
}

export const Aside = ({ editing, collapsed: initCollapsed, onChange, children }: Props) => {
  const [collapsed, setCollapsed] = useState(initCollapsed)
  return (
    <aside
      data-tracking-location="Condition Menu"
      className={clsx({
        editing: editing,
        collapsed: collapsed,
      })}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inert={!editing ? "" : null}
    >
      <div className="aside-head">
        <span className="aside-head-text">Conditions</span>
        <button
          data-auto-tracking="true"
          data-tracking-label="Collapse Button"
          data-tag-collapsed={collapsed}
          aria-label={collapsed ? "expand sidebar" : "collapse sidebar"}
          onClick={() => {
            setCollapsed(!collapsed)
            onChange(!collapsed)
          }}
        ></button>
      </div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <div className="aside-content" inert={collapsed ? "" : undefined}>
        {children}
      </div>
    </aside>
  )
}
