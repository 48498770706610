import type { $Action, $State } from "^/util/types"
import { FETCH_SUBSCRIPTION_DETAILS } from "^/Actions/SubscriptionsActions"
import type { $Subscription } from "^/types/Subscription"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type $SubscriptionsDetailsState = {
  subscription: $Subscription
} & $State
const defaultState: $SubscriptionsDetailsState = {
  fetching: false,
  fetched: false,
  error: null,
  subscription: {} as $Subscription,
}
export default function (state: $SubscriptionsDetailsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_SUBSCRIPTION_DETAILS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_SUBSCRIPTION_DETAILS): {
      return { ...state, fetching: false, fetched: true, subscription: action.payload.subscription }
    }
  }

  return state
}
