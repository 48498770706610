import React from "react"
import { goBack } from "react-router-redux"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import * as CurrencyActions from "../../Actions/CurrencyActions"
import Loader from "../../components/Loader"
import * as ProjectsActions from "./actions"
import type { $Dispatcher } from "../../util/Types"
import type { $AwsRegion } from "./RegionChoice"
import RegionChoice from "./RegionChoice"
import type { $CurrencyOption } from "../CurrencySelector/CurrencySelector"
import CurrencySelector from "../CurrencySelector/CurrencySelector"
import type { $ProjectType } from "./consts"
import ProjectTypeSelector from "./ProjectTypeSelector"

const serialize = require("form-serialize")

type Props = {} & $Dispatcher
type State = {
  fetching: boolean
  privateKey: string | null | undefined
  keySelected: boolean
  creating: boolean
  region: $AwsRegion | null | undefined
  currency: $CurrencyOption | null | undefined
  type: $ProjectType | null | undefined
  name: string
}

class ProjectWizard extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      fetching: false,
      privateKey: null,
      keySelected: true,
      creating: false,
      region: null,
      currency: null,
      type: null,
      name: "",
    }
  }

  componentDidMount() {
    if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
      this.props.dispatch(CurrencyActions.loadCurrencies())
    }
  }

  submit(event) {
    event.preventDefault()
    this.setState({
      creating: true,
    })
    const { name, currency, region } = this.state
    if (!currency || !region)
      // Submit button is blocked either way
      return
    const project = {
      name,
      default_currency: currency.value,
      region,
    }
    this.props.dispatch(ProjectsActions.createProject(project))
  }

  render() {
    if (!this.props.currenciesDetails.fetched) {
      return <Loader />
    }

    const createButton = this.state.creating ? (
      <button
        className="round border button"
        style={{
          minWidth: "120px",
          marginLeft: "15px",
        }}
      >
        <div className="loading-spinner" />
      </button>
    ) : (
      <input
        type="submit"
        className={`round button main${
          !this.state.name || !this.state.currency || !this.state.region ? " disabled" : ""
        }`}
        style={{
          marginLeft: "15px",
        }}
        value="Create"
        disabled={this.state.creating}
      />
    )
    return (
      <div>
        <ReactTooltip effect="solid" />
        <div className="row margin-bottom">
          <div className="large-12 columns">
            <h3 className="text-center title">Create a new project</h3>
          </div>
        </div>

        <div className="row">
          <form id="project-setup" onSubmit={this.submit.bind(this)}>
            <div className="large-6 medium-7 small-centered columns">
              <div className="row margin-bottom">
                <div className="large-12 columns">
                  <p>
                    ProcessOut projects help you manage separate your different websites and
                    services, securing your different{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                      }}
                    >
                      {" "}
                      payment environments
                    </span>
                    . Additionally, different projects can be used if you own several legal entities
                    and want to{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                      }}
                    >
                      split your transactions
                    </span>{" "}
                    between them. Let&apos;s get you started by creating a new project.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="large-3 medium-4 columns greyed next-to-input">Project name</div>
                <div className="large-8 medium-7 medium-offset-1 columns">
                  <input
                    name="name"
                    type="text"
                    placeholder="Project name..."
                    value={this.state.name}
                    onChange={event => {
                      this.setState({
                        name: event.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="large-3 medium-4 columns greyed next-to-input">
                  Project currency
                </div>
                <div className="large-8 medium-7 medium-offset-1 columns">
                  <CurrencySelector
                    value={this.state.currency}
                    onChange={currency => {
                      this.setState({
                        currency,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <div className="row small-margin-bottom">
                    <div className="large-12 columns">
                      <p>
                        Please also choose the region in which you’d like your customer data to be
                        stored, to comply with your local requirements such as the GDPR.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="large-3 medium-4 columns greyed next-to-input">Project type</div>
                <div className="large-8 medium-7 medium-offset-1 columns">
                  <ProjectTypeSelector
                    value={this.state.type}
                    onChange={type => {
                      this.setState({
                        type: type.value,
                      })
                    }}
                  />
                  <div className="row small-margin-top">
                    The region is tied to the functionality you wish to use. A project created on an
                    Analytics region cannot do Smart-Routing in the future.
                  </div>
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="large-12 columns">
                  <div className="row">
                    <div className="large-3 medium-4 columns greyed next-to-input">Region </div>
                    <div className="large-8 medium-7 medium-offset-1 columns">
                      <RegionChoice
                        onChange={region => {
                          this.setState({
                            region,
                          })
                        }}
                        value={this.state.region}
                        projectType={this.state.type}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="large-6 columns clearfix" />
                <div className="large-6 columns clearfix text-right">
                  {this.props.projectsDetails.fetched &&
                    this.props.projectsDetails.projects.length > 0 && (
                      <a
                        to="/projects"
                        onClick={() => {
                          this.props.dispatch(goBack())
                        }}
                        className="round border button red"
                      >
                        Cancel
                      </a>
                    )}
                  {createButton}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currenciesDetails: store.currencies,
  user: store.user,
  projectsDetails: store.projects,
}))(ProjectWizard)
