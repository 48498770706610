import moment from "moment"
import React from "react"
import { FormattedNumber } from "react-intl"
import getColor from "../../Boards/charts/colors"
import type { $ChartPoint } from "../Consts"
export type Props = {
  datapoints: Array<$ChartPoint>
  colors: Array<{
    color: string
    key: string
  }>
}

const DefaultTooltip = ({ datapoints, colors }: Props) => {
  const totalCount = datapoints.reduce((count, point) => count + point._count, 0)
  return (
    <div
      style={{
        fontSize: ".8em",
        maxWidth: "400px",
      }}
    >
      {datapoints
        .filter(entry => entry._count > 0)
        .map(entry => {
          const color = colors.find(color => color.key === entry.key)
          const key = entry.key instanceof Date ? moment(entry.key).format() : entry.key
          return (
            <div
              data-testid="default-tooltip"
              className="small-margin-bottom"
              key={key}
              style={{
                float: "left",
                width: "200px",
              }}
            >
              <div
                style={{
                  fontSize: "1.1em",
                  marginBottom: 0,
                  color: color ? color.color : getColor(0),
                }}
              >
                {key}
              </div>
              <FormattedNumber value={entry._count} /> transactions
              <span className="greyed">
                {" "}
                (
                <FormattedNumber value={entry._count / totalCount} style="percent" />)
              </span>
            </div>
          )
        })}
    </div>
  )
}

export default DefaultTooltip
