export const REQUEST_CONFIRMATION = "REQUEST_CONFIRMATION"
export type $RequestConfirmationAction = {
  type: string
  payload: {
    content: any
    // HTML content
    resolve: (arg0: any) => any
    reject: (arg0?: any) => any
    confirmTimeout?: number
  }
}
