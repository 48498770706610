export type $SearchBuilderOption = {
  value: string
  label: string
  canFetchValues: boolean
  type: "text" | "amount" | "date" | "values" | "metadata"
  group: string
  description?: string
}
export const SEARCH_BUILDER_GROUPS: Record<
  string,
  Array<{
    name: string
    label: string
  }>
> = {
  transactions: [
    {
      label: "Invoice",
      name: "invoice",
    },
    {
      label: "Card",
      name: "card",
    },
    {
      label: "Customer",
      name: "customer",
    },
    {
      name: "dates",
      label: "Dates",
    },
  ],
  analytics: [
    {
      label: "Transactions",
      name: "transactions",
    },
    {
      label: "Card",
      name: "card",
    },
  ],
  customers: [
    {
      name: "customer",
      label: "Customer",
    },
  ],
  cards: [
    {
      name: "card",
      label: "Card",
    },
    {
      name: "cardholder",
      label: "Card holder",
    },
  ],
  subscriptions: [
    {
      name: "subscription",
      label: "Subscription",
    },
    {
      name: "customer",
      label: "Customer",
    },
  ],
}
export const SEARCH_BUILDER_TOKENS: Record<string, Array<$SearchBuilderOption>> = {
  transactions: [
    {
      value: "name",
      label: "Name",
      canFetchValues: false,
      type: "text",
      group: "invoice",
      description: "Transaction name",
    },
    {
      value: "status",
      label: "Status",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Status of the transaction",
    },
    {
      value: "customer_email",
      label: "Customer email",
      canFetchValues: false,
      type: "text",
      group: "customer",
      description: "Email of the customer for a transaction",
    },
    {
      value: "acquirer_name",
      label: "Acquirer name",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Acquirer used within the transaction",
    },
    {
      value: "gateway_name",
      label: "Gateway name",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Name of the last gateway used on a transaction",
    },
    {
      value: "error_code",
      label: "Error code",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Last error code returned for a transaction",
    },
    {
      value: "currency",
      label: "Currency",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Currency of the transactions",
    },
    {
      value: "card_iin",
      label: "IIN/BIN",
      canFetchValues: false,
      type: "text",
      group: "card",
      description: "IIN/BIN of the card used for a transaction",
    },
    {
      value: "payment_type",
      label: "Payment type",
      canFetchValues: true,
      type: "values",
      group: "invoice",
      description: "Payment type used for a transaction",
    },
    {
      value: "card_last4",
      label: "Last 4 digits",
      canFetchValues: false,
      type: "text",
      group: "card",
      description: "Last 4 digits of the card used for a transaction",
    },
    {
      value: "country",
      label: "Country",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Country in which the customer card has ben issued",
    },
    {
      value: "card_bank",
      label: "Issuing bank",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Bank which issued the customer card",
    },
    {
      value: "card_type",
      label: "Card type",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Type of the card used for a transaction",
    },
    {
      value: "continent",
      label: "Continent",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Continent in which the customer card has been issued",
    },
    {
      value: "card_scheme",
      label: "Scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Scheme of the card used for a transaction",
    },
    {
      value: "card_co_scheme",
      label: "Co-scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
      description: "Co-Scheme of the card used for a transaction",
    },
    {
      value: "customer_name",
      label: "Customer name",
      canFetchValues: false,
      type: "text",
      group: "customer",
      description: "Name of the customer for a transaction",
    },
    {
      value: "amount_local",
      label: "Amount",
      canFetchValues: false,
      type: "amount",
      group: "invoice",
      description: "Amount of the transaction in your project's currency",
    },
    {
      value: "metadata.",
      label: "Metadata",
      canFetchValues: false,
      type: "metadata",
      group: "invoice",
      description: "Custom metadata sent at invoice creation",
    },
    {
      value: "created_after",
      label: "Created after",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "created_before",
      label: "Created before",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "chargedback_after",
      label: "Charged back after",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "chargedback_before",
      label: "Charged back before",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "refunded_after",
      label: "Refunded after",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "refunded_before",
      label: "Refunded before",
      canFetchValues: false,
      type: "date",
      group: "dates",
    },
    {
      value: "three_d_s_status",
      label: "three_d_s_status",
      type: "values",
      group: "invoice",
      description: "3DS Transaction status: unchecked, pending, success, failed…",
      canFetchValues: true,
    },
    {
      value: "payment_intent",
      label: "Payment intent",
      type: "values",
      group: "invoice",
      description: "Purpose of the Merchant Initiated Transaction",
      canFetchValues: true,
    },
    {
      value: "initiation_type",
      label: "Initiation type",
      type: "values",
      group: "invoice",
      description: "Initiation type of the transaction",
      canFetchValues: true,
    },
  ],
  analytics: [
    {
      value: "payment_type",
      label: "Payment type",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "status",
      label: "Status",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "gateway_name",
      label: "Gateway name",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "gateway_configuration_id",
      label: "Gateway configuration id",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "error_code",
      label: "Error code",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "currency",
      label: "Currency",
      canFetchValues: true,
      type: "values",
      group: "transactions",
    },
    {
      value: "metadata.",
      label: "Metadata",
      canFetchValues: false,
      type: "metadata",
      group: "transactions",
    },
    {
      value: "card_iin",
      label: "IIN/BIN",
      canFetchValues: false,
      type: "text",
      group: "card",
    },
    {
      value: "country",
      label: "Country",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "card_bank",
      label: "Issuing bank",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "card_type",
      label: "Card type",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "continent",
      label: "Continent",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "card_scheme",
      label: "Scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "card_co_scheme",
      label: "Co-scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "amount_local",
      label: "Amount",
      canFetchValues: false,
      type: "amount",
      group: "invoice",
    },
    {
      value: "three_d_s_status",
      label: "three_d_s_status",
      type: "values",
      group: "invoice",
      description: "3DS Transaction status: unchecked, pending, success, failed…",
      canFetchValues: true,
    },
  ],
  customers: [
    {
      value: "id",
      label: "id",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "email",
      label: "Email",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "first_name",
      label: "First name",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "last_name",
      label: "Last name",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "address1",
      label: "Address 1",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "address2",
      label: "Address 2",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "city",
      label: "City",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "state",
      label: "State",
      canFetchValues: true,
      type: "text",
      group: "customer",
    },
    {
      value: "zip",
      label: "Zip code",
      canFetchValues: true,
      type: "text",
      group: "customer",
    },
    {
      value: "country",
      label: "Country",
      canFetchValues: true,
      type: "values",
      group: "customer",
    },
  ],
  cards: [
    {
      value: "id",
      label: "id",
      canFetchValues: false,
      type: "text",
      group: "card",
    },
    {
      value: "scheme",
      label: "Scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "co_scheme",
      label: "Co-scheme",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "category",
      label: "Category",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "type",
      label: "Type",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "iin",
      label: "IIN/BIN",
      canFetchValues: false,
      type: "text",
      group: "card",
    },
    {
      value: "last_4",
      label: "Last 4 digits",
      canFetchValues: false,
      type: "text",
      group: "card",
    },
    {
      value: "country",
      label: "Country",
      canFetchValues: true,
      type: "values",
      group: "card",
    },
    {
      value: "address1",
      label: "Address 1",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "address2",
      label: "Address 2",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "state",
      label: "State",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "zip",
      label: "Zip code",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "city",
      label: "City",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "name",
      label: "Name",
      canFetchValues: false,
      type: "text",
      group: "cardholder",
    },
    {
      value: "metadata.",
      label: "Metadata",
      canFetchValues: false,
      type: "metadata",
      group: "card",
    },
  ],
  subscriptions: [
    {
      value: "id",
      label: "id",
      canFetchValues: false,
      type: "text",
      group: "subscription",
    },
    {
      value: "customer_id",
      label: "Customer id",
      canFetchValues: false,
      type: "text",
      group: "customer",
    },
    {
      value: "plan_id",
      label: "Plan id",
      canFetchValues: false,
      type: "text",
      group: "subscription",
    },
    {
      value: "plan_name",
      label: "Plan name",
      canFetchValues: false,
      type: "text",
      group: "subscription",
    },
    {
      value: "name",
      label: "Name",
      canFetchValues: false,
      type: "text",
      group: "subscription",
    },
    {
      value: "amount",
      label: "Amount",
      canFetchValues: false,
      type: "amount",
      group: "subscription",
    },
    {
      value: "currency",
      label: "Currency",
      canFetchValues: true,
      type: "values",
      group: "subscription",
    },
    {
      value: "canceled",
      label: "Has been canceled",
      canFetchValues: true,
      type: "values",
      group: "subscription",
    },
    {
      value: "active",
      label: "Is active",
      canFetchValues: true,
      type: "values",
      group: "subscription",
    },
    {
      value: "in_trial",
      label: "Is in trial",
      canFetchValues: true,
      type: "values",
      group: "subscription",
    },
    {
      value: "pending_cancellation",
      label: "Pending cancellation",
      canFetchValues: true,
      type: "values",
      group: "subscription",
    },
    {
      value: "activated_at",
      label: "Activation date",
      canFetchValues: false,
      type: "date",
      group: "subscription",
    },
    {
      value: "canceled_at",
      label: "Cancellation date",
      canFetchValues: false,
      type: "date",
      group: "subscription",
    },
    {
      value: "created_at",
      label: "Creation date",
      canFetchValues: false,
      type: "date",
      group: "subscription",
    },
    {
      value: "metadata.",
      label: "Metadata",
      canFetchValues: false,
      type: "metadata",
      group: "subscription",
    },
  ],
}
