import { $Keys } from "utility-types"
import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import QueryCreator from "../util/QueryCreator"
import type { $Dispatcher, $RouterLocation } from "../util/Types"

const Directions = {
  forward: "forward",
  backward: "backward",
}
type Props = {
  reloadFunction: (after: boolean, id: string) => void
  itemsArray: Array<{}>
  hasMore: boolean
  hide: boolean
  location: $RouterLocation
  smallContainer?: boolean
  // if the parent is not large enough
  disableURLUpdate?: boolean
  name?: string
  // use the props name when we have multiple <Pagination/> on the same page
  onPrevious?: () => void
  onNext?: () => void
} & $Dispatcher
type State = {
  prevLocked: boolean
  direction: $Keys<typeof Directions>
}
export class Pagination extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      prevLocked: true,
      direction: Directions.forward,
    }
  }

  componentDidMount() {
    const { name = "" } = this.props
    const afterItem = this.props.location.query[`${name ? `${name}_` : ""}after_item`]
    const beforeItem = this.props.location.query[`${name ? `${name}_` : ""}before_item`]
    this.setState({
      prevLocked: !afterItem && !beforeItem,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { name = "" } = nextProps
    if (nextProps.hide || nextProps.disableURLUpdate) return
    let afterItem = nextProps.location.query[`${name ? `${name}_` : ""}after_item`]
    let beforeItem = nextProps.location.query[`${name ? `${name}_` : ""}before_item`]
    if (!afterItem && !beforeItem)
      this.setState({
        prevLocked: true,
      })

    if (afterItem !== this.props.location.query[`${name ? `${name}_` : ""}after_item`]) {
      if (!afterItem) afterItem = ""
      this.setState({
        direction: Directions.forward,
      })
      this.props.dispatch(this.props.reloadFunction(true, afterItem))
    } else if (beforeItem !== this.props.location.query[`${name ? `${name}_` : ""}before_item`]) {
      if (!beforeItem) beforeItem = ""
      this.setState({
        direction: Directions.backward,
      })
      this.props.dispatch(this.props.reloadFunction(false, beforeItem))
    }
  }

  onPrevious(event) {
    event.preventDefault()
    this.setState({
      direction: Directions.backward,
    })
    this.updateURL(false, this.props.itemsArray[0].id)
    this.props.dispatch(this.props.reloadFunction(false, this.props.itemsArray[0].id))
    if (this.props.onPrevious) this.props.onPrevious()
  }

  onNext(event) {
    event.preventDefault()
    this.setState({
      prevLocked: false,
      direction: Directions.forward,
    })
    this.updateURL(true, this.props.itemsArray[this.props.itemsArray.length - 1].id)
    this.props.dispatch(
      this.props.reloadFunction(true, this.props.itemsArray[this.props.itemsArray.length - 1].id),
    )
    if (this.props.onNext) this.props.onNext()
  }

  updateURL(forward, id) {
    const { name = "" } = this.props
    if (this.props.disableURLUpdate) return
    const { query } = this.props.location
    const nextQuery = forward
      ? {
          [`${name ? `${name}_` : ""}after_item`]: id,
          [`${name ? `${name}_` : ""}before_item`]: "",
        }
      : {
          [`${name ? `${name}_` : ""}before_item`]: id,
          [`${name ? `${name}_` : ""}after_item`]: "",
        }

    this.props.dispatch(push(`${this.props.location.pathname}?${QueryCreator(query, nextQuery)}`))
  }

  render() {
    if (this.props.hide || this.props.itemsArray.length === 0) return null
    const previousEnabled =
      !this.state.prevLocked &&
      (this.state.direction === Directions.forward ||
        (this.state.direction === Directions.backward && this.props.hasMore))
    const nextEnabled =
      this.state.direction === Directions.backward ||
      (this.state.direction === Directions.forward && this.props.hasMore)
    var prev = <span className="disabled">← previous</span>
    if (previousEnabled) var prev = <a onClick={this.onPrevious.bind(this)}>← previous</a>
    var next = <span className="disabled">next &rarr;</span>
    if (nextEnabled) var next = <a onClick={this.onNext.bind(this)}>next &rarr;</a>
    return (
      <div className="row pagination-wrapper text-left">
        <div className={`medium-${this.props.smallContainer ? "5" : "3"} columns`}>
          <div className="row buttons">
            <div className="medium-6 columns">{prev}</div>
            <div className="medium-6 columns text-right">{next}</div>
          </div>
        </div>
        <div className={`medium-${this.props.smallContainer ? "7" : "9"} columns`}>
          <div className="line" />
        </div>
      </div>
    )
  }
}
export default connect()(Pagination)
