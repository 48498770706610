import { put, takeLatest } from "redux-saga/effects"
import { FETCH_BENCHMARK, REQUEST_FEES_BENCHMARK_FETCH } from "./consts"
import type { Action } from "../../../Types/Action"
import { typeFailed } from "^/util/ActionUtils"
type $ActionType = Action & {
  payload: {
    countryList: Array<string>
  }
}

function* retrieveFeesBehcmark(action: $ActionType) {
  const countries = action.payload.countryList

  try {
  } catch (err) {
    yield put({
      type: typeFailed(FETCH_BENCHMARK),
    })
  }
}

export default function* watchFeesBenchmarkRequest(): any {
  yield takeLatest(REQUEST_FEES_BENCHMARK_FETCH, retrieveFeesBehcmark)
}
