import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher, $RouterLocation } from "../../util/Types"
import ContentLayout from "../../pages/ContentLayout"
import PermissionGroup from "./PermissionGroup"
import * as Actions from "./actions"
import type { $PermissionGroupsState } from "./PermissionGroupsReducer"
import Loading from "../../components/Loader"
type Props = {
  permissionsGroupsDetails: $PermissionGroupsState
  location: $RouterLocation
} & $Dispatcher
export class PermissionGroups extends React.Component<Props> {
  addGroup = () => {
    const { dispatch } = this.props
    dispatch(Actions.addPermissionGroup())
  }
  saveGroups = () => {
    const { dispatch } = this.props
    dispatch(Actions.savePermissionGroups())
  }
  saveButtonDisabled = () => {
    const { permissionsGroupsDetails } = this.props
    const hasDuplicate = this.hasDuplicate()

    if (
      hasDuplicate ||
      (!permissionsGroupsDetails.permissionGroups.length &&
        !permissionsGroupsDetails.fetchedState.permissionGroups.length)
    ) {
      return true
    }
  }
  hasDuplicate = () => {
    const { permissionsGroupsDetails } = this.props
    const permissionGroupName = []
    permissionsGroupsDetails.permissionGroups.forEach(permissionGroup => {
      permissionGroupName.push(permissionGroup.name)

      if (permissionGroup.newName) {
        permissionGroupName.push(permissionGroup.newName)
      }
    })
    const hasDuplicate = permissionGroupName.some(
      (val, i) => permissionGroupName.indexOf(val) !== i,
    )
    return hasDuplicate
  }

  render() {
    const { permissionsGroupsDetails } = this.props
    const hasDuplicate = this.hasDuplicate()
    return (
      <ContentLayout title={<h4>Permission groups</h4>}>
        <div className="row small-margin-bottom">
          <div className="medium-12 columns">
            {permissionsGroupsDetails.permissionGroups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(group => (
                <PermissionGroup key={group.id} permissionGroupName={group.name} />
              ))}
          </div>
        </div>
        <div className="row">
          {hasDuplicate && (
            <div className="callout alert medium-4 columns text-left">
              <div>You can not have two permission groups with the same name.</div>
            </div>
          )}
          <div className="medium-2 medium-offset-7 columns">
            <button
              type="button"
              className="small round border button expanded"
              onClick={this.addGroup}
              data-testid="add-button"
            >
              Add new group
            </button>
          </div>
          <div className="medium-3 columns text-right">
            {permissionsGroupsDetails.saving || permissionsGroupsDetails.fetching ? (
              <Loading size={14} />
            ) : (
              <button
                type="button"
                className="round main small button expanded"
                onClick={this.saveGroups}
                disabled={this.saveButtonDisabled()}
                data-testid="save-button"
              >
                Save permission groups
              </button>
            )}
          </div>
        </div>
      </ContentLayout>
    )
  }
}
export default connect(store => ({
  permissionsGroupsDetails: store.permissions.permissionGroups,
}))(withRouter(PermissionGroups))
