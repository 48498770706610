import React from "react"
import { $Declaration, $RoutingRule, $RoutingRulesSettings } from "^/features/RoutingRules/consts"
import { getRoutingRulesChangeDetails } from "^/features/RoutingRules/Utils"
import { DECLARATION_LABELS, getDeclaration, getlabel } from "./utils"
import "./ChangeSummary.scss"

const getRuleNumber = (rule: $RoutingRule, rules: $RoutingRule[]) => {
  return (rules.filter(r => r.declaration === rule.declaration).findIndex(r => rule.id == r.id) + 1)
    .toString()
    .padStart(2, "0")
}

const getRuleName = (rule: $RoutingRule) => {
  return (
    <span className="rule-tag-container">
      {rule.tags.map(tag => (
        <span className="rule-tag" key={tag}>
          {tag}
        </span>
      ))}
    </span>
  )
}

const getChangeElements = (
  declaration: $Declaration,
  routingRulesSettings: $RoutingRulesSettings,
) => {
  const rules = routingRulesSettings.rules.filter(r => r.declaration === declaration)
  const initialRules = routingRulesSettings.initialRules.filter(r => r.declaration === declaration)

  const { ruleAdded, ruleUpdated, filterAdded, filterRemoved, ruleRemoved, positionChanged } =
    getRoutingRulesChangeDetails(rules, initialRules)

  if (
    ruleAdded.length == 0 &&
    ruleUpdated.length == 0 &&
    filterAdded.length === 0 &&
    filterRemoved.length === 0 &&
    ruleRemoved.length === 0 &&
    !positionChanged
  ) {
    return null
  }

  return (
    <div className="section">
      <h2 className="section-title">{getlabel(declaration)}</h2>
      {ruleAdded
        .filter(r => r.declaration === declaration)
        .map((rule, idx) => {
          return (
            <div className="row" key={idx}>
              <span className="rule-number">{getRuleNumber(rule, routingRulesSettings.rules)}</span>{" "}
              <span className="rule-tag">{getRuleName(rule)}</span> rule has been added
            </div>
          )
        })}
      {ruleUpdated
        .filter(rule => rule.declaration === declaration)
        .map((rule, idx) => {
          return (
            <div className="row" key={idx}>
              <span className="rule-number">{getRuleNumber(rule, routingRulesSettings.rules)}</span>
              <span className="rule-tag">{getRuleName(rule)}</span> rule has been updated
            </div>
          )
        })}
      {filterAdded
        .filter(r => r.declaration === declaration)
        .map((rule, idx) => {
          return (
            <div className="row" key={idx}>
              <span className="rule-number">{getRuleNumber(rule, routingRulesSettings.rules)}</span>{" "}
              <span className="rule-tag">{getRuleName(rule)}</span> a new condition has been added
            </div>
          )
        })}
      {filterRemoved
        .filter(r => r.declaration === declaration)
        .map((rule, idx) => {
          return (
            <div className="row" key={idx}>
              <span className="rule-number">{getRuleNumber(rule, routingRulesSettings.rules)}</span>{" "}
              <span className="rule-tag">{getRuleName(rule)}</span> a condition has been removed
            </div>
          )
        })}
      {ruleRemoved
        .filter(r => r.declaration === declaration)
        .map((rule, idx) => {
          return (
            <div className="row" key={idx}>
              <span className="rule-number">
                {getRuleNumber(rule, routingRulesSettings.initialRules)}
              </span>{" "}
              <span className="rule-tag">{getRuleName(rule)}</span> rule has been removed
            </div>
          )
        })}
      {positionChanged && <div className="row order-changed">The rule order changed</div>}
    </div>
  )
}

type Props = {
  routingRulesSettings: $RoutingRulesSettings
}

export const ChangeSummary = ({ routingRulesSettings }: Props) => {
  const sectionNames = []
  const sections = DECLARATION_LABELS.map(label => {
    const declaration = getDeclaration(label)
    const section = getChangeElements(declaration, routingRulesSettings)
    if (section) sectionNames.push(<b>{label}</b>)
    return section
  })

  return (
    <div className="change-summary">
      <div className="heading">
        You have made changes to{" "}
        {sectionNames.slice(1).reduce((previous, current, idx) => {
          return [previous, idx == sectionNames.length - 2 ? " and " : ", ", current]
        }, sectionNames[0])}
      </div>
      {sections}
    </div>
  )
}
