import { FETCH_EVENTS } from "^/Actions/EventsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetched: false,
    fetching: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_EVENTS): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_EVENTS): {
      if (!action.payload.success) {
        return { ...state, fetching: false, fetched: true, events: [], total_count: 0 }
      }

      return { ...state, ...action.payload, fetching: false, fetched: true }
    }

    case typeFailed(FETCH_EVENTS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
