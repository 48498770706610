// @ts-expect-error
import "./boardmenu.scss"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher } from "../../../../util/Types"
import * as BoardsActions from "../actions"
import {
  CANCEL_BOARD_EDITION,
  REQUEST_ADD_TEXT_CHART,
  REQUEST_BOARD_DUPLICATION,
  REQUEST_SAVE_LOCAL_LAYOUT,
} from "../Board/consts"
import { CHANGE_EDIT_BOARD_MODE, REQUEST_BOARD_EDITION } from "../consts"
import type { $BoardDetailsState } from "../Board/reducer"
type Props = {
  params: {
    project: string
    board: string
  }
  boardDetails: $BoardDetailsState
} & $Dispatcher
type State = {
  open: boolean
}

class BoardMenu extends React.Component<Props, State> {
  _menu: any

  constructor() {
    super()
    this.state = {
      open: false,
    }
  }

  componentDidMount(): void {
    document.addEventListener("mousedown", this.handleOutsideClick)
  }

  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.handleOutsideClick)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props, nextContext: any): void {
    if (!nextProps.params.board || nextProps.params.board !== this.props.params.board)
      this.closeMenu()
  }

  handleOutsideClick = (event: Event) => {
    if (this._menu && !this._menu.contains(event.target) && this.state.open) {
      this.setState({
        open: false,
      })
    }
  }
  triggerMenu = () => {
    this.setState({
      open: !this.state.open,
    })
  }
  deleteBoard = (event: Event) => {
    event.preventDefault()
    const { dispatch } = this.props

    if (window.confirm("Deleting a board cannot be undone! You will lose all your charts.")) {
      dispatch(
        BoardsActions.requestBoardDeletion(this.props.params.project, this.props.params.board),
      )
    }

    this.closeMenu()
  }
  duplicateBoard = () => {
    const board = {
      ...this.props.boardDetails.board,
      id: null,
      duplicate_from: this.props.params.board,
    }
    if (!board) return
    this.props.dispatch({
      type: REQUEST_BOARD_DUPLICATION,
      payload: {
        newBoard: board,
        projectId: this.props.params.project,
      },
    })
    this.closeMenu()
  }
  closeMenu = () => {
    this.setState({
      open: false,
    })
  }
  startEdit = () => {
    this.props.dispatch({
      type: CHANGE_EDIT_BOARD_MODE,
      payload: {
        editing: true,
      },
    })
    this.closeMenu()
  }
  onSave = (event: Event) => {
    if (this.props.boardDetails.localLayout) {
      // the board has been edited, we need to update charts position and sizing
      this.props.dispatch({
        type: REQUEST_SAVE_LOCAL_LAYOUT,
        payload: {
          boardId: this.props.boardDetails.board.id,
          charts: this.props.boardDetails.localLayout,
        },
      })
    }

    this.props.dispatch({
      type: REQUEST_BOARD_EDITION,
      payload: {
        newBoard: { ...this.props.boardDetails.board, name: this.props.boardDetails.nameEdition },
      },
    })
  }
  onCancel = (event: Event) => {
    this.props.dispatch({
      type: CANCEL_BOARD_EDITION,
    })
  }
  addText = () => {
    const { dispatch } = this.props
    dispatch({
      type: REQUEST_ADD_TEXT_CHART,
    })
  }

  render() {
    if (!this.props.params.board) return null
    const editingBoard = this.props.boardDetails.editing
    return (
      <div className="row" data-tracking-location="Board menu">
        {this.state.open ? (
          <div
            className="board-menu"
            ref={e => {
              this._menu = e
            }}
          >
            <a data-auto-tracking={true} onClick={this.startEdit} className="board-menu-button">
              Edit
            </a>
            <a data-auto-tracking={true} onClick={this.addText} className="board-menu-button">
              Add text
            </a>
            <a
              data-auto-tracking={true}
              onClick={this.duplicateBoard}
              className="board-menu-button"
            >
              Duplicate
            </a>
            <a
              data-auto-tracking={true}
              onClick={this.deleteBoard}
              className="board-menu-button"
              style={{
                color: "red",
              }}
            >
              Delete
            </a>
          </div>
        ) : null}
        {editingBoard ? (
          <div className="medium-12 columns">
            <div className="row">
              <div className="medium-6 columns">
                <a
                  data-auto-tracking={true}
                  onClick={this.onSave}
                  className="round main small button expanded"
                >
                  Save
                </a>
              </div>
              <div className="medium-6 columns">
                <a
                  data-auto-tracking={true}
                  onClick={this.onCancel}
                  className="round border small button expanded"
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="medium-12 columns text-right">
            <a
              className={`round ${this.state.open ? "main" : "border"} button small`}
              onClick={this.triggerMenu}
            >
              Settings
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default connect(store => ({
  boardDetails: store.analytics_v2.boardDetails,
}))(withRouter(BoardMenu))
