import type { $Action } from "../../util/Types"
import { FETCH_VALUES_FOR_FIELD } from "./consts"
import * as ProcessOut from "../../util/ProcessOut"
export function fetchValues(
  action: $Action & {
    payload: {
      document: string
      field: string
      projectId: string
    }
  },
): $Action {
  return {
    type: FETCH_VALUES_FOR_FIELD,
    payload: ProcessOut.APIcallPromise(
      `/helpers/search/${action.payload.document}?field=${action.payload.field}`,
      "GET",
    ),
  }
}
export const UPDATE_SEARCH_BAR_FILTER = "UPDATE_SEARCH_BAR_FILTER"
export type $UpdateSearchBarFilterAction = {
  type: "UPDATE_SEARCH_BAR_FILTER"
  payload: {
    filter: string
  }
}
export const updateSearchBarFilter = (filter: string): $UpdateSearchBarFilterAction => ({
  type: UPDATE_SEARCH_BAR_FILTER,
  payload: {
    filter,
  },
})
export const UPDATE_SEARCH_BAR_APPLIED_FILTER = "UPDATE_SEARCH_BAR_APPLIED_FILTER"
export type $UpdateSearchBarAppliedFilterAction = {
  type: "UPDATE_SEARCH_BAR_APPLIED_FILTER"
  payload: {
    filter: string
  }
}
export const updateSearchBarAppliedFilter = (
  filter: string,
): $UpdateSearchBarAppliedFilterAction => ({
  type: UPDATE_SEARCH_BAR_APPLIED_FILTER,
  payload: {
    filter,
  },
})
export const RESET_SEARCH_BAR_FILTER = "RESET_SEARCH_BAR_FILTER"
export const resetSerachBarFilter = () => ({
  type: RESET_SEARCH_BAR_FILTER,
})
export const FETCH_RESOURCE_WITH_ID = "FETCH_RESOURCE_WITH_ID"
export const fetchResourceWithId = (id: String) => ({
  type: FETCH_RESOURCE_WITH_ID,
  payload: {
    id,
  },
})
export const START_RESOURCES_SEARCH = "START_RESOURCES_SEARCH"
export const startResourcesSearch = (filter: string) => ({
  type: START_RESOURCES_SEARCH,
  payload: {
    filter,
  },
})
export const WIPE_SEARCH_RESOURCES = "WIPE_SEARCH_RESOURCES"
export const wipeSearchResources = () => ({
  type: WIPE_SEARCH_RESOURCES,
})
export const SET_FILTERING_DOCUMENT = "SET_FILTERING_DOCUMENT"
export const setFilteringDocument = (document: string | null | undefined) => ({
  type: SET_FILTERING_DOCUMENT,
  payload: {
    document,
  },
})
