import uniqid from "uniqid"
import type { $Dimension } from "^/features/analytics/DataExplorer/ChartBuilder/DimensionSelection/consts"
import type { $Filter } from "^/features/analytics/DataExplorer/ChartBuilder/Filters/consts"
import type { $NetMetricType } from "^/features/analytics/DataExplorer/ChartBuilder/NetDropdown"
export type $BuilderMetric = {
  name: string
  description: string
  metrics: Array<$Metric>
  generalFormula: string
  unit: string
  group: string
  forceDimensionPlotting?: string
  forceOrderingByTransactions?: boolean
  allowedResultGrouping: Array<$NetMetricType>
}
export type $ChartBuilderState = {
  selectedMetric: $BuilderMetric
  dimensions: Array<$Dimension>
  filters: Array<$Filter>
  displayInLocalCurrency: boolean
  displayNetMetrics: $NetMetricType | boolean
  canEditIfChartIdExists?: boolean
  type?: string
  metrics?: $Metric[]
}
export const FRICTIONLESS_RATE: $BuilderMetric = {
  name: "3DS frictionless rate",
  description: "",
  unit: "percentage",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "payment_type",
          operand: "==",
          value: ["three-d-s-2"],
        },
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["three_d_s_check"],
        },
        {
          id: uniqid(),
          path: "three_d_s.challenged",
          operand: "==",
          value: [false],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "payment_type",
          operand: "==",
          value: ["three-d-s-2"],
        },
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["three_d_s_check"],
        },
      ],
      name: "B",
      path: "transaction_operations",
      type: "count",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw", "net_router"],
}
export const AUTHORIZED_TRANSACTIONS_METRIC: $BuilderMetric = {
  name: "Authorized transactions",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const CAPTURED_TRANSACTIONS_METRIC: $BuilderMetric = {
  name: "Captured transactions",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw"],
}
export const CONVERSION_RATE_METRIC: $BuilderMetric = {
  name: "Conversion rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      path: "transaction_operations",
      type: "count",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["request"],
        },
      ],
      name: "B",
      path: "transaction_operations",
      type: "count",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw", "net"],
}
export const TRANSACTION_AMOUNT_METRIC: $BuilderMetric = {
  name: "Transaction amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const FAILED_TRANSACTIONS_METRIC: $BuilderMetric = {
  name: "Failed authorizations",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "has_failed",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const FAILED_CAPTURES_METRIC: $BuilderMetric = {
  name: "Failed captures",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "has_failed",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net"],
}
export const AUTHORIZED_AMOUNT_METRIC: $BuilderMetric = {
  name: "Authorized amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw"],
}
export const AUTHORIZATION_RATE_METRIC: $BuilderMetric = {
  name: "Authorization rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
      ],
      name: "B",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const AVERAGE_BASKET_METRIC: $BuilderMetric = {
  name: "Average captured basket",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "average",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw"],
}
export const CAPTURED_AMOUNT_METRIC: $BuilderMetric = {
  name: "Captured amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw"],
}
export const REFUNDED_AMOUNT_METRIC: $BuilderMetric = {
  name: "Refunded amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["refund"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw"],
}
export const REFUNDED_TRANSACTIONS_METRIC: $BuilderMetric = {
  name: "Number of refunds",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["refund"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw"],
}
export const FAILED_REFUNDS_METRIC: $BuilderMetric = {
  name: "Failed refunds",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["refund"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [false],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw"],
}
export const FAILED_AMOUNT_METRIC: $BuilderMetric = {
  name: "Failed amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "has_failed",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw", "net"],
}
export const REFUND_RATE_METRIC: $BuilderMetric = {
  name: "Refund rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["refund"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "B",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw"],
}
export const CHARGEBACK_AMOUNT_METRIC: $BuilderMetric = {
  name: "Chargeback amount",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["chargeback"],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A",
  group: "Amounts",
  allowedResultGrouping: ["raw"],
}
export const CHARGEBACK_NUMBERS_METRIC: $BuilderMetric = {
  name: "Number of chargebacks",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["chargeback"],
        },
        {
          id: uniqid(),
          path: "is_last_operation",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw"],
}
export const TRANSACTIONS_COUNT_METRIC: $BuilderMetric = {
  name: "Number of transactions",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "is_last_operation",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net"],
}
export const OPERATIONS_COUNT_METRIC: $BuilderMetric = {
  name: "Number of operations",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const AUTHORIZATIONS_COUNT_METRIC: $BuilderMetric = {
  name: "Number of authorization requests",
  description: "",
  unit: "count",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A",
  group: "Number of transactions",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const FEES_AMOUNT_METRIC: $BuilderMetric = {
  name: "Paid in fees",
  description: "",
  unit: "amount",
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "gateway_fee",
          operand: "is-not-null",
          value: [],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.gateway_fee",
    },
  ],
  generalFormula: "A",
  group: "Fees performance",
  allowedResultGrouping: ["raw"],
}
export const FEES_RATE_METRIC: $BuilderMetric = {
  name: "Fees rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "gateway_fee",
          operand: "is-not-null",
          value: [],
        },
      ],
      name: "A",
      type: "sum",
      path: "transaction_operations.gateway_fee",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "gateway_fee",
          operand: "is-not-null",
          value: [],
        },
      ],
      name: "B",
      type: "sum",
      path: "transaction_operations.amount",
    },
  ],
  generalFormula: "A / B",
  group: "Fees performance",
  allowedResultGrouping: ["raw"],
}
export const CHARGEBACK_RATE_PER_VOLUME_METRIC: $BuilderMetric = {
  name: "Chargeback rate / volume",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["chargeback"],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "B",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw"],
}
export const CHARGEBACK_RATE_PER_AMOUNT_METRIC: $BuilderMetric = {
  name: "Chargeback rate / amount",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["chargeback"],
        },
      ],
      name: "A",
      // FIXME: type should not be "- sum", should be "sum"
      // @ts-ignore
      type: "- sum",
      path: "transaction_operations.amount_local",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["capture"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
      ],
      name: "B",
      type: "sum",
      path: "transaction_operations.amount_local",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw"],
}
export const DECLINE_RATE_METRIC: $BuilderMetric = {
  name: "Decline rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [false],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
      ],
      name: "B",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw", "net_router", "net"],
}
export const THREE_D_S_TRIGGER_RATE: $BuilderMetric = {
  name: "3DS trigger rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["three_d_s_check", "three_d_s_2_check"],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
      name: "A",
      type: "count",
      path: "transaction_operations",
    },
    {
      id: uniqid(),
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["request"],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "is_card_payment",
          operand: "==",
          value: [true],
        },
      ],
      name: "B",
      type: "count",
      path: "transaction_operations",
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw", "net"],
}
export const THREE_D_S_SUCCESS_RATE_METRIC: $BuilderMetric = {
  name: "3DS success rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      name: "A",
      path: "transaction_operations",
      type: "count",
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["three_d_s_check", "three_d_s_2_check"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
    },
    {
      id: uniqid(),
      name: "B",
      path: "transaction_operations",
      type: "count",
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["three_d_s_check", "three_d_s_2_check"],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw"],
}
export const RETRY_SUCCESS_RATE_METRIC: $BuilderMetric = {
  name: "Retry success rate",
  description: "",
  unit: "percentage",
  forceOrderingByTransactions: true,
  metrics: [
    {
      id: uniqid(),
      name: "A",
      type: "count",
      path: "transaction_operations",
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "is_successful",
          operand: "==",
          value: [true],
        },
        {
          id: uniqid(),
          path: "authorization_retry_attempt",
          operand: ">",
          value: [1],
        },
      ],
    },
    {
      id: uniqid(),
      name: "B",
      type: "count",
      path: "transaction_operations",
      filters: [
        {
          id: uniqid(),
          path: "type",
          operand: "==",
          value: ["authorization"],
        },
        {
          id: uniqid(),
          path: "authorization_retry_attempt",
          operand: ">",
          value: [1],
        },
        {
          id: uniqid(),
          path: "is_last_payment_attempt",
          operand: "==",
          value: [true],
        },
      ],
    },
  ],
  generalFormula: "A / B",
  group: "Payment performance",
  allowedResultGrouping: ["raw"],
}
export const BUILDER_METRICS: Array<$BuilderMetric> = [
  AUTHORIZED_TRANSACTIONS_METRIC,
  TRANSACTION_AMOUNT_METRIC,
  AUTHORIZED_AMOUNT_METRIC,
  CONVERSION_RATE_METRIC,
  AUTHORIZATION_RATE_METRIC,
  FAILED_TRANSACTIONS_METRIC,
  AVERAGE_BASKET_METRIC,
  CAPTURED_AMOUNT_METRIC,
  REFUNDED_AMOUNT_METRIC,
  FAILED_AMOUNT_METRIC,
  REFUND_RATE_METRIC,
  CHARGEBACK_AMOUNT_METRIC,
  TRANSACTIONS_COUNT_METRIC,
  FEES_AMOUNT_METRIC,
  FEES_RATE_METRIC,
  CHARGEBACK_RATE_PER_VOLUME_METRIC,
  CHARGEBACK_RATE_PER_AMOUNT_METRIC,
  DECLINE_RATE_METRIC,
  THREE_D_S_TRIGGER_RATE,
  RETRY_SUCCESS_RATE_METRIC,
  CAPTURED_TRANSACTIONS_METRIC,
  REFUNDED_TRANSACTIONS_METRIC,
  FAILED_REFUNDS_METRIC,
  THREE_D_S_SUCCESS_RATE_METRIC,
  CHARGEBACK_NUMBERS_METRIC,
  OPERATIONS_COUNT_METRIC,
  AUTHORIZATIONS_COUNT_METRIC,
  FAILED_CAPTURES_METRIC,
  FRICTIONLESS_RATE,
]
export const ALERTING_BUILDER_METRICS: Array<$BuilderMetric> = [
  AUTHORIZED_TRANSACTIONS_METRIC,
  TRANSACTION_AMOUNT_METRIC,
  AUTHORIZED_AMOUNT_METRIC,
  CONVERSION_RATE_METRIC,
  AUTHORIZATION_RATE_METRIC,
  FAILED_TRANSACTIONS_METRIC,
  AVERAGE_BASKET_METRIC,
  CAPTURED_AMOUNT_METRIC,
  { ...REFUNDED_AMOUNT_METRIC, forceDimensionPlotting: "refunded_at" },
  FAILED_AMOUNT_METRIC,
  REFUND_RATE_METRIC,
  { ...CHARGEBACK_AMOUNT_METRIC, forceDimensionPlotting: "chargedback_at" },
  { ...CHARGEBACK_RATE_PER_VOLUME_METRIC, forceDimensionPlotting: "chargedback_at" },
  { ...CHARGEBACK_RATE_PER_AMOUNT_METRIC, forceDimensionPlotting: "chargedback_at" },
  TRANSACTIONS_COUNT_METRIC,
  FEES_AMOUNT_METRIC,
  FEES_RATE_METRIC,
  DECLINE_RATE_METRIC,
  THREE_D_S_TRIGGER_RATE,
  RETRY_SUCCESS_RATE_METRIC,
  CAPTURED_TRANSACTIONS_METRIC,
  REFUNDED_TRANSACTIONS_METRIC,
  FAILED_REFUNDS_METRIC,
  THREE_D_S_SUCCESS_RATE_METRIC,
  CHARGEBACK_NUMBERS_METRIC,
  OPERATIONS_COUNT_METRIC,
  AUTHORIZATIONS_COUNT_METRIC,
  FAILED_CAPTURES_METRIC,
  FRICTIONLESS_RATE,
]
export type $MetricType = "sum" | "count" | "average" | "cardinality"
export type $Metric = {
  id: string
  name: string
  type: $MetricType
  path: string
  filters: Array<$Filter>
  display_in_local_currency?: boolean
}
export const UPDATE_METRICS = "UPDATE_METRICS"
export const SelectStyle = {
  valueContainer: (provided: any) => ({ ...provided, paddingLeft: ".5em" }),
  container: (provided: any) => ({ ...provided, position: "relative", borderRadius: "0" }),
  control: (provided: any) => ({ ...provided, borderRadius: 0 }),
  placeholder: (provided: any) => ({ ...provided, color: "#757575" }),
}
