import React from "react"
import type { Customer } from "../../Types/Customer"
import CountryFlag from "../CountryFlag/CountryFlag"
type Props = {
  customer: Customer
  isFirst: boolean // is first of its category
}

class CustomerItem extends React.Component<Props> {
  render() {
    const { customer, isFirst } = this.props
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          {isFirst && (
            <div className="row collapse item-search-category">
              <div className="medium-12 columns">Customers</div>
            </div>
          )}
          <div className="row collapse item-resource-row highlight">
            <div className="medium-12 columns">
              <div className="row collapse">
                <div className="medium-2 columns">
                  <div
                    style={{
                      maxWidth: "50px",
                    }}
                  >
                    <CountryFlag country_code={customer.country_code} />
                  </div>
                </div>
                <div
                  className="medium-10 columns"
                  style={{
                    position: "relative",
                    top: ".25em",
                  }}
                >
                  <div className="row collapse">
                    <div className="medium-12 columns">
                      {customer.first_name} {customer.last_name}
                    </div>
                  </div>
                  <div className="row collapse">
                    <div className="medium-12 columns greyed font-console">{customer.email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerItem
