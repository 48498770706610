import { Responsive, WidthProvider } from "react-grid-layout"
import React from "react"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import { connect } from "react-redux"
import uniqid from "uniqid"
import type { $Dispatcher, $State } from "../../../util/Types"
import type { $Board, $ChartDisplay, $DataPoint } from "../Boards/consts"
import { MAP_CHART, SINGLE_VALUE, TEXT_CHART } from "../Boards/consts"
import Chart from "../Boards/charts/Chart"
import { SET_LOCAL_LAYOUT } from "../Boards/Board/consts"
const ResponsiveGridLayout = WidthProvider(Responsive)
type Props = {
  boardDetails: {
    editing: boolean
    board: $Board
    selector_datapoints: Array<$DataPoint>
  } & $State
  analyticsParams: {
    timeCompare: {
      comparing: boolean
    }
  }
  charts: any
  // map<string, chart>
  boardEditModeOn: boolean
  params: {
    project: string
  }
  keysMask: Array<String>
  colors:
    | Array<{
        key: string
        color: string
      }>
    | null
    | undefined
} & $Dispatcher
type State = {
  charts: Map<string, string>
  currentLayout: any
}

class BoardGrid extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      charts: new Map(),
      currentLayout: null,
    }
  }

  componentDidMount() {
    if (!this.props.boardDetails.fetched && !this.props.boardDetails.error)
      this.resetChartsIds(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!this.props.boardDetails.fetched && !this.props.boardDetails.error) {
      this.resetChartsIds(nextProps)
    }
  }

  resetChartsIds = (props: Props, specificId?: string) => {
    if (!props.boardDetails.fetched || props.boardDetails.error) return
    const charts: Map<string, string> = props.boardDetails.board.charts.reduce(
      (result: Map<string, string>, chart) => {
        if (specificId) {
          result.set(chart.id, `${chart.id}${chart.id === specificId ? uniqid() : ""}`)
          return result
        }

        result.set(chart.id, chart.id)
        return result
      },
      this.state.charts,
    )
    this.setState({
      charts,
    })
  }
  updateCurrentLayout = (newLayout: any) => {
    this.props.dispatch({
      type: SET_LOCAL_LAYOUT,
      payload: {
        localLayout: { ...newLayout },
      },
    })
  }

  render() {
    const { boardDetails, charts, colors } = this.props
    if (!boardDetails.fetched || boardDetails.error || !charts) return null
    // loop over every chart to create the grid
    // layout is an array of objects, see the demo for more complete usage
    const layout = Object.keys(charts)
      .map(key => charts[key])
      .filter(chart => !chart.selectedForDeletion)
      .map((chart: $ChartDisplay, index: number) => {
        // First checking if the chart has data or not
        let isEmpty = false

        if (chart.is_comparison) {
          if (chart.data.reduce((value, item) => item.datapoints.length === 0 && value, true)) {
            isEmpty = true
          }
        } else if (chart.data instanceof Object && Object.keys(chart.data).length === 0) {
          isEmpty = true
        }

        // Then computing the display height
        const isCompared =
          chart.is_comparison ||
          this.props.analyticsParams.timeCompare.comparing ||
          boardDetails.selector_datapoints
        // Computing maximum height
        const maxHeight =
          !isEmpty && chart.type === SINGLE_VALUE && !isCompared && chart.data instanceof Array
            ? 3
            : chart.type === MAP_CHART
            ? 12
            : 6
        return {
          i: chart.id,
          x: chart.position_x,
          y: chart.position_y,
          w: chart.size,
          h: isCompared ? 6 : chart.height || 6,
          minH: chart.type === TEXT_CHART ? 3 : maxHeight,
          maxH: maxHeight,
          minW: 3,
          maxW: 12,
          isDraggable: boardDetails.editing,
          isResizable: boardDetails.editing,
        }
      })
    // For Firefox and IE we disable the use of css transforms
    // $FlowFixMe
    const isFirefox = typeof InstallTrigger !== "undefined"
    const isIE =
      /* @cc_on!@ */
      false || !!document.documentMode
    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={{
          lg: layout,
        }}
        cols={{
          lg: 12,
          md: 0,
          sm: 0,
          xs: 0,
          xxs: 0,
        }}
        breakpoints={{
          lg: 1,
          md: 0,
          sm: 0,
          xs: 0,
          xxs: 0,
        }}
        rowHeight={30}
        margin={[30, 17]}
        containerPadding={[0, 0]}
        useCSSTransforms={!isFirefox && !isIE}
        compactType="vertical"
        onLayoutChange={newLayout => {
          this.updateCurrentLayout(newLayout)
        }}
        onResizeStop={(
          newLayout,
          oldItem: {
            i: string
          },
        ) => {
          this.resetChartsIds(this.props, oldItem.i)
        }}
      >
        {boardDetails.board.charts
          .filter(
            chart =>
              Object.keys(charts)
                .map(key => charts[key])
                .findIndex(c => c.id === chart.id && !c.selectedForDeletion) > -1,
          )
          .map((chart: $ChartDisplay) => (
            <div
              data-tracking-location="Chart"
              key={chart.id}
              style={{
                maxHeight: "500px",
              }}
            >
              <Chart
                key={`render-${this.state.charts.get(chart.id) || ""}`}
                chart={this.props.charts[chart.id]}
                fetched={this.props.charts[chart.id].fetched}
                board={boardDetails}
                project={this.props.params.project}
                colors={colors}
                canDelete={!boardDetails.board.id.includes("board_default")}
                canEdit={!boardDetails.board.id.includes("board_default")}
                keysMask={this.props.keysMask}
              />
              {this.props.boardDetails.editing ? (
                <div
                  style={{
                    overflow: "visible",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    cursor: "move",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow:
                        "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
                      background:
                        "repeating-linear-gradient(45deg, rgba(96, 109, 188, 0.2), rgba(96, 109, 188, 0.2) 10px, rgba(150, 159, 200, 0.2) 10px, rgba(150, 159, 200, 0.2) 20px)",
                      opacity: 0.5,
                      borderRadius: "4px",
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))}
      </ResponsiveGridLayout>
    )
  }
}

const mapStateToProps = store => ({
  boardDetails: store.analytics_v2.boardDetails,
  charts: store.charts,
  analyticsParams: store.analytics.params,
})

export default connect(mapStateToProps)(BoardGrid)
