import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../../util/Types"
import Error from "../../../components/Error"
import Loading from "../../../components/Loader"
import type { $BoardsState } from "./reducer"
import ContentLayout from "../../../pages/ContentLayout"
import NotPermitted from "../../Permissions/NotPermitted"
type Props = {
  boards: $BoardsState
  location: any
} & $Dispatcher
/**
 * Root containers for analytics
 */

class BoardsContainer extends React.Component {
  props: Props

  render() {
    const { location, boards, children, params } = this.props
    const error = boards.error ? boards.error.notPermitted ? <NotPermitted /> : <Error /> : null
    const loader = boards.fetching ? <Loading /> : null
    return (
      <div>
        {error ? <ContentLayout title={<h4>Analytics</h4>}>{error}</ContentLayout> : null}
        {loader}
        {boards.fetched && !boards.error ? children : null}
      </div>
    )
  }
}

export default connect(store => ({
  boards: store.analytics_v2.boards,
  fetchParams: store.analytics.params,
}))(BoardsContainer)
