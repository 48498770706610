import React from "react"
import Select from "react-select"
import type { $GatewayConfiguration } from "../../PaymentProviders/consts"
import type { $Option } from "../../analytics/DataExplorer/consts"
type Props = {
  gateways: Array<string>
  onChange: (gateways: Array<string>) => void
  configurations: Array<$GatewayConfiguration>
}

class SmartRoutingSelector extends React.Component<Props> {
  onChange = (newValues: $Option) => {
    const { onChange } = this.props

    if (newValues instanceof Array) {
      if (newValues.findIndex(v => v.value === "all") > -1) onChange(["all"])
      else if (newValues.length > 0) onChange(newValues.map(v => v.value))
      else onChange(["all"])
    } else if (newValues) {
      onChange([newValues.value])
    } else {
      onChange(["all"])
    }
  }

  render() {
    const { gateways, configurations } = this.props
    const options = [
      {
        label: "Enabled gateway configurations",
        value: "all",
      },
    ].concat(
      configurations
        .filter(c => c.enabled)
        .map(c => ({
          label: c.name,
          value: c.id,
        })),
    )
    const values = gateways.map(g => {
      const correspondingGateway = options.find(c => c.value === g)
      return {
        value: g,
        label: correspondingGateway ? correspondingGateway.label : g,
      }
    })
    return (
      <div
        className="labeled-field"
        style={{
          width: "75%",
        }}
      >
        <Select
          className="react-select"
          options={options}
          onChange={this.onChange}
          isClearable={false}
          value={values}
          closeMenuOnSelect={false}
          isMulti={values.findIndex(v => v.value === "all") === -1}
        />
      </div>
    )
  }
}

export default SmartRoutingSelector
