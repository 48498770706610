import React from "react"
type Props = {
  type: "authorization" | "chargeback"
}

class PaymentsOK extends React.PureComponent<Props> {
  render() {
    return (
      <div className="row margin-bottom">
        <div className="medium-12 columns text-center greyed bold">
          Your benchmarked {this.props.type === "authorization" ? "payment" : "chargeback"}{" "}
          performance is all good!
        </div>
      </div>
    )
  }
}

export default PaymentsOK
