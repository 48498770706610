import React, { useCallback, useState } from "react"
import { FormattedNumber } from "react-intl"
import { isoCountries } from "^/util/ISOCountries"
import { $CurrentProject } from "^/util/Types"
import AccordionTable from "../components/AccordionTable"
import { $Column, $SortDirection } from "../components/Table"
import { $Profile } from "../consts"
import { displayValueFromDictionary } from "../dictionary"
import { setFirstLetterToUppercase, sortAsc, sortDesc } from "../util"
import DiagnosticProfile from "./DiagnosticProfile"
import "./DiagnosticsTable.scss"

type Props = {
  tableData: $Profile[]
  currentProject: $CurrentProject
}

export default function DiagnosticsTable({ tableData, currentProject }: Props) {
  const [sortedDatas, setSortedTableDatas] = useState([...tableData])

  const currency = currentProject?.project?.default_currency
  const pattern_name = /^.*?(?= of)/
  const columnsData: $Column<$Profile>[] = [
    {
      id: "drop_type_id",
      header: "DROP TYPE",
      renderCell: field =>
        setFirstLetterToUppercase(
          displayValueFromDictionary(field.profile.op_type.match(pattern_name)[0]),
        ) ?? "",
    },
    {
      id: "amount_impacted_id",
      header: "AMOUNT IMPACTED",
      renderCell: field => (
        <FormattedNumber value={field.amount_to_gain} style="currency" currency={currency} />
      ),
      align: "right",
    },
    {
      id: "number_transactions_id",
      header: "NO. OF TRANSACTION",
      renderCell: field => <FormattedNumber value={field.transaction_count} />,
      align: "right",
    },
    {
      id: "percentage_volume_id",
      header: "TOTAL VOLUME SHARE",
      renderCell: field => field.profile.amount_bucket,
    },
    {
      id: "main_issuer_id",
      header: "MAIN ISSUER",
      renderCell: field => field.profile.issuer_name,
    },
  ]
  const onSort = useCallback(
    (direction: $SortDirection, id: string) => {
      let sortedProducts: Array<$Profile> = [...sortedDatas]

      switch (id) {
        case "drop_type_id":
          sortedProducts.sort(
            direction === "asc"
              ? (a, b) => {
                  if (a.profile.op_type > b.profile.op_type) {
                    return 1
                  } else if (a.profile.op_type < b.profile.op_type) {
                    return -1
                  }
                  return 0
                }
              : (a, b) => {
                  if (a.profile.op_type < b.profile.op_type) {
                    return 1
                  } else if (a.profile.op_type > b.profile.op_type) {
                    return -1
                  }
                  return 0
                },
          )
          break

        case "amount_impacted_id":
          sortedProducts.sort(
            direction === "asc" ? sortAsc("amount_to_gain") : sortDesc("amount_to_gain"),
          )
          break

        case "number_transactions_id":
          sortedProducts.sort(
            direction === "asc" ? sortAsc("transaction_count") : sortDesc("transaction_count"),
          )
          break

        case "percentage_volume_id":
          sortedProducts.sort(
            direction === "asc"
              ? (a, b) => {
                  if (a.profile.amount_bucket > b.profile.amount_bucket) {
                    return 1
                  } else if (a.profile.amount_bucket < b.profile.amount_bucket) {
                    return -1
                  }
                  return 0
                }
              : (a, b) => {
                  if (a.profile.amount_bucket < b.profile.amount_bucket) {
                    return 1
                  } else if (a.profile.amount_bucket > b.profile.amount_bucket) {
                    return -1
                  }
                  return 0
                },
          )
          break

        case "main_issuer_id":
          sortedProducts.sort(
            direction === "asc"
              ? (a, b) => {
                  if (a.profile.issuer_name > b.profile.issuer_name) {
                    return 1
                  } else if (a.profile.issuer_name < b.profile.issuer_name) {
                    return -1
                  }
                  return 0
                }
              : (a, b) => {
                  if (a.profile.issuer_name < b.profile.issuer_name) {
                    return 1
                  } else if (a.profile.issuer_name > b.profile.issuer_name) {
                    return -1
                  }
                  return 0
                },
          )
          break
      }

      setSortedTableDatas(sortedProducts)
    },
    [sortedDatas, setSortedTableDatas],
  )

  const expandableContent = (profile: $Profile) => {
    return <DiagnosticProfile profile={profile} />
  }

  return (
    <div data-tracking-location="Profiles">
      <AccordionTable
        columns={columnsData}
        datas={sortedDatas}
        sort={onSort}
        getExpandedContent={expandableContent}
        name="Anomaly detection Profiles"
      />
    </div>
  )
}
