import React from "react"
type Props = {
  value: string | null | undefined
  onChange: (arg0: string) => void
}

class TextFilter extends React.Component<Props> {
  render() {
    const { value, onChange } = this.props
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          <input
            type="text"
            value={value}
            onChange={event => {
              onChange(event.target.value)
            }}
            placeholder="Is equal to..."
          />
        </div>
      </div>
    )
  }
}

export default TextFilter
