import React from "react"
import type { $Check } from "../../Types/Transaction"
type Props = {
  title: string | null | undefined
  check: $Check
}
export class AvsCheck extends React.Component<Props> {
  render() {
    return (
      <div className="popup-avs-checks">
        <span>{this.props.title}</span>
        <span>{this.props.check}</span>
      </div>
    )
  }
}
export default AvsCheck
