import React from "react"
import { connect } from "react-redux"
import * as EventsActions from "../Actions/EventsActions"
import { loadTransactionByID } from "../Actions/EventsTransactionActions"
import Empty from "../components/Empty"
import Error from "../components/Error"
import Loader from "../components/Loader"
import Pagination from "../components/Pagination"
import POEvent from "../components/POEvent"
import NotPermitted from "../features/Permissions/NotPermitted"
import Webhooks from "../features/Webhooks/Webhooks"
import ContentLayout from "./ContentLayout"
const EVENTS_PER_PAGE = 30
type State = {
  transactionId: string | null | undefined
  searchMode: boolean
}
export class Events extends React.Component<State> {
  constructor() {
    super()
    this.state = {
      transactionId: "",
      searchMode: false,
    }
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Events | ProcessOut"
    const firstItem = location.query.events_after_item || ""
    dispatch(EventsActions.loadEvents(EVENTS_PER_PAGE, true, firstItem))
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const firstItem = this.props.location.query.events_after_item || ""

    if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
      this.props.dispatch(EventsActions.loadEvents(EVENTS_PER_PAGE, true, firstItem))
    }
  }

  getTransactionById = event => {
    event.preventDefault()
    this.setState({
      searchMode: true,
    })
    const { transactionId } = this.state

    if (transactionId) {
      this.props.dispatch(loadTransactionByID(transactionId))
    } else {
      this.resetEvents()
    }
  }
  getEvents = event =>
    event.map(poevent => (
      <div key={poevent.id}>
        <POEvent event={poevent} />
      </div>
    ))
  handleChange = event => {
    const { value } = event.target
    this.setState({
      transactionId: value,
      searchMode: false,
    })

    if (!value) {
      this.resetEvents()
    }
  }
  resetEvents = () => {
    this.setState({
      transactionId: "",
      searchMode: false,
    })
  }

  render() {
    const { details, transactions } = this.props
    const { transactionId, searchMode } = this.state
    const { events } = this.props.details
    let content

    if ((details.fetching || !details.fetched) && !details.error) {
      content = <Loader />
    } else if (!details.error) {
      var empty
      const header = (
        <div
          className="row capitalize greyed bold small-margin-bottom"
          style={{
            fontSize: "0.8em",
          }}
        >
          <div className="medium-6 columns">Event name</div>
          <div className="medium-6 columns text-right">Date</div>
        </div>
      )

      if (events.length === 0) {
        empty = <Empty text="No event has been fired yet." />
      } else if (transactions.fetched && !transactions.events.length && searchMode) {
        empty = <Empty text="Transaction ID does not match" />
        content = null
      } else if (transactions.fetched && transactions.events.length && searchMode) {
        content = (
          <div>
            {header}
            {this.getEvents(transactions.events)}
          </div>
        )
      } else {
        content = (
          <div>
            {header}
            {this.getEvents(events)}
          </div>
        )
      }
    }

    return (
      <ContentLayout title={<h4>Events</h4>}>
        {details.error ? (
          details.error.notPermitted ? (
            <NotPermitted />
          ) : (
            <Error />
          )
        ) : (
          <div>
            <div className="row big-margin-bottom">
              <Webhooks />
            </div>

            <hr
              style={{
                marginBottom: "3em",
              }}
            />

            <form id="transaction-form" onSubmit={this.getTransactionById}>
              <div className="text-right large-6 medium-8 row">
                <div className="large-8 medium-9 columns">
                  <input
                    type="text"
                    name="transaction_id"
                    id="reset-id-input"
                    className="bottom-border"
                    placeholder="Enter transaction ID: tr_"
                    value={transactionId}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="large-2 medium-3 columns text-left">
                  <button
                    type="submit"
                    className="button expanded small round border button-search"
                    style={{ paddingInline: "0" }}
                  >
                    Search
                  </button>
                </div>
                <div className="large-2 medium-9 columns text-left">
                  <button
                    type="button"
                    className="button expanded small round border button-reset"
                    style={{ paddingInline: "0" }}
                    onClick={this.resetEvents}
                    disabled={!searchMode}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>

            <div className="row" />

            <div className="row big-margin-bottom">
              <div className="large-12 columns">
                {empty}
                {content}
              </div>
            </div>
            <Pagination
              name="events"
              reloadFunction={EventsActions.loadEvents.bind(null, EVENTS_PER_PAGE)}
              itemsArray={this.props.details.fetched ? events : []}
              hasMore={this.props.details.fetched ? this.props.details.has_more : false}
              location={this.props.location}
              hide={!this.props.details.fetched || this.props.details.fetching}
            />
          </div>
        )}
      </ContentLayout>
    )
  }
}
export default connect(store => ({
  details: store.events,
  transactions: store.eventsTransaction,
}))(Events)
