import React from "react"
type Props = {
  size?: number
  spinnerClassName?: string
}
export default class Loading extends React.Component<Props> {
  static defaultProps = {
    size: 32,
    spinnerClassName: "LoadingSpinner",
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { size, spinnerClassName } = this.props
    return (
      <div
        data-testid="loader"
        className="loader opacity0-8"
        style={{
          textAlign: "center",
          marginTop: size,
        }}
      >
        <div
          className={spinnerClassName}
          style={{
            fontSize: size,
          }}
        />
      </div>
    )
  }
}
