import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import React, { useContext } from "react"
import { GatewayInformation } from "^/vNext/@types/types"
import { Button } from "^/vNext/Button"
import { Option, Select } from "^/vNext/Select/Select"
import { GatewayContext } from "../GatewayContext"
import { RoutingContext } from "../RoutingContext"
import { ReactComponent as SvgIcon } from "./caret-icon.svg"
import uniqid from "uniqid"
import { useDefaultGatewayOptions } from "../hooks"
import { colors } from "../_variables"
import { SwitchToggle } from "^/vNext/SwitchToggle/SwitchToggle"
import { isValidGateway } from "../utils"
import InvalidGatewayErrorSection from "^/vNext/RoutingRules/InvalidGatewayErrorSection"

export type GatewaySelectionProps = {
  authorizations: GatewayInformation[]
  hasSmartRouting: boolean
  auth: GatewayInformation
  onGatewayChanged: (auth: GatewayInformation) => void
  onRemoveGateway: () => void
}

export const GatewaySelection = ({
  authorizations,
  hasSmartRouting,
  auth,
  onGatewayChanged,
  onRemoveGateway,
}: GatewaySelectionProps) => {
  const options = useDefaultGatewayOptions()
  const { gatewayNames, gatewayConfigurations } = useContext(GatewayContext)
  const isSmartRouting = auth.gateway === "processout"

  if (isSmartRouting) {
    options.push({
      value: "all",
      label: "Enable gateway configurations",
    })
    const value = auth.configurations.map(config => {
      const option = options.find(c => c.value === config)
      return {
        value: config,
        label: option?.label || config,
      }
    })
    // If "all" is not selected, this allows smart routing to select subset of gateways
    const isMulti = !auth.configurations.includes("all")

    return (
      <>
        <SwitchToggle
          onChange={() => {
            const newAuth: GatewayInformation = {
              id: auth.id,
              gateway: options[0] ? options[0].value : "",
            }
            onGatewayChanged(newAuth)
          }}
          checked={isSmartRouting}
          labelText="Enable Smart routing"
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
        />
        <Select
          isMulti={isMulti}
          options={options}
          value={value}
          onChange={(change: Option) => {
            let config = []
            if (Array.isArray(change)) {
              if (change.findIndex(v => v.value === "all") > -1) config = ["all"]
              else if (change.length > 0) config = change.map(v => v.value)
              else config = ["all"]
            } else if (change) {
              config = [change.value]
            } else {
              config = ["all"]
            }
            onGatewayChanged({
              id: auth.id,
              configurations: config,
              gateway: "processout",
            })
          }}
        ></Select>
        <Button
          aria-label="Remove smart routing"
          data-auto-tracking="true"
          data-tracking-label={"Remove smart routing"}
          style={{ padding: 0, marginTop: 8 }}
          variant="tertiary"
          className="rule-condition__deleteBtn"
          onClick={() => onRemoveGateway()}
        >
          Remove smart routing
        </Button>
      </>
    )
  } else {
    const candidates = options.filter(option => {
      // We do not want to include gateways that are selected in other authorization blocks in this rule already
      return !authorizations.find(info => info.gateway === option.value)
    })

    const isGatewayInvalid = !isValidGateway(auth, gatewayConfigurations)

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            marginTop: 12,
            marginBottom: 12,
            color: hasSmartRouting ? "#CCCCCC" : "#000000",
          }}
        >
          <SwitchToggle
            // Only allow turn smart routing on when it is available, you can only have one smart routing
            disabled={hasSmartRouting}
            onChange={() => {
              const newAuth: GatewayInformation = {
                id: auth.id,
                gateway: "processout",
                configurations: ["all"],
              }
              onGatewayChanged(newAuth)
            }}
            checked={isSmartRouting}
            labelText="Enable Smart routing"
          />
        </div>
        <Select
          options={candidates}
          value={{
            label: gatewayNames[auth.gateway],
            value: auth.gateway,
          }}
          onChange={({ value }: Option) => {
            const newAuth: GatewayInformation = {
              id: auth.id,
              gateway: value,
            }
            onGatewayChanged(newAuth)
          }}
        />
        <InvalidGatewayErrorSection invalid={isGatewayInvalid} />
        <Button
          aria-label="Remove gateway"
          data-auto-tracking="true"
          data-tracking-label={"Remove gateway"}
          style={{ padding: 0, marginTop: 8 }}
          variant="tertiary"
          className="rule-condition__deleteBtn"
          onClick={() => onRemoveGateway()}
        >
          Remove gateway
        </Button>
      </>
    )
  }
}

export type Props = {
  authorizations: GatewayInformation[]
  onChange: (authorizations: GatewayInformation[]) => void
}

export const AuthorizationSection = ({ authorizations, onChange }: Props) => {
  const [state, dispatch] = useContext(RoutingContext)
  const options = useDefaultGatewayOptions()

  const hasSmartRouting = authorizations.some(auth => auth.gateway === "processout")
  const fallbackLimitReached = authorizations.length >= 3
  // Whether there are unselected options. Do not allow same gateway in the fallback
  const availableOptions = options.filter(option => {
    return !authorizations.find(v => v.gateway === option.value)
  })
  const hasAvailableOptions = availableOptions.length !== 0

  const addGatewayBtn = hasAvailableOptions && !fallbackLimitReached && (
    <Button
      style={{
        marginTop: 8,
        width: "100%",
        backgroundColor: colors.neutral.teal[20],
        color: colors.neutral.teal[120],
        border: "none",
      }}
      variant="primary"
      onClick={() => {
        const id = uniqid()
        // By default it adds the first available gateway option not already selected in the authorizations
        onChange([...authorizations, { id, gateway: availableOptions[0].value || "" }])
        dispatch({
          type: "select.routing.card",
          id,
        })
      }}
      data-auto-tracking="true"
      data-tracking-label="Add fallback gateway"
      data-tag-number-authorizations={authorizations.length}
    >
      {authorizations.length === 0 ? "Add gateway" : "Add fallback gateway"}
    </Button>
  )

  const addSmartRoutingBtn = !hasSmartRouting && !fallbackLimitReached && (
    <Button
      style={{
        marginTop: 8,
        width: "100%",
        backgroundColor: colors.neutral.purple[20],
        color: colors.neutral.purple[120],
        border: "none",
      }}
      variant="primary"
      onClick={() => {
        const id = uniqid()
        onChange([...authorizations, { id, gateway: "processout", configurations: ["all"] }])
        dispatch({
          type: "select.routing.card",
          id,
        })
      }}
      data-auto-tracking="true"
      data-tracking-label="Add fallback gateway"
      data-tag-number-authorizations={authorizations.length}
    >
      {authorizations.length === 0 ? "Add smart routing" : "Add fallback smart routing"}
    </Button>
  )

  return (
    <div className="CriteriaMenu__section" style={{ paddingTop: 0 }}>
      <p className="CriteriaMenu__sectionTitle">Authorize with</p>
      {authorizations.map((auth, idx) => {
        return (
          <Disclosure defaultOpen={auth.id === state.selectedCardId} key={auth.id}>
            {({ open }) => (
              <div className={clsx("rule-condition", { open })}>
                <Disclosure.Button
                  className={clsx("rule-condition__button", open && "rule-condition__button--open")}
                >
                  {idx == 0 ? "Authorize" : "Fallback"}
                  <SvgIcon className="rule-condition__icon" />
                </Disclosure.Button>
                <Disclosure.Panel
                  unmount={false}
                  className={clsx("rule-condition__panel", open && "rule-condition__panel--open")}
                >
                  <GatewaySelection
                    authorizations={authorizations}
                    hasSmartRouting={hasSmartRouting}
                    auth={auth}
                    onGatewayChanged={newAuth => {
                      const newAuths = [...authorizations]
                      newAuths[idx] = newAuth
                      onChange(newAuths)
                    }}
                    onRemoveGateway={() => {
                      const newAuths = [...authorizations]
                      newAuths.splice(idx, 1)
                      onChange(newAuths)
                      dispatch({
                        type: "select.routing.card",
                        id: null,
                      })
                    }}
                  ></GatewaySelection>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        )
      })}
      {addGatewayBtn}
      {addSmartRoutingBtn}
    </div>
  )
}
