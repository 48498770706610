import { AxisBottom, AxisLeft } from "@visx/axis"
import { scaleBand, scaleLinear } from "@visx/scale"
import { Line } from "@visx/shape"
import {
  AnimatedBarSeries,
  buildChartTheme,
  DataContext,
  Tooltip,
  TooltipData,
  XYChart,
} from "@visx/xychart"
import { ThemeConfig } from "@visx/xychart/lib/theme/buildChartTheme"
import { displayValueFromDictionary } from "../dictionary"
import { setFirstLetterToUppercase } from "../util"
import { ChartDatum, getCSSSpace, XAccessor, YAccessor } from "./util"

import * as React from "react"
import { useEffect, useRef } from "react"
import { ValueStyle } from "../consts"
import { trackTarget } from "^/vNext/tracking"

type Props = {
  datas: ChartDatum[]
  name: string
  axisLeft?: number
  limit?: { key: string; value: number }
  valueStyle?: ValueStyle
}

export default function BarChartHorizontal({
  datas,
  axisLeft = 0,
  limit,
  name,
  valueStyle = "percent",
}: Props) {
  const ref = useRef<HTMLDivElement>()
  const getCorrectSpace = getCSSSpace(datas)
  const getValue = d => Number(d.x)

  const max = Math.max(...datas.map(d => getValue(d)))

  const getCorrectPercentScale = datas => {
    // quick fix to set the scale of the chargeback rate correctly
    // TODO: find a better way to handle that
    if (valueStyle === "percent" && datas[0]?.y === "Chargeback rate") {
      return 1.5
    } else {
      return 100
    }
  }

  const setScaleDomainMax =
    valueStyle === "percent"
      ? getCorrectPercentScale(datas)
      : Math.ceil(max * 1.1) < 100
      ? 100
      : Math.ceil(max * 1.1)

  const xAccessor: XAccessor = d => d.x
  const yAccessor: YAccessor = d => d.y

  const customTheme = buildChartTheme({
    colors: ["#7B88C9"],
  } as ThemeConfig)

  const AxBottom = () => {
    const { innerWidth } = React.useContext(DataContext)

    const scaleX = scaleLinear({
      domain: [0, setScaleDomainMax],
      range: [0, innerWidth],
    })

    return (
      <AxisBottom
        scale={scaleX}
        hideAxisLine
        hideTicks
        left={axisLeft}
        top={250}
        tickFormat={value => `${value}${valueStyle === "percent" ? "%" : ""}`}
        tickLabelProps={() => ({
          fontSize: 15,
          fill: "#78909C",
          textAnchor: "middle",
        })}
      />
    )
  }

  const AxLeft = () => {
    // set scaleLinear allow us to set the bottom axis from 0 to 100 && set this axis to the all width
    const scaleX = scaleBand({
      domain: datas
        .slice(0)
        .reverse()
        .map(e => setFirstLetterToUppercase(displayValueFromDictionary(e.y.toString()))),
      range: [0, 250],
      paddingOuter: getCorrectSpace.paddingOuter,
    })

    return (
      <AxisLeft
        data-testid="bar-chart-axis-left"
        scale={scaleX}
        numTicks={datas.length}
        hideTicks
        left={axisLeft}
        hideAxisLine
        top={3}
        tickLabelProps={() => ({
          fontSize: `${datas.length > 10 ? 10 : 14}`,
          textAnchor: "end",
        })}
      />
    )
  }

  const AxisLimit = () => {
    const { innerWidth } = React.useContext(DataContext)

    const indicator = scaleLinear().domain([0, setScaleDomainMax]).range([0, innerWidth])

    const indicatorX = indicator(limit.value) as number

    const getCorrectTextPosition = () => {
      if (setScaleDomainMax > 3000) {
        return -10
      } else if (limit.key === "Previous period:") {
        return 180
      } else {
        return 90
      }
    }

    return (
      <>
        <text x={indicatorX - getCorrectTextPosition()} y="25">
          {limit.key} {limit.value}
          {valueStyle === "percent" && "%"}
        </text>
        <Line
          // We then need to pass the location of our indicator to our line
          from={{ x: indicatorX, y: 0 }}
          pointerEvents="none"
          stroke="#1E88E5"
          to={{ x: indicatorX, y: 250 }}
        />
      </>
    )
  }

  const datasSorted = datas => {
    return datas.sort((a, b) => a.x - b.x)
  }

  return (
    <div ref={ref}>
      <XYChart
        height={250}
        margin={{
          left: axisLeft,
          top: getCorrectSpace.top,
          bottom: getCorrectSpace.bottom,
          right: 0,
        }}
        yScale={{ type: "band", paddingInner: getCorrectSpace.paddingInner }}
        xScale={{
          type: "linear",
          domain: [0, setScaleDomainMax],
        }}
        theme={customTheme}
        horizontal
        onPointerUp={() => trackTarget(ref.current, "Click", "Bar Chart", { chartName: name })}
      >
        {/* rect set the background of the graph */}
        <rect width="100%" height="100%" fill="rgba(81, 99, 149, 0.05)" />;
        <AnimatedBarSeries
          dataKey="primary_line"
          data={datasSorted(datas)}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
        />
        <AxBottom />
        {!!axisLeft && <AxLeft />}
        {limit && <AxisLimit />}
        <Tooltip<ChartDatum>
          showSeriesGlyphs
          renderTooltip={({ tooltipData }) => (
            <BarChartHorizontalTooltip
              tooltipData={tooltipData}
              valueStyle={valueStyle}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
              chartRef={ref}
              chartName={name}
            />
          )}
        />
      </XYChart>
    </div>
  )
}

type BarChartHorizontalTooltipProps = {
  tooltipData: TooltipData<ChartDatum>
  valueStyle: ValueStyle
  xAccessor: XAccessor
  yAccessor: YAccessor
  chartRef: React.MutableRefObject<HTMLDivElement>
  chartName: string
}

function BarChartHorizontalTooltip({
  tooltipData,
  valueStyle,
  xAccessor,
  yAccessor,
  chartRef,
  chartName,
}: BarChartHorizontalTooltipProps) {
  // depend upon the datum updating, as this limits the track event to each time a tooltip with new data is rendered
  useEffect(() => {
    chartRef.current && trackTarget(chartRef.current, "Hover", "Bar Chart", { chartName })
  }, [chartRef, chartName, tooltipData.nearestDatum.datum])

  return (
    <div
      style={{
        padding: "5px",
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal",
        color: "#100C3A",
      }}
    >
      <div style={{ fontSize: "24px", marginBottom: "5px" }}>
        {setFirstLetterToUppercase(
          displayValueFromDictionary(yAccessor(tooltipData.nearestDatum.datum)),
        )}
      </div>
      <div style={{ fontSize: "16px" }}>
        {xAccessor(tooltipData.nearestDatum.datum)}
        {valueStyle === "percent" ? "%" : ""}
      </div>
    </div>
  )
}
