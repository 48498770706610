import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import ModalFooter from "../components/ModalFooter"
import { captureTransaction, createRefund, voidTransaction } from "../Actions/TransactionsActions"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import { track } from "^/vNext/tracking"

export class TransactionDetailsLayout extends React.Component {
  refundTransaction = () => {
    const { dispatch } = this.props
    const { currency, available_amount } = this.props.transactionDetails.transaction
    const header = <div className="large-12 columns">Refund a transaction</div>
    const content = (
      <div className="large-12 columns">
        <div className="row small-margin-bottom">
          <div
            className="medium-4 columns font-console"
            style={{
              marginTop: "7px",
            }}
          >
            Amount ({currency})
          </div>
          <div className="medium-8 columns">
            <RefundInput />
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns margin-bottom text-right">
            <a
              onClick={event => {
                event.preventDefault()
                document.querySelector("#refund-amount").value = available_amount
              }}
            >
              Operate a full refund &rarr;
            </a>
          </div>
        </div>
        <p className="greyed no-margin">
          There might be a slight delay between the refund and the availability on your
          customer&apos;s statement.
        </p>
      </div>
    )
    const footer = (
      <div className="row">
        <div className="medium-12 columns text-right">
          <ModalFooter
            ref={m => (this._modalFooter = m)}
            submitTitle="Refund"
            submitCallback={event => {
              event.preventDefault()

              this._modalFooter.getWrappedInstance().lock()

              const amount = document.querySelector("#refund-amount").value
              this.createRefund(amount)
            }}
          />
        </div>
      </div>
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header,
        content,
        footer,
      },
    })
    track("Transaction Details", "Click", "Header", "Refund Button")
  }
  captureTransaction = () => {
    const { dispatch } = this.props
    const { currency, amount } = this.props.transactionDetails.transaction
    const header = <div className="large-12 columns">Capture a transaction</div>
    const content = (
      <div className="large-12 columns">
        <div className="row margin-bottom">
          <div className="medium-4 columns font-console">Amount ({currency})</div>
          <div className="medium-8 columns text-right">
            <FormattedNumber value={amount} style="currency" currency={currency} />
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns margin-bottom">
            <p className="greyed no-margin">
              This will capture all the funds that were authorized for this transaction.
            </p>
          </div>
        </div>
      </div>
    )
    const footer = (
      <div className="large-12 columns">
        <div className="row">
          <div className="large-12 columns text-right">
            <a
              onClick={event => {
                event.preventDefault()
                dispatch({
                  type: CLOSE_MODAL,
                })
              }}
              className="round border small button"
            >
              Cancel
            </a>

            <a
              onClick={event => {
                event.preventDefault()
                this.capture()
              }}
              className="round small main button white-text"
            >
              Capture
            </a>
          </div>
        </div>
      </div>
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header,
        content,
        footer,
      },
    })
    track("Transaction Details", "Click", "Header", "Capture Button")
  }
  voidTransaction = () => {
    const { dispatch } = this.props
    const { currency, amount } = this.props.transactionDetails.transaction
    const header = <div className="large-12 columns">Void a transaction</div>
    const content = (
      <div className="large-12 columns">
        <div className="row margin-bottom">
          <div className="medium-4 columns font-console">Amount ({currency})</div>
          <div className="medium-8 columns text-right">
            <FormattedNumber value={amount} style="currency" currency={currency} />
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns margin-bottom">
            <p className="greyed no-margin">
              This will release all the funds that were authorized for this transaction.
            </p>
          </div>
        </div>
      </div>
    )
    const footer = (
      <div className="large-12 columns">
        <div className="row">
          <div className="large-12 columns text-right">
            <a
              onClick={event => {
                event.preventDefault()
                dispatch({
                  type: CLOSE_MODAL,
                })
              }}
              className="round border small button"
            >
              Cancel
            </a>

            <a
              onClick={event => {
                event.preventDefault()
                this.void()
              }}
              className="round small main button white-text"
            >
              Void
            </a>
          </div>
        </div>
      </div>
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header,
        content,
        footer,
      },
    })
    track("Transaction Details", "Click", "Header", "Void Button")
  }
  createRefund = amount => {
    const { available_amount, id } = this.props.transactionDetails.transaction
    const { transactionDetails, dispatch } = this.props
    if (!transactionDetails) return
    const data = {
      reason: "customer_request",
      amount: amount || available_amount,
    }
    dispatch(
      createRefund(
        id,
        JSON.stringify(data, null, 2),
        () => {
          dispatch({
            type: CLOSE_MODAL,
          })
        },
        () => {
          this._modalFooter.getWrappedInstance().unlock()
        },
      ),
    )
  }
  capture = () => {
    const { dispatch, transactionDetails } = this.props
    dispatch({
      type: CLOSE_MODAL,
    })
    if (!transactionDetails) return
    dispatch(captureTransaction(transactionDetails.transaction))
  }
  void = () => {
    const { dispatch, transactionDetails } = this.props
    dispatch({
      type: CLOSE_MODAL,
    })
    if (!transactionDetails) return
    dispatch(voidTransaction(transactionDetails.transaction))
  }

  render() {
    const { location, title, children } = this.props
    const { transaction, fetched } = this.props.transactionDetails
    const containerStyle = {
      marginTop: "60px",
    }
    let refundButton

    if (fetched && transaction.gateway_configuration) {
      const { gateway } = transaction.gateway_configuration

      if (
        gateway.can_refund &&
        transaction.available_amount > 0 &&
        transaction.status !== "chargeback-initiated"
      ) {
        refundButton = (
          <input
            type="button"
            onClick={this.refundTransaction}
            className="round button border right small"
            value="Refund"
          />
        )
      }
    }

    let voidButton

    if (fetched && transaction.status === "authorized" && transaction.invoice) {
      voidButton = (
        <input
          type="button"
          style={{
            width: "150px",
          }}
          onClick={this.voidTransaction}
          className="round button border right small"
          value="Void"
        />
      )
    }

    let captureButton

    if (fetched && transaction.status === "authorized" && transaction.invoice) {
      captureButton = (
        <input
          type="button"
          style={{
            width: "150px",
          }}
          onClick={this.captureTransaction}
          className="round button border right small"
          value="Capture"
        />
      )
    }

    let name

    if (fetched) {
      name = <h6 className="greyed bold">{transaction.name}</h6>
    }

    const header = (
      <div>
        <div className="row">
          <div className="medium-8 columns">
            <h4>{title}</h4>
          </div>
          <div className="medium-2 columns">{voidButton}</div>
          <div className="medium-2 columns">
            {refundButton}
            {captureButton}
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns">{name}</div>
        </div>
      </div>
    )
    return (
      <ContentLayout title={header} data-tracking-sub-page="Transaction Details">
        {children}
      </ContentLayout>
    )
  }
}

class RefundInput extends React.Component {
  constructor() {
    super()
    this.state = {
      refundAmount: null,
    }
  }

  render() {
    return (
      <input
        id="refund-amount"
        type="number"
        min="0"
        step="0.01"
        placeholder="Amount to be refunded"
        className="no-margin"
        value={this.state.refundAmount}
        onChange={e => {
          const amount = e.target.value
          // Check that the value is a valid number (https://www.w3schools.com/jsref/jsref_isnan.asp)
          if (isNaN(amount)) return
          this.setState({
            refundAmount: amount,
          })
        }}
      />
    )
  }
}

export default connect(store => ({
  transactionDetails: store.transactionDetails.details,
}))(TransactionDetailsLayout)
