import type { ValueStyle } from "../consts"

type Data = {
  key: string
  value: number
}

export type ChartDatum = {
  x: string | number
  y: string | number
}

export type XAccessor = (d: ChartDatum) => any
export type YAccessor = (d: ChartDatum) => any

export const getDatasPerDay = (data: Data[]): ChartDatum[] => {
  return data.map(d => {
    const date = d.key.split("T")[0]
    return {
      x: date,
      y: d.value ?? 0,
    }
  })
}

export const chartFormattedData = (datas: Data | Data[]): ChartDatum[] => {
  const arrayDatas = []

  if (Array.isArray(datas)) {
    datas.map(data => {
      if (data.key.length > 0 && data.key !== "unknown") {
        const percentage = data.value * 100
        const x = parseFloat(percentage.toFixed(2))
        const y = data.key
        arrayDatas.push({
          x,
          y,
        })
      }
    })
  } else {
    const percentage = datas?.value * 100
    const x = parseFloat(percentage.toFixed(2))
    const y = datas?.key
    arrayDatas.push({
      x,
      y,
    })
  }

  return arrayDatas
}

export const getCSSSpace = datas => {
  let top
  let bottom
  let paddingOuter
  let paddingInner

  if (datas.length > 15) {
    top = 0
    bottom = 0
    paddingOuter = 0
    paddingInner = 0
  } else {
    switch (datas.length) {
      case 1:
        top = 100
        bottom = 100
        paddingOuter = 0
        paddingInner = 0.5
        break

      case 2:
        top = 80
        bottom = 80
        paddingOuter = 1
        paddingInner = 0.5
        break

      case 3:
        top = 50
        bottom = 50
        paddingOuter = 0.6
        paddingInner = 0.5
        break

      case 4:
        top = 20
        bottom = 20
        paddingOuter = 0.2
        paddingInner = 0.4
        break

      default:
        top = 7
        bottom = 7
        paddingOuter = 0.1
        paddingInner = 0.1
        break
    }
  }

  return {
    top,
    bottom,
    paddingOuter,
    paddingInner,
  }
}

export const getLegend = datas => {
  let legend = []
  legend.push(datas.map(data => data.chartTitle))
  return legend[0]
}
