import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import { FormattedNumber, IntlProvider } from "react-intl"
import ReactDOMServer from "react-dom/server"
import GeoChart from "../../../components/Analytics/GeoChart"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import { computeMapChartColor } from "../../analytics/Charts/Utils"
import * as ISOCountries from "../../../util/ISOCountries"
import BarChart from "../../analytics/Charts/BarChart"
import Error from "../../../components/Error"
type Props = {
  telescope: $TelescopeState
}

class RefundsContent extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.telescope.standard.refundsReady !== this.props.telescope.standard.refundsReady
  }

  render() {
    const { telescope } = this.props
    return (
      <div className="row text-left">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-5 columns">
              <div className="row small-margin-bottom">
                <div className="large-12 columns">
                  <div className="chart-title">Refunds percent per country</div>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <div
                    style={{
                      height: "250px",
                    }}
                  >
                    {!this.props.telescope.standard.refundsReady ? null : (
                      <GeoChart
                        key={uniqid()}
                        data={telescope.standard.data.refunded_per_country}
                        color={computeMapChartColor.bind(
                          null,
                          telescope.standard.data.refunded_per_country.reduce(
                            (min, country) => (country.value < min ? country.value : min),
                            1,
                          ),
                          telescope.standard.data.refunded_per_country.reduce(
                            (max, country) => (country.value > max ? country.value : max),
                            0,
                          ),
                        )}
                        tip={function (d) {
                          const c = ISOCountries.isoCountries[d.key]
                          const refundsDisplay = ReactDOMServer.renderToString(
                            <IntlProvider
                              locale={navigator ? navigator.language || "en-US" : "en-US"}
                            >
                              <FormattedNumber
                                value={d.country.refunds_volume}
                                minimumFractionDigits={2}
                                style="percent"
                              />
                            </IntlProvider>,
                          )
                          return (
                            `${c.full_name}<br />` +
                            `<span class="greyed">Refunds percent:</span> ${refundsDisplay}<br />`
                          )
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="large-6 large-offset-1 columns">
              <div className="row">
                <div className="large-12 columns">
                  <div className="row small-margin-bottom">
                    <div className="large-6 columns">
                      <div className="chart-title">Refund rate per provider</div>
                    </div>
                  </div>
                  {telescope.standard.data.refund_amount_per_psp_bar ? (
                    <div className="row">
                      <div className="large-12 columns">
                        <div
                          style={{
                            height: "250px",
                          }}
                        >
                          <div key={uniqid()}>
                            <BarChart
                              type="percentage"
                              data={telescope.standard.data.refund_amount_per_psp_bar}
                              comparison={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Error />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
}))(RefundsContent)
