import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../../util/Types"
import * as Actions from "../actions"
import type { $ProcessorConfiguration, $RoutingRule, $RoutingRulesSettings } from "../consts"
import { RULES_FILTERS } from "../consts"
import type { $Filter } from "./Filter/consts"
import Filter from "./Filter/Filter"
import GatewaysSelection from "./GatewaysSelection"
import { trackTarget } from "^/vNext/tracking"
type Props = {
  rule: $RoutingRule
  rulesSettings: $RoutingRulesSettings
  processorsConfigurations: $ProcessorConfiguration
  ruleWontMatch: Boolean
} & $Dispatcher
export class RoutingRule extends React.Component<Props> {
  filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
    const newRule = { ...this.props.rule }
    newRule.conditions[conditionIndex].filters = newValue
    this.props.dispatch(Actions.updateRule(newRule.id, newRule))
  }

  render() {
    const {
      rule,
      ruleWontMatch,
      gateway_configurations_names,
      processorsConfigurations,
      dispatch,
    } = this.props
    return (
      <div className="row" data-tracking-location="Route">
        {ruleWontMatch && (
          <div className="warning-wont-match">
            A previous rule has no active filter and applies to every transaction: this rule will
            never be executed.
          </div>
        )}

        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-8 columns">Route transactions</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              {rule.conditions.map((condition, index) => (
                <div className="row small-margin-bottom" key={`${rule.id}-${index}`}>
                  <div className="medium-12 columns">
                    <Filter
                      onChange={this.filterChanged.bind(null, index)}
                      options={Object.keys(RULES_FILTERS).map(filter => ({
                        label: RULES_FILTERS[filter].label,
                        value: filter,
                        desc: RULES_FILTERS[filter].desc,
                        isMulti: RULES_FILTERS[filter].isMulti,
                      }))}
                      defaultFilters={condition.filters}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div
              className="medium-1 columns"
              style={{
                paddingTop: ".5em",
              }}
            >
              Towards
            </div>
            <div className="medium-9 end columns">
              <GatewaysSelection
                value={rule.gateways}
                gateways={gateway_configurations_names.gateway_configuration_names}
                processors={processorsConfigurations.configurations}
                onChange={newValue => {
                  const newRule = { ...rule, gateways: newValue }
                  dispatch(Actions.updateRule(newRule.id, newRule))
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  gateway_configurations_names: store.gateway_configurations_names,
  processorsConfigurations: store.processorsConfigurations,
}))(RoutingRule)
