import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
import ContentLayout from "../../../pages/ContentLayout"
import { prepareTelescopeV2Page } from "../actions"
import TelescopeCards from "../components/TelescopeCards"
import TelescopeHeader from "../components/TelescopeHeader"
import DiagnosticsCard from "./DiagnosticsCard"
import * as ProcessOut from "^/util/ProcessOut"
import { $Period, $TelescopeMetricData } from "../consts"

type Chart = Array<{
  key: string
  value: number
}>

export type Props = {
  metricsData: {
    authorization_rate: number
    authorization_rate_increase: number
    total_transaction_amount: number
    total_transaction_amount_increase: number
    number_of_transactions: number
    number_of_transactions_increase: number
    conversion_rate: number
    conversion_rate_increase: number
    success_3ds_rate: number
    success_3ds_rate_increase: number
    captures_and_fees: number
    captures_and_fees_increase: number
    refunds_and_voids: number
    refunds_and_voids_increase: number
    chargebacks: number
    chargebacks_increase: number
    captured_transactions_rate: number
    captured_transactions_rate_evolution: number
    captured_transactions_rate_chart: Chart
    failed_captures_rate: number
    failed_captures_rate_evolution: number
    failed_captures_rate_chart: Chart
    voided_transactions_rate: number
    voided_transactions_rate_evolution: number
    voided_transactions_rate_chart: Chart
    conversion_rate_chart: Chart
    number_of_transactions_chart: Chart
    total_transaction_amount_chart: Chart
    authorization_rate_chart: Chart
    success_3ds_rate_chart: Chart
    captures_and_fees_chart: Chart
    refunds_and_voids_chart: Chart
    chargebacks_chart: Chart
    fetching: boolean
    fetched: boolean
  }
  location: $RouterLocation
} & $Dispatcher

export const Overview: React.FC<Props> = ({ metricsData, dispatch, location }) => {
  useEffect(() => {
    document.title = "Overview | ProcessOut"
    dispatch(prepareTelescopeV2Page("overview", location.query.period as $Period))
  }, [dispatch, location])

  const metrics: $TelescopeMetricData[] = [
    {
      title: "Conversion rate",
      value: metricsData.conversion_rate,
      percentageEvolution: metricsData.conversion_rate_increase,
      valueStyle: "percent",
      chart: metricsData.conversion_rate_chart,
      id: "conversion_rate",
      tooltipContent:
        "Total number of authorized transactions / total number of initiated transactions (includes 3DS checks)",
    },
    {
      title: "Number of transactions",
      value: metricsData.number_of_transactions,
      percentageEvolution: metricsData.number_of_transactions_increase,
      valueStyle: "decimal",
      chart: metricsData.number_of_transactions_chart,
      id: "number_of_requests",
      tooltipContent:
        "Total volume of the authorization operations, regardless of their status (failed or success)",
    },
    {
      title: "Total transaction amount",
      value: metricsData.total_transaction_amount,
      percentageEvolution: metricsData.total_transaction_amount_increase,
      valueStyle: "currency",
      chart: metricsData.total_transaction_amount_chart,
      id: "total_transaction_amount",
      tooltipContent:
        "Total value of the authorization operations, regardless of their status (failed or success)",
    },
    {
      title: "Authorization rate",
      value: metricsData.authorization_rate,
      percentageEvolution: metricsData.authorization_rate_increase,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/authorization`,
      chart: metricsData.authorization_rate_chart,
      id: "authorization_rate",
      tooltipContent:
        "Number of successful authorizations / total number of authorization requests",
    },
    {
      title: "3DS Success rate",
      value: metricsData.success_3ds_rate,
      percentageEvolution: metricsData.success_3ds_rate_increase,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/domains-secure`,
      chart: metricsData.success_3ds_rate_chart,
      id: "3DS_success_rate",
      tooltipContent: "Number of successful 3DS checks / total number of 3DS checks",
    },
    {
      title: "Captured transactions",
      value: metricsData.captured_transactions_rate,
      percentageEvolution: metricsData.captured_transactions_rate_evolution,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/capture-voids`,
      chart: metricsData.captured_transactions_rate_chart,
      id: "captured_transactions_rate",
      tooltipContent:
        "Transactions which have a successful captured request processed by percentage, value and volume",
    },
    {
      title: "Failed captures",
      value: metricsData.failed_captures_rate,
      percentageEvolution: metricsData.failed_captures_rate_evolution,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/capture-voids`,
      chart: metricsData.failed_captures_rate_chart,
      id: "failed_captures_rate",
      tooltipContent:
        "Transactions which have received a capture request which were not successfully processed by percentage, value and volume",
    },
    {
      title: "Voided transactions",
      value: metricsData.voided_transactions_rate,
      percentageEvolution: metricsData.voided_transactions_rate_evolution,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/capture-voids`,
      chart: metricsData.voided_transactions_rate_chart,
      id: "voided_transactions_rate",
      tooltipContent: "Transactions which have been voided by percentage, value and volume",
    },
    {
      title: "Refunds",
      value: metricsData.refunds_and_voids,
      percentageEvolution: metricsData.refunds_and_voids_increase,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/refunds`,
      chart: metricsData.refunds_and_voids_chart,
      id: "refunds_voids",
      tooltipContent: "Total percentage of refunds that are successful",
    },
    {
      title: "Fees",
      value: metricsData.captures_and_fees,
      percentageEvolution: metricsData.captures_and_fees_increase,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/fees`,
      chart: metricsData.captures_and_fees_chart,
      id: "capture_fees",
      tooltipContent: "Total percentage of fees in relation to captured volume",
    },
    {
      title: "Chargebacks",
      value: metricsData.chargebacks,
      percentageEvolution: metricsData.chargebacks_increase,
      valueStyle: "percent",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/chargebacks`,
      chart: metricsData.chargebacks_chart,
      id: "chargebacks",
      tooltipContent: "Number of chargebacks (won or lost) / captured transactions",
    },
  ]

  return (
    <ContentLayout
      title={<TelescopeHeader title="Overview" />}
      noBackground
      padding="0"
      data-tracking-sub-page="Overview"
    >
      <DiagnosticsCard />
      <div className="row">
        <TelescopeCards data={metrics} loading={!metricsData.fetched || metricsData.fetching} />
      </div>
    </ContentLayout>
  )
}

export default connect(store => ({
  metricsData: store.metricsTelescopeReducer.overview,
}))(withRouter(Overview))
