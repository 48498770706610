import clsx from "clsx"
import React from "react"
import type { $Option } from "./consts"
import { TRANSACTION_FIELDS } from "./consts"
import PlottedFieldInput from "./PlottedFieldInput"
import "./PlottedField.scss"
type Props = {
  onChange: (arg0: string) => void
  defaultValue?: string
  suffix?: string
  rtl?: boolean | null | undefined
  disabled: boolean | null | undefined
  options?: Array<$Option> | null | undefined
  allowMetadata?: boolean | null | undefined
  shouldDisplayApplyButton?: boolean | null | undefined
}
type State = {
  value: $Option | null | undefined
  metadataValue: string
  fraudServicesValue: string
}
export const CustomOption = props => {
  return (
    <div
      className={clsx("select-option row", {
        "is-selected": props.isSelected,
        "is-focused": props.isFocused,
      })}
      ref={props.innerRef}
      {...props.innerProps}
    >
      <div className="large-12 columns">
        <div className="row small-margin-bottom">
          <div className="large-12 columns">{props.data.label}</div>
        </div>
        <div
          className="row small-font"
          style={{
            opacity: 0.7,
          }}
        >
          <div className="large-12 columns">{props.data.desc}</div>
        </div>
      </div>
    </div>
  )
}

class PlottedField extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      value: {
        label: "",
        value: "",
        desc: "",
      },
      metadataValue: "",
      fraudServicesValue: "",
    }
  }

  componentDidMount() {
    this.setDefaultValue(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.defaultValue && nextProps.defaultValue !== this.props.defaultValue)
      this.setDefaultValue(nextProps)
  }

  setDefaultValue = (props: Props) => {
    const { defaultValue, options } = props

    if (defaultValue) {
      let newValue

      // we parse the metadata part
      if (defaultValue.includes("metadata")) {
        // we remove the right part of the metadata
        const splits = defaultValue.split("metadata")
        newValue = `${splits[0]}metadata`
        // splits[1] should be something like ".CHOSEN_KEYWORD"
        const keyword = splits[1].split(".")[1]
        this.setState({
          metadataValue: keyword,
        })
      } else if (defaultValue.includes("action(")) {
        newValue = "fraud_services"
        const keyword = defaultValue.split('action("')[1].split('"')[0]
        this.setState({
          fraudServicesValue: keyword,
        })
      } else {
        newValue = defaultValue
      }

      let label

      if (options) {
        const currentOption = options.find(o => o.value === newValue)
        if (currentOption) label = currentOption.label
      }

      this.setState({
        value: {
          label: label || newValue,
          value: newValue,
          desc: "",
        },
      })
    }
  }
  updateValue = (newValue: $Option) => {
    if (!newValue) return

    if (newValue.value.includes("metadata")) {
      // we wait for the user to hit the "apply" button
    } else {
      this.props.onChange(newValue ? newValue.value : "")
    }

    this.setState({
      value: newValue,
      metadataValue: "",
      fraudServicesValue: "",
    })
  }
  updateMetadataValue = (event: Event) => {
    this.setState({
      metadataValue: event.target.value,
    })
  }
  updateFraudServicesValue = (event: Event) => {
    this.setState({
      fraudServicesValue: event.value,
    })
    this.props.onChange(`action("${event.value}")`)
  }
  submitMetadata = (event: Event) => {
    this.props.onChange(`${this.state.value.value}.${this.state.metadataValue}`)
  }

  // renderOption = (option: $Option) => (
  //   <div className="row">
  //     <div className="large-12 columns">
  //       <div className="row small-margin-bottom">
  //         <div className="large-12 columns">{option.label}</div>
  //       </div>
  //       <div className="row small-font" style={{ opacity: 0.7 }}>
  //         <div className="large-12 columns">{option.desc}</div>
  //       </div>
  //     </div>
  //   </div>
  // )
  render() {
    const { suffix, allowMetadata } = this.props
    const metadataOption = {
      label: "metadata",
      value: "metadata",
      desc: "",
    }
    let options

    if (this.props.options) {
      options = (allowMetadata ? [metadataOption] : []).concat(this.props.options)
    } else {
      options = Object.keys(
        allowMetadata ? { ...TRANSACTION_FIELDS, metadata: "" } : TRANSACTION_FIELDS,
      ).map(field => ({
        value: `${suffix || ""}${field}`,
        label: `${suffix || ""}${field}`,
        desc: TRANSACTION_FIELDS[field],
      }))
    }

    options.sort((a, b) => {
      if (b.label > a.label) return -1
      return 1
    })
    return (
      <PlottedFieldInput
        options={options}
        disabled={this.props.disabled}
        renderOption={CustomOption}
        value={this.state.value}
        updateValue={this.updateValue}
        rtl={this.props.rtl}
        metadataValue={this.state.metadataValue}
        updateMetadataValue={this.updateMetadataValue}
        onSubmit={this.submitMetadata}
        defaultMetadataValue={this.props.defaultValue}
        shouldDisplayApplyButton={this.props.shouldDisplayApplyButton}
        fraudServicesValue={this.state.fraudServicesValue}
        updateFraudServicesValue={this.updateFraudServicesValue}
      />
    )
  }
}

export default PlottedField
