import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router"
import type { $Dispatcher, $Project } from "../../util/Types"
import * as Actions from "./actions"
import ContentLayout from "../../pages/ContentLayout"
import Alerts from "./Alerts"
import type { $AlertsState } from "./reducer"
import Error from "../../components/Error"
import Loading from "../../components/Loader"
// @ts-expect-error
import "./alerting.scss"
import NotPermitted from "../Permissions/NotPermitted"
type Props = {
  alertingState: $AlertsState
  currentProject: {
    project: $Project
  }
} & $Dispatcher

class AlertingHome extends React.Component<Props> {
  componentDidMount() {
    document.title = "Alerting | ProcessOut"
    this.props.dispatch(Actions.requestAlertsFetch(5, null, null))
  }

  render() {
    const { alertingState, currentProject } = this.props
    return (
      <ContentLayout
        title={
          <div className="row">
            <div className="medium-9 columns">
              <h4>Alerting</h4>
            </div>
            <div className="medium-3 columns text-right">
              <Link
                class="small round border button"
                to={`/projects/${currentProject.project.id}/alerting/alerts/create`}
              >
                New alert
              </Link>
            </div>
          </div>
        }
        noBackground
        padding="1em"
      >
        {alertingState.error && !alertingState.error.notPermitted && <Error />}
        {alertingState.error && alertingState.error.notPermitted && <NotPermitted />}
        {alertingState.fetching && <Loading />}
        {alertingState.fetched && !alertingState.error && <Alerts alerts={alertingState.alerts} />}
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
  alertingState: store.alerting,
}))(AlertingHome)
