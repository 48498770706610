import type { $Action } from "../../../util/Types"
import type { $Chart, $ChartType, $FetchParams } from "../Boards/consts"
import type { $Params } from "./consts"
import { CLEAR_PREVIEW, REQUEST_CHART_PREVIEW, REQUEST_CHART_SAVE } from "./consts"
import * as ProcessOut from "../../../util/ProcessOut"
import { parseDates } from "../Charts/Utils"
import { segmentAnalytics } from "^/vNext/Segment"

export function requestChartPreview(
  name: string,
  description: string,
  formula: string,
  type: $ChartType,
  unit: string,
  size: number,
  boardId: string,
  params: $Params,
): $Action {
  return {
    type: REQUEST_CHART_PREVIEW,
    payload: {
      name,
      description,
      formula,
      type,
      unit,
      size,
      boardId,
      params,
    },
  }
}
export function requestChartSave(
  chartId: string | null | undefined,
  projectId: string,
  name: string,
  description: string,
  formula: string,
  type: $ChartType,
  unit: string,
  size: number,
  boardId: string,
  boardName: string,
  params: $Params,
  position:
    | {
        x: number
        y: number
      }
    | null
    | undefined,
  height: number,
): $Action {
  return {
    type: REQUEST_CHART_SAVE,
    payload: {
      projectId,
      name,
      description,
      formula,
      type,
      unit,
      size,
      boardId,
      boardName,
      params,
      id: chartId,
      position,
      height,
    },
  }
}
export const PREPARE_CHART_PREVIEW = "PREPARE_CHART_PREVIEW"
export function saveChart(chart: $Chart, boardId: string, params: $Params): $Action {
  const { from, to, timespan } = parseDates(params.interval.from, params.interval.to)
  segmentAnalytics?.track(chart.id ? "EDITED_CHART" : "CREATED_CHART", chart)
  return {
    type: PREPARE_CHART_PREVIEW,
    payload: ProcessOut.APIcallPromise(
      `/boards/${boardId}/charts${
        chart.id ? `/${chart.id}` : ""
      }?interval=${timespan}&start_at=${from}&end_at=${to}`,
      chart.id ? "PUT" : "POST",
      chart,
    ),
  }
}
export function fetchPreviewedChart(chart: $Chart, boardId: string, params: $FetchParams): $Action {
  const { from, to, timespan, timezone } = parseDates(params.interval.from, params.interval.to)
  const timeComparison =
    params.timeCompare.comparing &&
    `&compare_start_at=${params.timeCompare.from}&compare_end_at=${params.timeCompare.to}`

  return {
    type: PREPARE_CHART_PREVIEW,
    payload: ProcessOut.APIcallPromise(
      `/boards/${boardId}/charts?interval=${
        params.timeInterval || timespan
      }&timezone=${encodeURIComponent(timezone)}&start_at=${from}&end_at=${to}${
        timeComparison ? timeComparison : ""
      }&filter=${params.filter}`,
      "POST",
      chart,
    ),
  }
}
export function clearPreview(): $Action {
  return {
    type: CLEAR_PREVIEW,
  }
}
