import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Loader from "../../components/Loader"
import Pagination from "../../components/Pagination"
import type { $Dispatcher, $RouterLocation } from "../../util/Types"
import * as Actions from "./actions"
import Collaborator from "./Collaborator"
import { requestFetchCollaborators } from "./CollaboratorsActions"
import type { $PermissionsState } from "./reducer"
type Props = {
  permissionsDetails: $PermissionsState
  location: $RouterLocation
} & $Dispatcher
export class CollaboratorsList extends React.Component<Props> {
  saveTeamSettings = () => {
    const { dispatch } = this.props
    dispatch(Actions.saveTeamSettings())
  }

  render() {
    const { permissionsDetails } = this.props
    let content

    if (!permissionsDetails.collaborators.fetched) {
      content = (
        <div className="large-12 columns text-center">
          <Loader />
        </div>
      )
    } else {
      content = (
        <div>
          {permissionsDetails.collaborators.collaborators.map(collaborator => (
            <Collaborator key={collaborator.id} collaborator={collaborator} />
          ))}
        </div>
      )
    }

    return (
      <div className="row">
        <div className="medium-12 columns">
          <div className="row margin-bottom">
            <div className="medium-12 columns">
              {content}
              <Pagination
                reloadFunction={requestFetchCollaborators}
                itemsArray={
                  permissionsDetails.collaborators.fetched
                    ? permissionsDetails.collaborators.collaborators
                    : []
                }
                hasMore={
                  permissionsDetails.collaborators.fetched
                    ? permissionsDetails.collaborators.has_more
                    : false
                }
                hide={
                  !permissionsDetails.collaborators.fetched ||
                  permissionsDetails.collaborators.fetching
                }
                location={this.props.location}
              />
            </div>
          </div>
        </div>
        <div className="row small-margin-bottom">
          <div className="medium-2 columns medium-offset-7 text-right">
            <button
              onClick={() => {
                const { dispatch } = this.props
                dispatch(Actions.addCollaborator())
              }}
              className="small round border button expanded"
            >
              Add collaborator
            </button>
          </div>
          <div className="medium-3 columns text-right">
            {permissionsDetails.collaborators.saving ? (
              <Loader size={14} />
            ) : (
              <button onClick={this.saveTeamSettings} className="small round main button expanded">
                Save collaborators
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  permissionsDetails: store.permissions,
}))(withRouter(CollaboratorsList))
