import React from "react"

const InvalidGatewayErrorPanel: React.FC<{ ruleIndices: number[] }> = ({ ruleIndices }) => {
  return (
    <div className="error-panel">
      <div>
        You have missing providers for the following rules. Please add a provider to each of these
        rules to ensure they remain operational.
      </div>
      <ul>
        {ruleIndices.map(idx => (
          <li key={idx}>Rule number {idx + 1}</li>
        ))}
      </ul>
    </div>
  )
}

export default InvalidGatewayErrorPanel
