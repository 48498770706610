import React from "react"
import { SingleDatePicker } from "react-dates"
import moment from "moment"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"

type Props = {
  value: $Filter
  onChange: (filter: $Filter) => void
}

type State = {
  selectedDate: moment.Moment | null
  focused: boolean
}

class DateFilter extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      selectedDate: null,
      focused: false,
    }
  }

  componentDidMount() {
    const { value } = this.props
    if (value.value && value.value.length > 0)
      this.setState({
        selectedDate: moment(value.value[0].toString()),
      })
  }

  render() {
    const { value, onChange } = this.props
    return (
      <div className="row collapse date-filter">
        <div className="medium-12 columns">
          <SingleDatePicker
            date={this.state.selectedDate}
            onDateChange={date =>
              this.setState(
                {
                  selectedDate: date,
                },
                () => {
                  const newFilter = {
                    ...value,
                    value: [moment(date).startOf("day").format()],
                  }
                  onChange(newFilter)
                },
              )
            }
            focused={this.state.focused}
            onFocusChange={({ focused }) =>
              this.setState({
                focused,
              })
            }
            isOutsideRange={() => false}
            placeholder="Click to select date"
            daySize={30}
          />
        </div>
      </div>
    )
  }
}

export default DateFilter
