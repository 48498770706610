import React from "react"
import Creatable from "react-select/creatable"
import { groupBy } from "../../DataExplorer/Utils"
import { DIMENSIONS_MAP } from "./consts"
type Props = {
  value: string | null | undefined
  onChange: (arg0: string | null | undefined) => void
}

class ComparisonSelector extends React.Component<Props> {
  onChange = (
    newValue:
      | {
          label: string
          value: string
        }
      | null
      | undefined,
  ) => {
    const { onChange } = this.props

    if (!newValue) {
      onChange(null)
      return
    }

    onChange(newValue.value)
  }

  render() {
    const groupByGroupName = groupBy("group")
    const groupedByGroupName = groupByGroupName(DIMENSIONS_MAP)
    const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
      label: group,
      options: groupedByGroupName[group].map(d => ({
        value: d.field,
        label: d.name,
        top: d.top,
        strategy: d.strategy,
      })),
    }))
    const corOption = DIMENSIONS_MAP.find(
      d => this.props.value && d.field === this.props.value.replace("transaction_operations.", ""),
    )
    let corValue
    if (corOption)
      corValue = {
        label: corOption.name,
        value: corOption.field,
      }
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div
          className="field-label active"
          style={{
            borderRadius: "4px 0 0 4px",
          }}
        >
          Compare with
        </div>
        <div
          className="labeled-field text-left"
          style={{
            width: "300px",
          }}
        >
          <Creatable
            options={OPTIONS}
            value={corValue}
            isClearable
            onChange={this.onChange}
            placeholder="No parameter selected"
            styles={{
              valueContainer: (provided: any) => ({ ...provided, paddingLeft: ".5em" }),
              container: (provided: any) => ({
                ...provided,
                position: "relative",
                borderRadius: "0 4px 4px 0",
              }),
              control: (provided: any) => ({ ...provided, borderRadius: "0 4px 4px 0" }),
            }}
          />
        </div>
      </div>
    )
  }
}

export default ComparisonSelector
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)
