import type { Node } from "react"
import React, { useEffect } from "react"
import { initLaunchDarkly } from "^/vNext/LaunchDarkly/LaunchDarklyActions"
import { connect } from "react-redux"
import type { Dispatch } from "@@redux"
type Props = {
  children: Node
  dispatch: Dispatch<any>
}
export const AppLayout = ({ children, dispatch }: Props): Node => {
  useEffect(() => {
    dispatch(initLaunchDarkly())
  }, [dispatch])
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {children}
    </div>
  )
}
export default connect()(AppLayout)
