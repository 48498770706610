import React from "react"
import { connect } from "react-redux"
import ContentLayout from "../../pages/ContentLayout"
import type { $ExportState } from "./reducer"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
import Loading from "../../components/Loader"
import NotPermitted from "../Permissions/NotPermitted"
import Export from "./Export"
import Pagination from "../../components/Pagination"
import Error from "../../components/Error"
import Empty from "../../components/Empty"
type Props = {
  transactionsExports: $ExportState
} & $Dispatcher

class Exports extends React.Component<Props> {
  componentDidMount(): void {
    const { dispatch } = this.props
    const afterItem = this.props.location.query.after_item
    const beforeItem = this.props.location.query.before_item
    dispatch(Actions.requestExportsFetch(afterItem, afterItem || beforeItem))
  }

  render() {
    const { transactionsExports } = this.props
    return (
      <ContentLayout title={<h4>Exports</h4>}>
        {transactionsExports.fetching && <Loading />}
        {transactionsExports.error && transactionsExports.error.notPermitted ? (
          <NotPermitted />
        ) : transactionsExports.error ? (
          <Error />
        ) : (
          <div>
            <div
              className="row small-margin-bottom greyed capitalize bold"
              style={{
                fontSize: "0.8em",
              }}
            >
              <div className="medium-1 columns">Status</div>
              <div className="medium-3 columns">Description</div>
              <div className="medium-3 columns">Date range</div>
              <div className="medium-2 columns">Type</div>
              <div className="medium-3 columns text-right">Date</div>
            </div>
            {transactionsExports.fetched &&
              transactionsExports.exports.map(ex => <Export key={ex.id} ex={ex} />)}
            {transactionsExports.fetched && transactionsExports.exports.length === 0 && (
              <Empty text="You haven't requested any report yet." />
            )}
          </div>
        )}
        <Pagination
          reloadFunction={Actions.requestExportsFetch}
          itemsArray={transactionsExports.exports}
          hasMore={transactionsExports.has_more}
          location={this.props.location}
          hide={transactionsExports.fetching || !transactionsExports.fetched}
          isFirstPage={this.props.location.query.after_item == null}
        />
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  transactionsExports: store.transactionsExports,
}))(Exports)
