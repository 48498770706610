import React from "react"
import { FormattedNumber } from "react-intl"
import SummaryMainValue from "./SummaryMainValue"
type Props = {
  count?: number
  amount?: number
}
export default function SummaryMoreDetails({ count, amount }: Props) {
  return (
    <>
      <div
        style={{
          fontSize: "24px",
        }}
      >
        {amount && <SummaryMainValue valueStyle="decimal" value={amount} />}
      </div>
      <div
        style={{
          fontSize: "14px",
        }}
      >
        <FormattedNumber value={count} />
      </div>
    </>
  )
}
