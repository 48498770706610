import React, { useEffect } from "react"
import { Link, withRouter } from "react-router"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import Auth from "../../util/Auth"
import * as UserActions from "../../Actions/UserActions"
import UserStore from "../../stores/UserStore"
import ErrorStore from "../../stores/ErrorStore"
import NotificationStore from "../../stores/NotificationStore"
import * as ProcessOut from "../../util/ProcessOut"
import type { User } from "../../Types/User"
import ReportingBugWidget from "../../features/ReportingBugWidget/ReportingBugWidget"
import type { $Dispatcher, $RouterLocation } from "../../util/Types"
import SearchBar from "../../features/SearchBar/SearchBar"
import * as Actions from "../../features/SearchBar/actions"
import SearchSwitcher from "../../features/SearchBar/SearchSwitcher"
import type { $SearchBarState } from "../../features/SearchBar/reducer"

type Props = {
  redirectToWebsite: boolean
  location: $RouterLocation
  searchDetails: $SearchBarState
  isTransparent: boolean
} & $Dispatcher
type State = {
  user: User | null | undefined
}
export class Header extends React.Component<Props, State> {
  /*:: _unsubscribeLoginWatcher: ?() => void = null; */

  /*:: displayNotification: () => void; */

  /*:: getDetails: () => void; */

  /*:: displayError: () => void; */
  constructor() {
    super()
    this.state = {
      user: null,
    }
  }

  UNSAFE_componentWillMount() {
    ErrorStore.on("error", this.displayError)
    UserStore.on("change", this.getDetails)
    NotificationStore.on("notification", this.displayNotification)
  }

  componentDidMount() {
    const { user, dispatch } = this.props
    window.dispatchEvent(new CustomEvent("resize-dashboard"))

    if (user.auth.loggedIn) {
      dispatch(UserActions.loadUser2())
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Record<string, any>) {
    const { dispatch } = this.props

    if (
      nextProps.user.auth.loggedIn &&
      !nextProps.user.details.fetching &&
      !nextProps.user.details.fetched
    ) {
      dispatch(UserActions.loadUser2())
    }
  }

  displayNotification = () => {
    const data = NotificationStore.getNotification()
  }

  componentWillUnmount() {
    UserStore.removeListener("change", this.getDetails)
    NotificationStore.removeListener("notification", this.displayNotification)
    ErrorStore.removeListener("error", this.displayError)
    if (this._unsubscribeLoginWatcher) this._unsubscribeLoginWatcher()
  }

  displayError = () => {
    const error = ErrorStore.getError()
    ProcessOut.addNotification.bind(this)(error, "error")
  }
  getDetails = () => {
    const details = UserStore.getAll()
    this.setState({
      user: details.user,
    })
  }

  logout() {
    Auth.logout()
  }

  render() {
    const { redirectToWebsite, location, searchDetails, isTransparent, dispatch, launchDarkly } =
      this.props
    let logoLink = <Link to="/projects">ProcessOut</Link>

    if (redirectToWebsite) {
      logoLink = <a href="https://www.processout.com">ProcessOut</a>
    }

    if (window.ProcessOutConfig && window.ProcessOutConfig.logoUrl) {
      logoLink = (
        <Link to="/projects">
          <img
            alt={window.ProcessOutConfig.company}
            style={{
              height: window.ProcessOutConfig.logoHeight,
            }}
            src={window.ProcessOutConfig.logoUrl}
          />
        </Link>
      )
    }

    let loggedout

    if (!Auth.isLogged()) {
      loggedout = (
        <ul className="menu">
          <li>
            <a href="https://docs.processout.com">Help &amp; support</a>
          </li>
          <li>
            <Link
              to="/login"
              class="white round border button"
              style={{
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              Sign in
            </Link>
          </li>
        </ul>
      )
    } else {
      loggedout = (
        <ul className="menu">
          {launchDarkly.flags["dashboard-show-jimo"] && (
            <li>
              <JimoTrigger />
            </li>
          )}
          <li>
            <ReportingBugWidget />
          </li>
          <li>
            <Link to="" onClick={this.logout}>
              sign out
            </Link>
          </li>
        </ul>
      )
    }

    return (
      <div className={`${isTransparent ? "transparent" : ""} top-bar`}>
        <a className="skip-link greyed" href="#skip-content"></a>
        <div className="top-bar-left">
          <ul className="menu">
            <li className="menu-title">{logoLink}</li>
            {Auth.isLogged() && (
              <li className="menu-search">
                <SearchBar />
              </li>
            )}
            {searchDetails.filteringDocument && (
              <li
                style={{
                  marginLeft: "0.7rem",
                }}
              >
                <SearchSwitcher
                  onChange={filter => {
                    dispatch(Actions.updateSearchBarAppliedFilter(filter))
                    dispatch(push(`${location.pathname}?filter=${encodeURIComponent(filter)}`))
                  }}
                  document={searchDetails.filteringDocument}
                  defaultFilter={location.query.filter}
                />
              </li>
            )}
          </ul>
        </div>
        <div className="top-bar-right">{loggedout}</div>
      </div>
    )
  }
}

const JimoTrigger: React.FC = () => {
  useEffect(() => {
    window.jimo?.push(["do", "trigger-badge:find"])
  }, [])

  return (
    <a
      className="jimo-widget"
      style={{ display: "flex" }}
      onClick={() => window.jimo?.push(["do", "widget:open"])}
    >
      <div>What&apos;s new?</div>
    </a>
  )
}

export default connect(store => ({
  user: store.user,
  searchDetails: store.searchBar,
  launchDarkly: store.launchDarkly,
}))(withRouter(Header))
