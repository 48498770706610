import * as ProcessOut from "../../util/ProcessOut"
import { datadogRum } from "@datadog/browser-rum"
export const FETCH_FRAUD_SERVICES = "FETCH_FRAUD_SERVICES"
export function loadFraudServices() {
  return {
    type: FETCH_FRAUD_SERVICES,
    payload: ProcessOut.APIcallPromise("/fraud/services", "GET")
      .then(response => response.data)
      .catch(err => datadogRum.addError(err)),
  }
}
