import React from "react"
import type { $ApiKey } from "./consts"
type Props = {
  privateKey: $ApiKey
}
type State = {
  privateKey: $ApiKey | null | undefined
}

class KeyOverviewModal extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      privateKey: null,
    }
  }

  componentDidMount() {
    this.setState({
      privateKey: this.props.privateKey,
    })
  }

  getValue = () => ({ ...this.state.privateKey })

  render() {
    const { privateKey } = this.state
    if (!privateKey) return null
    return (
      <div className="row api-modal">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-4 columns greyed">Name</div>
            <div className="medium-8 columns text-right">{privateKey.name}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-4 columns greyed">Type</div>
            <div className="medium-8 columns text-right">
              <div
                className={`log-level ${privateKey.sandbox ? "warning" : "success"}`}
                style={{
                  width: "40%",
                  marginLeft: "auto",
                }}
              >
                {privateKey.sandbox ? "Sandbox" : "Production"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="medium-4 columns greyed">Enabled</div>
            <div className="medium-8 columns text-right">
              <div className="switch-container">
                <label className="local-switch">
                  <input
                    type="checkbox"
                    onChange={event => {
                      this.setState({
                        privateKey: { ...this.state.privateKey, enabled: event.target.checked },
                      })
                    }}
                    checked={privateKey.enabled}
                  />
                  <span className="local-slider round" />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default KeyOverviewModal
