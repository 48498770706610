import React from "react"
import { Link, withRouter } from "react-router"
import { connect } from "react-redux"
import { replace } from "react-router-redux"
import SideMenu from "./Sidemenu"
import type { $Dispatcher, $Project, $RouterLocation, $State } from "../../util/Types"
import * as ProcessOut from "../../util/ProcessOut"
import * as ProjectsActions from "../../Actions/ProjectsActions"
import Error from "../Error"
import Loading from "../Loader"
import ProjectRegionFlag from "./ProjectRegionFlag"
import Auth from "../../util/Auth"
import "./Nav.scss"
type Props = {
  params: {
    project: string
  }
  currentProject: {
    project: $Project
  } & $State
  projectsDetails: {
    projects: Array<$Project>
  } & $State
  location: $RouterLocation
  children: any
} & $Dispatcher
type State = {
  dropdownvisible: boolean
}
export class Nav extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      dropdownvisible: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(ProjectsActions.loadProjects())
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { dispatch } = this.props
    const { projectsDetails } = nextProps
    this.resetSelect(ProcessOut.config.ProjectId)

    if (!projectsDetails.fetching && !projectsDetails.fetched) {
      dispatch(ProjectsActions.loadProjects())
    }
  }

  getProject(props: Props) {
    if (!props) props = this.props
    const { projects } = props.projectsDetails

    for (let i = 0; i < projects.length; i++) {
      if (projects[i].id === props.params.project.replace("test-", "")) {
        return projects[i]
      }
    }
  }

  changeProject(id: string) {
    const { dispatch, params } = this.props
    this.toggleDropdown()
    dispatch(replace(`/projects?target=${params.project.includes("test-") ? "test-" : ""}${id}`))
  }

  resetSelect(id: string) {
    const sel = document.getElementById("select-projects")
    if (!sel) return
    const opts = sel.options

    for (let i = 0; i < opts.length; i++) {
      if (opts[i].value == id) {
        sel.selectedIndex = i
      }
    }
  }

  trunc(str: string, n: number) {
    return str.length > n ? `${str.substring(0, n)}...` : str
  }

  toggleDropdown = () => {
    const { dropdownvisible } = this.state
    this.setState({
      dropdownvisible: !dropdownvisible,
    })
  }

  render() {
    const { currentProject, projectsDetails, params, location, children } = this.props
    const { dropdownvisible } = this.state
    const { projects } = projectsDetails
    return (
      <div className="wrapper large-12 columns shadowed rounded-top">
        <div className="row">
          <div className="dashboard-side-bar large-2 medium-3 columns side-nav lighten-bg lighten-content hide-for-small no-padding">
            <div
              style={{
                textAlign: "center",
                paddingTop: "0px",
                width: "100%",
              }}
            >
              {projectsDetails.fetched && !projectsDetails.error && projects.length > 0 && (
                <div className={dropdownvisible ? "dropdown activated" : "dropdown"}>
                  <button type="button" className="dropbtn" onClick={this.toggleDropdown}>
                    <div className="project-region-flag-container">
                      {currentProject.fetched && (
                        <ProjectRegionFlag project={currentProject.project} />
                      )}
                    </div>
                    <span className="project-name">{this.getProject().name}</span>
                    <div className="project-icon">
                      <img
                        alt="Down arrow"
                        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI1NiAyNTYiIGhlaWdodD0iMjU2cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiB3aWR0aD0iMjU2cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zOC4zOTksNzYuOGMxLjYzNywwLDMuMjc0LDAuNjI1LDQuNTI0LDEuODc1bDg1LjA3NSw4NS4wNzZsODUuMDc1LTg1LjA3NmMyLjUtMi41LDYuNTUtMi41LDkuMDUsMHMyLjUsNi41NSwwLDkuMDUgIGwtODkuNiw4OS42MDFjLTIuNSwyLjUtNi41NTEsMi41LTkuMDUxLDBsLTg5LjYtODkuNjAxYy0yLjUtMi41LTIuNS02LjU1LDAtOS4wNUMzNS4xMjQsNzcuNDI1LDM2Ljc2Miw3Ni44LDM4LjM5OSw3Ni44eiIvPjwvc3ZnPg=="
                      />
                    </div>
                  </button>
                  <div className="dropdown-content">
                    {projects.map(project => {
                      if (project.id === params.project.replace("test-", ""))
                        return <span key={0} />
                      return (
                        <a
                          key={project.id}
                          onClick={this.changeProject.bind(this, project.id)}
                          className="clearfix"
                        >
                          <div className="project-region-flag-container">
                            <ProjectRegionFlag project={project} />
                          </div>
                          <div className="project-name">{project.name}</div>
                          <div className="project-icon">
                            <span>&rarr;</span>
                          </div>
                        </a>
                      )
                    })}

                    {Auth.isAdminUser() && (
                      <Link
                        to="/projects/create"
                        class="text-center"
                        style={{
                          padding: "1.5em",
                        }}
                        data-testid="create-new-project"
                      >
                        <img
                          alt="Add a new project icon"
                          src="//dashboard.processout.com/images/add.svg"
                          style={{
                            height: "1.1em",
                            marginRight: "1em",
                          }}
                        />
                        Create new project
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!dropdownvisible && (
              <SideMenu location={location} sandbox={params.project.includes("test-")} />
            )}
          </div>
          <div
            className="large-10 medium-9 small-12 columns panel-content normal-bg"
            id="skip-content"
          >
            {currentProject.fetched && !currentProject.error ? (
              children
            ) : currentProject.error ? (
              <Error />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  projectsDetails: store.projects,
  replace,
  currentProject: store.currentProject,
}))(withRouter(Nav))
