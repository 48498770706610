import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL } from "^/util/Types"
type Props = {
  submitTitle: string
  submitCallback: () => void
  deleteCallback?: () => void
  cancelCallback?: () => void
  formId?: string
} & $Dispatcher
type State = {
  locked: boolean
}
export class ModalFooter extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      locked: false,
    }
  }

  lock() {
    this.setState({
      locked: true,
    })
  }

  unlock() {
    this.setState({
      locked: false,
    })
  }

  render() {
    let deleteButton

    if (this.props.deleteCallback) {
      deleteButton = (
        <a
          onClick={this.props.deleteCallback}
          className="round border small button"
          style={{
            minWidth: "100px",
            opacity: 0.5,
          }}
        >
          Delete
        </a>
      )
    }

    let submitButton = (
      <input
        type="button"
        onClick={this.props.submitCallback}
        className="round small main button white-text"
        disabled={this.state.locked}
        value={this.props.submitTitle}
        style={{
          minWidth: "100px",
          height: "40px",
        }}
      />
    )
    if (this.props.formId)
      submitButton = (
        <input
          type="submit"
          form={this.props.formId}
          className="round small main button white-text"
          disabled={this.state.locked}
          value={this.props.submitTitle}
          style={{
            minWidth: "100px",
            height: "40px",
          }}
        />
      )

    if (this.state.locked) {
      submitButton = (
        <a
          className="round small main button white-text"
          style={{
            minWidth: "100px",
            height: "40px",
          }}
        >
          <div className="loading-spinner" />
        </a>
      )
    }

    return (
      <div className="row">
        <div className="medium-4 columns">{deleteButton}</div>
        <div className="medium-8 columns text-right">
          <input
            type="button"
            onClick={function (event) {
              event.preventDefault()
              this.props.dispatch({
                type: CLOSE_MODAL,
              })
              if (this.props.cancelCallback) this.props.cancelCallback()
            }.bind(this)}
            className="round border small button"
            disabled={this.state.locked}
            value="Cancel"
            style={{
              minWidth: "100px",
              height: "40px",
            }}
          />
          {submitButton}
        </div>
      </div>
    )
  }
}
export default connect(null, null, null, {
  withRef: true,
})(ModalFooter)
