import type { $State } from "../../util/Types"
import { FETCH_WEBHOOKS } from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import type { $Webhook } from "^/features/Webhooks/consts"
export type $WebhooksState = {
  webhook_endpoints: Array<$Webhook>
} & $State
const defaultState: $WebhooksState = {
  fetched: false,
  fetching: false,
  error: null,
  webhook_endpoints: [],
}
export default function (state: $WebhooksState = defaultState, action: any): $WebhooksState {
  switch (action.type) {
    case FETCH_WEBHOOKS: {
      return { ...state, fetched: false, fetching: true, error: null, webhook_endpoints: [] }
    }

    case typeFulfilled(FETCH_WEBHOOKS): {
      return { ...state, fetched: true, fetching: false, ...action.payload }
    }

    case typeFailed(FETCH_WEBHOOKS): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }
  }

  return state
}
