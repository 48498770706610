import React from "react"
import CardSchemeImage from "../../util/CardSchemeImage"
import type { $Card } from "^/Types/Card"
type Props = {
  card: $Card
  isFirst: boolean // is first of its category
}

class CardItem extends React.Component<Props> {
  render() {
    const { card, isFirst } = this.props
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          {isFirst && (
            <div className="row collapse item-search-category">
              <div className="medium-12 columns">Cards</div>
            </div>
          )}
          <div className="row collapse item-resource-row highlight">
            <div className="medium-12 columns">
              <div className="row collapse">
                <div
                  className="medium-2 columns"
                  style={{
                    position: "relative",
                    top: ".5em",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "50px",
                    }}
                  >
                    {CardSchemeImage(card)}
                  </div>
                </div>
                <div
                  className="medium-10 columns"
                  style={{
                    position: "relative",
                    top: ".25em",
                  }}
                >
                  <div className="row collapse">
                    <div className="medium-12 columns">{card.name}</div>
                  </div>
                  <div className="row collapse">
                    <div className="medium-12 columns greyed font-console card-information">
                      {card.iin}XXXXXX{card.last_4_digits} {`0${card.exp_month}`.slice(-2)}/
                      {card.exp_year}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CardItem
