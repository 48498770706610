import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import { Link } from "react-router"
import * as ProcessOut from "../util/ProcessOut"
import type { $Project } from "../util/Types"
import { $LaunchDarklyState } from "../vNext/LaunchDarkly/LaunchDarklyReducer"

export class MetadataDisplay extends React.Component {
  props: {
    metadata: Record<string, string>
    displayShipping?: boolean | null | undefined
    displayTaxes?: boolean | null | undefined
    currency?: string | null | undefined
    currentProject: $Project
    launchDarkly: $LaunchDarklyState
  }

  render() {
    const { metadata, launchDarkly } = this.props
    const metadatas: Array<{
      key: string
      value: string
    }> = Object.keys(metadata).map(key => ({
      key,
      value: metadata[key],
    }))

    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-12 columns">
              {metadatas.map(data => {
                if (
                  (data.key === "shipping_amount" && !this.props.displayShipping) ||
                  (data.key === "taxes_amount" && !this.props.displayTaxes)
                )
                  return
                let value

                if (data.key.includes("amount")) {
                  value = (
                    <FormattedNumber
                      value={data.value}
                      style="currency"
                      currency={this.props.currency || "USD"}
                    />
                  )
                } else {
                  value = data.value
                }

                const transactionsLink = launchDarkly.flags["new-dash-transactions-page"] ? (
                  <a
                    href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${ProcessOut.config.ProjectId}/transactions?metadata.${data.key}=${value}`}
                    target="_self"
                    data-testid="new-dashboard-link"
                  >
                    {value}
                  </a>
                ) : (
                  <Link
                    to={`/projects/${
                      ProcessOut.config.ProjectId
                    }/transactions?filter=${encodeURIComponent(
                      `metadata.${data.key} == "${value}"`,
                    )}`}
                    data-testid="old-dashboard-link"
                  >
                    {value}
                  </Link>
                )

                return (
                  <div className="row small-margin-bottom" key={data.key}>
                    <div className="large-5 columns">
                      <span className="greyed font-console">{data.key}</span>
                    </div>
                    <div
                      className="large-7 columns"
                      style={{
                        wordWrap: "break-word",
                      }}
                    >
                      <span
                        className="right"
                        style={{
                          wordBreak: "break-all",
                        }}
                      >
                        {transactionsLink}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  currentProject: store.currentProject,
  launchDarkly: store.launchDarkly,
}))(MetadataDisplay)
