import React from "react"
import moment from "moment"
import type { $AlertEvent } from "../consts"
import LineChart from "../../analytics/Charts/LineChart"
type Props = {
  event: $AlertEvent
}

class Event extends React.Component<Props> {
  render() {
    const { event } = this.props
    const normalPoints = event.points.filter(point => !point.triggered && !point.recovered)
    const triggeredPoints = event.points.filter(point => point.triggered).concat([normalPoints[0]])
    const recoveredPoints = event.points
      .filter(point => point.recovered)
      .concat([triggeredPoints[0]])
    return (
      <div className={`alert-event-row row${event.recovered ? " recovered" : ""}`}>
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              <span className="bold">{event.recovered ? "Recovered " : "Triggered "}</span>
              {!event.recovered && moment(event.triggered_at).calendar()}
              {event.recovered && moment(event.updated_at).calendar()}
              {event.recovered && <span> | triggered </span>}
              {event.recovered && (
                <span className="bold">{moment(event.triggered_at).calendar()}</span>
              )}{" "}
              <span className="greyed bold"> from {event.id}</span>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-6 columns">
              <span className="alert-title">{event.alert.name}</span>
            </div>
            <div className="medium-6 columns text-right">
              Checked every{" "}
              <span className="alert-interval-tag greyed">
                {moment.duration(event.alert.check_interval * 1000).humanize()}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <LineChart
                colors={[
                  {
                    key: "normal",
                    color: "#7986cb",
                  },
                  {
                    key: "recovered",
                    color: "#7986cb",
                  },
                  {
                    key: "triggered",
                    color: "rgb(226, 89, 80)",
                  },
                ]}
                data={[
                  {
                    datapoints: normalPoints,
                    key: "normal",
                  },
                  {
                    datapoints: triggeredPoints,
                    key: "triggered",
                  },
                  {
                    datapoints: recoveredPoints,
                    key: "recovered",
                  },
                ]}
                plotted_field={null}
                type="percentage"
                name="Event timeline"
                hidePoints
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Event
