import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import Loader from "../../components/Loader"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
import type { $Collaborator, $PermissionGroup } from "./consts"
import type { $PermissionsState } from "./reducer"
type Props = {
  collaborator: $Collaborator
  permissionsDetails: $PermissionsState
} & $Dispatcher
const CREATE_NEW_GROUP = "CREATE_NEW_GROUP"
export class PermissionGroupSelection extends React.Component<Props> {
  groupSelected = (newValue: { label: string; value: string }) => {
    const { dispatch, collaborator } = this.props

    if (newValue.value === CREATE_NEW_GROUP) {
      // Create new permission group
    } else if (newValue.value === "owner") {
      dispatch(Actions.setCollaboratorRole(collaborator, "owner"))
    } else {
      dispatch(Actions.setCollaboratorPermissionGroup(collaborator, newValue.value))
    }
  }

  getOptions(permissionGroups: Array<$PermissionGroup>) {
    return [
      {
        value: "owner",
        label: "owner",
      },
    ].concat(
      permissionGroups.map(group => ({
        value: group.name,
        label: group.name,
      })),
    )
  }

  render() {
    const { collaborator, permissionsDetails } = this.props
    const permissionGroupsDetails = permissionsDetails.permissionGroups
    const loading = !permissionGroupsDetails.fetched || permissionGroupsDetails.fetching
    return loading ? (
      <Loader size={12} />
    ) : (
      <div
        style={{
          width: "90%",
          display: "inline-block",
          verticalAlign: "middle",
        }}
      >
        <Select
          className="react-select"
          value={{
            label: collaborator.permission_group_name || collaborator.role,
            value: collaborator.permission_group_name || collaborator.role,
          }}
          options={this.getOptions(permissionGroupsDetails.fetchedState.permissionGroups)}
          onChange={this.groupSelected}
        />
      </div>
    )
  }
}
export default connect(store => ({
  permissionsDetails: store.permissions,
}))(PermissionGroupSelection)
