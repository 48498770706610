import React from "react"

class AdyenFlow extends React.Component<{
  hide: boolean
  onChange: (arg0: "telescope" | "smart-router") => void
}> {
  render() {
    if (this.props.hide) return null
    return (
      <div>
        <div className="row text-center">
          <div className="medium-12 columns">
            <div className="row">
              <div className="callout alert">
                Following Adyen&apos;s request: Merchants onboarded after April 2021 – Adyen
                integration is not available. Please contact your sales manager for information on
                the other integrations available.
              </div>
              <div className="callout warning">
                Merchants onboarded and already connected to Adyen on ProcessOut before April 2021 –
                your service will continue as usual.{" "}
              </div>
            </div>
            <div className="row">
              <div
                className="medium-6 columns"
                style={{
                  borderRight: "1px solid #0d113f",
                  paddingTop: "1em",
                }}
              >
                <div className="row small-margin-bottom">
                  <h3 className="bold">Telescope</h3>
                </div>
                <div className="row small-margin-bottom">
                  <div className="medium-10 small-centered columns">
                    <p>
                      Connect your Adyen account to Telescope to get access to ProcessOut&apos;s
                      live monitoring tools.
                    </p>
                    <p>
                      This does not give access to the ProcessOut&apos;s Smart-Routing features.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="medium-6 columns"
                style={{
                  paddingTop: "1em",
                }}
              >
                <div className="row small-margin-bottom">
                  <h3 className="bold">Router</h3>
                </div>
                <div className="row small-margin-bottom">
                  <div className="medium-10 small-centered columns">
                    <p>
                      Leverage the full power of ProcessOut by connecting Adyen to ProcessOut&apos;s
                      Smart-Routing features.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="medium-6 columns"
            style={{
              borderRight: "1px solid #0d113f",
            }}
          >
            <div className="row small-margin-bottom">
              <div className="medium-11 medium-centered columns">
                <a
                  className="round border button expanded"
                  onClick={this.props.onChange.bind(null, "telescope")}
                >
                  Set up Telescope
                </a>
              </div>
            </div>
          </div>
          <div className="medium-6 columns">
            <div className="row small-margin-bottom">
              <div className="medium-11 medium-centered columns">
                <a
                  className="round border button expanded"
                  onClick={this.props.onChange.bind(null, "smart-router")}
                >
                  Set up Smart routing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdyenFlow
