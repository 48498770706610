import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import type { $Dispatcher, $Project, $State } from "../../util/Types"
import TimespanSelector from "./Components/TimespanSelector"
import Section from "./Section"
import TransactionsTab from "./TransactionsTab"
// @ts-expect-error
import "./telescope.scss"
import * as TelescopeActions from "./actions"
import type { $TelescopeState } from "../../stores/Reducers/TelescopeReducer/Index"
import Loading from "../../components/Loader"
import type { $SurfacesState } from "./Surface/reducer"
import ContentLayout from "../../pages/ContentLayout"
import Surfaces from "./Surface/Surfaces"
import Error from "../../components/Error"
import Header from "./Surface/Header"
import { REQUEST_SURFACES_FETCH } from "./Surface/consts"
import Empty from "../../components/Empty"
import SurfaceSelector from "./Surface/SurfaceSelector"
type Props = {
  currentProject: $State & {
    project: $Project | null | undefined
  }
  telescope: $TelescopeState
  surfaces: $SurfacesState
} & $Dispatcher
type State = {
  hoveredSurface: any | null | undefined
}

class Telescope extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      hoveredSurface: null,
    }
  }

  componentDidMount() {
    document.title = "Telescope | ProcessOut"
    // we reset the url query
    this.props.dispatch(push(`${this.props.location.pathname}`))
    this.props.dispatch({
      type: REQUEST_SURFACES_FETCH,
      payload: {
        timespan: this.props.telescope.standard.timespan,
      },
    })
  }

  componentWillUnmount() {
    this.props.dispatch(TelescopeActions.resetTelescopeState())
  }

  onSurfaceOpen = surfaceId => {
    const element = document.getElementById(`transaction-cut-${surfaceId}`)
    if (element) element.className = `${element.className.replace(/ highlighted/g, "")} highlighted`
    this.setState({
      hoveredSurface: surfaceId,
    })

    for (const s of this.props.surfaces.surfaces) {
      if (s.id !== surfaceId) {
        const element = document.getElementById(`transaction-cut-${s.id}`)
        if (element) element.className = element.className.replace(/ highlighted/g, "")
      }
    }
  }
  onSurfaceClose = surfaceId => {
    const element = document.getElementById(`transaction-cut-${surfaceId}`)
    if (element) element.className = element.className.replace(/ highlighted/g, "")
    this.setState({
      hoveredSurface: null,
    })
  }

  render() {
    if (this.props.currentProject.fetching || !this.props.currentProject.fetched) return <Loading />
    return (
      <div>
        <div className="row large-margin-bottom">
          <div className="large-12 columns">
            <Section title="Overview" content={<TimespanSelector />}>
              <TransactionsTab />
            </Section>
          </div>
        </div>
        <Section
          title={
            <div>
              <span>Telescope diagnostic</span>
            </div>
          }
          content={<SurfaceSelector />}
        />

        {!this.props.surfaces.fetched && !this.props.surfaces.error ? (
          <div
            style={{
              marginBottom: "2em",
            }}
          >
            <Loading />
          </div>
        ) : this.props.surfaces.error ? (
          <div
            style={{
              marginBottom: "2em",
            }}
          >
            <Error />
          </div>
        ) : (
          <div>
            {this.props.surfaces.generating ? (
              <div
                className="row large-margin-bottom"
                style={{
                  marginTop: "2em",
                }}
              >
                <div className="medium-12 columns text-center">
                  <img
                    alt="Magnifying glass icon"
                    src="//dashboard.processout.com/images/analyzing.png"
                    style={{
                      verticalAlign: "center",
                      width: "2em",
                      marginRight: "1em",
                    }}
                  />
                  <div className="large-6 small-centered">
                    Our engineers are currently tweaking the Telescope algorithm. In the meantime,
                    our data-scientists are available to give you an in-depth analysis about your
                    payment performance. Feel free to reach out to us!
                  </div>
                </div>
              </div>
            ) : this.props.surfaces.surfaces.length === 0 ? (
              <Empty text="No recommendations were found" />
            ) : (
              <div>
                {this.props.surfaces.type === "authorization-rate" ? (
                  <Header
                    onHover={surfaceId => {
                      this.onSurfaceOpen(surfaceId)
                    }}
                    hoveredSurface={this.state.hoveredSurface}
                  />
                ) : null}
                <ContentLayout noBackground noPadding>
                  <Surfaces
                    hoveredSurface={this.state.hoveredSurface}
                    onOpen={surfaceId => {
                      this.onSurfaceOpen(surfaceId)
                    }}
                    onClose={(surfaceId: string) => {
                      this.onSurfaceClose(surfaceId)
                    }}
                  />
                </ContentLayout>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
  telescope: store.telescope,
  surfaces: store.surfaces,
}))(Telescope)
