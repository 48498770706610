import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import uniqid from "uniqid"
import ContentLayout from "../../pages/ContentLayout"
import * as fraudServicesConfigurationActions from "../../features/FraudServicesConfiguration/actions"
import Loader from "../Loader"
import type { $Dispatcher, $State } from "../../util/Types"
import FraudServicesSearch from "../../util/FraudServicesSearch"
import FraudConfiguration from "./FraudConfiguration"
import Empty from "../Empty"
import Project from "../../util/Project"
import InformationContainer from "../../features/InformationContainer/InformationContainer"
const PROVIDERS_PER_LINE = 6
type Props = {
  fraudServicesConfiguration: {
    fraudServices: Array<any> | null | undefined
  } & $State
  params: {
    project: string
  }
} & $Dispatcher
type State = {
  fraudSearchList: Array<any> | null | undefined
}
export class FraudConfigurations extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      fraudSearchList: null,
    }
  }

  componentDidMount() {
    this.props.dispatch(fraudServicesConfigurationActions.fetchFraudServicesConfig())
  }

  filter = e => {
    const filter = e.target.value

    if (filter === "") {
      this.setState({
        fraudSearchList: null,
      })
      return
    }

    this.setState({
      fraudSearchList: FraudServicesSearch(
        this.props.fraudServicesConfiguration.fraudServices,
        filter,
      ),
    })
  }
  renderGroup = (confsArray): Array<React.ReactElement<React.ComponentProps<"div">, "div">> => {
    const rowsN = confsArray.length / PROVIDERS_PER_LINE
    const FraudConfRows = []

    for (let j = 0; j < rowsN; j++) {
      let count = -1
      const row = (
        <div className="row" key={Math.floor(Math.random() * 100000)}>
          {confsArray
            .slice(j * PROVIDERS_PER_LINE, j * PROVIDERS_PER_LINE + PROVIDERS_PER_LINE)
            .map(config => {
              count++
              let last = ""
              if (count + j * PROVIDERS_PER_LINE == confsArray.length - 1) last = "end"
              return (
                <div key={`config-${count}`} className={`medium-2 ${last} columns margin-bottom`}>
                  <FraudConfiguration key={config.id} config={config} />
                </div>
              )
            })}
        </div>
      )
      FraudConfRows.push(row)
    }

    return FraudConfRows
  }

  render() {
    const tiles: Array<
      | React.ReactElement<React.ComponentProps<"div">, "div">
      | Array<React.ReactElement<React.ComponentProps<"div">, "div">>
    > = []
    const { fraudServicesConfiguration, params } = this.props
    const configurations = this.props.fraudServicesConfiguration.fraudServices
    const { fraudSearchList } = this.state
    const project = new Project(params.project)

    if (!fraudServicesConfiguration.fetched || fraudServicesConfiguration.fetching) {
      return (
        <ContentLayout title={<h4>Your activated fraud services</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    let empty

    if (!configurations.length) {
      empty = <Empty text="No fraud service has been activated yet" />
    } else if (fraudSearchList && fraudSearchList.length === 0) {
      empty = <Empty text="No fraud service matches your search" />
    }

    if (fraudSearchList) {
      tiles.push(
        <div className="row" key={uniqid()}>
          <div className="large-12 columns margin-bottom">{this.renderGroup(fraudSearchList)}</div>
        </div>,
      )
    } else if (configurations.length) {
      tiles.push(
        <div className="row" key={uniqid()}>
          <div className="large-12 columns margin-bottom">{this.renderGroup(configurations)}</div>
        </div>,
      )
    }

    return (
      <ContentLayout title={<h4>Your activated fraud services</h4>}>
        {project.isSandbox() && (
          <InformationContainer info="You are updating your Sandbox Fraud Service configurations." />
        )}
        <div className="row">
          <div className="large-12 columns">
            <div className="row small-margin-bottom">
              <div className="large-4 end columns">
                <input type="text" placeholder="Search" onChange={this.filter} />
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                {tiles}
                {empty}
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}
export default connect(store => ({
  fraudServicesConfiguration: store.fraudServiceConfigurationsReducer,
  projects: store.projects,
}))(withRouter(FraudConfigurations))
