import type { $Action, $State } from "^/util/Types"
import { FETCH_SUBSCRIPTION_TRANSACTIONS } from "^/Actions/SubscriptionsActions"
import type { TransactionModel } from "^/Types/Transaction"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type $SubscriptionTransactionsDetailsState = {
  transactions: Array<TransactionModel>
  count: number
} & $State
const defaultState: $SubscriptionTransactionsDetailsState = {
  fetching: false,
  fetched: false,
  error: null,
  transactions: [],
  count: 0,
}
export default function (
  state: $SubscriptionTransactionsDetailsState = defaultState,
  action: $Action,
) {
  switch (action.type) {
    case typePending(FETCH_SUBSCRIPTION_TRANSACTIONS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_SUBSCRIPTION_TRANSACTIONS): {
      return { ...state, ...action.payload, fetching: false, fetched: true }
    }
  }

  return state
}
