import type { $Action, $State } from "../../util/Types"
import { ANONYMIZE_VAULT_CARD, FETCH_VAULT_CARDS } from "^/Actions/VaultActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import type { $Card } from "^/Types/Card"
export type $VaultState = {
  cards: Array<$Card>
  cardAnonymized: boolean
} & $State
const defaultState: $VaultState = {
  fetching: false,
  fetched: false,
  cardAnonymized: false,
  error: undefined,
  cards: [],
}
export default function (state: $VaultState = defaultState, action: $Action): $VaultState {
  switch (action.type) {
    case typePending(FETCH_VAULT_CARDS): {
      return { ...state, ...defaultState, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_VAULT_CARDS): {
      return { ...state, ...action.payload, fetching: false, fetched: true }
    }

    case typeFailed(FETCH_VAULT_CARDS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }

    case typeFulfilled(ANONYMIZE_VAULT_CARD): {
      return { ...state, ...action.payload, cardAnonymized: true }
    }

    case typeFailed(ANONYMIZE_VAULT_CARD): {
      return { ...state, error: action.payload }
    }
  }

  return state
}
