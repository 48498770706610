import React, { ReactNode, useEffect, useRef, useState } from "react"
import "./DropDown.scss"

export type $ContentMenu = {
  label: string
  render: () => ReactNode
  isSeparate?: boolean
}

export type Props = {
  customIcon: ReactNode
  options: $ContentMenu[]
}

export const DropDown: React.FC<Props> = ({ customIcon, options }) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (!isOpen) return

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    const onKeyDown = event => {
      if (event.key === "Escape") {
        setIsOpen(false)
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [isOpen])

  const listenForSubmit = event => {
    if (event.key === "Enter" || event.key === "Space") {
      setIsOpen(false)
    }
  }

  return (
    <div className="dropdown-menu" ref={ref}>
      <div className="dropdown-icon-container">
        <button aria-label="edit route" onClick={toggleMenu}>
          {customIcon}
        </button>
      </div>

      <div
        data-testid="dropdown-menu"
        className={`${isOpen ? "is-menu-visible" : "is-not-menu-visible"}`}
      >
        <ul className="dropdown-menu-content">
          {options.map(d => (
            <li
              className={`${d.isSeparate ? "isolate-item" : ""}`}
              key={d.label}
              onKeyDown={event => listenForSubmit(event)}
            >
              {d.render()}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DropDown
