import React from "react"
import { connect } from "react-redux"
import Metadata from "../Metadata"
import * as CustomerActions from "../../Actions/CustomerActions"
import ModalFooter from "../ModalFooter"
import CountrySelector from "./CountrySelector"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { Customer } from "^/Types/Customer"
import { track } from "../../vNext/tracking"

const serialize = require("form-serialize")

type Props = {
  creating: boolean
  customer?: Customer
} & $Dispatcher

class DetailsModal extends React.Component<Props> {
  closeModal() {
    this.props.dispatch({
      type: CLOSE_MODAL,
    })
  }

  unlockModal() {
    this._modalFooter.getWrappedInstance().unlock()
  }

  submit(event) {
    event.preventDefault()
    const { creating } = this.props

    track(
      "Customer details",
      "Click",
      `${creating ? "Create" : "Save"}`,
      `${creating ? "Create" : "Save"} button`,
    )

    this._modalFooter.getWrappedInstance().lock()

    const invoiceForm = document.querySelector("#customer-edit")
    const data = serialize(invoiceForm, {
      hash: true,
    })

    if (data.country_code == "NONE") {
      delete data.country_code
      data.country_code = null
    }

    if (!data.metadata) data.metadata = {}

    const metas = this._meta.updateValue()

    for (let i = 0; i < metas.length; i++) {
      data.metadata[metas[i].key] = metas[i].value
    }

    const customer = JSON.stringify(data, null, 2)
    if (this.props.creating)
      this.props.dispatch(
        CustomerActions.createCustomer(
          customer,
          this.closeModal.bind(this),
          this.unlockModal.bind(this),
        ),
      )
    else
      this.props.dispatch(
        CustomerActions.editCustomer(
          this.props.customer.id,
          customer,
          this.closeModal.bind(this),
          this.unlockModal.bind(this),
        ),
      )
  }

  openModal() {
    let customer
    if (this.props.customer) customer = this.props.customer
    else
      customer = {
        metadata: {},
      }
    let title
    if (this.props.creating) title = "New customer"
    else if (!customer.first_name && !customer.last_name) title = "Anonymous user"
    else title = `${customer.first_name || ""} ${customer.last_name || ""}`
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: title,
        content: (
          <form id="customer-edit">
            <div className="row">
              <div className="large-12 columns">
                <h6 className="capitalize greyed font-console">Details</h6>
              </div>
            </div>
            <div className="row">
              <div className="medium-6 columns">
                <input
                  type="text"
                  key={`first.name:${customer.first_name}`}
                  placeholder="First name"
                  name="first_name"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.first_name}
                  required
                />
              </div>
              <div className="medium-6 columns">
                <input
                  type="text"
                  key={`last.name:${customer.last_name}`}
                  placeholder="Last name"
                  name="last_name"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.last_name}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns">
                <input
                  type="text"
                  key={`address1:${customer.address1}`}
                  placeholder="Address 1"
                  name="address1"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.address1}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns">
                <input
                  type="text"
                  key={`address2:${customer.address2}`}
                  placeholder="Address 2"
                  name="address2"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.address2}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="medium-6 columns">
                <input
                  type="text"
                  key={`city:${customer.city}`}
                  placeholder="City"
                  name="city"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.city}
                  required
                />
              </div>
              <div className="medium-6 columns">
                <input
                  type="text"
                  key={`zip:${customer.zip}`}
                  placeholder="Zip"
                  name="zip"
                  className="bottom-border"
                  id="item-name"
                  defaultValue={customer.zip}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns">
                <CountrySelector defaultValue={customer.country_code} />
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <h6 className="capitalize greyed font-console">Additional data</h6>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <Metadata resource={customer} ref={m => (this._meta = m)} />
              </div>
            </div>
          </form>
        ),
        footer: (
          <div className="row">
            <div className="medium-12 columns text-right">
              <ModalFooter
                ref={m => (this._modalFooter = m)}
                submitTitle={this.props.creating ? "Create" : "Save"}
                submitCallback={this.submit.bind(this)}
                cancelCallback={() => track("Customer details", "Click", "Cancel", "Cancel button")}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}

export default connect(null, null, null, {
  withRef: true,
})(DetailsModal)
