import React from "react"
import TelescopeTitle from "../components/TelescopeTitle"
import { $Profile, $RecommendationError } from "../consts"
import { displayValueFromDictionary } from "../dictionary"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"

const PropToLabelMapping = {
  gateway_name: "Gateway name",
  op_gateway_configuration_uid: "Gateway configuration",
  card_scheme: "Card scheme",
  card_iin: "BIN/IIN",
  card_type: "Card type",
  tr_merchant_initiator_type: "MIT",
  card_category: "Card category",
  card_country_code: "Card country code",
  card_bank_name: "Card bank name",
  op_type: "Operation type",
  op_acquirer_name: "Acquirer name",
  is_card_verification: "Card verification",
  op_payment_type: "Payment type",
}

type Props = {
  profile: $Profile
}

// get values from dictionary and show it in user friendly format
function shapeProfileErrorValues(data: Array<$RecommendationError>) {
  return data.map(({ type, merchant_percentage }) => ({
    value: merchant_percentage / 100 ?? 0,
    key: type ?? "",
  }))
}

function shapeProfilesType(data: $Profile) {
  return {
    key: data.profile.op_type,
    value: data.target,
  }
}

export default function DiagnosticProfile({ profile }: Props) {
  const profileErrorDatas = shapeProfileErrorValues(profile.errors)
  const profileDatas = shapeProfilesType(profile)
  const formattedDatas = chartFormattedData(profileDatas)
  let warning: React.ReactNode
  if (profile.profile.avs_street_check == "True") {
    warning = (
      <>
        <div>
          <strong>Warning: </strong>transaction volume varied by{" "}
          <strong>{profile.profile.avs_postal_check}</strong> for this profile
        </div>
      </>
    )
  }

  return (
    <div data-tracking-location="Profile">
      <p data-testid="diagnostic-profile-information">
        <i>Transaction profile: </i>
        {profile.actions.map((action, index) => {
          return (
            <React.Fragment key={index}>
              <span>
                <span>{index !== 0 ? ", " : ""}</span>
                <span>{PropToLabelMapping[action.type]}: </span>
                <strong>{displayValueFromDictionary(action.text.toString())}</strong>
              </span>
            </React.Fragment>
          )
        })}
        {warning}
      </p>
      <div className="row large-margin-bottom">
        <div className="medium-6 columns">
          <TelescopeTitle
            title="Conversion rate drop"
            tooltip={{
              content: "Number of successful transactions / total number of transaction requests",
              id: "authorization_rate",
            }}
          />
          <BarChartHorizontal
            datas={formattedDatas}
            limit={{
              key: "Previous period:",
              value: parseFloat((profile.benchmark * 100).toFixed(2)),
            }}
            name={"Authorization rate"}
          />
        </div>
        <div className="medium-6 columns">
          <TelescopeTitle
            title="Main decline reasons"
            tooltip={{
              content: "Breakdown of failed authorizations against their decline reasons",
              id: "decline_reasons",
            }}
          />
          <BarChartHorizontal
            datas={chartFormattedData(profileErrorDatas)}
            axisLeft={180}
            name="Decline Reasons"
          />
        </div>
      </div>
    </div>
  )
}
