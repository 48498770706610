import React, { useEffect, useRef } from "react"
import { Button } from "^/vNext/Button"
import { Creatable } from "^/vNext/Select/Select"
import { SwitchToggle } from "^/vNext/SwitchToggle/SwitchToggle"
import { trackTarget } from "^/vNext/tracking"
import type { $Declaration, $RoutingRule } from "../../../features/RoutingRules/consts"
import { AuthorizationSection } from "./AuthorizationSection"
import { ReactComponent as SvgIcon } from "./close-icon.svg"
import "./CriteriaMenu.scss"
import { Dynamic3DSSelection } from "./Dynamic3DSSelection"
import { RuleCondition } from "./RuleCondition"

type Props = {
  onClose: () => void
  number: number
  onDeletion: (id: string) => void
  rule: $RoutingRule
  onUpdateRule: (rule: $RoutingRule) => void
  position: { x: number; y: number }
}

const DECLARATION_NAME_MAP: Record<$Declaration, string> = {
  block: "Blocking",
  dynamic_3ds: "Dynamic 3DS",
  route: "Routing",
  trigger_3ds: "Trigger 3DS filters",
}

export const CriteriaMenu = React.forwardRef(
  (
    { number, onClose, onDeletion, rule, onUpdateRule, position }: Props,
    forwardRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const conditions = rule.conditions[0].filters
    const authorizations = rule.gateways

    const menuRef = useRef()

    const ref = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
      ref.current?.focus()
    }, [])

    // To match the design remove all the borders and indicators in the selection
    const customStyles = {
      control: () => ({
        border: "none",
      }),

      dropdownIndicator: () => ({
        display: "none",
      }),

      clearIndicator: () => ({
        display: "none",
      }),

      indicatorSeparator: () => ({
        display: "none",
      }),
    }

    return (
      <>
        {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          ref={forwardRef}
          data-tracking-location="Criteria Menu"
          className="CriteriaMenu"
          data-testid="criteria-menu"
          style={{ left: position.x, top: position.y }}
          onKeyDown={evt => {
            // Stop any keydown event propagate to global KeyboardHandler.
            // This will stop arrow or delete key taken effect in KeyboardHandler when any fields are focused in criteria menu.
            evt.stopPropagation()

            if (evt.key === "Escape") {
              onClose()
            }
          }}
        >
          <div ref={menuRef} className="CriteriaMenu__head">
            <div className="CriteriaMenu__close">
              <Button variant="shell" onClick={onClose} aria-label="close">
                <SvgIcon />
              </Button>
            </div>
            <div className="CriteriaMenu__control">
              <span className="CriteriaMenu__number">{number}.</span>
              <Creatable
                ref={ref}
                styles={customStyles}
                options={[]}
                value={rule.tags.map(tag => ({
                  label: tag,
                  value: tag,
                }))}
                onChange={changes => {
                  const newRule = structuredClone(rule)
                  newRule.tags = changes.map(change => change.value)
                  onUpdateRule(newRule)

                  trackTarget(menuRef.current, "Tag Change", "Tag List")
                }}
                placeholder="Input tags"
                isMulti
              ></Creatable>
            </div>
          </div>
          <div className="CriteriaMenu__content">
            <p className="CriteriaMenu__title">
              {`${DECLARATION_NAME_MAP[rule.declaration]}`} conditions
            </p>
            <div className="CriteriaMenu__sectionContainer">
              {/* Normal criterias  */}
              {conditions?.length !== 0 ? (
                <div className="CriteriaMenu__section">
                  <p className="CriteriaMenu__sectionTitle">Where criteria is</p>
                  {conditions.map((condition, idx) => (
                    <RuleCondition
                      key={condition.id}
                      name="Condition Name"
                      condition={condition}
                      onChange={newCondition => {
                        const newRule = structuredClone(rule)
                        newRule.conditions[0].filters[idx] = newCondition
                        onUpdateRule(newRule)
                      }}
                      onDeletion={onDeletion}
                    />
                  ))}
                </div>
              ) : (
                <div className="CriteriaMenu__addNew">Add new criteria from menu</div>
              )}
              {/* For dynamic 3ds params */}
              {rule.declaration === "dynamic_3ds" && (
                <Dynamic3DSSelection
                  rule={rule}
                  onChange={rule => {
                    onUpdateRule(rule)
                  }}
                ></Dynamic3DSSelection>
              )}
              {/* Authorization section only applies to route */}
              {rule.declaration === "route" && (
                <AuthorizationSection
                  authorizations={authorizations}
                  onChange={auths => {
                    const newRule = structuredClone(rule)
                    newRule.gateways = auths
                    onUpdateRule(newRule)
                  }}
                ></AuthorizationSection>
              )}

              {/* trigger 3ds specific section  */}
              {rule.declaration === "trigger_3ds" && (
                <SwitchToggle
                  onChange={() => {
                    const newRule = structuredClone(rule)
                    newRule.run_for_card_verifications = !newRule.run_for_card_verifications
                    onUpdateRule(newRule)
                  }}
                  style={{ fontSize: 16 }}
                  checked={rule?.run_for_card_verifications}
                  labelText="Trigger on card verification"
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  },
)
CriteriaMenu.displayName = "CriteriaMenu"
