import { call, put, takeLatest } from "redux-saga/effects"
import moment from "moment"
import {
  REQUEST_ALERT_DELETE,
  REQUEST_ALERT_UPDATE,
  REQUEST_ALERTS_EVENTS_FETCH,
  REQUEST_ALERTS_FETCH,
} from "./actions"
import * as ProcessOut from "../../util/ProcessOut"
import type { $Action } from "../../util/Types"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export function* fetchAlerts(): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_ALERTS_FETCH),
    })
    const alertsResult = yield call(ProcessOut.APIcallPromise, `/alerts`, "GET")
    const { has_more, count } = alertsResult.data
    yield put({
      type: typeFulfilled(REQUEST_ALERTS_FETCH),
      payload: {
        has_more,
        count,
        alerts: alertsResult.data.alerts,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_ALERTS_FETCH),
      payload: error,
    })
  }
}
export function* fetchEvents(action: $Action): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_ALERTS_EVENTS_FETCH),
    })
    const eventsResults = yield call(
      ProcessOut.APIcallPromise,
      `/alert-events?max_elements=30&start=${encodeURIComponent(moment().format())}${
        action.payload.alertId ? `&alert_id=${action.payload.alertId}` : ""
      }`,
      "GET",
    )
    yield put({
      type: typeFulfilled(REQUEST_ALERTS_EVENTS_FETCH),
      payload: {
        events: eventsResults.data.alert_events,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_ALERTS_EVENTS_FETCH),
      payload: error,
    })
  }
}

function* updateAlert(action: $Action): Generator<any, any, any> {
  try {
    const { alert } = action.payload
    yield call(ProcessOut.APIcallPromise, `/alerts/${alert.id}`, "PUT", alert)
    yield call(fetchAlerts)
  } catch (error) {
    datadogRum.addError(error)
  }
}

function* deleteAlert(action: $Action): Generator<any, any, any> {
  try {
    yield call(ProcessOut.APIcallPromise, `/alerts/${action.payload.alert}`, "DELETE")
    yield call(fetchAlerts)
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_ALERT_DELETE),
      payload: error,
    })
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_ALERTS_FETCH, fetchAlerts)
  yield takeLatest(REQUEST_ALERTS_EVENTS_FETCH, fetchEvents)
  yield takeLatest(REQUEST_ALERT_UPDATE, updateAlert)
  yield takeLatest(REQUEST_ALERT_DELETE, deleteAlert)
}
