import React from "react"
export default class Empty extends React.Component {
  props: {
    text: string | any
  }

  render() {
    const { text } = this.props
    return (
      <div className="row">
        <div className="large-12 columns text-center">
          <div>
            <p
              className="greyed"
              style={{
                fontSize: "3.5em",
                letterSpacing: "0.08em",
              }}
            >
              {"{...}"}
            </p>
            <h5 className="margin-bottom greyed">{text}</h5>
          </div>
        </div>
      </div>
    )
  }
}
