import React from "react"

class Getstarted extends React.Component<{}> {
  render() {
    return (
      <div className="row">
        <div className="large-6 columns">
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <a
                target="_blank"
                href="https://docs.processout.com/getting-started/"
                rel="noreferrer"
              >
                <div className="tile">
                  <div className="logo-div">
                    <img
                      alt="Documentation icon"
                      className="tips-img"
                      src="//dashboard.processout.com/images/documentation.png"
                    />
                  </div>
                  Discover how to get started with <b>ProcessOut</b>
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <a
                target="_blank"
                href="https://docs.processout.com/payments/processoutjs/"
                rel="noreferrer"
              >
                <div className="tile">
                  <div className="logo-div">
                    <img
                      alt="JS file icon"
                      className="tips-img"
                      src="//dashboard.processout.com/images/js.png"
                    />
                  </div>
                  Setup your payment page using ProcessOut.js
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="large-6 columns">
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <a
                target="_blank"
                href="https://docs.processout.com/subscriptions/get-started/"
                rel="noreferrer"
              >
                <div className="tile">
                  <div className="logo-div">
                    <img
                      alt="Recurring subscription icon"
                      className="tips-img"
                      src="//dashboard.processout.com/images/recurring.png"
                    />
                  </div>
                  Create your first subscription
                </div>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <a target="_blank" href="https://www.processout.com/flight-journal/" rel="noreferrer">
                <div className="tile">
                  <div className="logo-div">
                    <img
                      alt="Blog icon"
                      className="tips-img"
                      src="//dashboard.processout.com/images/blog.png"
                    />
                  </div>
                  Learn more about online payment
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Getstarted
