import React from "react"
import { FormattedNumber } from "react-intl"
import Tooltip from "../../../util/Tooltip"
import "../components/TelescopeCard.scss"

export type DiagnosticsMetricsProps = {
  title: string
  contentTooltip: string
  id: string
  diagnosticOptimization: number
  currency: string
}

const DiagnosticsMetrics: React.FC<DiagnosticsMetricsProps> = ({
  title,
  contentTooltip,
  id,
  diagnosticOptimization,
  currency,
}) => {
  let metric
  if (currency != "nil") {
    metric = <FormattedNumber value={diagnosticOptimization} style="currency" currency={currency} />
  } else {
    metric = <FormattedNumber value={diagnosticOptimization} />
  }

  return (
    <>
      <div
        className="small-margin-bottom"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>{title}</div>
        <Tooltip content={contentTooltip} id={id} size="large" place="top">
          <div
            className="info"
            data-tip
            data-for={id}
            style={{
              display: "inline-block",
            }}
          >
            i
          </div>
        </Tooltip>
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            color: "#516395",
            fontSize: "40px",
          }}
        >
          {metric}
        </div>
      </div>
    </>
  )
}

export default DiagnosticsMetrics
