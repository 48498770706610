import React from "react"
import { Link, withRouter } from "react-router"
import { connect } from "react-redux"
import type { $State } from "../../util/Types"
import "./usertag.scss"

const md5 = require("js-md5")

type Props = {
  user: {
    details: {
      email: string
      name: string
    }
  } & $State
}

class UserTag extends React.Component<{}> {
  render() {
    const { user, launchDarkly, params } = this.props
    let name = ""
    let email = ""

    if (user.details.fetched) {
      name = user.details.name
      email = user.details.email
    }

    const isNewDashSettingsEnabled = launchDarkly.flags["new-dash-settings-page"]

    const linkContent = (
      <div className="row">
        <div
          className="medium-9 columns"
          style={{
            position: "relative",
            top: ".25em",
          }}
        >
          {name}
        </div>
        <div className="medium-3 columns text-right">
          <img
            alt="Customer`s avatar"
            className="gravatar-icon"
            src={`https://secure.gravatar.com/avatar/${md5(email)}?s=80&r=g&d=identicon`}
          />
        </div>
      </div>
    )

    return (
      <div className="user-tag">
        {isNewDashSettingsEnabled ? (
          <a
            href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/settings/user`}
            target="_self"
          >
            {linkContent}
          </a>
        ) : (
          <Link to={"/user"}>{linkContent}</Link>
        )}
      </div>
    )
  }
}

export default connect(store => ({
  user: store.user,
  launchDarkly: store.launchDarkly,
}))(withRouter(UserTag))
