import * as ProcessOut from "../util/ProcessOut"
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS"
export function loadCustomers(filter?, length?, after?, id?) {
  filter = filter || ""
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_CUSTOMERS,
    payload: ProcessOut.APIcallPromise(
      `/customers?${bound}=${id}&limit=${length}&filter=${filter}`,
      "GET",
      null,
    ),
  }
}
export const EDITING_CUSTOMER = "EDITING_CUSTOMER"
export function editCustomer(id, customer, callback, error) {
  return {
    type: EDITING_CUSTOMER,
    payload: ProcessOut.APIcallPromise(`/customers/${id}`, "PUT", customer).then(response => {
      if (response.data.success) callback()
      else error()
      return response
    }),
  }
}
export const CREATING_CUSTOMER = "CREATING_CUSTOMER"
export function createCustomer(customer, callback, error) {
  return function (dispatch) {
    dispatch({
      type: CREATING_CUSTOMER,
      payload: ProcessOut.APIcallPromise(`/customers`, "POST", customer).then(response => {
        if (response.data.success) {
          dispatch(loadCustomers("", 30))
          callback()
        } else error()

        return response
      }),
    })
  }
}
