/**
 * Created by jeremylejoux on 03/10/2017.
 */
import React from "react"
import { withRouter } from "react-router"
import Error from "../../components/Error"
import { datadogRum } from "@datadog/browser-rum"
type Props = {
  location: {
    pathname: string
  }
}

class ErrorBoundary extends React.Component {
  props: Props
  state: {
    hasError: boolean
  }

  constructor() {
    super()
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
    })
    datadogRum.addError(error)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props, nextContext: any): void {
    if (this.state.hasError && nextProps.location.pathname !== this.props.location.pathname) {
      // location changed we reset the error boundary
      this.setState({
        hasError: false,
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            marginTop: "2em",
          }}
        >
          <Error />
        </div>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
