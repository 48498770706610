import React from "react"
import duration from "humanize-duration"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import type { $Dispatcher, $Project } from "../../util/Types"
import type { $Alert } from "./consts"
import * as Actions from "./actions"
import * as ConfirmActions from "../ConfirmModal/actions"
type Props = {
  alert: $Alert
  currentProject: {
    project: $Project
  }
} & $Dispatcher

class Alert extends React.Component<Props> {
  render() {
    const { alert, currentProject, dispatch } = this.props
    const interval = duration(alert.check_interval * 1000)
    return (
      <div className={`row alert-row ${alert.state}`}>
        <div
          className="medium-10 columns"
          onClick={() => {
            dispatch(
              push(`/projects/${currentProject.project.id}/alerting/events?alert_id=${alert.id}`),
            )
          }}
        >
          <div className="row small-margin-bottom">
            <div
              className="medium-6 columns"
              style={{
                fontSize: "1.1em",
              }}
            >
              {alert.name || <span className="greyed">Unnamed alert</span>}
            </div>
            <div className="medium-4 columns greyed">
              Checked every <span className="alert-interval-tag">{interval}</span>
            </div>
          </div>
        </div>
        <div className="medium-2 columns text-right">
          <a
            className="switch-button"
            onClick={() => {
              dispatch(
                Actions.requestAlertStateChange(
                  alert,
                  alert.state === "enabled" ? "disabled" : "enabled",
                ),
              )
            }}
          >
            {alert.state === "enabled" ? "Disable" : "Enable"}
          </a>
          <a
            className="delete-button"
            onClick={() => {
              dispatch(
                ConfirmActions.requestConfirmation(
                  <div className="row">
                    <div className="large-11 columns large-centered">
                      Do you really want to delete this alert?
                    </div>
                  </div>,
                  () => {
                    dispatch(Actions.requestAlertDelete(alert.id))
                  },
                  () => {},
                ),
              )
            }}
          >
            <img alt="Trash icon" src="//dashboard.processout.com/images/delete.png" />
          </a>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(Alert)
