import React from "react"
import { connect } from "react-redux"
import type { $GatewayConfiguration } from "./consts"
import GatewayModal from "./GatewayModal"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import * as GatewayActions from "./actions"
import * as GatewaysActions from "../../Actions/GatewaysActions"
import * as ProcessOut from "../../util/ProcessOut"
import type { $CurrencyOption } from "../CurrencySelector/CurrencySelector"
import CurrencySelector from "../CurrencySelector/CurrencySelector"
import ModalFooter from "../../components/ModalFooter"
type Props = {
  configuration: $GatewayConfiguration
} & $Dispatcher
type State = {
  saving: boolean
}

class ConfigurationModal extends React.Component<Props, State> {
  _contentRef: any
  _modalFooterRef: any

  constructor() {
    super()
    this._contentRef = React.createRef()
    this._modalFooterRef = React.createRef()
    this.state = {
      saving: false,
    }
  }

  handleSave = () => {
    this._modalFooterRef.current.getWrappedInstance().lock()

    const { name, sub_accounts, default_currency } = this._contentRef.current.getData()

    const { configuration, dispatch } = this.props
    const newConfiguration = {
      ...configuration,
      name,
      default_currency,
      sub_accounts_enabled: sub_accounts,
    }
    dispatch(
      GatewayActions.updateGatewayConfiguration(newConfiguration, success => {
        if (success) {
          dispatch({
            type: CLOSE_MODAL,
          })
          dispatch(GatewaysActions.loadGatewaysConfigurations(ProcessOut.config.ProjectId))
        }

        this._modalFooterRef.current.getWrappedInstance().unlock()
      }),
    )
  }
  openModal = () => {
    const { configuration } = this.props
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: (
          <div className="row">
            <div className="medium-12 columns text-center">
              <div className="row small-margin-bottom">
                <div className="medium-12 columns">{configuration.gateway.display_name}</div>
              </div>
              <div className="row">
                <div className="medium-12 columns greyed small-font font-console">
                  {configuration.id}
                </div>
              </div>
            </div>
          </div>
        ),
        content: <ModalContent configuration={configuration} ref={this._contentRef} />,
        footer: (
          <div className="row">
            <div className="medium-12 columns">
              <ModalFooter
                submitTitle="Save"
                submitCallback={this.handleSave}
                ref={this._modalFooterRef}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return <div id="config-modal" />
  }
}

export default connect(null, null, null, {
  withRef: true,
})(ConfigurationModal)

class ModalContent extends React.Component<
  {
    configuration: $GatewayConfiguration
  },
  {
    name: string
    default_currency: $CurrencyOption | null | undefined
    sub_accounts: Array<string>
  }
> {
  constructor() {
    super()
    this.state = {
      name: "",
      default_currency: null,
      sub_accounts: [],
    }
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      name: configuration.name,
      sub_accounts: configuration.sub_accounts_enabled,
      default_currency: {
        label: configuration.default_currency,
        value: configuration.default_currency,
      },
    })
  }

  getData = () => ({
    ...this.state,
    default_currency: this.state.default_currency ? this.state.default_currency.value : null,
  })

  render() {
    const { configuration } = this.props
    const { name, default_currency, sub_accounts } = this.state
    return (
      <div className="row">
        <GatewayModal
          modalName="Update configuration"
          gateway={configuration.gateway}
          configuration={configuration}
          ref={configuration.gateway.name}
        />
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-4 columns">Name</div>
            <div className="medium-8 columns text-right">
              <input
                type="text"
                value={name}
                onChange={event => {
                  this.setState({
                    name: event.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="medium-4 columns">Default currency</div>
            <div className="medium-8 columns text-right">
              <CurrencySelector
                value={this.state.default_currency}
                onChange={currency =>
                  this.setState({
                    default_currency: currency,
                  })
                }
              />
            </div>
          </div>
          {configuration.gateway.sub_accounts.length > 1 && (
            <div className="row margin-bottom">
              <div className="medium-4 columns">Sub accounts</div>
              <div className="medium-8 columns text-right">
                {configuration.gateway.sub_accounts.map(sub => (
                  <div className="row" key={sub}>
                    <div className="medium-12 columns text-left">
                      <div
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{
                            marginRight: "1em",
                          }}
                          checked={sub_accounts.includes(sub)}
                          onChange={event => {
                            const index = sub_accounts.findIndex(s => s === sub)
                            const newArray = sub_accounts.slice(0)

                            if (index > -1) {
                              if (sub_accounts.length < 2) {
                                // we want to always keep an active sub account
                                return
                              }

                              newArray.splice(index, 1)
                            } else {
                              newArray.push(sub)
                            }

                            this.setState({
                              sub_accounts: newArray,
                            })
                          }}
                        />
                        {sub}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="row small-margin-bottom">
            <div
              className="medium-6 columns"
              style={{
                position: "relative",
                top: ".5em",
              }}
            >
              API credentials
            </div>
            <div className="medium-6 columns text-right">
              <a
                className="round border small button"
                onClick={() => {
                  // TODO - this needs to be fixed, but is going to take a lot more thought to ensure nothing breaks. Come back to this
                  // eslint-disable-next-line react/no-string-refs
                  this.refs[configuration.gateway.name]
                    .getWrappedInstance()
                    .getWrappedInstance()
                    .openModal()
                }}
              >
                Edit
              </a>
            </div>
          </div>
          {configuration.gateway.public_fields.length > 0 && (
            <div className="row small-margin-bottom">
              <div className="medium-12 columns">
                <label
                  style={{
                    marginBottom: ".5em",
                  }}
                >
                  Public keys
                </label>
                {configuration.gateway.public_fields.map(field => (
                  <input
                    type="text"
                    value={configuration.public_keys[field]}
                    disabled
                    key={field}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
