import type { $Action, $State } from "^/util/Types"
import type { $FraudConfig } from "./consts"
import { FETCH_FRAUD_SERVICES_CONFIGURATIONS } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
type $FraudServicesState = {
  fraudServices: Array<$FraudConfig>
} & $State
const defaultState: $FraudServicesState = {
  fetched: false,
  fetching: false,
  fraudServices: [],
  error: null,
}
export default function (
  state: $FraudServicesState = defaultState,
  action: $Action,
): $FraudServicesState {
  switch (action.type) {
    case typePending(FETCH_FRAUD_SERVICES_CONFIGURATIONS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_FRAUD_SERVICES_CONFIGURATIONS): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        fraudServices: action.payload.fraud_service_configurations,
      }
    }

    case typeFailed(FETCH_FRAUD_SERVICES_CONFIGURATIONS): {
      return { ...state, fetching: false, fetched: true, error: action.payload }
    }
  }

  return state
}
