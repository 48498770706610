export const TRANSACTIONS_EXPORT_COLUMNS = [
  {
    value: "id",
    label: "id",
  },
  {
    value: "invoice_id",
    label: "invoice_id",
  },
  {
    value: "name",
    label: "name",
  },
  {
    value: "gateway_configuration_id",
    label: "gateway_configuration_id",
  },
  {
    value: "gateway_name",
    label: "gateway_name",
  },
  {
    value: "amount",
    label: "amount",
  },
  {
    value: "authorized_amount",
    label: "authorized_amount",
  },
  {
    value: "captured_amount",
    label: "captured_amount",
  },
  {
    value: "refunded_amount",
    label: "refunded_amount",
  },
  {
    value: "currency",
    label: "currency",
  },
  {
    value: "status",
    label: "status",
  },
  {
    value: "three_d_s_status",
    label: "three_d_s_status",
  },
  {
    value: "error_code",
    label: "error_code",
  },
  {
    value: "metadata",
    label: "metadata",
  },
  {
    value: "created_at",
    label: "created_at",
  },
]
export const PAYOUTS_EXPORT_COLUMNS = [
  {
    value: "id",
    label: "id",
  },
  {
    value: "created_at",
    label: "created_at",
  },
  {
    value: "gateway_configuration_id",
    label: "gateway_configuration_id",
  },
  {
    value: "gateway_resource_id",
    label: "gateway_resource_id",
  },
  {
    value: "status",
    label: "status",
  },
  {
    value: "failure_reason_code",
    label: "failure_reason_code",
  },
  {
    value: "failure_reason",
    label: "failure_reason",
  },
  {
    value: "metadata",
    label: "metadata",
  },
  {
    value: "amount",
    label: "amount",
  },
  {
    value: "fees",
    label: "fees",
  },
  {
    value: "currency",
    label: "currency",
  },
  {
    value: "sales_transactions",
    label: "sales_transactions",
  },
  {
    value: "sales_volume",
    label: "sales_volume",
  },
  {
    value: "refunds_transactions",
    label: "refunds_transactions",
  },
  {
    value: "refunds_volume",
    label: "refunds_volume",
  },
  {
    value: "chargebacks_transactions",
    label: "chargebacks_transactions",
  },
  {
    value: "chargebacks_volume",
    label: "chargebacks_volume",
  },
  {
    value: "adjustments",
    label: "adjustments",
  },
  {
    value: "reserve",
    label: "reserve",
  },
]
export const PAYOUTS_ITEMS_EXPORT_COLUMNS = [
  {
    value: "id",
    label: "id",
  },
  {
    value: "payout_id",
    label: "payout_id",
  },
  {
    value: "transaction_id",
    label: "transaction_id",
  },
  {
    value: "gateway_resource_id",
    label: "gateway_resource_id",
  },
  {
    value: "metadata",
    label: "metadata",
  },
  {
    value: "type",
    label: "type",
  },
  {
    value: "amount",
    label: "amount",
  },
  {
    value: "fees",
    label: "fees",
  },
  {
    value: "transaction_metadata",
    label: "transaction_metadata",
  },
]
