import type { Action } from "../../Types/Action"
export const REQUEST_TELESCOPE_PREPARATION = "REQUEST_TELESCOPE_PREPARATION"
export const PREPARE_TELESCOPE = "PREPARE_TELESCOPE"
export const PREPARE_TELESCOPE_SILENT = "PREPARE_TELESCOPE_SILENT"
export const FETCH_TELESCOPE_DATA = "FETCH_TELESCOPE_DATA"
export const UPDATE_TELESCOPE_TIMESPAN = "UPDATE_TELESCOPE_TIMESPAN"
export const UPDATE_TELESCOPE_TAB = "UPDATE_TELESCOPE_TAB"
export type $TelescopeRequestAction = {
  payload: {
    timespan: string
    silent: boolean
    tab: "auth" | "capt" | "refu" | "char"
  }
} & Action
export const TIMESPAN_OPTIONS = [
  {
    value: "weekly",
    label: "week",
  },
  {
    value: "monthly",
    label: "month",
  },
  {
    value: "quarterly",
    label: "quarter",
  },
  {
    value: "annually",
    label: "year",
  },
]

/**
        CHART FORMULAS
 */
// Authorizations
export const GLOBAL_AUTH_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "authorization"\n' +
  "    AND is_successful == true\n" +
  "    AND is_user_retry == false\n" +
  "    AND is_last_payment_attempt == true;\n" +
  "}\n" +
  "/ count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "authorization"\n' +
  "    AND is_user_retry == false\n" +
  "    AND is_last_payment_attempt == true;\n" +
  "}"
export const AUTH_PER_COUNTRY_FORMULA =
  "plot{\n" +
  "    path:card_country;\n" +
  "    strategy: value_descending;\n" +
  "    top: 150;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "authorization"\n' +
  "        AND is_successful == true\n" +
  "        AND is_user_retry == false\n" +
  "        AND is_last_payment_attempt == true;\n" +
  "    }\n" +
  "    / count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "authorization"\n' +
  "        AND is_user_retry == false\n" +
  "        AND is_last_payment_attempt == true;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const AUTHORIZED_TRANSACTIONS_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "authorization"\n' +
  "    AND is_successful == true\n" +
  "    AND is_user_retry == false\n" +
  "    AND is_last_payment_attempt == true;\n" +
  "}"
export const AUTHORIZED_AMOUNT_FORMULA =
  "sum{\n" +
  "    path:transaction_operations.amount_local;\n" +
  "    default: 0;\n" +
  '    type == "authorization"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const FAILED_TRANSACTIONS_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "authorization"\n' +
  "    AND has_failed == true\n" +
  "    AND is_user_retry == false\n" +
  "    AND is_last_payment_attempt == true;\n" +
  "}"
export const AUTH_PER_PSP_BAR =
  "plot{\n" +
  "    path:gateway_configuration_id;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "authorization"\n' +
  "        AND is_successful == true\n" +
  "        AND is_user_retry == false\n" +
  "        AND is_last_payment_attempt == true;\n" +
  "    }\n" +
  "    / count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "authorization"\n' +
  "        AND is_user_retry == false\n" +
  "        AND is_last_payment_attempt == true;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const AUTH_PER_PSP_PER_CARD_SCHEME_BAR =
  "plot{\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  '    (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express")}\n' +
  "    ;\n" +
  "    path: card_scheme;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    plot{\n" +
  "        path:gateway_name;\n" +
  "        strategy: value_descending;\n" +
  "        top: 10;\n" +
  "        formula: count{\n" +
  "            path:transaction_operations;\n" +
  "            default: 0;\n" +
  '            type == "authorization"\n' +
  "            AND is_successful == true\n" +
  "            AND is_user_retry == false\n" +
  "            AND is_last_payment_attempt == true\n" +
  '            AND (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express");\n' +
  "        }\n" +
  "        / count{\n" +
  "            path:transaction_operations;\n" +
  "            default: 0;\n" +
  '            type == "authorization"\n' +
  "            AND is_user_retry == false\n" +
  "            AND is_last_payment_attempt == true\n" +
  '            AND (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express");\n' +
  "        }\n" +
  "    }\n" +
  "    ;\n" +
  "}"
export const AUTH_PER_PSP_PER_CARD_TYPE_BAR =
  "plot{\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    path: card_type;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    plot{\n" +
  "        path:gateway_name;\n" +
  "        strategy: value_descending;\n" +
  "        top: 10;\n" +
  "        formula: count{\n" +
  "            path:transaction_operations;\n" +
  "            default: 0;\n" +
  '            type == "authorization"\n' +
  "            AND is_successful == true\n" +
  "            AND is_user_retry == false\n" +
  "            AND is_last_payment_attempt == true;\n" +
  "        }\n" +
  "        / count{\n" +
  "            path:transaction_operations;\n" +
  "            default: 0;\n" +
  '            type == "authorization"\n' +
  "            AND is_user_retry == false\n" +
  "            AND is_last_payment_attempt == true;\n" +
  "        }\n" +
  "    }\n" +
  "    ;\n" +
  "}"
// Captures
export const GLOBAL_FEES_FORMULA =
  "sum{\n" +
  "    path:transaction_operations.gateway_fee;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND gateway_fee != null;\n" +
  "    ;\n" +
  "}\n" +
  "/ sum{\n" +
  "    path:transaction_operations.amount;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND gateway_fee != null;\n" +
  "    ;\n" +
  "}"
export const CAPTURE_AMOUNT_FORMULA =
  "sum{\n" +
  "    path:transaction_operations.amount_local;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const CAPTURED_TRANSACTIONS_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const GATEWAY_FEES_FORMULA =
  "sum{\n" +
  "    path:transaction_operations.gateway_fee_local;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND gateway_fee != null;\n" +
  "    ;\n" +
  "}"
export const FEES_PER_COUNTRY_FORMULA =
  "plot{\n" +
  "    path:card_country;\n" +
  "    strategy: value_descending;\n" +
  "    top: 75;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    sum{\n" +
  "        path:transaction_operations.gateway_fee;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND gateway_fee != null;\n" +
  "        ;\n" +
  "    }\n" +
  "    / sum{\n" +
  "        path:transaction_operations.amount;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND gateway_fee != null;\n" +
  "        ;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const GATEWAY_FEES_PER_PSP_BAR_FORMULA =
  "plot{\n" +
  "    path:gateway_configuration_id;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    sum{\n" +
  "        path:transaction_operations.gateway_fee;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND gateway_fee != null;\n" +
  "        ;\n" +
  "    }\n" +
  "    / sum{\n" +
  "        path:transaction_operations.amount;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND gateway_fee != null;\n" +
  "        ;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const GATEWAY_FEES_PER_PSP_PER_CARD_SCHEME_BAR_FORMULA =
  "plot{\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  '    (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express")}\n' +
  "    ;\n" +
  "    path: card_scheme;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    plot{\n" +
  "        path:gateway_name;\n" +
  "        strategy: value_descending;\n" +
  "        top: 10;\n" +
  "        formula: sum{\n" +
  "            path:transaction_operations.gateway_fee;\n" +
  "            default: 0;\n" +
  '            type == "capture"\n' +
  "            AND gateway_fee != null\n" +
  '            AND (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express");\n' +
  "        }\n" +
  "        / sum{\n" +
  "            path:transaction_operations.amount;\n" +
  "            default: 0;\n" +
  '            type == "capture"\n' +
  "            AND gateway_fee != null\n" +
  '            AND (card_scheme == "visa" or card_scheme == "mastercard" or card_scheme == "american express");\n' +
  "        }\n" +
  "    }\n" +
  "    ;\n" +
  "}"
export const FEES_PER_PSP_PER_CARD_TYPE_BAR_FORMULA =
  "plot{\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    path: card_type;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    plot{\n" +
  "        path:gateway_name;\n" +
  "        strategy: value_descending;\n" +
  "        top: 10;\n" +
  "        formula: sum{\n" +
  "            path:transaction_operations.gateway_fee;\n" +
  "            default: 0;\n" +
  '            type == "capture"\n' +
  "            AND gateway_fee != null;\n" +
  "            ;\n" +
  "        }\n" +
  "        / sum{\n" +
  "            path:transaction_operations.amount;\n" +
  "            default: 0;\n" +
  '            type == "capture"\n' +
  "            AND gateway_fee != null;\n" +
  "            ;\n" +
  "        }\n" +
  "    }\n" +
  "    ;\n" +
  "}"
// Refunds
export const GLOBAL_REFUND_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "refund"\n' +
  "    AND is_successful == true;\n" +
  "}\n" +
  "/ count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const REFUNDED_AMOUNT_FORMULA =
  "sum{\n" +
  "    path:transaction_operations.amount_local;\n" +
  "    default: 0;\n" +
  '    type == "refund"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const REFUNDED_TRANSACTIONS_FORMULA =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "refund"\n' +
  "    AND is_successful == true;\n" +
  "}"
export const REFUNDS_PER_COUNTRY_FORMULA =
  "plot{\n" +
  "    path:card_country;\n" +
  "    strategy: value_descending;\n" +
  "    top: 150;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "refund"\n' +
  "        AND is_successful == true;\n" +
  "    }\n" +
  "    / count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND is_successful == true;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const VOIDED_AMOUNT_FORMULA =
  "sum{\n" +
  "        path: transaction_operations.amount_local;\n" +
  "    default: 0;\n" +
  '        type == "void" and is_successful == true;\n' +
  "    }"
export const VOIDED_TRANSACTIONS_FORMULA =
  "count{\n" +
  "    path: transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "void" and is_successful == true ;\n' +
  "}"
export const REFUND_AMOUNT_PER_PSP_BAR =
  "plot{\n" +
  "    path:gateway_configuration_id;\n" +
  "    strategy: value_descending;\n" +
  "    top: 10;\n" +
  "    formula: count{\n" +
  "        path: transaction_operations;\n" +
  "    }\n" +
  "    ;\n" +
  "    count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "refund"\n' +
  "        AND is_successful == true;\n" +
  "    }\n" +
  "    / count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  '        type == "capture"\n' +
  "        AND is_successful == true;\n" +
  "    }\n" +
  "}\n" +
  ";"
export const GLOBAL_CHARGEBACK_RATE =
  "count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "chargeback"\n' +
  "    AND is_successful == true;\n" +
  "}\n" +
  "/ count{\n" +
  "    path:transaction_operations;\n" +
  "    default: 0;\n" +
  '    type == "capture"\n' +
  "    AND is_successful == true;\n" +
  "}"
