import type { State } from "../../Types/State"
import type { Action } from "../../Types/Action"
import { DASHBOARD_PREPARATION, REQUEST_DASHBOARD_PREPARATOR_RESET } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
const defaultState: State = {
  fetching: false,
  fetched: false,
}
export default function (state: State = defaultState, action: Action): State {
  switch (action.type) {
    case typePending(DASHBOARD_PREPARATION): {
      return { ...state, fetched: false, fetching: true, error: false }
    }

    case typeFulfilled(DASHBOARD_PREPARATION): {
      return { ...state, fetched: true, fetching: false }
    }

    case typeFailed(DASHBOARD_PREPARATION): {
      return { ...state, fetching: false, fetched: false, error: true }
    }

    case REQUEST_DASHBOARD_PREPARATOR_RESET: {
      return { ...state, error: false, fetched: false, fetching: false }
    }
  }

  return state
}
