import * as React from "react"
import { Button } from "../../Button"
import "./EditControl.scss"
export type Props = {
  editing: boolean
  disableSave?: boolean
  onAddRuleBtnClick?: React.MouseEventHandler
  onSaveBtnClick?: React.MouseEventHandler
  onCancelValidation?: (callback: (confirmed: boolean) => void) => void
  onModeChanged?: (editing: boolean) => void
}

export const EditControl = ({
  editing,
  disableSave = false,
  onAddRuleBtnClick,
  onSaveBtnClick,
  onCancelValidation = callback => callback(true),
  onModeChanged,
  children,
}: React.PropsWithChildren<Props>) => {
  if (editing) {
    return (
      <div data-tracking-location="Control Menu" className="edit-control">
        <div className="editor-control-left">{children}</div>
        <div className="editor-control-right">
          {/* <Button variant="secondary" onClick={onCodeEditorBtnClick}>
            Code editor
          </Button>
          <Button variant="secondary">Filters</Button> */}

          <Button
            data-auto-tracking="true"
            variant="tertiary"
            onClick={() => {
              onCancelValidation(confirmed => {
                if (confirmed) {
                  onModeChanged(false)
                }
              })
            }}
          >
            Exit edit mode
          </Button>
          <Button
            disabled={disableSave}
            variant="primary"
            onClick={onSaveBtnClick}
            data-auto-tracking="true"
          >
            Save
          </Button>
          <Button variant="secondary" onClick={onAddRuleBtnClick} data-auto-tracking="true">
            Add new rule
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div data-tracking-location="Control Menu" className="edit-control">
      <div className="editor-control-left">{children}</div>
      <div className="editor-control-right">
        <Button
          data-auto-tracking="true"
          variant="secondary"
          onClick={() => {
            onModeChanged(true)
          }}
        >
          Edit rules
        </Button>
      </div>
    </div>
  )
}
