export type $ProjectTypeOption = {
  label: $ProjectType
  value: $ProjectType
}
export type $ProjectType = "Smart-Routing" | "Analytics"
export type $AwsRegion = "aws-us-east-1" | "aws-eu-west-1" | "aws-eu-central-1"
export const REQUEST_PROJECT_CREATION = "REQUEST_PROJECT_CREATION"
export const PROJECT_TYPE_OPTIONS: Array<$ProjectTypeOption> = [
  {
    value: "Smart-Routing",
    label: "Smart-Routing",
  },
  {
    value: "Analytics",
    label: "Analytics",
  },
]
