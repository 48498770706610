import React from "react"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import * as CouponsActions from "../Actions/CouponsActions"
import * as SubscriptionsActions from "../Actions/SubscriptionsActions"
import CouponSelect from "../components/InvoiceEdit/CouponSelect"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
const relativeStyle = {
  position: "relative",
}
const relativeFullStyle = {
  position: "relative",
  width: "100%",
}
const topRelativeStyle = {
  position: "absolute",
  width: "100%",
  top: 0,
}

class SubscriptionDetailsLayout extends React.Component {
  openModal() {
    const header = <div className="large-12 columns">Add a coupon</div>
    const content = (
      <div className="large-12 columns">
        <div className="row small-margin-bottom">
          <div
            className="medium-4 columns font-console"
            style={{
              marginTop: "7px",
            }}
          >
            Coupon
          </div>
          <div id="coupon-selector" className="medium-8 columns">
            <CouponSelect />
          </div>
        </div>
      </div>
    )
    const footer = (
      <div className="large-12 columns">
        <div className="row">
          <div className="large-4 large-offset-4 columns text-center">
            <a
              onClick={function (event) {
                event.preventDefault()
                this.props.dispatch({
                  type: CLOSE_MODAL,
                })
              }.bind(this)}
              className="round border small button expanded"
            >
              Cancel
            </a>
          </div>
          <div className="large-4 columns text-center">
            <a
              onClick={function (event) {
                event.preventDefault()
                const couponId = document.getElementsByName("coupon_id")[0].value
                this.createCoupon(couponId)
              }.bind(this)}
              className="round small main button expanded white-text"
            >
              Add
            </a>
          </div>
        </div>
      </div>
    )
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header,
        content,
        footer,
      },
    })
  }

  cancel(event) {
    event.preventDefault()
    if (!this.props.subscriptionDetails) return

    if (window.confirm("This will cancel the subscription")) {
      this.props.dispatch(
        SubscriptionsActions.deleteSubscription(this.props.subscriptionDetails.subscription.id),
      )
    }
  }

  createCoupon(id) {
    this.props.dispatch({
      type: CLOSE_MODAL,
    })
    if (!this.props.subscriptionDetails) return
    this.props.dispatch(
      CouponsActions.applyCoupon(this.props.subscriptionDetails.subscription.id, id),
    )
  }

  render() {
    let couponButton
    let cancelButton

    if (
      this.props.subscriptionDetails.fetched &&
      !this.props.subscriptionDetails.subscription.canceled
    ) {
      couponButton = (
        <input
          type="button"
          onClick={this.openModal.bind(this)}
          className="round button border right small"
          value="Apply a coupon"
        />
      )

      if (!this.props.subscriptionDetails.subscription.cancel_at) {
        cancelButton = (
          <input
            type="button"
            onClick={this.cancel.bind(this)}
            className="round button border right small"
            value="Cancel"
          />
        )
      }
    }

    const header = (
      <div className="row">
        <div className="large-8 columns">
          <h4>{this.props.title}</h4>
        </div>
        <div className="large-2 columns">{cancelButton}</div>
        <div className="large-2 columns">{couponButton}</div>
      </div>
    )
    return <ContentLayout title={header}>{this.props.children}</ContentLayout>
  }
}

export default connect(store => ({
  subscriptionDetails: store.subscriptionDetails,
}))(SubscriptionDetailsLayout)
