import React from "react"
import Select from "react-select"
import { VELOCITY_FIELDS } from "./consts"
import { SelectStyle } from "../../../analytics/DataExplorer/ChartBuilder/consts"

type FieldProps = {
  onChange: (arg0: string) => void
  value: string | null | undefined
  suffix?: string
  disabled: boolean | null | undefined
  options?: Array<$Option> | null | undefined
  allowMetadata?: boolean | null | undefined
  shouldDisplayApplyButton?: boolean | null | undefined
}
type $Option = {
  label: string
  value: string
  desc: string
}

class VelocityPath extends React.Component<FieldProps> {
  updateValue = (newValue: { label: string; value: string }) => {
    const { onChange } = this.props
    onChange(newValue.value)
  }

  render() {
    const { value } = this.props
    const OPTIONS = VELOCITY_FIELDS

    if (!value) this.updateValue(OPTIONS.find(option => option.value === "card_fingerprint"))

    const correspondingValue = VELOCITY_FIELDS.find(op => op.value === value)
    let label = value
    if (correspondingValue) label = correspondingValue.label
    return (
      <div
        className="labeled-field"
        style={{
          width: "75%",
        }}
      >
        <Select
          className="react-select"
          options={OPTIONS}
          placeholder="Choose option..."
          value={value && { label, value }}
          onChange={this.updateValue}
          isDisabled={this.props.disabled}
          styles={SelectStyle}
        />
      </div>
    )
  }
}

export default VelocityPath
