export default function (oldQuery, newQuery) {
  return Object.entries({ ...oldQuery, ...newQuery })
    .map(
      ([key, value]) =>
        `${key}=${
          value === undefined || value === null ? "" : encodeURIComponent(value?.toString())
        }`,
    )
    .join("&")
}
