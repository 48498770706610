import type { $Action, $Project, $State } from "^/util/Types"
import type { $Coupon } from "^/stores/Reducers/CouponsReducer.js"
import type { $Subscription } from "^/types/Subscription"
import { FETCH_DISCOUNTS } from "^/Actions/CouponsActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type $Discount = {
  id: string
  project: $Project
  project_id: string
  subscription: $Subscription
  subscription_id: string
  coupon: $Coupon
  coupon_id: string
  name: string
  amount: number
  percent: number
  expires_at: string
  created_at: string
}
export type $DiscountsState = {
  discounts: Array<$Discount>
} & $State
const defaultState: $DiscountsState = {
  fetching: false,
  fetched: false,
  error: null,
  discounts: [],
}
export default function (state: $DiscountsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_DISCOUNTS): {
      state = { ...state, fetched: false, fetching: true }
      return state
    }

    case typeFulfilled(FETCH_DISCOUNTS): {
      state = { ...state, fetched: true, fetching: false, discounts: action.payload.discounts }
      return state
    }
  }

  return state
}
