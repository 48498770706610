import * as React from "react"
import classnames from "classnames"
import "./Button.scss"
export type Variant = "primary" | "secondary" | "tertiary" | "shell"
export type Props = {
  variant?: Variant
  children?: React.ReactNode
  disabled?: boolean
} & React.HTMLAttributes<HTMLButtonElement>

export const Button: React.FC<Props> = React.forwardRef(
  (
    { variant = "primary", children, disabled, ...props },
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button disabled={disabled} className={classnames("btn", variant)} {...props} ref={ref}>
        {children}
      </button>
    )
  },
)
Button.displayName = "Button"
