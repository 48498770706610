import React from "react"
import { connect } from "react-redux"
import * as fraudServicesConfigurationActions from "../../features/FraudServicesConfiguration/actions"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $Dispatcher } from "../../util/Types"
import FraudServicesModal from "./FraudConfigurationModal"
import type { $FraudConfigurationObject } from "../../features/FraudServicesConfiguration/consts"
type Props = {
  config: Record<string, any>
} & $Dispatcher
export class FraudConfiguration extends React.Component<Props> {
  constructor() {
    super()
    this._configModal = React.createRef()
  }

  confirmDelete = (config, event) => {
    event.preventDefault()
    this.props.dispatch(
      fraudServicesConfigurationActions.deleteFraudConfig(config.id, () => {
        this.props.dispatch({
          type: CLOSE_MODAL,
        })
        this.props.dispatch(fraudServicesConfigurationActions.fetchFraudServicesConfig())
      }),
    )
  }
  deleteConfig = (config, event) => {
    event.preventDefault()
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: <div>Delete {config.name.toUpperCase()} configuration?</div>,
        content: (
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div
              className="row small-margin-bottom"
              style={{
                paddingTop: "1.5em",
              }}
            >
              <div className="large-12 columns text-center">
                <p>Are you sure you want to delete this configuration?</p>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">ID:</div>
              <div className="large-10  columns greyed text-right">
                <span className="console-font small-font">{config.id}</span>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">Gateway:</div>
              <div className="large-10  columns greyed text-right">{config.name}</div>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="large-12 columns text-right">
              <button
                type="button"
                onClick={event => {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
                className="round border small button"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={ev => this.confirmDelete(config, ev)}
                className="round small main button white-text"
              >
                Delete
              </button>
            </div>
          </div>
        ),
      },
    })
  }
  openModal = event => {
    event.preventDefault()

    this._configModal.current.getWrappedInstance().openModal()
  }

  render() {
    const { config, fraudServices } = this.props
    const fullFraudServiceData = fraudServices.fraud_services.find(
      fraud => fraud.name === config.fraud_service_name,
    )
    return (
      <div className="payment-processor-wrapper details-box">
        <FraudServicesModal configuration={config} ref={this._configModal} />
        <a onClick={this.openModal}>
          <div className="row">
            <div className="large-12 columns text-center img-wrapper">
              <img src={fullFraudServiceData.logo_url} alt={`${config.name}'s logo`} />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns text-center description">
              {config.name || config.fraud_service_name}
            </div>
          </div>
        </a>

        <div className="row">
          <div
            className="medium-12 columns text-right delete"
            style={{
              marginTop: "3px",
            }}
          >
            <a onClick={ev => this.deleteConfig(config, ev)}>delete</a>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  fraudServices: store.fraudServices,
}))(FraudConfiguration)
