import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher } from "../../util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import Empty from "../Empty"
import * as fraudServicesConfigurationActions from "../../features/FraudServicesConfiguration/actions"

const serialize = require("form-serialize")

type Props = {
  fraudService?:
    | {
        name: string
        config: Array<{
          key: string
          value: string
        }>
      }
    | null
    | undefined
} & $Dispatcher
export class FraudServicesModal extends React.Component<Props> {
  fraudService: any

  constructor() {
    super()
    this.fraudService = null
  }

  submit = (event: Event) => {
    event.preventDefault()
    const { fraudService, dispatch } = this.props

    if (!fraudService) {
      return
    }

    const form = document.querySelector("#fraud-services-form")
    const config = serialize(form, {
      hash: true,
    })
    dispatch(
      fraudServicesConfigurationActions.createFraudServiceConfig(fraudService.name, config, () => {
        dispatch({
          type: CLOSE_MODAL,
        })
        dispatch(fraudServicesConfigurationActions.fetchFraudServicesConfig())
      }),
    )
  }

  openModal() {
    const { fraudService } = this.props
    const fraudServiceInstructions = this.props.fraudService.settings_instructions
    let content = []

    if (Object.keys(fraudServiceInstructions).length === 0) {
      content = <Empty text="you can not log to this fraud service" />
    }

    for (const [key, value] of Object.entries(fraudServiceInstructions)) {
      content.push(
        <div className="row" key={key}>
          <div className="large-12 columns">
            <label className="text-left greyed">{key}</label>
            <input
              type="text"
              name={key}
              placeholder={value}
              className="bottom-border processor-input"
            />
          </div>
        </div>,
      )
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: (
          <img
            style={{
              maxHeight: "3em",
            }}
            src={fraudService.logo_url}
            alt={`${fraudService.name}'s logo`}
          />
        ),
        content: (
          <div>
            <div
              className="row small-margin-bottom"
              style={{
                marginTop: "2em",
              }}
            >
              <div className="large-11 large-centered columns">
                <form id="fraud-services-form" autoComplete="off">
                  <div className="large-12 columns text-center">
                    <div className="row big-margin-bottom">
                      <div className="large-12 small-centered columns">{content}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ),
        footer: (
          <div
            className="row"
            style={{
              paddingTop: "0.3em",
            }}
          >
            <div className="large-12 columns text-right">
              <button
                type="button"
                onClick={event => {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
                className="round border small button"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={this.submit}
                className="round small main button white-text"
              >
                Create
              </button>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}
const connectedModal = connect(null, null, null, {
  withRef: true,
})(FraudServicesModal)
export default withRouter(connectedModal, {
  withRef: true,
})
