/**
 * Created by jeremylejoux on 07/06/17.
 */
import { replace } from "react-router-redux"
import * as ProcessOut from "../util/ProcessOut"
import type { Action } from "../Types/Action"
import * as ProjectActions from "./ProjectsActions"
export const CREATE_KEYS_APPLE_PAY = "CREATE_KEYS_APPLE_PAY"
export function createKeys(merchant_id: string, resolve: (() => void) | null | undefined) {
  const body = {
    merchant_id,
  }
  return {
    type: CREATE_KEYS_APPLE_PAY,
    payload: ProcessOut.APIcallPromise("/applepay/keys", "POST", JSON.stringify(body)).then(
      response => {
        if (resolve) resolve()
        return response
      },
    ),
  }
}
export const ADD_APPLE_PAY = "ADD_APPLE_PAY"
export function addApplePay(
  merchant_certificate: string | null | undefined,
  processing_certificate: string,
  projectId: string,
  resolve: () => void,
): Action {
  const body = {
    applepay_settings: {
      enabled: true,
      merchant_certificate,
      processing_certificate,
    },
  }
  return {
    type: ADD_APPLE_PAY,
    payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, "PUT", JSON.stringify(body)).then(
      response => {
        if (response.data.success) resolve()
        return response
      },
    ),
  }
}
export const ENABLE_APPLE_PAY = "ENABLE_APPLE_PAY"
export function enableApplePay(projectId: string): Action {
  const body = {
    applepay_settings: {
      enabled: true,
    },
  }
  return {
    type: ENABLE_APPLE_PAY,
    payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, "PUT", JSON.stringify(body)),
  }
}
export const DISABLE_APPLE_PAY = "DISABLE_APPLE_PAY"
export function disableApplePay(projectId: string): Action {
  const body = {
    applepay_settings: {
      enabled: false,
    },
  }
  return {
    type: DISABLE_APPLE_PAY,
    payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, "PUT", JSON.stringify(body)),
  }
}
export const RESET_APPLE_PAY = "RESET_APPLE_PAY"
export function resetApplePay(projectId: string, done: (() => void) | null | undefined) {
  const body = {
    applepay_settings: {
      reset: true,
    },
  }
  return function (dispatch) {
    dispatch({
      type: RESET_APPLE_PAY,
      payload: ProcessOut.APIcallPromise(
        `/projects/${projectId}`,
        "PUT",
        JSON.stringify(body),
      ).then(response => {
        if (response.data.success) {
          if (done) done()
          else dispatch(ProjectActions.fetchProjectDetails(projectId))
        }

        return response
      }),
    })
  }
}
export const ADD_DOMAIN_APPLE_PAY = "ADD_DOMAIN_APPLE_PAY"
export function addDomain(projectId: string, domain: string, previousDomains: Array<string>) {
  previousDomains.unshift(domain)
  const body = {
    applepay_settings: {
      merchant_domain_names: previousDomains,
    },
  }
  return function (dispatch) {
    dispatch({
      type: ADD_DOMAIN_APPLE_PAY,
      payload: ProcessOut.APIcallPromise(
        `/projects/${projectId}`,
        "PUT",
        JSON.stringify(body),
      ).then(response => {
        if (response.data.success) {
          dispatch(replace(`/projects/${projectId}/settings/apple-pay`))
        }

        return response
      }),
    })
  }
}
export const REMOVE_DOMAIN_NAME = "REMOVE_DOMAIN_NAME"
export function removeDomain(projectId: string, domain: string, previousDomains: Array<string>) {
  const index = previousDomains.indexOf(domain)
  if (index === -1) throw "The domain name you want to delete cannot be found."
  previousDomains.splice(index, 1)
  const body = {
    applepay_settings: {
      merchant_domain_names: previousDomains,
    },
  }
  return function (dispatch) {
    dispatch({
      type: REMOVE_DOMAIN_NAME,
      payload: ProcessOut.APIcallPromise(
        `/projects/${projectId}`,
        "PUT",
        JSON.stringify(body),
      ).then(response => {
        if (response.data.success) {
          dispatch(replace(`/projects/${projectId}/settings/apple-pay`))
          dispatch(ProjectActions.fetchProjectDetails(projectId))
        }

        return response
      }),
    })
  }
}
