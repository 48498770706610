import { REQUEST_FETCH_VALUES_FOR_FIELD } from "../../SearchBar/consts"
export function fetchPathValues(path: string) {
  return {
    type: REQUEST_FETCH_VALUES_FOR_FIELD,
    payload: {
      document: "transactions",
      field: path,
    },
  }
}
