import React from "react"
import Select from "react-select"
import type { $Operand } from "./consts"
import { SelectStyle } from "../consts"
const GLOBAL_OPTIONS = [
  {
    label: "==",
    value: "==",
  },
  {
    label: "!=",
    value: "!=",
  },
  {
    label: "<",
    value: "<",
  },
  {
    label: "<=",
    value: "<=",
  },
  {
    label: ">",
    value: ">",
  },
  {
    label: ">=",
    value: ">=",
  },
  {
    label: "is null",
    value: "is-null",
  },
  {
    label: "is not null",
    value: "is-not-null",
  },
]
const KEYWORD_OPTIONS = [
  {
    label: "==",
    value: "==",
  },
  {
    label: "!=",
    value: "!=",
  },
  {
    label: "is null",
    value: "is-null",
  },
  {
    label: "is not null",
    value: "is-not-null",
  },
]
const MERCHANT_ADVICE_OPTIONS = [
  {
    label: "==",
    value: "==",
  },
  {
    label: "!=",
    value: "!=",
  },
]
type Props = {
  onChange: (arg0: $Operand) => void
  value: $Operand
  isKeywordOnly: boolean
  isMerchantAdvice: boolean
}

class Operand extends React.Component<Props> {
  valueChanged = (option: { value: $Operand }) => {
    const { onChange } = this.props
    if (onChange) onChange(option.value)
  }
  getOptions = () => {
    if (this.props.isKeywordOnly) {
      return KEYWORD_OPTIONS
    }

    if (this.props.isMerchantAdvice) {
      return MERCHANT_ADVICE_OPTIONS
    }

    return GLOBAL_OPTIONS
  }

  render() {
    const { value } = this.props
    const options = this.getOptions()
    return (
      <div
        className="labeled-field"
        style={{
          width: "100px",
        }}
      >
        <Select
          className="react-select"
          options={options}
          isSearcheable={false}
          value={{
            label: value,
            value,
          }}
          styles={SelectStyle}
          onChange={this.valueChanged}
        />
      </div>
    )
  }
}

export default Operand
