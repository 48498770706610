export const DRAG_ASIDE_CONDITION = "drag.aside.condition"
export const DRAG_ASIDE_AUTHORIZATION = "drag.aside.authorization"
export const DRAG_ROUTING_RULE = "drag.routing.rule"
export const DRAG_ROUTING_CONDITION = "drag.routing.condition"
export const DRAG_ROUTING_AUTHORIZATION = "drag.routing.authorization"
export const DRAG_ROUTING_DYNAMIC3DS = "drag.routing.dynamic3ds"

export type DragType =
  | typeof DRAG_ASIDE_CONDITION
  | typeof DRAG_ROUTING_RULE
  | typeof DRAG_ROUTING_CONDITION
  | typeof DRAG_ROUTING_AUTHORIZATION
  | typeof DRAG_ASIDE_AUTHORIZATION
  | typeof DRAG_ROUTING_DYNAMIC3DS

export const NEW_RULES_FILTERS = {
  name: {
    label: "Name",
    desc: "Transaction name",
    isMulti: true,
    type: "string",
  },
  currency: {
    label: "Currency",
    desc: "Currency used for the transactions: USD, EUR, CAD…",
    isMulti: true,
    type: "string",
  },
  card_country: {
    label: "Card country",
    desc: "Country in which the card was emitted: FR, US, UK…",
    isMulti: true,
    type: "string",
  },
  card_scheme: {
    label: "Card scheme",
    desc: "Card scheme: Visa, Mastercard, American Express…",
    isMulti: true,
    type: "string",
  },
  card_co_scheme: {
    label: "Card co-scheme",
    desc: "Card co-schemes: CB…",
    isMulti: true,
    type: "string",
  },
  card_iin: {
    label: "Card IIN/BIN",
    desc: "Card BIN/IIN",
    isMulti: true,
    type: "string",
  },
  card_type: {
    label: "Card type",
    desc: "Card types: Debit, Credit…",
    isMulti: true,
    type: "string",
  },
  card_category: {
    label: "Card category",
    desc: "Card categories: Consumer, Business…",
    isMulti: true,
    type: "string",
  },
  card_last4: {
    label: "Card last 4 digits",
    desc: "Last 4 digits of the card",
    isMulti: true,
    type: "string",
  },
  card_bank: {
    label: "Card issuing bank",
    desc: "Bank that issued the card used for the transactions",
    isMulti: true,
    type: "string",
  },
  amount: {
    label: "Amount",
    desc: "Transaction’s amount requested at its creation.",
    type: "number",
  },
  check_3ds: {
    label: "Is 3DS flow",
    desc: "Whether or not we are currently using a 3DS flow.",
    type: "boolean",
  },
  sca_exemption_reason: {
    label: "Exemption",
    desc: "Optional reason for requesting SCA exemption.",
    type: "string",
  },
  challenge_indicator: {
    label: "Challenge indicator",
    desc: "Optional challenge indicator field when requesting 3DS2.",
    type: "string",
  },
  rand: {
    label: "Random number (0-1)",
    desc: "i.e. match X% of transcations",
    type: "number",
  },
  velocity: {
    label: "Velocity",
    desc: "Filter by fields velocity",
    type: "number",
  },
  cvc_check: {
    label: "CVC check",
    desc: "Whether or not a CVC check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_postal_check: {
    label: "AVS postal check",
    desc: "Whether or not a postal check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_street_check: {
    label: "AVS street check",
    desc: "Whether or not a street check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_name_check: {
    label: "AVS name check",
    desc: "Whether or not a name check has been performed.",
    isMulti: true,
    type: "string",
  },
  merchant_initiated: {
    label: "Is merchant initiated",
    desc: "Whether or not a transaction is flagged as merchant initiated.",
    isMulti: false,
    type: "boolean",
  },
  fraud_services: {
    label: "Fraud services",
    desc: "Whether a specified fraud service should be allowed or denied.",
    isMulti: false,
    type: "fraud_service",
  },
  card_fingerprint: {
    label: "Card fingerprint",
    desc: "Card fingerprint is a unique identifier for the physical card.",
    isMulti: true,
    type: "string",
  },
  metadata: {
    label: "Metadata",
    value: "metadata",
    desc: "Metadata",
  },
}

export const NEW_RULES_FILTERS_GROUP = [
  ["Transaction", ["name", "currency", "amount", "merchant_initiated"]],
  [
    "Card",
    [
      "card_country",
      "card_scheme",
      "card_co_scheme",
      "card_iin",
      "card_type",
      "card_category",
      "card_last4",
      "card_bank",
      "card_fingerprint",
    ],
  ],
  ["Card verification", ["avs_postal_check", "avs_street_check", "avs_name_check", "cvc_check"]],
  ["Authentication", ["check_3ds", "sca_exemption_reason", "challenge_indicator"]],
  ["Other", ["metadata", "rand", "velocity", "fraud_services"]],
]
