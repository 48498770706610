import React from "react"
import { Select } from "../../Select/Select"
const options = [
  {
    label: "True",
    value: true,
  },
  {
    label: "False",
    value: false,
  },
]
export const BooleanSelection = ({ onChange, value, ...props }) => {
  return (
    <Select
      {...props}
      options={options}
      value={options.find(o => o.value === value)}
      onChange={onChange}
    />
  )
}
