import React from "react"
import { FormattedNumber } from "react-intl"
type Props = {
  currentValue: number
  maxValue: number
  name: string
  style: string
}

class ChargebackChart extends React.Component<Props> {
  render() {
    const { style, currentValue, maxValue, name } = this.props
    // Compute the top allowed value for this program
    const top = Math.max(currentValue, maxValue)
    // Check which value should be in the middle (either current or maximum allowed)
    let middleValue: number = 0
    if (top === currentValue) middleValue = maxValue
    else middleValue = currentValue
    // Compute the current percentage value
    const currentPercentage = (middleValue * 100) / top
    // Compute the percentage over the allowed value
    const overPercentage = 100 - currentPercentage
    return (
      <div className="row margin-bottom">
        <div className="medium-12 columns text-center">
          <div className="row small-margin-bottom">
            <div className="medium-12 columns text-center">
              <div className="chargeback-top">
                {top !== currentValue ? "Limit " : ""}
                {top && (
                  <FormattedNumber
                    style={style}
                    value={top}
                    minimumFractionDigits={style === "percent" ? 2 : 0}
                    maximumFractionDigits={2}
                  />
                )}
              </div>
              <div
                className="rate-chart"
                data-tip={`Card networks monitor multiple metrics regarding your chargeback rate. When all the metric's limits are exceeded you climb up a level and could be fined.`}
                data-multiline
              >
                <div className={`container${maxValue > 0 ? " ready" : ""}`}>
                  {top === currentValue && (
                    <div
                      className="over-part"
                      style={{
                        height: `${overPercentage}%`,
                      }}
                    />
                  )}
                  <div
                    className="ok-part"
                    style={{
                      height: `${overPercentage > 0 ? 100 - overPercentage : currentPercentage}%`,
                      top: `${top === currentValue ? 0 : 100 - currentPercentage}%`,
                    }}
                  >
                    <div className={`chargeback-label${maxValue > 0 ? " ready" : ""}`}>
                      <div
                        style={{
                          display: "inline-block",
                          marginRight: ".5em",
                        }}
                      >
                        {top === currentValue ? "Limit " : ""}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <FormattedNumber
                          value={middleValue}
                          style={style}
                          minimumFractionDigits={style === "percent" ? 2 : 0}
                          maximumFractionDigits={2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-12 columns text-center chargeback-chart-name">{name}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChargebackChart
