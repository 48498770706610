import React from "react"
import AdyenOnboardingWarning from "./AdyenOnboardingWarning"
import { BulletNumber } from "^/features/TelescopeOnboarding/processors/BulletNumber"
type Props = {
  hide: boolean
  togglePages: (newPage: "first" | "second") => void
  onAllowOnboarding: () => void
  allowOnboarding: boolean
  isForTelescope: boolean
}
type State = {
  username: string
  password: string
}
export default class First extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      username: "",
      password: "",
    }
  }

  fieldUpdated(field: string, event: any) {
    this.setState({
      [field]: event.target.value,
    })
  }

  nextClicked(event: Event) {
    event.preventDefault()

    if (this.state.username !== "" && this.state.password !== "") {
      this.props.togglePages("second")
    }
  }

  render() {
    const { hide } = this.props
    let inputs

    if (!hide) {
      inputs = (
        <div className="row">
          <div className="large-12 columns">
            <div className="row">
              <div
                className="medium-6 columns"
                style={{
                  paddingLeft: 0,
                }}
              >
                <input
                  onChange={this.fieldUpdated.bind(this, "username")}
                  type="text"
                  defaultValue={this.state.username}
                  name="rp_username"
                  placeholder="Username"
                  className="bottom-border processor-input"
                  required
                />
              </div>
              <div
                className="medium-6 columns"
                style={{
                  paddingRight: 0,
                }}
              >
                <input
                  onChange={this.fieldUpdated.bind(this, "password")}
                  type="password"
                  defaultValue={this.state.password}
                  name="rp_password"
                  placeholder="Password"
                  className="bottom-border processor-input"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      inputs = (
        <div className="row">
          <div className="medium-6 columns">
            <input
              onChange={this.fieldUpdated.bind(this, "username")}
              type="hidden"
              value={this.state.username}
              name="rp_username"
              placeholder="Username"
              className="bottom-border processor-input"
              required
            />
          </div>
          <div className="medium-6 columns">
            <input
              onChange={this.fieldUpdated.bind(this, "password")}
              type="hidden"
              value={this.state.password}
              name="rp_password"
              placeholder="Password"
              className="bottom-border processor-input"
              required
            />
          </div>
        </div>
      )
    }

    let content

    if (!hide) {
      content =
        !this.props.allowOnboarding && this.props.isForTelescope ? (
          <AdyenOnboardingWarning
            onHide={() => {
              this.props.onAllowOnboarding()
            }}
          />
        ) : (
          <div className="medium-12 columns">
            <div className="row margin-bottom">
              <div className="large-12 columns">
                <h4>Adyen configuration setup</h4>
              </div>
            </div>
            <div className="row margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={1} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold margin-bottom greyed">Create API Reports User</label>
                <p>
                  Enabling reports allows ProcessOut to be informed about your payment fees. To set
                  it up, go to your Adyen dashboard, and go to <span className="bold">Account</span>
                  , then <span className="bold">API credentials</span>, then{" "}
                  <span className="bold">Create new credential</span>, and select{" "}
                  <span className="bold">Report user.</span>
                </p>
              </div>
            </div>
            <div className="row margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={2} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold margin-bottom greyed">Enable API Reports User</label>
                <p>
                  Scroll down to the <span className="bold">Roles and Associated Accounts</span>{" "}
                  section and select the <span className="bold">Accounts</span> tab. Then tick the
                  checkbox next to the relevant account to enable it. Afterwards, be sure to press
                  the <span className="bold">Save</span> button.
                </p>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={3} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold greyed margin-bottom">Get API Reports User Details</label>
                <p>
                  Within the <span className="bold">Create New Report Service User</span>, copy the{" "}
                  <span className="bold">User Name</span> and enter it here. Then scroll to the{" "}
                  <span className="bold">Basic Auth</span> section and copy the displayed password
                  and enter it here.
                </p>
                <div className="row margin-bottom">
                  <div className="large-12 columns greyed">{inputs}</div>
                </div>
              </div>
            </div>
          </div>
        )
    } else {
      content = <div>{inputs}</div>
    }

    return <div className="row">{content}</div>
  }
}
