import { call, put, takeLatest } from "redux-saga/effects"
import type { $CreateWebhookAction, $DeleteWebhookAction } from "./actions"
import * as Actions from "./actions"
import { CREATE_WEBHOOK, DELETE_WEBHOOK, FETCH_WEBHOOKS } from "./actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { APIcallPromise } from "../../util/ProcessOut"

function* fetchWebhooks(action: Actions.$FetchWebhooksAction): Generator<any, any, any> {
  try {
    const { after, id = "" } = action.payload
    const bound = after ? "start_after" : "end_before"
    const webhooksResults = yield call(
      APIcallPromise,
      `/webhook-endpoints?${bound}=${id}&order=desc&limit=${10}`,
      "GET",
    )
    yield put({
      type: typeFulfilled(FETCH_WEBHOOKS),
      payload: { ...webhooksResults.data },
    })
  } catch (error) {
    yield put({
      type: typeFailed(FETCH_WEBHOOKS),
      payload: error,
    })
    datadogRum.addError(error)
  }
}

function* createWebhook(action: $CreateWebhookAction): Generator<any, any, any> {
  try {
    yield call(
      APIcallPromise,
      "/webhook-endpoints",
      "POST",
      JSON.stringify({
        url: action.payload.url,
      }),
    )
    yield put(Actions.fetchWebhooks())
  } catch (error) {
    datadogRum.addError(error)
  }
}

function* deleteWebhook(action: $DeleteWebhookAction): Generator<any, any, any> {
  try {
    yield call(APIcallPromise, `/webhook-endpoints/${action.payload.hook.id}`, "DELETE")
    yield put(Actions.fetchWebhooks())
  } catch (error) {
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(FETCH_WEBHOOKS, fetchWebhooks)
  yield takeLatest(CREATE_WEBHOOK, createWebhook)
  yield takeLatest(DELETE_WEBHOOK, deleteWebhook)
}
