import React from "react"
export default class Data extends React.Component {
  constructor() {
    super()
    this.state = {
      email: false,
      shipping: false,
    }
  }

  componentDidMount() {
    if (this.props.invoice == null) {
      return
    }

    const request_email = this.props.invoice.metadata.request_email === "true"
    const request_shipping = this.props.invoice.metadata.request_shipping === "true"
    this.setState({
      email: request_email,
      shipping: request_shipping,
    })
  }

  emailChanged() {
    this.setState({
      email: !this.state.email,
    })
  }

  shippingChanged() {
    this.setState({
      shipping: !this.state.shipping,
    })
  }

  render() {
    const { shipping } = this.state
    const { email } = this.state
    return (
      <div className="row">
        <div className="large-12 medium-8 columns">
          <div className="row">
            <div className="large-12 columns clearfix">
              <span className="left">
                <label
                  className="ios7-switch big"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={email}
                    name="request_email"
                    value="true"
                    id="item-request-email"
                    onChange={this.emailChanged.bind(this)}
                  />
                  <span />
                </label>
              </span>
              <span className="left">Request customer email address</span>
            </div>
            <div className="large-12 columns clearfix">
              <span className="left">
                <label
                  className="ios7-switch big"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={shipping}
                    name="request_shipping"
                    value="true"
                    id="item-request-shipping"
                    onChange={this.shippingChanged.bind(this)}
                  />
                  <span />
                </label>
              </span>
              <span className="left">Request customer shipping address</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
