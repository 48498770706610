import { isInternalUser } from "^/util/Auth"
import { SET_PROJECT_ID } from "^/Actions/ProjectsActions"
import { FETCH_USER } from "^/Actions/UserActions"
import { takeLatest } from "redux-saga"
import { datadogRum } from "@datadog/browser-rum"
import { select } from "redux-saga/effects"
import { typeFulfilled } from "^/util/ActionUtils"
export function* setUser(): Generator<any, any, any> {
  const { userDetails, projectId } = yield select<any>(store => ({
    userDetails: store.user.details,
    projectId: store.projects.current_project_id,
  }))

  if (userDetails.uniq_id && userDetails.email && projectId) {
    datadogRum.setUser({
      id: userDetails.uniq_id,
      projectId: projectId,
      isInternalUser: isInternalUser(userDetails.email),
    })
  }
}
export default function* watchDatadog(): Generator<any, any, any> {
  yield takeLatest(SET_PROJECT_ID, setUser)
  yield takeLatest(typeFulfilled(FETCH_USER), setUser)
}
