import * as React from "react"
type Props = {
  label: string
  onChange: (index: number) => void
  index: number
  value: number
  "aria-controls": string
  id: string
}
export default function Tab({ label, onChange, index, value, ...props }: Props) {
  return (
    <li data-testid={`${label} li`} className={`${index === value ? "active" : ""}`} {...props}>
      <button data-testid={label} onClick={() => onChange(index)}>
        {label}
      </button>
    </li>
  )
}
