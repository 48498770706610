export const REQUEST_BOARD_PREPARATION = "REQUEST_BOARD_PREPARATION"
export const FETCH_BOARD_DETAILS = "FETCH_BOARD_DETAILS"
export const REQUEST_BOARD_DUPLICATION = "REQUEST_BOARD_DUPLICATION"
export const DUPLICATE_BOARD = "DUPLICATE_BOARD"
export const WIPE_CURRENT_BOARD = "WIPE_CURRENT_BOARD"
export const SET_LOCAL_LAYOUT = "SET_LOCAL_LAYOUT"
export const REQUEST_SAVE_LOCAL_LAYOUT = "REQUEST_SAVE_LOCAL_LAYOUT"
export const CANCEL_BOARD_EDITION = "CANCEL_BOARD_EDITION"
export const REQUEST_ADD_TEXT_CHART = "REQUEST_ADD_TEXT_CHART"
export const REQUEST_CHART_DUPLICATE = "REQUEST_CHART_DUPLICATE"
export type $BoardLayoutItem = {
  chart_id: string
  height: number
  position_x: number
  position_y: number
  size: number
}
export type $BoardLayout = Array<$BoardLayoutItem>
