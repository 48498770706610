import React from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import type { $Alert } from "./consts"
import Alert from "./Alert"
import Empty from "../../components/Empty"
type Props = {
  alerts: Array<$Alert>
}

class Alerts extends React.Component<Props> {
  render() {
    const { alerts } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns">
          {alerts.length === 0 && <Empty text={`You haven't created any alerts yet.`} />}
          <ReactCSSTransitionGroup
            transitionEnterTimeout={350}
            transitionLeaveTimeout={200}
            transitionName="alert-index-animation"
          >
            {alerts.map(alert => (
              <Alert key={alert.id} alert={alert} />
            ))}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}

export default Alerts
