import * as ProcessOut from "../util/ProcessOut"
export const FETCH_PLANS = "FETCH_PLANS"
export function fetchPlans(length?, after?, id?) {
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_PLANS,
    payload: ProcessOut.APIcallPromise(`/plans?${bound}=${id}&order=desc&limit=${length}`, "GET"),
  }
}
export const CREATE_PLAN = "CREATE_PLAN"
export function createPlan(plan, resolve) {
  return function (dispatch) {
    const body = JSON.stringify(plan)
    dispatch({
      type: CREATE_PLAN,
      payload: ProcessOut.APIcallPromise("/plans", "POST", plan).then(response => {
        if (response.data.success) {
          resolve()
          dispatch(fetchPlans())
        }

        return response
      }),
    })
  }
}
export const EDIT_PLAN = "EDIT_PLAN"
export function editPlan(plan, id, resolve) {
  return function (dispatch) {
    const body = JSON.stringify(plan)
    dispatch({
      type: EDIT_PLAN,
      payload: ProcessOut.APIcallPromise(`/plans/${id}`, "PUT", plan).then(response => {
        if (response.data.success) {
          resolve()
          dispatch(fetchPlans())
        }

        return response
      }),
    })
  }
}
export const DELETE_PLAN = "DELETE_PLAN"
export function deletePlan(id) {
  return function (dispatch) {
    dispatch({
      type: DELETE_PLAN,
      payload: ProcessOut.APIcallPromise(`/plans/${id}`, "DELETE", null).then(response => {
        if (response.data.success) {
          dispatch(fetchPlans())
        }

        return response
      }),
    })
  }
}
export const START_PLAN_CREATION = "START_PLAN_CREATION"
