import React from "react"
import { connect } from "react-redux"
import type { $Filter, $Operand } from "./consts"
import PlottedField from "./PlottedField/PlottedField"
import Operand from "./Operand"
import ValueSelection from "./ValueSelection"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "../../../../SearchBar/consts"
import type { $Dispatcher } from "../../../../../util/Types"

type Props = {
  prefixLabel: string
  filter: $Filter | null | undefined
  onChange: (arg0: $Filter) => void
  onDelete: (arg0: $Filter) => void
  filterValues: any
} & $Dispatcher

class Filter extends React.Component<Props> {
  componentDidMount(): void {
    const { filter, dispatch } = this.props

    if (filter && filter.path && filter.value) {
      dispatch({
        type: REQUEST_FETCH_VALUES_FOR_FIELD,
        payload: {
          document: this.getDocument(filter.path),
          field: filter.path,
        },
      })
    }
  }

  getDocument = (path: string) => {
    const transactionOperationsPaths = [
      "three_d_s.status",
      "three_d_s.version",
      "three_d_s.ares_status",
      "three_d_s.cres_status",
      "three_d_s.device_channel",
      "three_d_s.fingerprinted",
      "three_d_s.challenged",
      "sca_exemption_reason",
      "challenge_indicator",
      "merchant_advice_code",
      "merchant_advice_reason",
      "merchant_initiator_type",
      "recommendation_code",
      "is_network_token",
    ]
    return transactionOperationsPaths.includes(path) ? "transaction_operations" : "transactions"
  }
  onPathChange = (newValue: string) => {
    const { dispatch, onChange, onDelete, filter } = this.props
    dispatch({
      type: REQUEST_FETCH_VALUES_FOR_FIELD,
      payload: {
        document: this.getDocument(newValue),
        field: newValue,
      },
    })
    onChange({ ...filter, path: newValue })
  }
  onOperandChange = (newValue: $Operand) => {
    const { onChange, filter } = this.props
    onChange({ ...filter, operand: newValue })
  }
  onValueChange = (newValue: Array<string>) => {
    const { onChange, filter } = this.props
    onChange({ ...filter, value: newValue })
  }
  onCustomValueChange = (event: Event) => {
    const { onChange, filter } = this.props
    onChange({ ...filter, value: [event.target.value] })
  }

  render() {
    const { filter, prefixLabel, filterValues, onDelete } = this.props
    return (
      <div className="row small-margin-bottom">
        <div className="large-12 columns">
          <div
            className="field-label round-left"
            style={{
              width: "65px",
            }}
          >
            {prefixLabel}
          </div>
          <PlottedField
            onChange={this.onPathChange}
            value={filter ? filter.path : null}
            disabled={false}
          />
          {filter && (
            <Operand
              onChange={this.onOperandChange}
              value={filter.operand}
              isKeywordOnly={
                !filter.path &&
                !filter.path.includes("metadata") &&
                !new RegExp(/^\w*_*(?:estimate|count|hour|day|fee|amount(?:_local)?)$/).test(
                  filter.path,
                )
              }
              isMerchantAdvice={filter.path.includes("merchant_advice")}
            />
          )}
          {filter ? (
            filter.operand && filter.operand.includes("null") ? null : filterValues[filter.path] ? (
              <ValueSelection
                onChange={this.onValueChange}
                options={filterValues[filter.path]}
                value={filter.value}
              />
            ) : (
              <div
                style={{
                  width: "200px",
                  display: "inline-block",
                }}
              >
                <input
                  className="text-right"
                  type="text"
                  value={filter.value}
                  onChange={this.onCustomValueChange}
                  placeholder="0"
                  style={{
                    marginBottom: 0,
                    position: "relative",
                    borderRadius: "0",
                    top: "1px",
                  }}
                />
              </div>
            )
          ) : null}
          {filter && (
            <div
              className="field-label clickable round-right"
              onClick={() => {
                this.props.onDelete(this.props.filter)
              }}
            >
              delete
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  filterValues: store.searchBar,
}))(Filter)
