import { put, select, takeLatest } from "redux-saga/effects"
import { push } from "react-router-redux"
import * as Actions from "./actions"
import { REQUEST_BOARD_DELETION } from "./consts"
import type { $Action } from "../../../util/Types"
import { datadogRum } from "@datadog/browser-rum"
type Action = {
  payload: {
    projectId: string
    id: string
  }
} & $Action

function* deleteBoard(action: Action): Generator<any, any, any> {
  try {
    yield put.resolve(Actions.deleteBoard(action.payload.id))
    yield put(push(`/projects/${action.payload.projectId}/boards?deleted=${action.payload.id}`))
    const boards = yield select<any>(store => store.analytics_v2.boards)
    const remainingBoards = boards.boards.filter(board => board.id !== action.payload.id)
    const boardTarget = remainingBoards.length > 0 ? remainingBoards[0].id : null
    yield put.resolve(Actions.requestBoardsFetch(boardTarget, true))
  } catch (e) {
    datadogRum.addError(e)
  }
}

export default function* watchForDelete(): Generator<any, any, any> {
  yield takeLatest(REQUEST_BOARD_DELETION, deleteBoard)
}
