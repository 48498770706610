import type { $Action } from "^/util/Types"
import type { $TelescopeV2Page } from "../../consts"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export const defaultState = {
  fetching: true,
  fetched: false,
}
export const createTelescopeV2PageReducer = (page: $TelescopeV2Page) => {
  return (state = defaultState, action: $Action) => {
    switch (action.type) {
      case typePending(`PREPARE_TELESCOPE_V2_${page.toUpperCase()}`): {
        return { ...state, fetching: true, fetched: false }
      }

      case typeFulfilled(`PREPARE_TELESCOPE_V2_${page.toUpperCase()}`): {
        if (!action.payload) return state
        return { ...state, fetching: false, fetched: true, ...action.payload }
      }
    }

    return state
  }
}
