import type { $PermissionFeature, $PermissionGroup } from "./consts"
import { newPermissionGroup } from "./consts"
import type { $State } from "../../util/Types"
import type {
  $DeletePermissionGroupAction,
  $RequestFetchPermissionGroupsAction,
  $SetPermissionGroupValuesAction,
  $UpdateGroupNameAction,
  AddPermissionGroupAction,
  RemovePermissionFromGroupAction,
  SavePermissionGroupsAction,
} from "./actions"
import {
  ADD_PERMISSION_GROUP,
  DELETE_PERMISSION_GROUP,
  REMOVE_PERMISSION_FROM_GROUP,
  REQUEST_FETCH_PERMISSION_GROUPS,
  SAVE_PERMISSION_GROUPS,
  SET_PERMISSION_GROUP_VALUES,
  UPDATE_GROUP_NAME,
} from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $PermissionGroupsState = {
  permissionFeatures: Array<$PermissionFeature>
  permissionGroups: Array<$PermissionGroup>
  fetchedState: {
    permissionGroups: Array<$PermissionGroup> | null | undefined
  }
  saving: boolean
  edited?: boolean
} & $State
const defaultState: $PermissionGroupsState = {
  fetching: false,
  fetched: false,
  error: null,
  permissionFeatures: [],
  permissionGroups: [],
  fetchedState: {
    permissionGroups: null,
  },
  saving: false,
}
export default function (
  state: $PermissionGroupsState = defaultState,
  action:
    | $UpdateGroupNameAction
    | $DeletePermissionGroupAction
    | SavePermissionGroupsAction
    | $SetPermissionGroupValuesAction
    | RemovePermissionFromGroupAction
    | AddPermissionGroupAction
    | $RequestFetchPermissionGroupsAction,
): $PermissionGroupsState {
  switch (action.type) {
    case REQUEST_FETCH_PERMISSION_GROUPS: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
        fetchedState: {
          permissionGroups: null,
        },
      }
    }

    case typeFulfilled(REQUEST_FETCH_PERMISSION_GROUPS): {
      // TODO: this is a hack to filter out any permission group contains /
      // So it won't break the save requests
      // Related to this ticket: https://checkout.atlassian.net/jira/software/c/projects/POS/boards/902?modal=detail&selectedIssue=POS-190
      const permissionGroups = (action as any).payload.permissionGroups.filter(
        (group: $PermissionGroup) => !group?.name?.includes("/"),
      )

      return {
        ...state,
        fetching: false,
        fetched: true,
        ...(action as any).payload,
        permissionGroups,
        fetchedState: {
          permissionGroups: permissionGroups,
        },
      }
    }

    case SAVE_PERMISSION_GROUPS: {
      return { ...state, saving: true }
    }

    case typeFulfilled(SAVE_PERMISSION_GROUPS): {
      return { ...state, saving: false }
    }

    case typeFailed(SAVE_PERMISSION_GROUPS): {
      return {
        ...state,
        saving: false,
        permissionGroups: state.fetchedState.permissionGroups
          ? state.fetchedState.permissionGroups.slice(0)
          : state.permissionGroups,
      }
    }

    case SET_PERMISSION_GROUP_VALUES: {
      const { payload } = action
      const permissionGroupIndex = state.permissionGroups.findIndex(
        g => g.name === payload.groupName,
      )
      if (permissionGroupIndex < 0) return state
      const permissions = state.permissionGroups[permissionGroupIndex].permissions[payload.category]
      if (!permissions) return state
      const newPermissions = permissions.slice(0)
      const urlIndex = newPermissions.findIndex(p => p.id === payload.feature.id)
      if (urlIndex < 0)
        newPermissions.push({
          action: payload.action,
          id: payload.feature.id,
        })
      else newPermissions[urlIndex].action = payload.action
      const newPermissionGroups = state.permissionGroups.slice(0)
      newPermissionGroups[permissionGroupIndex].permissions[payload.category] = newPermissions
      return { ...state, permissionGroups: newPermissionGroups, edited: true }
    }

    case REMOVE_PERMISSION_FROM_GROUP: {
      const { payload } = action
      const permissionGroupIndex = state.permissionGroups.findIndex(
        g => g.name === payload.groupName,
      )
      if (permissionGroupIndex < 0) return state
      const permissions = state.permissionGroups[permissionGroupIndex].permissions[payload.category]
      if (!permissions) return state
      const newPermissions = permissions.slice(0)
      const permIndex = newPermissions.findIndex(p => p.id === payload.feature.id)
      if (permIndex < 0) return state
      newPermissions.splice(permIndex, 1)
      const newPermissionGroups = state.permissionGroups.slice(0)
      newPermissionGroups[permissionGroupIndex].permissions[payload.category] = newPermissions
      return { ...state, permissionGroups: newPermissionGroups, edited: true }
    }

    case ADD_PERMISSION_GROUP: {
      const newGroup: $PermissionGroup = newPermissionGroup()
      const newGroupsList = state.permissionGroups.slice()
      newGroupsList.push(newGroup)
      return { ...state, permissionGroups: newGroupsList, edited: true }
    }

    case DELETE_PERMISSION_GROUP: {
      const { payload } = action
      const groups = state.permissionGroups.slice()
      const index = groups.findIndex(g => g.name === payload.name)
      if (index < 0) return state
      groups.splice(index, 1)
      return { ...state, permissionGroups: groups, edited: true }
    }

    case UPDATE_GROUP_NAME: {
      const { payload } = action
      const groups = state.permissionGroups.slice(0)
      const index = groups.findIndex(g => g.id === payload.id)
      if (index < 0) return state
      groups[index].newName = payload.newName
      return { ...state, permissionGroups: groups }
    }
  }

  return state
}
