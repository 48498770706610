import React from "react"
import type { $Recommendation } from "./consts"

class Recommendation extends React.Component<{
  recommendation: $Recommendation
  currency: string
  onSelect: () => void
}> {
  render() {
    const { recommendation } = this.props
    return (
      <div className="row small-margin-bottom">
        <div className="large-12 columns recommendation">
          <div className="row">
            <div className="large-12 columns row-title">
              <div className="row">
                <div className="large-8 columns name">{recommendation.name}</div>
                <div className="large-4 columns text-right">
                  <div className="learn-more" onClick={this.props.onSelect}>
                    Click to learn more <div className="arrow">→</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Recommendation
