import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ContentLayout from "../../pages/ContentLayout"
import * as GatewaysActions from "../../Actions/GatewaysActions"
import Loader from "../../components/Loader"
import Configuration from "./Configuration"
import FilterBar from "../FilterBar/FilterBar"
import type { $GatewayConfiguration } from "./consts"
import type { $Dispatcher, $State } from "../../util/Types"
import Project from "../../util/Project"
import InformationContainer from "../InformationContainer/InformationContainer"
const PROVIDERS_PER_LINE = 6
type Props = {
  configurationsDetails: {
    configurations: Array<$GatewayConfiguration>
  } & $State
  params: {
    project: string
  }
} & $Dispatcher
type State = {
  filteredConfigs: Array<$GatewayConfiguration>
}

class Configurations extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filteredConfigs: [],
    }
  }

  componentDidMount() {
    this.props.dispatch(GatewaysActions.loadGatewaysConfigurations())
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.configurationsDetails.fetching) {
      nextProps.configurationsDetails.configurations.sort((a, b) =>
        a.enabled && !b.enabled ? -1 : 1,
      )
    }
  }

  render() {
    const { configurationsDetails, params } = this.props

    if (!configurationsDetails.fetched || configurationsDetails.fetching) {
      return (
        <ContentLayout title={<h4>Your activated payment providers</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    const project = new Project(params.project)
    const { configurations } = this.props.configurationsDetails
    if (!configurations.length) return <div />
    const { filteredConfigs } = this.state
    const rowsN = filteredConfigs.length / PROVIDERS_PER_LINE
    const rows = []

    for (let j = 0; j < rowsN; j++) {
      let count = -1
      const row = (
        <div className="row" key={Math.floor(Math.random() * 100000)}>
          {filteredConfigs
            .slice(j * PROVIDERS_PER_LINE, j * PROVIDERS_PER_LINE + PROVIDERS_PER_LINE)
            .map(config => {
              count++
              let last = ""
              if (count + j * PROVIDERS_PER_LINE == filteredConfigs.length - 1) last = "end"
              return (
                <div key={`config-${count}`} className={`medium-2 ${last} columns margin-bottom`}>
                  <Configuration key={config.id} config={config} />
                </div>
              )
            }, this)}
        </div>
      )
      rows.push(row)
    }

    return (
      <ContentLayout title={<h4>Your activated payment providers</h4>}>
        {project.isSandbox() && (
          <InformationContainer info="You are updating your Sandbox Payment Provider configurations. These configurations interact with the test environments of the PSPs, allowing you to further test your ProcessOut integrations." />
        )}
        <div className="row">
          <div className="large-12 columns">
            <div className="row small-margin-bottom">
              <div className="large-4 end columns">
                <FilterBar
                  onFilter={(values: Array<$GatewayConfiguration>) => {
                    this.setState({
                      filteredConfigs: values,
                    })
                  }}
                  options={configurations.map(config => ({
                    key: config.name || config.id,
                    value: config,
                  }))}
                />
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">{rows}</div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  configurationsDetails: store.processorsConfigurations,
  projects: store.projects,
}))(withRouter(Configurations))
