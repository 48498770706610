/*
 * This feature is only meant to preload the dashboard data (user details, projects list)
 * and redirect to the telescope page.
 */
import { call, put, select, takeLatest } from "redux-saga/effects"
import { replace } from "react-router-redux"
import type { $ActionType } from "./consts"
import { DASHBOARD_PREPARATION, REQUEST_DASHBOARD_PREPARATION } from "./consts"
import * as ProjectsActions from "../../Actions/ProjectsActions"
import * as ProcessOut from "../../util/ProcessOut"
import { refreshToken } from "../NetworkManager/sagas"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export function* prepareDashboard(action: $ActionType): Generator<any, any, any> {
  try {
    // Url is the url that we want to load after dashboard preparation.
    let url
    const businessName = action.payload.email.split("@")[1]
    yield put({
      type: typePending(DASHBOARD_PREPARATION),
    })
    // We will fetch the projects list first
    const projectsResult = yield put.resolve(ProjectsActions.loadProjects())
    // If a project id is specified we need to test the authorization
    const { projectId } = action.payload

    if (!projectId) {
      const project = yield call(getProject, projectsResult.value.data.projects)

      if (!project) {
        /*
         * The Local storage was empty and the user doesn't have any project
         */
        url = "/projects/create"
      } else {
        /*
         * The Local storage was empty but the user has a project
         */
        url = `/projects/${project.id}/`
      }

      // We update the store to tell that the dashboard is ready
      yield put({
        type: typeFulfilled(DASHBOARD_PREPARATION),
      })
      // Go to telescope/project creation
      yield put(replace(url))
      return
    }

    try {
      const authorized = yield call(
        ProcessOut.APIcallPromise,
        `/projects/${projectId}`,
        "GET",
        null,
        ProcessOut.generateHeaders(projectId),
        true,
      )

      if (authorized.data.success) {
        ProcessOut.config.ProjectId = projectId
        url = `/projects/${projectId}/`
      }
    } catch (error) {
      /*
       * The id was in Local storage but belongs to another use
       */
      const project = yield call(getProject, projectsResult.value.data.projects)

      if (!project) {
        // The user does not have a project, we create one
        url = "/projects/create"
      } else {
        // The user has another project, we redirect to it
        url = `/projects/${project.id}/`
      }
    }

    // We update the store to tell that the dashboard is ready
    yield put({
      type: typeFulfilled(DASHBOARD_PREPARATION),
    })
    // Go to telescope/project creation
    yield put(replace(url))
  } catch (error) {
    yield put({
      type: typeFailed(DASHBOARD_PREPARATION),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}
export function* getProject(
  projects: Array<{
    id: string
  }>,
): Generator<any, any, any> {
  // we check if the user already has a project or not
  if (projects.length > 0) return projects[0]
  return null
}
export function* createProject(name: string): Generator<any, any, any> {
  // The user does not have any project. We need to create one
  const newProject = {
    name,
    default_currency: "USD",
  }
  // We create the project
  const body = JSON.stringify(newProject)
  const project = yield call(ProcessOut.APIcallPromise, "/projects", "POST", body)
  if (!project.data.success) throw project.data.message
  // We need to update the user tokens as a new project has been created
  // refresh token
  const authDetails = yield select<any>(store => store.user.auth)
  const rToken = authDetails.refreshToken.token

  if (rToken) {
    yield call(refreshToken, rToken)
  }

  return project.data.project
}
export function* requestDashboardPreparation(): any {
  yield takeLatest(REQUEST_DASHBOARD_PREPARATION, prepareDashboard)
}
