import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import TransactionDetailsLayout from "./TransactionDetailsLayout"
import Loader from "../components/Loader"
import Error from "../components/Error"
import * as TransactionsActions from "../Actions/TransactionsActions"
import Transaction from "../components/TransactionsDetails/Transaction"
import CardDetails from "../components/TransactionsDetails/CardDetails"
import OperationsHistory from "../components/TransactionsDetails/OperationsHistory"
import Customer from "../components/TransactionsDetails/Customer"
import MetadataDisplay from "../components/MetadataDisplay"
import type { $State } from "^/util/Types"
import DeclineExplainer from "../features/DeclineExplainer/DeclineExplainer"
import type { $DeclineExplainerState } from "^/features/DeclineExplainer/reducer"
import CountryFlag from "../features/CountryFlag/CountryFlag"
import NotPermitted from "../features/Permissions/NotPermitted"
import { getStatusTextFromTransaction } from "^/components/Helpers"
import NewDashPopup from "../vNext/NewDashPopup"
import { $LaunchDarklyState } from "../vNext/LaunchDarkly/LaunchDarklyReducer"

export type Props = {
  details: any
  dispatch: (payload: (...args: Array<any>) => any) => void
  probabilities: $State
  params: { project: string; transaction: string }
  declineExplainer: $DeclineExplainerState
  launchDarkly: $LaunchDarklyState
}
type State = {}

export class TransactionsDetails extends React.Component<Props, State> {
  componentDidUpdate(prevProps: Props, prevState: State) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Transaction Details | ProcessOut"
    this.props.dispatch(TransactionsActions.loadTransactionDetails(this.props.params.transaction))
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
      this.props.dispatch(TransactionsActions.loadTransactionDetails(this.props.params.transaction))
    }

    if (nextProps.params.transaction !== this.props.params.transaction) {
      // Changed transaction page
      this.props.dispatch(TransactionsActions.loadTransactionDetails(nextProps.params.transaction))
    }
  }

  render() {
    const { details, declineExplainer, params, launchDarkly } = this.props

    if ((details.fetching || !details.fetched) && !details.error) {
      return (
        <TransactionDetailsLayout title="Transaction details">
          <Loader />
        </TransactionDetailsLayout>
      )
    }

    if (details.error) {
      if (details.error.notPermitted) {
        return (
          <TransactionDetailsLayout title="Transaction details">
            <NotPermitted />
          </TransactionDetailsLayout>
        )
      }

      return (
        <TransactionDetailsLayout title="Transaction details">
          <Error />
        </TransactionDetailsLayout>
      )
    }

    const { transaction } = this.props.details

    if (transaction.id === 0) {
      return <TransactionDetailsLayout title="Transaction details" />
    }

    const { customer } = transaction
    let error

    if (transaction.error_code) {
      error = (
        <div>
          <label>Error code</label>
          <span className="big-font font-console">{transaction.error_code}</span>
        </div>
      )
    }

    let country
    if (transaction.details.country)
      country = (
        <div
          style={{
            float: "right",
          }}
        >
          {" "}
          <CountryFlag country_code={transaction.details.country} />
        </div>
      )
    return (
      <TransactionDetailsLayout title="Transaction details">
        <div className="row margin-bottom">
          <div className="large-5 columns">
            <div className="details-box">
              <div className="row small-margin-bottom">
                <div className="large-4 columns">
                  <label>Amount</label>
                  <span className="huge-font bold">
                    <FormattedNumber
                      value={transaction.amount}
                      style="currency"
                      currency={transaction.currency}
                    />
                  </span>
                </div>
                <div className="large-4 columns text-right">
                  <label>Processor</label>
                  <span className="big-font">
                    {transaction.gateway_configuration ? (
                      <img
                        alt={`${transaction.gateway_configuration.gateway.name}'s logo`}
                        style={{
                          maxHeight: "1.5em",
                        }}
                        src={transaction.gateway_configuration.gateway.logo_url}
                      />
                    ) : (
                      "--"
                    )}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="medium-12 columns text-right">
                  {transaction.gateway_configuration && transaction.gateway_configuration.name}
                </div>
              </div>
            </div>
          </div>
          <div
            className="large-7 columns"
            style={{
              paddingRight: 0,
            }}
          >
            <div
              className="details-box"
              style={{
                paddingBottom: "0em",
              }}
            >
              <div className="row">
                <div className="medium-5 columns">
                  <div className="row small-margin-bottom">
                    <div className="large-12 columns">
                      <label>Status</label>
                      <span className="big-font capitalize bold">
                        {getStatusTextFromTransaction(transaction)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="medium-7 columns text-right">
                  <div className="row small-margin-bottom">
                    <div className="large-12 columns">{error}</div>
                  </div>
                  {transaction.status === "failed" ? (
                    <div className="row small-margin-bottom">
                      <div className="large-12 columns text-right">
                        <DeclineExplainer transaction={transaction} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="large-5 columns left-border">
            <div className="row">
              <div className="large-12 columns">
                <div className="row">
                  <div className="large-12 columns">
                    <h6 className="margin-bottom capitalize greyed font-console">
                      Details {country}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="large-12 columns">
                    <Transaction transaction={transaction} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                {Object.keys(transaction.metadata).length > 0 ? (
                  <MetadataDisplay
                    metadata={transaction.metadata}
                    currency={transaction.currency}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="large-7 columns">
            <CardDetails card={transaction.card} token_id={transaction.token_id} />
            {
              // Only display 3DS status for card and 3ds transactions
              transaction.payment_type === "three-d-s" || transaction.payment_type === "card" ? (
                <div className="row small-margin-bottom">
                  <div className="large-4 columns">
                    <span className="greyed">3-D Secure</span>
                  </div>
                  <div className="large-8 columns">
                    <span className="right">
                      <ThreeDSStatus status={transaction.three_d_s_status} />
                    </span>
                  </div>
                </div>
              ) : null
            }
          </div>

          <div className="large-12 columns margin-top">
            <Customer customer={customer} />
          </div>
          <div className="large-12 columns margin-top">
            <OperationsHistory transaction={transaction} />
          </div>
        </div>
        {launchDarkly.flags["new-dash-transactions-page"] && (
          <NewDashPopup
            newDashRelativeUrl={`/projects/${params.project}/transactions/${params.transaction}`}
            className="margin-top"
          />
        )}
      </TransactionDetailsLayout>
    )
  }
}

class ThreeDSStatus extends React.Component {
  props: {
    status: string
  }

  render() {
    let display = null
    const style = {
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "0px",
      marginRight: "-3px",
    }

    switch (this.props.status) {
      case "success": {
        display = (
          <div className="log-level success" style={style}>
            {this.props.status}
          </div>
        )
        break
      }

      case "unchecked":
      case "pending": {
        display = (
          <div className="log-level pending" style={style}>
            {this.props.status}
          </div>
        )
        break
      }

      case "invalidated":
      case "failed": {
        display = (
          <div className="log-level failed" style={style}>
            {this.props.status}
          </div>
        )
        break
      }
    }

    return display
  }
}

export default connect(store => ({
  details: store.transactionDetails.details,
  refunds: store.refunds,
  probabilities: store.transactionDetails.probabilities,
  project: store.currentProject,
  declineExplainer: store.declineExplainer,
  launchDarkly: store.launchDarkly,
}))(TransactionsDetails)
