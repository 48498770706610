import React from "react"
import { connect } from "react-redux"
import type { TransactionModel } from "^/Types/Transaction"
import Operation from "./Operation"
import JSONViewer from "./JSONViewer"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import "./OperationsHistory.scss"
import { track } from "^/vNext/tracking"
type Props = {
  transaction: TransactionModel
} & $Dispatcher
export class OperationsHistory extends React.Component<Props> {
  openDetails = () => {
    const { dispatch, transaction } = this.props
    const transactionWithoutOps = Object.keys(transaction).reduce((object, key) => {
      if (key !== "operations") {
        object[key] = transaction[key]
      }

      return object
    }, {})
    const content = (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6
                className="capitalize greyed font-console"
                style={{
                  marginBottom: "0px",
                }}
              >
                Transaction
              </h6>
            </div>
          </div>
        </div>
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <JSONViewer value={transactionWithoutOps} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns">
            <hr />
          </div>
        </div>
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6
                className="capitalize greyed font-console"
                style={{
                  marginBottom: "0px",
                }}
              >
                Operation
              </h6>
            </div>
          </div>
        </div>
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              {transaction.operations.map(operation => {
                const ref = React.createRef()
                return (
                  <div
                    ref={ref}
                    data-collapse
                    key={operation.id}
                    className="row small-margin-bottom operation-header box-row"
                    style={{
                      marginBottom: "0px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div
                      onClick={() => {
                        ref.current.hasAttribute("data-collapse")
                          ? ref.current.removeAttribute("data-collapse")
                          : ref.current.setAttribute("data-collapse", true)
                      }}
                    >
                      <div className="large-3 columns greyed">{operation.type}</div>
                      <div
                        className="large-9 columns text-right"
                        style={{
                          wordWrap: "break-word",
                        }}
                      >
                        {operation.gateway_configuration ? (
                          <img
                            alt={`${operation.gateway_configuration.gateway.name}'s logo`}
                            className="operation-gateway-logo"
                            src={operation.gateway_configuration.gateway.logo_url}
                          />
                        ) : (
                          <span className="greyed">—</span>
                        )}
                        <div className="large-1 columns">
                          <div className="right">↗</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="more-details large-12 columns"
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      <div className="row small-margin-bottom">
                        <div className="large-12 columns">
                          <JSONViewer value={operation} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        large: true,
        header: "More info",
        content,
        footer: (
          <div className="row">
            <div className="columns text-right">
              <a
                onClick={event => {
                  event.preventDefault()
                  dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
                className="round border small button"
              >
                Close
              </a>
            </div>
          </div>
        ),
      },
    })
    track("Transaction Details", "Click", "Operations History", "More Info Button")
  }

  render() {
    const { transaction } = this.props
    const requests = transaction.operations.filter(operation => operation.type === "request")
    const lastRequestId = requests.length ? requests[requests.length - 1].id : ""
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row large-margin-bottom">
            <div className="medium-3 columns">
              <h6 className="capitalize greyed font-console">Operations history</h6>
            </div>
            <div className="medium-6 columns" />
            <div className="medium-2 columns text-right">
              <button
                onClick={this.openDetails}
                className="expanded round border small button right no-margin"
                type="submit"
              >
                More info
              </button>
            </div>
          </div>
        </div>
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-5 columns">Transaction created:</div>
            <div className="medium-7 columns greyed font-console text-right">{transaction.id}</div>
          </div>
          <div className="row margin-bottom">
            <div className="medium-5 columns">Routing rules:</div>
            <div className="medium-7 columns font-console text-right">
              {transaction.routing_tags &&
                transaction.routing_tags.map(tag => (
                  <div
                    key={tag}
                    style={{
                      padding: ".5em",
                      backgroundColor: "#E8EAF6",
                      borderRadius: "4px",
                      margin: "0 .5em .5em 0",
                      fontSize: ".8em",
                    }}
                    className="inline-block rule-tag"
                  >
                    {tag}
                  </div>
                ))}
              {!transaction.routing_tags && <div className="greyed rule-tag-placeholder">––</div>}
            </div>
          </div>
          {transaction.operations.map((operation, index, operations) => {
            let auth

            for (let i = 0; i < transaction.operations.length; i++) {
              if (transaction.operations[i].type === "authorization")
                auth = transaction.operations[i]
            }

            let finalRequest = false

            if (
              operation.gateway_operation_id !== "" &&
              auth &&
              operation.gateway_operation_id === auth.gateway_variable
            ) {
              finalRequest = true
            }

            if (!finalRequest) {
              finalRequest = operation.id === lastRequestId
            }

            if (operation.is_attempt && index !== operations.length - 1) return null
            return (
              <Operation
                class="operation"
                key={operation.id}
                operation={operation}
                request={finalRequest}
                transaction={transaction}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
export default connect()(OperationsHistory)
