import uniqid from "uniqid"
import {
  AUTHORIZATION_RATE_METRIC,
  AVERAGE_BASKET_METRIC,
  CAPTURED_AMOUNT_METRIC,
  DECLINE_RATE_METRIC,
  FEES_AMOUNT_METRIC,
  FEES_RATE_METRIC,
  REFUND_RATE_METRIC,
  REFUNDED_AMOUNT_METRIC,
  TRANSACTIONS_COUNT_METRIC,
} from "./ChartBuilder/consts"
import type { $ChartBuilderState } from "./ChartBuilder/consts"
export type $DataExplorerTab = "presets" | "chart-builder" | "editor"
export type $PresetCategory = "payment-performance" | "payment-fees" | "business-intel"
export type $DataExplorerMetricTab = "custom" | "authorization" | "fees"
export type $DataExplorerPreset = {
  id: string
  name: string
  chartBuilder: $ChartBuilderState
}
export type $Option = {
  label: string
  value: string | number
}
export const UPDATE_CHART_BUILDER_DETAILS = "UPDATE_CHART_BUILDER_DETAILS"
export const PRESETS: Record<string, Array<$DataExplorerPreset>> = {
  "payment-performance": [
    {
      id: uniqid(),
      name: "Global authorization rate",
      chartBuilder: {
        selectedMetric: AUTHORIZATION_RATE_METRIC,
        displayNetMetrics: true,
        filters: [],
        type: "line-chart",
        displayInLocalCurrency: false,
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            strategy: "",
            top: null,
            formula: "count{path: transactions; default: 0}",
          },
        ],
      },
    },
    {
      id: uniqid(),
      name: "Global decline rate",
      chartBuilder: {
        type: "line-chart",
        displayInLocalCurrency: false,
        filters: [],
        selectedMetric: DECLINE_RATE_METRIC,
        displayNetMetrics: true,
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            strategy: "",
            top: null,
            formula: "count{path: transactions; default: 0}",
          },
        ],
      },
    },
    {
      id: uniqid(),
      name: "Card schemes distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "card_scheme",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transaction_operations; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Countries distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.country",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
    {
      id: uniqid(),
      name: "Issuing banks distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.card_bank",
            top: 15,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Error codes distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.error_code",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        selectedMetric: TRANSACTIONS_COUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: false,
      },
    },
    {
      id: uniqid(),
      name: "Authorization rate per card scheme",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "card_scheme",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transaction_operations; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: AUTHORIZATION_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Authorization rate per country",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.country",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: AUTHORIZATION_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Authorization rate per issuing bank",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.card_bank",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: AUTHORIZATION_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Authorization rate per top BINs",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.card_iin",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: AUTHORIZATION_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
  ],
  "payment-fees": [
    {
      id: uniqid(),
      name: "Global paid fees",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: FEES_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: FEES_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per country",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.country",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: FEES_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per country",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.country",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: FEES_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per currency",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.currency",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: FEES_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per currency",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.currency",
            top: 20,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: FEES_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per payment type",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.payment_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: FEES_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per payment type",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.payment_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: FEES_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per card type",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.card_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        selectedMetric: FEES_AMOUNT_METRIC,
        displayNetMetrics: true,
      },
    },
    {
      id: uniqid(),
      name: "Global paid fees per card type",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.card_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        displayInLocalCurrency: false,
        selectedMetric: FEES_RATE_METRIC,
        displayNetMetrics: true,
      },
    },
  ],
  "business-intel": [
    {
      id: uniqid(),
      name: "Total transactions volume",
      chartBuilder: {
        type: "line-chart",
        filters: [],
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        selectedMetric: TRANSACTIONS_COUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: false,
      },
    },
    {
      id: uniqid(),
      name: "Total transactions volume",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
    {
      id: uniqid(),
      name: "Successful transactions volume",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
    {
      id: uniqid(),
      name: "Refunds volume",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        selectedMetric: REFUNDED_AMOUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
    {
      id: uniqid(),
      name: "Refunds volume",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        selectedMetric: REFUND_RATE_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: false,
      },
    },
    {
      id: uniqid(),
      name: "Payment methods distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.payment_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        selectedMetric: TRANSACTIONS_COUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: false,
      },
    },
    {
      id: uniqid(),
      name: "Payment method distribution",
      chartBuilder: {
        type: "bar-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.payment_type",
            top: 10,
            strategy: "value_descending",
            formula: "count{path: transactions; default: 0}",
          },
        ],
        filters: [],
        selectedMetric: CAPTURED_AMOUNT_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
    {
      id: uniqid(),
      name: "Average basket",
      chartBuilder: {
        type: "line-chart",
        dimensions: [
          {
            id: uniqid(),
            field: "transactions.created_at",
            formula: "count{path: transactions; default: 0}",
            strategy: "",
            top: null,
          },
        ],
        filters: [],
        selectedMetric: AVERAGE_BASKET_METRIC,
        displayNetMetrics: true,
        displayInLocalCurrency: true,
      },
    },
  ],
}
export const DEFAULT_FORMULA =
  "plot{\n" +
  "    path:created_at;\n" +
  "    formula: count{\n" +
  "        path:transaction_operations;\n" +
  "        default: 0;\n" +
  "        is_last_operation == true\n" +
  "        AND is_user_retry == false\n" +
  "        AND is_last_payment_attempt == true;\n" +
  "    }\n" +
  "}\n" +
  ";"
