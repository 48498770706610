import React from "react"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import Loader from "../components/Loader"
import UserUpdate from "./UserUpdate"

const md5 = require("js-md5")

class User extends React.Component {
  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Settings | ProcessOut"
  }

  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    const user = this.props.user.details

    if (!this.props.user.details.fetched || this.props.user.details.fetching) {
      return (
        <ContentLayout title={<h4>Settings</h4>}>
          <div className="row">
            <div className="large-7 medium-8 small-centered columns">
              <Loader />
            </div>
          </div>
        </ContentLayout>
      )
    }

    return (
      <div className="row content">
        <div className="large-7 medium-8 small-centered columns">
          <div className="row">
            <div className="large-2 medium-3 hide-for-small columns">
              <a href="http://en.gravatar.com">
                <img
                  alt="Customer`s avatar"
                  className="rounded-image"
                  src={`https://secure.gravatar.com/avatar/${md5(user.email)}?s=80&r=g&d=identicon`}
                />
              </a>
            </div>
            <div className="large-10 medium-9 small-12 columns">
              <div className="large-9 columns">
                <h4>{user.name}</h4>
                <h5>{user.email}</h5>
              </div>
            </div>
          </div>

          <hr />
        </div>
        <UserUpdate user={user} />
      </div>
    )
  }
}

export default connect(store => ({
  user: store.user,
}))(User)
