import React from "react"
import { connect } from "react-redux"
import type { $ApiKey } from "./consts"
import DateDisplay from "../DateDisplay"
import type { $Dispatcher } from "../../util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "../../util/Types"
import KeyOverviewModal from "./KeyOverviewModal"
import ModalFooter from "../../components/ModalFooter"
import * as Actions from "./actions"
import * as ConfirmActions from "../ConfirmModal/actions"
type Props = {
  privateKey: $ApiKey
} & $Dispatcher

class PrivateKey extends React.Component<Props> {
  _detailsModal: any
  openKeyDetails = () => {
    const { dispatch, privateKey } = this.props
    const footer = (
      <ModalFooter
        submitTitle="Save"
        submitCallback={() => {
          const newApiKey: $ApiKey = this._detailsModal.getValue()

          dispatch(
            Actions.updatePrivateKey(newApiKey, () => {
              dispatch({
                type: CLOSE_MODAL,
              })
            }),
          )
        }}
        deleteCallback={() => {
          dispatch(
            ConfirmActions.requestConfirmation(
              `Deleting a private key cannot be undone.`,
              () => {
                // Confirmed deletion
                dispatch(
                  Actions.deleteApiKey(this.props.privateKey.name, () => {
                    dispatch({
                      type: CLOSE_MODAL,
                    })
                  }),
                )
              },
              () => {
                // Rejected deletion
              },
            ),
          )
        }}
      />
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header: privateKey.name,
        content: <KeyOverviewModal privateKey={privateKey} ref={e => (this._detailsModal = e)} />,
        footer,
      },
    })
  }

  render() {
    const { privateKey } = this.props
    return (
      <div
        className={`row collapse api-key-row ${
          privateKey.sandbox ? "sandbox" : privateKey.enabled ? "active" : "inactive"
        }`}
        onClick={this.openKeyDetails}
      >
        <div className="large-7 columns">{privateKey.name}</div>
        <div className="large-4 columns">
          <DateDisplay value={privateKey.created_at} />
        </div>
        <div className="large-1 columns text-right">
          <div className={`log-level ${privateKey.enabled ? "success" : "warning"}`}>
            {privateKey.enabled ? "Enabled" : "Disabled"}
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(PrivateKey)
