import { $Action } from "^/util/Types"

export const RESET_ALERT_BUILDER_FORM = "RESET_ALERT_BUILDER_FORM"
export function resetAlertBuilderForm(): $Action {
  return {
    type: RESET_ALERT_BUILDER_FORM,
  }
}
export const UPDATE_ALERT_BUILDER_FORM = "UPDATE_ALERT_BUILDER_FORM"
export type $UpdateAlertBuilderFormAction = {
  type: "UPDATE_ALERT_BUILDER_FORM"
  payload: {
    field: string
    value: any
  }
}
export function updateAlertBuilderForm(field: string, value: any): $UpdateAlertBuilderFormAction {
  return {
    type: UPDATE_ALERT_BUILDER_FORM,
    payload: {
      field,
      value,
    },
  }
}
export const REQUEST_ALERT_BUILDER_PREVIEW = "REQUEST_ALERT_BUILDER_PREVIEW"
export function requestAlertBuilderPreview() {
  return {
    type: REQUEST_ALERT_BUILDER_PREVIEW,
  }
}
export const REQUEST_ALERT_SAVE = "REQUEST_ALERT_SAVE"
export function requestAlertSave() {
  return {
    type: REQUEST_ALERT_SAVE,
  }
}
