import { put, takeEvery } from "redux-saga/effects"
import moment from "moment"
import type { $Action } from "../../util/Types"
import { REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH } from "./actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"
import { CHART_PREVIEW_TEMPLATE } from "^/features/Telescope/Sagas/common"

function* fetchCustomerMonthlyRevenue(action: $Action): Generator<any, any, any> {
  try {
    const { payload } = action
    if (!payload) throw new Error("Missing payload")
    const chart = {
      ...CHART_PREVIEW_TEMPLATE,
      settings: {
        formula: `sum{
                    path:transactions.captured_amount_local;
                    customer_id == "${payload.customerId}";
                    default: 0;
                }`,
      },
      type: "single-value",
    }
    const startDate = moment().subtract(30, "days").date(1)
    const endDate = moment()
    const httpResult = yield APIcallPromise(
      `/boards/board_default-sales/charts?start_at=${startDate.unix()}&end_at=${endDate.unix()}`,
      "POST",
      JSON.stringify(chart),
      null,
      null,
      true,
    )

    if (!httpResult.data.success) {
      yield put({
        type: typeFailed(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH),
        payload: {
          error: httpResult.data.message,
        },
      })
      return null
    }

    yield put({
      type: typeFulfilled(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH),
      payload: {
        monthlyRevenue: httpResult.data.data[0].value,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeEvery(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH, fetchCustomerMonthlyRevenue)
}
