import React from "react"
import Select from "react-select"
import { connect } from "react-redux"
import type { $Option } from "../consts"
import type { $FraudConfig } from "^/features/FraudServicesConfiguration/consts"
type Props = {
  fraudServices: Array<$FraudConfig>
  value: string
  updateValue: (arg0: $Option) => void
}
export class FraudServicesInput extends React.Component<Props> {
  convertFraudServicesToOptions(fraudServices: $FraudConfig) {
    return fraudServices.map(fraudService => ({
      label: fraudService.name,
      value: fraudService.id,
    }))
  }

  render() {
    const { fraudServices, value, updateValue } = this.props
    const options = this.convertFraudServicesToOptions(fraudServices)
    const selectedValue = options.find(option => option.value === value)
    return (
      <div className="medium-7 columns">
        <Select
          className="react-select"
          placeholder="Fraud services..."
          options={options}
          value={selectedValue}
          onChange={updateValue}
        />
      </div>
    )
  }
}
export default connect(store => ({
  fraudServices: store.fraudServiceConfigurationsReducer.fraudServices,
}))(FraudServicesInput)
