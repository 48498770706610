import React from "react"
import { FormattedNumber } from "react-intl"
import type { $DataPoint } from "../../Boards/consts"

const DefaultTooltip = (props: { point: $DataPoint }) => {
  const { point } = props
  return (
    <div>
      <FormattedNumber value={point.value} /> transactions
    </div>
  )
}

export default DefaultTooltip
