import * as React from "react"
import Header from "../components/layout/Header"
import Modal from "../components/layout/Modal"
import Nav from "../components/layout/Nav"
import * as ProcessOut from "../util/ProcessOut"
import NotificationCenter from "../features/NotificationBar/NotificationCenter"
import ErrorBoundary from "../features/ErrorBoundary"
import SandboxIndicator from "../features/SandboxIndicator/index"
import type { $RouterLocation } from "^/util/Types"

type Props = {
  location: $RouterLocation
  children?: React.ReactNode
  params: {
    project: string
  }
}

class Layout extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    ProcessOut.config.ProjectId = this.props.params.project
  }

  render() {
    return (
      <>
        <Modal />
        <div className="colorful main-wrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              padding: "40px 15px",
              minHeight: 62,
              backgroundColor: "red",
              color: "white",
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M16 17v-5"
              />
              <path fill="#fff" d="M16 22.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z" />
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M27 14V7a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v7c0 12 11 15 11 15s11-3 11-15Z"
              />
            </svg>
            You are viewing a deprecated version of this page which will be removed on the 1st of
            October, 2024. Please use the left-hand side navigation to access the current (and
            supported) version of this page.
          </div>
          <Header isTransparent={false} />
          <div className="row main-content shadowed rounded-top">
            <NotificationCenter />
            <div className="large-12 columns">
              <SandboxIndicator />
            </div>
            <Nav location={this.props.location}>
              <ErrorBoundary>{this.props.children}</ErrorBoundary>
            </Nav>
          </div>
        </div>
      </>
    )
  }
}

export default Layout
