import { $Keys, $Values } from "utility-types"
import { connect } from "react-redux"
import { replace } from "react-router-redux"
import type { Node } from "react"
import React from "react"
import type { Dispatch } from "@@redux"
import type { $Flags } from "^/vNext/LaunchDarkly/types"
import type { $LaunchDarklyState } from "^/vNext/LaunchDarkly/LaunchDarklyReducer"
type Props = {
  launchDarkly: $LaunchDarklyState
  flagRequired: $Keys<typeof $Flags>
  flagRequiredValue: $Values<typeof $Flags>
  redirectPath: string
  children?: Node
}
export const RequireFeatureFlag = ({
  launchDarkly,
  flagRequired,
  flagRequiredValue,
  children,
  redirectPath,
  dispatch,
}: Props & Dispatch<any>) => {
  // If user is not identified, show no content to prevent flickering. If the initialisation state is "failed"
  // then the user is not identified because LD failed to initialise and thus the default flags state can be used.
  if (launchDarkly.initialiseState.state !== "failed" && !launchDarkly.userIdentified) {
    return null
  }

  const flagPresent = launchDarkly.flags[flagRequired] === flagRequiredValue

  if (!flagPresent) {
    dispatch(replace(redirectPath))
  }

  return <>{flagPresent && children}</>
}
export default connect(store => ({
  launchDarkly: store.launchDarkly,
}))(RequireFeatureFlag)
