import * as React from "react"
import { useCallback, useEffect } from "react"
import { GatewayInformation } from "^/vNext/@types/types"
import type { $RoutingRule, $VelocityRule } from "../../../features/RoutingRules/consts"
import type { $Filter } from "../../../features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { DropArea } from "../../DragDrop/DropArea"
import { DRAG_ROUTING_RULE } from "../consts"
import { Graph } from "../Graph"
import { RouteLane } from "../routes/RouteLane"
import { RoutingContext } from "../RoutingContext"
import { CardTooltip } from "../Tooltip/CardTooltip"

export type Props = {
  routingRules: Array<$RoutingRule>
  editing: boolean
  asideCollapsed: boolean
  onSortEnd?: (oldIndex: number, newIndex: number) => void
  onRemoveRule?: (id: string) => void
  cloneRule?: (id: string) => void
  onUpdateRule?: (rule: $RoutingRule) => void
  onAddCondition: (filter: $Filter) => void
}

/**
 * Contains all the elements that are scrolling
 */
export const ScrollPane = ({
  routingRules,
  editing,
  asideCollapsed,
  onSortEnd,
  onRemoveRule,
  cloneRule,
  onUpdateRule,
  onAddCondition,
}: Props) => {
  const [state, dispatch] = React.useContext(RoutingContext)
  // Calculate row and column index for criteria menu position
  const rowIndex = state.selectedRuleIndex
  let colIndex = 0

  if (routingRules[rowIndex]) {
    const gateways = routingRules[rowIndex].gateways
    const filters = routingRules[rowIndex].conditions[0].filters
    const items: Array<$Filter | $VelocityRule | GatewayInformation> = filters.concat(gateways)
    colIndex = items.findIndex(item => item.id === state.selectedCardId)
  }

  const onCloneCallback = useCallback(() => {
    cloneRule(routingRules[state.selectedRuleIndex].id)
  }, [state.selectedRuleIndex])

  const onRemoveCallback = useCallback(() => {
    const index = state.selectedRuleIndex
    onRemoveRule(routingRules[index].id)
    dispatch({
      type: "select.routing.rule",
      index: -1,
    })
  }, [state.selectedRuleIndex])

  return (
    <div className="scroll-pane">
      <DropArea
        onDragOver={(dragState, _, evt) => {
          if (dragState.activeCategory === DRAG_ROUTING_RULE) {
            evt.currentTarget.classList.add("drop-area-active")
          }
        }}
        onDragLeave={(dragState, _, evt) => {
          if (dragState.activeCategory === DRAG_ROUTING_RULE) {
            evt.currentTarget.classList.remove("drop-area-active")
          }
        }}
        onDrop={(dragState, _, evt) => {
          if (dragState.activeCategory === DRAG_ROUTING_RULE) {
            evt.currentTarget.classList.remove("drop-area-active")
            dispatch({
              type: "select.routing.rule",
              index: routingRules.length - 1,
            })
            // orderIndex is specified when onDragStart is trigger in RouteLane
            const oldIndex = Number(evt.dataTransfer.getData(DRAG_ROUTING_RULE))
            onSortEnd(oldIndex, routingRules.length - 1)
          }
        }}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Graph
          routingRules={routingRules}
          editing={editing}
          asideCollapsed={asideCollapsed}
        ></Graph>
        <div className="lane-container">
          {/* 
          We have normal routing rule. Still needs design and implementation for
          1. Blocking rule
          2. dynamic 3ds
          3. trigger 3ds. */}
          {routingRules.map((r, rowIndex) => {
            return (
              <RouteLane
                selected={rowIndex === state.selectedRuleIndex}
                editing={editing}
                orderIndex={rowIndex}
                key={r.id}
                routingRule={r}
                onRemove={() => onRemoveCallback()}
                onClone={() => onCloneCallback()}
                onCardSelect={condition => {
                  dispatch({
                    type: "select.routing.card",
                    id: condition.id,
                  })
                }}
                onDrop={(oldIndex, newIndex) => {
                  dispatch({
                    type: "select.routing.rule",
                    index: newIndex,
                  })
                  onSortEnd(oldIndex, newIndex)
                }}
                onFocus={orderIndex => {
                  dispatch({
                    type: "select.routing.rule",
                    index: orderIndex,
                  })
                }}
                onAddCondition={onAddCondition}
                onUpdateRule={onUpdateRule}
                onBlur={() => {}}
              />
            )
          })}
        </div>
      </DropArea>
      <CardTooltip />
    </div>
  )
}
