import type { $CurrentProject, $Dispatcher, $RouterLocation } from "^/util/Types"
import type { $ChartDatasFromApi, $PerCountryTableData, $Period, ValueStyle } from "../consts"

import Loading from "^/components/Loader"
import ContentLayout from "^/pages/ContentLayout"
import { prepareTelescopeV2Page } from "../actions"
import BarChartTooltip from "../components/BarChartTooltip"
import PerCountryTable from "../components/PerCountryTable"
import SummaryCards from "../components/SummaryCards/SummaryCards"
import TabsState from "../components/TabsPicker/TabsState"
import TelescopeHeader from "../components/TelescopeHeader"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"
import CaptureAndVoidsLineCharts from "./CaptureAndVoidsLineCharts"

import * as React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"

export type Metrics = {
  captured_transactions_rate: number
  captured_transactions_amount: number
  captured_transactions_count: number
  captured_transactions_rate_evolution: number
  failed_captures_rate: number
  failed_captures_rate_evolution: number
  failed_captures_amount: number
  failed_captures_count: number
  voided_transactions_rate: number
  voided_transactions_rate_evolution: number
  voided_transactions_amount: number
  voided_transactions_count: number
  failed_captures_chart: $ChartDatasFromApi[]
  failed_captures_rate_by_gateway: $ChartDatasFromApi[]
  failed_captures_rate_by_card_scheme: $ChartDatasFromApi[]
  failed_captures_rate_by_payment_method: $ChartDatasFromApi[]
  voided_transactions_rate_by_gateway: $ChartDatasFromApi[]
  voided_transactions_rate_by_card_scheme: $ChartDatasFromApi[]
  voided_transactions_rate_by_payment_method: $ChartDatasFromApi[]
  voided_transactions_chart: $ChartDatasFromApi[]
  failedCapturesTableData: Array<$PerCountryTableData>
  voidedTransactionsTableData: Array<$PerCountryTableData>
  fetched: boolean
  fetching: boolean
}

type Props = {
  location: $RouterLocation
  currentProject: $CurrentProject
  metricsTelescope: Metrics
} & $Dispatcher

export function CaptureAndVoids({ metricsTelescope, dispatch, location, currentProject }: Props) {
  useEffect(() => {
    document.title = "Capture & voids | ProcessOut"
  }, [])

  React.useEffect(() => {
    dispatch(prepareTelescopeV2Page("captureAndVoids", location.query.period as $Period))
  }, [location.query])

  const summaryMetrics = [
    {
      title: "Captured transactions",
      value: metricsTelescope.captured_transactions_rate,
      percentageEvolution: metricsTelescope.captured_transactions_rate_evolution,
      id: "captured_transactions",
      tooltipContent:
        "Transactions which have a successful captured request processed by percentage, value and volume",
      valueStyle: "percent" as ValueStyle,
      moreDetails: true,
      amount: metricsTelescope.captured_transactions_amount,
      count: metricsTelescope.captured_transactions_count,
    },
    {
      title: "Failed captures",
      value: metricsTelescope.failed_captures_rate,
      percentageEvolution: metricsTelescope.failed_captures_rate_evolution,
      id: "failed_captures",
      tooltipContent:
        "Transactions which have a received a capture request which was not successfully processed by percentage, value and volume",
      valueStyle: "percent" as ValueStyle,
      moreDetails: true,
      amount: metricsTelescope.failed_captures_amount,
      count: metricsTelescope.failed_captures_count,
    },
    {
      title: "Voided transactions",
      value: metricsTelescope.voided_transactions_rate,
      percentageEvolution: metricsTelescope.voided_transactions_rate_evolution,
      id: "voided_transactions",
      tooltipContent: "Transactions which have been voided by percentage, value and volume",
      valueStyle: "percent" as ValueStyle,
      moreDetails: true,
      amount: metricsTelescope.voided_transactions_amount,
      count: metricsTelescope.voided_transactions_count,
    },
  ]

  const failedCapturesBarChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.failed_captures_rate_by_gateway,
      tooltipContent: "Breakdown of failed captures per provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.failed_captures_rate_by_card_scheme,
      tooltipContent: "Breakdown of failed captures per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.failed_captures_rate_by_payment_method,
      tooltipContent: "Breakdown of failed captures per payment method",
      id: "paymentMethod_id",
    },
  ]
  const voidedTransactionsBarChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.voided_transactions_rate_by_gateway,
      tooltipContent: "Breakdown of voided transactions per provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.voided_transactions_rate_by_card_scheme,
      tooltipContent: "Breakdown of voided transactions per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.voided_transactions_rate_by_payment_method,
      tooltipContent: "Breakdown of voided transactions per payment method",
      id: "paymentMethod_id",
    },
  ]

  const tabs = [
    {
      label: "Failed captures",
      content: (
        <div data-tracking-location="Failed Captures">
          <PerCountryTable
            tableData={metricsTelescope.failedCapturesTableData}
            currentProject={currentProject}
            title="Failed captures by country"
            tooltipContent="Breakdown of failed captures per country"
          />
          <div className="large-margin-top row">
            {failedCapturesBarChartsData.map(data => (
              <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                <BarChartTooltip value={data} />
                <BarChartHorizontal
                  axisLeft={150}
                  datas={chartFormattedData(data.value)}
                  name={data.name}
                />
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      label: "Voided transactions",
      content: (
        <div data-tracking-location="Voided Transactions">
          <PerCountryTable
            tableData={metricsTelescope.voidedTransactionsTableData}
            currentProject={currentProject}
            title="Voided transactions by country"
            tooltipContent="Breakdown of voided transactions per country"
          />
          <div className="large-margin-top row">
            {voidedTransactionsBarChartsData.map(data => (
              <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                <BarChartTooltip value={data} />
                <BarChartHorizontal
                  axisLeft={150}
                  datas={chartFormattedData(data.value)}
                  name={data.name}
                />
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ]

  const loading = !metricsTelescope.fetched || metricsTelescope.fetching
  const lineChartData = [
    { data: metricsTelescope.failed_captures_chart, chartTitle: "Failed captures" },
    { data: metricsTelescope.voided_transactions_chart, chartTitle: "Voided transactions" },
  ]

  return (
    <div data-tracking-sub-page="Capture & voids">
      <ContentLayout
        title={<TelescopeHeader title="Capture & voids" />}
        noBackground
        padding="0"
        data-tracking-location="Summary"
      >
        <div className="large-margin-bottom small-margin-top">
          <SummaryCards
            data={summaryMetrics}
            loading={!metricsTelescope.fetched || metricsTelescope.fetching}
          />
        </div>
      </ContentLayout>
      <div className="large-margin-top">
        {loading ? (
          <Loading />
        ) : (
          <div data-tracking-location="Analytics">
            <ContentLayout>
              <div className="small-margin-bottom">
                <CaptureAndVoidsLineCharts datas={lineChartData} />
              </div>
            </ContentLayout>
            <div className="medium-margin-top">
              <TabsState tabs={tabs} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.captureAndVoids,
  currentProject: store.currentProject,
}))(CaptureAndVoids)
