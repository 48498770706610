import React from "react"
import { connect } from "react-redux"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
import Loading from "../../components/Loader"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "../SearchBar/consts"
import type { $Dispatcher } from "../../util/Types"
import type { $Option } from "../analytics/DataExplorer/consts"
import { SEARCH_BUILDER_TOKENS } from "./consts"
import TextFilter from "./TextFilter"
import AmountFilter from "./AmountFilter"
import DateFilter from "./DateFilter"
import MetadataFilter from "./MetadataFilter"
type Props = {
  filter: $Filter
  onChange: (arg0: $Filter | null | undefined) => void
  searchBar: Record<string, Array<$Option>>
  document: string
} & $Dispatcher
type State = {
  valuesFilter: string
}

class SearchBuilderFilter extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      valuesFilter: "",
    }
  }

  componentDidMount() {
    const document = SEARCH_BUILDER_TOKENS[this.props.document]

    if (document) {
      const tokenInfos = document.find(item => item.value === this.props.filter.path)

      if (tokenInfos && tokenInfos.canFetchValues) {
        const documentsOverride = {
          analytics: "transactions",
        }
        let doc = documentsOverride[this.props.document]
        if (!doc) doc = this.props.document
        this.props.dispatch({
          type: REQUEST_FETCH_VALUES_FOR_FIELD,
          payload: {
            field: this.props.filter.path,
            projectId: "",
            document: doc,
          },
        })
      }
    }
  }

  valueUpdated = (value: string, checked: boolean) => {
    const { onChange, filter } = this.props
    const newFilter = { ...filter }

    if (filter.value.includes(value) && !checked) {
      // Need to remove the value from this filter
      const newValues = newFilter.value.slice(0)
      const index = newValues.findIndex(v => v === value)
      if (index > -1) newValues.splice(index, 1)
      newFilter.value = newValues
    } else {
      // Need to add the value to the filter
      const newValues = newFilter.value.slice(0)
      newValues.push(value)
      newFilter.value = newValues
    }

    onChange(newFilter)
  }
  textValueUpdated = (value: string) => {
    const { onChange, filter } = this.props
    const newFilter = { ...filter, value: [value] }
    onChange(newFilter)
  }
  filterUpdated = (newFilter: $Filter) => {
    const { onChange } = this.props
    onChange(newFilter)
  }
  removeFilter = () => {
    this.props.onChange(null)
  }

  render() {
    const { filter, searchBar } = this.props
    let correspondingFilter = SEARCH_BUILDER_TOKENS[this.props.document].find(
      f => f.value === filter.path,
    )
    if (!correspondingFilter && filter.path.includes("metadata."))
      correspondingFilter = SEARCH_BUILDER_TOKENS[this.props.document].find(
        f => f.value === "metadata.",
      )
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          <div className="row path-row collapse">
            <div className="medium-1 columns">
              <input type="checkbox" checked onChange={this.removeFilter} />
            </div>
            <div
              className="medium-11 columns"
              onClick={() => {
                this.removeFilter()
              }}
            >
              {correspondingFilter ? correspondingFilter.label : filter.path}
            </div>
          </div>
          {correspondingFilter && correspondingFilter.type === "values" && (
            <input
              type="text"
              placeholder="Search for values..."
              value={this.state.valuesFilter}
              onChange={e => {
                this.setState({
                  valuesFilter: e.target.value,
                })
              }}
              style={{
                margin: 0,
                paddingLeft: "1em",
                border: "none",
                boxShadow: "none",
              }}
            />
          )}
          <div className="row collapse filter-content">
            <div className="medium-12 columns">
              {correspondingFilter && correspondingFilter.type === "values" && (
                <div className="filter-values">
                  {!searchBar[filter.path] && <Loading size={16} />}
                  {searchBar[filter.path] &&
                    searchBar[filter.path]
                      .filter(
                        p =>
                          !this.state.valuesFilter ||
                          p.value.toLowerCase().includes(this.state.valuesFilter.toLowerCase()),
                      )
                      .map(possibility => {
                        if (possibility.value) {
                          const renderHighlightedText = (text, higlight) => {
                            // Split text on higlight term, include term itself into parts, ignore case
                            const parts = text.split(new RegExp(`(${higlight})`, "gi"))
                            return (
                              <span>
                                {parts.map(part =>
                                  part.toLowerCase() === higlight.toLowerCase() ? (
                                    <span
                                      style={{
                                        backgroundColor: "lightgray",
                                      }}
                                    >
                                      {part}
                                    </span>
                                  ) : (
                                    part
                                  ),
                                )}
                              </span>
                            )
                          }

                          return (
                            <div
                              key={possibility.value}
                              className="row possibility-row collapse"
                              onClick={() => {
                                this.valueUpdated(
                                  possibility.value,
                                  !filter.value.includes(possibility.value),
                                )
                              }}
                            >
                              <div className="medium-1 columns">
                                <input
                                  type="checkbox"
                                  checked={filter.value.includes(possibility.value)}
                                  onChange={event => {
                                    this.valueUpdated(possibility.value, event.target.checked)
                                  }}
                                />
                              </div>
                              <div className="medium-11 columns">
                                {renderHighlightedText(possibility.label, this.state.valuesFilter)}
                              </div>
                            </div>
                          )
                        }
                      })}
                </div>
              )}
              {correspondingFilter && correspondingFilter.type === "text" && (
                <div>
                  <TextFilter
                    value={filter.value[0]}
                    onChange={newValue => {
                      this.textValueUpdated(newValue)
                    }}
                  />
                </div>
              )}
              {filter.path.includes("metadata.") && (
                <div>
                  <MetadataFilter
                    value={filter}
                    onChange={newValue => {
                      this.filterUpdated(newValue)
                    }}
                  />
                </div>
              )}
              {correspondingFilter && correspondingFilter.type === "amount" && (
                <div>
                  <AmountFilter
                    value={filter}
                    onChange={newValue => {
                      this.filterUpdated(newValue)
                    }}
                  />
                </div>
              )}
              {correspondingFilter && correspondingFilter.type === "date" && (
                <div>
                  <DateFilter
                    value={filter}
                    onChange={newValue => {
                      this.filterUpdated(newValue)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  searchBar: store.searchBar,
}))(SearchBuilderFilter)
