import React from "react"
import { connect } from "react-redux"
import { MapChart } from "@processout/picasso"
import * as ISOCountries from "../../util/ISOCountries"
import Loader from "../Loader"
type Props = {
  data: Array<Record<string, string>>
  color?: () => void
  tip?: () => void
}
type State = {
  uniqid: number
  map: MapChart
}

class GeoChart extends React.Component<Props, State> {
  draw() {
    if (!this.props.data || !document.getElementById(`chart${this.state.uniqid}`)) return

    if (!this.state.map) {
      this.setState({
        map: new MapChart(`#chart${this.state.uniqid}`, {
          tip: this.props.tip,
        }),
      })
      return
    }

    const { map } = this.state

    for (const country of this.props.data) {
      const c = ISOCountries.isoCountries[country.key]
      if (!c) continue
      map.addCountry({
        key: country.key,
        name: c.alpha3,
        country,
        color: this.props.color,
      })
    }

    map.resetSVG()
    map.draw()
  }

  UNSAFE_componentWillMount() {
    this.setState({
      uniqid: Math.floor(Math.random() * 1000),
    })
  }

  componentDidMount() {
    this.draw()
  }

  componentDidUpdate() {
    this.draw()
  }

  render() {
    if (!this.props.data) {
      return <Loader />
    }

    return (
      <svg
        className="chart map-chart"
        id={`chart${this.state.uniqid}`}
        width="100%"
        height="100%"
      />
    )
  }
}

export default connect()(GeoChart)
