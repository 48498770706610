import "brace/ext/language_tools"
import "brace/theme/cobalt"
import React from "react"
import AceEditor from "react-ace"
import { connect } from "react-redux"
import uniqid from "uniqid"
import CustomPolangMode from "./polang"
// @ts-expect-error
import type { $Dispatcher } from "../../../../util/Types"
import { CARDINALITY_FIELDS, TRANSACTION_FIELDS } from "../../ChartPreviewer/PathField/consts"
import * as Actions from "../actions"
import type { $DataExplorerState } from "../reducer"
import "./highlight.scss"
import TOKENS_LIST from "./PolangAutoComplete"
import { areFormulasEqual } from "../Utils"

type Props = {
  formula: {
    formula: string
  }
  disabled: boolean
  dataExplorer: $DataExplorerState
} & $Dispatcher

/**
 * We only want to run this code once
 * @type {any | *}
 */
// @ts-expect-error
const langTools = ace.acequire("ace/ext/language_tools")
const customMapper = {
  getCompletions: (editor, session, pos, prefix, callback) => {
    const tokens = []
    tokens.push(TOKENS_LIST)

    for (const field of TRANSACTION_FIELDS.concat(CARDINALITY_FIELDS).filter(
      (field, index, array) => array.findIndex(f => f === field) === index,
    )) {
      tokens.push({
        value: field,
        name: field,
        meta: "transactions",
      })
    }

    callback(null, tokens)
  },
}
langTools.addCompleter(customMapper)

class Editor extends React.Component {
  props: Props

  constructor() {
    super()
    this.aceEditorRef = React.createRef()
  }

  componentDidMount() {
    this.props.dispatch(Actions.selectTab("editor"))
    const customMode = new CustomPolangMode()
    const { editor } = this.aceEditorRef.current
    editor.getSession().setMode(customMode)
    editor.setOption("enableBasicAutocompletion", false)
    editor.setOption("enableLiveAutocompletion", true)
    editor.setOption("setBehavioursEnabled", true)
    editor.renderer.setShowGutter(false)
    this.resetContent(this.props.dataExplorer.formula)
    this.props.dispatch(Actions.setEditorHasBeenEdited(false))
  }

  // we want to force the update of the formula, to be the correct one and not the default one
  // if we don't - when we click on the new button [https://checkout.atlassian.net/browse/POFE-474] to be redirected to the code editor, the formula is the default one
  // and not the one related to the chart we just opened because of resetContent function that reset the formula every time we enter in the page
  // I don't want to change the whole logic by prevention and to not have regression on anything else
  // this will fix temporarily our problem before the revamp of analytics
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dataExplorer.previewedFormula) {
      if (
        !areFormulasEqual(
          nextProps.dataExplorer.previewedFormula,
          nextProps.dataExplorer.formula,
        ) &&
        !nextProps.dataExplorer.editorHasBeenEdited
      ) {
        this.props.dispatch(Actions.updateFormula(nextProps.dataExplorer.previewedFormula))
      }
    }
  }

  // Update the state => content
  onChange = (value: string) => {
    if (value === this.props.dataExplorer.formula) return
    this.props.dispatch(Actions.updateFormula(value))
    this.props.dispatch(Actions.setEditorHasBeenEdited(true))
  }
  resetContent = (formula: string) => {
    this.props.dispatch(Actions.updateFormula(formula))
  }

  render() {
    return (
      <div className="row">
        <div
          className="large-12 columns"
          data-equalizer-watch
          style={{
            minHeight: "15em",
          }}
        >
          <div className="code-editor">
            <AceEditor
              ref={this.aceEditorRef}
              theme="cobalt"
              value={this.props.dataExplorer.formula}
              onChange={this.onChange}
              name={uniqid()}
              editorProps={{
                $blockScrolling: true,
              }}
              showPrintMargin={false}
              fontSize={14}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  chartBuilder: store.chartBuilder,
  dataExplorer: store.dataExplorer,
  analytics: store.analytics,
}))(Editor)
