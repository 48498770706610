import React from "react"
import Loader from "../Loader"
import { datadogRum } from "@datadog/browser-rum"
type Props = {
  processOutClient: {
    setupForm: (
      element: HTMLElement | null | undefined,
      readyHandler: () => void,
      errorHandler: (error: Error) => void,
    ) => void
  }
  loading?: boolean
}
type State = {
  loading: boolean
}

class PaymentForm extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const formElement = document.getElementById("payment-form")
    this.props.processOutClient.setupForm(
      formElement,
      this.processoutReadyHandler.bind(this),
      err => {
        datadogRum.addError(err)
      },
      (event, data) => {
        switch (event) {
          case "onfocus": {
            break
          }

          case "onblur": {
            break
          }
        }
      },
    )
  }

  startLoading() {
    this.setState({
      loading: true,
    })
  }

  stopLoading() {
    this.setState({
      loading: false,
    })
  }

  getProcessOutForm() {
    return this._form
  }

  processoutReadyHandler(form) {
    this._form = form
    this.setState({
      loading: false,
    })
  }

  render() {
    const boxStyle = {
      border: "1px solid #cacaca",
      borderRadius: "4px",
      boxShadow: "0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)",
      height: "2.5em",
      padding: "0em 0.5em",
    }
    const boxFocusStyle = {
      border: "1px solid #8a8a8a",
      borderRadius: "4px",
      boxShadow: "0 5px 7px rgba(50, 50, 93, 0.04), 0 1px 3px rgba(0, 0, 0, 0.03)",
      height: "2.5em",
    }
    let content

    if (this.state.loading || this.props.loading) {
      content = <Loader />
    }

    return (
      <div>
        <form id="payment-form" className={`${this.state.loading ? "hide" : ""}`}>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <input
                type="text"
                id="card-holder-name"
                name="name"
                className="bottom-border no-margin"
                placeholder="Card holder name"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <div
                className="small-margin-bottom"
                style={boxStyle}
                data-processout-input="cc-number"
                data-processout-style="font-size:14px"
                data-processout-placeholder="1234 1234 1234 1234"
              />
            </div>
          </div>
          <div className="row">
            <div className="medium-6 columns">
              <div
                className="small-margin-bottom"
                style={boxStyle}
                data-processout-input="cc-exp"
                data-processout-style="font-size:14px"
                data-processout-placeholder="Expiration date (mm / yy)"
              />
            </div>
            <div className="medium-6 columns">
              <div
                className="small-margin-bottom"
                data-processout-style-focus={boxFocusStyle}
                style={boxStyle}
                data-processout-input="cc-cvc"
                data-processout-style="font-size:14px"
                data-processout-placeholder="CVC"
              />
            </div>
          </div>
        </form>
        {content}
      </div>
    )
  }
}

export default PaymentForm
