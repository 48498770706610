import React from "react"
import isoCurrencies from "iso-currencies"
import { connect } from "react-redux"
import type { $Project } from "^/util/Types"
import type { $Plan } from "^/stores/Reducers/PlansReducer"
import type { Product } from "^/types/Product"
type Props = {
  hideShippingTaxes: boolean
  displayLabel: boolean
  plan?: $Plan
  currentProject: {
    currentProject: {
      project: $Project
    }
  }
  invoice: Product
}
type State = {
  currency: string
}

class Prices extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      currency: "USD",
    }
  }

  componentDidMount() {
    const { project } = this.props.currentProject

    if (project) {
      this.setState({
        currency: project.default_currency,
      })
    }

    let resource = this.props.invoice

    if (this.props.plan) {
      resource = this.props.plan
    }

    if (!resource && !this.props.plan) return
    this.setState({
      currency: resource.currency,
    })
  }

  currencyChanged() {
    const s = document.getElementById("item-currency")
    const cur = s.options[s.selectedIndex].value
    this.setState({
      currency: cur,
    })
    this.formatPrices(null, cur)
  }

  formatPrices(event, currency) {
    const price = document.querySelector("#item-price")

    if (price.value.includes(".") && price.value[price.value.length - 1] !== ".") {
      const formatted = this.formatAmount(price.value, currency)
      if (formatted) price.value = formatted
    }
  }

  formatAmount(amount, currency) {
    if (!currency) {
      currency = this.state.currency
    }

    const precision = amount.split(".")[1].length
    const currencyInfo = isoCurrencies.information(currency)
    if (precision <= currencyInfo.places) return null
    amount = parseFloat(amount)
    return amount.toFixed(currencyInfo.places)
  }

  render() {
    let resource = this.props.invoice

    if (this.props.plan) {
      resource = this.props.plan
    }

    const { currencies } = this.props
    let name
    let price = null
    const { currency } = this.state

    if (resource != null) {
      name = resource.name
      price = parseFloat(resource.amount)
      // We only want to take 2 decimals if the price is exactly equal
      if (price == price.toFixed(2)) price = price.toFixed(2)
    }

    return (
      <div>
        <div className="row small-margin-bottom">
          <div className="large-12 medium-8 columns">
            <div className="row small-margin-bottom">
              <div className="large-6 columns">
                {this.props.displayLabel ? <label className="greyed">Price</label> : null}
                <input
                  type="text"
                  onInput={this.formatPrices.bind(this)}
                  name="amount"
                  disabled={this.props.plan != null}
                  className="bottom-border no-margin"
                  placeholder="Amount"
                  required
                  id="item-price"
                  defaultValue={price}
                />
              </div>
              <div className="large-6 columns">
                {this.props.displayLabel ? <label className="greyed">Currency</label> : null}
                <select
                  name="currency"
                  className="no-margin bottom-border"
                  id="item-currency"
                  value={currency}
                  onChange={this.currencyChanged.bind(this)}
                >
                  {currencies
                    .sort((curA, curB) => curA.code.localeCompare(curB.code))
                    .map(cur => (
                      <option key={cur.code} name={cur.code}>
                        {cur.code}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(Prices)
