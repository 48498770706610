import type {
  $Flags,
  $LaunchDarklyClient,
  $LaunchDarklyIdentifyUserAction,
  $LaunchDarklyInitFailedAction,
  $LaunchDarklyInitFulfilledAction,
  $LaunchDarklyInitPendingAction,
  $LaunchDarklyInitRequestAction,
  $LaunchDarklySetFlagsAction,
  $LaunchDarklyUser,
  $LaunchDarklyUserIdentifiedAction,
} from "./types"
import {
  LAUNCHDARKLY_INIT_FAILED,
  LAUNCHDARKLY_INIT_FULFILLED,
  LAUNCHDARKLY_INIT_PENDING,
  LAUNCHDARKLY_INIT_REQUEST,
  LAUNCHDARKLY_SET_FLAGS,
  LAUNCHDARKLY_USER_IDENTIFIED,
  LAUNCHDARKLY_USER_IDENTIFY,
} from "./consts"
export function initLaunchDarkly(): $LaunchDarklyInitRequestAction {
  return {
    type: LAUNCHDARKLY_INIT_REQUEST,
  }
}
export function setInitLaunchDarklyPending(): $LaunchDarklyInitPendingAction {
  return {
    type: LAUNCHDARKLY_INIT_PENDING,
  }
}
export function setInitLaunchDarklyFailed(error: Error): $LaunchDarklyInitFailedAction {
  return {
    type: LAUNCHDARKLY_INIT_FAILED,
    payload: {
      error: error as any,
    },
  }
}
export function setLaunchDarklyInitialised(
  client: $LaunchDarklyClient,
): $LaunchDarklyInitFulfilledAction {
  return {
    type: LAUNCHDARKLY_INIT_FULFILLED,
    payload: {
      client,
    },
  }
}
export function identifyUser(): $LaunchDarklyIdentifyUserAction {
  return {
    type: LAUNCHDARKLY_USER_IDENTIFY,
  }
}
export function userIdentified(user: $LaunchDarklyUser): $LaunchDarklyUserIdentifiedAction {
  return {
    type: LAUNCHDARKLY_USER_IDENTIFIED,
    payload: {
      user,
    },
  }
}
export function setFlags(flags: any): $LaunchDarklySetFlagsAction {
  return {
    type: LAUNCHDARKLY_SET_FLAGS,
    payload: {
      flags,
    },
  }
}
