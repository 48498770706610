import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import Tooltip from "react-tooltip"
import uniqid from "uniqid"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import Recommendations from "./Recommendations"
import type { $Project, $State } from "../../../util/Types"
import type { $Surface } from "./consts"
import type { $SurfacesState } from "./reducer"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer"
import { segmentAnalytics } from "^/vNext/Segment"
type Props = {
  surface: $Surface
  fadeOut: boolean
  opened: boolean
  onOpen: (arg0: string) => void
  onClose: (arg0: string) => void
  currentProject: {
    project: $Project
  } & $State
  surfaces: $SurfacesState
  hoveredSurface: string | null | undefined
  telescope: $TelescopeState
}
type State = {
  highlightHardFail: boolean
}

class Surface extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      highlightHardFail: false,
    }
  }

  componentDidMount() {
    if (
      this.props.hoveredSurface === this.props.surface.id &&
      this.props.surface.clickable &&
      !this.props.disabled
    )
      this.props.onOpen(this.props.surface.id)
    else if (this.props.hoveredSurface === this.props.surface.id && !this.props.surface.clickable) {
      this.setState(
        {
          highlightHardFail: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              highlightHardFail: false,
            })
          }, 400)
        },
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.hoveredSurface === nextProps.surface.id && nextProps.surface.clickable)
      this.props.onOpen(this.props.surface.id)
    else if (nextProps.hoveredSurface === nextProps.surface.id && !nextProps.surface.clickable) {
      this.setState(
        {
          highlightHardFail: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              highlightHardFail: false,
            })
          }, 400)
        },
      )
    }
  }

  toggle = () => {
    if (this.props.surface.clickable && !this.props.disabled) {
      if (!this.props.opened) {
        this.props.onOpen(this.props.surface.id)
        segmentAnalytics?.track("OPENED_SURFACE", {
          surface: this.props.surface.name,
          timespan: this.props.telescope.standard.timespan,
        })
      } else {
        this.props.onClose(this.props.surface.id)
        const cutElement = document.getElementById(`transaction-cut-${this.props.surface.id}`)
        if (!cutElement) return
        cutElement.className = cutElement.className.replace(" highlighted", "")
      }
    } else {
      this.setState(
        {
          highlightHardFail: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              highlightHardFail: false,
            })
          }, 400)
        },
      )
    }
  }

  componentDidUpdate() {
    Tooltip.rebuild()
  }

  render() {
    const { surface, telescope, surfaces } = this.props
    return (
      <div
        className={`row surface ${this.props.opened ? "opened" : ""}${
          this.props.fadeOut ? "fade" : ""
        }`}
        id={`surface-${surface.id}`}
      >
        <Tooltip effect="solid" />
        <div className="large-12 columns">
          <Container onClick={this.toggle} clickable={surface.clickable}>
            <div
              className="row head recuperable"
              onMouseOver={() => {
                const element = document.getElementById(`transaction-cut-${surface.id}`)
                if (element)
                  element.className = `${element.className.replace(
                    / highlighted/g,
                    "",
                  )} highlighted`
              }}
              onMouseLeave={() => {
                for (const s of this.props.surfaces.surfaces) {
                  if (this.props.hoveredSurface !== s.id) {
                    const element = document.getElementById(`transaction-cut-${s.id}`)
                    if (element) element.className = element.className.replace(/ highlighted/g, "")
                  }
                }
              }}
            >
              <div className="large-12 columns">
                <div className="row">
                  {surface.clickable ? (
                    <div>
                      <div className="large-6 columns">
                        {surface.is_new ? (
                          <div>
                            <span className="name ">{surface.name}</span>
                            <div className="recommendations-new-tag">NEW</div>
                          </div>
                        ) : (
                          <div className="name ">{surface.name}</div>
                        )}
                      </div>
                      <div className="large-5 columns text-right">
                        <div className="stats">
                          {surfaces.type === "chargeback-rate" ? "Found " : "Found on "}
                          <strong>
                            <FormattedNumber value={surface.count} />{" "}
                          </strong>
                          {surfaces.type === "chargeback-rate" ? " chargebacks " : " transactions "}

                          {surface.total_money_impact ? (
                            <div
                              style={{
                                display: "inline-block",
                              }}
                            >
                              {" for "}
                              <span className="high-number">
                                <FormattedNumber
                                  value={surface.total_estimated_gain}
                                  style="currency"
                                  currency={this.props.currentProject.project.default_currency}
                                  minimumFractionDigits={0}
                                  maximumFractionDigits={0}
                                />
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="large-1 columns">
                        {surface.clickable ? <div className="arrow">→</div> : null}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="large-3 columns">
                        <div className="name ">{surface.name}</div>
                      </div>
                      <div className="large-8 end columns text-right">
                        <div
                          className={`greyed hard-fail-indicator ${
                            this.state.highlightHardFail ? "highlight" : ""
                          }`}
                        >
                          {surface.description}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
          <ReactCSSTransitionGroup
            transitionName="surface-animation"
            transitionEnterTimeout={450}
            transitionLeaveTimeout={450}
          >
            {this.props.opened ? (
              <div key={uniqid()}>
                <Recommendations
                  recommendations={surface.associated_recommendations}
                  surface={surface}
                />
              </div>
            ) : null}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
  surfaces: store.surfaces,
  telescope: store.telescope,
}))(Surface)

class Container extends React.Component<{
  clickable: boolean
  onClick: () => any
  children?: any | null | undefined
}> {
  render() {
    return <a onClick={this.props.onClick}>{this.props.children}</a>
  }
}
