import sum from "lodash/sum"
import React from "react"
import Error from "^/components/Error"
import Loading from "^/components/Loader"
import ContentLayout from "^/pages/ContentLayout"
import { $Project } from "^/util/Types"
import { $Recommendations } from "../consts"
import { getEvolution } from "../util"
import DiagnosticsTable from "./DiagnosticsTable"
import MetricsOptimization from "./MetricsOptimization"

type Props = {
  diagnosticRecommendations: {
    periodRecommendations: {
      periodRecommendations: $Recommendations
      fetched: boolean
      fetching: boolean
      error?: {
        details: any
        type: string
      }
    }
  }
  currentProject: {
    project: $Project
  }
  loading: boolean
}

export function PaymentAudit({ diagnosticRecommendations, currentProject, loading }: Props) {
  const currency = currentProject.project.default_currency
  const profile = diagnosticRecommendations.periodRecommendations.periodRecommendations.profiles

  const getAmount = () => {
    const arrayOfAmounts = []
    profile?.forEach(profile => arrayOfAmounts.push(profile.amount_to_gain))
    return sum(arrayOfAmounts)
  }

  return (
    <ContentLayout
      title={
        <div className="row">
          <div className="large-12 columns">
            <h5>Detection of Conversion rate drops</h5>
            {profile && profile[0]?.profile.transaction_type ? (
              <h6>
                Last updated on {profile[0].profile.transaction_type} (for the period from{" "}
                {profile[0].profile.three_ds} to {profile[0].profile.ip_country_name}), no more
                conversion rate drops detected since
              </h6>
            ) : (
              <h6>No anomalies detected</h6>
            )}
            <div className="row large-margin-top">
              <div className="column medium-5">
                <MetricsOptimization diagnosticOptimization={getAmount()} currency={currency}>
                  Total amount impacted:
                </MetricsOptimization>
              </div>
            </div>
          </div>
        </div>
      }
      padding="0"
    >
      {loading ? (
        <Loading />
      ) : diagnosticRecommendations.periodRecommendations.error ? (
        <Error text="Our algorithm is still working on your data…" />
      ) : (
        <DiagnosticsTable tableData={profile} currentProject={currentProject} />
      )}
    </ContentLayout>
  )
}
