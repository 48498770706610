import React from "react"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import * as SubscriptionsActions from "../Actions/SubscriptionsActions"
import Loader from "../components/Loader"
import Empty from "../components/Empty"
import Error from "../components/Error"
import Pagination from "../components/Pagination"
import Subscription from "../components/Subscription"
import NotPermitted from "../features/Permissions/NotPermitted"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
import type { $SubscriptionsDetailsState } from "^/stores/Reducers/SubscriptionDetailsReducer.js"
const subscriptionsPerPage = 30
type Props = {
  location: $RouterLocation
  subscriptionsDetails: $SubscriptionsDetailsState
} & $Dispatcher
type State = {
  filter: string
}

class Subscriptions extends React.Component<Props, State> {
  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Subscriptions | ProcessOut"
    const { filter } = this.props.location.query
    this.setState({
      filter,
    })
    // we check that there is an afterItem parameter
    // if not then we are going backward and there should be a before item
    const afterItem = this.props.location.query.after_item
    const beforeItem = this.props.location.query.before_item
    this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let firstItem = this.props.location.query.after_item
    if (!firstItem) firstItem = ""

    if (
      !nextProps.subscriptionsDetails.fetching &&
      !nextProps.subscriptionsDetails.fetched &&
      !nextProps.subscriptionsDetails.error
    ) {
      this.props.dispatch(
        SubscriptionsActions.loadSubscriptions(
          this.state.filter,
          subscriptionsPerPage,
          true,
          firstItem,
        ),
      )
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  constructor() {
    super()
    this.state = {
      filter: "",
    }
  }

  applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
    this.props.dispatch(
      SubscriptionsActions.loadSubscriptions(
        filter,
        subscriptionsPerPage,
        forward !== null ? forward : true,
        firstItem,
      ),
    )
  }

  render() {
    const { subscriptions } = this.props.subscriptionsDetails
    let header
    let content

    if (
      (this.props.subscriptionsDetails.fetching || !this.props.subscriptionsDetails.fetched) &&
      !this.props.subscriptionsDetails.error
    ) {
      content = <Loader />
    } else if (this.props.subscriptionsDetails.error) {
      if (this.props.subscriptionsDetails.error.notPermitted) content = <NotPermitted />
      else content = <Error />
    } else {
      let empty

      if (subscriptions.length == 0) {
        if (this.state.filter !== "") {
          empty = <Empty text="No subscription matches your search." />
        } else {
          empty = <Empty text="You haven't added any subscription to your customers yet." />
        }
      }

      content = (
        <div>
          <div className="row">
            <div className="large-12 columns">
              {empty}

              {subscriptions.map(subscription => (
                <Subscription subscription={subscription} key={subscription.id} />
              ))}
            </div>
          </div>
        </div>
      )

      if (subscriptions && subscriptions.length > 0) {
        header = (
          <div
            className="row capitalize greyed bold small-margin-bottom"
            style={{
              fontSize: "0.8em",
            }}
          >
            <div className="medium-offset-1 medium-3 columns">
              <div
                style={{
                  marginLeft: "-1em",
                }}
              >
                Amount
              </div>
            </div>
            <div className="medium-6 columns">Name</div>
            <div className="medium-2 columns text-right">Date</div>
          </div>
        )
      }
    }

    const title = (
      <div className="row">
        <div className="large-12 columns">
          <h4>Subscriptions</h4>
        </div>
      </div>
    )
    return (
      <ContentLayout title={title}>
        <div className="row">
          <div className="large-12 columns">
            {header}
            {content}
            <Pagination
              location={this.props.location}
              reloadFunction={SubscriptionsActions.loadSubscriptions.bind(
                null,
                this.state.filter,
                subscriptionsPerPage,
              )}
              hasMore={
                this.props.subscriptionsDetails.fetched
                  ? this.props.subscriptionsDetails.has_more
                  : false
              }
              itemsArray={this.props.subscriptionsDetails.fetched ? subscriptions : []}
              hide={
                this.props.subscriptionsDetails.fetching || !this.props.subscriptionsDetails.fetched
              }
            />
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  subscriptionsDetails: store.subscriptions,
}))(Subscriptions)
