import React from "react"
import Creatable from "react-select/creatable"
import { SelectStyle } from "../../analytics/DataExplorer/ChartBuilder/consts"
import type { $Option } from "../../analytics/DataExplorer/consts"
type Props = {
  operand: string
  value: number
  onChange: (arg0: $Option) => void
}

class ValueSelection extends React.Component<Props> {
  render() {
    const { value, operand } = this.props
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label">{operand === "==" ? "to" : "than"}</div>
        <div
          className="labeled-field"
          style={{
            width: "150px",
          }}
        >
          <Creatable
            value={{
              label: value,
              value,
            }}
            styles={SelectStyle}
            onChange={newValue => {
              this.props.onChange(newValue.value)
            }}
            promptTextCreator={label => label}
          />
        </div>
      </div>
    )
  }
}

export default ValueSelection
