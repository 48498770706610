import { all, call, put, select, takeLatest } from "redux-saga/effects"
import type { $TelescopeRequestAction } from "../consts"
import {
  PREPARE_TELESCOPE,
  PREPARE_TELESCOPE_SILENT,
  REQUEST_TELESCOPE_PREPARATION,
  UPDATE_TELESCOPE_TIMESPAN,
} from "../consts"
import * as ProcessOut from "../../../util/ProcessOut"
import * as AuthorizationSagas from "./authorizationSagas"
import * as CaptureSagas from "./captureSagas"
import * as RefundsSagas from "./refundsSagas"
import * as ChargebackSagas from "./chargebacksSagas"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import * as Actions from "../actions"
import { REQUEST_CHARGEBACK_DATA_FETCH } from "../actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"

function* fetchTelescope(timespan: string): Generator<any, any, any> {
  try {
    const httpResult = yield ProcessOut.APIcallPromise(
      `/analytics/telescope?interval=${timespan}`,
      "GET",
    )
    if (!httpResult.data.success) throw httpResult.data.message || "Error while fetching telescope"
    return {
      telescope: httpResult.data.telescope,
      fees_recommendations: httpResult.data.telescope.recommendations_feed.filter(
        r => r.category === "fees",
      ),
    }
  } catch (error) {
    return null
  }
}

export function* prepareTelescope(action: $TelescopeRequestAction): Generator<any, any, any> {
  const { timespan, tab } = action.payload
  yield put({
    type: typePending(action.payload.silent ? PREPARE_TELESCOPE_SILENT : PREPARE_TELESCOPE),
    payload: {
      tab,
    },
  })

  try {
    const gwayResults = yield call(
      ProcessOut.APIcallPromise,
      "/helpers/gateway-configurations/names",
      "GET",
    )
    const gatewayConfigurations = Object.keys(gwayResults.data.gateway_configuration_names).map(
      id => ({
        id,
        name: gwayResults.data.gateway_configuration_names[id],
      }),
    )
    let promises = [
      call(AuthorizationSagas.fetchGlobalAuth, timespan),
      call(CaptureSagas.fetchGlobalFees, timespan),
      call(RefundsSagas.fetchGlobalRefunds, timespan),
      call(ChargebackSagas.fetchGlobalChargeback, timespan),
    ]

    switch (tab) {
      case "auth": {
        promises = promises.concat([
          call(fetchTelescope, timespan),
          call(AuthorizationSagas.fetchAuthCountry, timespan),
          call(AuthorizationSagas.fetchAuthorizedTransactions, timespan),
          call(AuthorizationSagas.fetchAuthorizedAmount, timespan),
          call(AuthorizationSagas.fetchFailedTransactions, timespan),
          call(AuthorizationSagas.fetchAuthorizationPerPspBar, timespan, gatewayConfigurations),
          call(
            AuthorizationSagas.fetchAuthorizationPerPspPerCardSchemeBar,
            timespan,
            gatewayConfigurations,
          ),
          call(
            AuthorizationSagas.fetchAuthorizationPerPspPerCardTypeBar,
            timespan,
            gatewayConfigurations,
          ),
        ])
        break
      }

      case "capt": {
        promises = promises.concat([
          call(CaptureSagas.fetchCaptureAmount, timespan),
          call(CaptureSagas.fetchCapturedTransactions, timespan),
          call(CaptureSagas.fetchGatewayFees, timespan),
          call(CaptureSagas.fetchFeesPercentPerCountry, timespan),
          call(CaptureSagas.fetchGatewayFeesPerPspBar, timespan, gatewayConfigurations),
          call(CaptureSagas.fetchFeesPerPspPerCardSchemeBar, timespan, gatewayConfigurations),
          call(CaptureSagas.fetchFeesPerPspPerCardTypeBar, timespan, gatewayConfigurations),
        ])
        break
      }

      case "refu": {
        promises = promises.concat([
          call(RefundsSagas.fetchRefundedAmount, timespan),
          call(RefundsSagas.fetchRefundedTransactions, timespan),
          call(RefundsSagas.fetchRefundsPerCountry, timespan),
          call(RefundsSagas.fetchVoidedAmount, timespan),
          call(RefundsSagas.fetchVoidedTransactions, timespan),
          call(RefundsSagas.fetchRefundAmountPerPspBar, timespan, gatewayConfigurations),
        ])
        break
      }

      case "char": {
        promises = promises.concat([call(ChargebackSagas.fetchChargebackConfigurations, timespan)])
        break
      }

      default: {
        break
      }
    }

    const results = yield all(promises)
    yield put({
      type: typeFulfilled(PREPARE_TELESCOPE),
      payload: {
        data: results.reduce((value, result) => ({ ...value, ...result }), {}),
        tab,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREPARE_TELESCOPE),
      payload: {
        error,
        tab,
      },
    })
    datadogRum.addError(error)
  }
}

function* updatedTimespan(): Generator<any, any, any> {
  const telescope: $TelescopeState = yield select<any>(store => store.telescope)
  yield put(
    Actions.requestTelescopePrepare(
      telescope.standard.timespan,
      true,
      telescope.standard.selectedTab,
    ),
  )
}

export function* requestTelescopePreparation(): Generator<any, any, any> {
  yield takeLatest(REQUEST_TELESCOPE_PREPARATION, prepareTelescope)
  yield takeLatest(UPDATE_TELESCOPE_TIMESPAN, updatedTimespan)
  yield takeLatest(REQUEST_CHARGEBACK_DATA_FETCH, ChargebackSagas.fetchChargebackData)
}
