import React from "react"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
// @ts-expect-error
import "./searchbuilder.scss"
import SearchBuilderFilter from "./SearchBuilderFilter"
import FilterPathSelector from "./FilterPathSelector"
import { SEARCH_BUILDER_GROUPS, SEARCH_BUILDER_TOKENS } from "./consts"
import uniqid from "uniqid"
import FiltersGroup from "./FiltersGroup"
import { track } from "^/vNext/tracking"
type Props = {
  value: Array<$Filter>
  onChange: (arg0: Array<$Filter>) => void
  document: "transactions" | "analytics" | "customers" | "cards"
  onSave: () => void
  onClear: () => void
  onClose?: (() => void) | null | undefined
  modified: boolean
}
type State = {
  open: boolean
}
const pageDocumentMapping = {
  analytics: "Analytics",
  cards: "Vault",
  customers: "Customers",
  transactions: "Transactions",
}

class SearchBuilder extends React.Component<Props, State> {
  _panel: any
  _filterButton: any

  constructor() {
    super()
    this.state = {
      open: false,
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = (event: MouseEvent) => {
    const { onClose } = this.props

    if (
      this._panel &&
      !this._panel.contains(event.target) &&
      this._filterButton &&
      !this._filterButton.contains(event.target)
    ) {
      this.setState({
        open: false,
      })

      if (onClose) {
        onClose()
      }
    }
  }
  newFilterSelected = (path: string) => {
    const newFilter = {
      id: uniqid(),
      path,
      operand: "==",
      value: [],
    }
    const newArray: Array<$Filter> = this.props.value.slice(0)
    newArray.push(newFilter)
    this.props.onChange(newArray)
    track(pageDocumentMapping[this.props.document], "Check", "Filter", "Criteria", {
      field: path,
    })
  }
  filterUpdated = (filter: $Filter) => {
    const { onChange, value } = this.props
    const index = value.findIndex(f => f.id === filter.id)

    if (index > -1) {
      const newFilters = value.slice(0)
      newFilters[index] = filter
      onChange(newFilters)
    }
  }
  filterRemoved = (id: string) => {
    const { onChange, value } = this.props
    const index = value.findIndex(f => f.id === id)

    if (index > -1) {
      const newFilters = value.slice(0)
      newFilters.splice(index, 1)
      onChange(newFilters)
      track(pageDocumentMapping[this.props.document], "Uncheck", "Filter", "Criteria", {
        field: value[index].path,
      })
    }
  }
  clearFilters = () => {
    this.setState({
      open: false,
    })
    this.props.onClear()
    track(pageDocumentMapping[this.props.document], "Click", "Filter", "Clear Button")
  }
  save = () => {
    this.setState({
      open: false,
    })
    track(pageDocumentMapping[this.props.document], "Click", "Filter", "Apply Button")

    for (const filter of this.props.value) {
      track(pageDocumentMapping[this.props.document], "Apply", "Filter Criteria", "Item", {
        field: filter.path,
        count: filter.value.length,
      })
    }

    this.props.onSave()
  }
  computeGroup = (group: string) =>
    SEARCH_BUILDER_TOKENS[this.props.document].filter(field => field.group === group)

  render() {
    const { value } = this.props
    const { open } = this.state
    const groups = SEARCH_BUILDER_GROUPS[this.props.document]
    const filtersGroups = groups.map(group => ({
      group: this.computeGroup(group.name),
      name: group.label,
    }))
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <button
          onClick={() => {
            this.setState({
              open: !this.state.open,
            })
          }}
          className="filter-button button round white"
          ref={e => (this._filterButton = e)}
          style={{
            margin: 0,
            padding: "0.6rem 1rem",
          }}
        >
          <span>
            Filter page data
            {value.length > 0 && ` (${value.length})`}
          </span>
        </button>
        {open && (
          <div className="search-builder" ref={e => (this._panel = e)}>
            <div className="search-builder-content">
              <div className="row header-row collapse">
                <div className="medium-4 columns">
                  <button className="small round border button" onClick={this.clearFilters}>
                    Clear
                  </button>
                </div>
                <div
                  className="medium-4 columns text-center"
                  style={{
                    position: "relative",
                    top: ".3em",
                  }}
                >
                  Filters
                </div>
                <div className="medium-4 columns text-right">
                  <button
                    className={`small round ${this.props.modified ? "main" : "border"} button`}
                    onClick={this.save}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="row collapse">
                <div className="medium-12 columns">
                  {filtersGroups.map(group => (
                    <FiltersGroup name={group.name} key={group.name}>
                      {group.group.map(field => {
                        const filter = value.find(
                          v =>
                            v.path === field.value ||
                            (v.path.includes("metadata.") && field.type === "metadata"),
                        )

                        if (filter) {
                          // Filter is active
                          return (
                            <SearchBuilderFilter
                              document={this.props.document}
                              key={filter.id}
                              filter={filter}
                              onChange={newFilter => {
                                if (newFilter) this.filterUpdated(newFilter)
                                else this.filterRemoved(filter.id)
                              }}
                            />
                          )
                        }

                        return (
                          <FilterPathSelector
                            option={field}
                            key={uniqid()}
                            onSelect={() => {
                              this.newFilterSelected(field.value)
                            }}
                          />
                        )
                      })}
                    </FiltersGroup>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default SearchBuilder
