import React from "react"
import uniqid from "uniqid"
import type { $TelescopeMetricData } from "../../consts"
import { sliceIntoChunks } from "../../util"
import SummaryCard from "./SummaryCard"
type Props = {
  data: Array<$TelescopeMetricData>
  loading: boolean
  size?: number
}
export default function SummaryCards({ data, loading, size }: Props) {
  // the size will be the number of item on the first line, then other items will be at the line
  const chunks = sliceIntoChunks(data, size ? size : 0, size)
  return (
    <>
      {chunks.map<React.ReactElement<React.ComponentProps<"div">, "div">>(metrics => (
        <div className="row margin-bottom" key={uniqid()}>
          {metrics.map(metric => (
            <div className="columns medium-3 end" key={metric.id}>
              <SummaryCard
                title={metric.title}
                value={metric.value}
                id={metric.id}
                tooltipContent={metric.tooltipContent}
                percentageEvolution={metric.percentageEvolution}
                valueStyle={metric.valueStyle}
                loading={loading}
                moreDetails={metric.moreDetails}
                count={metric.count}
                amount={metric.amount}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}
