import { combineReducers } from "redux"
import { createTelescopeV2PageReducer } from "./createTelescopeV2PageReducer"

export default combineReducers({
  overview: createTelescopeV2PageReducer("overview"),
  anomalyDetection: createTelescopeV2PageReducer("anomalyDetection"),
  authorization: createTelescopeV2PageReducer("authorization"),
  domainsSecure: createTelescopeV2PageReducer("domainsSecure"),
  captureAndVoids: createTelescopeV2PageReducer("captureAndVoids"),
  refunds: createTelescopeV2PageReducer("refunds"),
  fees: createTelescopeV2PageReducer("fees"),
  chargebacks: createTelescopeV2PageReducer("chargebacks"),
})
