import React from "react"
import PolangWordItem from "./PolangWordItem"
import type { $SearchBuilderOption } from "../SearchBuilder/consts"
type Props = {
  suggestions: $SearchBuilderOption
  cursor: boolean
  selectPolandWord: () => void
}
export class SearchPolangItem extends React.Component<Props> {
  render() {
    const { cursor, suggestions, selectPolandWord } = this.props
    const content = suggestions
      .filter(item => !!item.value)
      .map((item, i) => (
        <div
          key={item.value}
          role="button"
          onClick={() => {
            selectPolandWord(item)
          }}
          className={cursor === i ? "highlighted" : ""}
          id={`polang-word-${i}`}
          tabIndex={0}
        >
          <PolangWordItem word={item.value} description={item.description} />
        </div>
      ))
    return <div>{content}</div>
  }
}
export default SearchPolangItem
