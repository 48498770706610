import React from "react"
type Props = {
  value: string
  updateValue: (arg0: Event) => void
  onSubmit: (arg0: Event) => void
  defaultValue?: string
  shouldDisplayApplyButton?: boolean | null | undefined
}
export class MetadataInput extends React.Component<Props> {
  render() {
    const { shouldDisplayApplyButton, value, updateValue, onSubmit, defaultValue } = this.props
    return (
      <>
        <div className={`medium-${shouldDisplayApplyButton ? 5 : 7} columns`}>
          <input
            id="plotted-field-metadata-field-name-input"
            type="text"
            placeholder="Customer id..."
            onChange={updateValue}
            onBlur={shouldDisplayApplyButton ? null : onSubmit}
            value={value}
          />
        </div>
        <div className="medium-2 columns">
          {shouldDisplayApplyButton ? (
            <a
              className="round border button small metadata-apply-button"
              onClick={onSubmit}
              disabled={defaultValue && defaultValue === `transactions.metadata.${value}`}
            >
              Apply
            </a>
          ) : null}
        </div>
      </>
    )
  }
}
export default MetadataInput
