import React, { useReducer } from "react"
import { DragDropContext, initialState } from "./DragDropContext"
import type { actions, DragDropState } from "./types"

function reducer(state: DragDropState, action: actions): DragDropState {
  switch (action.type) {
    case "start":
      return {
        ...state,
        activeCategory: action.activeCategory,
        draggingTarget: action.draggingTarget,
      }

    case "over":
      return { ...state }

    case "leave":
      break

    case "drop":
      return { ...state, activeCategory: null, draggingTarget: null }
  }

  return state
}

export type Props = {
  children: React.ReactNode
}

/**
 * Drag and drop container element, ancester of Draggable elements.
 */
export const DragDrop = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <DragDropContext.Provider value={[state, dispatch]}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}

      {children}
    </DragDropContext.Provider>
  )
}
