import React from "react"
import Creatable from "react-select/creatable"
import { SelectStyle } from "../../../analytics/DataExplorer/ChartBuilder/consts"
import { VELOCITY_INTERVAL_OPTIONS } from "./consts"
import type { $Option } from "../../../analytics/DataExplorer/consts"

type Props = {
  value: string | null | undefined
  onChange: (arg0: string) => void
}

class VelocityInterval extends React.Component<Props> {
  createValue = newValue => {
    this.props.onChange(newValue)
  }

  render() {
    const { value, onChange } = this.props

    if (!value) onChange(VELOCITY_INTERVAL_OPTIONS[0].value)

    const correspondingOption = VELOCITY_INTERVAL_OPTIONS.find(op => op.value === value)
    let label = value
    if (correspondingOption) label = correspondingOption.label
    return (
      <div
        className="labeled-field"
        style={{
          width: "75%",
        }}
      >
        <Creatable
          options={VELOCITY_INTERVAL_OPTIONS}
          placeholder="Choose option..."
          value={
            value
              ? {
                  label,
                  value,
                }
              : null
          }
          onChange={(newValue: $Option) => {
            onChange(newValue.value)
          }}
          styles={SelectStyle}
          onCreateOption={this.createValue}
        />
      </div>
    )
  }
}

export default VelocityInterval
