import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import GeoChart from "../../../components/Analytics/GeoChart"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import { computeAuthorizationColor } from "../Recommendation/utils"
import * as ISOCountries from "../../../util/ISOCountries"
import BarChart from "../../analytics/Charts/BarChart"
import Error from "../../../components/Error"
import type { $ProviderCompareOption } from "../Components/ProviderCompareSelector"
import ProviderCompareSelector from "../Components/ProviderCompareSelector"
import getColor from "../../analytics/Boards/charts/colors"
import Empty from "../../../components/Empty"
type Props = {
  telescope: $TelescopeState
}
type State = {
  pspCompare: $ProviderCompareOption
}

class AuthorizationsContent extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      pspCompare: {
        value: "general",
        label: "general",
      },
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextProps.telescope.standard.authorizationReady !==
        this.props.telescope.standard.authorizationReady || this.state !== nextState
    )
  }

  render() {
    const { telescope } = this.props
    return (
      <div className="row text-left">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-5 columns">
              <div className="row">
                <div className="large-12 columns">
                  <div className="chart-title">Authorization rate per country</div>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  {telescope.standard.data.authPerCountry ? (
                    <div
                      style={{
                        height: "250px",
                      }}
                    >
                      <GeoChart
                        key={uniqid()}
                        data={telescope.standard.data.authPerCountry}
                        color={computeAuthorizationColor}
                        tip={function (d) {
                          const c = ISOCountries.isoCountries[d.key]
                          const authDisplay =
                            d.country.authorization !== null
                              ? `${(d.country.authorization * 100).toLocaleString(undefined, {
                                  maximumFractionDigit: 2,
                                })}%`
                              : "--"
                          return (
                            `${c.full_name}<br />` +
                            `<span class="greyed">Authorization rate:</span> ${authDisplay}<br />`
                          )
                        }}
                      />
                    </div>
                  ) : (
                    <div className="row small-margin-bottom">
                      <div className="large-12 columns">
                        <Error />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="large-6 large-offset-1 columns">
              <div className="row">
                <div className="large-12 columns">
                  <div className="row">
                    <div className="large-5 columns">
                      <div className="chart-title">Authorization rate</div>
                    </div>
                    <div className="large-7 columns">
                      <ProviderCompareSelector
                        value={this.state.pspCompare}
                        onChange={(newValue: $ProviderCompareOption) => {
                          this.setState({
                            pspCompare: newValue,
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">
                      <div
                        style={{
                          height: "250px",
                        }}
                      >
                        {(() => {
                          const data =
                            this.state.pspCompare.value === "general"
                              ? telescope.standard.data.authorization_rate_per_psp_bar
                              : this.state.pspCompare.value === "card_scheme"
                              ? telescope.standard.data
                                  .authorization_rate_per_psp_per_card_scheme_bar
                              : telescope.standard.data.authorization_rate_per_psp_per_card_type_bar

                          if (!data) {
                            return (
                              <div
                                style={{
                                  marginTop: "2em",
                                }}
                              >
                                <Error />
                              </div>
                            )
                          }

                          if (data.length === 0 || data[0].datapoints.length === 0)
                            return <Empty text="No data to display" />
                          return (
                            <div key={uniqid()}>
                              <BarChart
                                type="percentage"
                                data={data}
                                colors={data.map((entry, index) => ({
                                  key: entry.key,
                                  color: getColor(index),
                                }))}
                                comparison
                              />
                            </div>
                          )
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  currentProject: store.currentProject,
}))(AuthorizationsContent)
