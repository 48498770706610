import React, { useEffect } from "react"
import { prepareTelescopeV2Page } from "../actions"
import type { $CurrentProject, $Dispatcher, $RouterLocation } from "^/util/Types"
import type {
  $ChartDatasFromApi,
  $PerCountryTableData,
  $Period,
  $TelescopeMetricData,
} from "../consts"
import ContentLayout from "^/pages/ContentLayout"
import TelescopeHeader from "../components/TelescopeHeader"
import SummaryCards from "../components/SummaryCards/SummaryCards"
import { connect } from "react-redux"
import FeesLineCharts from "./FeesLineCharts"
import Loading from "^/components/Loader"
import BarChartTooltip from "../components/BarChartTooltip"
import PerCountryTable from "../components/PerCountryTable"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"

type $MetricsTelescope = {
  fees_rate: number
  fees_rate_increase: number
  fees_amount: number
  fees_rate_chart: $ChartDatasFromApi[]
  fees_rate_by_gateway: $ChartDatasFromApi[]
  fees_rate_by_card_scheme: $ChartDatasFromApi[]
  fees_rate_by_payment_method: $ChartDatasFromApi[]
  fetched: boolean
  fetching: boolean
  feesTableData: Array<$PerCountryTableData>
}
type Props = {
  location: $RouterLocation
  metricsTelescope: $MetricsTelescope
  currentProject: $CurrentProject
} & $Dispatcher
export function Fees({ location, dispatch, metricsTelescope, currentProject }: Props) {
  useEffect(() => {
    document.title = "Fees | ProcessOut"
  }, [])

  useEffect(() => {
    dispatch(prepareTelescopeV2Page("fees", location.query.period as $Period))
  }, [location.query])
  const summaryMetrics: $TelescopeMetricData[] = [
    {
      title: "Fees rate",
      value: metricsTelescope.fees_rate,
      percentageEvolution: metricsTelescope.fees_rate_increase,
      id: "fees_rate",
      tooltipContent: "The percentage of fees that are paid for all transactions",
      valueStyle: "percent",
    },
    {
      title: "Fees amount",
      value: metricsTelescope.fees_amount,
      id: "fees_amount",
      tooltipContent: "The value of the fees paid in total over the selected period",
      valueStyle: "currency",
    },
  ]
  const feesBarChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.fees_rate_by_gateway,
      tooltipContent: "Breakdown of fees paid per payment service provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.fees_rate_by_card_scheme,
      tooltipContent: "Breakdown of fees paid per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.fees_rate_by_payment_method,
      tooltipContent: "Breakdown of fees paid per payment method",
      id: "paymentMethod_id",
    },
  ]
  const lineChartData = [{ data: metricsTelescope.fees_rate_chart ?? [], chartTitle: "Fees" }]
  const loading = !metricsTelescope.fetched || metricsTelescope.fetching
  return (
    <div data-tracking-sub-page="Fees">
      <ContentLayout
        title={<TelescopeHeader title="Fees" />}
        noBackground
        padding="0"
        data-tracking-location="Summary"
      >
        <div className="large-margin-bottom small-margin-top">
          <SummaryCards data={summaryMetrics} loading={loading} />
        </div>
      </ContentLayout>
      <ContentLayout>
        {loading ? (
          <Loading />
        ) : (
          <div data-tracking-location="Analytics">
            <div className="small-margin-bottom">
              <FeesLineCharts datas={lineChartData} />
            </div>
            <PerCountryTable
              tableData={metricsTelescope.feesTableData}
              currentProject={currentProject}
              title="Fees by country"
              tooltipContent="Breakdown of fees paid per country"
            />
            <div className="large-margin-top row">
              {feesBarChartsData.map(data => (
                <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                  <BarChartTooltip value={data} />
                  <BarChartHorizontal
                    datas={chartFormattedData(data.value)}
                    axisLeft={150}
                    name={data.name}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </ContentLayout>
    </div>
  )
}
export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.fees,
  currentProject: store.currentProject,
}))(Fees)
