import Dispatcher from "../Dispatcher"
import * as ProcessOut from "../util/ProcessOut"
import persistedDataHandler from "^/vNext/persistedDataHandler"

export const FETCH_PROJECTS = "FETCH_PROJECTS"
export function loadProjects() {
  return {
    type: FETCH_PROJECTS,
    payload: ProcessOut.APIcallPromise("/projects", "GET", null),
  }
}
export const GENERATE_PRIVATE_KEY = "GENERATE_PRIVATE_KEY"
export function generatePrivateKey(id: string, resolve: (privateKey: string) => void) {
  return {
    type: GENERATE_PRIVATE_KEY,
    payload: ProcessOut.APIcallPromise(`/projects/${id}/private-key`, "POST").then(response => {
      if (response.data.success) resolve(response.data.project.private_key)
      return response
    }),
  }
}
export const FETCH_API_VERSIONS = "FETCH_API_VERSIONS"
export function loadApiVersions() {
  return {
    type: FETCH_API_VERSIONS,
    payload: ProcessOut.APIcallPromise("/", "GET", null, ProcessOut.generateHeaders("")),
  }
}
export const EDIT_PROJECT = "EDIT_PROJECT"
export function editProject(project: any, id: string, resolve: () => void) {
  const body = JSON.stringify(project)
  return {
    type: EDIT_PROJECT,
    payload: ProcessOut.APIcallPromise(`/projects/${id}`, "PUT", body).then(response => {
      if (response.data.success) resolve()
      return response
    }),
  }
}
export function deleteProject(id: string, resolve: () => void) {
  ProcessOut.APIcall(
    `/projects/${id}`,
    "DELETE",
    null,
    json => {
      if (json.success) {
        persistedDataHandler.deleteProjectId()
        resolve()
      }
    },
    ProcessOut.generateHeaders(id),
  )
}
export const CREATING_PROJECT = "CREATING_PROJECT"
export const CREATED_PROJECT = "CREATED_PROJECT"
export function createProject(data: any) {
  const { region } = data
  const project = JSON.stringify(data)
  Dispatcher.dispatch({
    type: CREATING_PROJECT,
  })
  const headers =
    region && region !== "null"
      ? { ...ProcessOut.generateHeaders(""), "X-ProcessOut-region": data.region }
      : ProcessOut.generateHeaders("")
  ProcessOut.APIcall(
    "/projects",
    "POST",
    project,
    json => {
      if (json.success) {
        Dispatcher.dispatch({
          type: CREATED_PROJECT,
          project: json.project,
        })
      }
    },
    headers,
  )
}
export const UPDATE_DUNNING_SETTINGS = "UPDATE_DUNNING_SETTINGS"
export function updateDunning(projectId: string, project: any) {
  return function (dispatch) {
    return {
      type: UPDATE_DUNNING_SETTINGS,
      payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, "PUT", project).then(
        response => {
          dispatch(fetchDunning(projectId))
          return response
        },
      ),
    }
  }
}
export const FETCH_DUNNING_SETTINGS = "FETCH_DUNNING_SETTINGS"
export function fetchDunning(projectId: string) {
  return {
    type: FETCH_DUNNING_SETTINGS,
    payload: ProcessOut.APIcallPromise(`/projects/${projectId}`, "GET"),
  }
}
export const FETCH_PROJECT_DETAILS = "FETCH_PROJECT_DETAILS"
export function fetchProjectDetails(id: string) {
  return function (dispatch) {
    dispatch({
      type: FETCH_PROJECT_DETAILS,
      payload: ProcessOut.APIcallPromise(`/projects/${id}?expand=api_version`, "GET"),
    })
  }
}
export const SET_PROJECT_ID = "SET_PROJECT_ID"
export function setProjectId(id: string) {
  return function (dispatch) {
    dispatch({
      type: SET_PROJECT_ID,
      payload: {
        projectId: id,
      },
    })

    if (id) {
      dispatch(fetchProjectDetails(id))
    }
  }
}
