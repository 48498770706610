import { AnimatedLineSeries, buildChartTheme, Tooltip, TooltipData, XYChart } from "@visx/xychart"
import { ThemeConfig } from "@visx/xychart/lib/theme/buildChartTheme"
import React, { useEffect, useRef } from "react"
import { FormattedDate } from "react-intl"
import TelescopeCardValue from "../components/TelescopeCardValue"
import { ValueStyle } from "../consts"
import { trackTarget } from "^/vNext/tracking"
import { ChartDatum, XAccessor, YAccessor } from "^/features/TelescopeV2/visualisations/util"

type Props = {
  datas: ChartDatum[]
  name: string
  valueStyle: ValueStyle
  showFullNumberTooltip?: boolean
}

function Sparkline({ valueStyle, datas, name, showFullNumberTooltip = false }: Props) {
  const ref = useRef<HTMLDivElement>()
  const xAccessor: XAccessor = d => d.x
  const yAccessor: YAccessor = d => d.y

  const customTheme = buildChartTheme({
    svgLabelBig: {
      fill: "#516395",
    },
  } as ThemeConfig)

  return (
    <div ref={ref} data-tag-name={name}>
      <XYChart
        height={64}
        margin={{
          left: 0,
          top: 5,
          bottom: 5,
          right: 0,
        }}
        xScale={{
          type: "band",
          paddingInner: 0.95,
        }}
        yScale={{
          type: "linear",
          zero: false,
        }}
        theme={customTheme}
        onPointerUp={() => trackTarget(ref.current, "Click", "Sparkline", { chartName: name })}
      >
        <AnimatedLineSeries
          stroke="#516395"
          dataKey="primary_line"
          data={datas}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
        />
        <Tooltip<ChartDatum>
          showDatumGlyph
          renderTooltip={({ tooltipData }) => (
            <SparklineTooltip
              tooltipData={tooltipData}
              valueStyle={valueStyle}
              showFullNumberTooltip={showFullNumberTooltip}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
              chartRef={ref}
              chartName={name}
            />
          )}
        />
      </XYChart>
    </div>
  )
}

type SparklineTooltipProps = {
  tooltipData: TooltipData<ChartDatum>
  valueStyle: ValueStyle
  showFullNumberTooltip: boolean
  xAccessor: XAccessor
  yAccessor: YAccessor
  chartRef: React.MutableRefObject<HTMLDivElement>
  chartName: string
}

function SparklineTooltip({
  tooltipData,
  valueStyle,
  showFullNumberTooltip,
  xAccessor,
  yAccessor,
  chartRef,
  chartName,
}: SparklineTooltipProps) {
  // depend upon the datum updating, as this limits the track event to each time a tooltip with new data is rendered
  useEffect(() => {
    chartRef.current && trackTarget(chartRef.current, "Hover", "Sparkline", { chartName })
  }, [chartRef, chartName, tooltipData.nearestDatum.datum])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontWeight: "normal",
        color: "#100C3A",
      }}
    >
      <div
        style={{
          fontSize: "24px",
          marginBottom: "5px",
        }}
      >
        {valueStyle === "percent" && showFullNumberTooltip ? (
          yAccessor(tooltipData.nearestDatum.datum) * 100 + "%"
        ) : (
          <TelescopeCardValue
            value={yAccessor(tooltipData.nearestDatum.datum)}
            style={valueStyle}
          />
        )}
      </div>
      <div
        style={{
          fontSize: "16px",
        }}
      >
        <FormattedDate
          value={xAccessor(tooltipData.nearestDatum.datum)}
          day="numeric"
          month="long"
          year="numeric"
        />
      </div>
    </div>
  )
}

export default Sparkline
