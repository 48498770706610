import React from "react"
import { FormattedDate, FormattedNumber } from "react-intl"
import type { $Discount } from "^/stores/Reducers/DiscountsReducer.js"
type Props = {
  discount: $Discount
  currency: string
}
export default class Discount extends React.Component<Props> {
  render() {
    const { discount } = this.props
    return (
      <div className="row">
        <div className="large-4 columns">
          <div className="big-font">
            <span className="greyed">{this.props.currency}</span>{" "}
            <FormattedNumber
              value={discount.amount}
              style="currency"
              currency={this.props.currency}
            />
          </div>
        </div>
        <div className="large-4 columns">
          <span className="greyed">{discount.coupon.id}</span>
        </div>
        <div className="large-4 columns text-right">
          <span className="greyed">
            <FormattedDate
              value={discount.created_at}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </span>
        </div>
      </div>
    )
  }
}
