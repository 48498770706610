import { BarLineChart } from "@processout/picasso"
import getSymbolFromCurrency from "currency-symbol-map"
import moment from "moment"
import React from "react"
import * as ReactDOMServer from "react-dom/server"
import { IntlProvider } from "react-intl"
import uniqid from "uniqid"
import type { $ChartType } from "../../Boards/consts"
import ChartLegend from "../ChartLegend"
import type { $ChartPoint } from "../Consts"
import { abbreviateNumber, generateKeysColors } from "../Utils"
import AmountTooltip from "./AmountTooltip"
import DefaultTooltip from "./DefaultTooltip"
import PercentTooltip from "./PercentTooltip"
type Props = {
  data: Array<{
    key: string
    datapoints: Array<$ChartPoint>
  }>
  currency: string | null | undefined
  type: $ChartType
  keysMask?: Array<string> | null | undefined
  // keys to be masked
  error?: boolean | null | undefined
}
type State = {
  id: string
  chart: any
  colors: Array<{
    color: string
    key: string
  }>
}
export class StackChart extends React.Component<Props, State> {
  _chartElement: any

  constructor() {
    super()
    this.state = {
      id: uniqid(),
      chart: null,
      colors: [],
    }
  }

  componentDidMount(): void {
    if (this.props.error) return
    this.prepareDraw(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.prepareDraw(nextProps)
  }

  componentWillUnmount() {
    const { chart } = this.state
    if (chart) chart.clear()
  }

  prepareDraw = (props: Props) => {
    const { data } = props
    const keys: Array<string> = []

    for (const entry of data) {
      for (let i = 0; i < entry.datapoints.length; i++) {
        if (!keys[entry.datapoints[i].key]) keys.push(entry.datapoints[i].key)
      }
    }

    const colors = generateKeysColors(keys)
    this.setState(
      {
        colors,
      },
      () => {
        this.draw()
      },
    )
  }
  draw = () => {
    const { data, currency, type, keysMask } = this.props
    const { colors } = this.state
    let chart

    if (!this.state.chart) {
      // $FlowFixMe
      chart = new BarLineChart(this._chartElement, {
        yAxisFormatter: function (d) {
          const { type } = this.props
          let suffix = ""
          if (type === "percentage") suffix = "%"
          else if (type === "amount") suffix = getSymbolFromCurrency(this.props.currency)
          return `${abbreviateNumber(
            this.props.type === "percentage" ? Math.round(d * 100 * 100) / 100 : d,
          )}${suffix}`
        }.bind(this),
      })
      this.setState({
        chart,
      })
    } else {
      chart = this.state.chart
    }

    chart.cleanupTip()
    chart.reset()
    // fill chart
    const bars = []
    let datesOnly = true

    // Check for dates oonly
    for (const point of data) {
      if (!moment(point.key, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()) {
        datesOnly = false
        break
      }
    }

    if (datesOnly) {
      // We only have dates so we update each key to a Date object
      for (const point of data) {
        point.key = new Date(point.key)
      }
    }

    for (const entry of data) {
      if (keysMask && keysMask.includes(entry.key)) continue
      const bar = {
        key: entry.key,
      }

      for (const set of entry.datapoints)
        bar[set.key] = type === "percentage" ? set._count : set.value

      bars.push({ ...bar, _datapoints: entry.datapoints.sort((a, b) => b.value - a.value) })
    }

    chart.addBar({
      data: bars,
      colors: colors.map(color => color.color),
      tip: d =>
        ReactDOMServer.renderToString(
          <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
            <div>
              <h5>
                {d.key instanceof Date
                  ? moment(d.key).calendar(null, {
                      sameDay: "[Today at] LT",
                      nextDay: "[Tomorrow]",
                      nextWeek: "dddd",
                      lastDay: "[Yesterday at] LT",
                      lastWeek: "MMMM Do YYYY, h:mm a",
                      sameElse: "MMMM Do YYYY, h:mm a",
                    })
                  : d.key}
              </h5>
              {type === "amount" ? (
                <AmountTooltip
                  barName={d.key}
                  datapoints={d._datapoints}
                  currency={currency || "USD"}
                  colors={colors}
                />
              ) : type === "percentage" ? (
                <PercentTooltip datapoints={d._datapoints} colors={colors} />
              ) : (
                <DefaultTooltip datapoints={d._datapoints} colors={colors} />
              )}
            </div>
          </IntlProvider>,
        ),
    })
    chart.resetSVG()
    chart.draw()
  }

  render() {
    const { id, colors } = this.state
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-12 columns">
              <svg
                className={`chart stack-chart `}
                id={`chart${id}`}
                ref={e => (this._chartElement = e)}
                width="100%"
                height="200px"
              />
            </div>
          </div>
          <ChartLegend
            colors={colors.map(color => ({
              name: color.key,
              value: color.color,
            }))}
          />
        </div>
      </div>
    )
  }
}
export default StackChart
