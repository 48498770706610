import cloneDeep from "lodash/cloneDeep"
import merge from "lodash/merge"

const DeepMerge = (firstObject: Record<string, any>, secondObject: Record<string, any>) => {
  const firstObjectClone = cloneDeep(firstObject)
  merge(firstObjectClone, secondObject)
  return firstObjectClone
}

export default DeepMerge
