import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $RoutingRule, $RoutingRulesSettings } from "./consts"
import { CONFIRM_TIMEOUT, ROUTING_RULES_MODES } from "./consts"
import type { $Dispatcher, $RouterLocation, $State } from "../../util/Types"
import * as Actions from "./actions"
import Loading from "../../components/Loader"
import Error from "../../components/Error"
import NotPermitted from "../Permissions/NotPermitted"
import Project from "../../util/Project"
import { requestConfirmation } from "../ConfirmModal/actions"
import { areRulesEqual } from "./Utils"
import RoutingRulesModeSwitcher from "./RoutingRulesModeSwitcher"
import Auth from "^/util/Auth"
import uniqid from "uniqid"

type Props = {
  routingRulesSettings: $RoutingRulesSettings & $State
  params: {
    project: string
  }
  location: $RouterLocation
} & $Dispatcher

class RulesIndexV2 extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(Actions.prepareRoutingRulesSettings())
    document.title = "Smart routing beta | ProcessOut"
  }

  askSave = () => {
    const { routingRulesSettings } = this.props
    const { rules, rulesRaw, initialRules } = routingRulesSettings
    const isEditorMode = location.pathname.includes(ROUTING_RULES_MODES.EDITOR)
    const newRules = []
    const updatedRules = []
    const removedRules = []

    for (const rule of rules) {
      const initialIndex = initialRules.findIndex(r => r.id === rule.id)
      if (initialIndex < 0) newRules.push(rule)
      else if (!areRulesEqual(rule, initialRules[initialIndex])) {
        updatedRules.push(rule)
      }
    }

    for (const rule of initialRules) {
      const index = rules.findIndex(r => r.id === rule.id)
      if (index < 0) removedRules.push(rule)
    }

    const hasDestructiveRule = this.getHasDestructiveRule(newRules)
    this.props.dispatch(
      requestConfirmation(
        <div className="row rule-confirmation-popup">
          <div className="large-11 columns large-centered">
            <div className="row margin-bottom">
              <div className="medium-12 columns">
                <div
                  style={{
                    fontSize: "1.1em",
                  }}
                >
                  {hasDestructiveRule && (
                    <div className="callout alert">
                      <div>A rule that targets all transactions is about to be applied.</div>
                      <div>Are you sure you want to do this?</div>
                    </div>
                  )}
                  Are you sure you want to apply those changes?
                </div>
              </div>
            </div>
            {newRules.length > 0 && (
              <div
                className="row"
                style={{
                  color: "green",
                }}
              >
                <div className="medium-12 columns">++ added</div>
              </div>
            )}

            <ul>
              {newRules.filter(rule => rule.tags.length > 0).length > 0 &&
                newRules
                  .filter(rule => rule.tags.length > 0)
                  .map(rule => (
                    <li key={rule.id || uniqid()}>
                      {rule.tags.slice(0, 2).map(tag => (
                        <div className="rule-tag" key={tag}>
                          {tag}
                        </div>
                      ))}{" "}
                      was created
                    </li>
                  ))}
              {newRules.filter(rule => rule.tags.length === 0).length > 0 && (
                <li>
                  {newRules.filter(rule => rule.tags.length === 0).length > 1
                    ? `${newRules.length} unnamed rules were created`
                    : "1 unnamed rule was created"}
                </li>
              )}
            </ul>
            {updatedRules.length > 0 && (
              <div className="row">
                <div
                  className="medium-12 columns"
                  style={{
                    color: "orange",
                  }}
                >
                  ~~ updated
                </div>
              </div>
            )}
            <ul>
              {updatedRules.map(rule => (
                <li key={rule.id || uniqid()}>
                  {rule.tags
                    ? rule.tags.slice(0, 2).map(tag => (
                        <div className="rule-tag" key={tag}>
                          {tag}
                        </div>
                      ))
                    : initialRules.findIndex(r => r.id === rule.id)}
                  has been updated
                </li>
              ))}
            </ul>
            {removedRules.length > 0 && (
              <div
                className="row"
                style={{
                  color: "red",
                }}
              >
                <div className="medium-12 columns">-- removed</div>
              </div>
            )}
            <ul>
              {removedRules.map(rule => (
                <li key={rule.id || uniqid()}>
                  {rule.tags
                    ? rule.tags.slice(0, 2).map(tag => (
                        <div className="rule-tag" key={tag}>
                          {tag}
                        </div>
                      ))
                    : initialRules.findIndex(r => r.id === rule.id)}
                  has been removed
                </li>
              ))}
            </ul>
          </div>
        </div>,
        () => {
          this.props.dispatch(
            isEditorMode ? Actions.saveRules(rulesRaw) : Actions.requestRulesSaving(),
          )
        },
        () => {},
        CONFIRM_TIMEOUT,
      ),
    )
  }
  getHasDestructiveRule = (rules: Array<$RoutingRule>) => {
    for (const rule of rules) {
      for (const condition of rule.conditions) {
        if (!condition.filters.length) {
          return true
        }
      }
    }
  }

  render() {
    const { routingRulesSettings, params, children } = this.props
    const project = new Project(params.project)
    const isEditorMode = location.pathname.includes(ROUTING_RULES_MODES.EDITOR)

    return (
      <div>
        <div className="row category-title collapse" style={{ marginBottom: 28 }}>
          <h4 className="inline-block">Smart routing</h4>
        </div>
        {!routingRulesSettings.fetched || routingRulesSettings.fetching ? (
          <Loading />
        ) : routingRulesSettings.error ? (
          routingRulesSettings.error.notPermitted ? (
            <NotPermitted />
          ) : (
            <Error />
          )
        ) : (
          children
        )}
      </div>
    )
  }
}

export default connect(store => ({
  routingRulesSettings: store.routingRulesSettings,
}))(withRouter(RulesIndexV2))
