import React, { useCallback, useMemo, useState } from "react"
import { FormattedNumber } from "react-intl"
import Table from "^/features/TelescopeV2/components/Table"
import type { $PerCountryTableData } from "^/features/TelescopeV2/consts"
import { sortAsc, sortDesc } from "^/features/TelescopeV2/util"
import type { $CurrentProject } from "^/util/Types"
import type { $Column, $SortDirection } from "./Table"
import PaginationV2 from "./PaginationV2"
import TelescopeTitle from "^/features/TelescopeV2/components/TelescopeTitle"

type Props = {
  tableData: Array<$PerCountryTableData>
  currentProject: $CurrentProject
  title: string
  tooltipContent: string
}

const PerCountryTableData = ({ currentProject, tableData, title, tooltipContent }: Props) => {
  const [sortedDatas, setSortedTableDatas] = useState([...tableData])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemsPerPage = 10

  const currency = currentProject?.project?.default_currency
  const columnsData: $Column<$PerCountryTableData>[] = [
    {
      id: "country_id",
      header: "COUNTRY",
      renderCell: (field: $PerCountryTableData) => field.country as unknown as JSX.Element,
    },
    {
      id: "rate_id",
      header: "RATE",
      renderCell: (field: $PerCountryTableData) => {
        return (
          <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            style="percent"
            value={field.rate ?? 0}
          />
        )
      },
      align: "right",
    },
    {
      id: "rate_evolution_id",
      header: "CHANGE(%)",
      renderCell: (field: $PerCountryTableData) => {
        return (
          <FormattedNumber
            style="percent"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={field.rateEvolution ?? 0}
          />
        )
      },
      align: "right",
    },
    {
      id: "number_transaction_id",
      header: "NO. OF TRANSACTIONS",
      renderCell: (field: $PerCountryTableData) => {
        return <FormattedNumber style="decimal" value={field.count ?? 0} />
      },
      align: "right",
    },
    {
      id: "amount_transaction_id",
      header: "TRANSACTION AMOUNT",
      renderCell: (field: $PerCountryTableData) => {
        return <FormattedNumber style="currency" currency={currency} value={field.amount ?? 0} />
      },
      align: "right",
    },
  ]
  const onSort = useCallback(
    (direction: $SortDirection, id: string) => {
      let sortedProducts: Array<$PerCountryTableData> = [...sortedDatas]

      switch (id) {
        case "country_id":
          sortedProducts.sort(direction === "asc" ? sortAsc("country") : sortDesc("country"))
          break

        case "rate_id":
          sortedProducts.sort(direction === "asc" ? sortAsc("rate") : sortDesc("rate"))
          break

        case "rate_evolution_id":
          sortedProducts.sort(
            direction === "asc" ? sortAsc("rateEvolution") : sortDesc("rateEvolution"),
          )
          break

        case "number_transaction_id":
          sortedProducts.sort(direction === "asc" ? sortAsc("count") : sortDesc("count"))
          break

        case "amount_transaction_id":
          sortedProducts.sort(direction === "asc" ? sortAsc("amount") : sortDesc("amount"))
          break
      }

      setSortedTableDatas(sortedProducts)
      setCurrentPage(1)
    },
    [sortedDatas, setSortedTableDatas],
  )
  const indexOfLastPost = currentPage * itemsPerPage
  const indexOfFirstPost = indexOfLastPost - itemsPerPage
  const dataCount = sortedDatas.length
  const totalPages = Math.ceil(dataCount / itemsPerPage)
  const pagedSortedData = useMemo(() => {
    return sortedDatas.slice(indexOfFirstPost, indexOfLastPost)
  }, [currentPage, sortedDatas])
  return (
    <>
      <Table
        title={<TelescopeTitle title={title} tooltip={{ content: tooltipContent, id: title }} />}
        columns={columnsData}
        datas={pagedSortedData}
        sort={onSort}
        name={title}
      />
      {pagedSortedData.length > 0 && (
        <PaginationV2
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      )}
    </>
  )
}

export default PerCountryTableData
