import { call, put, takeEvery } from "redux-saga/effects"
import { replace } from "react-router-redux"
import { REQUEST_PROJECT_CREATION } from "./consts"
import * as ProcessOut from "../../util/ProcessOut"
import { refreshToken } from "../NetworkManager/sagas"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typePending } from "^/util/ActionUtils"
import persistedDataHandler from "^/vNext/persistedDataHandler"

export type $ProjectCreationPayload = {
  name: string
  default_currency: string
  region?: string
}

function* createProject(action: { type: string; payload: $ProjectCreationPayload }) {
  try {
    yield put({
      type: typePending(REQUEST_PROJECT_CREATION),
    })
    const { name, default_currency, region } = action.payload
    const headers = region
      ? { ...ProcessOut.generateHeaders(""), "X-ProcessOut-region": region }
      : ProcessOut.generateHeaders("")
    const httpResult = yield ProcessOut.APIcallPromise(
      "/projects",
      "POST",
      JSON.stringify({
        name,
        default_currency,
        region,
      }),
      headers,
    )
    // refresh token
    const rToken = persistedDataHandler.getAuthTokens().refreshToken

    if (rToken) {
      yield call(refreshToken, rToken)
    }

    yield put(replace(`/projects?target=${httpResult.data.project.id}`))
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_PROJECT_CREATION),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeEvery(REQUEST_PROJECT_CREATION, createProject)
}
