import React, { useEffect } from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import ContentLayout from "../../../../pages/ContentLayout"
import Delete from "../../../Delete"
import Error from "../../../Error"
import Loader from "../../../Loader"
import * as AlternativeCertificate from "./actions"
import type { $ApplepayCertificate, $ApplepaySettings } from "./consts"
type Props = {
  currentProject: {
    project: {
      applepay_settings: $ApplepaySettings
      id: string
    }
  }
  applepayAlternativeCertificate: $ApplepayCertificate
  dispatch: $Dispatcher
}

function AlternativeCertificates({
  applepayAlternativeCertificate,
  currentProject,
  dispatch,
}: Props) {
  useEffect(() => {
    if (currentProject.project.applepay_settings) {
      dispatch(AlternativeCertificate.fetchAlternativeCertificate())
    }
  }, [])

  const shouldUploadCertificates = (event: Event) => {
    event.preventDefault()
    const location = {
      pathname: `/projects/${currentProject.project.id}/settings/apple-pay/step-2`,
      state: {
        alternative_certificate: true,
      },
    }
    dispatch(push(location))
  }

  const confirmDelete = (id: string, event: Event) => {
    event.preventDefault()
    dispatch(
      AlternativeCertificate.removeAlternativeCertificate(id, () => {
        dispatch({
          type: CLOSE_MODAL,
        })
        dispatch(AlternativeCertificate.fetchAlternativeCertificate())
      }),
    )
  }

  const deleteCertificate = (id: string) => {
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header: <div>Delete confirmation</div>,
        content: (
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div
              className="row small-margin-bottom"
              style={{
                paddingTop: "1.5em",
              }}
            >
              <div className="large-12 columns text-center">
                <p>Are you sure you want to delete this certificate?</p>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">ID:</div>
              <div className="large-10  columns greyed text-right">
                <span className="console-font small-font">{id}</span>
              </div>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="large-12 columns text-right">
              <button
                type="button"
                onClick={event => {
                  event.preventDefault()
                  dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
                className="round border small button"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={ev => confirmDelete(id, ev)}
                className="round small main button white-text"
              >
                Delete
              </button>
            </div>
          </div>
        ),
      },
    })
  }

  const separator = (
    <div className="row">
      <div className="large-12 columns">
        <hr />
      </div>
    </div>
  )
  let content

  if (applepayAlternativeCertificate.error) {
    content = <Error />
  } else if (
    currentProject.project.applepay_settings &&
    applepayAlternativeCertificate.ApplepayCertificate
  ) {
    if (
      applepayAlternativeCertificate.ApplepayCertificate.count === 0 &&
      !applepayAlternativeCertificate.fetching
    ) {
      content = (
        <div className="greyed">
          You haven&apos;t defined any alternative certificates yet.{" "}
          <a onClick={shouldUploadCertificates}>&#43; adding an alternative certificate</a>
        </div>
      )
    } else if (
      applepayAlternativeCertificate.fetched &&
      applepayAlternativeCertificate.ApplepayCertificate.count > 0
    ) {
      const arrayOfAlternativeCertificate =
        applepayAlternativeCertificate.ApplepayCertificate.alternative_merchant_certificates
      content = (
        <div>
          {arrayOfAlternativeCertificate.map((certificate, index) => (
            <>
              <div className="row alternative-card" key={certificate.id}>
                <div className="medium-6 columns">
                  <label>Certificate ID</label>
                  <h4>{certificate.id}</h4>
                </div>
                <div className="large-3 columns text-right huge-font">
                  <Delete
                    verticalMiddle
                    onDelete={() => {
                      deleteCertificate(certificate.id)
                    }}
                  />
                </div>
              </div>
              {index !== arrayOfAlternativeCertificate.length - 1 && separator}
            </>
          ))}
        </div>
      )
    } else {
      content = <Loader />
    }
  }

  return (
    <ContentLayout
      title={
        <div className="row">
          <div className="large-6 columns">
            <h4>Alternative certificates</h4>
          </div>
          <div
            className="large-6 columns text-right"
            style={{
              paddingRight: "0",
            }}
          >
            <button
              style={{
                marginLeft: "1em",
              }}
              className="button small round border"
              onClick={shouldUploadCertificates}
            >
              Upload alternative certificate
            </button>
          </div>
        </div>
      }
    >
      {content}
    </ContentLayout>
  )
}

export default connect(store => ({
  currentProject: store.currentProject,
  applepayAlternativeCertificate: store.applePayAlternativeCertificate,
}))(AlternativeCertificates)
