import { put, takeLatest } from "redux-saga/effects"
import type {
  $RequestPayoutDetailsFetchAction,
  $RequestPayoutItemsFetch,
  $RequestPayoutsFetchAction,
} from "./actions"
import {
  REQUEST_PAYOUT_DETAILS_FETCH,
  REQUEST_PAYOUT_ITEMS_FETCH,
  REQUEST_PAYOUTS_FETCH,
} from "./actions"
import * as GatewayActions from "../../Actions/GatewaysActions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"
export function* fetchPayouts(action: $RequestPayoutsFetchAction): Generator<any, any, any> {
  try {
    const { length, after, id } = action.payload
    const bound = after ? "start_after" : "end_before"
    const payoutsResults = yield APIcallPromise(
      `/payouts?${bound}=${id || ""}&limit=${length}&order=desc&expand[]=gateway_configuration`,
      "GET",
    )
    const payoutsDetails = payoutsResults.data
    yield put.resolve(GatewayActions.loadGatewaysConfigurations())
    yield put({
      type: typeFulfilled(REQUEST_PAYOUTS_FETCH),
      payload: payoutsDetails,
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_PAYOUTS_FETCH),
      payload: error,
    })
    datadogRum.addError(error)
  }
}
export function* fetchPayoutDetails(
  action: $RequestPayoutDetailsFetchAction,
): Generator<any, any, any> {
  try {
    const { id } = action.payload
    const payoutDetailsResult = yield APIcallPromise(
      `/payouts/${id}?expand[]=gateway_configuration`,
      "GET",
    )
    yield put({
      type: typeFulfilled(REQUEST_PAYOUT_DETAILS_FETCH),
      payload: payoutDetailsResult.data,
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_PAYOUT_DETAILS_FETCH),
      payload: error,
    })
    datadogRum.addError(error)
  }
}
export function* fetchPayoutItems(action: $RequestPayoutItemsFetch): Generator<any, any, any> {
  try {
    const { id, after, length, afterId } = action.payload
    const bound = after ? "start_after" : "end_before"
    const itemsResult = yield APIcallPromise(
      `/payouts/${id}/items?${bound}=${afterId || ""}&limit=${length}&order=desc`,
      "GET",
    )
    yield put({
      type: typeFulfilled(REQUEST_PAYOUT_ITEMS_FETCH),
      payload: itemsResult.data,
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_PAYOUT_ITEMS_FETCH),
      payload: error,
    })
    datadogRum.addError(error)
  }
}
export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_PAYOUTS_FETCH, fetchPayouts)
  yield takeLatest(REQUEST_PAYOUT_DETAILS_FETCH, fetchPayoutDetails)
  yield takeLatest(REQUEST_PAYOUT_ITEMS_FETCH, fetchPayoutItems)
}
