import React from "react"
import Select from "react-select"
import type { $ProjectTypeOption } from "./consts"
import { PROJECT_TYPE_OPTIONS } from "./consts"
type Props = {
  value: $ProjectTypeOption | null | undefined
  onChange: (arg0: $ProjectTypeOption | null | undefined) => void
}
export class ProjectTypeSelector extends React.Component<Props> {
  render() {
    const value = PROJECT_TYPE_OPTIONS.find(option => option.value === this.props.value)
    return (
      <div className="row">
        <div className="large-12 columns text-left">
          <Select
            className="react-select"
            value={value || PROJECT_TYPE_OPTIONS[0]}
            isClearable={false}
            options={PROJECT_TYPE_OPTIONS}
            onChange={value => {
              this.props.onChange(value)
            }}
            placeholder="Select your project’s type"
          />
        </div>
      </div>
    )
  }
}
export default ProjectTypeSelector
