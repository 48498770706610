import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import type { $State } from "../../util/Types"
import type { $Payout } from "./reducer"
import * as Actions from "./actions"
type Props = {
  payout: $Payout
  processorsConfigurations: {
    configurations: Array<{
      id: string
      gateway: {
        logo_url: string
      }
      name: string
    }>
  } & $State
  params: {
    project: string
  }
}

class Payout extends React.PureComponent<Props> {
  render() {
    const { payout, processorsConfigurations, params } = this.props
    const config = processorsConfigurations.configurations.find(
      config => payout.gateway_configuration_id === config.id,
    )
    return (
      <Link to={`/projects/${params.project}/reports/payouts/${payout.id}`}>
        <div className="row payout-row">
          <div className="medium-1 columns">
            <span
              className={`log-level ${
                payout.status === "received"
                  ? "success"
                  : payout.status === "cancelled" || payout.status === "failed"
                  ? "failed"
                  : "warning"
              }`}
            >
              {payout.status}
            </span>
          </div>
          <div className="medium-2 columns">
            {Actions.formatCurrency(payout.amount, payout.currency)}
          </div>
          <div className="medium-2 columns">
            {Actions.formatCurrency(payout.fees, payout.currency)}
          </div>
          <div className="medium-4 columns">
            {config ? (
              <div className="row">
                <div className="medium-4 columns">
                  <img alt={`${config.name}'s logo`} src={config.gateway.logo_url} />
                </div>
                <div className="medium-8 columns">{config.name}</div>
              </div>
            ) : (
              <span className="greyed">—</span>
            )}
          </div>
          <div className="medium-3 columns text-right greyed">
            {moment(payout.created_at).calendar()}
          </div>
        </div>
      </Link>
    )
  }
}

export default connect(store => ({
  processorsConfigurations: store.processorsConfigurations,
}))(withRouter(Payout))
