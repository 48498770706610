import React from "react"
import MetadataDisplay from "../MetadataDisplay"
import type { $Plan } from "^/stores/Reducers/PlansReducer"
type Props = {
  plan: $Plan
}
export default class PlanDetails extends React.Component<Props> {
  render() {
    const { plan } = this.props
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Identifier</div>
            <div className="large-9 columns font-console text-right">{plan.id}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Name</div>
            <div className="large-9 columns text-right">{plan.name}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="row small-margin-bottom">
              <div className="large-12 medium-9 columns">
                {plan.metadata.length > 0 ? <MetadataDisplay metadata={plan.metadata} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
