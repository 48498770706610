import React from "react"
import Select from "react-select"
import { track } from "^/vNext/tracking"
import type { $BuilderMetric } from "./consts"
import { SelectStyle } from "./consts"
export type $NetMetricType = "raw" | "net_router" | "net"
type Props = {
  netMetricType: $NetMetricType
  onChange: (arg0: $NetMetricType) => void
  selectedMetric: $BuilderMetric
}
type $Option = {
  label: string
  value: $NetMetricType
}
const OPTIONS: Array<$Option> = [
  {
    label: "All transaction requests",
    value: "raw",
  },
  {
    label: "Regroup ProcessOut retries",
    value: "net_router",
  },
  {
    label: "Regroup all retries",
    value: "net",
  },
]
export class NetDropdown extends React.Component<Props> {
  UNSAFE_componentWillReceiveProps(nextProps: Props, nextContext: any): any {
    if (
      !nextProps.selectedMetric.allowedResultGrouping.includes(nextProps.netMetricType) &&
      nextProps.selectedMetric.name !== this.props.selectedMetric.name
    )
      this.props.onChange(
        nextProps.selectedMetric.allowedResultGrouping[
          nextProps.selectedMetric.allowedResultGrouping.length - 1
        ],
      )
  }

  render() {
    const { netMetricType, selectedMetric } = this.props
    const value = OPTIONS.find(o => o.value === netMetricType)
    const options = OPTIONS.filter(o => selectedMetric.allowedResultGrouping.includes(o.value))
    return (
      <div className="row small-margin-bottom">
        <div className="medium-12 columns">
          <div className="field-label round-left">Display results</div>
          <div className="labeled-field text-left">
            <Select
              className="react-select"
              options={options}
              styles={SelectStyle}
              value={value}
              onChange={newValue => {
                track("Analytics", "Select", "Data explorer", "Display result", value)
                this.props.onChange(newValue.value)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
export default NetDropdown
