import React from "react"
type Props = {
  onDelete: () => void
  verticalMiddle?: boolean
}
export class Delete extends React.Component<Props> {
  render() {
    const { onDelete, verticalMiddle } = this.props
    return (
      <button onClick={onDelete}>
        <i
          className={`icon icon-trash cursor-pointer ${
            verticalMiddle ? "vertical-middle" : ""
          } greyed`}
        />
      </button>
    )
  }
}
export default Delete
