export const colors = {
  neutral: {
    orange: {
      20: "#FBF2EE",
      30: "#fbd6c6",
      40: "#fbc0a7",
      120: "#8A3913",
    },
    grey: {
      10: "#fcfcfc",
      20: "#fafafa",
      40: "#ececec",
      50: "#dbdadd",
      60: "#c4c4c8",
      110: "#2c2d31",
      120: "#121212",
    },
    teal: {
      20: "#e8f7f7",
      30: "#bfe8e7",
      40: "#9fd6d7",
      60: "#64b4b5",
      120: "#154747",
    },
    blue: {
      20: "#f0f3f9",
      40: "#b6c5e5",
    },
    purple: {
      20: "#f1f0fa",
      30: "#cbc8e2",
      40: "#b7b4d7",
      120: "#222254",
    },
  },
}
