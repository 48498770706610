/**
 * Created by jeremylejoux on 08/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router"
import ContentLayout from "../../../pages/ContentLayout"
import Empty from "../../Empty"
import APDomainName from "./APDomainName"
export class ApplePayDomainName extends React.Component {
  props: Props
  static defaultProps = {
    dispatch: null,
    currentProject: null,
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    let { currentProject } = this.props
    if (!currentProject.project || !currentProject.project.applepay_settings) return null
    currentProject = currentProject.project
    const applePaySettings = currentProject.applepay_settings
    if (!applePaySettings) return null
    let content

    if (applePaySettings.merchant_domain_names.length === 0) {
      content = <Empty text="No domain names have been added so far." />
    } else {
      content = (
        <div className="row">
          <div className="large-12 columns">
            {applePaySettings.merchant_domain_names.map(domain => (
              <APDomainName key={domain} domainName={domain} />
            ))}
          </div>
        </div>
      )
    }

    return (
      <ContentLayout title={<h4>Domain names</h4>}>
        <div className="row margin-bottom">
          <div className="medium-10 columns">
            <p className="greyed">Domain names associated to your merchant id.</p>
          </div>
          <div className="medium-2 columns text-right">
            <Link
              to={`/projects/${this.props.currentProject.project.id}/settings/apple-pay/domains`}
              class="button round border small"
            >
              Add domain
            </Link>
          </div>
        </div>
        {content}
      </ContentLayout>
    )
  }
}
type Props = {
  currentProject:
    | any
    | {
        fetched: boolean
        fetching: boolean
        project: {
          id: string
          applepay_settings: any
        }
      }
  dispatch: any | (() => void)
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(ApplePayDomainName)
