import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import { withRouter } from "react-router"
import type { $DataExplorerState } from "./reducer"
import type { $Dispatcher, $Project } from "../../../util/Types"
import type { $DataExplorerTab } from "./consts"
import * as ConfirmActions from "../../ConfirmModal/actions"
import * as ProcessOut from "../../../util/ProcessOut"
type Props = {
  dataExplorer: $DataExplorerState
  currentProject: {
    project: $Project
  }
  isChartBuilder: boolean | null | undefined
} & $Dispatcher
export class Tabs extends React.Component {
  props: Props
  onTabClick = (tab: $DataExplorerTab) => {
    const { dispatch, location, dataExplorer } = this.props

    const redirect = () => {
      dispatch(
        push(
          `/projects/${ProcessOut.config.ProjectId}/boards/${
            this.props.isChartBuilder
              ? `${this.props.dataExplorer.boardId}/new-chart/${tab}${
                  location.query.chart ? `?chart=${location.query.chart}` : ""
                }`
              : `data-explorer/${tab}`
          }`,
        ),
      )
    }

    if (
      dataExplorer.selectedTab === "editor" &&
      tab !== "editor" &&
      dataExplorer.editorHasBeenEdited
    ) {
      dispatch(
        ConfirmActions.requestConfirmation(
          <div className="row">
            <div className="large-11 columns large-centered">
              Leaving the editor will reset its content. Are your sure you want to proceed?
            </div>
          </div>,
          () => {
            redirect()
          },
          () => {},
        ),
      )
    } else {
      redirect()
    }
  }

  render() {
    const { dataExplorer } = this.props
    return (
      <div className="data-explorer-tabs" data-tracking-location="Data explorer tabs">
        <div className="row">
          <div className="large-12 columns">
            <a
              data-auto-tracking={true}
              className={`tab-button ${
                dataExplorer.selectedTab === "chart-builder" ? " active" : ""
              }`}
              onClick={event => {
                event.preventDefault()
                this.onTabClick("chart-builder")
              }}
            >
              Chart Builder
            </a>
            <a
              data-auto-tracking={true}
              className={`tab-button last${dataExplorer.selectedTab === "editor" ? " active" : ""}`}
              onClick={event => {
                event.preventDefault()
                this.onTabClick("editor")
              }}
            >
              Code Editor
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  dataExplorer: store.dataExplorer,
  currentProject: store.currentProject,
}))(withRouter(Tabs))
