import React from "react"

class DocumentationItem extends React.Component<{}> {
  render() {
    return (
      <div className="row collapse search-documentation">
        <div className="medium-12 columns">
          <div className="row small-margin-bottom collapse">
            <div className="medium-12 columns">
              <div
                className="greyed"
                style={{
                  marginBottom: ".5em",
                  fontSize: "1.2em",
                }}
              >
                <div className="question inline-block">?</div>Search tips
              </div>
              You can search for Transactions, Customers, Cards or Projects through this search bar.
              Supported queries include:
            </div>
          </div>
          <div className="row collapse">
            <div className="medium-12 columns">
              <ul>
                <li>
                  <code>iv_</code>: invoice ID
                </li>
                <li>
                  <code>tr_</code>: transaction ID
                </li>
                <li>
                  <code>cust_</code>: customer ID
                </li>
                <li>
                  <code>john@smith.com</code>: customer email
                </li>
                <li>
                  <code>order-1042</code>: invoice name
                </li>
              </ul>
              <em>Note: Press tab to trigger the advanced query mode.</em>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DocumentationItem
