import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import SearchBuilder from "../SearchBuilder/SearchBuilder"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
import * as Utils from "../SearchBuilder/Utils"
import type { $SearchBarState } from "./reducer"
type Props = {
  defaultFilter?: string | null | undefined
  onChange: (arg0: string) => void
  // The state is kept in this component but the output (string filter) is dispatched to the parent
  document: string
  searchDetails: $SearchBarState
}
type State = {
  filters: Array<$Filter>
  appliedFilters: Array<$Filter>
}
export class SearchSwitcher extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filters: [],
      appliedFilters: [],
    }
  }

  saveFilters = () => {
    this.setState({
      appliedFilters: this.state.filters,
    })
    this.props.onChange(Utils.computeFilterStringFromArray(this.state.filters))
  }
  clearFilters = () => {
    this.setState(
      {
        filters: [],
      },
      () => {
        this.saveFilters()
      },
    )
  }

  render() {
    return (
      <SearchBuilder
        value={this.state.filters}
        document={this.props.document}
        onChange={filters => {
          this.setState({
            filters,
          })
        }}
        onSave={this.saveFilters}
        onClear={this.clearFilters}
        modified={!Utils.areFiltersArraysEquals(this.state.appliedFilters, this.state.filters)}
      />
    )
  }
}
export default connect(store => ({
  searchDetails: store.searchBar,
}))(withRouter(SearchSwitcher))
