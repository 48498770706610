import React from "react"
import { connect } from "react-redux"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import type { $Project } from "../../../util/Types"
import Partial from "./Partial"
import PanelNumber from "../Components/PanelNumber"
type Props = {
  telescope: $TelescopeState
  currentProject: {
    project: $Project
  }
}

class Refunds extends React.Component<Props> {
  render() {
    const { data } = this.props.telescope.standard
    const currency = this.props.currentProject.project.default_currency || "USD"
    return (
      <Partial
        leftPanel={
          <div className="row">
            <div className="large-6 columns">
              <PanelNumber
                number={data.refunded_amount}
                name="Refunded"
                style="currency"
                currency={currency}
              />
            </div>
            <div className="large-6 columns text-right">
              <PanelNumber number={data.transactions_refunded} name="Refunded transactions" />
            </div>
          </div>
        }
        rightPanel={
          <div className="row">
            <div className="large-6 columns">
              <PanelNumber
                number={data.voided_amount}
                name="Voided"
                style="currency"
                currency={currency}
              />
            </div>
            <div className="large-6 columns text-right">
              <PanelNumber number={data.transactions_voided} name="Voided transactions" />
            </div>
          </div>
        }
      />
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  currentProject: store.currentProject,
}))(Refunds)
