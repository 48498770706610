import type { $FetchDeclineExplainationAction } from "./sagas"
import { REQUEST_DECLINE_EXPLANATION_FETCH } from "./sagas"
import type { $Action } from "../../util/Types"
export function requestDeclineExplainationFetch(
  transaction: string,
): $FetchDeclineExplainationAction {
  return {
    type: REQUEST_DECLINE_EXPLANATION_FETCH,
    payload: {
      transaction,
    },
  }
}
export const RESET_DECLINE_EXPLAINER = "RESET_DECLINE_EXPLAINER"
export function resetDeclineExplainer(): $Action {
  return {
    type: RESET_DECLINE_EXPLAINER,
  }
}
