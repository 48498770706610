import React from "react"
import { connect } from "react-redux"
import ChargebackGatewayConfigSelect from "../Components/ChargebackGatewayConfigSelect"
import type { $Dispatcher } from "../../../util/Types"
import type { $GatewayConfiguration } from "../../PaymentProviders/consts"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer"
import * as Actions from "../actions"
type Props = {
  telescope: $TelescopeState
  gatewayConfigurations: {
    configurations: Array<$GatewayConfiguration>
  }
} & $Dispatcher

class ChargebackHeader extends React.Component<Props> {
  render() {
    // Retrieve component props
    const { telescope, gatewayConfigurations } = this.props
    // Retrieve which config belongs to a program
    const configurationsData = telescope.standard.data.gatewayConfigurationsData
    // Retrieve configurations details list
    const { configurations } = gatewayConfigurations
    // Create two groups of configurations: 1 for configs which belong to a program and 1 for other configs
    const programGroup = configurationsData.filter(config => config.belongsToProgram)
    const nonProgramGroup = configurationsData.filter(config => !config.belongsToProgram)

    // Helper to generate dropdown options for a specific group
    const generateGroupOptions = (group: Array<$GatewayConfiguration>) =>
      group.map(config => {
        const correspondingConfigIndex = configurations.findIndex(c => c.id === config.id)
        if (correspondingConfigIndex < 0)
          return {
            label: config.id,
            value: config.id,
          }
        return {
          label: configurations[correspondingConfigIndex].name,
          value: config.id,
        }
      })

    // Generate all dropdown options
    const options = [
      {
        label: "Belongs to program",
        options: generateGroupOptions(programGroup),
      },
      {
        label: "Safe from chargebacks",
        options: generateGroupOptions(nonProgramGroup),
      },
    ]
    const selectedGatewayConfig =
      this.props.telescope.standard.data.chargebacksData.selectedGatewayConfiguration
    return (
      <div className="row chargeback-header collapse">
        <div
          className="medium-6 columns"
          style={{
            borderRight: "1px solid #f2f2f2",
            padding: "1em",
          }}
        >
          <div className="row">
            <div
              className="medium-4 columns"
              style={{
                paddingTop: ".5em",
              }}
            >
              Viewing data for:
            </div>
            <div className="medium-8 columns text-right">
              <ChargebackGatewayConfigSelect
                options={options}
                onChange={newValue => {
                  if (!newValue) return
                  const { dispatch } = this.props
                  dispatch(Actions.selectChargebackGatewayConfiguration(newValue))
                  dispatch(
                    Actions.requestChargebackDataFetch(newValue.value, telescope.standard.timespan),
                  )
                }}
                value={
                  this.props.telescope.standard.data.chargebacksData.selectedGatewayConfiguration
                }
              />
            </div>
          </div>
        </div>
        <div
          className="medium-6 columns"
          style={{
            padding: "1em",
          }}
        >
          <div
            style={{
              position: "relative",
              top: ".5em",
            }}
          >
            {selectedGatewayConfig === null ? (
              <div>← Start by selecting a gateway configuration</div>
            ) : telescope.standard.data.chargebacksData.belongsToProgram ? (
              <div className="row">
                <div className="medium-12 columns">
                  <img
                    alt="Warning icon"
                    style={{
                      marginRight: "1em",
                      height: "30px",
                      display: "inline-block",
                    }}
                    src="//dashboard.processout.com/images/warning.png"
                  />
                  This configuration belongs to chargeback programs!
                </div>
              </div>
            ) : (
              <div>You do not belong to any monitoring program for this gateway configuration</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  gatewayConfigurations: store.processorsConfigurations,
}))(ChargebackHeader)
