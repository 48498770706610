import * as React from "react"
import { useState } from "react"
import { Tooltip } from "./Tooltip"
import ReactDOM from "react-dom"
import { CARD_TOOLTIP_MAX_WIDTH } from "./consts"
import { CARD_HEIGHT, CARD_WIDTH } from "../cards/consts"

/**
 * Manage display and hide of the card tooltips
 *
 * Note that the card tooltip timeout requires that all the children of Card component emit NO EVENT.
 * The reason is that otherwise mouseover and mouseout will be re-triggered when enter a child of the card element (e.g., filter)
 * and it will close and show the tooltip again. Therefore the pointer-event: none is set for all the children of the card component
 * to avoid this event re-trigger issue
 */
export const CardTooltip = () => {
  const [tooltip, setTooltip] = useState<{ x: number; y: number; text: string } | null>(null)
  const timeoutID = React.useRef<NodeJS.Timeout>()

  React.useEffect(() => {
    const hideTooltip = () => {
      setTooltip(null)
      clearTimeout(timeoutID.current)
    }

    const mouseover = e => {
      timeoutID.current = setTimeout(() => {
        const element = (e.target as HTMLElement).closest("[data-card-tooltip]")
        const text = element?.getAttribute("data-card-tooltip")
        if (text) {
          const elementPos = element.getBoundingClientRect()
          setTooltip({
            x: elementPos.x - (CARD_TOOLTIP_MAX_WIDTH - CARD_WIDTH) / 2 + window.scrollX,
            // 12 is the margin
            y: CARD_HEIGHT + 12 + elementPos.y + window.scrollY,
            text,
          })
        }
      }, 400)
    }

    const mouseout = e => {
      const element = (e.target as HTMLElement).closest("[data-card-tooltip]")
      const text = element?.getAttribute("data-card-tooltip")
      if (text) {
        hideTooltip()
      }
    }

    const scrollPane = document.querySelector(".scroll-pane")
    scrollPane.addEventListener("scroll", hideTooltip)
    window.addEventListener("scroll", hideTooltip)
    document.body.addEventListener("mouseover", mouseover)
    document.body.addEventListener("mouseout", mouseout)

    return () => {
      document.body.removeEventListener("mouseover", mouseover)
      document.body.removeEventListener("mouseout", mouseout)
      scrollPane.removeEventListener("scroll", hideTooltip)
      window.removeEventListener("scroll", hideTooltip)
    }
  }, [])

  if (!tooltip) return null

  return ReactDOM.createPortal(
    <Tooltip position={{ x: tooltip.x, y: tooltip.y }}>{tooltip.text}</Tooltip>,
    document.body,
  )
}
