import { FETCH_SUBSCRIPTIONS } from "^/Actions/SubscriptionsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_SUBSCRIPTIONS): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_SUBSCRIPTIONS): {
      return { ...state, ...action.payload, fetching: false, fetched: true }
    }

    case typeFailed(FETCH_SUBSCRIPTIONS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
