import React, { useContext } from "react"
import type { $VelocityRule } from "^/features/RoutingRules/consts"
import type { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Creatable, Option, Select } from "../../Select/Select"
import { FraudServiceContext } from "../FraudServiceContext"
export type Props = {
  options: Array<{ label: string; value: any }>
  selectedFraudServiceId: string
  values: any
  onFraudServiceChange: (change: Option) => void
  onValueChange: (changes: Option[]) => void
}

export const FraudServiceSelection = ({
  options,
  selectedFraudServiceId,
  values,
  onFraudServiceChange,
  onValueChange,
}: Props) => {
  return (
    <>
      {/* fraud service selections */}
      <Select
        options={options}
        value={options.find(option => selectedFraudServiceId === option.value)}
        onChange={onFraudServiceChange}
      ></Select>
      <Creatable
        options={[]}
        isMulti
        value={values.map(value => ({
          label: value,
          value,
        }))}
        onChange={onValueChange}
      ></Creatable>
    </>
  )
}

type ContextProps = {
  condition: $Filter | $VelocityRule
  onFraudServiceChange: (change: Option) => void
  onValueChange: (changes: Option[]) => void
}

export const FraudServiceSelectionContext = ({
  condition,
  onFraudServiceChange,
  onValueChange,
}: ContextProps) => {
  const fraudServices = useContext(FraudServiceContext)

  const regex = /action\("(.+)"\)/g
  const results = regex.exec(condition.path)

  let selectedFraudServiceId = ""
  if (results) {
    selectedFraudServiceId = results[1]
  }

  return (
    <FraudServiceSelection
      selectedFraudServiceId={selectedFraudServiceId}
      values={condition.value}
      options={fraudServices.map(service => ({
        label: service.name || service.fraud_service_name,
        value: service.id,
      }))}
      onFraudServiceChange={onFraudServiceChange}
      onValueChange={onValueChange}
    ></FraudServiceSelection>
  )
}
