import type { $RequestConfirmationAction } from "./consts"
import { REQUEST_CONFIRMATION } from "./consts"
export function requestConfirmation(
  content: any,
  resolve: (arg0: any) => any,
  reject: (arg0: any) => any,
  confirmTimeout?: number,
): $RequestConfirmationAction {
  return {
    type: REQUEST_CONFIRMATION,
    payload: {
      content,
      resolve,
      reject,
      confirmTimeout,
    },
  }
}
