import type { Action } from "../../Types/Action"
import {
  PREPARE_TELESCOPE,
  PREPARE_TELESCOPE_SILENT,
  UPDATE_TELESCOPE_TAB,
  UPDATE_TELESCOPE_TIMESPAN,
} from "./consts"
import {
  REQUEST_CHARGEBACK_DATA_FETCH,
  RESET_TELESCOPE_STATE,
  SELECT_CHARGEBACK_GATEWAY_CONFIGURATION,
} from "./actions"
import type { $Option } from "./Components/ChargebackGatewayConfigSelect"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
type $ChartData =
  | Array<{
      key: string
      datapoints: Array<{
        key: string
        corresponding_filter: string
        value: number | null | undefined
      }>
    }>
  | null
  | undefined
export type $FeesRecommendation = {
  advice: string
  country_code: string
  title: string
  transactions_impacted: number
  volume_impacted: number
}
export type $ChargebackThreshold = {
  metrics: Array<"count" | "volume" | "rate">
  count: number
  rate: number
  volume: number
}
export type $ChargebackLimit = {
  name: string
  card_scheme: "visa" | "mastercard"
  metrics: Array<string>
  count: number
  rate: number
  volume: number
  fines: {
    Info: string
    Timeline: any
    Details: string
  }
}
export type $ChargebackProgram = {
  current_name: string
  limit_name: string
  fines: Array<string>
  belong_since: number
}
export type $ChargebackProgramData = {
  name: string
  card_scheme: "visa" | "mastercard"
  metrics: Array<string>
  count: number
  rate: number
  volume: number
  fines: Array<string>
  belong_since: number
}
export type $ChargebackData = {
  ready: boolean
  selectedGatewayConfiguration: $Option | null | undefined
  belongsToProgram: boolean
  chargebackThresholds: Record<string, $ChargebackThreshold>
  globalChargebackCount: number
  globalChargebackRate: number
  globalChargebackVolume: number
  globalNotificationsOfFraud: number
  globalRequestsForInformation: number
  mastercardChargebackCount: number
  mastercardChargebackRate: number
  mastercardChargebackVolume: number
  mastercardNotificationsOfFraud: number
  mastercardRequestsForInformation: number
  visaChargebackCount: number
  visaChargebackRate: number
  visaChargebackVolume: number
  visaNotificationsOfFraud: number
  visaRequestsForInformation: number
  chargebackPrograms: {
    visa: $ChargebackProgramData | null | undefined
    mastercard: $ChargebackProgramData | null | undefined
  }
  defaultChargebackPrograms: {
    visa: string
    mastercard: string
  }
}
export type $TelescopeStateType = {
  error: boolean
  timespan: string
  authorizationReady: boolean
  capturesReady: boolean
  refundsReady: boolean
  chargebacksReady: boolean
  selectedTab: "auth" | "capt" | "refu" | "char"
  data: {
    // Authorization
    global_authorization_rate: number | null | undefined
    authPerCountry: Array<{
      key: string
      corresponding_filter: string
      authorization: number | null | undefined
    }>
    authorization_rate_per_psp: $ChartData
    authorization_rate_per_psp_bar: $ChartData
    authorization_rate_per_psp_per_card_scheme: $ChartData
    authorization_rate_per_psp_per_card_scheme_bar: $ChartData
    authorization_rate_per_psp_per_card_type: $ChartData
    authorization_rate_per_psp_per_card_type_bar: $ChartData
    transactions_authorized: number | null | undefined
    authorized_amount: number | null | undefined
    failed_transactions: number | null | undefined
    authorization_rate: number | null | undefined
    // Capture
    global_fees_percent: number | null | undefined
    captured_amount: number | null | undefined
    transactions_captured: number | null | undefined
    gateway_fees: number | null | undefined
    gateway_fees_percent_per_country: Array<{
      key: string
      corresponding_filter: string
      fees: number | null | undefined
    }>
    gateway_fees_per_psp: $ChartData
    gateway_fees_per_psp_bar: $ChartData
    gateway_fees_per_psp_per_card_scheme: $ChartData
    gateway_fees_per_psp_per_card_scheme_bar: $ChartData
    gateway_fees_per_psp_per_card_type: $ChartData
    gateway_fees_per_psp_per_card_type_bar: $ChartData
    // Refunds
    global_refund_percent: number | null | undefined
    refunded_amount: number | null | undefined
    transactions_refunded: number | null | undefined
    refunded_per_country: Array<{
      key: string
      corresponding_filter: string
      refunds_volume: number | null | undefined
    }>
    voided_amount: number | null | undefined
    transactions_voided: number | null | undefined
    refund_amount_per_psp_bar: $ChartData
    refund_amount_per_psp: $ChartData
    // Chargebacks
    gatewayConfigurationsData: Array<{
      id: string
      belongsToProgram: boolean
    }>
    chargebacksData: $ChargebackData
    global_chargeback_rate: number | null | undefined
    chargedback_amount: number | null | undefined
    chargedback_transactions: number | null | undefined
    telescope:
      | {
          authorizations_status: {
            volume_impacted: number | null | undefined
          }
          fees_status: {
            volume_impacted: number | null | undefined
          }
        }
      | null
      | undefined
    fees_recommendations: Array<$FeesRecommendation>
  }
}
const defaultState: $TelescopeStateType = {
  error: false,
  authorizationReady: false,
  capturesReady: false,
  refundsReady: false,
  chargebacksReady: false,
  timespan: "weekly",
  selectedTab: "auth",
  data: {
    global_authorization_rate: 0,
    authPerCountry: [],
    authorization_rate_per_psp: [],
    authorization_rate_per_psp_bar: [],
    authorization_rate_per_psp_per_card_scheme: [],
    authorization_rate_per_psp_per_card_scheme_bar: [],
    authorization_rate_per_psp_per_card_type_bar: [],
    authorization_rate_per_psp_per_card_type: [],
    transactions_authorized: 0,
    authorized_amount: 0,
    failed_transactions: 0,
    authorization_rate: 0,
    global_fees_percent: 0,
    captured_amount: 0,
    transactions_captured: 0,
    gateway_fees: 0,
    gateway_fees_percent_per_country: [],
    gateway_fees_per_psp: [],
    gateway_fees_per_psp_bar: [],
    gateway_fees_per_psp_per_card_scheme: [],
    gateway_fees_per_psp_per_card_scheme_bar: [],
    gateway_fees_per_psp_per_card_type_bar: [],
    gateway_fees_per_psp_per_card_type: [],
    global_refund_percent: 0,
    refunded_amount: 0,
    transactions_refunded: 0,
    refunded_per_country: [],
    refund_amount_per_psp: [],
    refund_amount_per_psp_bar: [],
    voided_amount: 0,
    transactions_voided: 0,
    gatewayConfigurationsData: [],
    global_chargeback_rate: 0,
    chargedback_amount: 0,
    chargedback_transactions: 0,
    telescope: {
      authorizations_status: {
        volume_impacted: 0,
      },
      fees_status: {
        volume_impacted: 0,
      },
    },
    fees_recommendations: [],
    chargebacksData: {
      ready: false,
      selectedGatewayConfiguration: null,
      chargebackThresholds: {
        "Current Visa Program": {
          metrics: ["count", "rate"],
          rate: 0,
          count: 0,
          volume: 0,
        },
        "Current Mastercard Program": {
          metrics: ["count", "rate"],
          rate: 0,
          count: 0,
          volume: 0,
        },
      },
      globalChargebackCount: 0,
      globalChargebackRate: 0,
      globalChargebackVolume: 0,
      globalNotificationsOfFraud: 0,
      globalRequestsForInformation: 0,
      mastercardChargebackCount: 0,
      mastercardChargebackRate: 0,
      mastercardChargebackVolume: 0,
      mastercardNotificationsOfFraud: 0,
      mastercardRequestsForInformation: 0,
      visaChargebackCount: 0,
      visaChargebackRate: 0,
      visaChargebackVolume: 0,
      visaNotificationsOfFraud: 0,
      visaRequestsForInformation: 0,
      chargebackPrograms: {
        visa: {
          name: "Current Visa Program",
          card_scheme: "visa",
          metrics: ["Count", "Volume"],
          count: 0,
          rate: 0,
          volume: 0,
          fines: [],
          belong_since: 0,
        },
        mastercard: {
          name: "Current Mastercard Program",
          card_scheme: "mastercard",
          metrics: ["Count", "Volume"],
          count: 0,
          rate: 0,
          volume: 0,
          fines: [],
          belong_since: 0,
        },
      },
      defaultChargebackPrograms: {
        visa: "Current Visa Program",
        mastercard: "Current Mastercard Program",
      },
      belongsToProgram: false,
    },
  },
}
export default function (
  state: $TelescopeStateType = defaultState,
  action: Action,
): $TelescopeStateType {
  switch (action.type) {
    case RESET_TELESCOPE_STATE: {
      return { ...state, ...defaultState }
    }

    case typePending(PREPARE_TELESCOPE): {
      const { tab } = action.payload
      return {
        ...state,
        ...defaultState,
        selectedTab: state.selectedTab,
        [tab === "auth"
          ? "authorizationReady"
          : tab === "capt"
          ? "capturesReady"
          : tab === "char"
          ? "chargebacksReady"
          : "refundsReady"]: false,
        error: false,
      }
    }

    case typePending(PREPARE_TELESCOPE_SILENT): {
      const { tab } = action.payload
      return {
        ...state,
        [tab === "auth"
          ? "authorizationReady"
          : tab === "capt"
          ? "capturesReady"
          : tab === "char"
          ? "chargebacksReady"
          : "refundsReady"]: false,
        error: false,
      }
    }

    case typeFulfilled(PREPARE_TELESCOPE): {
      const { tab } = action.payload
      return {
        ...state,
        [tab === "auth"
          ? "authorizationReady"
          : tab === "capt"
          ? "capturesReady"
          : tab === "char"
          ? "chargebacksReady"
          : "refundsReady"]: true,
        data: { ...state.data, ...action.payload.data },
      }
    }

    case typeFailed(PREPARE_TELESCOPE): {
      const { tab } = action.payload
      return {
        ...state,
        [tab === "auth" ? "authorizationReady" : tab === "capt" ? "capturesReady" : "refundsReady"]:
          false,
        error: true,
      }
    }

    case typeFailed(REQUEST_CHARGEBACK_DATA_FETCH): {
      return { ...state, error: action.payload }
    }

    case UPDATE_TELESCOPE_TIMESPAN: {
      if (!action.payload) return state
      return {
        ...state,
        authorizationReady: false,
        capturesReady: false,
        refundsReady: false,
        timespan: action.payload.timespan,
      }
    }

    case UPDATE_TELESCOPE_TAB: {
      if (!action.payload) return state
      return {
        ...state,
        selectedTab: action.payload.tab,
        data: { ...state.data, chargebacksData: { ...defaultState.data.chargebacksData } },
      }
    }

    case SELECT_CHARGEBACK_GATEWAY_CONFIGURATION: {
      if (!action.payload) return state
      return {
        ...state,
        data: {
          ...state.data,
          chargebacksData: {
            ...state.data.chargebacksData,
            selectedGatewayConfiguration: action.payload.config,
          },
        },
      }
    }

    case REQUEST_CHARGEBACK_DATA_FETCH: {
      return {
        ...state,
        data: { ...state.data, chargebacksData: { ...state.data.chargebacksData, ready: false } },
      }
    }

    case typeFulfilled(REQUEST_CHARGEBACK_DATA_FETCH): {
      const { tab } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          chargebacksData: {
            ...action.payload.data.chargebacksData,
            selectedGatewayConfiguration: state.data.chargebacksData.selectedGatewayConfiguration,
          },
        },
      }
    }
  }

  return state
}
