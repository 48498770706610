import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "./consts"
import type { $Action } from "../../util/Types"
import * as Actions from "./actions"
import { START_RESOURCES_SEARCH } from "./actions"
import * as ProcessOut from "../../util/ProcessOut"
import * as Helpers from "../SearchBar/helpers"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
const IGNORE_FIELDS = [
  "merchant_initiated",
  "rand",
  "velocity",
  "check_3ds",
  "authorization_retry_attempt",
  "is_last_operation",
]
export function* fetchValues(action: $Action): Generator<any, any, any> {
  try {
    if (
      new RegExp(/.*(amount$|amount_.+|fee$|fee_local|fee_estimate.*|seconds)/).test(
        action.payload.field,
      )
    )
      return

    // we do not want to fetch numbers values
    if (action.payload.field === "is_three_d_s_flow" || !action.payload.field) {
      return // this field is only used in local (we are using three_d_s_status behind the scene)
    }

    if (IGNORE_FIELDS.includes(action.payload.field)) return
    const result = yield put.resolve(Actions.fetchValues(action as any))
    yield put({
      type: typeFulfilled(REQUEST_FETCH_VALUES_FOR_FIELD),
      payload: {
        field: action.payload.field,
        values: result.value.data.values,
        document: action.payload.document,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTransactionWithId(id: string): Generator<any, any, any> {
  const apiResult = yield call(
    ProcessOut.APIcallPromise,
    `/transactions/${id}?expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=card`,
    "GET",
    null,
    null,
    true,
  )
  return apiResult.data.transaction
}
export function* fetchCardWithId(id: string): Generator<any, any, any> {
  const apiResult = yield call(ProcessOut.APIcallPromise, `/cards/${id}`, "GET", null, null, true)
  return apiResult.data.card
}
export function* fetchCustomerWithId(id: string): Generator<any, any, any> {
  const apiResult = yield call(
    ProcessOut.APIcallPromise,
    `/customers/${id}`,
    "GET",
    null,
    null,
    true,
  )
  return apiResult.data.customer
}
export function* searchForTransactionWithKeyword(keyword: string): Generator<any, any, any> {
  const resourceType = Helpers.getResourceTypeFromId(keyword)
  let additionalFilter: string | null | undefined
  if (resourceType === "invoice") additionalFilter = `invoice_id == "${keyword}"`
  else if (resourceType === "card") additionalFilter = `card_id == "${keyword}"`
  const filter = `name == "${keyword}" OR customer_email == "${keyword}" OR customer_name == "${keyword}"${
    additionalFilter ? ` OR ${additionalFilter}` : ""
  }`
  const apiResult = yield call(
    ProcessOut.APIcallPromise,
    `/transactions?filter=${encodeURIComponent(
      filter,
    )}&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=card`,
    "GET",
    null,
    null,
    true,
  )
  return apiResult.data.transactions
}
export function* searchForCardsWithKeyword(keyword: string): Generator<any, any, any> {
  const resourceType = Helpers.getResourceTypeFromId(keyword)
  const filter = `name == "${keyword}" OR last_4 == "${keyword}"`
  const apiResult = yield call(
    ProcessOut.APIcallPromise,
    `/cards?filter=${encodeURIComponent(filter)}`,
    "GET",
    null,
    null,
    true,
  )
  return apiResult.data.cards
}
export function* searchForCustomersWithKeyword(keyword: string): Generator<any, any, any> {
  const resourceType = Helpers.getResourceTypeFromId(keyword)
  const filter = `email == "${keyword}"`
  const apiResult = yield call(
    ProcessOut.APIcallPromise,
    `/customers?filter=${encodeURIComponent(filter)}`,
    "GET",
    null,
    null,
    true,
  )
  return apiResult.data.customers
}
export function* startResourcesFetch(action: $Action): Generator<any, any, any> {
  try {
    const { filter } = action.payload
    const resources = {
      transactions: [],
      customers: [],
      cards: [],
    }
    // Search for transactions
    const resourceType = Helpers.getResourceTypeFromId(filter)
    // If filter is an ID, we try to fetch the resource
    let idFetchCall

    switch (resourceType) {
      case "transaction":
        idFetchCall = yield call(fetchTransactionWithId, filter)
        break

      case "customer":
        idFetchCall = yield call(fetchCustomerWithId, filter)
        break

      case "card":
        idFetchCall = yield call(fetchCardWithId, filter)
        break

      default:
        break
      // Filter is not an id
    }

    // We try a deeper search
    // Prepare calls for parallel fetching
    const trs = yield call(searchForTransactionWithKeyword, filter)
    const csts = yield call(searchForCustomersWithKeyword, filter)
    const crds = yield call(searchForCardsWithKeyword, filter)
    const searchCalls = [idFetchCall, trs, csts, crds]
    // Execute the calls
    const results = yield all(searchCalls)

    if (searchCalls[0]) {
      switch (resourceType) {
        case "transaction":
          resources.transactions.push(searchCalls[0])
          break

        case "customer":
          resources.customers.push(searchCalls[0])
          break

        case "card":
          resources.cards.push(searchCalls[0])
          break

        default:
          break
        // Filter is not an id
      }
    }

    // Fills transactions
    resources.transactions = resources.transactions.concat(results[1])
    // Fills customers
    resources.customers = resources.customers.concat(results[2])
    // Fills cards
    resources.cards = resources.cards.concat(results[3])
    // Dispatch the results
    yield put({
      type: typeFulfilled(START_RESOURCES_SEARCH),
      payload: {
        resources: resources.transactions.concat(resources.customers).concat(resources.cards),
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(START_RESOURCES_SEARCH),
      payload: error,
    })
  }
}
export default function* watchSearchBarSagas(): Generator<any, any, any> {
  yield takeEvery(REQUEST_FETCH_VALUES_FOR_FIELD, fetchValues)
  yield takeLatest(START_RESOURCES_SEARCH, startResourcesFetch)
}
