import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import user from "./UserReducer/Index"
import transactions from "./TransactionsReducer"
import transactionDetails from "./TransactionDetails/Index"
import customers from "./CustomersReducer"
import customerDetails from "./CustomerDetailsReducer/Index"
import customerSubscriptions from "./RecurringsReducer"
import events from "./EventsReducer"
import eventDetails from "./EventDetailsReducer"
import eventsTransaction from "./EventsTransactionReducer"
import projects from "./ProjectsReducer"
import refunds from "./RefundsReducer"
import invoices from "./InvoicesReducer"
import invoiceDetails from "./InvoiceDetailsReducer"
import gateways from "./GatewaysReducer"
import fraudServiceConfigurationsReducer from "../../features/FraudServicesConfiguration/reducer"
import fraudServices from "../../features/FraudServices/reducer"
import plans from "./PlansReducer"
import processorsConfigurations from "./ProcessorsConfigurationReducer"
import analytics from "./AnalyticsReducers/Index"
import currencies from "./CurrenciesReducer"
import apiVersions from "./ApiVersionsReducer"
import notification from "../../features/NotificationBar/NotificationsReducer"
import subscriptions from "./SubscriptionsReducer"
import telescope from "./TelescopeReducer/Index"
import Countries from "./CountryReducer"
import discounts from "./DiscountsReducer"
import coupons from "./CouponsReducer"
import subscriptionDetails from "./SubscriptionDetailsReducer"
import subscriptionTransactions from "./SubscriptionTransactionsDetailsReducer"
import vault from "./VaultReducer"
import modal from "./ModalReducer"
import JS from "./JSReducer"
import Permissions from "../../features/Permissions/reducer"
import CurrentProject from "./CurrentProjectReducer"
import Dunning from "./DunningReducer"
import ApplePay from "./ApplePayReducer"
import ApplePayAlternativeCertificate from "../../components/Settings/ApplePay/AlternativeCertificates/reducer"
import Preparator from "../../features/Preparator/reducer"
import BoardsReducer from "../../features/analytics/Boards/reducerIndex"
import GwayConfNames from "../../features/GatewaysConfigurations/reducer"
import SearchBarReducer from "../../features/SearchBar/reducer"
import ErrorContext from "../../features/ErrorContext/reducer"
import RoutingRules from "../../features/RoutingRules/reducer"
import ApiSetupReducer from "../../features/ApiSetup/reducer"
import NetworkReducer from "../../features/NetworkManager/reducer"
import SurfacesReducer from "../../features/Telescope/Surface/reducer"
import DataExplorer from "../../features/analytics/DataExplorer/reducer"
import BoardCreator from "../../features/analytics/BoardCreator/reducer"
import Reports from "../../features/Reports/reducer"
import PayoutsReducer from "../../features/Payouts/reducer"
import PayoutDetailsReducer from "../../features/Payouts/detailsReducer"
import DeclineExplainer from "../../features/DeclineExplainer/reducer"
import chartBuilder from "../../features/analytics/DataExplorer/ChartBuilder/reducer"
import WebhooksReducer from "../../features/Webhooks/reducer"
import TransactionsExportsReducer from "../../features/Exports/reducer"
import AlertingReducer from "../../features/Alerts/reducer"
import AlertingEventsReducer from "../../features/Alerts/Events/reducer"
import AlertBuilderReducer from "../../features/Alerts/AlertBuilder/reducer"
import TelescopeRecommendations from "../../features/TelescopeV2/reducers/recommendations"
import MetricsTelescopeReducer from "../../features/TelescopeV2/reducers/metrics"
import LaunchDarklyReducer from "^/vNext/LaunchDarkly/LaunchDarklyReducer"
const reducers = {
  analytics: analytics(false),
  applePay: ApplePay,
  applePayAlternativeCertificate: ApplePayAlternativeCertificate,
  apiVersions,
  currencies,
  user,
  coupons,
  discounts,
  transactions,
  transactionDetails,
  customers,
  customerDetails,
  modal,
  notification,
  events,
  eventDetails,
  eventsTransaction,
  projects,
  refunds,
  plans,
  invoices,
  countries: Countries,
  invoiceDetails,
  customers_subscriptions: customerSubscriptions,
  subscriptions,
  subscriptionDetails,
  subscriptionTransactions,
  gateways,
  fraudServices,
  fraudServiceConfigurationsReducer,
  telescope,
  processorsConfigurations,
  routing: routerReducer,
  vault,
  scripts: JS,
  permissions: Permissions,
  currentProject: CurrentProject,
  dunning: Dunning,
  preparator: Preparator,
  analytics_v2: BoardsReducer,
  gateway_configurations_names: GwayConfNames,
  searchBar: SearchBarReducer,
  errorContext: ErrorContext,
  routingRulesSettings: RoutingRules,
  apiSetup: ApiSetupReducer,
  network: NetworkReducer,
  surfaces: SurfacesReducer,
  dataExplorer: DataExplorer,
  boardCreator: BoardCreator,
  reports: Reports,
  payouts: PayoutsReducer,
  payoutDetails: PayoutDetailsReducer,
  declineExplainer: DeclineExplainer,
  chartBuilder,
  webhooks: WebhooksReducer,
  transactionsExports: TransactionsExportsReducer,
  alerting: AlertingReducer,
  alertingEvents: AlertingEventsReducer,
  alertBuilder: AlertBuilderReducer,
  telescopeRecommendations: TelescopeRecommendations,
  metricsTelescopeReducer: MetricsTelescopeReducer,
  launchDarkly: LaunchDarklyReducer,
}

const rootReducer = (state: Record<string, any> | null | undefined, action: any) => {
  switch (action.type) {
    case "WIPE_STORE": {
      if (!action.payload || !action.payload.excludes) {
        state = undefined
        break
      }

      if (state) {
        const keys = Object.keys(state)

        for (let i = 0; i < keys.length; i++) {
          if (action.payload.excludes.indexOf(keys[i]) === -1) {
            state[keys[i]] = undefined
          }
        }
      }

      break
    }
  }

  if (state && (action.type === "CLOSE_MODAL" || action.type.includes("@@router"))) {
    state.notification = {
      displayed: true,
      message: "",
      type: "",
    }
  }

  return combineReducers(reducers)(state, action)
}

export default rootReducer
export function injectReducer(reducer, name) {
  const newReducers = { ...reducers, [name]: reducer }
  return combineReducers(newReducers)
}
