import { EventEmitter } from "events"
import Dispatcher from "../Dispatcher"

class ErrorStore extends EventEmitter {
  constructor() {
    super()
    this.error = null
  }

  getError() {
    return this.error
  }

  handleAction(action) {
    switch (action.type) {
      case "ERROR": {
        this.error = action.error
        this.emit("error")
        break
      }
    }
  }
}

const errorStore = new ErrorStore()
Dispatcher.register(errorStore.handleAction.bind(errorStore))
export default errorStore
