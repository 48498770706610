import React from "react"
import CurrencyField from "../../../components/CurrencyField"
import { BulletNumber } from "^/features/TelescopeOnboarding/processors/BulletNumber"
import { Select, Option } from "../../../vNext/Select/Select"
type Props = {
  hide: boolean
  isForTelescope?: boolean | null | undefined
  currency: string
}

const visaMCOption: Option = {
  value: "Default on Visa & Mastercard",
  label: "Default on Visa & Mastercard",
}
const cbOption: Option = { value: "Default on Carte Bancaire", label: "Default on Carte Bancaire" }
const preferredSchemeOptions = [visaMCOption, cbOption]

const yesOption: Option = { value: "Yes", label: "Yes" }
const noOption: Option = { value: "No", label: "No" }
const activateCBOptions = [yesOption, noOption]

export class AdyenThird extends React.Component<Props> {
  render() {
    const { hide } = this.props
    if (hide) return null
    return (
      <div className="row">
        <div className="large-12 large-centered columns">
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <h4>Complete your configuration</h4>
            </div>
          </div>
          {!this.props.isForTelescope && (
            <div className="row small-margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={8} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold greyed margin-bottom">Generate Adyen user</label>
                <p>
                  From the Adyen dashboard, go to <span className="bold">Account</span>, then{" "}
                  <span className="bold">API credentials</span>, then{" "}
                  <span className="bold">Create new credential</span>. Under{" "}
                  <span className="bold">User Account Details</span> ensure{" "}
                  <span className="bold">Webservice</span> is selected. Enter the generated username
                  and password below. Make sure to save the user.
                </p>
                <div className="row margin-bottom">
                  <div className="large-12 columns greyed">
                    <input type="text" name="ws_username" placeholder="Account username" />
                    <input type="password" name="ws_password" placeholder="Password" />
                    <input
                      type="text"
                      name="merchant_account"
                      placeholder="You merchant account (upper left hand corner of your Adyen dashboard)"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!this.props.isForTelescope && (
            <div className="row small-margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={9} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold greyed margin-bottom">Set up your Adyen sub-domain</label>
                <p>
                  In your Adyen dashboard, go to <span className="bold">Account</span>, then{" "}
                  <span className="bold">API URLs</span>. Under one of the displayed endpoints, copy
                  the sub-domain and paste it below. It is generally in the format{" "}
                  <span className="bold">abcd1234-CompanyName</span>
                </p>
                <div className="row margin-bottom">
                  <div className="medium-12 columns">
                    <input
                      type="text"
                      name="sub_domain"
                      placeholder="Your live endpoint subdomain"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!this.props.isForTelescope && (
            <div className="row small-margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={10} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold greyed margin-bottom">
                  Activate Carte Bancaire and set up the preferred card scheme
                </label>
                <p>
                  If your contract allows it, you can activate Carte Bancaire on your Adyen
                  configuration. For compliance reasons, your customer can decide the network they
                  decide to use with the <code>preferred_scheme</code>field on our API, explained on{" "}
                  <a href="https://docs.processout.com/payments/processoutjs-reference/#bind-the-form-and-tokenize-the-card">
                    Bind the form and tokenize the card↗
                  </a>
                  ).
                </p>
                <p>
                  By default this feature is not activated, and we will not default to Carte
                  Bancaire unless explicitly selected.
                </p>
                <div className="row margin-bottom">
                  <div className="medium-12 columns">
                    <Select
                      options={activateCBOptions}
                      isSearchable={false}
                      placeholder="Activate Carte Bancaire"
                      name="activate_cb"
                    />
                  </div>
                </div>
                <div className="row margin-bottom">
                  <div className="medium-12 columns">
                    <Select
                      options={preferredSchemeOptions}
                      isSearchable={false}
                      placeholder="Default network on Carte Bancaire network"
                      name="preferred_scheme"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!this.props.isForTelescope && (
            <div className="row small-margin-bottom">
              <div className="medium-1 columns">
                <BulletNumber value={11} />
              </div>
              <div className="medium-11 columns greyed">
                <label className="bold greyed margin-bottom">
                  Activate required Adyen features
                </label>
                <p>
                  Please ensure that your Adyen customer success manager has enabled the following
                  features
                </p>
                <ul>
                  <li>Direct PANs</li>
                  <li>Enrolled MIDs in 3DS & 3DS2</li>
                  <li>Checkout API role</li>
                  <li>Authentication-only role</li>
                  <li>mpiData field</li>
                </ul>
              </div>{" "}
            </div>
          )}
          <div className="row small-margin-bottom">
            <div className="medium-1 columns">
              <BulletNumber value={this.props.isForTelescope ? 8 : 12} />
            </div>
            <div className="medium-11 columns greyed">
              <label className="bold greyed margin-bottom">Saving your configuration</label>
              <div className="row margin-bottom">
                <div className="medium-12 columns">
                  <div className="row">
                    <div className="medium-12 columns">
                      <label className="greyed">Configuration name</label>
                      <input type="text" name="name" placeholder="Adyen" />
                    </div>
                  </div>
                  <div className="row margin-bottom">
                    <div className="large-12 columns">
                      <CurrencyField name="default_currency" defaultValue={this.props.currency} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AdyenThird
