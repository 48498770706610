import React from "react"
import { connect } from "react-redux"
import type { $Filter } from "./Filter/consts"
import * as Actions from "../actions"
import type { $Dispatcher } from "../../../util/Types"
import type { $RoutingRule } from "../consts"
import { RULES_FILTERS } from "../consts"
import Filter from "./Filter/Filter"
type Props = {
  rule: $RoutingRule
  ruleWontMatch: Boolean
} & $Dispatcher
export class TriggerRule extends React.Component<Props> {
  filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
    const newRule = { ...this.props.rule }
    newRule.conditions[conditionIndex].filters = newValue
    this.props.dispatch(Actions.updateRule(newRule.id, newRule))
  }
  runForCardVerificationsChanged = e => {
    const newRule = { ...this.props.rule }
    newRule.run_for_card_verifications = e.target.checked
    this.props.dispatch(Actions.updateRule(newRule.id, newRule))
  }

  render() {
    const { rule, ruleWontMatch } = this.props
    return (
      <div className="row" data-tracking-location="Trigger 3DS">
        {ruleWontMatch ? (
          <div className="warning-wont-match">
            A previous rule has no active filter and applies to every transaction: this rule will
            never be executed.
          </div>
        ) : null}
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-8 columns">Trigger 3DS on transactions</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              {rule.conditions.map((condition, index) => (
                <div className="row small-margin-bottom" key={`${rule.id}-${index}`}>
                  <div className="medium-12 columns">
                    <Filter
                      onChange={this.filterChanged.bind(null, index)}
                      options={Object.keys(RULES_FILTERS).map(filter => ({
                        label: RULES_FILTERS[filter].label,
                        value: filter,
                        desc: RULES_FILTERS[filter].desc,
                        isMulti: RULES_FILTERS[filter].isMulti,
                      }))}
                      defaultFilters={condition.filters}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <label
                className="ios7-switch big"
                style={{
                  marginRight: "15px",
                  position: "relative",
                  top: ".2em",
                }}
              >
                <input
                  type="checkbox"
                  defaultChecked={rule.run_for_card_verifications}
                  name="run_for_card_verifications"
                  value="false"
                  onChange={this.runForCardVerificationsChanged}
                />
                <span />
              </label>{" "}
              Enable this trigger on card verifications
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(TriggerRule)
