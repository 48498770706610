import * as ProcessOut from "../util/ProcessOut"
export const FETCH_VAULT_CARDS = "FETCH_VAULT_CARDS"
export function loadCards(filter, length, after, id) {
  filter = filter || ""
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_VAULT_CARDS,
    payload: ProcessOut.APIcallPromise(
      `/cards?expand=token.customer&order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
      "GET",
    ),
  }
}
export const ANONYMIZE_VAULT_CARD = "ANONYMIZE_VAULT_CARD"
export function anonymizeCard(id) {
  return {
    type: ANONYMIZE_VAULT_CARD,
    payload: ProcessOut.APIcallPromise(`/cards/${id}`, "DELETE"),
  }
}
