import React from "react"
type Props = {
  value: string
  onChange: (arg0: string) => void
}

class IntervalSelector extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <select
          name="interval"
          style={{
            height: "100%",
            fontSize: ".8em",
            width: "100%",
          }}
          value={this.props.value}
          onChange={event => {
            const interval = event.target.value
            this.props.onChange(interval)
          }}
        >
          <option value="auto">Best fit</option>
          <option value="15m">15 minutes</option>
          <option value="1h">1 hour</option>
          <option value="12h">12 hours</option>
          <option value="1d">1 day</option>
          <option value="7d">7 days</option>
          <option value="1M">1 month</option>
          <option value="3M">3 months</option>
          <option value="6M">6 months</option>
          <option value="1Y">1 year</option>
        </select>
      </div>
    )
  }
}

export default IntervalSelector
