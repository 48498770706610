import {
  FETCH_RECURRING_INVOICES,
  FETCH_RECURRING_INVOICES_NORELOAD,
} from "^/Actions/InvoicesActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_RECURRING_INVOICES): {
      return { ...state, fetching: true, fetched: false, subscriptions: null }
    }

    case typeFulfilled(FETCH_RECURRING_INVOICES): {
      return formatReducer(state, action)
    }

    case typeFulfilled(FETCH_RECURRING_INVOICES_NORELOAD): {
      return formatReducer(state, action)
    }

    case typeFailed(FETCH_RECURRING_INVOICES_NORELOAD):
    case typeFailed(FETCH_RECURRING_INVOICES): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }
  }

  return state
}

function formatReducer(state, action) {
  return { ...state, ...action.payload, fetching: false, fetched: true }
}
