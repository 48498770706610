import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import React, { useContext } from "react"
import uniqid from "uniqid"
import { $RoutingRule, DYNAMIC_PARAMS, DYNAMIC_RULES_FILTER } from "^/features/RoutingRules/consts"
import { Button } from "^/vNext/Button"
import { Select } from "^/vNext/Select/Select"
import { GatewayContext } from "../GatewayContext"
import { useDefaultGatewayOptions } from "../hooks"
import { RoutingContext } from "../RoutingContext"
import { ReactComponent as SvgIcon } from "./caret-icon.svg"
import { colors } from "../_variables"
import { isValidGateway } from "^/vNext/RoutingRules/utils"
import InvalidGatewayErrorSection from "^/vNext/RoutingRules/InvalidGatewayErrorSection"

export type Props = {
  rule: $RoutingRule
  onChange: (rule: $RoutingRule) => void
}

export const Dynamic3DSSelection = ({ rule, onChange }: Props) => {
  const [state, dispatch] = useContext(RoutingContext)
  const gatewayOptions = useDefaultGatewayOptions()
  const { gatewayNames, gatewayConfigurations } = useContext(GatewayContext)
  const gatewayInfo = rule.gateways[0] || { gateway: "", id: uniqid() }

  const isGatewayInvalid = !isValidGateway(gatewayInfo, gatewayConfigurations)

  rule.dynamic_3ds_params = rule.dynamic_3ds_params || []

  const elements3DS = DYNAMIC_RULES_FILTER.map(({ label: name, value: path }) => {
    const paramIndex = rule.dynamic_3ds_params.findIndex(param => param.path === path)
    const isSet = paramIndex !== -1
    const addButtonLabel = `Add ${name.toLocaleLowerCase()}`

    if (isSet) {
      const filter = rule.dynamic_3ds_params[paramIndex]
      const value = DYNAMIC_PARAMS[path].find(a => a.value === filter.value) || null
      const removeButtonLabel = `Remove ${name.toLocaleLowerCase()}`

      const defaultOpen =
        rule.dynamic_3ds_params.find(param => param.path === path)?.id == state.selectedCardId

      return (
        <Disclosure key={path} defaultOpen={defaultOpen}>
          {({ open }) => (
            <div className={clsx("rule-condition", { open })}>
              <Disclosure.Button
                className={clsx("rule-condition__button", open && "rule-condition__button--open")}
              >
                {`Set ${name.toLocaleLowerCase()}`}
                <SvgIcon className="rule-condition__icon" />
              </Disclosure.Button>
              <Disclosure.Panel
                unmount={false}
                className={clsx("rule-condition__panel", open && "rule-condition__panel--open")}
              >
                <div key={path}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <Select
                        options={DYNAMIC_PARAMS[filter.path]}
                        value={value}
                        onChange={({ value }) => {
                          const newRule = structuredClone(rule)
                          filter.value = value
                          if (paramIndex == -1) {
                            newRule.dynamic_3ds_params.push(filter)
                          } else {
                            newRule.dynamic_3ds_params[paramIndex] = filter
                          }
                          onChange(newRule)
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    aria-label={removeButtonLabel}
                    data-auto-tracking="true"
                    data-tracking-label={removeButtonLabel}
                    style={{ padding: 0, marginTop: 8 }}
                    variant="tertiary"
                    className="rule-condition__deleteBtn"
                    onClick={() => {
                      const newRule = structuredClone(rule)
                      const idx = newRule.dynamic_3ds_params.findIndex(p => p.path === path)
                      newRule.dynamic_3ds_params.splice(idx, 1)
                      onChange(newRule)
                      // Close menu if the card is deleted
                      if (filter.id === state.selectedCardId) {
                        dispatch({
                          type: "select.routing.card",
                          id: null,
                        })
                      }
                    }}
                  >
                    {removeButtonLabel}
                  </Button>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      )
    }

    return (
      <div key={path} style={{ marginTop: 12, marginBottom: 12 }}>
        <Button
          key={path}
          aria-label={addButtonLabel}
          data-auto-tracking="true"
          data-tracking-label={addButtonLabel}
          style={{
            width: "100%",
            backgroundColor: colors.neutral.orange[20],
            color: colors.neutral.orange[120],
            border: "none",
          }}
          variant="primary"
          onClick={() => {
            const newRule = structuredClone(rule)
            const id = uniqid()
            newRule.dynamic_3ds_params.push({
              id,
              path,
              value: "",
            })
            onChange(newRule)

            dispatch({
              type: "select.routing.card",
              id,
            })
          }}
        >
          {addButtonLabel}
        </Button>
      </div>
    )
  })

  return (
    <>
      {/* dynamic 3ds */}
      <div className="CriteriaMenu__body" style={{ paddingTop: 0 }}>
        <p className="CriteriaMenu__sectionTitle">{`Set exemption or challenge indicator`}</p>
        {elements3DS}
      </div>
      {/* gateway */}
      <div className="CriteriaMenu__body" style={{ paddingTop: 0 }}>
        <p className="CriteriaMenu__sectionTitle">On gateway</p>
        <Disclosure defaultOpen={state.selectedCardId == rule.gateways[0]?.id}>
          <Select
            options={gatewayOptions}
            value={{
              label: gatewayNames[gatewayInfo.gateway],
              value: gatewayInfo.gateway,
            }}
            onChange={({ value }) => {
              const newRule = structuredClone(rule)
              newRule.gateways[0] = { ...newRule.gateways[0], gateway: value }
              onChange(newRule)
            }}
          />
        </Disclosure>

        <div style={{ marginTop: 8 }}>
          <InvalidGatewayErrorSection invalid={isGatewayInvalid} />
        </div>
      </div>
    </>
  )
}
