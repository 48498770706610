import type { $Action } from "../../../../util/Types"
import type { $TelescopeRecommendations } from "../../consts"
import { LOAD_RECOMMENDATIONS_FROM_PERIOD, WIPE_TELESCOPE_STATE } from "../../consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $PeriodRecommendations = {
  fetching: boolean
  fetched: boolean
  periodRecommendations: $TelescopeRecommendations
  success: boolean
}
const defaultState: $PeriodRecommendations = {
  fetching: true,
  fetched: false,
  periodRecommendations: {} as $TelescopeRecommendations,
  success: false,
}
export default function (state: $PeriodRecommendations = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(LOAD_RECOMMENDATIONS_FROM_PERIOD): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(LOAD_RECOMMENDATIONS_FROM_PERIOD): {
      return {
        fetching: false,
        fetched: true,
        periodRecommendations: action.payload.recommendations,
        success: action.payload.success,
      }
    }

    case typeFailed(LOAD_RECOMMENDATIONS_FROM_PERIOD): {
      return { ...state, ...defaultState, fetching: false, fetched: true, error: action.payload }
    }

    case WIPE_TELESCOPE_STATE: {
      return { ...defaultState }
    }
  }

  return state
}
