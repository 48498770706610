import React from "react"
import { connect } from "react-redux"
import AceEditor from "react-ace"
import * as Actions from "../actions"
import CustomRoutingRulesMode from "./customRoutingRulesMode"
import NotPermitted from "^/features/Permissions/NotPermitted"
import Auth from "^/util/Auth"
import type { $Dispatcher, $Project } from "^/util/Types"
type Props = {
  rulesRaw: string
  initialRulesRaw: string
  currentProject: {
    project: $Project
  }
} & $Dispatcher
export class RoutingRulesEditor extends React.Component<Props> {
  constructor() {
    super()
    this.aceEditorRef = React.createRef()
  }

  componentDidMount() {
    if (this.aceEditorRef.current) {
      const customMode = new CustomRoutingRulesMode()
      const { editor } = this.aceEditorRef.current
      editor.getSession().setMode(customMode)
      editor.renderer.setShowGutter(false)
    }
  }

  onChange = (value: string) => {
    this.props.dispatch(Actions.updateRoutingRulesFormula(value))
  }

  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="code-editor">
            {Auth.isAdminUser() ? (
              <AceEditor
                ref={this.aceEditorRef}
                theme="cobalt"
                value={this.props.rulesRaw}
                onChange={this.onChange}
                showPrintMargin={false}
                fontSize={14}
              />
            ) : (
              <NotPermitted />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  rulesRaw: store.routingRulesSettings.rulesRaw,
  initialRulesRaw: store.routingRulesSettings.initialRulesRaw,
}))(RoutingRulesEditor)
