import { put, takeLatest } from "redux-saga/effects"
import type { $Action } from "../../util/Types"
import { UPDATE_CURRENT_URL } from "./consts"

function* updateCurrentUrl(): Generator<any, any, any> {
  yield put({
    type: UPDATE_CURRENT_URL,
    payload: {
      url: document.location.hash,
    },
  })
}

export default function* watchForSaga(): Generator<any, any, any> {
  yield takeLatest(
    (action: $Action) => new RegExp(/^@@router\/\w*/).test(action.type),
    updateCurrentUrl,
  )
}
