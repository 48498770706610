import React from "react"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import { connect } from "react-redux"
import { SortableContainer } from "react-sortable-hoc"
import ReactTooltip from "react-tooltip"
import type { $Dispatcher } from "../../../util/Types"
import * as Actions from "../actions"
import type { $RoutingRule, $RoutingRulesSettings } from "../consts"
import Rule from "./Rule"

type Props = {
  rules: Array<$RoutingRule>
  rulesSettings: $RoutingRulesSettings
} & $Dispatcher
export class DynamicRulesListing extends React.Component<Props> {
  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  render() {
    const { rules, dispatch, rulesSettings } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns">
          {rules.length === 0 && (
            <div
              className="greyed"
              style={{
                position: "relative",
                top: ".5em",
              }}
            >
              You haven&apos;t defined any dynamic 3DS rules yet.
              <a
                data-auto-tracking="true"
                className="small round border button right add-rule-button"
                onClick={() => {
                  dispatch(Actions.addRule("dynamic_3ds"))
                }}
              >
                Add a dynamic 3DS rule
              </a>
            </div>
          )}
          <ReactTooltip effect="solid" />
          <ReactCSSTransitionGroup
            transitionName="routing-setting-rule-transition"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
          >
            {rules.map((rule, index, array) => (
              <Rule
                position={index + 1}
                index={index}
                key={rule.id}
                rule={rule}
                ruleWontMatch={
                  array
                    .slice(0, index)
                    .findIndex(
                      rule =>
                        rule.conditions[0].filters.filter(
                          filter => filter.path !== "" && filter.value !== "",
                        ).length === 0,
                    ) > -1
                }
              />
            ))}
          </ReactCSSTransitionGroup>
          <div className="small-margin-bottom" />
          {rulesSettings.rules.filter(rule => rule.declaration === "dynamic_3ds").length > 0 && (
            <a
              data-auto-tracking="true"
              onClick={() => {
                dispatch(Actions.addRule("dynamic_3ds"))
              }}
              className="round border button small right"
            >
              &#43; add another rule
            </a>
          )}
        </div>
      </div>
    )
  }
}
const ConnectedRules = connect(store => ({
  rulesSettings: store.routingRulesSettings,
}))(DynamicRulesListing)
export default SortableContainer(props => <ConnectedRules {...props} />)
