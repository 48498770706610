/**
 * Created by jeremylejoux on 07/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import Serialize from "form-serialize"
import { push, replace } from "react-router-redux"
import * as Store from "../../../stores/Store"
import * as ApplePayActions from "../../../Actions/ApplePayActions"
import ContentLayout from "../../../pages/ContentLayout"
import * as ProjectActions from "../../../Actions/ProjectsActions"
export class Start extends React.Component {
  props: Props
  state: State

  /*:: _unsubscribe: ?() => void; */
  static defaultProps = {
    dispatch: null,
  }

  constructor() {
    super()
    this.state = {
      working: false,
    }
  }

  componentDidMount() {
    this._unsubscribe = Store.store.subscribe(this.storeDidUpdate.bind(this))
    // TODO: wipe current keys
    const { params, dispatch, currentProject } = this.props
    if (!currentProject.project) return
    if (currentProject.project.applepay_settings)
      dispatch(replace(`/projects/${params.project}/settings/apple-pay`))
  }

  storeDidUpdate() {
    const state = Store.store.getState()
    const { dispatch, currentProject } = this.props

    if (state.applePay.keys.keys && this._unsubscribe) {
      this._unsubscribe()

      if (!currentProject.project) {
        return
      }

      dispatch(push(`/projects/${currentProject.project.id}/settings/apple-pay/step-2`))
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentWillUnmount() {
    if (this._unsubscribe) this._unsubscribe()
  }

  submit(event: Event) {
    event.preventDefault()
    const data = Serialize(event.target, {
      hash: true,
    })
    this.setState({
      working: true,
    })
    this.props.dispatch(
      ApplePayActions.createKeys(data.merchant_id, () => {
        this.setState({
          working: false,
        })
        this.props.dispatch(ProjectActions.fetchProjectDetails(this.props.params.project))
      }),
    )
  }

  render() {
    let button

    if (this.state.working) {
      button = (
        <a
          className="button border small round"
          style={{
            minWidth: "60px",
          }}
          onClick={function (event: Event) {
            event.preventDefault()
          }}
        >
          <div className="loading-spinner" />
        </a>
      )
    } else {
      button = <input type="submit" className="button border small round" value="Continue" />
    }

    return (
      <ContentLayout title={<h4>Apple Pay setup</h4>}>
        <div className="row">
          <div className="large-10 columns large-centered">
            <div className="row">
              <div className="medium-6 columns">
                <p>
                  To start accepting payments using Apple Pay you need to register for a merchant ID{" "}
                  <a
                    href="https://developer.apple.com/account/ios/identifier/merchant/create"
                    className="bold"
                  >
                    {" "}
                    here↗
                  </a>
                  .
                </p>
              </div>
              <div className="medium-5 medium-offset-1 columns">
                <form onSubmit={this.submit.bind(this)}>
                  <div className="row">
                    <div className="large-12 columns">
                      <label>Merchant ID</label>
                      <input type="text" name="merchant_id" placeholder="Merchant ID" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">{button}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}
type Props = {
  currentProject: {
    fetched: boolean
    fetching: boolean
    project?: {
      id: string
    }
  }
  params: {
    project: string
  }
  dispatch: any | (() => void)
}
type State = {
  working: boolean
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(Start)
