import * as ProcessOut from "../util/ProcessOut"
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS"
export function loadCustomerDetails(id, filter) {
  if (!filter) {
    filter = ""
  }

  return {
    type: FETCH_CUSTOMER_DETAILS,
    payload: ProcessOut.APIcallPromise(`/customers/${id}?filter=${filter}`, "GET", null),
  }
}
export const FETCH_CUSTOMER_TRANSACTIONS = "FETCH_CUSTOMER_TRANSACTIONS"
export const FETCH_CUSTOMER_TRANSACTIONS_NORELOAD = "FETCH_CUSTOMER_TRANSACTIONS_NORELOAD"
export function loadCustomerTransactions(filter, length, customerId, after, id) {
  if (!filter) {
    filter = ""
  }

  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: filter !== "" ? FETCH_CUSTOMER_TRANSACTIONS_NORELOAD : FETCH_CUSTOMER_TRANSACTIONS,
    payload: ProcessOut.APIcallPromise(
      `/customers/${customerId}/transactions?order=desc&${bound}=${id}&limit=${length}&expand=gateway_configuration.gateway&filter=${filter}`,
      "GET",
    ),
  }
}
export const FETCH_CUSTOMER_TOKENS = "FETCH_CUSTOMER_TOKENS"
export function fetchTokens(customer) {
  return {
    type: FETCH_CUSTOMER_TOKENS,
    payload: ProcessOut.APIcallPromise(
      `/customers/${customer}/tokens?expand[]=gateway_configuration.gateway&expand[]=card`,
      "GET",
    ),
  }
}
export const ADD_CUSTOMER_TOKEN = "ADD_CUSTOMER_TOKEN"
export function addToken(customer, token, resolve) {
  return function (dispatch) {
    return {
      type: ADD_CUSTOMER_TOKEN,
      payload: ProcessOut.APIcallPromise(`/customers/${customer}/tokens`, "POST", {
        source: token,
      }).then(response => {
        if ((response as any).data.success) {
          dispatch(fetchTokens(customer))
          resolve()
        }

        return response
      }),
    }
  }
}
export const DELETE_CUSTOMER_TOKEN = "DELETE_CUSTOMER_TOKEN"
export function deleteToken(customer, token, resolve) {
  return dispatch => ({
    type: DELETE_CUSTOMER_TOKEN,
    payload: ProcessOut.APIcallPromise(`/customers/${customer}/tokens/${token}`, "DELETE").then(
      response => {
        if ((response as any).data.success) {
          dispatch(fetchTokens(customer))
          resolve(true)
        } else {
          resolve(false)
        }

        return response
      },
    ),
  })
}
