import React, { useContext } from "react"
import Card from "./Card"
import { GatewayContext } from "../GatewayContext"
import { GatewayInformation } from "^/vNext/@types/types"
import { RoutingContext } from "../RoutingContext"
import clsx from "clsx"
import { Pill } from "./Pill"
import { isValidGateway } from "../utils"

export type Props = {
  authorizations: GatewayInformation[]
  onGatewaySelection: (auth: GatewayInformation) => void
}

/**
 * The container for authorization steps: one authorization step and others are fallbacks, maximum three steps.
 */
export const Authorization = ({ authorizations, onGatewaySelection }: Props) => {
  const { gatewayNames, gatewayConfigurations } = useContext(GatewayContext)
  const [state] = useContext(RoutingContext)

  const elements = authorizations.map((auth, idx) => {
    const gatewayName = auth.gateway === "processout" ? "Smart routing" : gatewayNames[auth.gateway]

    const isGatewayInvalid = !isValidGateway(auth, gatewayConfigurations)

    const name = idx ? "Fallback" : "Authorize"
    const tooltip = `${name} ${gatewayName}`
    return (
      <Card
        cardId={auth.id}
        className={clsx("gateway", {
          "smart-routing": auth.gateway === "processout",
          invalid: isGatewayInvalid,
        })}
        selected={state.selectedCardId === auth.id}
        key={auth.id}
        name={name}
        onClick={() => onGatewaySelection(auth)}
        tooltip={tooltip}
      >
        <Pill key={auth.id}>{gatewayName}</Pill>
      </Card>
    )
  })
  return <>{elements}</>
}
