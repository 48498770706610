import { CLOSE_MODAL } from "^/util/Types"
import React from "react"
import { connect } from "react-redux"

const CancelButton = ({ rejectCallback, dispatch }) => (
  <a
    onClick={() => {
      dispatch({
        type: CLOSE_MODAL,
      })
      rejectCallback()
    }}
    className="round border small button"
  >
    cancel
  </a>
)

export default connect()(CancelButton)
