import React from "react"
import { IndexRedirect, IndexRoute, Route } from "react-router"
import { store } from "../stores/Store"
import Layout from "../pages/Layout"
import Customers from "../pages/Customers"
import CustomerDetails from "../pages/CustomerDetails"
import Transactions from "../pages/Transactions"
import TransactionsDetails from "../pages/TransactionsDetails"
import Invoices from "../pages/Invoices"
import InvoiceEdit from "../pages/InvoiceEdit"
import Events from "../pages/Events"
import EventsDetails from "../pages/EventsDetails"
import Login from "../pages/Login"
import Plans from "../pages/Plans"
import Reminder from "../pages/Reminder"
import Projects from "../pages/Projects"
import User from "../pages/User"
import Error from "../pages/Error"
import NotFound from "../pages/NotFound"
import UserUpdate from "../pages/UserUpdate"
import LayoutNoSideBar from "../pages/LayoutNoSideBar"
import AppLayout from "../pages/AppLayout"
import Coupons from "../pages/Coupons"
import Subscriptions from "../pages/Subscriptions"
import SubscriptionDetails from "../pages/SubscriptionDetails"
import Vault from "../pages/Vault"
import * as ProcessOut from "./ProcessOut"
import * as ProjectsActions from "../Actions/ProjectsActions"
import SettingsContainer from "../containers/SettingsContainer"
import GeneralSettings from "../components/Settings/GeneralSettings"
import TeamSettings from "../components/Settings/TeamSettings"
import DunningSettings from "../components/Settings/DunningSettings"
import ApplePaySettings from "../components/Settings/ApplePay/ApplePaySettings"
import Start from "../components/Settings/ApplePay/Start"
import SecondStep from "../components/Settings/ApplePay/SecondStep"
import ApplePayOverview from "../components/Settings/ApplePay/ApplePayOverview"
import DomainNameFlow from "../components/Settings/ApplePay/DomainNameFlow"
import BoardsContainer from "../features/analytics/Boards/BoardsContainer"
import Board from "../features/analytics/Boards/Board/Board"
import IndexBoard from "../features/analytics/Boards/IndexBoard"
import BoardFetcher from "../features/analytics/Boards/BoardFetcher"
import RoutingRulesEditor from "../features/RoutingRules/RoutingRulesEditor/RoutingRulesEditor"
import ApiSetup from "../features/ApiSetup/ApiSetup"
import DataExplorer, { DataExplorerBuilder } from "../features/analytics/DataExplorer/DataExplorer"
import ChartBuilder from "../features/analytics/DataExplorer/ChartBuilder/ChartBuilder"
import Editor from "../features/analytics/DataExplorer/Editor/Editor"
import BoardCreator from "../features/analytics/BoardCreator/BoardCreator"
import { CHANGE_SANDBOX_STATE } from "../features/SandboxSwitch/consts"
import Reports from "../features/Reports/Reports"
import Payouts from "../features/Payouts/Payouts"
import PayoutDetails from "../features/Payouts/PayoutDetails"
import AnalyticsContainer from "../features/analytics/AnalyticsContainer"
import PaymentProviders from "../features/PaymentProviders/PaymentProviders"
import FraudServices from "../components/FraudServices/FraudServices"
import TransactionsExports from "../features/Exports/Exports"
import AlertingHome from "../features/Alerts/AlertingHome"
import EventsHome from "../features/Alerts/Events/EventsHome"
import AlertBuilder from "../features/Alerts/AlertBuilder/AlertBuilder"
import Diagnostics from "../features/TelescopeV2/Diagnostics/Diagnostics"
import Overview from "../features/TelescopeV2/Overview/Overview"
import Authorization from "../features/TelescopeV2/Authorization/Authorization"
import DomainsSecure from "../features/TelescopeV2/DomainsSecure/DomainsSecure"
import CaptureAndVoids from "../features/TelescopeV2/CaptureAndVoids/CaptureAndVoids"
import Refunds from "../features/TelescopeV2/Refunds/Refunds"
import Fees from "../features/TelescopeV2/Fees/Fees"
import ProjectWizard from "^/features/ProjectWizard/ProjectWizard"
import RequireAdminRights from "^/features/RequireAdminRights/RequireAdminRights"
import ConnectedRequireFeatureFlag from "^/features/RequireFeatureFlag/RequireFeatureFlag"
import TelescopeWrapper from "^/features/TelescopeOnboarding/TelescopeWrapper"
import { ROUTING_RULES_MODES } from "../features/RoutingRules/consts"
import RulesIndexV2 from "../features/RoutingRules/RulesIndexV2"
import RoutingRulesBuilderV2 from "../features/RoutingRules/RoutingRulesBuilder/RoutingRulesBuilderV2"
import RulesIndex from "../features/RoutingRules/RulesIndex"
import RoutingRulesBuilder from "../features/RoutingRules/RoutingRulesBuilder/RoutingRulesBuilder"
import persistedDataHandler from "../vNext/persistedDataHandler"

export const OnAuthProjectsRedirect = function (nextState, replace) {
  if (new RegExp(/^#/).test(nextState.location?.hash)) {
    replace(nextState.location.hash.replace("#", ""))
  }

  const state = store.getState()

  if (state.user.auth.loggedIn) {
    const redirectUrl = nextState.location?.query?.redirectUrl

    if (redirectUrl) {
      const url = new URL(redirectUrl)
      window.location.replace(url)
    } else {
      replace("/projects")
    }
  }
}
export const RequireAuthOnChange = function (prevState, nextState, replace) {
  const state = store.getState()

  if (!state.user.auth.loggedIn) {
    replace("/login")
  }
}
export const RequireAuthOnEnter = function (nextState, replace) {
  const state = store.getState()

  if (!state.user.auth.loggedIn) {
    replace("/login")
  }
}
export const SetProject = function (nextState, replace) {
  const state = store.getState()

  if (!state.user.auth.loggedIn) {
    replace("/login")
  } else {
    persistedDataHandler.setProjectId(nextState.params.project)
    ProcessOut.config.ProjectId = nextState.params.project
    const sandbox = nextState.params.project.includes("test-")
    store.dispatch({
      type: CHANGE_SANDBOX_STATE,
      payload: {
        active: sandbox,
      },
    })
    ProcessOut.config.sandbox = sandbox
    store.dispatch(ProjectsActions.setProjectId(nextState.params.project))
  }
}

const GetProjectId = () => {
  return store.getState().projects.current_project_id
}

const Setup = function (nextState, replace) {
  // Check if we are using the old history system
  if (new RegExp(/^#/).test(nextState.location.hash)) {
    replace(nextState.location.hash.replace("#", ""))
  }

  const processoutJS = document.createElement("script")
  processoutJS.src = `https://js.processout${
    process.env.API_HOST.split("https://api.processout")[1]
  }/processout.js`
  processoutJS.type = "text/javascript"

  processoutJS.onload = function () {
    store.dispatch({
      type: "PROCESSOUT_JS_LOADED",
    })
  }

  document.body.appendChild(processoutJS)
}

const getTelescopeRoute = (path, Component, v2 = true) => (
  <Route
    path={path}
    component={props => (
      <ConnectedRequireFeatureFlag
        flagRequired="dashboard-telescope-v2"
        flagRequiredValue={v2}
        redirectPath={`/projects/${GetProjectId()}/${v2 ? "telescope" : "overview"}`}
      >
        <div className={`${v2 ? "telescope-V2" : ""}`}>
          <Component {...props} />
        </div>
      </ConnectedRequireFeatureFlag>
    )}
  />
)

export const Routes = (
  <div id="routes">
    <Route path="/reminder" component={Reminder} onEnter={OnAuthProjectsRedirect} />
    <Route path="*" component={Login} onEnter={OnAuthProjectsRedirect} />
  </div>
)

export const _Routes = (
  <div id="routes">
    <Route path="/" component={AppLayout} onEnter={Setup}>
      <IndexRedirect to="/login" />
      <Route
        path="/projects/:project/"
        component={Layout}
        onEnter={SetProject}
        onChange={RequireAuthOnChange}
      >
        <IndexRedirect to="telescope" />
        {getTelescopeRoute("telescope", TelescopeWrapper, false)}
        {getTelescopeRoute("overview", Overview)}
        {getTelescopeRoute("authorization", Authorization)}
        {getTelescopeRoute("domains-secure", DomainsSecure)}
        {getTelescopeRoute("capture-voids", CaptureAndVoids)}
        {getTelescopeRoute("refunds", Refunds)}
        {getTelescopeRoute("fees", Fees)}
        {/* action taken from this task: https://checkout.atlassian.net/browse/POF-781 */}
        {/* {getTelescopeRoute("chargebacks", Chargebacks)} */}
        {getTelescopeRoute("anomaly-detection", Diagnostics)}
        <Route path="alerting">
          <IndexRedirect to="alerts" />
          <Route path="alerts">
            <IndexRoute component={AlertingHome} />
            <Route path="create" component={AlertBuilder} />
          </Route>
          <Route path="events" component={EventsHome} />
        </Route>
        <Route path="boards" component={AnalyticsContainer}>
          <Route component={BoardsContainer}>
            <IndexRoute component={IndexBoard} />
            <Route path="new" component={BoardCreator} />
            <Route path="data-explorer" component={DataExplorer}>
              <IndexRedirect to="chart-builder" />
              <Route path="chart-builder" component={ChartBuilder} />
              <Route path={ROUTING_RULES_MODES.EDITOR} component={Editor} />
            </Route>
            <Route path=":board" component={BoardFetcher}>
              <IndexRoute component={Board} />
            </Route>
          </Route>
          <Route path=":board" component={BoardFetcher}>
            <Route path="new-chart" component={DataExplorerBuilder}>
              <IndexRedirect to="chart-builder" />
              <Route path="chart-builder" component={ChartBuilder} />
              <Route path="editor" component={Editor} />
            </Route>
          </Route>
        </Route>
        <Route path="recurring/">
          <Route path="subscriptions" component={Subscriptions} />
          <Route path="subscriptions/:subscription" component={SubscriptionDetails} />
          <Route path="plans" component={Plans} />
          <Route path="coupons" component={Coupons} />
        </Route>
        <Route path="router" component={RulesIndex}>
          <IndexRoute component={RoutingRulesBuilder} />
          <Route
            path="editor"
            component={() => (
              <RequireAdminRights redirectPath={`/projects/${GetProjectId()}/router`}>
                <RoutingRulesEditor />
              </RequireAdminRights>
            )}
          />
        </Route>
        <Route
          path="router-beta"
          component={props => {
            return (
              <ConnectedRequireFeatureFlag
                flagRequired="dashboard-routing-rules-v2"
                flagRequiredValue={true}
                redirectPath={`/projects/${GetProjectId()}/router`}
              >
                <RulesIndexV2 {...props} />
              </ConnectedRequireFeatureFlag>
            )
          }}
        >
          <IndexRoute component={RoutingRulesBuilderV2} />
          <Route
            path="editor"
            component={() => (
              <RequireAdminRights redirectPath={`/projects/${GetProjectId()}/router-beta`}>
                <RoutingRulesEditor />
              </RequireAdminRights>
            )}
          />
        </Route>
        <Route path="settings" component={SettingsContainer}>
          <Route path="general" component={GeneralSettings} />
          <Route path="team" component={TeamSettings} />
          <Route path="dunning" component={DunningSettings} />
          <Route path="apple-pay" component={ApplePaySettings}>
            <IndexRoute component={ApplePayOverview} />
            <Route path="step-1" component={Start} />
            <Route path="step-2" component={SecondStep} />
            <Route path="domains" component={DomainNameFlow} />
          </Route>
        </Route>
        <Route path="customers" component={Customers} />
        <Route path="customers/:customer" component={CustomerDetails} />
        <Route path="customers/create" component={CustomerDetails} />
        <Route path="customers/:customer/recurring-invoices/:invoice" component={InvoiceEdit} />
        <Route path="transactions" component={Transactions} />
        <Route path="transactions/:transaction" component={TransactionsDetails} />
        <Route path="gateways" component={PaymentProviders} />
        <Route path="fraud-services" component={FraudServices} />
        <Route path="products" component={Invoices} />
        <Route path="vault" component={Vault} />
        <Route path="developer/">
          <Route path="api-setup" component={ApiSetup} />
          <Route path="events" component={Events} />
          <Route path="events/:event" component={EventsDetails} />
        </Route>
        <Route path="reports">
          <IndexRedirect to="payouts" />
          <Route path="uploads" component={Reports} />
          <Route path="payouts" component={Payouts} />
          <Route path="payouts/:payout" component={PayoutDetails} />
          <Route path="exports" component={TransactionsExports} />
        </Route>
      </Route>
      <Route
        onEnter={RequireAuthOnEnter}
        onChange={RequireAuthOnChange}
        component={LayoutNoSideBar}
      >
        <Route path="/user" component={User} />
        <Route path="/user/update" component={UserUpdate} />
        <Route path="/projects" component={Projects} />
        <Route
          path="/projects/create"
          component={() => (
            <RequireAdminRights redirectPath="/projects">
              <ProjectWizard />
            </RequireAdminRights>
          )}
        />
        <Route path="/error" component={Error} />
        <Route path="/404" component={NotFound} />
      </Route>
      <Route path="/login" component={Login} onEnter={OnAuthProjectsRedirect} />
      <Route path="/reminder" component={Reminder} onEnter={OnAuthProjectsRedirect} />
      <Route
        path="/register"
        component={() => {
          window.location.href = "https://processout.com/contact-us/"
          return null
        }}
      />
    </Route>
    <Route path="*" component={Login} onEnter={OnAuthProjectsRedirect} />
  </div>
)
