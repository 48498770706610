import React from "react"
import { connect } from "react-redux"
import type { $RoutingRule, $RoutingRulesSettings } from "../consts"
import TagsListing from "./TagsListing"
import * as Actions from "../actions"
import type { $Dispatcher } from "../../../util/Types"
import { segmentAnalytics } from "^/vNext/Segment"

type Props = {
  rule: $RoutingRule
  children?: any | null | undefined
  collapse: () => void
  routingRules: $RoutingRulesSettings
} & $Dispatcher
export class RuleContainer extends React.Component<Props> {
  tagsChanged = (newTags: Array<string>) => {
    const { rule, dispatch } = this.props
    const newRule = { ...rule, tags: newTags }
    dispatch(Actions.updateRule(rule.id, newRule))
  }

  render() {
    const { rule, routingRules, dispatch, children, collapse } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns ">
          <div
            className={`row small-margin-bottom routing-rule-row ${
              rule.declaration === "trigger_3ds"
                ? "trigger_3ds"
                : rule.declaration === "block"
                ? "block"
                : rule.declaration === "dynamic_3ds"
                ? "trigger_3ds"
                : "route"
            }`}
          >
            <div className="medium-12 columns">
              <div className="row margin-bottom">
                <div className="medium-8 columns">
                  <TagsListing tags={rule.tags} onChange={this.tagsChanged} />
                </div>
                <div
                  className="medium-4 columns text-right"
                  style={{
                    position: "relative",
                    top: ".5em",
                  }}
                >
                  <a
                    className="greyed"
                    style={{
                      marginRight: "1em",
                    }}
                    onClick={collapse}
                  >
                    Collapse
                  </a>

                  {(rule.declaration !== "route" ||
                    routingRules.rules.filter(rule => rule.declaration === "route").length > 1) && (
                    <a
                      data-auto-tracking="true"
                      data-tracking-label="Remove Rule"
                      className="greyed"
                      onClick={() => {
                        dispatch(Actions.removeRule(rule.id))
                      }}
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <img
                        alt="Trash icon"
                        src="//dashboard.processout.com/images/delete.png"
                        style={{
                          height: "20px",
                          display: "inline-block",
                          marginRight: ".5em",
                          opacity: 0.4,
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                        }}
                      >
                        remove rule
                      </span>
                    </a>
                  )}
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  routingRules: store.routingRulesSettings,
}))(RuleContainer)
