import { FETCH_TRANSACTIONS } from "^/Actions/TransactionsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_TRANSACTIONS): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_TRANSACTIONS): {
      if (!action.payload.success) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          total_count: 0,
          has_more: action.payload.has_more,
          transactions: [],
        }
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        has_more: action.payload.has_more,
        total_count: action.payload.total_count,
        transactions: action.payload.transactions,
      }
    }

    case typeFailed(FETCH_TRANSACTIONS): {
      return { ...state, error: action.payload, fetching: false, fetched: false }
    }
  }

  return state
}
