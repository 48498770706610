import * as React from "react"
import "./Tooltip.scss"
import { ReactComponent as TooltipArrow } from "./tooltip-arrow.svg"

export type Props = {
  position: { x: number; y: number }
  children: React.ReactNode
}

export const Tooltip: React.FC<Props> = ({ position, children }) => {
  return (
    <div
      className="routing-rule-tooltip"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    >
      <div className="tooltip-content">
        {children}
        <TooltipArrow />
      </div>
    </div>
  )
}
