import { put, select, takeEvery, takeLatest } from "redux-saga/effects"
import type { $ApiKeyModel } from "./consts"
import {
  CREATE_NEW_PRIVATE_KEY,
  DELETE_PRIVATE_KEY,
  PREPARE_API_SETUP,
  SAVE_PRIVATE_KEY_UPDATE,
} from "./consts"
import * as ProcessOut from "../../util/ProcessOut"
import * as Actions from "./actions"
import type { $Action } from "../../util/Types"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export function* prepareApiSetup(): Generator<any, any, any> {
  try {
    // Retrieve the list of current api-keys
    const apiKeysResult = yield ProcessOut.APIcallPromise("/api-keys", "GET")
    const apiKeys: Array<$ApiKeyModel> = apiKeysResult.data.api_keys
    let project = yield select<any>(store => store.currentProject)

    while (!project.fetched || project.fetching) {
      project = yield select<any>(store => store.currentProject)
    }

    yield put({
      type: typeFulfilled(PREPARE_API_SETUP),
      payload: {
        apiKeys,
        publicKey: project.project.id,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREPARE_API_SETUP),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}
export function* newPrivateKey(action: $Action): Generator<any, any, any> {
  try {
    const { name } = action.payload
    const result = yield ProcessOut.APIcallPromise("/api-keys", "POST", {
      name,
    })
    // Call completion callback
    action.payload.callback(result.data.api_key.private_key)
    // Reload the keys list
    yield put(Actions.requestApiSetupPreparation())
  } catch (e) {
    yield put({
      type: typeFailed(CREATE_NEW_PRIVATE_KEY),
      payload: e,
    })
    action.payload.onError()
    datadogRum.addError(e)
  }
}
export function* deletePrivateKey(action: $Action): Generator<any, any, any> {
  try {
    yield ProcessOut.APIcallPromise(`/api-keys/${action.payload.name}`, "DELETE")
    // Call completion callback
    action.payload.callback()
    // Reload the keys list
    yield put(Actions.requestApiSetupPreparation())
  } catch (error) {
    yield put({
      type: typeFailed(DELETE_PRIVATE_KEY),
      payload: error,
    })
    datadogRum.addError(error)
  }
}
export function* updatePrivateKey(action: $Action): Generator<any, any, any> {
  try {
    const key: $ApiKeyModel = action.payload.privateKey
    yield ProcessOut.APIcallPromise(`/api-keys/${key.name}`, "PUT", key)
    // Call completion callback
    action.payload.callback()
    // Reload the keys list
    yield put(Actions.requestApiSetupPreparation())
  } catch (error) {
    yield put({
      type: typeFailed(SAVE_PRIVATE_KEY_UPDATE),
      payload: error,
    })
    datadogRum.addError(error)
  }
}
export default function* watchForApiSetupSagas(): Generator<any, any, any> {
  yield takeLatest(PREPARE_API_SETUP, prepareApiSetup)
  yield takeLatest(CREATE_NEW_PRIVATE_KEY, newPrivateKey)
  yield takeEvery(DELETE_PRIVATE_KEY, deletePrivateKey)
  yield takeLatest(SAVE_PRIVATE_KEY_UPDATE, updatePrivateKey)
}
