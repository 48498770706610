import React from "react"
import { FormattedNumber } from "react-intl"
import ReactCSSTransitionReplace from "react-css-transition-replace"
import { connect } from "react-redux"
import { Link } from "react-router"
import Authorizations from "./Partials/Authorizations"
import Captures from "./Partials/Captures"
import AuthorizationsContent from "./Partials/AuthorizationsContent"
import type { $TelescopeState } from "../../stores/Reducers/TelescopeReducer/Index"
import CapturesContent from "./Partials/CapturesContent"
import Refunds from "./Partials/Refunds"
import RefundsContent from "./Partials/RefundsContent"
import Chargebacks from "./Partials/Chargebacks"
import Warning from "./Components/Warning"
import type { $Dispatcher, $Project } from "../../util/Types"
import LoadingIndicator from "./Components/LoadingIndicator"
import * as TelescopeActions from "./actions"
import { UPDATE_TELESCOPE_TAB } from "./consts"
import ChargebacksContent from "./Partials/ChargebacksContent"
import ChargebackHeader from "./Partials/ChargebackHeader"
type $SelectionName = $SelectionName
type Props = {
  telescope: $TelescopeState
  currentProject: {
    project: $Project
  }
} & $Dispatcher

class TransactionsTab extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(
      TelescopeActions.requestTelescopePrepare(
        this.props.telescope.standard.timespan,
        false,
        this.props.telescope.standard.selectedTab,
      ),
    )
  }

  tabSelected = (name: $SelectionName) => {
    let shouldFetch = false

    switch (name) {
      case "auth": {
        if (!this.props.telescope.standard.authorizationReady) shouldFetch = true
        break
      }

      case "capt": {
        if (!this.props.telescope.standard.capturesReady) shouldFetch = true
        break
      }

      case "refu": {
        if (!this.props.telescope.standard.refundsReady) shouldFetch = true
        break
      }

      case "char": {
        if (!this.props.telescope.standard.chargebacksReady) shouldFetch = true
        break
      }
    }

    this.props.dispatch({
      type: UPDATE_TELESCOPE_TAB,
      payload: {
        tab: name,
      },
    })

    if (shouldFetch) {
      this.props.dispatch(
        TelescopeActions.requestTelescopePrepare(
          this.props.telescope.standard.timespan,
          true,
          name,
        ),
      )
    }
  }

  render() {
    const ready =
      this.props.telescope.standard.selectedTab === "auth"
        ? this.props.telescope.standard.authorizationReady
        : this.props.telescope.standard.selectedTab === "capt"
        ? this.props.telescope.standard.capturesReady
        : this.props.telescope.standard.selectedTab === "refu"
        ? this.props.telescope.standard.refundsReady
        : this.props.telescope.standard.selectedTab === "char"
        ? this.props.telescope.standard.chargebacksReady
        : true
    return (
      <div>
        {!ready ? <LoadingIndicator /> : null}
        <div className="row">
          <div className="large-12 columns text-center">
            <div className={`telescope ${ready ? "fetched" : "fetching"}`}>
              <div className="row">
                <div className="large-12 columns">
                  <div className="row header-tabs">
                    <div className="large-12 columns no-padding">
                      <Tab
                        name="Authorizations"
                        content={
                          <div className="legend">
                            <span className="greyed">Global auth. rate</span>
                            {!isNaN(
                              this.props.telescope.standard.data.global_authorization_rate,
                            ) ? (
                              <FormattedNumber
                                value={this.props.telescope.standard.data.global_authorization_rate}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                style="percent"
                              />
                            ) : (
                              <span>
                                {this.props.telescope.standard.data.global_authorization_rate ||
                                !this.props.telescope.standard.authorizationReady ? null : (
                                  <Warning text="Data could not be fetched" />
                                )}
                                —%
                              </span>
                            )}
                          </div>
                        }
                        selected={this.props.telescope.standard.selectedTab}
                        selectionName="auth"
                        onClick={this.tabSelected}
                        index={9}
                      />
                      <Tab
                        name="Captures & Fees"
                        content={
                          <div className="legend">
                            <span className="greyed">Total fees</span>
                            {!isNaN(this.props.telescope.standard.data.global_fees_percent) ? (
                              <FormattedNumber
                                value={this.props.telescope.standard.data.global_fees_percent}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                style="percent"
                              />
                            ) : (
                              <span>
                                {this.props.telescope.standard.data.global_fees_percent ||
                                !this.props.telescope.standard.capturesReady ? null : (
                                  <Warning text="Data could not be fetched" />
                                )}
                                —%
                              </span>
                            )}
                          </div>
                        }
                        selected={this.props.telescope.standard.selectedTab}
                        selectionName="capt"
                        onClick={this.tabSelected}
                        index={7}
                      />
                      <Tab
                        name="Refunds & Voids"
                        content={
                          <div className="legend">
                            <span className="greyed">Refund rate</span>
                            {!isNaN(this.props.telescope.standard.data.global_refund_percent) ? (
                              <FormattedNumber
                                value={this.props.telescope.standard.data.global_refund_percent}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                style="percent"
                              />
                            ) : (
                              <span>
                                {this.props.telescope.standard.data.global_refund_percent ||
                                !this.props.telescope.standard.refundsReady ? null : (
                                  <Warning text="Data could not be fetched" />
                                )}
                                —%
                              </span>
                            )}
                          </div>
                        }
                        selected={this.props.telescope.standard.selectedTab}
                        selectionName="refu"
                        onClick={this.tabSelected}
                        index={5}
                      />
                      <Tab
                        name={
                          <div>
                            Chargebacks{" "}
                            <div
                              className="recommendations-new-tag"
                              style={{
                                top: "-.15em",
                              }}
                            >
                              NEW
                            </div>
                          </div>
                        }
                        content={
                          <div className="legend">
                            <span className="greyed">Chargeback rate </span>
                            {!isNaN(this.props.telescope.standard.data.global_chargeback_rate) ? (
                              <FormattedNumber
                                value={this.props.telescope.standard.data.global_chargeback_rate}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                                style="percent"
                              />
                            ) : (
                              <span>
                                {this.props.telescope.standard.data.global_chargeback_rate ||
                                !this.props.telescope.standard.chargebacksReady ? null : (
                                  <Warning text="Data could not be fetched" />
                                )}
                                0%
                              </span>
                            )}
                          </div>
                        }
                        selected={this.props.telescope.standard.selectedTab}
                        selectionName="char"
                        onClick={this.tabSelected}
                        last={false}
                        index={3}
                      />
                      <Link to={`/projects/${this.props.currentProject.project.id}/boards`}>
                        <div
                          className="header-tab last"
                          style={{
                            width: "12%",
                          }}
                        >
                          <div
                            className="content"
                            style={{
                              zIndex: 2,
                              textAlign: "center",
                              paddingTop: 0,
                            }}
                          >
                            <div
                              className="tab-name text-right"
                              style={{
                                lineHeight: "70px",
                                verticalAlign: "middle",
                                paddingRight: "1em",
                              }}
                            >
                              In depth →
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overview-container">
                <div className="row">
                  <div className="large-12 columns">
                    <div className="row">
                      <div className="large-12 columns">
                        <div className="kpi-container">
                          {this.props.telescope.standard.selectedTab === "char" && (
                            <ChargebackHeader />
                          )}
                          <ReactCSSTransitionReplace
                            transitionName="kpi-container-fade"
                            transitionLeaveTimeout={175}
                            transitionEnterTimeout={350}
                          >
                            {(() => {
                              switch (this.props.telescope.standard.selectedTab) {
                                case "auth": {
                                  return <Authorizations key="auth" />
                                }

                                case "capt": {
                                  return <Captures key="capt" />
                                }

                                case "refu": {
                                  return <Refunds key="refu" />
                                }

                                case "char": {
                                  return <Chargebacks key="char" />
                                }
                              }
                            })()}
                          </ReactCSSTransitionReplace>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <div
                          className="content-container" // We set the height to 0 if chargeback
                          style={{
                            padding:
                              this.props.telescope.standard.selectedTab === "char" ? 0 : null,
                          }}
                        >
                          <ReactCSSTransitionReplace
                            transitionName="kpi-container-fade"
                            transitionLeaveTimeout={175}
                            transitionEnterTimeout={350}
                            component="div"
                          >
                            {(() => {
                              switch (this.props.telescope.standard.selectedTab) {
                                case "auth": {
                                  return <AuthorizationsContent key="auth" />
                                }

                                case "capt": {
                                  return <CapturesContent key="capt" />
                                }

                                case "refu": {
                                  return <RefundsContent key="refu" />
                                }

                                case "char": {
                                  return <ChargebacksContent key="char" />
                                }
                              }
                            })()}
                          </ReactCSSTransitionReplace>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Tab extends React.Component<{
  selected: $SelectionName
  selectionName: $SelectionName
  name: string
  content: any
  onClick: (arg0: $SelectionName) => any
  index: number
}> {
  render() {
    return (
      <a
        onClick={() => {
          this.props.onClick(this.props.selectionName)
        }}
        className={`header-tab ${
          this.props.selected === this.props.selectionName ? "selected" : ""
        }`}
      >
        <div
          className="content"
          style={{
            zIndex: this.props.index + 1,
          }}
        >
          <div className="tab-name">{this.props.name}</div>
          <div className="tab-content">{this.props.content || " "}</div>
        </div>
      </a>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  currentProject: store.currentProject,
}))(TransactionsTab)
