import * as React from "react"
import { Tab } from "./Tab"

export type Props = {
  defaultSelectedIndex: number
  tabLabels: string[]
  onSelection: (idx: number) => void
}

export const TabGroup = ({ defaultSelectedIndex, tabLabels, onSelection }: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultSelectedIndex)
  return (
    <div data-tracking-location="Tab group" className="tab-group" data-testid="tab-group">
      {tabLabels.map((label, idx) => (
        <Tab
          selected={selectedIndex === idx}
          key={label}
          onClick={() => {
            setSelectedIndex(idx)
            onSelection(idx)
          }}
        >
          {label}
        </Tab>
      ))}
    </div>
  )
}
