import CountryData from "country-data"
import CurrencyCodes from "currency-codes"
import type { $DataPoint } from "../consts"
import { $FetchParams, BAR_CHART, SINGLE_VALUE } from "../consts"
import moment from "moment/moment"

export const replaceForGwayKeys = (
  data: Array<$DataPoint>,
  configurations_names: any,
): Array<$DataPoint> => {
  let configCount = 0

  for (const entry of data) {
    if (`${entry.key}`.includes("gway_conf")) configCount++
  }

  // If 80%+ of configs
  if (configCount <= data.length * 0.8) return data
  return data.slice().map(entry => ({
    ...entry,
    key: configurations_names[entry.key] || entry.key,
    _original_key: (entry as any)._original_key || entry.key,
  }))
}

export const replaceForCountries = (data: Array<$DataPoint>): Array<$DataPoint> => {
  let countryCount = 0

  for (const entry of data) {
    if (CountryData.countries[entry.key]) countryCount++
  }

  // If 80%+ of countries
  if (countryCount <= data.length * 0.9) return data
  return data.slice().map(entry => ({
    ...entry,
    _original_key: (entry as any)._original_key || entry.key,
    key: CountryData.countries[entry.key] ? CountryData.countries[entry.key].name : entry.key,
  }))
}

export const replaceForCurrencies = (data: Array<$DataPoint>): Array<$DataPoint> => {
  let currencyCount = 0

  for (const entry of data) {
    if (CurrencyCodes.code(`${entry.key}`)) currencyCount++
  }

  // If 80%+ of currencies
  if (currencyCount < data.length * 0.8) return data
  return data.slice().map(entry => ({
    ...entry,
    key: CurrencyCodes.code(`${entry.key}`)
      ? CurrencyCodes.code(`${entry.key}`).currency
      : entry.key,
    _original_key: (entry as any)._original_key || entry.key,
  }))
}

export const getChart = (
  chartData: any,
  params: $FetchParams,
): { data: any; hadToTrim: boolean; timeCompareAndDimensions: any; is_comparison: boolean } => {
  let hadToTrim = false

  if (chartData.is_comparison && chartData.chart.type === BAR_CHART) {
    // trim data
    const maximumPoints = (100 * chartData.chart.size) / 12
    hadToTrim = chartData.data.length > 10
    chartData.data = chartData.data.slice(0, 10)
    const maximumSubLength = maximumPoints / (chartData.data.length || 1)

    for (const entry of chartData.data) {
      hadToTrim = hadToTrim || entry.datapoints.length > maximumSubLength
      entry.datapoints = entry.datapoints.slice(0, maximumSubLength)
    }
  }

  // Check if we are on a multi dimensional chart
  if (
    chartData.data.length > 0 &&
    typeof chartData.data[0].key === "string" &&
    chartData.data[0].key.includes("AND")
  ) {
    return {
      data: chartData.data,
      hadToTrim,
      timeCompareAndDimensions: params.timeCompare.comparing,
      is_comparison: chartData.is_comparison,
    }
  }

  if (!params.timeCompare.comparing) {
    return {
      data: chartData.data,
      timeCompareAndDimensions: false,
      hadToTrim,
      is_comparison: chartData.is_comparison,
    }
  }

  // Looking for the oldest date
  const periods = chartData.data.map(point => point.key)
  const currentPeriodStartDate = periods.reduce((value, current) => {
    if (moment(current).isAfter(moment(value))) {
      return current
    }

    return value
  }, periods[0])
  const currentPeriodStartDateIndex = periods.findIndex(period => period === currentPeriodStartDate)
  // Retrieving the other one
  const previousPeriodStartDateIndex = periods.findIndex(
    period => period !== currentPeriodStartDate,
  )

  if (chartData.chart.type === SINGLE_VALUE) {
    chartData.data[previousPeriodStartDateIndex] = {
      datapoints: [{ ...chartData.data[previousPeriodStartDateIndex] }],
      key: chartData.data[previousPeriodStartDateIndex].key,
    }
    chartData.data[currentPeriodStartDateIndex] = {
      datapoints: [{ ...chartData.data[currentPeriodStartDateIndex] }],
      key: chartData.data[currentPeriodStartDateIndex].key,
    }
  }

  const previousData = replaceDates(
    chartData.data[previousPeriodStartDateIndex],
    chartData.data[currentPeriodStartDateIndex],
  )
  // Constructing tooltips labels
  const previousPeriodLabel = `${moment
    .unix(params.timeCompare.from)
    .format("MMMM Do YYYY")} → ${moment.unix(params.timeCompare.to).format("MMMM Do YYYY")}`
  const currentPeriodLabel = `${moment.unix(params.interval.from).format("MMMM Do YYYY")} → ${moment
    .unix(params.interval.to)
    .format("MMMM Do YYYY")}`

  return {
    data: [
      {
        key: previousPeriodLabel,
        datapoints: previousData,
      },
      {
        key: currentPeriodLabel,
        datapoints: chartData.data[currentPeriodStartDateIndex].datapoints,
      },
    ],
    hadToTrim,
    timeCompareAndDimensions: false,
    is_comparison: true,
  }
}

/**
 * Replaces all the keys of an object to match the current period dates
 * @param data
 * @param currentData
 * @returns {Array}
 */
const replaceDates = (
  data,
  currentData: {
    datapoints: Array<{
      key: string
    }>
    key: string
  },
) => {
  if (data.datapoints) {
    return data.datapoints
      .filter(
        (entry, index) =>
          currentData.datapoints[index] !== null && currentData.datapoints[index] !== undefined,
      )
      .map((dataEntry, index) => {
        if (moment(dataEntry.key).isValid()) {
          // Merging dates
          return { ...dataEntry, key: currentData.datapoints[index].key }
        }

        return dataEntry
      })
  }

  return Object.keys(data.datapoints).map(key => replaceDates(data.datapoints[key], currentData))
}
