import React from "react"
import { Link } from "react-router"
import clsx from "clsx"
import { track } from "../../vNext/tracking"

const MenuLink: React.FC<
  React.PropsWithChildren<{
    url: string
    name: string
    beta: boolean
    externalLink: boolean
    className: string
  }>
> = ({ externalLink, url, name, beta, children, className }) => {
  const onLinkClick = () => {
    track("Navigation", "Click", "Menu", "Item", { value: name, beta: !!beta })
  }

  return externalLink ? (
    <a
      href={url}
      target="_self"
      onClick={onLinkClick}
      data-testid={`external-link-${name}`}
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link
      to={url}
      onClick={onLinkClick}
      data-testid={`react-router-link-${name}`}
      className={className}
    >
      {children}
    </Link>
  )
}

export default class MenuButton extends React.Component {
  getInitialProps() {
    return {
      to: "",
      name: "",
      img: "",
    }
  }

  constructor() {
    super()
  }

  render() {
    const { location, className, launchDarkly } = this.props
    const { name, beta, isNew, externalLink } = this.props
    const regex = new RegExp(`/projects/.+/${this.props.route}$`, "gi")
    let current = regex.test(location.pathname) && !this.props.route === "analytics" ? "active" : ""

    if (this.props.route == "analytics") {
      const regex2 = new RegExp("/projects/.+/$", "gi")
      if (regex2.test(location.pathname) || location.pathname.includes("analytics"))
        current = "active"
      else current = ""
    }

    if (location.pathname.includes(this.props.route) && !location.pathname.includes("analytics")) {
      current = "active"
    }

    // When new dash settings page is enabled, we are moving Apple Pay menu item out from under Settings, so don't want
    // Settings to expand, nor be set as active
    if (
      this.props.route === "settings" &&
      location.pathname.includes("apple-pay") &&
      launchDarkly?.flags["new-dash-settings-page"]
    ) {
      current = ""
    }

    // beta page might have same path chunk so cannot simply check whether pathname includes route name
    // If the button is not beta but the path contains beta then do not activate it.
    const betaRegex = new RegExp(`${this.props.route}.*beta`, "g")
    if (!this.props.beta && betaRegex.test(location.pathname)) {
      current = ""
    }

    return (
      <li className={clsx(current, { beta: this.props.beta, new: this.props.isNew })}>
        <MenuLink
          url={this.props.to}
          name={name}
          beta={beta}
          externalLink={externalLink}
          className={className}
        >
          {this.props.image ? <img src={this.props.image} alt="" className="menu-icon" /> : ""}
          {this.props.richLabel || this.props.name}
          {!this.props.children ||
          this.props.children.filter(child => child !== null).length === 0 ? (
            ""
          ) : (
            <img
              alt="Down arrow icon"
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI1NiAyNTYiIGhlaWdodD0iMjU2cHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiB3aWR0aD0iMjU2cHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0zOC4zOTksNzYuOGMxLjYzNywwLDMuMjc0LDAuNjI1LDQuNTI0LDEuODc1bDg1LjA3NSw4NS4wNzZsODUuMDc1LTg1LjA3NmMyLjUtMi41LDYuNTUtMi41LDkuMDUsMHMyLjUsNi41NSwwLDkuMDUgIGwtODkuNiw4OS42MDFjLTIuNSwyLjUtNi41NTEsMi41LTkuMDUxLDBsLTg5LjYtODkuNjAxYy0yLjUtMi41LTIuNS02LjU1LDAtOS4wNUMzNS4xMjQsNzcuNDI1LDM2Ljc2Miw3Ni44LDM4LjM5OSw3Ni44eiIvPjwvc3ZnPg=="
              className="down-arrow"
            />
          )}
        </MenuLink>
        <ul className="children">{this.props.children}</ul>
      </li>
    )
  }
}
