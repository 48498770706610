import React from "react"
import { FormattedNumber } from "react-intl"

type Props = {
  diagnosticOptimization: number
  currency: string
}

export function MetricsOptimization({
  diagnosticOptimization,
  currency,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <>
      {children}{" "}
      <span className="bold">
        <FormattedNumber value={diagnosticOptimization} style="currency" currency={currency} />
      </span>
    </>
  )
}
export default MetricsOptimization
