import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import InvoiceModal from "./InvoiceModal"
import type { Product } from "../types/Product"
import { track } from "../vNext/tracking"
export class Invoice extends React.Component {
  props: {
    invoice: Product
  }
  state: {
    showDetails: boolean
  }
  _modal: Record<string, any> | null | undefined = null

  constructor() {
    super()
    this.state = {
      showDetails: false,
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  displayInvoice(event: Event) {
    event.preventDefault()
    track("Products", "Click", "List", "Row")
    if (this._modal) this._modal.getWrappedInstance().openModal()
  }

  render() {
    const { invoice } = this.props
    let desc = "No KYC collected during checkout"

    if (invoice.metadata.request_email === "true" && invoice.metadata.request_shipping === "true") {
      desc = "Request email and shipping address during checkout"
    } else if (invoice.metadata.request_email === "true") {
      desc = "Request email during checkout"
    } else if (invoice.metadata.request_shipping === "true") {
      desc = "Request shipping during checkout"
    }

    return (
      <div>
        <InvoiceModal modalName="Edit product" invoice={invoice} ref={m => (this._modal = m)} />
        <div className="box-row row padding">
          <div className="medium-10 columns">
            <a onClick={this.displayInvoice.bind(this)}>
              <div className="row">
                <div className="medium-2 columns">
                  <div className="big-font">
                    <span className="greyed">{invoice.currency}</span>{" "}
                    <FormattedNumber
                      value={invoice.amount}
                      style="currency"
                      currency={invoice.currency}
                    />
                  </div>
                </div>
                <div className="large-5 medium-4 columns">{invoice.name}</div>
                <div className="large-5 medium-6 columns greyed">{desc}</div>
              </div>
            </a>
          </div>
          <div className="medium-2 expanded columns text-right">
            <a
              target="_blank"
              href={invoice.url}
              rel="noreferrer"
              onClick={() => {
                track("Products", "Click", "Payment link", "Payment link button")
              }}
            >
              Payment link ↗
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(Invoice)
