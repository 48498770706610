import type { $Action } from "../../../util/Types"
export const CHANGE_COMPARE_DATE = "CHANGE_COMPARE_DATE"
export const SET_COMPARING = "SET_COMPARING"
export type $Period =
  | "today"
  | "yesterday"
  | "previous"
  | "last-7"
  | "previous-week"
  | "last-30"
  | "previous-month"
  | "last-6-months"
  | "last-year"
  | "previous-year"
  | "custom"
export const PERIODS: Array<{
  value: string
  label: string
}> = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "last-7",
    label: "Last 7 days",
  },
  {
    value: "previous-week",
    label: "Previous week",
  },
  {
    value: "last-30",
    label: "Last 30 days",
  },
  {
    value: "previous-month",
    label: "Previous month",
  },
  {
    value: "last-6-months",
    label: "Last 6 months",
  },
  {
    value: "last-year",
    label: "Last year",
  },
  {
    value: "previous-year",
    label: "Previous year",
  },
  {
    value: "custom",
    label: "Custom period",
  },
]
export const UPDATE_ANALYTICS_DATES = "UPDATE_ANALYTICS_DATES"
export const INIT_ANALYTICS_DATES = "INIT_ANALYTICS_DATES"
export type $UpdateDatesAction = {
  payload: {
    fromDate: any
    toDate: any
  }
} & $Action
