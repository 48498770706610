import React from "react"
type Props = {
  filter: string
}

class PolangItem extends React.Component<Props> {
  render() {
    const { filter } = this.props
    return (
      <div className="row collapse item-resource-row polang">
        <div className="medium-12 columns">
          <div
            style={{
              display: "inline-block",
            }}
          >
            Advanced search for:{" "}
          </div>
          <div className="filter">{filter}</div>
        </div>
      </div>
    )
  }
}

export default PolangItem
