import React from "react"
import type { $ChargebackProgramData } from "../reducer"
type Props = {
  program: $ChargebackProgramData
}

class ChargebackProgramBelonging extends React.Component<Props> {
  render() {
    const { program } = this.props
    return (
      <div className="row margin-bottom">
        <div className="large-11 large-centered columns">
          <div className="row">
            <div className="medium-4 columns bold">Duration:</div>
            <div className="medium-8 columns text-right greyed">
              {program.belong_since ? `${program.belong_since} month(s)` : "—"}
            </div>
          </div>
          <div className="row">
            <div className="medium-2 columns bold">Fines:</div>
            <div className="medium-10 columns text-right greyed">
              {program.fines.length > 0 && program.fines.join(", ")}
              {program.fines.length === 0 && "—"}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChargebackProgramBelonging
