import React from "react"

const InvalidGatewayErrorSection: React.FC<{ invalid: boolean }> = ({ invalid }) => {
  return (
    /* I don't think aria-live='assertive' will work as criteria menu is not always rendered, but I'll keep it here for complete reason */
    <div aria-live="assertive">
      {invalid && (
        <div
          className="error-section"
          style={{
            color: "#7B0F17",
          }}
        >
          This payment provider is disabled or invalid, please choose another provider or enable it
          from the Providers page
        </div>
      )}
    </div>
  )
}

export default InvalidGatewayErrorSection
