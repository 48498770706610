import React from "react"
import { FormattedDate } from "react-intl"
import CardSchemeImage from "../../util/CardSchemeImage"
import CardModal from "../CardModal"
import GatewayConfModal from "../GatewayConfModal"
import type { $Token } from "^/stores/Reducers/CustomerDetailsReducer/TokensReducer"
import { track } from "../../vNext/tracking"
type Props = {
  token: $Token
}
export default class Token extends React.Component<Props> {
  openModal(event) {
    const { token } = this.props
    event.preventDefault()
    track(
      "Customer",
      "Click",
      `${token.type === "card" ? "Row card" : "Row gateway"}`,
      `${token.type === "card" ? "Card modal" : "Gateway modal"}`,
    )
    if (token.type !== "card") this._gatewayModal.getWrappedInstance().openModal()
    else this._modal.getWrappedInstance().openModal()
  }

  render() {
    const { token } = this.props
    let cnt

    if (token.type !== "card") {
      cnt = (
        <div className="row">
          <div className="medium-3 columns">
            <div className="transaction-processor">
              <img
                alt={`${token.gateway_configuration.gateway.name}'s logo`}
                src={token.gateway_configuration.gateway.logo_url}
                style={{
                  left: "0",
                }}
              />
            </div>
          </div>
          <div className="medium-9 columns greyed">{token.gateway_configuration.name}</div>
        </div>
      )
    } else {
      cnt = (
        <div className="row">
          <div className="medium-3 columns">
            <div className="row">
              <div className="medium-6 columns">{CardSchemeImage(token.card)}</div>
              <div className="medium-6 columns font-console">{token.card.last_4_digits}</div>
            </div>
          </div>
          <div className="medium-9 columns">
            <span className={!token.card.name ? "greyed" : ""}>
              {token.card.name || "No name provided"}
            </span>
          </div>
        </div>
      )
    }

    let modal

    if (this.props.token.type === "card") {
      modal = (
        <CardModal
          ref={m => (this._modal = m)}
          card={this.props.token.card}
          subscriptionOnly={this.props.token.is_subscription_only}
          customerId={this.props.token.customer_id}
          tokenId={this.props.token.id}
        />
      )
    } else {
      modal = (
        <GatewayConfModal
          ref={m => (this._gatewayModal = m)}
          configuration={token.gateway_configuration}
          subscriptionOnly={this.props.token.is_subscription_only}
        />
      )
    }

    return (
      <div>
        {modal}
        <a onClick={this.openModal.bind(this)}>
          <div
            className="box-row padding row small-margin-bottom"
            style={{
              marginBottom: "0",
            }}
          >
            <div className="medium-9 columns">{cnt}</div>
            <div className="medium-3 columns text-right">
              <FormattedDate
                value={token.created_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />
            </div>
          </div>
        </a>
      </div>
    )
  }
}
