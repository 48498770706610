import React from "react"
import { connect } from "react-redux"
import Error from "../../../components/Error"
import PieChart from "./PieChart/PieChart"
import StackChart from "./StackChart/StackChart"
import type { $ChartType } from "../Boards/consts"
type $SingleDimensionData = Array<{
  key: string
  datapoints: null
  value: number
  _count: number
}>
type $MultiDimensionsData = Array<{
  key: string
  value: null
  datapoints: Array<$SingleDimensionData>
}>
type Props = {
  data: $SingleDimensionData | $MultiDimensionsData
  currency: string | null | undefined
  error?: boolean | null | undefined
  keysMask?: Array<string> | null | undefined
  // keys to be masked
  popup: {
    chartId: string | null | undefined
  }
  type: $ChartType
}
export class PieStackChart extends React.Component<Props> {
  render() {
    const { data, type, currency, error, popup, keysMask } = this.props
    if (error) return <Error />
    if (data.length > 0 && data[0].value !== null && data[0].value !== undefined)
      return <PieChart data={data} currency={currency} type={type} canUpdateAnalyticsFilter />
    return <StackChart data={data} currency={currency} type={type} keysMask={keysMask} />
  }
}
export default connect(store => ({
  popup: store.analytics_v2.popup,
}))(PieStackChart)
