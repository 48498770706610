import { FETCH_EVENT_DETAILS } from "^/Actions/EventsDetailsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: true,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_EVENT_DETAILS): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_EVENT_DETAILS): {
      return { ...state, fetching: false, fetched: true, event: action.payload.event }
    }

    case typeFailed(FETCH_EVENT_DETAILS): {
      return { ...state, error: true, fetched: false, fetching: false }
    }
  }

  return state
}
