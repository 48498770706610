import { CLEAR_NOTIFICATION, DISPATCH_NOTIFICATION } from "./consts"
import type { $Action } from "../../util/Types"
type State = {
  displayed: boolean
  message: string
  id: string
  type: string
}
const defaultState: State = {
  displayed: true,
  message: "",
  type: "",
  id: "",
}
export default function (state: State = defaultState, action: $Action): State {
  switch (action.type) {
    case DISPATCH_NOTIFICATION: {
      if (!action.payload) return state
      return {
        ...state,
        displayed: false,
        message: action.payload.message,
        type: action.payload.type,
        id: action.payload.id,
      }
    }

    case CLEAR_NOTIFICATION: {
      return { ...state, displayed: true }
    }
  }

  return state
}
