import moment from "moment"
import React from "react"
import { DateRangePicker } from "react-dates"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../../util/Types"
import { track } from "../../../vNext/tracking"
import type { $FetchParams } from "../Boards/consts"
import * as Actions from "./actions"
import type { $Period } from "./consts"
import { INIT_ANALYTICS_DATES, PERIODS } from "./consts"
import IntervalSelector from "./IntervalSelector"
type Props = {
  params: $FetchParams
} & $Dispatcher
type State = {
  fromDate: any
  toDate: any
  dateFocused: any
  compareFromDate: any
  compareToDate: any
  compareDateFocused: any
  editing: boolean
  comparing: boolean
  period: $Period
  comparePeriod: $Period
  interval: string
}
const ONE_YEAR_IN_MONTHS = 12
export class DatePicker extends React.Component<Props, State> {
  _panel: any

  constructor() {
    super()
    this.state = {
      editing: false,
      fromDate: moment(),
      toDate: moment(),
      dateFocused: null,
      compareFromDate: moment(),
      compareToDate: moment(),
      compareDateFocused: null,
      comparing: false,
      period: "last-30",
      comparePeriod: "previous",
      interval: "auto",
    }
  }

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside)
    this.setState({
      comparing: this.props.params.timeCompare.comparing,
      fromDate: moment.unix(this.props.params.interval.from) || moment().subtract(1, "month"),
      toDate: moment.unix(this.props.params.interval.to) || moment(),
      compareFromDate:
        moment.unix(this.props.params.interval.from).subtract(1, "months") ||
        moment().subtract(2, "months"),
      compareToDate: moment.unix(this.props.params.interval.from) || moment().subtract(1, "month"),
    })
    this.props.dispatch({
      type: INIT_ANALYTICS_DATES,
    })
  }
  UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
    if (nextProps.params.timeCompare.comparing !== this.state.comparing)
      this.setState({
        comparing: nextProps.params.timeCompare.comparing,
      })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (this._panel && !this._panel.contains(event.target)) {
      this.setState({
        editing: false,
      })
    }
  }
  onDatesChange = (dates: any) => {
    this.setState({
      fromDate: dates.startDate,
      toDate: dates.endDate,
      period: "custom",
    })
  }
  onFocusChange = (focused: any) => {
    this.setState({
      dateFocused: focused,
    })
  }
  onCompareDatesChange = (dates: any) => {
    this.setState({
      compareFromDate: dates.startDate,
      compareToDate: dates.endDate,
    })
  }
  onCompareFocusChange = (focused: any) => {
    this.setState({
      compareDateFocused: focused,
    })
  }
  onApply = () => {
    const { fromDate, toDate } = this.state
    if (!fromDate || !toDate) return
    // We set the hour and minute for the startDate and endDate to be at the beginning and the end of each date
    fromDate.set({
      hour: 0,
      minute: 0,
      second: 0,
    })
    toDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    })
    this.props.dispatch(Actions.updateAnalyticsDates(fromDate, toDate, this.state.interval))

    if (this.state.comparePeriod !== "custom") {
      const newCompareDates: {
        from: any
        to: any
      } = Actions.computeCompareDatesFromPeriod(
        fromDate,
        toDate,
        this.state.period,
        this.state.comparePeriod,
      )
      this.setState({
        compareFromDate: newCompareDates.from,
        compareToDate: newCompareDates.to,
      })
      track("Analytics", "Click", "DatePicker", "Apply", {
        from: newCompareDates.from.unix(),
        to: newCompareDates.to.unix(),
      })
      this.props.dispatch(
        Actions.changeCompareDate(newCompareDates.from.unix(), newCompareDates.to.unix()),
      )
    } else {
      const { compareFromDate, compareToDate } = this.state
      // We set the hour and minute for the startDate and endDate to be at the beginning and the end of each date
      compareFromDate.set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      compareToDate.set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      track("Analytics", "Click", "DatePicker", "Apply", {
        from: compareFromDate.unix(),
        to: compareToDate.unix(),
      })
      this.props.dispatch(Actions.changeCompareDate(compareFromDate.unix(), compareToDate.unix()))
    }

    this.setState({
      editing: false,
    })
    this.props.dispatch(Actions.setComparing(this.state.comparing))
  }
  cancel = () => {
    track("Analytics", "Click", "DatePicker", "Cancel")
    this.setState({
      editing: false,
    })
  }
  getBanner = () => {
    const { fromDate } = this.state
    const today = moment()
    const getFromDate = moment(fromDate)
    const monthsSinceFromDate = today.diff(getFromDate, "months")

    if (monthsSinceFromDate >= ONE_YEAR_IN_MONTHS) {
      return (
        <div className="callout warning">
          <div>
            You have selected a date from {monthsSinceFromDate} months ago. Please note that it may
            take some time to process this data.
          </div>
        </div>
      )
    }
  }

  render() {
    const panel = this.state.editing && (
      <div
        ref={el => {
          this._panel = el
        }}
        className={`row text-left ${this.state.editing ? "" : "hide"}`}
      >
        <div className="large-12 columns">
          <div className="date-range-picker">
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <div className="row collapse">
                  <div className="large-5 columns">
                    <label>Date range</label>
                    <select
                      style={{
                        fontSize: ".8em",
                        height: "100%",
                      }}
                      value={this.state.period}
                      onChange={event => {
                        const period = event.target.value

                        if (period !== "custom") {
                          const newDates = Actions.computeDatesFromPeriod(event.target.value)
                          const newCompareDate = Actions.computeCompareDatesFromPeriod(
                            newDates.from,
                            newDates.to,
                            period,
                            this.state.comparePeriod,
                          )
                          this.setState({
                            period: event.target.value,
                            fromDate: newDates.from,
                            toDate: newDates.to,
                            compareFromDate: newCompareDate.from,
                            compareToDate: newCompareDate.to,
                          })
                        } else {
                          this.setState({
                            period,
                          })
                        }
                      }}
                    >
                      {PERIODS.map((period: { value: string; label: string }) => (
                        <option key={period.value} value={period.value}>
                          {period.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="medium-6 medium-offset-1 columns">
                    <label>Interval</label>
                    <IntervalSelector
                      value={this.state.interval}
                      onChange={(newInterval: string) => {
                        this.setState({
                          interval: newInterval,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="row collapse">
                  <div className="large-12 columns">
                    <DateRangePicker
                      startDate={this.state.fromDate}
                      endDate={this.state.toDate}
                      focusedInput={this.state.dateFocused}
                      onDatesChange={this.onDatesChange}
                      onFocusChange={this.onFocusChange}
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <div
                  style={{
                    display: "inline-block",
                    marginRight: ".5em",
                  }}
                >
                  <input
                    id="compare_checkbox"
                    type="checkbox"
                    onChange={event => {
                      this.setState({
                        comparing: event.target.checked,
                      })
                    }}
                    checked={this.state.comparing}
                  />
                </div>
                <div
                  style={{
                    display: "inline-block",
                    margin: "0 .5em 0 .5em",
                  }}
                >
                  <label htmlFor="compare_checkbox">compare to:</label>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    margin: "0 .5em 0 .5em",
                  }}
                >
                  <select
                    name="compare-period"
                    disabled={!this.state.comparing}
                    style={{
                      height: "100%",
                      fontSize: ".8em",
                    }}
                    value={this.state.comparePeriod}
                    onChange={event => {
                      const period = event.target.value

                      if (period !== "custom") {
                        const newDates = Actions.computeCompareDatesFromPeriod(
                          this.state.fromDate,
                          this.state.toDate,
                          this.state.period,
                          event.target.value,
                        )
                        this.setState({
                          compareFromDate: newDates.from,
                          compareToDate: newDates.to,
                          comparePeriod: period,
                        })
                      } else {
                        this.setState({
                          comparePeriod: period,
                        })
                      }
                    }}
                  >
                    <option value="previous">Previous period</option>
                    <option value="previous-year">Previous year</option>
                    <option value="custom">Custom period</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className={`row margin-bottom ${
                this.state.comparing && this.state.comparePeriod === "custom" ? "" : "hide"
              }`}
            >
              <div className="large-12 columns">
                <label>Compare period</label>
                <DateRangePicker
                  startDate={this.state.compareFromDate}
                  endDate={this.state.compareToDate}
                  focusedInput={this.state.compareDateFocused}
                  onDatesChange={this.onCompareDatesChange}
                  onFocusChange={this.onCompareFocusChange}
                  isOutsideRange={() => false}
                />
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <div className="row collapse">
                  <div className="large-4 columns end" />
                </div>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-4 columns large-offset-4">
                <a className="expanded round main button small-font" onClick={this.onApply}>
                  Apply
                </a>
              </div>
              <div className="large-4 columns">
                <a className="expanded round border button small-font" onClick={this.cancel}>
                  cancel
                </a>
              </div>
            </div>
            {this.getBanner()}
          </div>
        </div>
      </div>
    )
    return (
      <div className="row">
        <div className="large-12 columns">
          <div
            className="row"
            style={{
              paddingTop: ".5em",
            }}
          >
            <div className="large-12 columns">
              <a
                className={`date-range-input ${this.state.editing ? "selected" : ""}`}
                onClick={() => {
                  this.setState({
                    editing: !this.state.editing,
                  })
                }}
              >
                {moment.unix(this.props.params.interval.from).format("ll")} -{" "}
                {moment.unix(this.props.params.interval.to).format("ll")}
              </a>
            </div>
          </div>
          {panel}
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  params: store.analytics.params,
})

export default connect(mapStateToProps)(DatePicker)
