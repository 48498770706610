import React from "react"
import { connect } from "react-redux"
import * as ReactDOMServer from "react-dom/server"
import { push } from "react-router-redux"
import { FormattedNumber, IntlProvider } from "react-intl"
import Uniqid from "uniqid"
import moment from "moment"
import qs from "qs"
import getSymbolFromCurrency from "currency-symbol-map"
import { BarLineChart } from "@processout/picasso"
import { abbreviateNumber, generateKeysColors } from "./Utils"
import type { $ChartRenderer, $DataPoint } from "../Boards/consts"
import type { $Dispatcher, $RouterLocation } from "../../../util/Types"
import getColor from "../Boards/charts/colors"
import Error from "../../../components/Error"
import ChartPopup from "../Boards/charts/Popup/ChartPopup"
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from "../Boards/charts/Popup/consts"
import ChartLegend from "./ChartLegend"
import { ANALYTICS_FILTER_CHANGE } from "^/Actions/AnalyticsActions"
import "./charts.scss"
import { segmentAnalytics } from "^/vNext/Segment"

type Props = {
  location: $RouterLocation
  error?: boolean | null | undefined
  singleValue?: boolean | null | undefined
  comparisonPlot?: string | null | undefined
  comparison: boolean
  popup: {
    chartId: string | null | undefined
  }
  canUpdateAnalyticsFilter?: boolean
  keysMask: any
  colors: Array<{
    key: string
    color: string
  }>
} & $ChartRenderer &
  $Dispatcher
type State = {
  id: number
  chart: any
  initialData: any
  hadToTrim: boolean
  colors: Array<{
    key: string
    color: string
  }>
  filter: string | null | undefined
}
export class BarChart extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      id: 0,
      chart: null,
      initialData: null,
      hadToTrim: false,
      colors: [],
      filter: "",
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      id: Uniqid(),
    })
  }

  componentDidMount() {
    const { data, error } = this.props
    this.computeColors(this.props, () => {
      this.setState(
        {
          initialData: data.slice(),
        },
        () => {
          if (!error) this.draw()
        },
      )
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.computeColors(nextProps)
  }

  computeColors = (props: Props, callback: (() => void) | null | undefined) => {
    let { data, colors } = props

    if (!colors || colors.length === 0 || data.length !== colors.length) {
      colors = generateKeysColors(data.map(entry => entry.key))
    }

    this.setState(
      {
        colors,
      },
      () => {
        if (callback) callback()
      },
    )
  }

  componentDidUpdate() {
    const { error } = this.props
    if (!error) this.draw()
  }

  componentWillUnmount() {
    const { chart } = this.state
    if (chart) chart.clear()
  }

  shouldDisplayHours(): boolean {
    const { data } = this.props
    return (
      (data[0].datapoints && data[0].datapoints.length < 2) ||
      moment(data[0].datapoints[0].key).hour() !== moment(data[0].datapoints[1].key).hour() ||
      moment(data[0].datapoints[0].key).minutes() !== moment(data[0].datapoints[1].key).minutes()
    )
  }

  draw() {
    const { initialData, id, colors } = this.state
    const { currency, type, singleValue, comparison, keysMask, location, dispatch } = this.props

    if (!initialData || !document.getElementById(`chart${id}`)) return

    if (!this.state.chart) {
      this.setState({
        chart: new BarLineChart(`#chart${id}`, {
          yAxisFormatter: d => {
            let suffix = ""
            if (type === "percentage") suffix = "%"
            else if (type === "amount") suffix = getSymbolFromCurrency(currency)
            return `${abbreviateNumber(
              type === "percentage" ? Math.round(d * 100 * 100) / 100 : d,
            )}${suffix}`
          },
        }),
      })
      return
    }

    const data = this.props.data.slice()

    // we loop over every value if we're on a single value chart to set the same key ""
    // This way nothing is displayed below the chart
    for (const set of data) {
      for (const point of set.datapoints) {
        if (!point.key || singleValue) {
          point.key = ""
        }
      }
    }

    const { chart } = this.state
    chart.cleanupTip()
    chart.reset()
    let colorCount = -1

    for (const entry of data) {
      colorCount++

      const tip = d => {
        const displayHours = this.shouldDisplayHours()
        const barKey = d.key
        const isDate = moment(barKey, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()
        const header = singleValue
          ? null
          : isDate
          ? moment(barKey).calendar(null, {
              sameDay: displayHours ? "[Today at] LT" : "[Today]",
              nextDay: "[Tomorrow]",
              nextWeek: "dddd",
              lastDay: displayHours ? "[Yesterday at] LT" : "[Yesterday]",
              lastWeek: displayHours ? "MMMM Do YYYY, h:mm a" : "MMMM Do YYYY",
              sameElse: displayHours ? "MMMM Do YYYY, h:mm a" : "MMMM Do YYYY",
            })
          : barKey
        let content

        switch (type) {
          case "percentage": {
            content = (
              <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
                <div>
                  <FormattedNumber
                    value={d.value}
                    style="percent"
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />{" "}
                  <span className="greyed">
                    (<FormattedNumber value={d._count} /> transactions)
                  </span>
                </div>
              </IntlProvider>
            )
            break
          }

          case "amount": {
            content = (
              <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
                <div>
                  <FormattedNumber
                    value={d.value}
                    style="currency"
                    currency={this.props.currency}
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                  />{" "}
                  <span className="greyed">
                    (<FormattedNumber value={d._count} /> transactions)
                  </span>
                </div>
              </IntlProvider>
            )
            break
          }

          default: {
            content = (
              <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
                <div>
                  <FormattedNumber value={d.value} maximumFractionDigits={2} /> transactions
                </div>
              </IntlProvider>
            )
          }
        }

        return ReactDOMServer.renderToString(
          <div className="row">
            <div className="large-12 columns">
              <div className="row">
                <div className="large-12 columns">
                  <h5>{header && header.length > 40 ? `${header.slice(0, 40)}...` : header}</h5>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  {content}
                  {comparison && (
                    <span
                      style={{
                        color: "#30336b",
                        marginLeft: ".5em",
                      }}
                    >
                      {`- ${entry.key}`}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>,
        )
      }

      // we check that all the keys are valid dates
      let onlyDates = true

      for (const point of entry.datapoints) {
        if (!moment(point.key, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()) {
          onlyDates = false
          break
        }
      }

      if (onlyDates) {
        // We only have dates so we update each key to a Date object
        for (const point of entry.datapoints) {
          point.key = new Date(point.key)
        }
      }

      if (keysMask && keysMask.includes(entry.key)) continue
      const currentColor = colors.find(color => entry.key === color.key)
      chart.addBar({
        data: entry.datapoints,
        colors: currentColor ? [currentColor.color] : getColor(0),
        tip,
        onclick: (point: $DataPoint) => {
          this.setState({
            filter: point._corresponding_filter,
          })

          const onFilter = () => {
            const filter = point._corresponding_filter
            if (!filter) return
            const splits = filter.split(";")
            const filterIndex = splits.findIndex(
              entry => !entry.includes("start:") && !entry.includes("end:"),
            )
            const filterValue = splits[filterIndex] || ""
            const filterParam = qs.parse(location.query.search)
            filterParam.filter = filterValue
            const newParam = qs.stringify(filterParam)
            dispatch(push(`${location.pathname}?${newParam}`))
            dispatch({
              type: ANALYTICS_FILTER_CHANGE,
              payload: {
                filter: filterValue,
              },
            })
            segmentAnalytics?.track("BAR_CHART_CLICK_FILTER", {
              filterValue,
            })
          }

          dispatch({
            type: OPEN_CHART_POPUP,
            payload: {
              chartId: id,
              onFilter,
            },
          })
        },
      })
    }

    chart.resetSVG()
    chart.draw()
  }

  render() {
    const { canUpdateAnalyticsFilter, popup, error, dispatch } = this.props
    const { filter, id, colors } = this.state

    if (error) {
      return <Error text="An error occured while loading this chart." />
    }

    return (
      <div
        onClick={e => {
          if (popup.chartId === id) {
            if (!e.target.classList.contains("popup-button")) {
              dispatch({
                type: CLOSE_CHART_POPUP,
              })
            }
          }
        }}
      >
        <svg
          className={`chart line-chart chart-popup ${
            id === popup.chartId ? "chart-popup-open" : ""
          }`}
          id={`chart${id}`}
          width="100%"
          height="200px"
        />
        {colors.length > 0 && colors[0].key !== "single" && (
          <ChartLegend
            colors={colors.map(color => ({
              name: color.key,
              value: color.color,
            }))}
          />
        )}
        <ChartPopup
          correspondingFilter={filter}
          correspondingChartId={id}
          canUpdateAnalyticsFilter={canUpdateAnalyticsFilter}
        />
      </div>
    )
  }
}
export default connect(store => ({
  popup: store.analytics_v2.popup,
  location: store.routing.locationBeforeTransitions,
}))(BarChart)
