import type { $Action, $State } from "../../util/Types"
import type { $Alert } from "./consts"
import { REQUEST_ALERTS_FETCH } from "./actions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $AlertsState = {
  alerts: Array<$Alert>
  count: number
  has_more: boolean
} & $State
export const defaultState: $AlertsState = {
  alerts: [],
  fetching: false,
  fetched: false,
  error: null,
  count: 0,
  has_more: false,
}
export default function (state: $AlertsState = defaultState, action: $Action): $AlertsState {
  switch (action.type) {
    case typePending(REQUEST_ALERTS_FETCH): {
      return { ...state, ...defaultState, fetching: true, fetched: false, alerts: [] }
    }

    case typeFailed(REQUEST_ALERTS_FETCH): {
      return { ...state, ...defaultState, fetched: true, fetching: false, error: action.payload }
    }

    case typeFulfilled(REQUEST_ALERTS_FETCH): {
      return { ...state, fetching: false, fetched: true, ...action.payload }
    }
  }

  return state
}
