/**
 * Created by jeremylejoux on 19/09/2017.
 */
import "regenerator-runtime/runtime"
import { fork } from "redux-saga/effects"
import { requestTelescopePreparation } from "../features/Telescope/Sagas/sagas"
import FeesBenchmarkSaga from "../features/analytics/FeesChart/sagas"
import { requestDashboardPreparation } from "../features/Preparator/sagas"
import BoardsSaga from "../features/analytics/Boards/sagas"
import ChartsSaga from "../features/analytics/Boards/charts/sagas"
import BoardDelete from "../features/analytics/Boards/DeleteSaga"
import BoardEdit from "../features/analytics/Boards/EditingSaga"
import BoardPreparation from "../features/analytics/Boards/Board/sagas"
import TransactionProbabilities from "../features/TransactionDetailsProbabilities/sagas"
import ChartBuilderSaga from "../features/analytics/ChartPreviewer/BuilderEffects/sagas"
import SearchFilterSagas from "../features/SearchBar/sagas"
import NotificationSagas from "../features/NotificationBar/sagas"
import ErrorContextSagas from "../features/ErrorContext/sagas"
import RoutingSettingsSagas from "../features/RoutingRules/sagas"
import ApiSetupSagas from "../features/ApiSetup/sagas"
import AnalyticsDatesSagas from "../features/analytics/DatePicker/sagas"
import NetworkSagas from "../features/NetworkManager/sagas"
import ConfirmModalSagas from "../features/ConfirmModal/sagas"
import ProjectCreationSagas from "../features/ProjectWizard/sagas"
import SurfaceSages from "../features/Telescope/Surface/sagas"
import DataExplorer from "../features/analytics/DataExplorer/sagas"
import DataExplorerEditSagas from "../features/analytics/DataExplorer/editSagas"
import ChartSavingSagas from "../features/analytics/DataExplorer/SavingSagas"
import BoardCreator from "../features/analytics/BoardCreator/sagas"
import CustomerDetailsSagas from "../features/CustomerDetails/sagas"
import ReportsSagas from "../features/Reports/sagas"
import PayoutsSagas from "../features/Payouts/sagas"
import DeclineExplainerSagas from "../features/DeclineExplainer/sagas"
import PermissionsSagas from "../features/Permissions/sagas"
import WebhooksSagas from "../features/Webhooks/sagas"
import TransactionsExportsSagas from "../features/Exports/sagas"
import AlertingSagas from "../features/Alerts/sagas"
import AlertBuiderSagas from "../features/Alerts/AlertBuilder/sagas"
import telescopeV2 from "^/features/TelescopeV2/sagas"
import LaunchDarklySagas from "^/vNext/LaunchDarkly/sagas"
import DatadogSagas from "^/vNext/Datadog/sagas"
import SegmentSagas from "^/vNext/Segment/sagas"
import JimoSagas from "^/vNext/Jimo/sagas"

const sagas = [
  FeesBenchmarkSaga,
  requestDashboardPreparation,
  requestTelescopePreparation,
  telescopeV2,
  BoardsSaga,
  ChartsSaga,
  BoardDelete,
  BoardEdit,
  BoardPreparation,
  TransactionProbabilities,
  ChartBuilderSaga,
  SearchFilterSagas,
  NotificationSagas,
  ErrorContextSagas,
  RoutingSettingsSagas,
  ApiSetupSagas,
  AnalyticsDatesSagas,
  NetworkSagas,
  ConfirmModalSagas,
  ProjectCreationSagas,
  SurfaceSages,
  DataExplorer,
  DataExplorerEditSagas,
  ChartSavingSagas,
  BoardCreator,
  CustomerDetailsSagas,
  ReportsSagas,
  PayoutsSagas,
  DeclineExplainerSagas,
  PermissionsSagas,
  WebhooksSagas,
  TransactionsExportsSagas,
  AlertingSagas,
  AlertBuiderSagas,
  LaunchDarklySagas,
  DatadogSagas,
  SegmentSagas,
  JimoSagas,
]
export default function* root() {
  yield sagas.map(saga => fork(saga))
}
