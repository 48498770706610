import React from "react"
import { connect } from "react-redux"
import { replace } from "react-router-redux"
import serialize from "form-serialize"
import Auth from "../util/Auth"
import * as ProcessOut from "../util/ProcessOut"
import AuthStore from "../stores/AuthStore"
import NotificationCenter from "../features/NotificationBar/NotificationCenter"
import Header from "../components/layout/Header"
import * as UserActions from "../Actions/UserActions"
import * as Store from "../stores/Store"
import Background from "../features/Background/Background"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
type Props = {
  location: $RouterLocation
} & $Dispatcher
type State = {
  email: string
  password: string
}

class Reminder extends React.Component<Props, State> {
  constructor() {
    super()
  }

  UNSAFE_componentWillMount() {
    this.asked = this.asked.bind(this)
    this.error = this.error.bind(this)
    AuthStore.on("asked", this.asked)
    AuthStore.on("error", this.error)
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    // Add a watcher to redirect when the user will be loggedin
    this.unsubscribeLoginWatcher = Store.store.subscribe(this.loggedIn.bind(this))
  }

  componentWillUnmount() {
    AuthStore.removeListener("asked", this.asked)
    AuthStore.removeListener("error", this.error)
  }

  componentWillUnmount() {
    this.unsubscribeLoginWatcher()
  }

  loginClicked(event) {
    event.preventDefault()
    const loginForm = document.querySelector("#remind-form")
    const login = serialize(loginForm, {
      hash: true,
    })
    Auth.askResetPassword(login.email)
  }

  error() {
    ProcessOut.addNotification(AuthStore.getError(), "error")
  }

  asked() {
    if (this.props.location.query.token && this.props.location.token != "") {
      this.props.dispatch(UserActions.generateToken(this.state.email, this.state.password))
      ProcessOut.addNotification("Password changed successfuly.", "success")
    } else {
      ProcessOut.addNotification("An email has been sent to you.", "success")
    }
  }

  // Watcher
  loggedIn() {
    const nextState = Store.store.getState()

    if (nextState.user.auth.loggedIn) {
      this.unsubscribeLoginWatcher()
      this.props.dispatch(replace("/projects"))
    }
  }

  reset(event) {
    event.preventDefault()
    const loginForm = document.querySelector("#reset-form")
    const login = serialize(loginForm, {
      hash: true,
    })

    if (login.password_confirmation != login.password) {
      ProcessOut.addNotification("Passwords are different.", "error")
    } else {
      this.setState({
        email: login.email,
        password: login.password,
      })
      Auth.askResetPassword(login.email, login.password, login.password_token)
    }
  }

  render() {
    let title = "Password reminder"
    let fields = (
      <div className="row margin-bottom">
        <div className="large-12 columns">
          <div className="content">
            <div className="row collapse">
              <div className="small-9 large-10 large-centered columns">
                <p>
                  Forgot your password? Drop us your email so our robots can help you recover it.
                </p>
              </div>
            </div>
            <form id="remind-form" onSubmit={this.loginClicked.bind(this)}>
              <div className="row collapse margin-bottom">
                <div className="small-9 large-10 large-centered columns">
                  <input
                    type="email"
                    className="bottom-border"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="medium-5 medium-centered columns">
                  <input
                    type="submit"
                    className="button round expanded border"
                    value="Send email"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )

    if (this.props.location.query.token && this.props.location.token != "") {
      title = "Password reset"
      fields = (
        <div className="row small-margin-bottom">
          <div className="large-12 columns">
            <form id="reset-form" onSubmit={this.reset.bind(this)}>
              <input type="hidden" name="password_token" value={this.props.location.query.token} />
              <div className="row collapse">
                <div className="small-9 large-10 large-centered columns">
                  <input
                    className="bottom-border"
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
              <div className="row collapse">
                <div className="small-9 large-10 large-centered columns">
                  <input
                    className="bottom-border"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                  />
                </div>
              </div>
              <div className="row collapse small-margin-bottom">
                <div className="small-9 large-10 large-centered columns">
                  <input
                    className="bottom-border"
                    type="password"
                    name="password_confirmation"
                    placeholder="Password confirmation"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="large-5 large-centered columns">
                  <input
                    type="submit"
                    value="Reset password"
                    className="button small round border expanded"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )
    }

    return (
      <div className="colorful">
        <Background />
        <Header isTransparent />
        <div className="row huge-margin-top">
          <div className="large-4 medium-4 small-centered columns">
            <div className="normal-content rounded shadowed">
              <div className="row">
                <div className="large-12 small-centered columns">
                  <NotificationCenter />
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="big-wrapper">
                  <div className="large-12 columns">
                    <div className="title no-spacing">
                      <h3 className="title no-spacing">{title}</h3>
                    </div>
                  </div>
                </div>
              </div>

              {fields}

              <div className="row">
                <div className="large-12 columns centered margin-bottom text-center">
                  {"Don't have an account? "}
                  <a
                    href="https://processout.com/contact-us/"
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Join us now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(Reminder)
