import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { arrayMove } from "react-sortable-hoc"
import uniqid from "uniqid"
import { track } from "../../../vNext/tracking"
import { SimpleModal, SimpleModalProps } from "../../../vNext/RoutingRules/SimpleModal"
import { ChangeSummary } from "../../../vNext/RoutingRules/ChangeSummary"
import { App } from "../../../vNext/RoutingRules/App"
import { $FraudConfig } from "../../FraudServicesConfiguration/consts"
import { $GatewayConfiguration } from "../../PaymentProviders/consts"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "../../SearchBar/consts"
import * as Actions from "../actions"
import type { $Declaration, $RoutingRule, $RoutingRulesSettings } from "../consts"
import { areRulesEqual, getRoutingRules } from "../Utils"
import { $Filter } from "./Filter/consts"

type Props = {
  routingRulesSettings: $RoutingRulesSettings
  dispatch: (action: any) => void
  fraudServices: $FraudConfig[]
  gatewayNames: Record<string, string>
  gatewayConfigurations: Array<$GatewayConfiguration>
  searchBar: any
}

const OPTIONS_TO_FETCH = new Set([
  "card_country",
  "currency",
  "avs_postal_check",
  "avs_name_check",
  "avs_street_check",
  "cvc_check",
  "card_iin",
  "card_category",
  "card_co_scheme",
  "card_fingerprint",
  "card_bank",
  "card_last4",
  "card_scheme",
  "card_type",
  "challenge_indicator",
  "sca_exemption_reason",
  "name",
])

export type ModalConfig = {
  open: boolean
  content?: JSX.Element | string
  onButtonClick: (label: string) => void
} & Omit<SimpleModalProps, "children" | "onClose">

const saveConfirmModalConfig: Omit<ModalConfig, "onButtonClick"> = {
  title: "Save changes",
  subtitle: "Review and save changes to your routing",
  open: false,
  buttonConfigs: [
    { label: "Cancel", variant: "tertiary", visible: true },
    { label: "Confirm", variant: "primary", visible: true },
  ],
}

const saveReminderModalConfig: Omit<ModalConfig, "onButtonClick"> = {
  title: "Unsaved changes",
  subtitle:
    "You currently have unsaved changed. If you exit without saving you will lose any changes you have made since you last saved.",
  open: false,
  buttonConfigs: [
    { label: "Cancel", variant: "tertiary", visible: true },
    { label: "Exit without saving", variant: "primary", visible: true },
  ],
}

const smartRoutingLimitModalConfig: Omit<ModalConfig, "onButtonClick"> = {
  title: "Smart routing limit",
  content: (
    <div style={{ padding: "18px 24px 24px 24px" }}>
      You can only have one smart routing per route.
    </div>
  ),
  open: false,
  buttonConfigs: [{ label: "OK", variant: "primary", visible: true }],
}

const authorizationLimitModalConfig: Omit<ModalConfig, "onButtonClick"> = {
  title: "Retries limit",
  content: (
    <div style={{ padding: "18px 24px 24px 24px" }}>
      The maximum number of retries is limited to 3 to avoid unnecessary retries and any side
      effects from banks.
    </div>
  ),
  open: false,
  buttonConfigs: [
    { label: "Cancel", variant: "tertiary", visible: false },
    { label: "OK", variant: "primary", visible: true },
  ],
}

const dynamic3DSLimitModalConfig: Omit<ModalConfig, "onButtonClick"> = {
  title: "Duplicate 3DS settings",
  content: "There are two duplicate 3DS settings, that is not allowed.",
  open: false,
  buttonConfigs: [
    { label: "Cancel", variant: "tertiary", visible: false },
    { label: "OK", variant: "primary", visible: true },
  ],
}

export const RoutingRulesBuilderV2 = ({
  routingRulesSettings,
  dispatch,
  fraudServices,
  gatewayNames,
  gatewayConfigurations,
  searchBar,
}: Props) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    ...saveReminderModalConfig,
    onButtonClick: _ => {},
  })

  // Enable v-next component style and disable it after routing rule v2 is unmounted.
  // This ensures v-next component styles are not polluting other part of the dashboard.
  useEffect(() => {
    document.body.classList.add("v-next")
    return () => {
      document.body.classList.remove("v-next")
    }
  }, [])

  const reorderedRules = (oldIndex: number, newIndex: number, declaration: $Declaration) => {
    dispatch(
      Actions.changeRoutingRulesOrder(
        declaration,
        // @ts-ignore
        arrayMove(getRoutingRules(routingRulesSettings, declaration), oldIndex, newIndex),
      ),
    )

    track("Routing Rules V2", "Reorder", declaration, "Rule", {
      oldIndex,
      newIndex,
    })
  }

  const fetchOptions = (filters: $Filter[]) => {
    const paths = new Set(
      filters
        .map(f => f.path || "")
        .filter(path => OPTIONS_TO_FETCH.has(path) || path.includes("metadata")),
    )
    paths.forEach(path => {
      const doc = ["sca_exemption_reason", "challenge_indicator"].includes(path)
        ? "transaction_operations"
        : "transactions"

      // If metadata key value is not set the path will be 'metadata', but the fetch value endpoint
      // does not like pure 'metadata' and it has to use metadata.* to fetch options
      if (path === "metadata") path = "metadata.*"

      dispatch({
        type: REQUEST_FETCH_VALUES_FOR_FIELD,
        payload: {
          // document is the actually corresponds to the endpoint
          // Different filter path has different endpoints
          document: doc,
          field: path,
        },
      })
    })
  }

  // Prepare all the options, once for all.
  // Any new filter added will trigger fetchOptions individually.
  // Maybe cache it in the future.
  const prepareOptions = declaration => {
    const rules = getRoutingRules(routingRulesSettings, declaration)
    const filters: $Filter[] = []
    rules.forEach(rule => filters.push(...rule.conditions[0].filters))
    fetchOptions(filters)
  }

  const save = () => {
    dispatch(Actions.requestRulesSaving())
  }

  const reset = () => {
    dispatch(Actions.resetRoutingRules())
  }

  const removeRule = (id: string) => {
    dispatch(Actions.removeRule(id))

    track("Routing Rules V2", "Remove", "Route", "Rule")
  }

  const cloneRule = (id: string) => {
    let rule = routingRulesSettings.rules.find(rule => rule.id === id)
    const r = structuredClone(rule)!
    r.id = uniqid()

    for (const filter of r.conditions[0].filters) {
      filter.id = uniqid()
    }

    if (r.gateways) {
      for (const gateway of r.gateways) {
        gateway.id = uniqid()
      }
    }

    let index = routingRulesSettings.rules.findIndex(rule => rule.id === id)
    dispatch(Actions.cloneRule(r, index))
  }

  // Need to limit number of authorization steps to 3
  const limitAuthorizations = (rule: $RoutingRule) => {
    if (rule.gateways) {
      return rule.gateways?.length <= 3
    }
    // If no gateways specified (not sure why it can be undefined), assume it can still add authorization step.
    return true
  }

  const limitSmartRoutings = (rule: $RoutingRule) => {
    if (rule.gateways) {
      return (
        rule.gateways.filter(g => g.gateway === "processout" || g.gateway === "processout1")
          .length <= 1
      )
    }
    return false
  }

  const limitDynamic3DS = (rule: $RoutingRule) => {
    if (rule.dynamic_3ds_params) {
      const set = new Set(rule.dynamic_3ds_params.map(p => p.path))
      return set.size == rule.dynamic_3ds_params.length
    }
    return true
  }

  return (
    <div data-tracking-sub-page="Routing Rules V2">
      <App
        onTabChange={tabIndex => dispatch(Actions.selectTab(tabIndex))}
        selectedTabIndex={routingRulesSettings.ui.selectedTabIndex}
        prepareOptions={prepareOptions}
        hasChanges={routingRulesSettings.rulesHaveBeenEdited}
        routingRulesSettings={routingRulesSettings}
        onAddRuleBtnClick={(declaration: $Declaration) => dispatch(Actions.addRule(declaration))}
        onSortEnd={reorderedRules}
        onRemoveRule={removeRule}
        cloneRule={cloneRule}
        gatewayNames={gatewayNames}
        gatewayConfigurations={gatewayConfigurations}
        options={searchBar}
        fetchOptions={fetchOptions}
        onSaveBtnClick={() => {
          setModalConfig({
            ...saveConfirmModalConfig,
            content: <ChangeSummary routingRulesSettings={routingRulesSettings}></ChangeSummary>,
            open: true,
            onButtonClick: label => {
              if (label === "Confirm") {
                save()
                setModalConfig({
                  ...modalConfig,
                  open: false,
                })
              } else if (label === "Cancel") {
                setModalConfig({ ...modalConfig, open: false })
              }
            },
          })
        }}
        onUpdateRule={(rule: $RoutingRule) => {
          if (!limitAuthorizations(rule)) {
            setModalConfig({
              ...authorizationLimitModalConfig,
              open: true,
              onButtonClick: () => {
                setModalConfig({ ...modalConfig, open: false })
              },
            })
          } else if (!limitSmartRoutings(rule)) {
            setModalConfig({
              ...smartRoutingLimitModalConfig,
              open: true,
              onButtonClick: () => {
                setModalConfig({ ...modalConfig, open: false })
              },
            })
          } else if (!limitDynamic3DS(rule)) {
            setModalConfig({
              ...dynamic3DSLimitModalConfig,
              open: true,
              onButtonClick: () => {
                setModalConfig({ ...modalConfig, open: false })
              },
            })
          } else {
            dispatch(Actions.updateRule(rule.id, rule))
          }
        }}
        fraudServices={fraudServices}
        onCancelValidation={callback => {
          if (routingRulesSettings.rulesHaveBeenEdited) {
            setModalConfig({
              ...saveReminderModalConfig,
              content: <ChangeSummary routingRulesSettings={routingRulesSettings}></ChangeSummary>,
              open: true,
              onButtonClick: label => {
                if (label === "Exit without saving") {
                  reset()
                  callback(true)
                  setModalConfig({ ...modalConfig, open: false })
                } else if (label === "Cancel") {
                  setModalConfig({ ...modalConfig, open: false })
                }
              },
            })
          } else {
            callback(true)
          }
        }}
      />
      <SimpleModal
        open={modalConfig.open}
        title={modalConfig.title}
        subtitle={modalConfig.subtitle}
        buttonConfigs={modalConfig.buttonConfigs}
        onButtonClick={modalConfig.onButtonClick}
        onClose={() => {
          setModalConfig({ ...modalConfig, open: false })
        }}
      >
        {modalConfig.content}
      </SimpleModal>
    </div>
  )
}

export const RoutingRulesBuilderWithRouter = withRouter(props => {
  // Confirmation before refresh, close tab or navigate away
  useEffect(() => {
    const beforeUnload = e => {
      if (props.routingRulesSettings.rulesHaveBeenEdited) {
        e.preventDefault()
        e.returnValue = ""
        track("Routing Rules V2", "Close", "Exit Confirm", "Tab")
      }
    }
    window.addEventListener("beforeunload", beforeUnload)

    // When navigate away, show confirmation
    // No need to tear down the hook, react-router handles it.
    props.router.setRouteLeaveHook(props.route, () => {
      if (props.routingRulesSettings.rulesHaveBeenEdited) {
        track("Routing Rules V2", "Navigate Away", "Exit Confirm", "Navigation")
        return "You have unsaved changes, are you sure you want to leave this page?"
      }
    })

    return () => {
      window.removeEventListener("beforeunload", beforeUnload)
    }
  }, [props.routingRulesSettings])

  return <RoutingRulesBuilderV2 {...props}></RoutingRulesBuilderV2>
})

export default connect(store => {
  return {
    routingRulesSettings: store.routingRulesSettings,
    fraudServices: store.fraudServiceConfigurationsReducer.fraudServices,
    gatewayNames: store.gateway_configurations_names.gateway_configuration_names,
    gatewayConfigurations: store.processorsConfigurations.configurations,
    searchBar: store.searchBar,
  }
})(RoutingRulesBuilderWithRouter)
