import type { $Action } from "../../util/Types"
export const PREPARE_REPORTS_PAGE = "PREPARE_REPORTS_PAGE"
export const REQUEST_REPORTS_FETCH = "REQUEST_REPORTS_FETCH"
export const STOP_REPORTS_AUTOFETCH = "STOP_REPORTS_AUTOFETCH"
export const ADD_LOCAL_REPORT_UPLOAD = "ADD_LOCAL_REPORT_UPLOAD"
export const REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS =
  "REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS"
export const UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS = "UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS"
export const REMOVE_LOCAL_REPORT_UPLOAD = "REMOVE_LOCAL_REPORT_UPLOAD"
export const INITIATE_REPORT_UPLOAD = "INITIATE_REPORT_UPLOAD"
export const requestReportsFetch = (
  filter: string,
  length: number,
  after: boolean | null | undefined,
  id: string | null | undefined,
): $Action => ({
  type: REQUEST_REPORTS_FETCH,
  payload: {
    filter,
    length,
    after,
    id,
  },
})
export function initUpload(files: Array<any>, gatewayConfigurationId: string): $Action {
  return {
    type: INITIATE_REPORT_UPLOAD,
    payload: {
      files,
      gatewayConfigurationId,
    },
  }
}
export function prepareReportsPage(): $Action {
  return {
    type: PREPARE_REPORTS_PAGE,
  }
}
export const CHANGE_REPORTS_SELECTED_GATEWAY = "CHANGE_REPORTS_SELECTED_GATEWAY"
export function selectGatewayConfiguration(gatewayConfigurationId: string): $Action {
  return {
    type: CHANGE_REPORTS_SELECTED_GATEWAY,
    payload: {
      gatewayConfigurationId,
    },
  }
}
export const CHANGE_REPORTS_SELECTED_FILES = "CHANGE_REPORTS_SELECTED_FILES"
export function selectFiles(files: Array<any>): $Action {
  return {
    type: CHANGE_REPORTS_SELECTED_FILES,
    payload: {
      files,
    },
  }
}
