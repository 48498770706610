import React from "react"
type Props = {
  privateKey: string
}

class PrivateKeyModal extends React.Component<Props> {
  render() {
    const { privateKey } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div
              className="medium-12 columns"
              style={{
                color: "rgb(226, 89, 80)",
              }}
            >
              Your newly created private key is displayed below; please store it securely.
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <input type="text" value={privateKey} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivateKeyModal
