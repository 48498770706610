import * as React from "react"
import Tab from "./Tab"
export type $Tabs = {
  label: string
  content: JSX.Element
}
type Props = {
  value: number
  onChange: (index: number) => void
  tabs: $Tabs[]
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  }
}

export default function Tabs({ value, onChange, tabs }: Props) {
  return (
    <>
      <nav>
        <ul>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              index={index}
              value={value}
              onChange={onChange}
              {...a11yProps(index)}
            />
          ))}
          <div className="tab-grey-border" />
        </ul>
      </nav>
    </>
  )
}
