import type {
  $DataExplorerMetricTab,
  $DataExplorerPreset,
  $DataExplorerTab,
  $PresetCategory,
} from "./consts"
import type { $Action } from "../../../util/Types"
import type { $ChartType, $FetchParams } from "../Boards/consts"
import type { $Filter } from "./ChartBuilder/Filters/consts"
import type { $MetricType } from "./ChartBuilder/consts"
import ConvertWindowsCarriageReturns from "^/util/ConvertWindowsCarriageReturns"
import { segmentAnalytics } from "^/vNext/Segment"

export const SET_SELECTED_PRESET_CATEGORY = "SET_SELECTED_PRESET_CATEGORY"
export function setSelectedPresetCategory(category: $PresetCategory): $Action {
  return {
    type: SET_SELECTED_PRESET_CATEGORY,
    payload: {
      category,
    },
  }
}
export const UPDATE_PRESETS_SEARCH = "UPDATE_PRESETS_SEARCH"
export function updatePresetsSearch(newValue: string): $Action {
  return {
    type: UPDATE_PRESETS_SEARCH,
    payload: {
      search: newValue,
    },
  }
}
export const SELECT_TAB = "SELECT_TAB"
export function selectTab(tab: $DataExplorerTab): $Action {
  return {
    type: SELECT_TAB,
    payload: {
      tab,
    },
  }
}
export type $ChartPreviewAction = {
  type: string
  payload: {
    name: string
    formula: string
    type: $ChartType
    unit: string
    params: $FetchParams
  }
}
export const REQUEST_CHART_FETCH = "REQUEST_CHART_FETCH"
export function requestChartFetch(
  formula: string,
  name: string,
  type: $ChartType,
  unit: string,
  params: $FetchParams,
): $ChartPreviewAction {
  return {
    type: REQUEST_CHART_FETCH,
    payload: {
      formula: ConvertWindowsCarriageReturns(formula),
      name,
      type,
      unit,
      params,
    },
  }
}
export const RESET_DATA_EXPLORER = "RESET_DATA_EXPLORER"
export type ResetDataExplorerAction = {
  type: typeof RESET_DATA_EXPLORER
}
export function resetDataExplorer() {
  return {
    type: RESET_DATA_EXPLORER,
  }
}
export const UPDATE_DATA_EXPLORER_FORMULA = "UPDATE_DATA_EXPLORER_FORMULA"
export function updateFormula(formula: string): $Action {
  return {
    type: UPDATE_DATA_EXPLORER_FORMULA,
    payload: {
      formula,
    },
  }
}
export const SELECT_DATA_EXPLORER_PRESET = "SELECT_DATA_EXPLORER_PRESET"
export function selectPreset(preset: $DataExplorerPreset | null | undefined): $Action {
  return {
    type: SELECT_DATA_EXPLORER_PRESET,
    payload: {
      preset,
    },
  }
}
export const APPLY_DATA_EXPLORER_PRESET = "APPLY_DATA_EXPLORER_PRESET"
export type $ApplyPresetAction = {
  type: string
  payload: {
    preset: $DataExplorerPreset
  }
}
export function applyPreset(preset: $DataExplorerPreset): $ApplyPresetAction {
  segmentAnalytics?.track("CHART_PRESET_SELECTED", {
    name: preset.name,
  })
  return {
    type: APPLY_DATA_EXPLORER_PRESET,
    payload: {
      preset,
    },
  }
}
export const UPDATE_DATA_EXPLORER_TYPE = "UPDATE_DATA_EXPLORER_TYPE"
export function updateType(type: $ChartType): $Action {
  return {
    type: UPDATE_DATA_EXPLORER_TYPE,
    payload: {
      type,
    },
  }
}
export const UPDATE_DATA_EXPLORER_UNIT = "UPDATE_DATA_EXPLORER_UNIT"
export function updateUnit(unit: string): $Action {
  return {
    type: UPDATE_DATA_EXPLORER_UNIT,
    payload: {
      unit,
    },
  }
}
export const SET_METRIC_TAB = "SET_METRIC_TAB"
export function setMetricTab(tab: $DataExplorerMetricTab): $Action {
  return {
    type: SET_METRIC_TAB,
    payload: {
      tab,
    },
  }
}
export const UPDATE_METRIC_FILTERS = "UPDATE_METRIC_FILTERS"
export function updateMetricFilters(metricId: string, filters: Array<$Filter>): $Action {
  return {
    type: UPDATE_METRIC_FILTERS,
    payload: {
      metricId,
      filters,
    },
  }
}
export const UPDATE_METRIC_TYPE = "UPDATE_METRIC_TYPE"
export function updateMetricType(metricId: string, type: $MetricType): $Action {
  return {
    type: UPDATE_METRIC_TYPE,
    payload: {
      metricId,
      type,
    },
  }
}
export const UPDATE_METRIC_PATH = "UPDATE_METRIC_PATH"
export function updateMetricPath(metricId: string, path: string): $Action {
  return {
    type: UPDATE_METRIC_PATH,
    payload: {
      metricId,
      path,
    },
  }
}
export const UPDATE_METRIC_LOCAL_CURRENCY = "UPDATE_METRIC_LOCAL_CURRENCY"
export function updateMetricLocalCurrency(
  metricId: string,
  displayInLocalCurrency: boolean,
): $Action {
  return {
    type: UPDATE_METRIC_LOCAL_CURRENCY,
    payload: {
      metricId,
      displayInLocalCurrency,
    },
  }
}
export const SAVE_CHART_ON_BOARD = "SAVE_CHART_ON_BOARD"
export function saveChartOnBoard(boardId: string): $Action {
  return {
    type: SAVE_CHART_ON_BOARD,
    payload: {
      boardId,
    },
  }
}
export const SET_DATA_EXPLORER_BOARD_ID = "SET_DATA_EXPLORER_BOARD_ID"
export type $SetBoardIdAction = {
  type: string
  payload: {
    id: string | null | undefined
  }
}
export function setBoardId(id: string | null | undefined): $SetBoardIdAction {
  return {
    type: SET_DATA_EXPLORER_BOARD_ID,
    payload: {
      id,
    },
  }
}
export const SET_DATA_EXPLORER_CHART_EDITION = "SET_DATA_EXPLORER_CHART_EDITION"
export function setDataExplorerChartEdition(chartId: string): $Action {
  return {
    type: SET_DATA_EXPLORER_CHART_EDITION,
    payload: {
      chartId,
    },
  }
}
export const SET_EDITOR_HAS_BEEN_EDITED = "SET_EDITOR_HAS_BEEN_EDITED"
export function setEditorHasBeenEdited(value: boolean): $Action {
  return {
    type: SET_EDITOR_HAS_BEEN_EDITED,
    payload: {
      editorHasBeenEdited: value,
    },
  }
}
export const REQUEST_FORMULA_COMPILE = "REQUEST_FORMULA_COMPILE"
export type $RequestCompileFormulaToBuilderState = {
  type: typeof REQUEST_FORMULA_COMPILE
  payload: {
    chartId: string
    project: string
    board: string
    params: $FetchParams
  }
}
export function requestFormulaCompile(
  chartId: string,
  project: string,
  board: string,
  params: $FetchParams,
): $RequestCompileFormulaToBuilderState {
  return {
    type: REQUEST_FORMULA_COMPILE,
    payload: {
      chartId,
      project,
      board,
      params,
    },
  }
}
export const PREVIEW_CHART = "PREVIEW_CHART"
export const APPLY_PRESET_DATA_EXPLORER = "APPLY_PRESET_DATA_EXPLORER"
export const APPLY_PRESET_CHART_BUILDER = "APPLY_PRESET_CHART_BUILDER"
export type ApplyPresetChartBuilderAction = {
  type: typeof APPLY_PRESET_CHART_BUILDER
  payload: any
}
