import React from "react"
import type { TransactionModel } from "../Types/Transaction"
export const getStatusTextFromTransaction = transaction => {
  if (transaction.status === "waiting" || transaction.status.includes("pending")) {
    return transaction.three_d_s_status === "failed" ? "3DS failed" : transaction.status
  } else if (transaction.status === "failed" && transaction.three_d_s_status === "failed") {
    return "3DS failed"
  } else {
    return transaction.status
  }
}
export const generateTransactionStatusTag = (
  transaction: TransactionModel,
): React.ReactElement<React.ComponentProps<any>, any> => {
  let classModifier,
    showTip = false

  if (transaction.status === "completed") {
    classModifier = "success"
  } else if (transaction.status === "waiting" || transaction.status.includes("pending")) {
    classModifier = transaction.three_d_s_status === "failed" ? "failed" : "warning"
  } else if (transaction.status.includes("refund")) {
    classModifier = "warning"
  } else if (transaction.status === "authorized") {
    classModifier = "warning"
  } else if (transaction.status === "blocked") {
    classModifier = "blocked"
  } else if (transaction.status === "failed" && transaction.three_d_s_status === "failed") {
    classModifier = "failed"
  } else {
    classModifier =
      transaction.status === "failed" || transaction.status === "reversed" ? "failed" : "warning"
    showTip = true
  }

  return (
    <div
      data-testid="transaction-status-tag"
      data-tip={showTip ? transaction.error_code : undefined}
      className={`log-level ${classModifier}`}
      style={{
        margin: "0px",
        marginTop: "0.2em",
      }}
    >
      {getStatusTextFromTransaction(transaction)}
    </div>
  )
}
