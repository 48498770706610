import React from "react"
import { connect } from "react-redux"
import type { $AlertsEventsState } from "./reducer"
import type { $Dispatcher, $RouterLocation } from "../../../util/Types"
import ContentLayout from "../../../pages/ContentLayout"
import Error from "../../../components/Error"
import Loading from "../../../components/Loader"
import * as Actions from "../actions"
import Events from "./Events"
import Empty from "../../../components/Empty"
type Props = {
  eventsState: $AlertsEventsState
  location: $RouterLocation
} & $Dispatcher

class EventsHome extends React.Component<Props> {
  componentDidMount() {
    document.title = "Alerting — Events | ProcessOut"
    const { dispatch, location } = this.props
    dispatch(Actions.requestAlertsEventsFetch(location.query.alert_id))
  }

  render() {
    const { eventsState, location } = this.props
    return (
      <ContentLayout
        title={
          <div className="row">
            <div className="medium-1 columns">
              <h4>Events</h4>
            </div>
            <div
              className="medium-11 columns"
              style={{
                position: "relative",
                top: ".3em",
                fontSize: "1.2em",
              }}
            >
              {location.query.alert_id && <span className="greyed">{location.query.alert_id}</span>}
            </div>
          </div>
        }
        noBackground
        padding="1em"
      >
        {eventsState.error && <Error />}
        {eventsState.fetching && <Loading />}
        {eventsState.fetched && !eventsState.error && <Events events={eventsState.events} />}
        {eventsState.fetched && !eventsState.error && eventsState.events.length === 0 && (
          <Empty
            text={
              location.query.alert_id
                ? `This alert hasn't been triggered yet.`
                : "No alerts has been triggered yet."
            }
          />
        )}
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  eventsState: store.alertingEvents,
}))(EventsHome)
