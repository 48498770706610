import uniqid from "uniqid"
import type { $ConditionNode, $EndCondition } from "./FormulaCompiler/consts"
import type { $Filter, $Operand } from "./ChartBuilder/Filters/consts"
import type { $BuilderMetric, $Metric } from "./ChartBuilder/consts"
import { BUILDER_METRICS } from "./ChartBuilder/consts"
export function filtersCanBeMerged(
  operand: "or" | "and",
  filter1: $EndCondition,
  filter2: $EndCondition,
): boolean {
  return (
    (operand === "or" &&
      filter1.original_field === filter2.original_field &&
      filter1.comparator === "==" &&
      filter2.comparator === "==") ||
    (operand === "and" &&
      filter1.original_field === filter2.original_field &&
      filter1.comparator === "!=" &&
      filter2.comparator === "!=")
  )
}
// Takes a condition and compiles it to a filter
export function conditionToFilter(condition: $EndCondition): $Filter {
  return {
    id: uniqid(),
    path: condition.original_field,
    operand:
      condition.value === null
        ? condition.comparator === "=="
          ? "is-null"
          : "is-not-null"
        : condition.comparator,
    value: [condition.value],
  }
}
export function mergeTwoNodes(ast: $ConditionNode): Array<$Filter> | null | undefined {
  if (!ast.operator) {
    // we are on a leaf
    return [
      {
        id: uniqid(),
        path: ast.original_field,
        operand: ast.comparator,
        value: [ast.value],
      },
    ]
  }

  if (!ast.left.operator && !ast.right.operator) {
    // both are leaves
    const { left } = ast
    const { right } = ast

    if (filtersCanBeMerged(ast.operator, left, right)) {
      return [{ ...conditionToFilter(left), value: [left.value, right.value] }]
    }

    if (ast.operator === "and") {
      return [conditionToFilter(left), conditionToFilter(right)]
    }

    return null
  }

  if (!ast.left.operator && ast.right.operator) {
    // Left node is a leaf but right node isn't
    const { left } = ast
    const rightMerged = mergeTwoNodes(ast.right)
    if (!rightMerged) return null // Right could not be merged

    if (
      (ast.operator === "or" &&
        left.original_field === rightMerged[0].path &&
        left.comparator === "==" &&
        rightMerged[0].operand === "==") ||
      (ast.operator === "and" &&
        left.original_field === rightMerged[0].path &&
        left.comparator === "!=" &&
        // FIXME: fix the typo, seems like it misses the array accessor: [0]
        // @ts-ignore
        rightMerged.operand === "!=")
    ) {
      rightMerged[0].value.splice(0, 0, left.value)
    } else {
      rightMerged.push(conditionToFilter(left))
    }

    return rightMerged
  }

  if (ast.left.operator && !ast.right.operator) {
    // Right node is a leaf but left node isn't
    const { right } = ast
    const leftMerged = mergeTwoNodes(ast.left)
    if (!leftMerged) return null // Left could not be merged

    if (
      (ast.operator === "or" &&
        right.original_field === leftMerged[0].path &&
        right.comparator === "==" &&
        leftMerged[0].operand === "==") ||
      (ast.operator === "and" &&
        right.original_field === leftMerged[0].path &&
        right.comparator === "!=" &&
        // FIXME: fix the typo, seems like it misses the array accessor: [0]
        // @ts-ignore
        leftMerged.operand === "!=")
    ) {
      leftMerged[0].value.push(right.value)
    } else {
      leftMerged.push(conditionToFilter(right))
    }

    return leftMerged
  }

  // Both nodes aren't leaves
  const leftMerged = mergeTwoNodes(ast.left)
  const rightMerged = mergeTwoNodes(ast.right)
  if (!leftMerged || !rightMerged) return null

  if (
    (ast.operator === "or" &&
      rightMerged[0].path === leftMerged[0].path &&
      rightMerged[0].operand === "==" &&
      leftMerged[0].operand === "==") ||
    (ast.operator === "and" &&
      rightMerged[0].path === leftMerged[0].path &&
      rightMerged[0].operand === "!=" &&
      // FIXME: fix the typo, seems like it misses the array accessor: [0]
      // @ts-ignore
      leftMerged.operand === "!=")
  ) {
    leftMerged[0].value = leftMerged[0].value.concat(rightMerged[0].value)
    return leftMerged
  }

  return leftMerged.concat(rightMerged)
}
export function addSingleValueFilter(
  filter: {
    path: string
    operand: $Operand
    value: string | number | boolean
  },
  outFilters: Array<$Filter>,
): void {
  const index = outFilters.findIndex(
    f => f.path === filter.path && f.operand === filter.operand && !f.value.includes(filter.value),
  )

  if (index > -1) {
    outFilters[index].value.push(filter.value)
  } else {
    outFilters.push({
      id: uniqid(),
      path: filter.path,
      operand: filter.operand,
      value: [filter.value],
    })
  }
}
// Takes an array of array of filters and return an array of shared filters
export function extractCommonFilters(filtersArray: Array<Array<$Filter>>): Array<$Filter> {
  const output: Array<$Filter> = []

  for (const filters of filtersArray) {
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]

      // Check that they all have this filter with this value
      for (const value of filter.value) {
        let checked = true

        for (const filters2 of filtersArray) {
          if (
            filters2.findIndex(
              f =>
                f.path === filter.path && f.operand === filter.operand && f.value.includes(value),
            ) < 0
          ) {
            checked = false
            break
          }
        }

        if (checked) {
          // check that we don't already have it in the output
          if (
            output.findIndex(
              f =>
                f.path === filter.path && f.operand === filter.operand && f.value.includes(value),
            ) < 0
          )
            addSingleValueFilter(
              {
                path: filter.path,
                operand: filter.operand,
                value,
              },
              output,
            )
        }
      }
    }
  }

  return output
}
export function detectPresetMetric(
  metrics: Array<$Metric>,
  generalFormula: string,
): $BuilderMetric | null | undefined {
  topMetricsLoop: for (const bMetric of BUILDER_METRICS) {
    if (bMetric.metrics.length !== metrics.length) continue

    for (const metric of bMetric.metrics) {
      // Metrics
      const correspondingMetric = metrics.find(m => {
        if (m.path !== metric.path || m.type !== metric.type) return false

        // Filters
        for (const filter of metric.filters) {
          const correspondingFilter = m.filters.find(
            f => f.path === filter.path && f.operand === filter.operand,
          )
          if (!correspondingFilter) return false

          // values
          for (const value of filter.value) {
            if (
              filter.value.length !== correspondingFilter.value.length ||
              !correspondingFilter.value.includes(value)
            )
              return false
          }
        }

        return true
      })
      if (!correspondingMetric) continue topMetricsLoop
    }

    return bMetric
  }

  return null
}

export function isSameFilter(filterA: $Filter, filterB: $Filter) {
  let isSameValue =
    filterA.value.length === filterB.value.length &&
    filterA.value.every(v => {
      // undefined and null are treated the same
      if (v === undefined || v === null) {
        return filterB.value.includes(undefined) || filterB.value.includes(null)
      } else {
        return filterB.value.includes(v)
      }
    })

  return filterA.path === filterB.path && filterA.operand === filterB.operand && isSameValue
}

/**
 * The purpose of the normalizeFilters function is to remove duplicate filters if for example the metrics is a ratio, A / B. Because A will contain a set of filters and B will also contains the exact same set of filters.
 * For example, given an authorization metrics, the formula will be: the number of success authorization (A) / the total number of authorization (B).
 * If the metrics contains any user defined filters, they will be duplicated in both A and B, therefore needs to be normalized (remove duplicated).
 * Any metrics specific filters (for authorization ratio metric, these filters will be: type == authorization, is_successfull == true) that are not part of the A nor B will be retained.
 *
 * This duplication removing process is still valid even if the formula is not A / B and duplicates exists.
 *
 * @param filters The compiled raw filters. The filters might contains duplicated entries if for example it is a ratio metric A / B.
 * @returns The filters that contains no duplications.
 */
export function normalizeFilters(filters: Array<$Filter>): Array<$Filter> {
  const result: Array<$Filter> = []

  for (const filter of filters) {
    if (filter.value.length === 0) {
      const index = result.findIndex(f => f.path === filter.path && f.operand === filter.operand)
      if (index < 0) {
        result.push(filter)
        continue
      }
    }

    const index = result.findIndex(f => {
      return isSameFilter(filter, f)
    })

    if (index > -1) {
      // filter already there, skip
    } else {
      result.push({
        ...structuredClone(filter),
        id: uniqid(),
      })
    }
  }

  return result
}
