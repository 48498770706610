import React from "react"
export default class Name extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { invoice } = this.props
    let name

    if (invoice != null) {
      name = invoice.name
    }

    let recurring

    if (this.props.recurring) {
      recurring = (
        <div>
          <div className="row small-margin-bottom">
            <div className="large-6 medium-6 columns">
              <input
                type="number"
                placeholder="Interval"
                name="interval_days"
                className="bottom-border"
                defaultValue={this.props.interval_days}
                required
              />
            </div>
            <div className="large-6 medium-6 columns">
              <p
                style={{
                  marginTop: "15px",
                }}
              >
                Days
              </p>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="row">
          <div className="large-12 medium-8 columns">
            <input
              type="text"
              placeholder="Name"
              name="name"
              className="bottom-border"
              id="item-name"
              defaultValue={name}
              required
            />
          </div>
        </div>

        {recurring}
      </div>
    )
  }
}
