import type { $Action, $State } from "^/util/Types"
import { FETCH_COUPONS } from "^/Actions/CouponsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $Coupon = {
  id: string
  name: string
}
export type $CouponsState = {
  coupons: Array<$Coupon>
} & $State
const defaultState: $CouponsState = {
  fetching: false,
  fetched: false,
  error: undefined,
  coupons: [],
}
export default function (state: $CouponsState = defaultState, action: $Action): $CouponsState {
  switch (action.type) {
    case typePending(FETCH_COUPONS): {
      state = { ...state, ...defaultState, fetching: true }
      return state
    }

    case typeFulfilled(FETCH_COUPONS): {
      state = { ...state, ...action.payload, fetched: true, fetching: false }
      return state
    }

    case typeFailed(FETCH_COUPONS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
