import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import { routerMiddleware, syncHistoryWithStore } from "react-router-redux"
import { browserHistory } from "react-router"
import createSagaMiddleware from "redux-saga"
import reducer from "./Reducers/Index"
import Sagas from "../sagas"

const errorHandler = store => next => action => {
  if (!action.payload) return next(action)

  if (action.payload.error) {
    action.type = action.type.replace("_FULFILLED", "_REJECTED")
  }

  if (action.payload.status) {
    // The action is an HTTP call. We filter the action payload to keep only the JSON
    if (action.payload.status === 412) {
      // Two factor is needed.
      action.payload.waiting_for_two_factor = true
    }

    action.payload = action.payload.data || action.payload
  }

  return next(action)
}

const sagaMiddleware = createSagaMiddleware()
let middleware
let his
let composeEnhancers

if (process.env.NODE_ENV !== "test") {
  his = browserHistory
  const logger = createLogger({
    collapsed: true,
  })
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  if (process.env.TARGET_ENV.startsWith("local")) {
    middleware = applyMiddleware(
      promise(),
      thunk,
      errorHandler,
      logger,
      routerMiddleware(his),
      sagaMiddleware,
    )
  } else {
    middleware = applyMiddleware(
      promise(),
      thunk,
      errorHandler,
      routerMiddleware(his),
      sagaMiddleware,
    )
  }
} else {
  composeEnhancers = compose
  middleware = applyMiddleware(promise(), thunk, errorHandler, sagaMiddleware)
}

export const store = createStore(reducer, composeEnhancers(middleware))
;(store as any).asyncReducers = {}
export const history = process.env.NODE_ENV === "test" ? null : syncHistoryWithStore(his, store)
// Start listening for Sagas
sagaMiddleware.run(Sagas)

// expose store when run in Cypress
if (window["Cypress"]) {
  window["store"] = store
}
