import React from "react"
import { connect } from "react-redux"
import type { $BoardCreatorState } from "./reducer"
import type { $Dispatcher, $OnChangeEvent } from "../../../util/Types"
import * as Actions from "./actions"
type Props = {
  boardCreator: $BoardCreatorState
} & $Dispatcher

class SearchBar extends React.Component {
  props: Props
  onSearchChange = (event: $OnChangeEvent) => {
    this.props.dispatch(Actions.updateSearch(event.target.value))
  }

  render() {
    return (
      <div className="search">
        <label htmlFor="search-input" />
        <input
          id="search-input"
          type="text"
          value={this.props.boardCreator.presetSearch}
          placeholder="Search for a specific board"
          onChange={this.onSearchChange}
        />
      </div>
    )
  }
}

export default connect(store => ({
  boardCreator: store.boardCreator,
}))(SearchBar)
