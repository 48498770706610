import type { $Chart } from "^/features/analytics/Boards/consts"

export type $BoardPreset = {
  name: string
  description: string
  charts: Array<$Chart & { margin_left: number; on_new_line: boolean; favorite: boolean }>
}
export const BOARDS_PRESETS: Array<$BoardPreset> = [
  {
    name: "Payment performance",
    description:
      "**Get a clear view on your payment performance:**\n" +
      "* Monitor your authorization rate\n" +
      "* How much you're paying on fees\n" +
      "* How are your different countries performing\n",
    charts: [
      {
        id: "chart_xozr1JdSSGSBZCk6tybCwxqblhzaC2Ef",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Merchant accounts",
        description: "",
        position_x: 0,
        position_y: 0,
        size: 3,
        height: 3,
        type: "single-value",
        settings: {
          formula: 'cardinality{path:"transactions.gateway_configuration_id"; default: 0}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-28T13:30:31.483099Z",
      },
      {
        id: "chart_uYkAZImh6DzvOP3fz2gfbEsCaJQumHjk",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Conversion rate NET",
        description: "",
        position_x: 3,
        position_y: 0,
        size: 3,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "request"\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:31:15.993657Z",
      },
      {
        id: "chart_Y1RLBwnPNxRBHGMDEabPoKl0NANb4Ez5",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Authorization rate NET",
        description: "",
        position_x: 6,
        position_y: 0,
        size: 3,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:31:34.601275Z",
      },
      {
        id: "chart_FMS6CEDVIpqycNxX8XWFdow2iarXDAkm",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Transactions per gateway",
        description: "",
        position_x: 0,
        position_y: 3,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:gateway_configuration_id;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-28T13:46:49.586535Z",
      },
      {
        id: "chart_DGhkHgh0xg5Ael2EZU4J8nJ3obGHYHbr",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Authorization rate per gateway",
        description: "",
        position_x: 6,
        position_y: 3,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:gateway_configuration_id;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:50:18.678365Z",
      },
      {
        id: "chart_2MWR570dDOusnAehmoE2OzjdokSejibn",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Authorization rate evolution",
        description: "",
        position_x: 0,
        position_y: 9,
        size: 12,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:51:29.479249Z",
      },
      {
        id: "chart_naGuoXTbUZCxvjVkTbPv0OIxQwFIx1Gv",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Conversion rate evolution",
        description: "",
        position_x: 0,
        position_y: 15,
        size: 12,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "request"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:51:42.696118Z",
      },
      {
        id: "chart_xWf7khhomRZedTVIAnWkINs4VkniUhcT",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Fees rate per gateway",
        description: "",
        position_x: 0,
        position_y: 21,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    path: gateway_name;\n    plot{\n        path:created_at;\n        formula: sum{\n            path:transaction_operations.gateway_fee;\n            default: 0;\n            type == "capture"\n            AND gateway_fee != null;\n            ;\n        }\n        / sum{\n            path:transaction_operations.amount;\n            default: 0;\n            type == "capture"\n            AND gateway_fee != null;\n            ;\n        }\n    }\n    ;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T13:52:14.913449Z",
      },
      {
        id: "chart_fOcdP0K1cwhiR8MvN6wW4PEygYyCH0gK",
        board_id: "board_Q4JW7ZJs03l0qHYlBE3AwMWurYcqWIWb",
        name: "Top 10 countries authorization rate",
        description: "",
        position_x: 6,
        position_y: 21,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-28T14:15:33.185373Z",
      },
    ],
  },
  {
    name: "Sales overview",
    description:
      "**Get a clear view on your sales performances:**\n" +
      "* Overall sales volume\n" +
      "* Refunds volume\n" +
      "* Country distribution\n",
    charts: [
      {
        id: "chart_LLAjRP21ePllNp0A33kgZiBZFAStg4gk",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Total captured",
        description: "",
        position_x: 0,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "amount",
        favorite: false,
        created_at: "2020-02-29T13:48:10.460462Z",
      },
      {
        id: "chart_3QJ8Z6IB835CCRhng5u0CbpjgAZmpa2T",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Refunded",
        description: "",
        position_x: 4,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "amount",
        favorite: false,
        created_at: "2020-02-29T13:48:25.61135Z",
      },
      {
        id: "chart_cAQMwShpK1g1xetmMzxtfWhVxFWcBonQ",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Authorized transactions",
        description: "",
        position_x: 8,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    status == "authorized"\n    AND is_successful == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:48:39.335115Z",
      },
      {
        id: "chart_bwLOGz8c52uQu5JHRp87W6dvhSZ4l6hG",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Captured amount evolution",
        description: "",
        position_x: 0,
        position_y: 3,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "capture"\n        AND is_successful == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "amount",
        favorite: false,
        created_at: "2020-02-29T13:50:35.791517Z",
      },
      {
        id: "chart_eajvMHLbxdUJkDRXuvqGmMTul24tNKzY",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Authorized transactions evolution",
        description: "",
        position_x: 6,
        position_y: 3,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        status == "authorized"\n        AND is_successful == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:50:54.959115Z",
      },
      {
        id: "chart_anoDrtw1dViAdhUTCLmrspdI44oxS7f5",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Refunded amount evolution",
        description: "",
        position_x: 0,
        position_y: 9,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "refund"\n        AND is_successful == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "amount",
        favorite: false,
        created_at: "2020-02-29T13:51:30.827041Z",
      },
      {
        id: "chart_tqX6Q7ovAzWSBJxf8BCI2nFkedPxq3dA",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Refund rate evolution",
        description: "",
        position_x: 6,
        position_y: 9,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "refund"\n        AND is_successful == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND is_successful == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T13:51:48.02347Z",
      },
      {
        id: "chart_DDvymupoOyopZvxYK3kWisyLBSLH3MKa",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Distribution of currencies",
        description: "",
        position_x: 0,
        position_y: 15,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:currency;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        status == "authorized"\n        AND is_successful == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:52:22.29722Z",
      },
      {
        id: "chart_VyWHQsuVuxx11TFtTrt4N2IfHkCnAFIe",
        board_id: "board_RAI1st0WTCrVkfJb3sZ1nhQRzIyPZV6W",
        name: "Distribution per country",
        description: "",
        position_x: 6,
        position_y: 15,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 15;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:52:40.792674Z",
      },
    ],
  },
  {
    name: "Error code analysis",
    description:
      "**Dig into your error codes to understand their origin:**\n" +
      "* Discover why payments are failing\n" +
      "* How many failed transactions you encounter\n" +
      "* What's your decline rate",
    charts: [
      {
        id: "chart_GicMbh2MkYmfZHrNZMtWD12Y3LakjXIo",
        board_id: "board_GYDs3YglLBVfzu5BkzrnZ64CCgtCNR5g",
        name: "Failed authorizations",
        description: "",
        position_x: 0,
        position_y: 0,
        size: 3,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND has_failed == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:55:42.223774Z",
      },
      {
        id: "chart_fu4SzsSxaMAlYehh8x8SHcX1vbPoXjPS",
        board_id: "board_GYDs3YglLBVfzu5BkzrnZ64CCgtCNR5g",
        name: "Top 5 error codes evolution",
        description: "",
        position_x: 3,
        position_y: 0,
        size: 9,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    path: error_code;\n    top: 5;\n    plot{\n        path:created_at;\n        formula: count{\n            path:transaction_operations;\n            default: 0;\n            type == "authorization"\n            AND has_failed == true\n            AND is_user_retry == false\n            AND is_last_payment_attempt == true;\n        }\n    }\n    ;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:56:31.214129Z",
      },
      {
        id: "chart_tw1E4rmRlS4NuuHsQfP5PQTRnBokfX0I",
        board_id: "board_GYDs3YglLBVfzu5BkzrnZ64CCgtCNR5g",
        name: "Top 10 error codes",
        description: "",
        position_x: 0,
        position_y: 6,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:error_code;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND has_failed == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T13:58:32.047794Z",
      },
      {
        id: "chart_b4gdJuFQHLSYdd4RBlGtSTJOMUYxPgCR",
        board_id: "board_GYDs3YglLBVfzu5BkzrnZ64CCgtCNR5g",
        name: "Decline rate per payment type",
        description: "",
        position_x: 6,
        position_y: 6,
        size: 6,
        height: 6,
        type: "bar-chart",
        settings: {
          formula:
            'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == false\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T13:59:10.927924Z",
      },
    ],
  },
  {
    name: "Retries analysis",
    description:
      "**Understand your transaction's retries performance:**\n" +
      "* How is your retry strategy performing\n" +
      "* What transactions are retried\n" +
      "* Raw vs Net authorization rates",
    charts: [
      {
        id: "chart_QJPlmI3qBK7bsaDDMd8BW7KIZnLYkN7B",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Router retries",
        description: "",
        position_x: 0,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_last_payment_attempt == true and authorization_retry_attempt \u003e 1\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "count",
        favorite: false,
        created_at: "2020-02-29T14:00:23.878939Z",
      },
      {
        id: "chart_TDRr1oB3f1BkadTnKVZWLKTFJZ2k7WN7",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Retry success rate",
        description: "",
        position_x: 4,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND authorization_retry_attempt \u003e 1;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND authorization_retry_attempt \u003e 1\n    AND is_last_payment_attempt == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:00:38.764261Z",
      },
      {
        id: "chart_aHulXKQ4zxGYdXLsojq1QUohuoPFGj8q",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Saved by router retries",
        description: "",
        position_x: 8,
        position_y: 0,
        size: 4,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "capture"\n    AND is_successful == true and authorization_retry_attempt \u003e 1\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "amount",
        favorite: false,
        created_at: "2020-02-29T14:01:19.596735Z",
      },
      {
        id: "chart_DZwE3hO4bsiPvsDTNalKpLCBAPMGxah3",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Retry success rate evolution",
        description: "",
        position_x: 0,
        position_y: 3,
        size: 12,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND authorization_retry_attempt \u003e 1;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND authorization_retry_attempt \u003e 1\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:01:51.152231Z",
      },
      {
        id: "chart_JdVqUnXfk14c0axDMNsWXfg5FnytKvHg",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Conversion rate without user retries",
        description: "",
        position_x: 0,
        position_y: 9,
        size: 6,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_last_payment_attempt == true;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "request"\n    AND is_last_payment_attempt == true;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:02:35.796539Z",
      },
      {
        id: "chart_qDQ1qgwNhcyH3tspeaIeTv58mBBiXMdf",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Conversion after user retries",
        description: "",
        position_x: 6,
        position_y: 9,
        size: 6,
        height: 3,
        type: "single-value",
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "request"\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:02:59.110943Z",
      },
      {
        id: "chart_0ApOOehvTGXWXHQI1R2rYs0MdQddUsFq",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Conversion rate without grouping user retries",
        description: "",
        position_x: 0,
        position_y: 12,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "request"\n        AND is_last_payment_attempt == true;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:03:44.218579Z",
      },
      {
        id: "chart_wCNK6P8KWXeYqerGYrKaGGQhBQvhMKcb",
        board_id: "board_hc7i5uz3KJKz0BorixDXc2ff6WFpzDMM",
        name: "Conversion rate after grouping user retries",
        description: "",
        position_x: 6,
        position_y: 12,
        size: 6,
        height: 6,
        type: "line-chart",
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n    }\n    ;\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n    / count{\n        path:transaction_operations;\n        default: 0;\n        type == "request"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n}\n;',
        },
        margin_left: 0,
        on_new_line: false,
        unit: "percentage",
        favorite: false,
        created_at: "2020-02-29T14:04:00.437714Z",
      },
    ],
  },
  {
    name: "Network tokens",
    description: "**Get a clear view on your network token performance**",
    charts: [
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T14:55:56.087073Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_ccN0awrPoF5jgu3GlwY7YBUk5hHJsVtf",
        margin_left: 0,
        name: "Auth Rate",
        on_new_line: false,
        position_x: 0,
        position_y: 9,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_network_token == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == true;}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T15:08:33.56865Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_8bvxTHjHXoQqTWBQXpRtqh7NxchbEZbS",
        margin_left: 0,
        name: "3DS success rate",
        on_new_line: false,
        position_x: 0,
        position_y: 15,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_successful == true\n    AND is_last_payment_attempt == true\n    AND is_network_token == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_last_payment_attempt == true\n    AND is_network_token == true;}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T15:10:07.90205Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_mN53kOJt2aHFZUHotiTTWvw0bDsWT02B",
        margin_left: 0,
        name: "3DS challenge rate",
        on_new_line: false,
        position_x: 3,
        position_y: 15,
        settings: {
          formula:
            'count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND payment_type == "three-d-s-2"\n        AND type == "three_d_s_check"\n        AND three_d_s.challenged == true\n        AND is_network_token == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND payment_type == "three-d-s-2"\n        AND type == "three_d_s_check"\n        AND is_network_token == true;\n    }',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T15:21:58.129599Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_QXsJ37s17kfdJqRpMOtIeOv87BRwtagA",
        margin_left: 0,
        name: "Success Authorizations [NT]",
        on_new_line: false,
        position_x: 0,
        position_y: 6,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true\n    AND is_network_token == true\n    AND gateway_configuration_id != "x";}',
        },
        size: 6,
        type: "single-value",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T15:23:55.13668Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_DkIf04cGp7wzcOGOQ2SSpk6qadivYBbr",
        margin_left: 0,
        name: "Success Authentications [NT]",
        on_new_line: false,
        position_x: 0,
        position_y: 12,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    is_user_retry == false\n    AND is_last_payment_attempt == true\n    AND (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_successful == true\n    AND is_network_token == true;}',
        },
        size: 6,
        type: "single-value",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T18:18:34.441074Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_5616bi4VojDPGJOvuWrRpjJaRnIKMuVv",
        margin_left: 0,
        name: "3DS Trigger Rate",
        on_new_line: false,
        position_x: 3,
        position_y: 9,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND payment_type == "three-d-s-2"\n    AND type == "three_d_s_check"\n    AND three_d_s.challenged == false\n    AND is_network_token == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND payment_type == "three-d-s-2"\n    AND type == "three_d_s_check"\n    AND is_network_token == true;}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-03-22T15:42:11.934625Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_MlZCpCqSzWXhsPwh3UvyDaLyhbeRIovI",
        margin_left: 0,
        name: "text",
        on_new_line: false,
        position_x: 0,
        position_y: 0,
        settings: {
          formula: "# **Quick stats**\n\nxxx gateways\n",
        },
        size: 12,
        type: "text",
        unit: "",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-04-25T10:44:45.879498Z",
        description: "",
        favorite: false,
        height: 6,
        id: "chart_rAYwj1EpjTnUw4EvVI75eDmhxUtYqwTo",
        margin_left: 0,
        name: "NT Authorizations by Gateway ID",
        on_new_line: false,
        position_x: 0,
        position_y: 27,
        settings: {
          formula:
            'plot{\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    path: gateway_configuration_id;\n    plot{\n        path:created_at;\n        formula: count{\n            path:transaction_operations;\n            default: 0;\n            type == "authorization"\n            AND is_successful == true\n            AND is_user_retry == false\n            AND is_last_payment_attempt == true\n            AND is_network_token == true;\n        }\n    };}',
        },
        size: 9,
        type: "line-chart",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-04-25T10:45:23.575828Z",
        description: "",
        favorite: false,
        height: 6,
        id: "chart_yl31sHkjVtl2WBjLfZ7raI3jtxFMwATU",
        margin_left: 0,
        name: "NT 3DS Authen by Gateway ID",
        on_new_line: false,
        position_x: 0,
        position_y: 33,
        settings: {
          formula:
            'plot{\n    formula: count{\n        path: transaction_operations;\n    };\n    path: gateway_configuration_id;\n    plot{\n        path:created_at;\n        formula: count{\n    path:transaction_operations;\n    default: 0;\n    is_user_retry == false\n    AND is_last_payment_attempt == true\n    AND (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_successful == true\n    AND is_network_token == true;\n        }\n    };}\n    \n',
        },
        size: 9,
        type: "line-chart",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-04-25T10:55:28.308246Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_1dwUf4YYljFVjbSniZKNv0kgHO51hb7Z",
        margin_left: 0,
        name: "text",
        on_new_line: false,
        position_x: 0,
        position_y: 18,
        settings: {
          formula: "# **Ramp-up progress**",
        },
        size: 12,
        type: "text",
        unit: "",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-04-25T10:56:37.330956Z",
        description: "",
        favorite: false,
        height: 6,
        id: "chart_cQG2W1DkU3BY8mkPALkIoYU2dR4CZIRi",
        margin_left: 0,
        name: "% of Auth as NT",
        on_new_line: false,
        position_x: 0,
        position_y: 21,
        settings: {
          formula:
            'plot{\n    path:created_at;\n    formula:\n        count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == false;};\n    }\n};',
        },
        size: 9,
        type: "line-chart",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:09:29.357935Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_8F4bKH7e5BisR8ZRMrZpHOSKcaHqFKzL",
        margin_left: 0,
        name: "text",
        on_new_line: false,
        position_x: 6,
        position_y: 3,
        settings: {
          formula: "Excludes Network Tokens",
        },
        size: 6,
        type: "text",
        unit: "",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:11:36.05384Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_lA4uyLGJciLvSJUIcCZtEBdkekyaJmTe",
        margin_left: 0,
        name: "text",
        on_new_line: false,
        position_x: 0,
        position_y: 3,
        settings: {
          formula: "Network Tokens",
        },
        size: 6,
        type: "text",
        unit: "",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:20:43.502995Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_suPQQeohMNoa77coLpW4w3DlNVMwneXT",
        margin_left: 0,
        name: "Success Authorizations",
        on_new_line: false,
        position_x: 6,
        position_y: 6,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";}',
        },
        size: 6,
        type: "single-value",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:20:47.884631Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_4rDT47UnKuTQuatIHO5yktWTHW85c806",
        margin_left: 0,
        name: "Auth Rate",
        on_new_line: false,
        position_x: 6,
        position_y: 9,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:20:52.028263Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_MelckwowbGxKLIqZ0TSm4N4YvWagcp8T",
        margin_left: 0,
        name: "3DS Trigger Rate",
        on_new_line: false,
        position_x: 9,
        position_y: 9,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND payment_type == "three-d-s-2"\n    AND type == "three_d_s_check"\n    AND three_d_s.challenged == false\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";\n} / count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND payment_type == "three-d-s-2"\n    AND type == "three_d_s_check"\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:20:56.673885Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_7Zn71u6EPcbMKrHF4KMuoWZirjJnHdeJ",
        margin_left: 0,
        name: "Success Authentications [NT]",
        on_new_line: false,
        position_x: 6,
        position_y: 12,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    is_user_retry == false\n    AND is_last_payment_attempt == true\n    AND (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_successful == true\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";}',
        },
        size: 6,
        type: "single-value",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:21:02.155363Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_UQj0LNv3rOCQlel62K7W7SJqGBwI542Z",
        margin_left: 0,
        name: "3DS success rate",
        on_new_line: false,
        position_x: 6,
        position_y: 15,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_successful == true\n    AND is_last_payment_attempt == true\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";\n} / count{\n    path:transaction_operations;\n    default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_last_payment_attempt == true\n    AND is_network_token == false\n    AND gateway_configuration_id != "x";}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-16T13:21:06.781032Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_7g23vndoqBh7esdsn0Elk65g6Ozq8OVv",
        margin_left: 0,
        name: "3DS challenge rate",
        on_new_line: false,
        position_x: 9,
        position_y: 15,
        settings: {
          formula:
            'count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND payment_type == "three-d-s-2"\n        AND type == "three_d_s_check"\n        AND three_d_s.challenged == true\n        AND is_network_token == false\n        AND gateway_configuration_id != "x";\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND payment_type == "three-d-s-2"\n        AND type == "three_d_s_check"\n        AND is_network_token == false\n        AND gateway_configuration_id != "x";\n    }',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-23T09:53:39.026478Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_u5hSKbqOVb5hJ7zTXJrxxFCTxwbPLbWx",
        margin_left: 0,
        name: "% of Auth as NT",
        on_new_line: false,
        position_x: 9,
        position_y: 21,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == false;}',
        },
        size: 3,
        type: "single-value",
        unit: "percentage",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-23T09:58:23.951415Z",
        description: "",
        favorite: false,
        height: 6,
        id: "chart_YhKbuQVg6ctuVtzoJAuDrmYBJY0I7Ypr",
        margin_left: 0,
        name: "NT Authorizations by Gateway ID",
        on_new_line: false,
        position_x: 9,
        position_y: 27,
        settings: {
          formula:
            'plot{\n    path:gateway_configuration_id;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true\n        AND is_network_token == true;\n    }\n};',
        },
        size: 3,
        type: "pie-chart",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-23T10:00:26.221734Z",
        description: "",
        favorite: false,
        height: 3,
        id: "chart_zvREuGT1Db3zRtuiOA5gxUDcMNboMeya",
        margin_left: 0,
        name: "Number of Auth as NT",
        on_new_line: false,
        position_x: 9,
        position_y: 24,
        settings: {
          formula:
            'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_network_token == true;}',
        },
        size: 3,
        type: "single-value",
        unit: "count",
      },
      {
        board_id: "board_z1Pp1kjye26OzPpBa2XRoqOkaBt3a5IH",
        created_at: "2023-05-23T10:01:44.495807Z",
        description: "",
        favorite: false,
        height: 6,
        id: "chart_WIPXCUEVd73QKrD3EWluCXs2xRSkS6OD",
        margin_left: 0,
        name: "NT 3DS Authen by Gateway ID",
        on_new_line: false,
        position_x: 9,
        position_y: 33,
        settings: {
          formula:
            'plot{\n    path:gateway_configuration_id;\n    strategy: value_descending;\n    top: 10;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        is_user_retry == false\n        AND is_last_payment_attempt == true\n        AND (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_network_token == true;\n    }\n};',
        },
        size: 3,
        type: "pie-chart",
        unit: "count",
      },
    ],
  },
]
