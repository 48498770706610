import React from "react"
import { put, takeLatest } from "redux-saga/effects"
import type { $RequestConfirmationAction } from "./consts"
import { REQUEST_CONFIRMATION } from "./consts"
import ConfirmButton from "./ConfirmButton"
import { OPEN_MODAL } from "^/util/Types"
import CancelButton from "^/features/ConfirmModal/CancelButton"

function* requestConfirmation(action: $RequestConfirmationAction): Generator<any, any, any> {
  const header = "Confirmation"
  const { content } = action.payload
  const { confirmTimeout } = action.payload
  const footer = (
    <div className="row">
      <div className="large-12 columns text-right">
        <CancelButton rejectCallback={() => action.payload.reject()} />
        <ConfirmButton confirmTimeout={confirmTimeout} confirmCallback={action.payload.resolve} />
      </div>
    </div>
  )
  yield put({
    type: OPEN_MODAL,
    payload: {
      header,
      content,
      footer,
    },
  })
}

export default function* watch(): Generator<any, any, any> {
  yield takeLatest(REQUEST_CONFIRMATION, requestConfirmation)
}
