import React from "react"
import { connect } from "react-redux"
import Name from "./InvoiceEdit/Name"
import Prices from "./InvoiceEdit/Prices"
import Data from "./InvoiceEdit/Data"
import Metadata from "./Metadata"
import * as InvoicesActions from "../Actions/InvoicesActions"
import ModalFooter from "./ModalFooter"
import { START_INVOICE_CREATION } from "^/Actions/InvoicesActions"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { Product } from "../types/Product"
import type { CurrenciesState } from "^/stores/Reducers/CurrenciesReducer.js"
import { track } from "../vNext/tracking"

const serialize = require("form-serialize")

type Props = {
  modalName: string
  invoice?: Product
  customer?: string
  recurring?: boolean
  currenciesDetails: CurrenciesState
} & $Dispatcher
type State = {
  create: boolean
  showDetails: boolean
}
export class InvoiceModal extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      create: false,
      showDetails: false,
    }
  }

  componentDidMount() {
    if (this.props.invoice) {
      this.id = this.props.invoice.id
    }

    this.create = !this.props.invoice

    if (this.create) {
      this.props.dispatch({
        type: START_INVOICE_CREATION,
      })
    }

    this.setState({
      create: this.create,
      customer: this.props.customer,
    })
  }

  createResponse(response) {
    this._modalFooter.getWrappedInstance().unlock()

    if (response.success) {
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }
  }

  save(event) {
    event.preventDefault()

    track("Products", "Click", "New product modal", "Save button")

    this._modalFooter.getWrappedInstance().lock()

    const invoiceForm = document.querySelector("#invoice-form")
    const data = serialize(invoiceForm, {
      hash: true,
    })
    if (!data.metadata) data.metadata = {}
    data.metadata.request_email = data.request_email
    data.metadata.request_shipping = data.request_shipping

    const metas = this._meta.updateValue()

    for (let i = 0; i < metas.length; i++) {
      data.metadata[metas[i].key] = metas[i].value
    }

    if (data.metadata && Object.keys(data.metadata).length == 0) {
      delete data.metadata
    }

    if (this.props.recurring) {
      data.customer_id = this.props.customer
    }

    if (!data.amount) {
      data.amount = 0
    }

    data.amount = parseFloat(data.amount)
    data.amount += ""
    const invoice = JSON.stringify(data)

    if (this.state.create) {
      track("Products", "Click", "New product modal", "Create button")
      this.props.dispatch(InvoicesActions.createInvoice(invoice, this.createResponse.bind(this)))
    } else {
      this.props.dispatch(
        InvoicesActions.editInvoice(invoice, this.props.invoice.id, this.createResponse.bind(this)),
      )
    }
  }

  deleteInvoice() {
    this._modalFooter.getWrappedInstance().lock()

    if (confirm("Are you sure you want to delete this product?")) {
      this.props.dispatch(InvoicesActions.deleteInvoice(this.props.invoice.id))
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    } else {
      this._modalFooter.getWrappedInstance().unlock()
    }
  }

  openModal() {
    const { invoice } = this.props
    let invoiceUrl

    if (invoice) {
      invoiceUrl = (
        <a
          target="_blank"
          href={invoice.url}
          className="greyed"
          rel="noreferrer"
          onClick={() => {
            track("Products", "Click", "New product modal", "Payment link button")
          }}
        >
          Payment link ↗
        </a>
      )
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: this.props.modalName,
        content: (
          <div className="row">
            <div className="large-12 columns">
              <form id="invoice-form" onSubmit={this.save.bind(this)}>
                <div className="row small-margin-bottom">
                  <div className="medium-12 medium-centered columns">
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Details</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <Name invoice={invoice} />
                        <Prices
                          invoice={invoice}
                          currencies={this.props.currenciesDetails.currencies}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Additional data</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <Metadata resource={invoice} ref={m => (this._meta = m)} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">KYC handling</h6>
                      </div>
                    </div>
                    <div className="row margin-bottom">
                      <div className="large-12 columns">
                        <Data invoice={invoice} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns text-right">{invoiceUrl}</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ),
        footer: (
          <ModalFooter
            ref={m => (this._modalFooter = m)}
            submitTitle={this.props.invoice ? "Save" : "Create"}
            submitCallback={this.save.bind(this)}
            cancelCallback={() => track("Products", "Click", "New product modal", "Cancel button")}
            deleteCallback={() => {
              track("Products", "Click", "New product modal", "Delete button")
              this.props.invoice ? this.deleteInvoice.bind(this) : null
            }}
          />
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(
  store => ({
    currenciesDetails: store.currencies,
    invoiceDetails: store.invoiceDetails,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(InvoiceModal)
