import React from "react"
import Creatable from "react-select/creatable"
import { SelectStyle } from "../consts"
type Props = {
  value: number | null | undefined
  onChange: (newValue: number) => void
}

class TopSelection extends React.Component<Props> {
  onChange = (newValue: { label: number; value: number }) => {
    const { onChange } = this.props
    onChange(newValue.value)
  }

  render() {
    const { value } = this.props
    return (
      <div
        className="labeled-field"
        style={{
          width: "85px",
        }}
      >
        <Creatable
          options={[
            {
              label: 5,
              value: 5,
            },
            {
              label: 10,
              value: 10,
            },
            {
              label: 15,
              value: 15,
            },
            {
              label: 25,
              value: 25,
            },
            {
              label: 50,
              value: 50,
            },
            {
              label: 100,
              value: 100,
            },
          ]}
          value={{
            label: value,
            value,
          }}
          clearable={false}
          creatable
          onChange={this.onChange}
          styles={SelectStyle}
        />
      </div>
    )
  }
}

export default TopSelection
