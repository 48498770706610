import { FETCH_GATEWAYS } from "^/Actions/GatewaysActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetched: false,
    fetching: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_GATEWAYS): {
      return { ...state, fetching: true, fetched: false, error: false }
    }

    case typeFulfilled(FETCH_GATEWAYS): {
      return {
        ...state,
        ...action.payload,
        gateways: action.payload.gateways,
        fetching: false,
        fetched: true,
      }
    }
  }

  return state
}
