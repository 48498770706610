import type { $CurrentProject, $Dispatcher, $RouterLocation } from "^/util/Types"
import type {
  $ChartDatasFromApi,
  $PerCountryTableData,
  $Period,
  $TelescopeMetricData,
} from "../consts"

import Loading from "^/components/Loader"
import ContentLayout from "^/pages/ContentLayout"
import { prepareTelescopeV2Page } from "../actions"
import BarChartTooltip from "../components/BarChartTooltip"
import PerCountryTable from "../components/PerCountryTable"
import SummaryCards from "../components/SummaryCards/SummaryCards"
import TabsState from "../components/TabsPicker/TabsState"
import TelescopeHeader from "../components/TelescopeHeader"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"
import RefundsFailedLineCharts from "./RefundsFailedLineCharts"
import RefundsLineCharts from "./RefundsLineCharts"

import React, { useEffect } from "react"
import { connect } from "react-redux"

export type $MetricsTelescope = {
  refunds_rate: number
  refunds_rate_increase: number
  refunds_amount: number
  refunds_number: number
  failed_refunds_rate: number
  failed_refunds_rate_increase: number
  failed_refunds_amount: number
  failed_refunds_number: number
  refunds_rate_chart: $ChartDatasFromApi[]
  failed_refunds_rate_chart: $ChartDatasFromApi[]
  refunds_rate_by_gateway: $ChartDatasFromApi[]
  failed_refunds_rate_by_gateway: $ChartDatasFromApi[]
  refunds_rate_by_card_scheme: $ChartDatasFromApi[]
  failed_refunds_rate_by_card_scheme: $ChartDatasFromApi[]
  refunds_rate_by_payment_method: $ChartDatasFromApi[]
  failed_refunds_rate_by_payment_method: $ChartDatasFromApi[]
  refundsTableData: Array<$PerCountryTableData>
  failedRefundsTableData: Array<$PerCountryTableData>
  fetched: boolean
  fetching: boolean
}

type Props = {
  location: $RouterLocation
  metricsTelescope: $MetricsTelescope
  currentProject: $CurrentProject
} & $Dispatcher

export function Refunds({ location, dispatch, metricsTelescope, currentProject }: Props) {
  useEffect(() => {
    document.title = "Refunds | ProcessOut"
  }, [])

  useEffect(() => {
    dispatch(prepareTelescopeV2Page("refunds", location.query.period as $Period))
  }, [location.query.period])

  const summaryMetrics: $TelescopeMetricData[] = [
    {
      title: "Refunds",
      value: metricsTelescope.refunds_rate,
      percentageEvolution: metricsTelescope.refunds_rate_increase,
      id: "refunds_rate",
      tooltipContent: "Total volume, value and percentage of transactions that have been refunded",
      valueStyle: "percent",
      moreDetails: true,
      amount: metricsTelescope.refunds_amount,
      count: metricsTelescope.refunds_number,
    },
    {
      title: "Refunds failed",
      value: metricsTelescope.failed_refunds_rate,
      percentageEvolution: metricsTelescope.failed_refunds_rate_increase,
      id: "failed_refunds_rate",
      tooltipContent:
        "Total volume, value and percentage of refunds requested that have not been successfully processed",
      valueStyle: "percent",
      moreDetails: true,
      amount: metricsTelescope.failed_refunds_amount,
      count: metricsTelescope.failed_refunds_number,
    },
  ]

  const refundsBarChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.refunds_rate_by_gateway,
      tooltipContent: "Breakdown of refunds per payment service provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.refunds_rate_by_card_scheme,
      tooltipContent: "Breakdown of refunds per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.refunds_rate_by_payment_method,
      tooltipContent: "Breakdown of refunds per payment method",
      id: "paymentMethod_id",
    },
  ]

  const failedRefundsBarChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.failed_refunds_rate_by_gateway,
      tooltipContent: "Breakdown of refunds failed per payment service provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.failed_refunds_rate_by_card_scheme,
      tooltipContent: "Breakdown of refunds failed per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.failed_refunds_rate_by_payment_method,
      tooltipContent: "Breakdown of refunds failed per payment method",
      id: "paymentMethod_id",
    },
  ]

  const refundslineChartData = [
    { data: metricsTelescope.refunds_rate_chart, chartTitle: "Refunds" },
  ]
  const refundsFailedlineChartData = [
    { data: metricsTelescope.failed_refunds_rate_chart, chartTitle: "Refunds failed" },
  ]
  const loading = !metricsTelescope.fetched || metricsTelescope.fetching

  const tabs = [
    {
      label: "Refunds",
      content: (
        <>
          {loading ? (
            <Loading />
          ) : (
            <div data-tracking-location="Refunds">
              <div className="small-margin-bottom">
                <RefundsLineCharts datas={refundslineChartData} />
              </div>
              <PerCountryTable
                tableData={metricsTelescope.refundsTableData}
                currentProject={currentProject}
                title="Refunds per country"
                tooltipContent="Breakdown of refunds rate per country"
              />
              <div className="large-margin-top row">
                {refundsBarChartsData.map(data => (
                  <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                    <BarChartTooltip value={data} />
                    <BarChartHorizontal
                      axisLeft={150}
                      datas={chartFormattedData(data.value)}
                      name={data.name}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      label: "Refunds failed",
      content: (
        <>
          {loading ? (
            <Loading />
          ) : (
            <div data-tracking-location="Refunds Failed">
              <div className="small-margin-bottom">
                <RefundsFailedLineCharts datas={refundsFailedlineChartData} />
              </div>
              <PerCountryTable
                tableData={metricsTelescope.failedRefundsTableData}
                currentProject={currentProject}
                title="Refunds failed per country"
                tooltipContent="Breakdown of refunds failed per country"
              />
              <div className="large-margin-top row">
                {failedRefundsBarChartsData.map(data => (
                  <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                    <BarChartTooltip value={data} />
                    <BarChartHorizontal
                      axisLeft={150}
                      datas={chartFormattedData(data.value)}
                      name={data.name}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ),
    },
  ]

  return (
    <div data-tracking-sub-page="Refunds">
      <ContentLayout
        title={<TelescopeHeader title="Refunds" />}
        noBackground
        padding="0"
        data-tracking-location="Summary"
      >
        <div className="large-margin-bottom small-margin-top">
          <SummaryCards data={summaryMetrics} loading={loading} />
        </div>
      </ContentLayout>
      <div className="large-margin-top" data-tracking-location="Analytics">
        <TabsState tabs={tabs} />
      </div>
    </div>
  )
}

export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.refunds,
  currentProject: store.currentProject,
}))(Refunds)
