import { put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { default as Uniqid, default as uniqid } from "uniqid"
import * as GatewaysActions from "../../Actions/GatewaysActions"
import * as ProcessOut from "../../util/ProcessOut"
import type { $Action } from "../../util/Types"
import { fetchFraudServicesConfig } from "../FraudServicesConfiguration/actions"
import * as GatewaysActionsBis from "../GatewaysConfigurations/actions"
import type { $RemoveRuleAction, $UpdateRuleAction } from "./actions"
import * as Actions from "./actions"
import type { $RoutingRule } from "./consts"
import {
  ADD_RULE,
  NORMALIZATION_FIELDS,
  PREPARE_ROUTING_RULES_SETTINGS,
  REMOVE_RULE,
  REQUEST_RULES_SAVING,
  RULES_FILTERS,
  SAVE_ROUTING_RULES,
  UPDATE_RULE,
} from "./consts"
import * as Utils from "./Utils"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { $Filter } from "./RoutingRulesBuilder/Filter/consts"

/**
 * Fetches all the
 */
export function* prepareRoutingRulesSettings(): Generator<any, any, any> {
  try {
    // fetching all the gateways configurations
    yield put.resolve(GatewaysActions.loadGatewaysConfigurations())
    const gateways = yield select<any>(store => store.processorsConfigurations)

    if (gateways.error) {
      yield put({
        type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS),
      })
      throw "Could not fetch gateway configurations."
    }

    // fetching each gateway names
    yield put.resolve(GatewaysActionsBis.fetchGwayConfNames())
    const gatewaysNames = yield select<any>(store => store.gateway_configurations_names)

    if (gatewaysNames.error) {
      yield put({
        type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS),
      })
      throw "Could not fetch gateway configurations names."
    }

    yield put.resolve(fetchFraudServicesConfig())
    const fraudServices = yield select<any>(store => store.fraudServiceConfigurationsReducer)

    if (fraudServices.error) {
      yield put({
        type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS),
      })
      throw "Could not fetch fraud services."
    }

    // fetching all the current rules
    const rulesResult = yield put.resolve(Actions.fetchRules())

    if (rulesResult.value.status !== 200) {
      throw "Could not fetch routing rules"
    }

    const compileResult = yield put.resolve(
      Actions.compileRules(rulesResult.value.data.routing_rules),
    )
    const fetchedPaths = []
    const rules = compileResult.value.data.routing_rules_compiled.map(rule => {
      const compiledFilters = rule.condition_token_steps.reduce(
        (value, token) => {
          if (token.kind !== "LOGICALOP") {
            value[value.length - 1].push(token)
          } else {
            value.push([])
          }

          return value
        },
        [[]],
      )

      // we remove the last empty array
      if (compiledFilters[compiledFilters.length - 1].length === 0) {
        compiledFilters.splice(compiledFilters.length - 1, 1)
      }

      const filtersCount = compiledFilters.length
      const filters = []

      for (let i = 0; i < filtersCount; i++) {
        const filter: $Filter = {
          id: uniqid(),
          path: "",
          value: [],
          operand: "==",
        }

        if (compiledFilters[i][0].kind !== "VARIABLE") {
          if (compiledFilters[i][0].kind === "ACCESSOR") {
            // we check if we're dealing with metadatas
            const { value } = compiledFilters[i][0]

            if (value.length > 1 && value[0] === "metadata") {
              filter.path = `${value[0]}.${value[1]}`
            } else {
              // unknown filter
              throw new Error(`Could not parse routing rules filter ${value}`)
            }
          } else if (
            compiledFilters[i][0].kind === "FUNCTION" &&
            compiledFilters[i][0].value.name === "rand"
          ) {
            filter.path = "rand"
            const operand = compiledFilters[i].find(entry => entry.kind === "COMPARATOR")
            if (!operand) continue
            filter.operand = operand.value
            const value = compiledFilters[i].find(entry => entry.kind === "NUMERIC")
            if (!value) continue
            filter.value = [value.value]
            filters.push(filter)
            continue
          } else if (
            compiledFilters[i][0].kind === "FUNCTION" &&
            compiledFilters[i][0].value.name === "action"
          ) {
            const operand = compiledFilters[i].find(entry => entry.kind === "COMPARATOR")
            if (!operand) continue
            filter.operand = operand.value
            const strings = compiledFilters[i].filter(entry => entry.kind === "STRING")
            if (strings.length < 2) continue
            filter.path = `action("${strings[0].value}")`
            strings.slice(1).map(string => {
              ;(filter.value as any).push(string.value)
            })
            filters.push(filter)
            continue
          } else {
            continue
          }
        } else if (rule.variables[compiledFilters[i][0].value]) {
          // We're dealing with a variable
          filter.path = rule.variables[compiledFilters[i][0].value].type

          if (filter.path === "velocity") {
            ;(filter as any).velocityPath = rule.variables[compiledFilters[i][0].value].path
            ;(filter as any).interval = rule.variables[compiledFilters[i][0].value].interval
          }
        } else {
          filter.path = compiledFilters[i][0].value
        }

        if (!fetchedPaths.includes(filter.path)) {
          // We need to fetch possible values for this path
          fetchedPaths.push(filter.path)
        }

        // Now let's look for the operand and value
        if (
          compiledFilters[i][1].kind === "COMPARATOR" &&
          (compiledFilters[i][1].value === "in" || compiledFilters[i][1].value === "in_insensitive")
        ) {
          // multi value filters
          // Need to find if there is a negation
          let negativeExpression = false

          for (let j = 2; j < compiledFilters[i].length - 1; j++) {
            if (
              (compiledFilters[i][j].value === "==" || compiledFilters[i][j].value === "===") &&
              compiledFilters[i][j + 1].value === false
            ) {
              negativeExpression = true
              break //  Shouldn't be necessary as FALSE should be the last token
            }
          }

          if (negativeExpression) {
            filter.operand = "!="

            if (compiledFilters[i][1].value === "in_insensitive") {
              filter.operand = "!=="
            }
          } else {
            filter.operand = "=="

            if (compiledFilters[i][1].value === "in_insensitive") {
              filter.operand = "==="
            }
          }

          const values = []
          let valueCount = 3

          while (
            valueCount < compiledFilters[i].length &&
            compiledFilters[i][valueCount].kind !== "CLAUSE_CLOSE" &&
            compiledFilters[i][valueCount].kind !== "COMPARATOR" &&
            compiledFilters[i][valueCount].kind !== "BOOLEAN"
          ) {
            values.push(compiledFilters[i][valueCount].value)
            valueCount += 2 // We skip the comma token
          }

          filter.value = values
        } else if (compiledFilters[i][2].value === "null") {
          if (compiledFilters[i][1].value === "==" || compiledFilters[i][1].value === "===")
            filter.operand = "is-null"
          else if (compiledFilters[i][1].value === "!=" || compiledFilters[i][1].value === "!==")
            filter.operand = "is-not-null"
        } else {
          filter.operand = compiledFilters[i][1].value
          filter.value = [compiledFilters[i][2].value]
        }

        filters.push(filter)
      }

      let dynamic3dsParams

      if (rule.dynamic_3ds_params) {
        dynamic3dsParams = Utils.getDynamicRulesFormatted(rule.dynamic_3ds_params, "fetch")
      }

      return {
        gateways: !rule.gateways
          ? []
          : rule.gateways.map(gateway => {
              if (gateway.includes("processout")) {
                return Utils.parseSmartRoutingGateway(gateway)
              }

              return {
                id: uniqid(),
                gateway,
              }
            }),
        conditions: [
          {
            logical: "and",
            filters,
          },
        ],
        declaration: rule.declaration,
        dynamic_3ds_params: dynamic3dsParams && dynamic3dsParams,
        run_for_card_verifications: rule.run_for_card_verifications,
        slow_gateway_detector: rule.slow_gateway_detector,
        no_retry_error_codes: rule.no_retry_error_codes,
        external_3ds_providers: rule.external_3ds_providers,
        tags:
          rule.tags ||
          Utils.computeRuleTags([
            {
              logical: "and",
              filters,
            },
          ]),
        id: uniqid(),
      }
    })

    // Check if we have any routing rules
    if (rules.filter(rule => rule.declaration === "route").length === 0) {
      // We need to add a default rule
      rules.push({
        id: uniqid(),
        declaration: "route",
        tags: [],
        gateways: [
          {
            id: uniqid(),
            gateway: "processout",
            configurations: ["all"],
          },
        ],
        conditions: [
          {
            subGroup: null,
            logical: "and",
            filters: [],
          },
        ],
      })
    }

    // dispatching that we're ready to display the page
    yield put({
      type: typeFulfilled(PREPARE_ROUTING_RULES_SETTINGS),
      payload: {
        rules,
        rulesRaw: rulesResult.value.data.routing_rules,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREPARE_ROUTING_RULES_SETTINGS),
      payload: error,
    })
    datadogRum.addError(error)
  }
}

/**
 * Add a rule to the rules list
 */
function* addRule(action: $Action): Generator<any, any, any> {
  const gateways = yield select<any>(store => store.processorsConfigurations)
  const gateway = gateways.configurations.find(config => config.enabled)
  const newRule: $RoutingRule = {
    id: Uniqid(),
    conditions: [
      {
        subGroup: null,
        logical: "and",
        filters: [],
      },
    ],
    tags: [],
    declaration: action.payload.type,
    gateways:
      action.payload.type !== "route" && action.payload.type !== "dynamic_3ds"
        ? []
        : gateway
        ? [
            {
              id: Uniqid(),
              gateway: gateway.id,
            },
          ]
        : [
            {
              id: Uniqid(),
              gateway: "",
            },
          ],
    new: true,
  }
  yield put.resolve({
    type: typeFulfilled(ADD_RULE),
    payload: {
      rule: newRule,
    },
  })
}

/**
 * Removes a rule from the list
 * @param action (Should contain the generated id
 */
function* removeRule(action: $RemoveRuleAction): Generator<any, any, any> {
  // retrieve all the current rules
  const rules = yield select<any>(store => store.routingRulesSettings.rules)
  // find the index of the corresponding rule we want to remove from the list
  const ruleIndex = rules.findIndex(rule => rule.id === action.payload.id)

  if (ruleIndex !== -1) {
    // index found we delete the rule
    rules.splice(ruleIndex, 1)
    yield put({
      type: typeFulfilled(REMOVE_RULE),
      payload: {
        rules,
      },
    })
  } else {
    ProcessOut.addNotification(
      "The rule you want to remove could not be found. Please refresh the page",
    )
  }
}

/**
 * Update a rule
 * @param action
 */
function* updateRule(action: $UpdateRuleAction): Generator<any, any, any> {
  const { rule } = action.payload
  // Retrieve all the current rules
  const rules = yield select<any>(store => store.routingRulesSettings.rules)
  // look for the rule we want to update
  const ruleIndex = rules.findIndex(entry => entry.id === rule.id)
  if (ruleIndex === -1) return
  // replace the rule
  rules[ruleIndex] = rule
  // dispatch the new array
  yield put.resolve({
    type: typeFulfilled(UPDATE_RULE),
    payload: {
      rules,
    },
  })
}

function* requestRulesSaving(): Generator<any, any, any> {
  try {
    let globalFormula = ""
    const rulesSettings = yield select<any>(store => store.routingRulesSettings)
    const { rules } = rulesSettings
    // order rules by declaration/type
    const blockingRules = rules.filter(rule => rule.declaration === "block")
    const triggerRules = rules.filter(rule => rule.declaration === "trigger_3ds")
    const dynamic3ds = rules.filter(rule => rule.declaration === "dynamic_3ds")
    const routingRules = rules.filter(rule => rule.declaration === "route")
    const orderedRules = blockingRules.concat(triggerRules).concat(dynamic3ds).concat(routingRules)

    for (const rule of orderedRules) {
      const filters = rule.conditions.reduce((value, condition) => {
        // we check if all filters are correct (i.e. no missing values etc.)
        condition.filters = condition.filters.filter(filter => {
          // If operand is is null or is not null, ignore value filtering just check path
          if (filter.operand === "is-null" || filter.operand === "is-not-null") {
            return filter.path
          }

          return (
            filter.path &&
            filter.operand &&
            filter.value !== null &&
            filter.value !== undefined &&
            (typeof filter.value === "boolean" ||
              // FIXME: I assume it should be !(filter.value instanceof Array)
              // @ts-ignore
              !filter.value instanceof Array ||
              filter.value.length > 0)
          )
        })
        const filterString = condition.filters
          .filter(filter => filter.path && filter.value !== null && filter.value !== undefined)
          .map((filter, index, array) => {
            if (NORMALIZATION_FIELDS.includes(filter.path)) {
              switch (filter.operand) {
                case "==":
                  filter.operand = "==="
                  break

                case "!=":
                  filter.operand = "!=="
                  break
              }
            }

            const correspondingFilter = RULES_FILTERS[filter.path]

            if (correspondingFilter && correspondingFilter.type === "boolean") {
              filter.operand = "==" // We set the equal operator for every boolean value
            } else if (filter.path === "rand") {
              // or rand()
              filter.path = "rand()"
            } else if (filter.path === "velocity") {
              // or Velocity
              return `velocity{path:${filter.velocityPath}; interval:${filter.interval}} ${filter.operand} ${filter.value}`
            }

            if (filter.operand === "is-null") {
              return `${filter.path} == null${index === array.length - 1 ? ";" : " AND "}`
            }

            if (filter.operand === "is-not-null") {
              return `${filter.path} != null${index === array.length - 1 ? ";" : " AND "}`
            }

            if (
              filter.value instanceof Array &&
              filter.value.length > 1 &&
              (filter.value[0] instanceof String || typeof filter.value[0] === "string")
            ) {
              let operandSub = "IN"
              if (filter.operand === "===" || filter.operand === "!==")
                operandSub = "IN_INSENSITIVE"
              return `${filter.path} ${operandSub} (${filter.value.reduce(
                (acc, val) => `${acc}${acc && ","}"${val}"`,
                "",
              )})${filter.operand === "!=" || filter.operand === "!==" ? " == false" : ""}`
            }

            const correspondingOption = RULES_FILTERS[filter.path]
            // Need to check if we want to store the values as strings or numbers
            let value = ""

            if (filter.path.includes("metadata.")) {
              value = `"${filter.value}"`
            } else if (
              filter.path === "rand()" ||
              (correspondingOption && correspondingOption.type !== "string")
            ) {
              value = filter.value
            } else {
              value = `"${filter.value}"`
            }

            return `${filter.path} ${filter.operand} ${value}`
          })
          .reduce((result, filter) => `${result}${result && " AND "}${filter}`, "")
        return `${value}${filterString}`
      }, "")
      let dynamic3dsParams

      if (rule.dynamic_3ds_params) {
        dynamic3dsParams = Utils.getDynamicRulesFormatted(rule.dynamic_3ds_params, "save")
      }

      let noRetryErrorCodes = ""
      if (rule.declaration === "route" && rule.no_retry_error_codes) {
        noRetryErrorCodes = rule.no_retry_error_codes.reduce(
          (acc, tag) => `${acc}${acc && ","}${tag}`,
          "",
        )
      }

      const formula = `${rule.declaration}{gateways: ${rule.gateways.reduce(
        (value, gateway, index, array) => {
          if (gateway.gateway === "processout" || gateway.gateway === "processout1") {
            // smart routing
            if (
              !gateway.configurations ||
              gateway.configurations.length === 0 ||
              gateway.configurations.includes("all")
            )
              return `${value}${value && ", "}${gateway.gateway}`
            return `${value}${value && ", "}${gateway.gateway}[${gateway.configurations.reduce(
              (value, config) => `${value}${value && " "}${config}`,
              "",
            )}]`
          }

          return `${value}${value && ", "}${gateway.gateway}`
        },
        "",
      )};condition: ${filters === "" ? "true" : filters}; ${
        rule.declaration === "dynamic_3ds"
          ? `dynamic_3ds_params: ${dynamic3dsParams ? JSON.stringify(dynamic3dsParams) : ""};`
          : ""
      } tags: ${rule.tags.reduce((acc, tag) => `${acc}${acc && ","}${tag}`, "")}; ${
        rule.declaration === "trigger_3ds"
          ? `run_for_card_verifications: ${rule.run_for_card_verifications}`
          : ""
      } ${
        rule.declaration === "dynamic_3ds"
          ? `external_3ds_providers: ${rule.external_3ds_providers ?? ""};`
          : ""
      } ${rule.declaration === "route" ? `no_retry_error_codes: ${noRetryErrorCodes};` : ""} ${
        // Default slow_gateway_detector is false, even it does not exist for whatever reason.
        rule.declaration === "route"
          ? `slow_gateway_detector: ${!!rule.slow_gateway_detector};`
          : ""
      }};`

      globalFormula = `${globalFormula}\n${formula}`
    }

    const result = yield put.resolve(Actions.saveRules(globalFormula))

    if (result.value.status === 200) {
      yield put(Actions.prepareRoutingRulesSettings())
    }
  } catch (error) {
    yield put({
      type: typeFailed(SAVE_ROUTING_RULES),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

function* addSuccessNotification(): Generator<any, any, any> {
  ProcessOut.addNotification("Routing rules saved successfully", "success")
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeEvery(PREPARE_ROUTING_RULES_SETTINGS, prepareRoutingRulesSettings)
  yield takeEvery(ADD_RULE, addRule)
  yield takeEvery(REMOVE_RULE, removeRule)
  yield takeEvery(UPDATE_RULE, updateRule)
  yield takeLatest(REQUEST_RULES_SAVING, requestRulesSaving)
  yield takeEvery(typeFulfilled(SAVE_ROUTING_RULES), addSuccessNotification)
}
