import React from "react"
import { connect } from "react-redux"
import Name from "./InvoiceEdit/Name"
import Prices from "./InvoiceEdit/Prices"
import TokenSelect from "./CustomerDetails/TokenSelect"
import Metadata from "./Metadata"
import ModalFooter from "./ModalFooter"
import * as InvoicesActions from "../Actions/InvoicesActions"
import * as CustomerDetailsActions from "../Actions/CustomerDetailsActions"
import { START_INVOICE_CREATION } from "^/Actions/InvoicesActions"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { CurrenciesState } from "^/stores/Reducers/CurrenciesReducer"
import type { CustomerDetailsState } from "^/stores/Reducers/CustomerDetailsReducer"
import { track } from "../vNext/tracking"

const serialize = require("form-serialize")

type Props = {
  customerId: string
  currenciesDetails: CurrenciesState
  customerDetails: CustomerDetailsState
} & $Dispatcher
export class CustomerChargeModal extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: START_INVOICE_CREATION,
    })
  }

  created(invoice) {
    this.props.dispatch(
      InvoicesActions.chargeSingleInvoice(
        invoice.id,
        invoice.metadata.token,
        () => {
          this.props.dispatch({
            type: CLOSE_MODAL,
          })
          this.props.dispatch(
            CustomerDetailsActions.loadCustomerTransactions("", 15, this.props.customerId),
          )
        },
        () => {
          this._modalFooter.getWrappedInstance().unlock()
        },
      ),
    )
  }

  save(event) {
    event.preventDefault()

    track("Customer", "Click", "New payment", "Create button")

    this._modalFooter.getWrappedInstance().lock()

    const invoiceForm = document.querySelector("#invoice-form")
    const data = serialize(invoiceForm, {
      hash: true,
    })
    if (!data.metadata) data.metadata = {}

    const metas = this._meta.updateValue()

    for (let i = 0; i < metas.length; i++) {
      data.metadata[metas[i].key] = metas[i].value
    }

    if (data.metadata && Object.keys(data.metadata).length == 0) {
      delete data.metadata
    }

    data.metadata = { ...data.metadata, token: data.source }
    delete data.source

    if (!data.amount) {
      data.amount = 0
    }

    data.amount = parseFloat(data.amount)
    data.amount += ""

    // This payment should be set to merchant_initiator_type: one-off and initiation_type: mit
    // So the transaction won't be incorrectly interpreted as cit by API
    data.merchant_initiator_type = "one-off"
    data.initiation_type = "mit"

    const invoice = JSON.stringify(data)
    this.props.dispatch(
      InvoicesActions.createSingleInvoice(invoice, this.created.bind(this), () => {
        this._modalFooter.getWrappedInstance().lock()
      }),
    )
  }

  openModal() {
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: "New charge",
        content: (
          <div className="row">
            <div className="large-12 columns">
              <form id="invoice-form" onSubmit={this.save.bind(this)}>
                <div className="row small-margin-bottom">
                  <div className="medium-12 medium-centered columns">
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Details</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <Name />
                        <Prices currencies={this.props.currenciesDetails.currencies} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Source</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <TokenSelect customerId={this.props.customerDetails.details.customer.id} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Additional data</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <Metadata ref={m => (this._meta = m)} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ),
        footer: (
          <ModalFooter
            ref={m => (this._modalFooter = m)}
            submitTitle="Create"
            submitCallback={this.save.bind(this)}
            cancelCallback={() => track("Customer", "Click", "New payment", "Cancel button")}
          />
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(
  store => ({
    currenciesDetails: store.currencies,
    customerDetails: store.customerDetails,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(CustomerChargeModal)
