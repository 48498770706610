import React from "react"
import { FormattedNumber, IntlProvider } from "react-intl"
import type { $PointValue } from "../Consts"
type Props = {
  point: $PointValue
  currency: string
  _countTotal: number
  _valueTotal: number
}

class AmountToolTip extends React.Component<Props> {
  render() {
    const { point, _countTotal, _valueTotal, currency } = this.props
    return (
      <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
        <ul>
          <li>
            <FormattedNumber
              value={point.value}
              style="currency"
              currency={currency}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
            <div
              className="greyed"
              style={{
                display: "inline-block",
              }}
            >
              {` — `}
              <FormattedNumber
                value={point.value / _valueTotal}
                style="percent"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />{" "}
              of total
            </div>
          </li>
          <li>
            <div
              style={{
                display: "inline-block",
              }}
            >
              <FormattedNumber value={point._count} /> transactions
            </div>
            <div
              className="greyed"
              style={{
                display: "inline-block",
              }}
            >
              {` — `}
              <FormattedNumber
                value={point._count / _countTotal}
                style="percent"
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />{" "}
              of total
            </div>
          </li>
        </ul>
      </IntlProvider>
    )
  }
}

export default AmountToolTip
