import React from "react"
import "./style.scss"
import type { $Check } from "../../Types/Transaction"
type Props = {
  check: $Check
}

class Check extends React.Component {
  props: Props

  render() {
    return (
      <span
        className={`log-level check tag ${(() => {
          switch (this.props.check) {
            case "passed":
              return "success"

            case "failed":
            case "required":
              return "failed"

            case "unavailable":
            case "unchecked":
              return "pending"

            default:
              return "failed"
          }
        })()}`}
      >
        {this.props.check}
      </span>
    )
  }
}

export default Check
