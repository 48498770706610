export const REQUEST_CHART_PREVIEW = "REQUEST_CHART_PREVIEW"
export const FETCH_CHART_PREVIEW = "FETCH_CHART_PREVIEW"
export const CLEAR_PREVIEW = "CLEAR_PREVIEW"
export const REQUEST_CHART_SAVE = "REQUEST_CHART_SAVE"
export const SAVE_CHART = "SAVE_CHART"
export type $Params = {
  filter: string
  interval: {
    from: number
    to: number
  }
  timeInterval: string
}
