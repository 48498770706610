import React, { useEffect, useState } from "react"
import Select from "react-select"
import * as CustomerDetailsActions from "../../Actions/CustomerDetailsActions"
import { connect } from "react-redux"
import type { $Dispatcher } from "^/util/Types"
import type { $CustomerTokensState } from "^/stores/Reducers/CustomerDetailsReducer/TokensReducer"
type Props = {
  customerId: string
  tokens: $CustomerTokensState
} & $Dispatcher

function TokenSelect({ customerId, dispatch, tokens }: Props) {
  const [value, setValue] = useState(null)
  useEffect(() => {
    if (!tokens.fetched && tokens.fetching) {
      dispatch(CustomerDetailsActions.fetchTokens(customerId))
    }
  }, [])

  const planSelected = value => {
    setValue(value)
  }

  const fetching = !tokens.fetched && tokens.fetching
  let options = []

  if (!fetching && tokens.fetched) {
    const validTokens = tokens.tokens.filter(token => !token.is_subscription_only)

    for (const token of validTokens) {
      const { gateway_configuration, id, is_chargeable, card } = token
      // https://github.com/processout/api/blob/c7240344bca3388b17dcb59062970ff166eb27f2/database/models/customertoken.go#L510
      // token.type can be 'pending', which means verification is still pending or failed. Therefore we checking
      // the data field directly for two different type: card and gateway
      // is_chargeable is used for identifies whether the payment method is not chargeable or not
      const isChargeable = !is_chargeable ? "(not chargeable)" : ""

      if (gateway_configuration) {
        const fullGatewayName =
          gateway_configuration.gateway.display_name || gateway_configuration.name
        options.push({
          value: id,
          label: `${fullGatewayName} ${isChargeable}`,
          disabled: !is_chargeable,
        })
      } else {
        const label = `${card.scheme} - ${card.last_4_digits}`
        options.push({
          value: id,
          label: `${label} ${isChargeable}`,
          disabled: !is_chargeable,
        })
      }
    }
  }

  return (
    <Select
      className="react-select"
      name="source"
      placeholder="Select a payment method..."
      options={options}
      isLoading={fetching}
      value={value}
      onChange={planSelected}
    />
  )
}

export default connect(store => ({
  tokens: store.customerDetails.tokens,
}))(TokenSelect)
