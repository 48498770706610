import React from "react"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import * as InvoicesActions from "../Actions/InvoicesActions"
import * as CurrencyActions from "../Actions/CurrencyActions"
import Invoice from "../components/Invoice"
import Loader from "../components/Loader"
import Error from "../components/Error"
import Empty from "../components/Empty"
import InvoiceModal from "../components/InvoiceModal"
import Pagination from "../components/Pagination"
import NotPermitted from "../features/Permissions/NotPermitted"
import type { $InvoicesState } from "^/stores/Reducers/InvoicesReducer.js"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
import type { CurrenciesState } from "^/stores/Reducers/CurrenciesReducer.js"
import { track } from "../vNext/tracking"
const INVOICES_PER_PAGE = 30
type Props = {
  invoicesDetails: $InvoicesState
  location: $RouterLocation
  currenciesDetails: CurrenciesState
} & $Dispatcher
type State = {
  filter: string
}

class Invoices extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filter: "",
    }
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Products | ProcessOut"
    const { filter } = this.props.location.query
    this.setState({
      filter,
    })
    this.props.dispatch(
      InvoicesActions.loadInvoices(
        INVOICES_PER_PAGE,
        true,
        this.props.location.query.firstItem,
        filter,
      ),
    )

    if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
      this.props.dispatch(CurrencyActions.loadCurrencies())
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.invoicesDetails.fetching &&
      !nextProps.invoicesDetails.fetched &&
      !nextProps.invoicesDetails.error
    ) {
      this.props.dispatch(InvoicesActions.loadInvoices(INVOICES_PER_PAGE, true))
    }

    if (!nextProps.currenciesDetails.fetching && !nextProps.currenciesDetails.fetched) {
      this.props.dispatch(CurrencyActions.loadCurrencies())
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  openModal() {
    this._modal.getWrappedInstance().openModal()
  }

  render() {
    if (this.props.invoicesDetails.error) {
      if (this.props.invoicesDetails.error.notPermitted) {
        return (
          <ContentLayout title={<h4>Products</h4>}>
            <NotPermitted />
          </ContentLayout>
        )
      }

      return (
        <ContentLayout title={<h4>Products</h4>}>
          <Error />
        </ContentLayout>
      )
    }

    const { invoices } = this.props.invoicesDetails
    let content

    if (
      this.props.invoicesDetails.fetching ||
      !this.props.invoicesDetails.fetched ||
      this.props.currenciesDetails.fetching ||
      !this.props.currenciesDetails.fetched
    ) {
      content = <Loader />
    } else {
      var header

      if (invoices != null && invoices.length > 0) {
        header = (
          <div
            className="row capitalize greyed bold small-margin-bottom"
            style={{
              fontSize: "0.8em",
            }}
          >
            <div className="medium-10 columns">
              <div className="row">
                <div className="medium-2 columns">Price</div>
                <div className="large-5 medium-4 columns">Name</div>
                <div className="large-5 medium-6 columns">KYC handling</div>
              </div>
            </div>
          </div>
        )
      }

      content = (
        <div>
          {header}
          {invoices.map(invoice => (
            <div key={invoice.id}>
              <Invoice invoice={invoice} />
            </div>
          ))}
        </div>
      )
    }

    const createButton = (
      <a onClick={this.openModal.bind(this)} className="button round border small">
        New product
      </a>
    )
    var header = (
      <div className="row">
        <div className="medium-2 columns">
          <h4>Products</h4>
        </div>
        <div className="medium-7 columns" />
        <div className="medium-3 columns text-right">{createButton}</div>
      </div>
    )
    return (
      <ContentLayout title={header}>
        <InvoiceModal modalName="New product" ref={m => (this._modal = m)} />
        {(() => {
          if (this.props.invoicesDetails.fetched && (invoices == null || invoices.length == 0)) {
            return (
              <div>
                <div className="row">
                  <Empty text="You haven't created any product yet." />
                </div>
              </div>
            )
          }

          return <div>{content}</div>
        })()}
        <Pagination
          reloadFunction={InvoicesActions.loadInvoices.bind(null, INVOICES_PER_PAGE)}
          itemsArray={this.props.invoicesDetails.fetched ? invoices : []}
          hasMore={this.props.invoicesDetails.fetched ? this.props.invoicesDetails.has_more : false}
          hide={this.props.invoicesDetails.fetching || !this.props.invoicesDetails.fetched}
          location={this.props.location}
          onPrevious={() => track("Products", "Click", "Pagination", "Previous")}
          onNext={() => track("Products", "Click", "Pagination", "Next")}
        />
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  invoicesDetails: store.invoices,
  currenciesDetails: store.currencies,
  permissions: store.permissions,
}))(Invoices)
