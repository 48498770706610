import { call, put, select, takeLatest } from "redux-saga/effects"
import uniqid from "uniqid"
import { replace } from "react-router-redux"
import type { $Chart, $FetchParams } from "../Boards/consts"
import type { $RequestCompileFormulaToBuilderState } from "./actions"
import {
  APPLY_PRESET_CHART_BUILDER,
  REQUEST_CHART_FETCH,
  REQUEST_FORMULA_COMPILE,
  updateFormula,
} from "./actions"
import type {
  $CompiledFormula,
  $FormulaPlottingInfo,
  $FormulaVariable,
} from "./FormulaCompiler/consts"
import type { $BuilderMetric, $ChartBuilderState, $Metric } from "./ChartBuilder/consts"
import type { $Dimension } from "./ChartBuilder/DimensionSelection/consts"
import * as ASTUtils from "./ASTUtils"
import { detectPresetMetric, normalizeFilters } from "./ASTUtils"
import type { $Filter } from "./ChartBuilder/Filters/consts"
import { UPDATE_CHART_BUILDER_DETAILS } from "./consts"
import type { $NetMetricType } from "./ChartBuilder/NetDropdown"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"
export function* fetchCompiledFormula(chart: $Chart): Generator<any, any, any> {
  const compiledResult = yield APIcallPromise(
    `/boards/${chart.board_id}/charts/${chart.id}/compile`,
    "POST",
    null,
    null,
    false,
    true,
  )
  if (compiledResult.data.formula_compiled.evaluation)
    return compiledResult.data.formula_compiled.evaluation
  if (compiledResult.data.formula_compiled.comparator)
    return compiledResult.data.formula_compiled.comparator
}
export function computeMetricFilters(variable: $FormulaVariable): Array<$Filter> {
  let filters: Array<$Filter> | null | undefined

  if (variable.compiled_condition) {
    return ASTUtils.mergeTwoNodes(variable.compiled_condition) || []
  }

  return []
}
// Converts a compiled variable to a Metric used in the chart builder state
export function variableToMetric(
  variable: $FormulaVariable,
  name: string,
): $Metric | null | undefined {
  let filters: Array<$Filter> | null | undefined

  if (variable.compiled_condition) {
    filters = ASTUtils.mergeTwoNodes(variable.compiled_condition)
    if (!filters) return null
  }

  return {
    id: uniqid(),
    filters: mergeCompiledFilters(filters || []),
    path: variable.path.replace("_local", ""),
    display_in_local_currency: variable.path.includes("_local"),
    type: variable.type,
    name,
  }
}
// Converts a compiled plotting info to a Dimension used in chart builder state
export function plottingInfoToDimension(plottingInfo: $FormulaPlottingInfo): $Dimension {
  return {
    id: uniqid(),
    field: plottingInfo.field,
    top: plottingInfo.top,
    strategy: plottingInfo.strategy,
    formula: "count{path: transactions; default: 0;}",
  }
}
// Takes an array of compiled filters and merge filters that can be
export function mergeCompiledFilters(filters: Array<$Filter>): Array<$Filter> {
  const newArray: Array<$Filter> = []
  const baseFilters = filters.slice()

  while (baseFilters.length > 0) {
    const currentFilter = baseFilters[0]

    for (let i = 1; i < baseFilters.length; i++) {
      // for each other filter we check if it can be merged with the current one
      const f = baseFilters[i]

      if (f.path !== currentFilter.path || f.operand !== currentFilter.operand) {
        // Cannot be merged
        continue
      }

      // can be merged
      currentFilter.value = currentFilter.value.concat(f.value)
      // remove second filter from baseFilters
      baseFilters.splice(i, 1)
    }

    // add currentFilter to the new array
    newArray.push(currentFilter)
    // remove currentFilter
    baseFilters.splice(0, 1)
  }

  return newArray
}
export function* setChartBuilderDetails(chart: $Chart): Generator<any, any, any> {
  yield put({
    type: UPDATE_CHART_BUILDER_DETAILS,
    payload: {
      type: chart.type,
      unit: chart.unit,
      name: chart.name,
      size: chart.size,
    },
  })
}

// Takes a compiled formula and converts it to a complete usable chart builder state
function* compileFormulaToBuilderState(
  action: $RequestCompileFormulaToBuilderState,
): Generator<any, any, any> {
  const { chartId, project, board, params } = action.payload
  // We retrieve boardDetails
  const boardDetails = yield select<any>(store => store.analytics_v2.boardDetails)
  const charts: Array<$Chart> = boardDetails.board.charts.slice()
  // We retrieve the chart details
  const chart = charts.find(c => c.id === chartId)

  if (!chart) {
    throw new Error(`chart-edition: The chart id ${chartId} could not be found`)
  }

  try {
    // We first fetch the compiled formula
    const compiledResult: $CompiledFormula = yield call(fetchCompiledFormula, chart)
    let variablesNames: Array<string>
    let variables: Array<$FormulaVariable>
    let formula: string

    // We check if we are on a comparison
    if (compiledResult.selectors) {
      // We are on a comparison case
      variablesNames = Object.keys(compiledResult.templates.default.evaluation.variables)
      variables = variablesNames.map(v => compiledResult.templates.default.evaluation.variables[v])
      formula = compiledResult.templates.default.evaluation.formula
    } else {
      // We are on a non-comparison case
      variablesNames = Object.keys(compiledResult.variables)
      variables = variablesNames.map(v => compiledResult.variables[v])
      formula = compiledResult.formula
    }

    // We reconstruct the general formula
    for (let i = 0; i < variablesNames.length; i++) {
      formula = formula.replace(variablesNames[i].toLocaleLowerCase(), getCharAtPos(i))
    }

    const commonFilters: Array<$Filter> = []
    // We first etract common filters from all metrics
    const variablesArrayFilters: Array<Array<$Filter>> = []

    for (let i = 0; i < variables.length; i++) {
      variablesArrayFilters.push(computeMetricFilters(variables[i]))
    }

    // Reconstruct dimensions
    let dimensions: Array<$Dimension> = []

    if (compiledResult.selectors) {
      for (const selector of compiledResult.selectors) {
        if (selector.evaluation.variables) {
          const variables = Object.keys(selector.evaluation.variables).map(key => ({
            name: key,
            value: selector.evaluation.variables[key],
          }))

          for (const variable of variables) {
            variablesArrayFilters.push(computeMetricFilters(variable.value))
          }
        }

        dimensions.push(plottingInfoToDimension(selector.plotting_info))
      }

      if (compiledResult.templates.default.plotting_info)
        dimensions.push(plottingInfoToDimension(compiledResult.templates.default.plotting_info))
    }

    if (compiledResult.plotting_info)
      dimensions.push(plottingInfoToDimension(compiledResult.plotting_info))
    // We reverse the dimensions array
    dimensions = dimensions.reverse()
    // Reconstruct metrics
    const metrics: Array<$Metric> = []

    // Then we rebuild every metrics
    for (let i = 0; i < variables.length; i++) {
      const metric = variableToMetric(variables[i], getCharAtPos(i))

      if (!metric) {
        throw new Error("Unssuported metric")
      }

      metrics.push(metric)
    }

    const selectedMetric: $BuilderMetric | null | undefined = detectPresetMetric(metrics, formula)

    if (!selectedMetric) {
      yield put(
        replace(`/projects/${project}/boards/${chart.board_id}/new-chart/editor?chart=${chart.id}`),
      )
      throw new Error("Selected metric not found")
    }

    const selectedMetricFiltersArray = selectedMetric.metrics.map(m => m.filters)
    const selectedMetricFilters: Array<$Filter> = selectedMetricFiltersArray.reduce(
      (acc, cur) => acc.concat(cur),
      [],
    )
    const variablesFilters: Array<$Filter> = variablesArrayFilters.reduce(
      (acc, cur) => acc.concat(cur),
      [],
    )
    const resultFilters: Array<$Filter> = []
    const variablesFiltersNormalized = normalizeFilters(variablesFilters)
    const selectedFiltersNormalized = normalizeFilters(selectedMetricFilters)

    // Remove any duplicated metric specific filters
    for (const filter of variablesFiltersNormalized) {
      if (selectedFiltersNormalized.find(f => ASTUtils.isSameFilter(filter, f))) {
        // skip
      } else {
        resultFilters.push(filter)
      }
    }

    let netMetric: $NetMetricType = "raw"
    const routerNetIndex = resultFilters.findIndex(f => f.path.includes("is_last_payment_attempt"))
    if (routerNetIndex > -1) netMetric = "net_router"
    const userNetIndex = resultFilters.findIndex(f => f.path.includes("is_user_retry"))
    if (userNetIndex > -1) netMetric = "net"

    if (netMetric !== "raw") {
      if (routerNetIndex > -1) resultFilters.splice(routerNetIndex, 1)
      if (userNetIndex > -1) resultFilters.splice(userNetIndex, 1)
    }

    // Generate the compiled chart builder state
    const chartBuilderState: $ChartBuilderState = {
      displayInLocalCurrency: metrics.some(metric => metric.display_in_local_currency),
      filters: resultFilters,
      type: chart.type,
      selectedMetric,
      dimensions,
      metrics,
      canEditIfChartIdExists: true,
      displayNetMetrics: netMetric,
    }
    // Dispatch that the UI compile has been done
    yield put({
      type: typeFulfilled(REQUEST_FORMULA_COMPILE),
      payload: chartBuilderState,
    })
    // Dispatch the compiled chart builder state
    yield put({
      type: APPLY_PRESET_CHART_BUILDER,
      payload: chartBuilderState,
    })
    // Set the correct name, unit, type...
    yield call(setChartBuilderDetails, chart)
    // Re-fetch the chart preview with correct params
    const params: $FetchParams = yield select<any>(store => store.analytics.params)
    yield put({
      type: REQUEST_CHART_FETCH,
      payload: {
        name: chart.name,
        formula: chart.settings.formula,
        type: chart.type,
        unit: chart.unit,
        params,
      },
    })
  } catch (error) {
    // Something went wrong while compiling the UI, we redirect towards the formula editor
    yield put({
      type: typeFailed(REQUEST_FORMULA_COMPILE),
      payload: {
        error,
      },
    })
    yield put(replace(`/projects/${project}/boards/${board}/new-chart/editor?chart=${chartId}`))
    yield put(updateFormula(chart.settings.formula))
    // Set the correct name, unit, type...
    yield call(setChartBuilderDetails, chart)
    datadogRum.addError(error)
  }
}

// Returns A + index char. ex: 0 returns A, 1 returns B etc
export function getCharAtPos(index: number): string {
  return String.fromCharCode(65 + index)
}
export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_FORMULA_COMPILE, compileFormulaToBuilderState)
}
