import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import CouponModal from "./CouponModal"
import type { $Coupon } from "^/stores/Reducers/CouponsReducer.js"
export type Props = {
  coupon: $Coupon
}
export class Coupon extends React.Component<Props> {
  private _modal: any
  displayCoupon(event) {
    event.preventDefault()

    this._modal.getWrappedInstance().openModal()
  }

  render() {
    const { coupon } = this.props as any
    let amount

    if (coupon.amount_off !== "0" && coupon.percent_off === 0) {
      amount = (
        <FormattedNumber value={coupon.amount_off} style="currency" currency={coupon.currency} />
      )
    } else {
      amount = <span>{coupon.percent_off}%</span>
    }

    let lasts
    if (coupon.iteration_count > 1)
      lasts = <span>Lasts {coupon.iteration_count} iterations once applied</span>
    else if (coupon.iteration_count > 0) lasts = <span>Applies to one iteration only</span>
    else lasts = <span>Lasts forever once applied</span>
    let redemptions
    if (coupon.max_redemptions === 0) redemptions = <span>Unlimited possible redemptions</span>
    else if (coupon.max_redemptions === 1) redemptions = <span>Only one possible redemption</span>
    else redemptions = <span>{coupon.max_redemptions} maximum redemptions</span>
    return (
      <div>
        <CouponModal modalName="Edit coupon" coupon={coupon} ref={m => (this._modal = m)} />
        <a onClick={this.displayCoupon.bind(this)}>
          <div className="box-row row padding">
            <div className="medium-2 columns">
              <div className="big-font">{amount}</div>
              <div className="greyed">
                {coupon.amount_off !== "0" && coupon.percent_off === 0
                  ? coupon.currency
                  : "FROM TOTAL"}
              </div>
            </div>
            <div className="medium-5 columns">
              <div className="font-console">{coupon.id}</div>
              <div className="greyed">{lasts}</div>
            </div>
            <div className="medium-5 columns">
              <div>Redeemed {coupon.redeemed_number} time(s)</div>
              <div className="greyed">{redemptions}</div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}
export default connect()(Coupon)
