export type $Dispatcher = {
  dispatch: any | ((arg0: any) => void)
}
export type $Action = {
  type: string
  payload?: any
}
export type $State = {
  fetching: boolean
  fetched: boolean
  error?: {
    status: number
    notPermitted?: true
    details: {}
  }
  notPermitted?: true
}
export type $Project = {
  id: string
  name: string
  api_version: {
    name: string
  }
  default_currency: string
  feature_flags: any
  region_name: string
}
export type $CurrentProject = {
  project: $Project
}
export type $Context = {
  projectId: string
}
export type $OnChangeEvent = {
  target: {
    value: string
  }
}
export type $RouterLocation = {
  pathname: string
  query: Record<string, string>
  search: string
}
export type $Input = {
  key: string
  value: string
}
export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"
