import React from "react"
import { connect } from "react-redux"
import Configurations from "./Configurations"
import ContentLayout from "../../pages/ContentLayout"
import Loader from "../../components/Loader"
import NotPermitted from "../Permissions/NotPermitted"
import Error from "../../components/Error"
import Empty from "../../components/Empty"
import * as GatewaysActions from "../../Actions/GatewaysActions"
import * as ProcessOut from "../../util/ProcessOut"
import type { $GatewayMenu, $GatewaySubMenu } from "./consts"
import { PAYMENT_PROVIDERS_MENU, PROVIDERS_PER_LINE } from "./consts"
import GatewaySearch from "../../util/GatewaySearch"
import GatewayModal from "./GatewayModal"
import type { $Dispatcher } from "../../util/Types"
type Props = {} & $Dispatcher
type State = {
  gatewaysSearchList: Array<any> | null | undefined
  firstlevelCategory: string
  secondlevelCategory: string | null | undefined
}

class PaymentProviders extends React.Component<Props, State> {
  static defaultProps = {}

  constructor() {
    super()
    this.state = {
      gatewaysSearchList: null,
      firstlevelCategory: PAYMENT_PROVIDERS_MENU[0].id,
      secondlevelCategory: null,
    }
  }

  componentDidMount() {
    document.title = "Gateways | ProcessOut"
    const { dispatch } = this.props
    dispatch(GatewaysActions.loadGateways())
    dispatch(GatewaysActions.loadGatewaysConfigurations(ProcessOut.config.ProjectId))
  }

  openModal = processor => {
    // TODO - this needs to be fixed, but is going to take a lot more thought to ensure nothing breaks. Come back to this
    // eslint-disable-next-line react/no-string-refs
    this.refs[processor.name].getWrappedInstance().getWrappedInstance().openModal()
  }
  updateCategory = (firstlevelCategory, secondlevelCategory, e) => {
    e.preventDefault()
    this.setState({
      firstlevelCategory,
      secondlevelCategory,
    })
    return false
  }
  filter = e => {
    const filter = e.target.value

    if (filter === "") {
      this.setState({
        gatewaysSearchList: null,
      })
      return
    }

    this.setState({
      gatewaysSearchList: GatewaySearch(this.props.gatewaysDetails.gateways, filter),
    })
  }
  tagsMatches = (tags, categories) => {
    for (let j = 0; j < categories.length; j++) {
      if (!categories[j]) continue
      let found = false

      for (let i = 0; i < tags.length; i++) if (tags[i] === categories[j]) found = true

      if (!found) return false
    }

    return true
  }
  filterCategory = categories => {
    if (!categories || categories.length === 0) {
      return this.props.gatewaysDetails.gateways
    }

    const filteredGateways = []

    for (let i = 0; i < this.props.gatewaysDetails.gateways.length; i++) {
      const g = this.props.gatewaysDetails.gateways[i]
      if (this.tagsMatches(g.tags, categories)) filteredGateways.push(g)
    }

    return filteredGateways
  }
  renderGroup = (itemsArray): Array<React.ReactElement<React.ComponentProps<"div">, "div">> => {
    const rowsN = itemsArray.length / PROVIDERS_PER_LINE
    const rows = []

    for (let j = 0; j < rowsN; j++) {
      let count = -1
      const row = (
        <div className="row" key={Math.floor(Math.random() * 100000)}>
          {itemsArray
            .slice(j * PROVIDERS_PER_LINE, j * PROVIDERS_PER_LINE + PROVIDERS_PER_LINE)
            .map(function (processor) {
              // Map all the gateways for a given tag and add their display to the tile list
              count++
              let last = ""
              if (count + j * PROVIDERS_PER_LINE === itemsArray.length - 1) last = "end"
              return (
                <div
                  key={count}
                  className={`medium-${12 / PROVIDERS_PER_LINE} ${last} columns margin-bottom`}
                >
                  <GatewayModal
                    modalName="New configuration"
                    gateway={processor}
                    ref={processor.name}
                  />
                  <a
                    className="payment-processor-wrapper details-box only-img"
                    onClick={() => {
                      if (!processor.demo) this.openModal(processor)
                    }}
                  >
                    <div className="row">
                      <div className="large-12 columns text-center img-wrapper">
                        <img
                          alt={`${processor.display_name}'s logo`}
                          className="payment-processor"
                          src={processor.logo_url}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns text-center description">
                        {processor.display_name}
                      </div>
                    </div>
                  </a>
                </div>
              )
            }, this)}
        </div>
      )
      rows.push(row)
    }

    return rows
  }

  render() {
    const processorsDetails = this.props.gatewaysDetails
    const { gateways } = this.props.gatewaysDetails
    const { processorsConfigurations } = this.props
    const { gatewaysSearchList, firstlevelCategory, secondlevelCategory } = this.state

    if (processorsConfigurations.error) {
      if (processorsConfigurations.error.notPermitted)
        return (
          <ContentLayout title={<h4>Payment providers</h4>}>
            <NotPermitted />
          </ContentLayout>
        )
      return (
        <ContentLayout title={<h4>Payment providers</h4>}>
          <Error />
        </ContentLayout>
      )
    }

    if (processorsDetails.fetching || !processorsDetails.fetched) {
      return (
        <ContentLayout title={<h4>Payment providers</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    const tiles: Array<
      | React.ReactElement<React.ComponentProps<"div">, "div">
      | Array<React.ReactElement<React.ComponentProps<"div">, "div">>
    > = []

    if (gatewaysSearchList) {
      // The search bar is filled
      tiles.push(this.renderGroup(gatewaysSearchList))
    } else if (firstlevelCategory === "credit-card") {
      const creditcardGateways = []

      for (const gateway of gateways) {
        if (gateway.tags.includes(firstlevelCategory)) {
          creditcardGateways.push(gateway)
        }
      }

      tiles.push(
        <div className="row" key={`gateway-sub${creditcardGateways.name}`}>
          <div className="large-12 columns margin-bottom">
            {this.renderGroup(creditcardGateways)}
          </div>
        </div>,
      )
    } else {
      // First: get the category we're in
      const filtered = this.filterCategory([firstlevelCategory, secondlevelCategory])
      let curmenu: $GatewayMenu | null | undefined

      for (let i = 0; i < PAYMENT_PROVIDERS_MENU.length; i++)
        if (PAYMENT_PROVIDERS_MENU[i].id === firstlevelCategory) {
          curmenu = PAYMENT_PROVIDERS_MENU[i]
          break
        }

      if (curmenu) {
        let { submenus } = curmenu

        if (secondlevelCategory != null) {
          let tmp: $GatewaySubMenu = {
            name: "",
            description: "",
            id: "",
          }

          for (let i = 0; i < submenus.length; i++)
            if (submenus[i].id === secondlevelCategory) {
              tmp = submenus[i]
              break
            }

          submenus = [tmp]
        }

        for (let i = 0; i < submenus.length; i++) {
          const subfiltered = []

          for (let j = 0; j < filtered.length; j++) {
            if (this.tagsMatches(filtered[j].tags, [curmenu.id, submenus[i].id])) {
              let skip = false
              if (submenus[i].not)
                for (let k = 0; k < submenus[i].not.length; k++) {
                  if (this.tagsMatches(filtered[j].tags, [curmenu.id, submenus[i].not[k]])) {
                    skip = true
                    break
                  }
                }
              if (skip) continue
              subfiltered.push(filtered[j])
            }
          }

          if (!subfiltered.length) continue
          tiles.push(
            <div className="row" key={`gateway-sub${i}-${i}`}>
              <div className="large-12 columns margin-bottom">
                <div
                  className="row text-left"
                  style={{
                    marginBottom: "1em",
                  }}
                >
                  <div className="large-8 medium-9 columns">
                    <h4
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {submenus[i].name}
                    </h4>
                    <h6 className="greyed">{submenus[i].description}</h6>
                  </div>
                </div>

                {this.renderGroup(subfiltered)}
              </div>
            </div>,
          )
        }
      }
    }

    const displayMenu = []

    for (let i = 0; i < PAYMENT_PROVIDERS_MENU.length; i++) {
      const displaySubMenu = []

      for (let j = 0; j < PAYMENT_PROVIDERS_MENU[i].submenus.length; j++) {
        displaySubMenu.push(
          <li key={`menu-li-${i}-${j}`}>
            <a
              href="#"
              onClick={event => {
                this.updateCategory(
                  PAYMENT_PROVIDERS_MENU[i].id,
                  PAYMENT_PROVIDERS_MENU[i].submenus[j].id,
                  event,
                )
              }}
            >
              {PAYMENT_PROVIDERS_MENU[i].submenus[j].name}
            </a>
          </li>,
        )
      }

      displayMenu.push(
        <div className="row" key={`menu-div-${i}`}>
          <div className="large-12 columns">
            <h5>
              <a
                href="#"
                onClick={event => {
                  this.updateCategory(PAYMENT_PROVIDERS_MENU[i].id, null, event)
                }}
              >
                {PAYMENT_PROVIDERS_MENU[i].name}
              </a>
            </h5>
            <ul>{displaySubMenu}</ul>
          </div>
        </div>,
      )
    }

    let empty

    if (gatewaysSearchList && gatewaysSearchList.length === 0) {
      empty = <Empty text="No provider matches your search" />
    } else if (!tiles.length) {
      empty = <Empty text="There's no provider available for that category yet" />
    }

    return (
      <div>
        <Configurations />
        <ContentLayout title={<h4>Configure a new payment provider</h4>}>
          <div className="row">
            <div className="medium-4 columns">
              <div className="payment-processor-menu">
                <div className="row">
                  <div className="large-12 columns">
                    <input type="text" placeholder="Search" onChange={this.filter} />
                  </div>
                </div>

                {displayMenu}
              </div>
            </div>
            <div className="medium-8 columns">
              {tiles}
              {empty}
            </div>
          </div>
        </ContentLayout>
      </div>
    )
  }
}

export default connect(store => ({
  gatewaysDetails: store.gateways,
  user: store.user,
  processorsConfigurations: store.processorsConfigurations,
}))(PaymentProviders)
