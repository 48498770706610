import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher, $OnChangeEvent, $State } from "../../../util/Types"
import type { $Board } from "../Boards/consts"
type Props = {
  project: {
    project?: {
      id: string
    }
  }
  submit: () => void
  boards: {
    boards: Array<$Board>
  } & $State
} & $Dispatcher
type State = {
  value: string
}
export class SimpleSavingForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
    }
  }

  componentDidMount() {
    const availableBoards: Array<$Board> = this.props.boards.boards.filter(
      board => !board.id.includes("board_default"),
    )

    if (availableBoards.length > 0) {
      this.setState({
        value: availableBoards[0].id,
      })
    }
  }

  boardChanged = (event: $OnChangeEvent) => {
    this.setState({
      value: event.target.value,
    })
  }
  retrieveInfos = () => ({
    boardId: this.state.value,
  })

  render() {
    const { project } = this.props
    if (!project.project) return null
    if (!this.props.boards.fetched || this.props.boards.error) return null
    const availableBoards: Array<$Board> = this.props.boards.boards.filter(
      board => !board.id.includes("board_default"),
    )
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-11 large-centered columns">
              <p>Chose the board you want to save your chart on.</p>
            </div>
          </div>

          <div>
            <div className="row small-margin-bottom">
              <div
                className={`large-12 columns${availableBoards.length === 0 ? " text-center" : ""}`}
              >
                {availableBoards.length > 0 ? (
                  <select
                    placeholder="Select a board..."
                    value={this.state.value}
                    onChange={this.boardChanged}
                  >
                    {availableBoards.map(board => (
                      <option value={board.id} key={board.id}>
                        {board.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span className="greyed">No boards available.</span>
                )}
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns text-center small">— or —</div>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns text-center">
              <a
                className="round border button small"
                onClick={() => {
                  this.props.submit()
                }}
              >
                Create a new board
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(
  store => ({
    project: store.currentProject,
    boards: store.analytics_v2.boards,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(SimpleSavingForm)
