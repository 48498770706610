import clsx from "clsx"
import React from "react"
import "./SwitchToggle.scss"

export type Props = {
  onChange: () => void
  checked: boolean
  disabled?: boolean
  labelText?: string
  style?: React.CSSProperties
}

export const SwitchToggle: React.FC<Props> = ({
  onChange,
  checked,
  disabled = false,
  labelText,
  style,
}) => {
  return (
    <label
      className={clsx("switch-toggle", { disabled: disabled })}
      data-testid="switch-toggle"
      style={style}
    >
      <span className={clsx("switch-toggle-container")}>
        <input type="checkbox" onChange={onChange} checked={checked} />
        <span className="switch-toggle-slider round"></span>
      </span>
      <span className="switch-toggle-label">{labelText}</span>
    </label>
  )
}
