import React from "react"
type Props = {
  onChange: (displayInLocal: boolean) => void
  displayInLocal: boolean
}
export class LocalSwitch extends React.Component<Props> {
  render() {
    const { onChange, displayInLocal } = this.props
    return (
      <div className="row small-margin-bottom">
        <div className="large-12 columns">
          <div className="switch-container">
            <span className="small-margin-right">Display in your project&apos;s currency</span>
            <label className="local-switch">
              <input
                type="checkbox"
                onChange={event => {
                  onChange(!displayInLocal)
                }}
                checked={displayInLocal}
              />
              <span className="local-slider round" />
            </label>
          </div>
        </div>
      </div>
    )
  }
}
export default LocalSwitch
