import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import Markdown from "react-markdown"
import type { $Recommendation, $Surface } from "./consts"
import Recommendation from "./Recommendation"
import type { $Dispatcher, $Project } from "../../../util/Types"
import Empty from "../../../components/Empty"
import LineChart from "../../analytics/Charts/LineChart"
import RecoModal from "./RecoModal"
import BarChart from "../../analytics/Charts/BarChart"
import TalkToExpertCTA from "../../TalkToExpertCTA/TalkToExpertCTA"
type Props = {
  surface: $Surface
  recommendations: Array<$Recommendation>
  currentProject: {
    project: $Project
  }
} & $Dispatcher
type State = {
  selectedRecoId: string
}

class Recommendations extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      selectedRecoId: "",
    }
  }

  selectReco = (recoId: string) => {
    this.setState({
      selectedRecoId: recoId,
    })
  }

  render() {
    const { surface, recommendations } = this.props
    const currentReco = this.props.recommendations
      ? recommendations.find(reco => reco.id === this.state.selectedRecoId)
      : null
    return (
      <div className="content opened">
        <ReactCSSTransitionGroup
          transitionName="reco-modal-animation"
          transitionEnterTimeout={600}
          transitionLeaveTimeout={450}
        >
          {currentReco ? (
            <RecoModal
              key={uniqid()}
              recommendation={currentReco}
              onClose={() => {
                this.setState({
                  selectedRecoId: "",
                })
              }}
              surface={surface}
              currency={this.props.currentProject.project.default_currency || "USD"}
            />
          ) : null}
        </ReactCSSTransitionGroup>
        <div className="row">
          <div className="large-12 columns">
            <div className="row small-margin-bottom">
              <div className="large-10 large-centered columns surface-description">
                <Markdown escapeHtml={false} source={surface.description} />
              </div>
            </div>
            <div className="row">
              <div className="chart-container">
                <div className="row reco-chart">
                  <div className="large-12 columns">
                    <div className="row">
                      <div className="large-12 columns text-center chart-title">
                        {surface.chart_name}
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div
                        className="large-12 columns"
                        style={{
                          height: "250px",
                        }}
                      >
                        {(() => {
                          const props = {
                            data: [
                              {
                                key: "Current performance",
                                datapoints: surface.datapoints.current_performance.filter(
                                  point => point._count > 0,
                                ),
                              },
                              {
                                key: "Expected performance",
                                datapoints: (
                                  surface.datapoints.possible_gain ||
                                  surface.datapoints.threshold_performance
                                ).filter(point => point._count > 0),
                              },
                            ],
                            type: "percentage",
                            colors: [
                              {
                                key: "Current performance",
                                color: "#5c6bc0",
                              },
                              {
                                key: "Expected performance",
                                color: "rgba(192, 57, 43,.7)",
                              },
                            ],
                          }
                          if (surface.chart_type === "bar-chart") return <BarChart {...props} />
                          if (surface.chart_type === "line-chart") return <LineChart {...props} />
                        })()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="row">
                        <div className="large-6 columns text-right">
                          <div className="surface-chart-legend surface" />
                          Current performance
                        </div>
                        <div className="large-6 columns ">
                          <div className="surface-chart-legend benchmark" />
                          Expected performance
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reco-container">
                <div className="recos">
                  {!surface.associated_recommendations ||
                  surface.associated_recommendations.length === 0 ? (
                    <div className="row">
                      <div className="large-12 columns">
                        {surface.suggested_fixes || (
                          <Empty text="Telescope did not find any recommendation for this issue." />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="large-12 columns">
                        <div className="row greyed small-margin-bottom">
                          <div className="large-12 columns">Possible actions</div>
                        </div>
                        <div className="row small-margin-bottom">
                          <div className="large-12 columns">
                            {recommendations.map(reco => {
                              const selected = reco.id === this.state.selectedRecoId
                              return (
                                <RecoContainer
                                  id={reco.id}
                                  key={reco.id}
                                  preSelected={false}
                                  selected={selected}
                                  onSelect={this.selectReco}
                                >
                                  <Recommendation
                                    onSelect={this.selectReco.bind(null, reco.id)}
                                    recommendation={reco}
                                    currency={this.props.currentProject.project.default_currency}
                                    selected={selected}
                                  />
                                </RecoContainer>
                              )
                            })}
                          </div>
                        </div>
                        <div className="row">
                          <div className="medium-12 columns text-center">
                            <TalkToExpertCTA surface={surface.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(Recommendations)

class RecoContainer extends React.Component<{
  id: string
  preSelected: boolean
  selected: boolean
  onSelect: (arg0: string) => void
  children?: any | null | undefined
}> {
  render() {
    if (this.props.preSelected || this.props.selected) {
      return <div>{this.props.children}</div>
    }

    return (
      <a
        onClick={() => {
          this.props.onSelect(this.props.id)
        }}
      >
        {this.props.children}
      </a>
    )
  }
}
