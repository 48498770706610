import React from "react"
import { FormattedDate } from "react-intl"
import moment from "moment"
import CardModal from "./CardModal"
import CardSchemeImage from "../util/CardSchemeImage"
import type { $Card } from "^/Types/Card"
import { track } from "../vNext/tracking"
type Props = {
  card: $Card
}
export default class Card extends React.Component<Props> {
  private modalRef: React.RefObject<any>
  constructor(props) {
    super(props)
    this.modalRef = React.createRef()
  }

  showModal(event) {
    event.preventDefault()
    track("Vault", "Click", "List", "Row")
    this.modalRef.current.getWrappedInstance().openModal()
  }

  render() {
    const { card } = this.props
    let cvcStatus
    let cvcExplain

    if (card.cvc_check === "passed") {
      cvcStatus = "success"
      cvcExplain = "The security code verification has passed successfully."
    } else if (
      card.cvc_check.includes("pending") ||
      card.cvc_check === "unavailable" ||
      card.avs_check === "unknown"
    ) {
      cvcStatus = "pending"
      if (card.cvc_check.includes("pending"))
        cvcExplain = "The security code verification is still pending approval."
      else cvcExplain = "The security code verification is not available."
    } else {
      cvcStatus = "failed"
      cvcExplain = "The security code verification has failed."
    }

    let avsStatus
    let avsExplain

    if (card.avs_check === "passed") {
      avsStatus = "success"
      avsExplain = "The address verification has passed successfully."
    } else if (
      card.avs_check.includes("pending") ||
      card.avs_check === "unavailable" ||
      card.avs_check === "unknown"
    ) {
      avsStatus = "pending"
      if (card.avs_check.includes("pending"))
        avsExplain = "The address verification is still pending approval."
      else avsExplain = "The address verification is not available."
    } else {
      avsStatus = "failed"
      avsExplain = "The address verification has failed."
    }

    const expiry = moment(new Date(card.exp_year, card.exp_month - 1))
    return (
      <a onClick={this.showModal.bind(this)}>
        <CardModal ref={this.modalRef} card={card} />
        <div className="box-row padding row">
          <div className="medium-2 columns">
            <div className="row">
              <div className="medium-4 columns">{CardSchemeImage(card)}</div>
              <div className="medium-8 columns font-console">{card.last_4_digits}</div>
            </div>
          </div>
          <div className="medium-2 columns font-console" data-testid="expiry-date">
            {card.exp_month && card.exp_year ? (
              <FormattedDate value={expiry.toISOString()} month="2-digit" year="numeric" />
            ) : (
              <span className="greyed">Unknown expiration</span>
            )}
          </div>
          <div className="medium-4 columns end" data-testid="card-name">
            {card.name && card.name !== "" && card.name.split(" ").join("").length > 0 ? (
              card.name
            ) : (
              <span className="greyed">--</span>
            )}
          </div>
          <div className="medium-1 columns text-right">
            <div
              data-testid="card-cvc"
              data-tip={cvcExplain}
              className={`log-level ${cvcStatus}`}
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "0px",
                marginRight: "-3px",
              }}
            >
              CVC
            </div>
          </div>
          <div className="medium-1 columns text-right">
            <div
              data-testid="card-avs"
              data-tip={avsExplain}
              className={`log-level ${avsStatus}`}
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
                marginTop: "0px",
                marginRight: "-3px",
              }}
            >
              AVS
            </div>
          </div>
          <div className="medium-2 columns text-right greyed">
            <FormattedDate value={card.created_at} day="2-digit" month="2-digit" year="numeric" />
          </div>
        </div>
      </a>
    )
  }
}
