import React from "react"
import type { $AlertEvent } from "../consts"
import Event from "./Event"
type Props = {
  events: Array<$AlertEvent>
}

class Events extends React.Component<Props> {
  render() {
    const { events } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns">
          {events.map(event => (
            <Event key={event.id} event={event} />
          ))}
        </div>
      </div>
    )
  }
}

export default Events
