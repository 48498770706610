import uniqid from "uniqid"
import type { $ChartBuilderState } from "./consts"
import { TRANSACTIONS_COUNT_METRIC } from "./consts"
import type {
  $SelectBuilderMetricAction,
  $SelectTypeAction,
  $SetDisplayLocalCurrencyAction,
  $SetDisplayNetMetrics,
  $UpdateDimensionsAction,
  $UpdateFiltersAction,
  UpdateDimensions,
} from "./actions"
import {
  SELECT_BUILDER_METRIC,
  SET_DISPLAY_LOCAL_CURRENCY,
  SET_DISPLAY_NET_METRICS,
  UPDATE_DIMENSIONS,
  UPDATE_FILTERS,
} from "./actions"
import {
  ApplyPresetChartBuilderAction,
  APPLY_PRESET_CHART_BUILDER,
  REQUEST_FORMULA_COMPILE,
  ResetDataExplorerAction,
  RESET_DATA_EXPLORER,
} from "../actions"
import { typeFailed } from "^/util/ActionUtils"
const defaultState: $ChartBuilderState = {
  selectedMetric: TRANSACTIONS_COUNT_METRIC,
  dimensions: [
    {
      id: uniqid(),
      field: "created_at",
      formula: "count{path: transaction_operations; default: 0}",
      strategy: "key_ascending",
      top: null,
    },
  ],
  filters: [],
  displayInLocalCurrency: true,
  displayNetMetrics: "net",
  canEditIfChartIdExists: false,
}
export default function (
  state: $ChartBuilderState = defaultState,
  action:
    | $SelectBuilderMetricAction
    | $SelectTypeAction
    | $SetDisplayLocalCurrencyAction
    | $UpdateDimensionsAction
    | $UpdateFiltersAction
    | $SetDisplayNetMetrics
    | ResetDataExplorerAction
    | UpdateDimensions
    | ApplyPresetChartBuilderAction,
): $ChartBuilderState {
  switch (action.type) {
    case RESET_DATA_EXPLORER: {
      return {
        ...state,
        selectedMetric: TRANSACTIONS_COUNT_METRIC,
        dimensions: [
          {
            id: uniqid(),
            field: "created_at",
            formula: "count{path: transaction_operations; default: 0}",
            strategy: "key_ascending",
            top: null,
          },
        ],
        filters: [],
        displayInLocalCurrency: true,
        canEditIfChartIdExists: false,
      }
    }

    case SELECT_BUILDER_METRIC: {
      return { ...state, selectedMetric: action.payload.metric, displayInLocalCurrency: true }
    }

    case SET_DISPLAY_LOCAL_CURRENCY: {
      return { ...state, displayInLocalCurrency: action.payload.displayInLocalCurrency }
    }

    case UPDATE_DIMENSIONS: {
      return { ...state, dimensions: action.payload.dimensions }
    }

    case UPDATE_FILTERS: {
      return { ...state, filters: action.payload.filters }
    }

    case APPLY_PRESET_CHART_BUILDER: {
      const { payload } = action
      return { ...state, ...payload }
    }

    case SET_DISPLAY_NET_METRICS: {
      const { payload } = action
      return { ...state, displayNetMetrics: payload.displayNetMetrics }
    }

    case typeFailed(REQUEST_FORMULA_COMPILE): {
      return { ...state, canEditIfChartIdExists: true }
    }
  }

  return state
}
