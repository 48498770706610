import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { push } from "react-router-redux"
import qs from "qs"
import moment from "moment"
import type { $UpdateDatesAction } from "./consts"
import { INIT_ANALYTICS_DATES, UPDATE_ANALYTICS_DATES } from "./consts"
import type { $Action } from "../../../util/Types"

function* initDates(action: $Action): Generator<any, any, any> {
  const location = yield select<any>(store => store.routing.locationBeforeTransitions)
  const { from, to } = location.query

  if (from && to) {
    // FIXME: missing ANALYTICS_INTERVAL_CHANGE action type.
    // @ts-ignore
    yield call(updateDates, {
      payload: {
        fromDate: moment.unix(from),
        toDate: moment.unix(to),
      },
    })
  }
}

export const ANALYTICS_INTERVAL_CHANGE = "ANALYTICS_INTERVAL_CHANGE"

function* updateDates(action: $UpdateDatesAction): Generator<any, any, any> {
  const { fromDate, toDate, interval } = action.payload
  yield put({
    type: ANALYTICS_INTERVAL_CHANGE,
    payload: {
      interval: {
        from: fromDate.unix(),
        to: toDate.unix(),
      },
      timeInterval: interval,
      success: true,
    },
  })
  const location = yield select<any>(store => store.routing.locationBeforeTransitions)
  const query = { ...location.query, from: fromDate.unix(), to: toDate.unix() }
  yield put(push(`${location.pathname}?${qs.stringify(query)}`))
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(INIT_ANALYTICS_DATES, initDates)
  yield takeEvery(UPDATE_ANALYTICS_DATES, updateDates)
}
