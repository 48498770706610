import React from "react"
import Subscription from "./Subscription"
export default class CustomerDetailsContent extends React.Component {
  constructor() {
    super()
    this.state = {
      details: false,
    }
  }

  toggle() {
    this.setState({
      details: !this.state.details,
    })
  }

  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          {this.props.invoices.map(invoice => (
            <Subscription subscription={invoice} key={invoice.id} />
          ))}
        </div>
      </div>
    )
  }
}
