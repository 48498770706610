import type { Action } from "../../Types/Action"
export const REQUEST_DASHBOARD_PREPARATION = "REQUEST_DASHBOARD_PREPARATION"
export const DASHBOARD_PREPARATION = "DASHBOARD_PREPARATION"
export const REQUEST_DASHBOARD_PREPARATOR_RESET = "REQUEST_DASHBOARD_PREPARATOR_RESET"
export type $ActionType = {
  payload: {
    projectId?: string
    email: string
  }
} & Action
