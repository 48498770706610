import type { $Action, $State } from "../../../util/Types"
import { PREPARE_TELESCOPE } from "../../../features/Telescope/consts"
import { typePending } from "^/util/ActionUtils"
export type State = {
  authorization: {}
  fees: {}
} & $State
const defaultState: State = {
  authorization: {},
  fees: {},
  fetched: false,
  fetching: false,
}
export default function (state: State = defaultState, action: $Action): State {
  switch (action.type) {
    case typePending(PREPARE_TELESCOPE): {
      return { ...state, fetching: true, fetched: false }
    }

    default:
      return state
  }
}
