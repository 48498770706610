// @ts-expect-error
import "./reports.scss"
import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../util/Types"
import ContentLayout from "../../pages/ContentLayout"
import type { $ReportsState } from "./reducer"
import * as Actions from "./actions"
import Loading from "../../components/Loader"
import Report from "./Report"
import Pagination from "../../components/Pagination"
import Empty from "../../components/Empty"
import UploadModal from "./UploadModal"
import UploadingReport from "./UploadingReport"
import NotPermitted from "../Permissions/NotPermitted"
import Error from "../../components/Error"
import { STOP_REPORTS_AUTOFETCH } from "./actions"
type Props = {
  reportsDetails: $ReportsState
} & $Dispatcher

class Reports extends React.Component {
  props: Props
  state: {
    dropHovered: boolean
  }
  _uploadModal: any
  _drop: any

  constructor() {
    super()
    this.state = {
      dropHovered: false,
    }
  }

  componentDidMount(): void {
    document.title = "Reports | ProcessOut"
    const { dispatch } = this.props
    dispatch(Actions.requestReportsFetch("", 10))
    dispatch(Actions.prepareReportsPage())

    const preventDefaults = event => {
      event.preventDefault()
      event.stopPropagation()
    } // Prevent default drag behaviors
    ;["dragenter", "dragover", "dragleave", "drop"].forEach(eventName => {
      this._drop.addEventListener(eventName, preventDefaults, false)

      // $FlowFixMe
      document.addEventListener(eventName, preventDefaults, false)
    }) // Highlight drop area when item is dragged over it
    ;["dragenter", "dragover"].forEach(eventName => {
      this._drop.addEventListener(
        eventName,
        () => {
          this.setState({
            dropHovered: true,
          })
        },
        false,
      )
    })
    ;["dragleave", "drop"].forEach(eventName => {
      this._drop.addEventListener(
        eventName,
        () => {
          this.setState({
            dropHovered: false,
          })
        },
        false,
      )
    })

    // Handle dropped files
    this._drop.addEventListener(
      "drop",
      e => {
        this.handleFiles(e.dataTransfer.files)
      },
      false,
    )
  }

  componentWillUnmount(): void {
    // we stop the auto-refetch
    this.props.dispatch({
      type: STOP_REPORTS_AUTOFETCH,
    })
  }

  handleFiles = files => {
    this.props.dispatch(Actions.selectFiles(files))

    this._uploadModal.getWrappedInstance().openModal()
  }

  render() {
    const { reportsDetails } = this.props
    let error
    if (reportsDetails.error && reportsDetails.error.notPermitted) error = <NotPermitted />
    else if (reportsDetails.error) error = <Error />

    if (error) {
      return <ContentLayout title={<h4>Reports</h4>}>{error}</ContentLayout>
    }

    return (
      <>
        <UploadModal ref={el => (this._uploadModal = el)} />
        <div className="large-12 columns">
          <div className="row category-title medium-collapse">
            <div className="large-12 columns">
              <h4>Reports</h4>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <div className="row">
                <div className="large-12 columns">
                  <form>
                    <div
                      ref={el => (this._drop = el)}
                      className={`upload-container${this.state.dropHovered ? " highlight" : ""}`}
                    >
                      <label
                        htmlFor="report-upload"
                        className="custom-file-upload greyed"
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <img
                          alt="Upload cloud icon"
                          src="//dashboard.processout.com/images/upload-cloud.png"
                          style={{
                            opacity: 0.3,
                            height: "3em",
                          }}
                        />
                        <div>
                          {this.state.dropHovered ? "Drop to upload..." : "Drag your report here"}
                        </div>
                      </label>
                      <input
                        id="report-upload"
                        type="file"
                        multiple="multiple"
                        onClick={event => {
                          // Trick to detect same file selection
                          event.target.value = null
                        }}
                        onChange={e => {
                          this.handleFiles(e.target.files)
                        }}
                      />
                      <div
                        className="row"
                        style={{
                          marginTop: "1.5em",
                          fontSize: "1em",
                        }}
                      >
                        <div className="medium-12 columns text-center">
                          <p className="text-center greyed">
                            Upload reports generated by your Payment Providers so ProcessOut can
                            ingest them and provide you with analytics, monitoring and
                            reconciliation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {reportsDetails.uploadingReports.length > 0 ? (
            <div className="row">
              <div className="large-12 columns">
                <ContentLayout
                  title={
                    <div className="row">
                      <div className="large-12 columns">
                        <h5>Uploading</h5>
                      </div>
                    </div>
                  }
                >
                  <div
                    className="row capitalize greyed bold small-margin-bottom"
                    style={{
                      padding: "0 1em 0 1em",
                    }}
                  >
                    <div
                      className="medium-9 columns"
                      style={{
                        fontSize: ".8em",
                      }}
                    >
                      <span>File name</span>
                    </div>
                    <div
                      className="medium-3 columns text-right"
                      style={{
                        fontSize: ".8em",
                      }}
                    >
                      <span>Upload progress</span>
                    </div>
                  </div>
                  {reportsDetails.uploadingReports.map(report => (
                    <UploadingReport report={report} key={report.id} />
                  ))}
                </ContentLayout>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="large-12 columns">
              <ContentLayout
                title={
                  <div className="row">
                    <div className="large-12 columns">
                      <h5>Uploads history</h5>
                    </div>
                  </div>
                }
              >
                {reportsDetails.fetching || !reportsDetails.fetched ? (
                  <Loading />
                ) : (
                  <>
                    <div className="row capitalize greyed bold small-margin-bottom">
                      <div
                        className="medium-3 columns"
                        style={{
                          fontSize: ".8em",
                        }}
                      >
                        <span>Date</span>
                      </div>
                      <div
                        className="medium-7 columns"
                        style={{
                          fontSize: ".8em",
                        }}
                      >
                        <span>File name</span>
                      </div>
                      <div
                        className="medium-2 columns text-right"
                        style={{
                          fontSize: ".8em",
                        }}
                      >
                        <span>Progress</span>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        borderRadius: "4px",
                      }}
                    >
                      <div className="large-12 columns">
                        {reportsDetails.reports.map(report => (
                          <Report key={report.id} report={report} />
                        ))}
                        {reportsDetails.count === 0 ? (
                          <Empty text="No reports have been uploaded yet." />
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
                <Pagination
                  reloadFunction={Actions.requestReportsFetch.bind(null, "", 10)}
                  itemsArray={reportsDetails.fetched ? reportsDetails.reports : []}
                  hasMore={!reportsDetails.fetched ? false : reportsDetails.hasMore}
                  location={this.props.location}
                  hide={reportsDetails.fetching || !reportsDetails.fetched}
                  isFirstPage={this.props.location.query.before_item && !reportsDetails.hasMore}
                />
              </ContentLayout>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(store => ({
  reportsDetails: store.reports,
}))(Reports)
