export default function reducer(
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case "FETCH_USER_PENDING": {
      return { ...state, fetching: true, fetched: false }
    }

    case "FETCH_USER_FULFILLED": {
      return { ...state, fetching: false, fetched: true, ...action.payload.user }
    }
  }

  return state
}
