import React from "react"
import uniqid from "uniqid"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import qs from "qs"
import { FormattedNumber, IntlProvider } from "react-intl"
import * as ReactDOMServer from "react-dom/server"
import { PieChart as PicassoPieChart } from "@processout/picasso"
import type { $ChartType, $DataPoint } from "../../Boards/consts"
import type { $Dispatcher, $RouterLocation } from "../../../../util/Types"
import ChartPopup from "../../Boards/charts/Popup/ChartPopup"
import { CLOSE_CHART_POPUP, OPEN_CHART_POPUP } from "../../Boards/charts/Popup/consts"
import "./charts.scss"
import Legend from "./Legend"
import { generateKeysColors, KeyColor } from "../Utils"
import { ANALYTICS_FILTER_CHANGE } from "^/Actions/AnalyticsActions"
import AmountTooltip from "./AmountTooltip"
import DefaultTooltip from "./DefaultTooltip"
import PercentTooltip from "./PercentTooltip"
import type { $ChartPoint } from "../Consts"
import { segmentAnalytics } from "^/vNext/Segment"

type Props = {
  location: $RouterLocation
  canUpdateAnalyticsFilter?: boolean
  error?: boolean | null | undefined
  data: Array<$ChartPoint>
  type: $ChartType
  popup: {
    chartId: string | null | undefined
  }
  currency: string
} & $Dispatcher
type State = {
  id: string
  chart: any
  colors: KeyColor[]
  filter: string | null | undefined
}
export class PieChart extends React.Component<Props, State> {
  _chartElement: any

  constructor() {
    super()
    this.state = {
      id: uniqid(),
      chart: null,
      colors: [],
      filter: "",
    }
  }

  componentDidMount(): void {
    this.generateColorsAndRequestDraw(this.props)
  }

  componentDidUpdate() {
    const { error } = this.props
    if (!error) this.draw()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.generateColorsAndRequestDraw(nextProps)
  }

  generateColorsAndRequestDraw(props: Props): void {
    const colors = generateKeysColors(props.data.map(d => d.key))
    this.setState(
      {
        colors,
      },
      () => {
        this.requestDraw(props)
      },
    )
  }

  requestDraw = (props: Props) => {
    if (props.error) return
    this.draw()
  }
  getTotalCount = () => {
    const { data } = this.props
    return data.reduce((total, entry) => total + entry._count, 0)
  }
  getTotalValue = () => {
    const { data } = this.props
    return data.reduce((total, entry) => total + entry.value, 0)
  }
  draw = () => {
    const { data, type, currency, dispatch, location } = this.props
    if (!data) return
    let chart

    if (!this.state.chart) {
      chart = new PicassoPieChart(this._chartElement, {
        tip: d => {
          const totalValue = this.getTotalValue()
          const totalCount = this.getTotalCount()
          return ReactDOMServer.renderToString(
            <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
              <div className="row">
                <div className="large-12 columns">
                  <div className="row">
                    <div className="large-12 columns">
                      <h5>
                        {d.key} —{" "}
                        <span className="greyed">
                          <FormattedNumber
                            value={
                              type === "percentage" ? d._count / totalCount : d.value / totalValue
                            }
                            style="percent"
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                          />
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">
                      {(() => {
                        switch (type) {
                          case "amount": {
                            return <AmountTooltip point={d} currency={currency} />
                          }

                          case "percentage": {
                            return <PercentTooltip point={d} />
                          }

                          default: {
                            return <DefaultTooltip point={d} />
                          }
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </IntlProvider>,
          )
        },
        onclick: (point: $DataPoint) => {
          this.setState({
            filter: point._corresponding_filter,
          })

          const onFilter = () => {
            const filter = point._corresponding_filter
            if (!filter) return
            const splits = filter.split(";")
            const filterIndex = splits.findIndex(
              entry => !entry.includes("start:") && !entry.includes("end:"),
            )
            const filterValue = splits[filterIndex] || ""
            const filterParam = qs.parse(location.query.search)
            filterParam.filter = filterValue
            const newParam = qs.stringify(filterParam)
            dispatch(push(`${location.pathname}?${newParam}`))
            dispatch({
              type: ANALYTICS_FILTER_CHANGE,
              payload: {
                filter: filterValue,
              },
            })
            segmentAnalytics?.track("PIE_CHART_CLICK_FILTER", {
              filterValue,
            })
          }

          dispatch({
            type: OPEN_CHART_POPUP,
            payload: {
              chartId: this.state.id,
              onFilter,
            },
          })
        },
      })
      this.setState({
        chart,
      })
    } else {
      chart = this.state.chart
    }

    const totalValue = this.getTotalValue()
    const totalCount = this.getTotalCount()
    chart.cleanupTip()
    chart.reset()

    for (let i = 0; i < data.length; i++) {
      chart.addSlice({
        ...data[i],
        key: data[i].key,
        data: Math.round(
          type === "percentage"
            ? (data[i]._count / totalCount) * 100
            : (data[i].value / totalValue) * 100,
        ),
        color: this.state.colors.find(c => c.key === data[i].key).color,
      })
    }

    chart.resetSVG()
    chart.draw()
  }

  render() {
    const { id, colors, filter } = this.state
    const { dispatch, data, popup, canUpdateAnalyticsFilter } = this.props
    return (
      <div
        className="pie-chart"
        onClick={e => {
          if (popup.chartId === id) {
            if (!e.target.classList.contains("popup-button")) {
              dispatch({
                type: CLOSE_CHART_POPUP,
              })
            }
          }
        }}
      >
        <svg
          className={`chart chart-popup ${id === popup.chartId ? "chart-popup-open" : ""}`}
          id={`chart${id}`}
          ref={e => (this._chartElement = e)}
          width="100%"
          height="250px"
          style={{
            position: "relative",
            left: "-3.5em",
          }}
        />
        {colors.length > 0 && colors[0].key !== "single" && (
          <Legend
            colors={data.map((entry, index) => ({
              key: entry.key,
              color: colors.find(c => c.key === entry.key).color,
            }))}
          />
        )}
        <ChartPopup
          correspondingFilter={filter}
          correspondingChartId={id}
          canUpdateAnalyticsFilter={canUpdateAnalyticsFilter}
        />
      </div>
    )
  }
}
export default connect(store => ({
  popup: store.analytics_v2.popup,
  location: store.routing.locationBeforeTransitions,
}))(PieChart)
