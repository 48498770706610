import React from "react"
import { connect } from "react-redux"
import type { $AlertBuilderState } from "./reducer"
import type { $Dispatcher } from "../../../util/Types"
import LineChart from "../../analytics/Charts/LineChart"
import Loading from "../../../components/Loader"
import Error from "../../../components/Error"
type Props = {
  alertBuilder: $AlertBuilderState
} & $Dispatcher

class AlertPreview extends React.Component<Props> {
  render() {
    const { alertBuilder } = this.props
    return (
      <div className="row">
        <div
          className="medium-12 columns"
          style={{
            minHeight: "200px",
          }}
        >
          {alertBuilder.fetching && <Loading />}
          {alertBuilder.error && <Error />}
          {alertBuilder.fetched && !alertBuilder.error && (
            <LineChart
              colors={[]}
              hidePoints
              data={[
                {
                  datapoints: alertBuilder.previewPoints,
                  key: "single",
                },
              ]}
              plotted_field={null}
              type={alertBuilder.selectedMetric.unit}
              name=""
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  alertBuilder: store.alertBuilder,
}))(AlertPreview)
