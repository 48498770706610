import React from "react"
import { Link } from "react-router"
import { FormattedDate } from "react-intl"
import * as ProcessOut from "../util/ProcessOut"
import type { $Event } from "^/stores/Reducers/EventsTransactionReducer"
type Props = {
  event: $Event
}
export default class POEvent extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  render() {
    const { event } = this.props
    let eventName = event.name
    if (eventName.length > 40) eventName = `${eventName.substring(0, 40)}...`
    return (
      <Link to={`/projects/${ProcessOut.config.ProjectId}/developer/events/${event.id}`}>
        <div className="box-row padding row">
          <div className="medium-5 columns font-console">{eventName}</div>
          <div className="medium-5 columns font-console greyed">{event.id}</div>
          <div className="medium-2 columns text-right greyed">
            <FormattedDate
              value={event.fired_at}
              day="2-digit"
              month="2-digit"
              year="numeric"
              hour="2-digit"
              minute="2-digit"
              seconds="2-digit"
            />
          </div>
        </div>
      </Link>
    )
  }
}
