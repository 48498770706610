import type { $Action, $State } from "../../util/Types"
import { FETCH_TRSN_PROBA } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
type State = {} & $State
const defaultState: State = {
  fetched: false,
  fetching: false,
  error: null,
}
export default (state: State = defaultState, action: $Action): State => {
  switch (action.type) {
    case typePending(FETCH_TRSN_PROBA): {
      return { ...state, fetching: true, fetched: false, error: null }
    }

    case typeFulfilled(FETCH_TRSN_PROBA): {
      return { ...state, ...action.payload, fetched: true, fetching: false }
    }

    case typeFailed(FETCH_TRSN_PROBA): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }
  }

  return state
}
