import { FETCH_CUSTOMER_TOKENS } from "^/Actions/CustomerDetailsActions"
import type { GatewayConfig } from "^/Types/Gateway"
import type { $Action, $State } from "^/util/Types"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import type { $Card } from "^/Types/Card"
export type $Token = {
  id: string
  customer_id: string
  invoice_id: string
  gateway_configuration?: GatewayConfig
  gateway_configuration_id: string
  card?: $Card
  card_id: string
  is_subscription_only: boolean
  type: string
  created_at: string
  verification_status: string
}
export type $CustomerTokensState = {
  tokens: Array<$Token>
} & $State
const defaultState = {
  fetching: false,
  fetched: false,
  tokens: [],
  count: 0,
}
export default function (state: $CustomerTokensState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_CUSTOMER_TOKENS): {
      return { ...state, fetched: false, fetching: true }
    }

    case typeFulfilled(FETCH_CUSTOMER_TOKENS): {
      return { ...state, ...action.payload, fetched: true, fetching: false }
    }

    case typeFailed(FETCH_CUSTOMER_TOKENS): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }
  }

  return state
}
