import type { $Action, $State } from "^/util/Types"
import {
  EDIT_INVOICE,
  FETCH_INVOICE_DETAILS,
  START_INVOICE_CREATION,
} from "^/Actions/InvoicesActions"
import type { Product } from "^/types/Product"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type InvoiceDetailsState = {
  invoice?: Product
} & $State
const defaultState = {
  fetching: false,
  fetched: false,
}
export default function (state: InvoiceDetailsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_INVOICE_DETAILS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_INVOICE_DETAILS): {
      return { ...state, fetching: false, fetched: true, invoice: action.payload.product }
    }

    case START_INVOICE_CREATION: {
      return { ...state, fetching: false, fetched: false, invoice: null }
    }

    case typeFulfilled(EDIT_INVOICE): {
      return { ...state, fetching: false, fetched: true, invoice: action.payload.product }
    }
  }

  return state
}
