import React from "react"
import { Link } from "react-router"
import moment from "moment"
import type { $PayoutItem } from "./detailsReducer"
import type { $Payout } from "./reducer"
import * as Actions from "./actions"
import { $LaunchDarklyState } from "../../vNext/LaunchDarkly/LaunchDarklyReducer"
import { connect } from "react-redux"

type Props = {
  payoutItem: $PayoutItem
  project: string
  payout: $Payout
}

const Wrapper: React.FC<
  React.PropsWithChildren<{
    projectId: string
    transactionId: string
    launchDarkly: $LaunchDarklyState
  }>
> = ({ projectId, transactionId, launchDarkly, children }) => {
  if (!transactionId) return <div>{children}</div>

  return launchDarkly.flags["new-dash-transactions-page"] ? (
    <a
      href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${projectId}/transactions/${transactionId}`}
      target="_self"
    >
      {children}
    </a>
  ) : (
    <Link to={`/projects/${projectId}/transactions/${transactionId}`}>{children}</Link>
  )
}

const ConnectedWrapper = connect(store => ({
  launchDarkly: store.launchDarkly,
}))(Wrapper)

class PayoutItem extends React.PureComponent {
  props: Props

  render() {
    const { payoutItem, project, payout } = this.props
    return (
      <ConnectedWrapper projectId={project} transactionId={payoutItem.transaction_id}>
        <div className="row payout-item-row">
          <div className="medium-1 columns">
            <span
              className={`log-level ${
                payoutItem.type === "sale"
                  ? "success"
                  : payoutItem.type === "chargeback"
                  ? "failed"
                  : "warning"
              }`}
            >
              {payoutItem.type}
            </span>
          </div>
          <div className="medium-2 columns">
            {payoutItem.amount && payoutItem.amount !== "0" ? (
              Actions.formatCurrency(payoutItem.amount, payout.currency)
            ) : (
              <span className="greyed">—</span>
            )}
          </div>
          <div className="medium-2 columns">
            {payoutItem.fees && payoutItem.fees !== "0" ? (
              Actions.formatCurrency(payoutItem.fees, payout.currency)
            ) : (
              <span className="greyed">—</span>
            )}
          </div>
          <div className="medium-4 columns">
            <span className="font-console greyed">{payoutItem.gateway_resource_id}</span>
          </div>
          <div className="medium-3 columns text-right">
            {moment(payoutItem.created_at).calendar()}
          </div>
        </div>
      </ConnectedWrapper>
    )
  }
}

export default PayoutItem
