import React, { useContext, useEffect } from "react"
import { $RoutingRule } from "^/features/RoutingRules/consts"
import { segmentAnalytics } from "../Segment"
import { track, trackTarget } from "../tracking"
import { RoutingContext } from "./RoutingContext"

export type Props = {
  routingRules: Array<$RoutingRule>
  editing: boolean
  children: React.ReactNode
  onUpdateRule: (routingRule: $RoutingRule) => void
  onSortEnd: (oldIndex: number, newIndex: number) => void
  onRemoveRule: (ruleId: string) => void
}

export const KeyboardHandler = ({
  routingRules,
  editing,
  children,
  onUpdateRule,
  onSortEnd,
  onRemoveRule,
}: Props) => {
  const [state, dispatch] = useContext(RoutingContext)
  useEffect(() => {
    const onKeyDown = evt => {
      if (!editing) return

      if (evt.key === "ArrowDown") {
        if (state.selectedRuleIndex === -1) return
        const newIndex = Math.min(state.selectedRuleIndex + 1, routingRules.length - 1)
        onSortEnd(state.selectedRuleIndex, newIndex)
        dispatch({
          type: "select.routing.rule",
          index: newIndex,
        })
      } else if (evt.key === "ArrowUp") {
        if (state.selectedRuleIndex === -1) return
        const newIndex = Math.max(state.selectedRuleIndex - 1, 0)
        onSortEnd(state.selectedRuleIndex, newIndex)
        dispatch({
          type: "select.routing.rule",
          index: newIndex,
        })
      } else if (evt.key === "Delete") {
        // If condition is selected then delete condition
        if (state.selectedCardId) {
          dispatch({
            type: "select.routing.card",
            id: null,
          })

          const selectedRule = routingRules[state.selectedRuleIndex]
          const newRule = structuredClone(selectedRule)
          newRule.conditions[0].filters = newRule.conditions[0].filters.filter(
            item => item.id !== state.selectedCardId,
          )
          newRule.gateways = newRule.gateways.filter(item => item.id !== state.selectedCardId)
          onUpdateRule(newRule)

          if (newRule.gateways.length !== selectedRule.gateways.length) {
            track("Routing Rules V2", "Delete(keyboard)", "Route", "Remove Gateway Card")
          } else {
            track("Routing Rules V2", "Delete(keyboard)", "Route", "Remove Condition Card")
          }
        } // otherwise delete route
        else if (state.selectedRuleIndex !== -1) {
          onRemoveRule(routingRules[state.selectedRuleIndex].id)
        }
      }
    }

    document.addEventListener("keydown", onKeyDown)
    return () => document.removeEventListener("keydown", onKeyDown)
  }, [state, routingRules.length, editing])
  return <>{children}</>
}
