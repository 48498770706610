import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import PlansModal from "./PlansModal"
import type { $Plan } from "^/stores/Reducers/PlansReducer"
type Props = {
  plan: $Plan
}
type State = {
  showDetails: boolean
}
export class Plan extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      showDetails: false,
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  toggle() {
    this.setState({
      showDetails: !this.state.showDetails,
    })
  }

  displayPlan(event) {
    event.preventDefault()

    this._modal.getWrappedInstance().openModal()
  }

  render() {
    const { plan } = this.props
    const { id } = plan
    let interval = <span>every {plan.interval_days} days</span>
    if (plan.interval_days == 1) interval = <span>per day</span>
    else if (plan.interval_days == 7) interval = <span>per week</span>
    else if (plan.interval_days == 30) interval = <span>per month</span>
    else if (plan.interval_days == 365) interval = <span>per year</span>
    return (
      <div>
        <PlansModal modalName="Edit plan" plan={plan} ref={m => (this._modal = m)} />
        <div className="box-row row padding">
          <div className="medium-10 columns">
            <a onClick={this.displayPlan.bind(this)}>
              <div className="row">
                <div className="medium-2 columns">
                  <div className="big-font">
                    <span className="greyed">{plan.currency}</span>{" "}
                    <FormattedNumber
                      value={plan.amount}
                      style="currency"
                      currency={plan.currency}
                    />
                  </div>
                </div>
                <div
                  className="medium-2 columns"
                  style={{
                    marginLeft: "-1em",
                  }}
                >
                  {interval}
                </div>
                <div className="medium-8 columns">{plan.name}</div>
              </div>
            </a>
          </div>
          <div className="medium-2 expanded columns text-right">
            <a target="_blank" href={plan.url} rel="noreferrer">
              Payment link ↗
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(Plan)
