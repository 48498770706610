import { all, call, put, takeLatest } from "redux-saga/effects"
import * as ProcessOut from "^/util/ProcessOut"
import type { $Params } from "../../analytics/ChartPreviewer/consts"
import {
  $TelescopeV2Page,
  $TimespanMetrics,
  METRICS_TEMPLATE,
  REQUEST_PREPARE_TELESCOPE_V2_PAGE,
  SELECT_CHARGEBACK_GATEWAY_CONFIGURATION,
} from "../consts"
import {
  fetch3dsSuccessRate,
  fetch3DSTriggerRate,
  fetchAuthenticationRateByCardScheme,
  fetchAuthenticationRateByGateway,
  fetchAuthenticationRateByPaymentMethod,
  fetchAuthenticatedTransactionAmount,
  fetchAuthenticatedTransactionCount,
  fetchAuthorizationRate,
  fetchAuthorizationRateByCardScheme,
  fetchAuthorizationRateByCardType,
  fetchAuthorizationRateByGateway,
  fetchAuthorizationRateByPaymentMethod,
  fetchAuthorizedAmount,
  fetchAuthorizedTransactions,
  fetchCapturedTransactionsAmount,
  fetchCapturedTransactionsCount,
  fetchCapturedTransactionsRate,
  fetchCapturesAndFees,
  fetchChargebacks,
  fetchConversionRate,
  fetchDeclinedRate,
  fetchDeclinedTransactions,
  fetchDeclinedTransactionsAmount,
  fetchFailedCapturesAmount,
  fetchFailedCapturesCount,
  fetchFailedCapturesRate,
  fetchFailedCapturesRateByCardScheme,
  fetchFailedCapturesRateByGateway,
  fetchFailedCapturesRateByPaymentMethod,
  fetchFailedRefundsAmount,
  fetchFailedRefundsnumber,
  fetchFailedRefundsRate,
  fetchFailedRefundsRateByCardScheme,
  fetchFailedRefundsRateByGateway,
  fetchFailedRefundsRateByPaymentMethod,
  fetchFeesAmount,
  fetchFeesRate,
  fetchFeesRateByCardScheme,
  fetchFeesRateByGateway,
  fetchFeesRateByPaymentMethod,
  fetchNumberOfTransactions,
  fetchRefundsAmount,
  fetchRefundsAndVoids,
  fetchRefundsnumber,
  fetchRefundsRate,
  fetchRefundsRateByCardScheme,
  fetchRefundsRateByGateway,
  fetchRefundsRateByPaymentMethod,
  fetchTableAuthorizationDatas,
  fetchTableDomainsSecureDatas,
  fetchTableFailedCapturesDatas,
  fetchTableFailedRefundsDatas,
  fetchTableFeesDatas,
  fetchTableRefundsDatas,
  fetchTableVoidedTransactionsDatas,
  fetchTotalTransactionAmount,
  fetchVoidedTransactionsAmount,
  fetchVoidedTransactionsCount,
  fetchVoidedTransactionsRate,
  fetchVoidedTransactionsRateByCardScheme,
  fetchVoidedTransactionsRateByGateway,
  fetchVoidedTransactionsRateByPaymentMethod,
  fetchTriggered3dsTransactionAmount,
  fetchTriggered3dsTransactionCount,
  fetchFailedAuthenticationTransactionAmount,
  fetchFailedAuthenticationTransactionCount,
} from "./metricSagas"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { selectChargebackGatewayConfiguration } from "../actions"
import {
  getEndDateUtcFromPreviousPeriod,
  getEndDateUtcUnix,
  getStartDateUtcFromPreviousPeriod,
  getStartDateUtcUnixFromTimespan,
  getStartOfTheMonthUtcUnix,
} from "^/features/TelescopeV2/util"

type $PrepareTelescopeV2PageActions = {
  type: string
  payload: {
    page: $TelescopeV2Page
    period: $TimespanMetrics
  }
}

export function* prepareTelescopeV2page(
  action: $PrepareTelescopeV2PageActions,
): Generator<any, any, any> {
  const { page, period } = action.payload

  const calls = {
    overview: [
      call(fetchConversionRate, period),
      call(fetchTotalTransactionAmount, period),
      call(fetchNumberOfTransactions, period),
      call(fetchAuthorizationRate, period),
      call(fetch3dsSuccessRate, period),
      call(fetchCapturesAndFees, period),
      call(fetchRefundsAndVoids, period),
      call(fetchChargebacks, period),
      call(fetchCapturedTransactionsRate, period),
      call(fetchFailedCapturesRate, period),
      call(fetchVoidedTransactionsRate, period),
    ],
    anomalyDetection: [
      call(fetchAuthorizationRate, period),
      call(fetchConversionRate, period),
      call(fetch3dsSuccessRate, period),
    ],
    authorization: [
      call(fetchAuthorizationRate, period),
      call(fetchTotalTransactionAmount, period),
      call(fetchNumberOfTransactions, period),
      call(fetchAuthorizedAmount, period),
      call(fetchAuthorizedTransactions, period),
      call(fetchDeclinedTransactions, period),
      call(fetchDeclinedTransactionsAmount, period),
      call(fetchDeclinedRate, period),
      call(fetchAuthorizationRateByGateway, period),
      call(fetchAuthorizationRateByCardScheme, period),
      call(fetchAuthorizationRateByCardType, period),
      call(fetchAuthorizationRateByPaymentMethod, period),
      call(fetchTableAuthorizationDatas, period),
    ],
    domainsSecure: [
      call(fetchAuthenticatedTransactionAmount, period),
      call(fetchAuthenticatedTransactionCount, period),
      call(fetch3DSTriggerRate, period),
      call(fetch3dsSuccessRate, period),
      call(fetchDeclinedRate, period),
      call(fetchTriggered3dsTransactionAmount, period),
      call(fetchTriggered3dsTransactionCount, period),
      call(fetchFailedAuthenticationTransactionAmount, period),
      call(fetchFailedAuthenticationTransactionCount, period),
      call(fetchAuthenticationRateByGateway, period),
      call(fetchAuthenticationRateByCardScheme, period),
      call(fetchAuthenticationRateByPaymentMethod, period),
      call(fetchTableDomainsSecureDatas, period),
    ],
    captureAndVoids: [
      call(fetchCapturedTransactionsRate, period),
      call(fetchCapturedTransactionsCount, period),
      call(fetchCapturedTransactionsAmount, period),
      call(fetchFailedCapturesRate, period),
      call(fetchFailedCapturesCount, period),
      call(fetchFailedCapturesAmount, period),
      call(fetchVoidedTransactionsRate, period),
      call(fetchVoidedTransactionsCount, period),
      call(fetchVoidedTransactionsAmount, period),
      call(fetchTableFailedCapturesDatas, period),
      call(fetchFailedCapturesRateByGateway, period),
      call(fetchFailedCapturesRateByCardScheme, period),
      call(fetchFailedCapturesRateByPaymentMethod, period),
      call(fetchTableVoidedTransactionsDatas, period),
      call(fetchVoidedTransactionsRateByGateway, period),
      call(fetchVoidedTransactionsRateByCardScheme, period),
      call(fetchVoidedTransactionsRateByPaymentMethod, period),
    ],
    fees: [
      call(fetchFeesRate, period),
      call(fetchFeesAmount, period),
      call(fetchTableFeesDatas, period),
      call(fetchFeesRateByGateway, period),
      call(fetchFeesRateByCardScheme, period),
      call(fetchFeesRateByPaymentMethod, period),
    ],
    refunds: [
      call(fetchRefundsRate, period),
      call(fetchRefundsAmount, period),
      call(fetchRefundsnumber, period),
      call(fetchFailedRefundsRate, period),
      call(fetchFailedRefundsAmount, period),
      call(fetchFailedRefundsnumber, period),
      call(fetchTableRefundsDatas, period),
      call(fetchRefundsRateByGateway, period),
      call(fetchRefundsRateByCardScheme, period),
      call(fetchRefundsRateByPaymentMethod, period),
      call(fetchTableFailedRefundsDatas, period),
      call(fetchFailedRefundsRateByGateway, period),
      call(fetchFailedRefundsRateByCardScheme, period),
      call(fetchFailedRefundsRateByPaymentMethod, period),
    ],
    chargebacks: [call(fetchChargebackConfigurations)],
  }

  yield put({
    type: typePending(`PREPARE_TELESCOPE_V2_${page.toUpperCase()}`),
  })
  try {
    const results = yield all(calls[page])
    yield put({
      type: typeFulfilled(`PREPARE_TELESCOPE_V2_${page.toUpperCase()}`),
      payload: results.reduce((value, result) => ({ ...value, ...result }), {}),
    })
  } catch {
    yield put({
      type: typeFailed(`PREPARE_TELESCOPE_V2_${page.toUpperCase()}`),
    })
  }
}

export function* fetchTelescopeMetricDatas(
  formula: string,
  timespan: $TimespanMetrics,
): Generator<any, any, any> {
  const chart = {
    ...METRICS_TEMPLATE,
    settings: {
      formula,
    },
  }
  const httpResult = yield ProcessOut.APIcallPromise(
    computeUrl("/boards/board_default-sales/charts", timespan),
    "POST",
    JSON.stringify(chart),
    null,
    null,
    true,
  )
  if (!httpResult.data.success) {
    return null
  }

  return httpResult.data.data
}

export function* fetchTelescopeMetricEvolutionDatas(
  formula: string,
  timespan: $TimespanMetrics,
): Generator<any, any, any> {
  const chart = {
    ...METRICS_TEMPLATE,
    settings: {
      formula,
    },
  }
  const httpResult = yield ProcessOut.APIcallPromise(
    computeUrlEvolution("/boards/board_default-sales/charts", timespan),
    "POST",
    JSON.stringify(chart),
    null,
    null,
    true,
  )
  if (!httpResult.data.success) {
    return null
  }

  return httpResult.data.data
}

export function* loadCharts(
  formula: string,
  params: $Params,
  timespan: $TimespanMetrics,
): Generator<any, any, any> {
  const chart = {
    ...METRICS_TEMPLATE,
    settings: {
      formula,
    },
    name: "Authorization rate",
    type: "line-chart",
    description: "",
  }
  const httpResult = yield ProcessOut.APIcallPromise(
    `/boards/board_default-sales/charts?interval=1d&start_at=${getStartDateUtcUnixFromTimespan(
      timespan,
    )}&end_at=${getEndDateUtcUnix()}&filter=${params.filter}`,
    "POST",
    chart,
  )

  if (!httpResult.data.success) {
    return null
  }

  return httpResult.data.data
}

export const computeUrl = (url: string, timespan: $TimespanMetrics): string => {
  return `${url}?start_at=${getStartDateUtcUnixFromTimespan(
    timespan,
  )}&end_at=${getEndDateUtcUnix()}`
}

export const computeUrlEvolution = (url: string, timespan: $TimespanMetrics) => {
  return `${url}?start_at=${getStartDateUtcFromPreviousPeriod(
    timespan,
  )}&end_at=${getEndDateUtcFromPreviousPeriod(timespan)}`
}

export function* fetchChargebackConfigurations(): Generator<any, any, any> {
  const apiReturn = yield call(
    ProcessOut.APIcallPromise,
    `/analytics/telescope/chargebacks?start_at=${getStartOfTheMonthUtcUnix()}`,
    "GET",
  )

  // Retrieve all gateways from the project
  const gatewayConfigurationNames = yield call(
    ProcessOut.APIcallPromise,
    "/helpers/gateway-configurations/names",
    "GET",
  )

  const gatewayConfigurations = Object.keys(apiReturn.data.chargebacks).map(key => ({
    id: key,
    belongsToProgram: apiReturn.data.chargebacks[key],
    gatewayConfigurationName: gatewayConfigurationNames.data.gateway_configuration_names[key],
  }))

  // force the reset of chargebackDatas and selectedGateway
  // the state stays if we switch to sandbox mode (certainly because of the when you trigger the sandbox button `replace`)
  // this should be deleted as soon as we've found a way to prevent the state to be maintain after enabling sandbox
  yield put({
    type: typeFulfilled("PREPARE_TELESCOPE_V2_CHARGEBACKS"),
    payload: { chargebackDatas: null, selectedGateway: null },
  })

  // select the first gateway by default
  if (gatewayConfigurations.length > 0) {
    const firstGatewayConf = {
      label: gatewayConfigurations[0].gatewayConfigurationName,
      value: gatewayConfigurations[0].id,
    }
    yield put(selectChargebackGatewayConfiguration(firstGatewayConf))
  }

  return {
    chargebackGateway: gatewayConfigurations,
  }
}

export function* fetchChargebackDatas(action): Generator<any, any, any> {
  const apiReturn = yield call(
    ProcessOut.APIcallPromise,
    `/analytics/telescope/chargebacks/${
      action.payload.config.value
    }/details?start_at=${getStartOfTheMonthUtcUnix()}`,
    "GET",
  )

  yield put({
    type: typeFulfilled("PREPARE_TELESCOPE_V2_CHARGEBACKS"),
    payload: { chargebackDatas: apiReturn.data, selectedGateway: action.payload.config },
  })
}

export default function* requestTelescopeV2Preparation(): Generator<any, any, any> {
  yield takeLatest(REQUEST_PREPARE_TELESCOPE_V2_PAGE, prepareTelescopeV2page)
  yield takeLatest(SELECT_CHARGEBACK_GATEWAY_CONFIGURATION, fetchChargebackDatas)
}
