import { Context, segmentAnalytics } from "./Segment"

const LOCATION_ATTRIBUTE = "data-tracking-location"
const SUB_PAGE_ATTRIBUTE = "data-tracking-sub-page"
const AUTO_TRACKING = "data-auto-tracking"
const AUTO_TRACKING_LABEL = "data-tracking-label"

export function track(
  subPageName: string,
  actionName: string,
  locationName: string,
  objectName: string,
  context?: Context,
) {
  segmentAnalytics?.track(`${subPageName} - ${actionName} - ${locationName} - ${objectName}`, {
    ...context,
    subPageName,
    actionName,
    locationName,
    objectName,
  })
}

export function trackTarget(
  target: HTMLElement,
  actionName: string,
  objectName: string,
  context: Context = {},
) {
  try {
    const locationName = target
      ?.closest(`[${LOCATION_ATTRIBUTE}]`)
      ?.getAttribute(LOCATION_ATTRIBUTE)
    const subPageName = target?.closest(`[${SUB_PAGE_ATTRIBUTE}]`)?.getAttribute(SUB_PAGE_ATTRIBUTE)

    const chunks = []
    if (subPageName) {
      chunks.push(subPageName)
    }
    chunks.push(actionName)
    if (locationName) {
      chunks.push(locationName)
    }
    chunks.push(objectName)

    segmentAnalytics?.track(chunks.join(" - "), {
      ...context,
      subPageName,
      actionName,
      locationName,
      objectName,
    })
  } catch (err) {
    console.log(err)
  }
}

// Any attribute named as data-tag-<name> will be aggregated to form the context.
const dataTagRegex = /data-tag-(.*)/
/**
 * Initialize auto tracking
 * You should never nest auto tracking element. Because the event source target might not be the one generated the event, the listener
 * looks for the closet element has auto tracking enable and use that as tracking element.
 */
export function initAutoTracking() {
  const handler = e => {
    if (e.target instanceof HTMLElement) {
      let autoTrackTarget = e.target
      if (!autoTrackTarget.hasAttribute(AUTO_TRACKING)) {
        autoTrackTarget = e.target.closest(`[${AUTO_TRACKING_LABEL}]`)
      }

      if (
        autoTrackTarget instanceof HTMLElement &&
        autoTrackTarget.getAttribute(AUTO_TRACKING) === "true"
      ) {
        const attributeNames = autoTrackTarget.getAttributeNames()
        const context = new Map()
        attributeNames.forEach(name => {
          const matches = dataTagRegex.exec(name)
          if (matches != null && matches.length !== 0) {
            context.set(matches[1], autoTrackTarget.getAttribute(name))
          }
        })

        const label =
          autoTrackTarget.getAttribute(AUTO_TRACKING_LABEL) || autoTrackTarget?.textContent
        trackTarget(autoTrackTarget, "Click", label, Object.fromEntries(context))
      }
    }
  }

  // Just in case init is called multiple times
  document.body.removeEventListener("click", handler, true)
  document.body.addEventListener(
    "click",
    handler,
    // Use capture phase so event won't be stopped by stopPropagation or confused if event target is removed from DOM
    true,
  )
}
