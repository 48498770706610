import type { $Action } from "^/util/Types"
import * as ProcessOut from "../util/ProcessOut"
import { datadogRum } from "@datadog/browser-rum"
export const FETCH_EVENTS_TRANSACTION = "FETCH_EVENTS_TRANSACTION"
export function loadTransactionByID(transaction_id): $Action {
  return {
    type: FETCH_EVENTS_TRANSACTION,
    payload: ProcessOut.APIcallPromise(`/events?filter_by_resource_id=${transaction_id}`, "GET")
      .then(response => response.data.events)
      .catch(err => datadogRum.addError(err)),
  }
}
