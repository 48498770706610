export const TRANSACTION_FIELDS: Array<{
  key: string
  desc: string
  group: string
  type: "string" | "number" | "bool"
}> = [
  {
    key: "is_network_token",
    desc: "If Network token was used for a transaction",
    group: "Transaction parameters",
    type: "bool",
  },
  {
    key: "error_code",
    desc: "Transaction error codes: do-not-honor…",
    group: "Results",
    type: "string",
  },
  {
    key: "payment_type",
    desc: "Transaction payment types: Card, Paypal…",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "name",
    desc: "Transaction names",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "currency",
    desc: "Currency used for the transactions: USD, EUR, CAD…",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "status",
    desc: "Transaction status: completed, pending, failed…",
    group: "Results",
    type: "string",
  },
  {
    key: "card_country",
    desc: "Country in which the transactions occured: FR, US, UK…",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "continent",
    desc: "Continent in which the transactions occured",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "subscription_cycle",
    desc: "Number of iteration of a subscription",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "card_scheme",
    desc: "Card scheme: Visa, Mastercard, Amex…",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "card_co_scheme",
    desc: "Card co-schemes: CB…",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "card_iin",
    desc: "Card BIN/IIN",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "card_type",
    desc: "Card types: Debit, Credit…",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "card_category",
    desc: "Card categories: ",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "card_bank",
    desc: "Bank that issued the card used for the transactions",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "effective_scheme",
    desc: "The effective scheme used for the transactions",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "cvc_check",
    desc: "CVC status for transactions: passed, checked…",
    group: "Card parameters",
    type: "string",
  },
  {
    key: "acquirer_name",
    desc: "Name of acquirers that handled the transactions",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "gateway_configuration_id",
    desc: "ID of the gateway configuration used for a transaction",
    group: "Gateway parameters",
    type: "string",
  },
  {
    key: "gateway_name",
    desc: "Name of the gateways used: Stripe, Adyen, Braintree…",
    group: "Gateway parameters",
    type: "string",
  },
  {
    key: "gateway_fee_currency",
    desc: "Currency of the fees paid to the gateway",
    group: "Gateway parameters",
    type: "string",
  },
  {
    key: "created_at",
    desc: "Transaction creation dates",
    group: "Time",
    type: "string",
  },
  {
    key: "created_at_day_hour",
    desc: "Hour of the day transaction was created (int)",
    group: "Time",
    type: "number",
  },
  {
    key: "created_at_day_hour_segment",
    desc: "Hour of the day transaction was created (string)",
    group: "Time",
    type: "string",
  },
  {
    key: "created_at_month_day",
    desc: "Day of the month transaction was created (int)",
    group: "Time",
    type: "number",
  },
  {
    key: "created_at_week_day",
    desc: "Day of the week transaction was created (int)",
    group: "Time",
    type: "number",
  },
  {
    key: "created_at_week_day_name",
    desc: "Day of the week transaction was created (name/string)",
    group: "Time",
    type: "string",
  },
  {
    key: "three_d_s.status",
    desc: "Whether or not the transactions went through 3DS",
    group: "Results",
    type: "string",
  },
  {
    key: "three_d_s.version",
    desc: "The 3DS version used",
    group: "Results",
    type: "string",
  },
  {
    key: "three_d_s.ares_status",
    desc: "The 3DS ares status",
    group: "Results",
    type: "string",
  },
  {
    key: "three_d_s.cres_status",
    desc: "The 3DS cres status",
    group: "Results",
    type: "string",
  },
  {
    key: "three_d_s.device_channel",
    desc: "The 3DS device channel",
    group: "Results",
    type: "string",
  },
  {
    key: "three_d_s.fingerprinted",
    desc: "Fingerprinted",
    group: "Results",
    type: "bool",
  },
  {
    key: "three_d_s.challenged",
    desc: "Challenged",
    group: "Results",
    type: "bool",
  },
  {
    key: "eci",
    desc: "Transaction type (such as recurring)",
    group: "Results",
    type: "string",
  },
  {
    key: "mcc",
    desc: "Merchant Category Code",
    group: "Gateway parameters",
    type: "string",
  },
  {
    key: "merchant_account_id",
    desc: "ID of the merchant account",
    group: "Gateway parameters",
    type: "string",
  },
  {
    key: "is_on_us",
    desc: "Whether the transaction is performed on-us or not",
    group: "Transaction parameters",
    type: "bool",
  },
  {
    key: "origin",
    desc: "Origin of the transaction",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "authorization_retry_attempt",
    desc: "Authorization retry number",
    group: "Transaction parameters",
    type: "number",
  },
  {
    key: "is_last_operation",
    desc: "True if the operation is the last operation that has been performed on a transaction.",
    group: "Transaction parameters",
    type: "bool",
  },
  {
    key: "routing_tags",
    desc: "Routing tags linked to the transaction",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "challenge_indicator",
    desc: "Challenge indicator",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "sca_exemption_reason",
    desc: "Exemption reason",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "merchant_advice_code",
    desc: "Merchant advice code",
    group: "Results",
    type: "number",
  },
  {
    key: "merchant_advice_reason",
    desc: "Merchant advice reason",
    group: "Results",
    type: "string",
  },
  {
    key: "merchant_initiator_type",
    desc: "Merchant initiator type",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "payment_intent",
    desc: "Purpose of the Merchant Initiated Transaction",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "initiation_type",
    desc: "Initiation type of the transaction",
    group: "Transaction parameters",
    type: "string",
  },
  {
    key: "recommendation_code",
    desc: "Recommendation code",
    group: "Results",
    type: "number",
  },
]
export const OPERATIONS_HIDDEN_FIELDS: Array<{
  key: string
  type: "string" | "number" | "bool"
}> = [
  {
    key: "is_user_retry",
    type: "bool",
  },
  {
    key: "is_successful",
    type: "bool",
  },
  {
    key: "is_last_payment_attempt",
    type: "bool",
  },
  {
    key: "authorization_retry_attempt",
    type: "number",
  },
  {
    key: "is_card_payment",
    type: "bool",
  },
  {
    key: "has_failed",
    type: "bool",
  },
  {
    key: "three_d_s.status",
    type: "string",
  },
]
export const TRANSACTION_AMOUNT_FIELDS: Array<{
  key: string
  desc: string
  group: string
  type: "string" | "number" | "bool"
}> = [
  {
    key: "amount",
    desc: "Transaction’s amount requested at its creation.",
    group: "Amount",
    type: "number",
  },
  {
    key: "amount_local",
    desc: "Transaction’s amount requested at its creation in your project’s currency",
    group: "Amount",
    type: "number",
  },
  {
    key: "available_amount",
    desc: "Captured amount minus the total refund value.",
    group: "Amount",
    type: "number",
  },
  {
    key: "available_amount_local",
    desc: "Captured amount minus the total refund value in your project’s currency",
    group: "Amount",
    type: "number",
  },
  {
    key: "refunded_amount",
    desc: "Total refunded amount.",
    group: "Amount",
    type: "number",
  },
  {
    key: "refunded_amount_local",
    desc: "Total refunded amount in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "captured_amount",
    desc: "Total captured amount.",
    group: "Amount",
    type: "number",
  },
  {
    key: "captured_amount_local",
    desc: "Total captured amount in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "authorized_amount",
    desc: "Total authorized amount.",
    group: "Amount",
    type: "number",
  },
  {
    key: "authorized_amount_local",
    desc: "Total authorized amount in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "gateway_fee",
    desc: "Fee charged by your gateway.",
    group: "Amount",
    type: "number",
  },
  {
    key: "gateway_fee_local",
    desc: "Fee charged by your gateway in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "processing_fee",
    desc: "Total processing fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "processing_fee_local",
    desc: "Total processing fees in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "exchange_fee",
    desc: "Total exchange fee",
    group: "Amount",
    type: "number",
  },
  {
    key: "exchange_fee_local",
    desc: "Total exchange fees in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "refund_fee",
    desc: "Total refund fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "refund_fee_local",
    desc: "Total refund fee in your project’s currency",
    group: "Amount",
    type: "number",
  },
  {
    key: "interchange_fee",
    desc: "Total interchange fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "interchange_fee_local",
    desc: "Total interchange fee in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "scheme_fee",
    desc: "Total scheme (Visa, mastercard…) fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "scheme_fee_local",
    desc: "Total scheme (Visa, mastercard…) fee in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "markup_fee",
    desc: "Fee above the interchange+",
    group: "Amount",
    type: "number",
  },
  {
    key: "markup_fee_local",
    desc: "Fee above the interchange+ in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "interchange_fee_estimate",
    desc: "Total estimated interchange fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "interchange_fee_estimate_local",
    desc: "Total estimated interchange fee in your project’s currency.",
    group: "Amount",
    type: "number",
  },
  {
    key: "scheme_fee_estimate",
    desc: "Total estimated scheme (Visa, mastercard…) fee.",
    group: "Amount",
    type: "number",
  },
  {
    key: "scheme_fee_estimate_local",
    desc: "Total estimated scheme (Visa, mastercard…) fee in your project’s currency.",
    group: "Amount",
    type: "number",
  },
]
export const TRANSACTION_CUSTOM_FIELDS: Array<{
  key: string
  type: "string" | "number" | "bool"
}> = [
  {
    key: "authorized",
    type: "bool",
  },
]
