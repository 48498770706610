import { call, cancel, fork, put, take, takeEvery, takeLatest } from "redux-saga/effects"
import { Channel, delay, eventChannel } from "redux-saga"
import axios, { AxiosResponse } from "axios"
import uniqid from "uniqid"
import { NETWORK_REQUEST_FAILURE } from "../NetworkManager/consts"
import * as GatewayActions from "../../Actions/GatewaysActions"
import type { $LocalReport } from "./reducer"
import { CLOSE_MODAL } from "^/util/Types"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"
import {
  ADD_LOCAL_REPORT_UPLOAD,
  INITIATE_REPORT_UPLOAD,
  PREPARE_REPORTS_PAGE,
  REMOVE_LOCAL_REPORT_UPLOAD,
  REQUEST_REPORTS_FETCH,
  REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
  STOP_REPORTS_AUTOFETCH,
  UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
} from "^/features/Reports/actions"

function* prepareReportsPage(): Generator<any, any, any> {
  try {
    yield put(GatewayActions.loadGatewaysConfigurations())
    yield put({
      type: typeFulfilled(PREPARE_REPORTS_PAGE),
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREPARE_REPORTS_PAGE),
      payload: error,
    })

    if (error.type && error.type.includes("NETWORK")) {
      // error handled by network manager
    } else {
      datadogRum.addError(error)
    }
  }
}

function* fetchReports(action): Generator<any, any, any> {
  try {
    const { filter, length, after, id, silent } = action.payload

    if (!silent) {
      // we reset the autofetcher
      yield put({
        type: STOP_REPORTS_AUTOFETCH,
      })
    }

    yield put({
      type: typePending(REQUEST_REPORTS_FETCH),
      payload: {
        silent,
      },
    })
    // We retrieve all the reports from the API
    const bound = after ? "start_after" : "end_before"
    const reportsResult = yield APIcallPromise(
      `/uploads/reports?limit=${length}&filter=${filter}&${bound}=${id || ""}`,
      "GET",
    )
    // Dispatch the results
    yield put({
      type: typeFulfilled(REQUEST_REPORTS_FETCH),
      payload: { ...reportsResult.data, silent },
    })

    if (!silent) {
      // Init the auto-fetch
      const fetcher = yield fork(refetchReportsLooper, action)
      // Watch for auto-fetch cancel
      yield take(STOP_REPORTS_AUTOFETCH)
      yield cancel(fetcher)
    }
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_REPORTS_FETCH),
      payload: error,
    })

    if (error.type && error.type.includes("NETWORK")) {
      // error handled by the network manager
      if (error.type === NETWORK_REQUEST_FAILURE) {
        // The request could not be made so we cancel the auto-fetcher
        yield put({
          type: STOP_REPORTS_AUTOFETCH,
        })
      }
    } else {
      datadogRum.addError(error)
    }
  }
}

function* refetchReportsLooper(action): Generator<any, any, any> {
  try {
    // Start automatically updating report list
    while (true) {
      // Wait 10 s
      yield call(delay, 10000)
      yield call(fetchReports, { ...action, payload: { ...action.payload, silent: true } })
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

function* startLocalUpload(fileName: string, id: string, file: any): Generator<any, any, any> {
  try {
    yield put({
      type: ADD_LOCAL_REPORT_UPLOAD,
      payload: {
        fileName,
        id,
        progress: 0,
        file,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}

type $UpdateReportProgressAction = {
  type: string
  payload: {
    id: string
    progress: number
  }
}

function* updateLocalUploadProgress(action: $UpdateReportProgressAction): Generator<any, any, any> {
  try {
    const { id, progress } = action.payload
    yield put({
      type: UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
      payload: {
        id,
        progress,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}

function uploadReport(url, file, onUploadProgress) {
  return axios.put(url, file, {
    onUploadProgress,
  })
}

function createReportUploader(
  url,
  file,
  reportId,
): [Promise<AxiosResponse<any>>, Channel<unknown>] {
  let emit
  const chan = eventChannel(emitter => {
    emit = emitter
    return () => {}
  })
  const uploadPromise = uploadReport(url, file, progressEvent => {
    const progression = progressEvent.loaded / progressEvent.total
    emit({
      reportId,
      progress: progression,
    })
  })
  return [uploadPromise, chan]
}

function* watchUploadProgress(chan) {
  while (true) {
    const data = yield take(chan)
    yield put({
      type: REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS,
      payload: {
        id: data.reportId,
        progress: data.progress,
      },
    })
  }
}

export function* initiateReportUpload(action): Generator<any, any, any> {
  try {
    const { files, gatewayConfigurationId } = action.payload
    const localReports: Array<$LocalReport> = []

    for (let i = 0; i < files.length; i++) {
      const localId = uniqid()
      localReports.push({
        id: localId,
        fileName: files[i].name,
        progress_percentage: 0,
        file: files[i],
      })
    }

    for (const report of localReports) {
      // Store the report upload locally
      yield call(startLocalUpload, report.fileName, report.id, report.file)
    }

    yield put({
      type: CLOSE_MODAL,
    })

    for (const report of localReports) {
      // generate an upload url and start uploading it
      const urlResponse = yield APIcallPromise(
        "/uploads/reports",
        "POST",
        JSON.stringify({
          fileName: report.fileName,
          gateway_configuration_id: gatewayConfigurationId,
        }),
      )
      // start the upload
      const [uploadPromise, chan] = createReportUploader(
        urlResponse.data.url,
        report.file,
        report.id,
      )
      yield fork(watchUploadProgress, chan)
      yield call(() => uploadPromise.then(response => {}))
      yield put({
        type: REMOVE_LOCAL_REPORT_UPLOAD,
        payload: {
          id: report.id,
        },
      })
      // Update the API
      yield APIcallPromise(
        "/uploads/reports",
        "PUT",
        JSON.stringify({
          token: urlResponse.data.token,
          description: report.fileName,
        }),
      )
    }

    // Upload done, reload the reports
    yield call(fetchReports, {
      payload: {
        filter: "",
        after: false,
        id: null,
        length: 10,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(INITIATE_REPORT_UPLOAD),
      payload: {
        error,
      },
    })

    if (error.type && error.type.includes("NETWORK")) {
      // error handled by network manager
    } else {
      datadogRum.addError(error)
    }
  }
}
export default function* watchForSagas(): Generator<any, any, any> {
  yield takeEvery(PREPARE_REPORTS_PAGE, prepareReportsPage)
  yield takeLatest(REQUEST_REPORTS_FETCH, fetchReports)
  yield takeEvery(INITIATE_REPORT_UPLOAD, initiateReportUpload)
  yield takeLatest(REQUEST_UPDATE_LOCAL_REPORT_UPLOAD_PROGRESS, updateLocalUploadProgress)
}
