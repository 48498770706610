import React from "react"
import { connect } from "react-redux"
import * as CurrencyAction from "../Actions/CurrencyActions"
import { CurrenciesState } from "../stores/Reducers/CurrenciesReducer"
import { $Dispatcher } from "../util/Types"
type Props = {
  onChange: (newCurrency: string) => void
  disabled: boolean
  defaultValue: string
  name: string
  currenciesDetails: CurrenciesState
} & $Dispatcher
type State = {
  currency: string
}
export class CurrencyField extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      currency: "USD",
    }
  }

  componentDidMount() {
    if (this.props.currenciesDetails.fetching || !this.props.currenciesDetails.fetched) {
      this.props.dispatch(CurrencyAction.loadCurrencies())
    }

    if (this.props.defaultValue) {
      this.setState({
        currency: this.props.defaultValue,
      })
    }
  }

  currencyChanged() {
    const s = document.querySelector("#item-currency") as any
    const cur = s.options[s.selectedIndex].value
    this.setState({
      currency: cur,
    })

    if (this.props.onChange) {
      this.props.onChange(cur)
    }
  }

  render() {
    if (this.props.currenciesDetails.fetching || !this.props.currenciesDetails.fetched) {
      return null
    }

    const { currencies } = this.props.currenciesDetails
    return (
      <div className="row">
        <div className="large-12 columns">
          <label className="greyed">Currency</label>
          <select
            name={this.props.name ? this.props.name : "currency"}
            className="no-margin bottom-border"
            disabled={this.props.disabled}
            id="item-currency"
            value={this.state.currency}
            onChange={this.currencyChanged.bind(this)}
          >
            {currencies.map(cur => (
              // FIXME: remove name attribute which does not exist in option element
              // @ts-ignore
              <option key={cur.code} name={cur.code}>
                {cur.code}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  currenciesDetails: store.currencies,
}))(CurrencyField)
