import type { $Action } from "^/util/Types"
import * as ProcessOut from "^/util/ProcessOut"
import { datadogRum } from "@datadog/browser-rum"
import { segmentAnalytics } from "^/vNext/Segment"
import { typeFulfilled } from "^/util/ActionUtils"
export const FETCH_FRAUD_SERVICES_CONFIGURATIONS = "FETCH_FRAUD_SERVICES_CONFIGURATIONS"
export function fetchFraudServicesConfig(): $Action {
  return {
    type: FETCH_FRAUD_SERVICES_CONFIGURATIONS,
    payload: ProcessOut.APIcallPromise("/fraud/service-configurations", "GET")
      .then(response => response.data)
      .catch(err => datadogRum.addError(err)),
  }
}
export const CREATE_FRAUD_CONFIGURATION = "CREATE_FRAUD_CONFIGURATION"
export function createFraudServiceConfig(fraudService, config, optResolve) {
  return function (dispatch) {
    const body = {
      configuration_name: fraudService,
      settings: config,
    }
    const configBody = JSON.stringify(body, null)
    dispatch({
      type: CREATE_FRAUD_CONFIGURATION,
      payload: ProcessOut.APIcallPromise(
        `/fraud/${fraudService}/service-configurations`,
        "POST",
        configBody,
      )
        .then(response => {
          if (response.data.success && optResolve) {
            optResolve()
            segmentAnalytics?.track(typeFulfilled(CREATE_FRAUD_CONFIGURATION), {
              processor: fraudService,
            })
          }
        })
        .catch(err => datadogRum.addError(err)),
    })
  }
}
export const DELETE_FRAUD_CONFIGURATION = "DELETE_FRAUD_CONFIGURATION"
export function deleteFraudConfig(id, optResolve) {
  return {
    type: DELETE_FRAUD_CONFIGURATION,
    payload: ProcessOut.APIcallPromise(`/fraud/service-configurations/${id}`, "DELETE").then(
      response => {
        if (response.data.success && optResolve) {
          optResolve()
        }
      },
    ),
  }
}
export const UDPDATE_FRAUD_CONFIGURATION = "UDPDATE_FRAUD_CONFIGURATION"
export function updateFraudConfiguration(
  newConfiguration,
  configuration_id,
  callback?: (success: boolean) => void,
): $Action {
  const body = newConfiguration
  return {
    type: UDPDATE_FRAUD_CONFIGURATION,
    payload: ProcessOut.APIcallPromise(
      `/fraud/service-configurations/${configuration_id}`,
      "PUT",
      body,
    )
      .then(response => {
        if (callback) callback(true)
        return response
      })
      .catch(error => {
        if (callback) callback(false)
        return error
      }),
  }
}
