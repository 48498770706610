import React from "react"
type Props = {
  name: string
  displayName: string
  selected: boolean
  select: (arg0: string) => void
  logoUrl: string
}
export default class GatewayDisplay extends React.Component<Props> {
  selected = (event: Event) => {
    event.preventDefault()
    const { select, name } = this.props
    select(name)
  }

  render() {
    const { logoUrl, displayName, selected } = this.props
    return (
      <div
        className="large-6 columns"
        style={{
          opacity: selected ? 1 : 0.4,
        }}
      >
        <a className="payment-processor-wrapper details-box only-img" onClick={this.selected}>
          <div className="row">
            <div className="large-12 columns text-center img-wrapper">
              <img alt={`${displayName}'s logo`} className="payment-processor" src={logoUrl} />
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns text-center description">{displayName}</div>
          </div>
        </a>
      </div>
    )
  }
}
