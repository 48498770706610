import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../../util/Types"
import type { $FetchParams } from "../Boards/consts"
import * as Actions from "./actions"
// @ts-expect-error
import "./dataexplorer.scss"
import Header from "./Header"
import Previewer from "./Previewer"
import type { $DataExplorerState } from "./reducer"

type Props = {
  params: {
    project: string
    board: string | null | undefined
  }
  dataExplorer: $DataExplorerState
  isChartBuilder: boolean | null | undefined
  analytics: {
    params: $FetchParams
  }
} & $Dispatcher
export class DataExplorer extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, location, params, dataExplorer, analytics } = this.props
    dispatch(Actions.setBoardId(params.board))

    // Check if we have a chart id as parameter
    if (location.query.chart) {
      // we set up the edit mode
      dispatch(Actions.setDataExplorerChartEdition(location.query.chart))
      // And Request for the chart compilation
      dispatch(
        Actions.requestFormulaCompile(
          location.query.chart,
          params.project,
          params.board,
          analytics.params,
        ),
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { dataExplorer } = nextProps
    const oldParams = this.props.analytics.params
    const newParams = nextProps.analytics.params

    if (
      oldParams.interval.to !== newParams.interval.to ||
      oldParams.interval.from !== newParams.interval.from ||
      oldParams.timeInterval !== newParams.timeInterval ||
      oldParams.filter !== newParams.filter ||
      oldParams.timeCompare.comparing !== newParams.timeCompare.comparing
    ) {
      this.props.dispatch(
        Actions.requestChartFetch(
          dataExplorer.formula,
          dataExplorer.name || "Preview",
          dataExplorer.type,
          dataExplorer.unit,
          newParams,
        ),
      )
    }
  }

  componentWillUnmount(): void {
    const { dispatch } = this.props
    // We reset the explorer
    dispatch(Actions.resetDataExplorer())
  }

  render() {
    const { dataExplorer, location } = this.props
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="data-explorer">
            <Header isChartBuilder={this.props.isChartBuilder} />
            <div className="row margin-bottom">
              <div className="large-12 columns">
                <div className="content">
                  <div className="row">
                    <div className="large-12 columns no-padding">{this.props.children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.dataExplorer.selectedTab === "editor" ? <Previewer /> : null}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  dataExplorer: store.dataExplorer,
  analytics: store.analytics,
}))(DataExplorer)
export const DataExplorerBuilder = connect(store => ({
  dataExplorer: store.dataExplorer,
  analytics: store.analytics,
}))(props => <DataExplorer {...props} isChartBuilder />)
