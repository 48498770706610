import React from "react"
import Creatable from "react-select/creatable"
import { connect } from "react-redux"
import type { $RoutingRulesSettings } from "../consts"
import { trackTarget } from "^/vNext/tracking"

type Props = {
  tags: Array<string>
  onChange: (arg0: Array<string>) => void
  rulesSettings: $RoutingRulesSettings
}
export class TagsListing extends React.Component<Props> {
  private ref: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)
    this.ref = React.createRef<HTMLDivElement>()
  }
  onChange = (
    newValue: Array<{
      value: string
      label: string
    }>,
  ) => {
    this.props.onChange(newValue.map(value => value.value))
  }

  render() {
    const { tags, onChange, rulesSettings } = this.props
    return (
      <div className="row tags-listing" ref={this.ref}>
        <div className="medium-12 columns">
          <Creatable
            options={[]}
            value={tags.map(tag => ({
              label: tag,
              value: tag,
            }))}
            onChange={changes => {
              trackTarget(this.ref.current, "Tag Change", "Tag List")
              this.onChange(changes)
            }}
            placeholder="Tags"
            closeMenuOnSelect={false}
            isMulti
            promptTextCreator={label => label}
            noOptionsMessage={() =>
              "Add tags to easily understand what a rule is doing. Start by creating a new one."
            }
          />
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  rulesSettings: store.routingRulesSettings,
}))(TagsListing)
