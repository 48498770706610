import React from "react"
import Select from "react-select"
import { SelectStyle } from "../../analytics/DataExplorer/ChartBuilder/consts"
import type { $Option } from "../../analytics/DataExplorer/consts"
type Props = {
  value: $Option
  onChange: (arg0: $Option) => void
}
const OPTIONS: Array<$Option> = [
  {
    label: "30 seconds",
    value: 30,
  },
  {
    label: "minute",
    value: 60,
  },
  {
    label: "2 minutes",
    value: 120,
  },
  {
    label: "5 minutes",
    value: 300,
  },
  {
    label: "10 minutes",
    value: 600,
  },
  {
    label: "30 minutes",
    value: 1800,
  },
  {
    label: "hour",
    value: 3600,
  },
  {
    label: "day",
    value: 86400,
  },
]

class IntervalSelector extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label">every</div>
        <div
          className="labeled-field"
          style={{
            width: "150px",
          }}
        >
          <Select
            className="react-select"
            value={this.props.value}
            options={OPTIONS}
            styles={SelectStyle}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    )
  }
}

export default IntervalSelector
