import React from "react"
import ReactTooltip, { Place } from "react-tooltip"
export type TooltipProps = {
  children?: any
  id: string
  size?: "small" | "medium" | "large"
  place?: Place
  content: string
  light?: boolean
}
export class Tooltip extends React.Component<TooltipProps> {
  render() {
    const { children, id, content, place, size, light } = this.props
    const className = `${size || "medium"}-custom-tooltip ${light ? "tooltip-box-shadow" : ""}`
    return (
      <>
        {children}
        <ReactTooltip
          id={id}
          effect="solid"
          place={place}
          // FIXME: '' is not a valid type
          // @ts-ignore
          type={`${light ? "light" : ""}`}
          className={`${className}`}
          arrowColor={`${light ? "transparent" : ""}`}
        >
          {content}
        </ReactTooltip>
      </>
    )
  }
}
export default Tooltip
