import moment, { Moment } from "moment/moment"
import type { $State } from "../../util/Types"
import type { $Export } from "./Export"
import type {
  $RequestExportFetchAction,
  $ResetPayoutDetailsExportsForm,
  $ResetPayoutsExportsForm,
  $ResetTransactionsExportsForm,
  $UpdateExportForm,
} from "./actions"
import {
  REQUEST_EXPORTS_FETCH,
  RESET_PAYOUT_DETAILS_EXPORTS_FORM,
  RESET_PAYOUTS_EXPORTS_FORM,
  RESET_TRANSACTIONS_EXPORTS_FORM,
  UPDATE_EXPORT_FORM,
} from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $ExportState = {
  exports: Array<$Export>
  form: {
    format: "csv" | "xls"
    description: string
    columns: Array<string>
    start_at: Moment
    end_at: Moment
    email: string
    type: string
    payout_id?: string
  }
  has_more: boolean
} & $State
const defaultTransactionsState: $ExportState = {
  fetching: false,
  fetched: false,
  error: null,
  exports: [],
  has_more: true,
  form: {
    format: "csv",
    description: "",
    columns: [
      "id",
      "invoice_id",
      "name",
      "gateway_name",
      "amount",
      "currency",
      "status",
      "three_d_s_status",
      "error_code",
      "created_at",
    ],
    start_at: moment().startOf("day").subtract(1, "weeks"),
    end_at: moment().endOf("day"),
    email: "",
    type: "transaction",
  },
}
const defaultPayoutsState: $ExportState = {
  fetching: false,
  fetched: false,
  error: null,
  exports: [],
  has_more: true,
  form: {
    format: "csv",
    description: "",
    columns: ["id", "created_at", "status", "amount", "currency"],
    start_at: moment().startOf("day").subtract(1, "weeks"),
    end_at: moment().endOf("day"),
    email: "",
    type: "payout",
  },
}
const defaultPayoutDetailsState: $ExportState = {
  fetching: false,
  fetched: false,
  error: null,
  exports: [],
  has_more: true,
  form: {
    format: "csv",
    description: "",
    columns: ["id", "payout_id", "created_at", "transaction_id", "amount", "fees"],
    start_at: moment().subtract(1, "weeks").hours(0).minutes(0).seconds(0),
    end_at: moment().hours(23).minutes(23).seconds(23),
    email: "",
    type: "payout-item",
  },
}
export default function (
  state: $ExportState = defaultTransactionsState,
  action:
    | $UpdateExportForm
    | $ResetTransactionsExportsForm
    | $ResetPayoutsExportsForm
    | $ResetPayoutDetailsExportsForm
    | $UpdateExportForm
    | $RequestExportFetchAction,
): $ExportState {
  switch (action.type) {
    case UPDATE_EXPORT_FORM: {
      // FIXME: probably a typo. actioin.payload.form does not exist. action.payload is the form
      // @ts-ignore
      return { ...state, form: { ...state.form, ...action.payload.form } }
    }

    case RESET_TRANSACTIONS_EXPORTS_FORM: {
      return { ...state, ...defaultTransactionsState }
    }

    case RESET_PAYOUTS_EXPORTS_FORM: {
      return { ...state, ...defaultPayoutsState }
    }

    case RESET_PAYOUT_DETAILS_EXPORTS_FORM: {
      return {
        ...state,
        ...defaultPayoutDetailsState,
        form: { ...defaultPayoutDetailsState.form, payout_id: action.payload.payout },
      }
    }

    case REQUEST_EXPORTS_FETCH: {
      return { ...state, fetching: true, fetched: false, error: null, exports: [] }
    }

    case typeFulfilled(REQUEST_EXPORTS_FETCH): {
      return { ...state, fetching: false, fetched: true, ...(action as any).payload }
    }

    case typeFailed(REQUEST_EXPORTS_FETCH): {
      return { ...state, fetching: false, fetched: true, error: (action as any).payload }
    }
  }

  return state
}
