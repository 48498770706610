import { FETCH_EVENTS_TRANSACTION } from "^/Actions/EventsTransactionActions"
import type { TransactionModel } from "../../Types/Transaction"
import type { $Action, $State } from "../../util/Types"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import type { $Webhook } from "^/features/Webhooks/consts"
type Data = {
  name: string
  sandbox: boolean
  transaction: TransactionModel
}
export type $Event = {
  data: Data
  fired_at: string
  id: string
  name: string
  project_id: string
  sandbox: boolean
  webhook: Array<$Webhook>
}
export type $EventsTransactionState = {
  events: Array<$Event>
} & $State
const defaultState: $EventsTransactionState = {
  fetched: false,
  fetching: false,
  events: [],
}
export default function (state: $EventsTransactionState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_EVENTS_TRANSACTION): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_EVENTS_TRANSACTION): {
      return { ...state, fetching: false, fetched: true, events: [...action.payload] }
    }

    case typeFailed(FETCH_EVENTS_TRANSACTION): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
