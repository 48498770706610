import type { Action } from "../../Types/Action"
import type { $State } from "^/util/Types"
import { FETCH_CUSTOMERS } from "^/Actions/CustomerActions"
import type { Customer } from "^/Types/Customer"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type CustomersState = {
  customers?: Array<Customer>
} & $State
const defaultState = {
  fetched: false,
  fetching: false,
}
export default function (state: CustomersState = defaultState, action: Action) {
  switch (action.type) {
    case typePending(FETCH_CUSTOMERS): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_CUSTOMERS): {
      if (action.payload && !action.payload.success) {
        return { ...state, fetching: false, fetched: true, customers: [], total_count: [] }
      }

      return { ...state, ...action.payload, fetching: false, fetched: true }
    }

    case typeFailed(FETCH_CUSTOMERS): {
      return { ...state, fetched: false, fetching: false, error: action.payload }
    }
  }

  return state
}
