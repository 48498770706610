import { all, call, put, select, takeLatest } from "redux-saga/effects"
import uniqid from "uniqid"
import type { $RequestFetchPermissionGroupsAction } from "./actions"
import * as Actions from "./actions"
import {
  REQUEST_FETCH_PERMISSION_GROUPS,
  SAVE_PERMISSION_GROUPS,
  SAVE_TEAM_SETTINGS,
} from "./actions"
import type { $Collaborator, $PermissionFeature, $PermissionGroup } from "./consts"
import { PREPARE_PERMISSIONS } from "./consts"
import type { $PermissionsState } from "./reducer"
import type { $RequestFetchCollaboratorsAction } from "./CollaboratorsActions"
import { REQUEST_FETCH_COLLABORATORS, requestFetchCollaborators } from "./CollaboratorsActions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { addNotification, APIcallPromise } from "../../util/ProcessOut"

function* preparePermissions(action: any): Generator<any, any, any> {
  try {
    const { collaboratorsAfter, collaboratorsId } = action.payload
    yield put({
      type: typePending(PREPARE_PERMISSIONS),
    })
    yield put.resolve(requestFetchCollaborators(collaboratorsAfter, collaboratorsId))
    yield put.resolve(Actions.requestFetchPermissionGroups())
    // Retrieve permission features
    yield put({
      type: typeFulfilled(PREPARE_PERMISSIONS),
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREPARE_PERMISSIONS),
      payload: error,
    })
    datadogRum.addError(error)
  }
}

function* fetchCollaborators(action: $RequestFetchCollaboratorsAction): Generator<any, any, any> {
  try {
    const { after, id = "" } = action.payload
    const bound = after ? "start_after" : "end_before"
    const collaboratorsResult = yield call(
      APIcallPromise,
      `/collaborators?${bound}=${encodeURIComponent(id)}&limit=10&order=desc`,
      "GET",
    )
    const collaborators: Array<$Collaborator> = collaboratorsResult.data.collaborators.map(c => ({
      ...c,
      id: c.user.email,
    }))
    yield put({
      type: typeFulfilled(REQUEST_FETCH_COLLABORATORS),
      payload: {
        collaborators,
        has_more: collaboratorsResult.data.has_more,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_FETCH_COLLABORATORS),
      payload: error,
    })
  }
}

function* fetchPermissionGroups(
  action: $RequestFetchPermissionGroupsAction,
): Generator<any, any, any> {
  try {
    const permissionGroupDefaults = yield call(APIcallPromise, "/permission-groups-defaults", "GET")
    const permissionFeatures: Array<$PermissionFeature> = Object.keys(
      permissionGroupDefaults.data.permission_groups,
    ).map(feature => ({
      id: feature,
      ...permissionGroupDefaults.data.permission_groups[feature],
    }))
    const permissionGroupsResult = yield call(APIcallPromise, "/permission-groups", "GET")
    const permissionGroups: Array<$PermissionGroup> =
      permissionGroupsResult.data.permission_groups.map(group => ({ ...group, id: uniqid() }))
    yield put({
      type: typeFulfilled(REQUEST_FETCH_PERMISSION_GROUPS),
      payload: {
        permissionGroups,
        permissionFeatures,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_FETCH_COLLABORATORS),
      payload: error,
    })
  }
}

function* saveTeamSettings(): Generator<any, any, any> {
  try {
    const permissions: $PermissionsState = yield select<any>(store => store.permissions)
    const fetchedCollaborators = permissions.collaborators.fetchedState.collaborators
    const promises = []

    for (const collab of permissions.collaborators.collaborators) {
      // Check if the collaborator already exists
      if (
        fetchedCollaborators &&
        fetchedCollaborators.find(
          g =>
            g.id === collab.id &&
            (g.role !== collab.role || g.permission_group_name !== collab.permission_group_name),
        )
      ) {
        // Collaborator existed we update it
        promises.push(
          call(
            APIcallPromise,
            `/collaborators/${collab.user.email}`,
            "PUT",
            JSON.stringify({
              role: collab.role,
              permission_group_name: collab.permission_group_name,
            }),
          ),
        )
      } else if (fetchedCollaborators && !fetchedCollaborators.find(g => g.id === collab.id)) {
        // Collaborator doesn't exist we create it
        promises.push(
          call(
            APIcallPromise,
            `/collaborators`,
            "POST",
            JSON.stringify({
              role: collab.role,
              permission_group_name: collab.permission_group_name,
              user_email: collab.user.email,
            }),
          ),
        )
      }
    }

    // Check if some collaborators were deleted
    if (fetchedCollaborators) {
      for (const collab of fetchedCollaborators) {
        if (!permissions.collaborators.collaborators.find(g => g.id === collab.id)) {
          // Group was deleted
          promises.push(call(APIcallPromise, `/collaborators/${collab.user.email}`, "DELETE"))
        }
      }
    }

    yield all(promises)
    yield put({
      type: typeFulfilled(SAVE_TEAM_SETTINGS),
    })
    addNotification("Team settings saved.", "success")
    yield put(Actions.preparePermissions())
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(SAVE_TEAM_SETTINGS),
      payload: {
        error,
      },
    })
    addNotification("Could not save team settings.")
  }
}

function* savePermissionGroups(): Generator<any, any, any> {
  try {
    const permissions: $PermissionsState = yield select<any>(store => store.permissions)
    const fetchedPermissionGroups = permissions.permissionGroups.fetchedState.permissionGroups
    const promises = []

    // First we save permission groups
    for (const group of permissions.permissionGroups.permissionGroups) {
      // Check if the group already exists
      if (fetchedPermissionGroups && fetchedPermissionGroups.find(g => g.name === group.name)) {
        // Group existed we update it
        promises.push(
          call(
            APIcallPromise,
            `/permission-groups/${group.name}`,
            "PUT",
            JSON.stringify({
              name: group.newName,
              permissions: group.permissions,
            }),
          ),
        )
      } else {
        // Group doesn't exist we create it
        promises.push(
          call(
            APIcallPromise,
            `/permission-groups`,
            "POST",
            JSON.stringify({
              name: group.newName || group.name,
              permissions: group.permissions,
            }),
          ),
        )
      }
    }

    // Check if some groups were deleted
    if (fetchedPermissionGroups) {
      for (const group of fetchedPermissionGroups) {
        if (!permissions.permissionGroups.permissionGroups.find(g => g.name === group.name)) {
          // Group was deleted
          promises.push(call(APIcallPromise, `/permission-groups/${group.name}`, "DELETE"))
        }
      }
    }

    yield all(promises)
    yield put({
      type: typeFulfilled(SAVE_PERMISSION_GROUPS),
    })
    addNotification("Permission groups saved successfully", "success")
    yield put(Actions.preparePermissions())
  } catch (error) {
    yield put({
      type: typeFailed(SAVE_PERMISSION_GROUPS),
    })
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(PREPARE_PERMISSIONS, preparePermissions)
  yield takeLatest(REQUEST_FETCH_COLLABORATORS, fetchCollaborators)
  yield takeLatest(REQUEST_FETCH_PERMISSION_GROUPS, fetchPermissionGroups)
  yield takeLatest(SAVE_TEAM_SETTINGS, saveTeamSettings)
  yield takeLatest(SAVE_PERMISSION_GROUPS, savePermissionGroups)
}
