import type { $Action } from "../../../../util/Types"
export const UPDATE_BOARD_EDITION_NAME = "UPDATE_BOARD_EDITION_NAME"
export function updateBoardName(name: string): $Action {
  return {
    type: UPDATE_BOARD_EDITION_NAME,
    payload: {
      name,
    },
  }
}
