import React from "react"
import Select from "react-select"
import { groupBy } from "../../analytics/DataExplorer/Utils"
import type { $BuilderMetric } from "../../analytics/DataExplorer/ChartBuilder/consts"
import {
  ALERTING_BUILDER_METRICS,
  SelectStyle,
} from "../../analytics/DataExplorer/ChartBuilder/consts"
type Props = {
  value: $BuilderMetric
  onChange: (arg0: $BuilderMetric) => void
}

class MetricSelector extends React.Component<Props> {
  render() {
    const { value } = this.props
    const groupByGroupName = groupBy("group")
    const groupedByGroupName = groupByGroupName(ALERTING_BUILDER_METRICS)
    const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
      label: group,
      options: groupedByGroupName[group].map(metric => ({
        label: metric.name,
        value: metric.name,
        metric,
      })),
    }))
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label round-left">Watch</div>
        <div className="labeled-field">
          <Select
            className="react-select"
            options={OPTIONS}
            formatGroupLabel={formatGroupLabel}
            styles={SelectStyle}
            value={{
              label: value.name,
              value: value.name,
            }}
            onChange={newValue => {
              this.props.onChange(newValue.metric)
            }}
          />
        </div>
      </div>
    )
  }
}

export default MetricSelector
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)
