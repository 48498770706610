import React from "react"
import { FormattedNumber } from "react-intl"
import type { $CountryMultiDimPoint } from "../Consts"
export type Props = {
  point: $CountryMultiDimPoint
  currency: string
}

class AmountMultiDimTooltip extends React.Component<Props> {
  render() {
    const { point, currency } = this.props
    const totalCount = point.datapoints.reduce((count, point) => count + point._count, 0)
    return (
      <ul>
        {point.datapoints
          .sort((a, b) => b._count - a._count)
          .map(point => (
            <li className="small-margin-bottom" key={point.key}>
              <h6
                style={{
                  marginBottom: 0,
                }}
              >
                {point.key}
              </h6>
              <FormattedNumber
                value={point.value}
                style="currency"
                currency={currency}
                maximumFractionDigits={2}
                minimumFractionDigits={2}
              />{" "}
              <div
                className="greyed"
                style={{
                  display: "inline-block",
                }}
              >
                (
                <FormattedNumber value={point._count / totalCount} style="percent" />)
              </div>
            </li>
          ))}
      </ul>
    )
  }
}

export default AmountMultiDimTooltip
