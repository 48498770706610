import clsx from "clsx"
import React, { useContext } from "react"
import { $DynamicFilters, RULES_FILTERS } from "^/features/RoutingRules/consts"
import { RoutingContext } from "../RoutingContext"
import Card from "./Card"
import { Pill } from "./Pill"

export type Props = {
  filters: $DynamicFilters[] | undefined
  onFilterSelection: (filter: $DynamicFilters, name: string) => void
}

/**
 * The container for dynamic 3ds fields: exemption and challenge indicator and finally gateway.
 */
export const Dynamic3DSParams = ({ filters, onFilterSelection }: Props) => {
  const [state] = useContext(RoutingContext)

  const elements = filters?.map(filter => {
    const name = `Set ${(RULES_FILTERS[filter.path]?.label || filter.path)?.toLocaleLowerCase()}`
    return (
      <Card
        cardId={filter.id}
        className={clsx("dynamic3ds")}
        selected={state.selectedCardId === filter.id}
        key={filter.id}
        name={name}
        onClick={() => onFilterSelection(filter, name)}
        tooltip={`${name} ${filter.value}`}
      >
        <Pill key={filter.id}>{filter.value}</Pill>
      </Card>
    )
  })
  return <>{elements}</>
}
