import React from "react"
import { Link } from "react-router"
import { track } from "../../vNext/tracking"

type Props = {
  route: string
  routeRegex: string | null | undefined
  name: string
  location: any
  to: string
  children: any
  richLabel?: JSX.Element
}
export default class SubButton extends React.Component {
  props: Props

  getInitialProps() {
    return {
      to: "",
      name: "",
      img: "",
    }
  }

  constructor() {
    super()
  }

  render() {
    const { location, children } = this.props
    const regex = this.props.routeRegex
      ? new RegExp(this.props.routeRegex)
      : new RegExp(`/projects/.+/${this.props.route}$`, "gi")
    let current = regex.test(location.pathname) ? "active" : ""

    if (this.props.route === "analytics") {
      const regex2 = new RegExp("/projects/.+/$", "gi")
      if (regex2.test(location.pathname)) current = "active"
      else current = ""
    }

    return (
      <li className={current}>
        <Link
          // data-auto-tracking="true"
          // data-tracking-label={trackingLabel}
          to={this.props.to}
          className="subButton"
          title={this.props.name}
          onClick={() => {
            track("Navigation", "Click", "Menu", "Sub-Item", { value: this.props.name })
          }}
        >
          <span>{this.props.richLabel || this.props.name}</span>
        </Link>
        {this.props.children}
      </li>
    )
  }
}
