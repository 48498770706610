import React from "react"
type Props = {
  children: any
  name: string
}

class FiltersGroup extends React.Component<Props> {
  render() {
    return (
      <div className="row filters-group">
        <div className="large-12 columns">
          <div className="row group-name collapse">
            <div className="large-12 columns">{this.props.name}</div>
          </div>
          <div className="row">
            <div className="large-12 columns">{this.props.children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default FiltersGroup
