import React from "react"
import TelescopeTitle from "^/features/TelescopeV2/components/TelescopeTitle"

type Props = {
  value: {
    name: string
    tooltipContent: string
    id: string
  }
}
export default function BarChartTooltip({ value }: Props) {
  return (
    <TelescopeTitle title={value.name} tooltip={{ content: value.tooltipContent, id: value.id }} />
  )
}
