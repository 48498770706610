import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher, $Project } from "../../../util/Types"
import * as Actions from "./actions"
import type { $AlertBuilderState } from "./reducer"
type Props = {
  currentProject: {
    project: $Project
  }
  alertBuilder: $AlertBuilderState
} & $Dispatcher

class Header extends React.Component<Props> {
  preview = () => {
    this.props.dispatch(Actions.requestAlertBuilderPreview())
  }

  render() {
    const { alertBuilder, currentProject } = this.props
    return (
      <div className="sticky-actions">
        <div
          className="row"
          style={{
            padding: "0 1em",
          }}
        >
          <div className="large-9 columns">
            <input
              type="text"
              className="name-input"
              placeholder="Alert name..."
              value={alertBuilder.name}
              onChange={event => {
                this.props.dispatch(Actions.updateAlertBuilderForm("name", event.target.value))
              }}
            />
          </div>
          <div className="large-3 columns text-right">
            {alertBuilder.fetched && !alertBuilder.error && (
              <div
                className="small round button main"
                style={{
                  marginRight: "1em",
                }}
                onClick={() => {
                  this.props.dispatch(Actions.requestAlertSave())
                }}
              >
                Create alert
              </div>
            )}
            <div
              className={`small round ${
                alertBuilder.fetched && !alertBuilder.error ? "border" : "main"
              } button preview-button`}
              onClick={this.preview}
            >
              Preview
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  alertBuilder: store.alertBuilder,
  currentProject: store.currentProject,
}))(withRouter(Header))
