import React from "react"
import Select from "react-select"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
import type { $OperandType } from "../analytics/ChartPreviewer/Aggregator/consts"
type Props = {
  value: $Filter | null | undefined
  onChange: (arg0: $Filter) => void
}

class AmountFilter extends React.Component<Props> {
  render() {
    const { value, onChange } = this.props
    return (
      <div className="row collapse">
        <div className="medium-4 columns">
          <OperandSelector
            onChange={(newValue: $OperandType) => {
              const filter = { ...value, operand: newValue }
              onChange(filter)
            }}
            value={value.operand}
          />
        </div>
        <div className="medium-8 columns">
          <input
            type="number"
            value={value.value}
            onChange={event => {
              const amount = event.target.value
              const newFilter = { ...value, value: [amount] }
              onChange(newFilter)
            }}
            placeholder="10"
          />
        </div>
      </div>
    )
  }
}

export default AmountFilter
type OperandProps = {
  onChange: (arg0: $OperandType) => void
  value: $OperandType
}

class OperandSelector extends React.Component<OperandProps> {
  render() {
    const { value, onChange } = this.props
    const options = [
      {
        label: "greater",
        value: ">",
      },
      {
        label: "less",
        value: "<",
      },
      {
        label: "equal",
        value: "==",
      },
      {
        label: "not",
        value: "!=",
      },
    ]
    const corValue = options.find(op => op.value === value)
    return (
      <div
        className="labeled-field"
        style={{
          width: "100%",
        }}
      >
        <Select
          className="react-select"
          options={options}
          value={corValue}
          onChange={newValue => {
            onChange(newValue.value)
          }}
        />
      </div>
    )
  }
}
