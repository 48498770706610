/**
 * Created by jeremylejoux on 07/06/17.
 */
import * as ApplePayActions from "../../../Actions/ApplePayActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
import { Action } from "^/Types/Action"
type State = {
  fetching: boolean
  fetched: boolean
  keys: any
}
export default function (
  state: State = {
    fetching: false,
    fetched: false,
    keys: null,
  },
  action: Action,
): State {
  switch (action.type) {
    case typePending(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
      return { ...state, fetching: true, fetched: false, keys: null }
    }

    case typeFulfilled(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
      return { ...state, fetching: false, fetched: true, keys: action.payload.keys }
    }

    case typeFulfilled(ApplePayActions.RESET_APPLE_PAY): {
      return { ...state, fetching: false, fetched: false, keys: null }
    }
  }

  return state
}
