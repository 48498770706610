import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import uniqid from "uniqid"
import type { $Dispatcher } from "../../../util/Types"
import * as Actions from "../actions"
import type { $DynamicFilters, $ProcessorConfiguration, $RoutingRule } from "../consts"
import { RULES_FILTERS } from "../consts"
import DynamicRuleParam from "./DynamicRuleParam"
import type { $Filter } from "./Filter/consts"
import Filter from "./Filter/Filter"
type $GatewayChange = {
  label: string
  value: string
}
type Props = {
  rule: $RoutingRule
  processorsConfigurations: $ProcessorConfiguration
  ruleWontMatch: Boolean
} & $Dispatcher
type State = {
  filters: Array<$DynamicFilters>
}
export class DynamicRule extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filters: [],
    }
  }

  componentDidMount() {
    const { rule } = this.props

    if (rule.dynamic_3ds_params) {
      this.setState({
        filters: rule.dynamic_3ds_params,
      })
    }
  }

  handleGatewayChange = (value: $GatewayChange) => {
    const { dispatch, rule } = this.props
    const newValue = []
    const id = rule.id
    const gateway = value.value
    newValue.push({
      id,
      gateway,
    })
    const newRule = { ...rule, gateways: newValue }
    dispatch(Actions.updateRule(newRule.id, newRule))
  }
  handleDynamicChange = (event: Event, value: $DynamicFilters) => {
    const { dispatch, rule } = this.props
    const { filters } = this.state
    const index = filters.findIndex(item => item.id === value.id)

    if (
      event.value.includes("sca_exemption_reason") ||
      event.value.includes("challenge_indicator")
    ) {
      filters[index].path = event.value
      filters[index].value = ""
      this.setState({
        filters,
      })
    } else {
      filters[index].value = event.value
      this.setState({
        filters,
      })
    }

    const newRule = { ...rule }
    newRule.dynamic_3ds_params = filters
    dispatch(Actions.updateRule(newRule.id, newRule))
  }
  filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
    const { rule, dispatch } = this.props
    const newRule = { ...rule }
    newRule.conditions[conditionIndex].filters = newValue
    dispatch(Actions.updateRule(newRule.id, newRule))
  }
  addFilter = (event: Event) => {
    event.preventDefault()
    const { filters } = this.state
    const { rule, dispatch } = this.props
    filters.push({
      id: uniqid(),
      path: "",
      value: "",
    })
    this.setState({
      filters,
    })
    const newRule = { ...rule }
    newRule.dynamic_3ds_params = filters
    dispatch(Actions.updateRule(newRule.id, newRule))
  }
  deleteFilter = (filterId: string) => {
    const { filters } = this.state
    const { rule, dispatch } = this.props
    const newFilters = filters.filter(item => item.id !== filterId)
    this.setState({
      filters: newFilters,
    })
    const newRule = { ...rule }
    newRule.dynamic_3ds_params = newFilters
    dispatch(Actions.updateRule(newRule.id, newRule))
  }

  render() {
    const { rule, ruleWontMatch, processorsConfigurations } = this.props
    const { filters } = this.state
    const options = []
    processorsConfigurations.configurations.forEach(el => {
      const value = el.id
      const label = el.name ?? el.gateway.display_name
      options.push({
        value,
        label,
      })
    })
    let gatewaySelected

    if (rule.gateways.length > 0) {
      gatewaySelected = options.filter(el => rule.gateways[0].gateway === el.value)[0]
    }

    const emptyAddButton = filters.length === 0 && (
      <div className="greyed">
        Add your dynamic 3DS parameter <a onClick={this.addFilter}>&#43; adding a filter</a>
      </div>
    )
    return (
      <div className="row" data-tracking-location="Dynamic Rules">
        {ruleWontMatch && (
          <div className="warning-wont-match">
            A previous rule has no active filter and applies to every transaction: this rule will
            never be executed.
          </div>
        )}

        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-8 columns">Apply Dynamic 3DS values on transactions</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              {rule.conditions.map((condition, index) => (
                <div className="row small-margin-bottom" key={`${rule.id}-${index}`}>
                  <div className="medium-12 columns">
                    <Filter
                      onChange={this.filterChanged.bind(null, index)}
                      options={Object.keys(RULES_FILTERS).map(filter => ({
                        label: RULES_FILTERS[filter].label,
                        value: filter,
                        desc: RULES_FILTERS[filter].desc,
                        isMulti: RULES_FILTERS[filter].isMulti,
                      }))}
                      defaultFilters={condition.filters}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row columns small-margin-bottom">
            <div
              className="medium-1 small-margin-bottom"
              style={{
                paddingTop: ".5em",
              }}
            >
              Set
            </div>
            <div className="row">
              <div className="large-12 columns">
                {emptyAddButton}
                {filters.map((filter, index, array) => (
                  <DynamicRuleParam
                    filterState={filters}
                    filter={filter}
                    index={index}
                    array={array}
                    handleDynamicChange={this.handleDynamicChange}
                    deleteFilter={this.deleteFilter}
                    addFilter={this.addFilter}
                    key={filter.id}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="medium-1 columns"
              style={{
                paddingTop: ".5em",
              }}
            >
              On
            </div>
            <div className="medium-3 end columns">
              <Select
                className="react-select"
                options={options}
                value={gatewaySelected}
                isClearable={false}
                onChange={this.handleGatewayChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  processorsConfigurations: store.processorsConfigurations,
}))(DynamicRule)
