import { FETCH_COUNTRIES } from "^/Actions/CountryActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetched: false,
    fetching: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_COUNTRIES): {
      state = { ...state, fetched: false, fetching: true }
      break
    }

    case typeFulfilled(FETCH_COUNTRIES): {
      state = { ...state, ...action.payload, fetched: true, fetching: false }
      break
    }
  }

  return state
}
