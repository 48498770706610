import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import type { $BoardCreatorState } from "./reducer"
import type { $BoardPresetSaveRequest, $RequestBoardPreviewAction } from "./actions"
import * as Actions from "./actions"
import { REQUEST_BOARD_PRESET_SAVE, REQUEST_BOARD_PREVIEW, SELECT_BOARD_PRESET } from "./actions"
import * as ChartActions from "../ChartPreviewer/actions"
import type { $Chart, $DataChart, $FetchParams } from "../Boards/consts"
import * as BoardsActions from "../Boards/actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"

function* previewBoard(action: $RequestBoardPreviewAction): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_BOARD_PREVIEW),
    })
    const { payload } = action
    if (!payload) throw new Error("Payload is null. Should contain a board preset")
    const { board } = payload
    const params: $FetchParams = yield select<any>(store => store.analytics.params)
    const chartsPromises = board.charts.map(chart =>
      put.resolve(
        ChartActions.fetchPreviewedChart(
          { ...chart, preview: true } as unknown as $Chart,
          "board_default-sales",
          params,
        ),
      ),
    )
    const chartsResults = yield all(chartsPromises)
    // Will contain all the fetched charts
    const fetchedCharts: Array<{
      chart: $Chart
      data: $DataChart
    }> = chartsResults.map(result => result.value.data)
    yield put({
      type: typeFulfilled(REQUEST_BOARD_PREVIEW),
      payload: {
        previewedCharts: fetchedCharts,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_BOARD_PREVIEW),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

function* saveBoardPreset(action: $BoardPresetSaveRequest): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_BOARD_PRESET_SAVE),
    })
    const currentProject = yield select<any>(store => store.currentProject)
    const fetchParams: $FetchParams = yield select<any>(store => store.analytics.params)
    const { preset } = action.payload
    const boardResponse = yield call(
      APIcallPromise,
      "/boards",
      "POST",
      JSON.stringify({
        name: preset.name,
        description: "",
      }),
    )
    const { board } = boardResponse.data
    const promises = preset.charts.map(chart =>
      call(APIcallPromise, `/boards/${board.id}/charts`, "POST", JSON.stringify(chart)),
    )
    yield all(promises)
    // We update the store with the newly created board
    yield put.resolve(BoardsActions.requestBoardsFetch(board.id, true))
    yield put({
      type: typeFulfilled(REQUEST_BOARD_PRESET_SAVE),
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_BOARD_PRESET_SAVE),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

function* selectBoardPresetWatcher(): Generator<any, any, any> {
  try {
    const boardCreator: $BoardCreatorState = yield select<any>(store => store.boardCreator)
    if (!boardCreator.selectedPreset) throw new Error("No selected preset to preview")
    yield put(Actions.requestBoardPreview(boardCreator.selectedPreset))
  } catch (error) {
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_BOARD_PREVIEW, previewBoard)
  yield takeEvery(REQUEST_BOARD_PRESET_SAVE, saveBoardPreset)
  yield takeLatest(SELECT_BOARD_PRESET, selectBoardPresetWatcher)
}
