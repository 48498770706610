// @ts-expect-error
import "./permissions.scss"
import React from "react"
import { NOT_ALLOWED } from "./consts"

class NotPermitted extends React.Component {
  render() {
    return (
      <div className="row permissions">
        <div className="medium-12 columns">{NOT_ALLOWED}</div>
      </div>
    )
  }
}

export default NotPermitted
