import React from "react"
import { connect } from "react-redux"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import { track } from "^/vNext/tracking"
import ModalFooter from "../../../components/ModalFooter"
import type { $Dispatcher } from "../../../util/Types"
import * as BoardActions from "./actions"
import SimpleSavingForm from "./SimpleSavingForm"
type Props = {
  boardId: string | null | undefined
} & $Dispatcher

type State = {
  isButtonDisabled: boolean
}

export const ON_NEW_BOARD = "ON_NEW_BOARD"
export class SavingModal extends React.Component<Props, State> {
  // quick fix for https://checkout.atlassian.net/browse/POF-638
  // prevent the user to click multiple times only if boardId is present
  constructor(props) {
    super(props)
    this.state = { isButtonDisabled: false }
  }

  _boardForm: any
  openModal = () => {
    track("Analytics", "Click", "Header", "Save")
    const saveChart = (boardId: string) => {
      this.props.dispatch(BoardActions.saveChartOnBoard(boardId))
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }

    if (this.props.boardId) {
      // disable the button after clicking
      // it won't affect the behaviour of the anaytics page because when clicking you are redirected to the good board
      this.setState({ isButtonDisabled: true })
      saveChart(this.props.boardId)
      return
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        large: false,
        header: "Save chart",
        content: (
          <SimpleSavingForm
            ref={el => {
              this._boardForm = el
            }}
            submit={() => {
              saveChart(ON_NEW_BOARD)
            }}
          />
        ),
        footer: (
          <ModalFooter
            submitTitle="Save"
            submitCallback={() => {
              saveChart(this._boardForm.getWrappedInstance().retrieveInfos().boardId)
            }}
          />
        ),
      },
    })
  }

  render() {
    return (
      <button
        className="small border round button save-button fade-in-button"
        onClick={this.openModal}
        style={{
          marginRight: "1em",
        }}
        disabled={this.state.isButtonDisabled}
      >
        Save
      </button>
    )
  }
}
export default connect(store => ({
  project: store.currentProject,
}))(SavingModal)
