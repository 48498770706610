import uniqid from "uniqid"
import { call, put, select, take, takeLatest } from "redux-saga/effects"
import type { $ApplyPresetAction, $ChartPreviewAction } from "./actions"
import {
  APPLY_DATA_EXPLORER_PRESET,
  APPLY_PRESET_CHART_BUILDER,
  APPLY_PRESET_DATA_EXPLORER,
  PREVIEW_CHART,
  REQUEST_CHART_FETCH,
  requestChartFetch,
  SAVE_CHART_ON_BOARD,
  SET_METRIC_TAB,
  UPDATE_DATA_EXPLORER_FORMULA,
  UPDATE_DATA_EXPLORER_TYPE,
  UPDATE_DATA_EXPLORER_UNIT,
  UPDATE_METRIC_FILTERS,
  UPDATE_METRIC_LOCAL_CURRENCY,
  UPDATE_METRIC_PATH,
  UPDATE_METRIC_TYPE,
  updateFormula,
} from "./actions"
import type { $SelectTypeAction, $UpdateDimensionsAction } from "./ChartBuilder/actions"
import * as ChartBuilderActions from "./ChartBuilder/actions"
import {
  REQUEST_UPDATE_DIMENSIONS,
  SELECT_BUILDER_METRIC,
  SELECT_TYPE,
  SET_DISPLAY_LOCAL_CURRENCY,
  SET_DISPLAY_NET_METRICS,
  UPDATE_DIMENSIONS,
  UPDATE_FILTERS,
} from "./ChartBuilder/actions"
import * as ProcessOut from "../../../util/ProcessOut"
import * as ChartPreviewerActions from "../ChartPreviewer/actions"
import {
  getChart,
  replaceForCountries,
  replaceForCurrencies,
  replaceForGwayKeys,
} from "../Boards/charts/utils"
import type { $DataExplorerState } from "./reducer"
import { formatFormula } from "../ChartPreviewer/BuilderEffects/actions"
import { $Chart, MAP_CHART, PIE_CHART, SINGLE_VALUE } from "../Boards/consts"
import { UPDATE_CHART_BUILDER_DETAILS } from "./consts"
import { generateDefaultDimension } from "./ChartBuilder/DimensionSelection/consts"
import type { $Action } from "^/util/Types"
import { ON_NEW_BOARD } from "./SavingModal"
import { requestBoardsFetch } from "../Boards/actions"
import { computeFilterString, computeMergedFormula, mergeFilters } from "./Utils"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import type { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"

export function* fetchChartPreview(action: $ChartPreviewAction): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(PREVIEW_CHART),
    })
    const { name, formula, unit, type, params } = action.payload
    const chart: $Chart = {
      name,
      description: "",
      type,
      settings: {
        formula,
        // FIXME: plotted_field is optional but not null. Should be removed.
        // @ts-ignore
        plotted_field: null,
      },
      size: 12,
      unit,
      preview: true,
    }

    const chartResult = yield put.resolve(
      ChartPreviewerActions.fetchPreviewedChart(
        chart,
        "board_default-sales",
        action.payload.params,
      ),
    )
    const chartData = chartResult.value.data
    // we fetch the gateway ids and names list from the store.
    let gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)

    // if we don't have them we wait for it
    while (!gway_configurations_names.fetched) {
      yield take()
      gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)
    }

    // we update the charts keys with gateway names
    chartData.data = replaceForGwayKeys(
      chartData.data,
      gway_configurations_names.gateway_configuration_names,
    )
    // do the same for country codes
    chartData.data = replaceForCountries(chartData.data)
    // same for currencies
    chartData.data = replaceForCurrencies(chartData.data)

    const chartFetched = getChart(chartData, params)

    const payload = {
      chart: {
        ...chartData.chart,
        fetched: true,
        data: chartFetched.data,
        is_comparison: chartFetched.is_comparison,
        timeCompareAndDimensions: chartFetched.timeCompareAndDimensions,
      },
    }

    yield put({
      type: chartData.success ? typeFulfilled(PREVIEW_CHART) : typeFailed(PREVIEW_CHART),
      payload,
    })
  } catch (error) {
    yield put({
      type: typeFailed(PREVIEW_CHART),
      payload: {
        error,
      },
    })
  }
}
export function* applyPreset(action: $ApplyPresetAction): Generator<any, any, any> {
  const { preset } = action.payload
  yield put.resolve({
    type: APPLY_PRESET_DATA_EXPLORER,
    payload: preset,
  })
  yield put.resolve({
    type: APPLY_PRESET_CHART_BUILDER,
    payload: preset.chartBuilder,
  })
}
export function* rebuildFormula(): Generator<any, any, any> {
  try {
    const chartBuilder: $ChartBuilderState = yield select<any>(store => store.chartBuilder)
    const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
    // We make sure we are on the chart builder tab
    if (dataExplorer.selectedTab === "editor") return
    let formula = ""
    const { dimensions } = chartBuilder
    // Deep copy metrics
    const duplicatedMetrics = chartBuilder.selectedMetric.metrics.map(m => ({ ...m }))

    for (const metric of duplicatedMetrics) {
      switch (chartBuilder.displayNetMetrics) {
        case "raw": {
          break
        }

        case "net_router": {
          if (metric.filters.find(f => f.path === "is_last_payment_attempt")) break
          metric.filters = metric.filters.slice(0)
          metric.filters.push({
            id: uniqid(),
            path: "is_last_payment_attempt",
            operand: "==",
            value: [true],
          })
          break
        }

        case "net": {
          if (metric.filters.find(f => f.path === "is_user_retry")) break
          metric.filters = metric.filters.slice(0)
          metric.filters.push({
            id: uniqid(),
            path: "is_user_retry",
            operand: "==",
            value: [false],
          })
          if (metric.filters.find(f => f.path === "is_last_payment_attempt")) break
          metric.filters.push({
            id: uniqid(),
            path: "is_last_payment_attempt",
            operand: "==",
            value: [true],
          })
          break
        }
      }
    }

    // we need to merge metrics filters with global filters
    for (const metric of duplicatedMetrics) {
      metric.filters = mergeFilters(metric.filters, chartBuilder.filters)
    }

    // Special treatment for conversion rate.
    // https://checkout.atlassian.net/jira/software/c/projects/POFE/boards/821?modal=detail&selectedIssue=POFE-353
    if (chartBuilder.selectedMetric.name === "Conversion rate") {
      const authorizationRetryFilterBIndex = duplicatedMetrics[1].filters.findIndex(
        f => f.path === "authorization_retry_attempt",
      )
      // If authorization_retry_attempt filter is applied and it is a conversion rate metric, we need to
      // remove authorization_retry_attempt and update authorization_retry_attempt to 0 in B the divisor.
      const authorizationRetryFilterB = duplicatedMetrics[1].filters[authorizationRetryFilterBIndex]
      if (authorizationRetryFilterB) {
        // Update authorization_retry_attempt to 0 in B, the divisor
        duplicatedMetrics[1].filters[authorizationRetryFilterBIndex] = {
          ...authorizationRetryFilterB,
          value: [0],
        }

        // delete is_last_payment_attempt filter if any
        for (const metric of duplicatedMetrics) {
          const idx = metric.filters.findIndex(f => f.path === "is_last_payment_attempt")
          if (idx != -1) {
            metric.filters.splice(idx, 1)
          }
        }
      }
    }

    const dataFormula = computeMergedFormula(
      chartBuilder,
      chartBuilder.selectedMetric.generalFormula,
      "",
      duplicatedMetrics,
    )

    for (let i = 0; i < dimensions.length; i++) {
      const strategy = dimensions[i].top
        ? `${dimensions[i].strategy ? `strategy: ${dimensions[i].strategy}` : ""};`
        : ""
      const top = dimensions[i].top ? `top: ${dimensions[i].top};` : ""

      let filterString = computeFilterString(chartBuilder.filters).replace(";", "")

      // Special handling for conversion rate filter string
      // If authorization_retry_attempt is presented we need to
      // use (authorization_retry_attempt == {value} OR authorization_retry_attempt == 0)
      // https://checkout.atlassian.net/jira/software/c/projects/POFE/boards/821?modal=detail&selectedIssue=POFE-353
      if (chartBuilder.selectedMetric.name === "Conversion rate") {
        // computeFilterString aggregates multiple authorization_retry_attempt using AND instead of OR. So have to manually construct the filterString
        const idx = chartBuilder.filters.findIndex(f => f.path === "authorization_retry_attempt")
        if (idx != -1) {
          const retryFilter = chartBuilder.filters[idx]
          const filters = [...chartBuilder.filters]
          filters.splice(idx, 1)
          if (filters.length == 0) {
            filterString = `(authorization_retry_attempt == ${retryFilter.value[0]} OR authorization_retry_attempt == 0)`
          } else {
            filterString = computeFilterString(filters).replace(";", "")
            filterString += ` AND (authorization_retry_attempt == ${retryFilter.value[0]} OR authorization_retry_attempt == 0)`
          }
        }
      }

      if (i === 0) {
        if (chartBuilder.selectedMetric.forceOrderingByTransactions)
          formula = `plot{path:${dimensions[i].field}; ${strategy} ${top} formula: ${
            dimensions.length === 1 ? `count{path: transaction_operations; ${filterString}};` : ""
          }${dataFormula || ""}};`
        else
          formula = `plot{path:${dimensions[i].field}; ${strategy} ${top} formula: ${
            dataFormula || ""
          }};`
      } else {
        formula = `plot{formula: count{path: transaction_operations; ${filterString}};path: ${dimensions[i].field}; ${strategy} ${top} ${formula}}`
      }
    }

    if (dimensions.length === 0) {
      // no dimensions: this is a single value
      formula = dataFormula
    }

    const formattedFormula = formatFormula(formula)
    yield put(updateFormula(formattedFormula))
    return formattedFormula
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* metricTabWatcher(action: $Action): Generator<any, any, any> {
  const { payload } = action
  if (!payload) return

  if (payload.tab === "authorization" || payload.tab === "fees") {
    yield put({
      type: UPDATE_CHART_BUILDER_DETAILS,
      payload: {
        unit: "percentage",
      },
    })
  }
}
export function* formulaWatcher(action: $Action): Generator<any, any, any> {
  const { payload } = action
  if (!payload) return
  const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
  const analytics = yield select<any>(store => store.analytics)

  if (dataExplorer.selectedTab === "presets") {
    yield put(
      requestChartFetch(
        payload.formula,
        dataExplorer.name || "Preview",
        dataExplorer.type,
        dataExplorer.unit,
        analytics.params,
      ),
    )
  }
}
export function* saveChart(action: $Action): Generator<any, any, any> {
  try {
    const { payload } = action
    if (!payload) return
    const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
    const analytics = yield select<any>(store => store.analytics)
    const currentProject = yield select<any>(store => store.currentProject)
    let { boardId } = payload

    if (boardId === ON_NEW_BOARD) {
      // we need to create a board
      const boardResult = yield call(ProcessOut.APIcallPromise, "/boards", "POST", {
        name: "New board",
      })
      const { board } = boardResult.data
      yield put.resolve(requestBoardsFetch(board.id, true))
      boardId = board.id
    }

    if (dataExplorer.editingChartId) {
      // we're editing a chart
      const charts = yield select<any>(store => store.charts)
      const chart = charts[Object.keys(charts).find(id => id === dataExplorer.editingChartId)]
      yield put.resolve(
        ChartPreviewerActions.requestChartSave(
          chart.id,
          currentProject.project.id,
          dataExplorer.name,
          chart.description,
          dataExplorer.formula,
          dataExplorer.type,
          dataExplorer.unit,
          chart.size,
          boardId,
          "",
          analytics.params,
          {
            x: chart.position_x,
            y: chart.position_y,
          },
          chart.height,
        ),
      )
    } else {
      // We're creating a new chart
      yield put.resolve(
        ChartPreviewerActions.requestChartSave(
          null,
          currentProject.project.id,
          dataExplorer.name,
          "",
          dataExplorer.formula,
          dataExplorer.type,
          dataExplorer.unit,
          12,
          boardId,
          "",
          analytics.params,
          null,
          dataExplorer.type === MAP_CHART ? 12 : dataExplorer.type === SINGLE_VALUE ? 3 : 6,
        ),
      )
    }
  } catch (error) {
    ProcessOut.addNotification("An error occurred while saving your chart.", "error")
    datadogRum.addError(error)
  }
}
export function* rebuildFormulaWatcher(action: $Action): Generator<any, any, any> {
  try {
    const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
    if (dataExplorer.selectedTab !== "editor") yield call(rebuildFormula, action)
  } catch (error) {
    datadogRum.addError(error)
  }
}

function* setupChartEdition(action: $Action): Generator<any, any, any> {
  try {
    const { payload } = action
    if (!payload) throw new Error("missing payload")
    const charts = yield select<any>(store => store.charts)
    const analytics = yield select<any>(store => store.analytics)
    const chart = charts[Object.keys(charts).find(id => id === payload.chartId)]
    yield put({
      type: UPDATE_CHART_BUILDER_DETAILS,
      payload: {
        type: chart.type,
        unit: chart.unit,
        name: chart.name,
        size: chart.size,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* rebuildFormulaAndFetchWatcher(action: $Action): Generator<any, any, any> {
  try {
    const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
    const chartBuilder: $ChartBuilderState = yield select<any>(store => store.chartBuilder)
    // We make sure we are on the chart builder tab
    if (dataExplorer.selectedTab === "editor") return
    const analytics = yield select<any>(store => store.analytics)
    const newFormula = yield call(rebuildFormula, action)
    // Retrieve newly updated formula
    yield put(
      requestChartFetch(
        newFormula,
        dataExplorer.name || chartBuilder.selectedMetric.name,
        dataExplorer.type,
        dataExplorer.unit,
        analytics.params,
      ),
    )
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* dimensionsUpdateWatcher(
  action: $UpdateDimensionsAction,
): Generator<any, any, any> {
  const chartBuilder: $ChartBuilderState = yield select<any>(store => store.chartBuilder)
  const dataExplorer: $DataExplorerState = yield select<any>(store => store.dataExplorer)
  yield put({
    type: UPDATE_DIMENSIONS,
    payload: action.payload,
  })
  if (action.payload.dimensions.length === 0) return

  if (!new RegExp(/.*_at$/).test(action.payload.dimensions[0].field)) {
    if (dataExplorer.type !== "pie-chart")
      yield put(ChartBuilderActions.selectType("bar-chart", dataExplorer.type))
  } else {
    yield put(ChartBuilderActions.selectType("line-chart", dataExplorer.type))
  }
}
export function* selectTypeWatcher(action: $SelectTypeAction): Generator<any, any, any> {
  const chartBuilder: $ChartBuilderState = yield select<any>(store => store.chartBuilder)
  const { type, oldType } = action.payload

  if (type === SINGLE_VALUE) {
    yield put({
      type: UPDATE_DIMENSIONS,
      payload: {
        dimensions: [],
      },
    })
  } else if (oldType === SINGLE_VALUE) {
    if (type === PIE_CHART) {
      yield put({
        type: UPDATE_DIMENSIONS,
        payload: {
          dimensions: [
            {
              ...generateDefaultDimension(),
              field: "card_scheme",
              top: 10,
              strategy: "value_descending",
            },
          ],
        },
      })
    } else {
      yield put({
        type: UPDATE_DIMENSIONS,
        payload: {
          dimensions: [generateDefaultDimension()],
        },
      })
    }
  }
}
export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_CHART_FETCH, fetchChartPreview)
  yield takeLatest(APPLY_DATA_EXPLORER_PRESET, applyPreset)
  yield takeLatest(
    [
      UPDATE_METRIC_FILTERS,
      UPDATE_METRIC_LOCAL_CURRENCY,
      UPDATE_METRIC_PATH,
      UPDATE_METRIC_TYPE,
      UPDATE_DATA_EXPLORER_UNIT,
      UPDATE_DATA_EXPLORER_TYPE,
      SET_METRIC_TAB,
      UPDATE_CHART_BUILDER_DETAILS,
      APPLY_PRESET_CHART_BUILDER,
      APPLY_PRESET_DATA_EXPLORER,
      UPDATE_FILTERS,
    ],
    rebuildFormulaWatcher,
  )
  yield takeLatest(
    [
      SELECT_BUILDER_METRIC,
      SET_DISPLAY_LOCAL_CURRENCY,
      SELECT_TYPE,
      UPDATE_DIMENSIONS,
      SET_DISPLAY_NET_METRICS,
    ],
    rebuildFormulaAndFetchWatcher,
  )
  yield takeLatest(UPDATE_DATA_EXPLORER_FORMULA, formulaWatcher)
  yield takeLatest(SET_METRIC_TAB, metricTabWatcher)
  yield takeLatest(SAVE_CHART_ON_BOARD, saveChart)
  yield takeLatest(REQUEST_UPDATE_DIMENSIONS, dimensionsUpdateWatcher)
  yield takeLatest(SELECT_TYPE, selectTypeWatcher)
}
