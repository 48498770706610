import React from "react"
import Select from "react-select"
import type { $Option } from "../../analytics/DataExplorer/consts"
type Props = {
  id: string
  gateway: string
  gateways: any
  onChange: (arg0: string, arg1: string) => void
  processors: Array<{
    id: string
    gateway: {
      id: string
      tags: Array<string>
    }
  }>
}

class Gateway extends React.Component<Props> {
  onChange = (option: $Option) => {
    this.props.onChange(this.props.id, option.value)
  }

  render() {
    const { gateway, processors, gateways } = this.props
    const options: Array<$Option> = Object.keys(gateways)
      .filter(gateway => processors.findIndex(processor => processor.id === gateway) !== -1)
      .map(key => ({
        value: key,
        label: gateways[key],
      }))
      .concat({
        value: "processout",
        label: "Smart Routing",
      })
      .concat({
        value: "processout1",
        label: "Smart Routing (no retry)",
      })
    return (
      <div className="row">
        <div className="medium-12 columns">
          <Select
            className="react-select"
            value={{
              label:
                gateway === "processout1"
                  ? "Smart Routing (no retry)"
                  : gateway === "processout"
                  ? "Smart Routing"
                  : gateways[gateway],
              value: gateway,
            }}
            isClearable={false}
            options={options}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}

export default Gateway
