import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher, $RouterLocation } from "../../../util/Types"
import ContentLayout from "../../../pages/ContentLayout"
import Header from "./Header"
// @ts-expect-error
import "./alertBuilder.scss"
import MetricSelector from "./MetricSelector"
import Filters from "../../analytics/DataExplorer/ChartBuilder/Filters/Filters"
import IntervalSelector from "./IntervalSelector"
import TriggerSelector from "./TriggerSelector"
import ValueSelection from "./ValueSelection"
import type { $AlertBuilderState } from "./reducer"
import * as Actions from "./actions"
import AlertPreview from "./AlertPreview"
import CountSelector from "./CountSelector"
type Props = {
  location: $RouterLocation
  alertBuilder: $AlertBuilderState
} & $Dispatcher

class AlertBuilder extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(Actions.resetAlertBuilderForm())
    dispatch(Actions.requestAlertBuilderPreview())
  }

  updateForm = (field: string, value: any) => {
    const { dispatch } = this.props
    dispatch(Actions.updateAlertBuilderForm(field, value))
  }

  render() {
    const { alertBuilder } = this.props
    return (
      <ContentLayout
        title={
          <div className="row">
            <div className="medium-12 columns">
              <h4>Create new alert</h4>
            </div>
          </div>
        }
        noPadding
        noBackground
      >
        <div className="row">
          <div className="large-12 columns">
            <div className="alert-builder">
              <Header isChartBuilder={this.props.isChartBuilder} />
              <div className="row margin-bottom">
                <div className="large-12 columns">
                  <div className="content">
                    <div className="row margin-bottom">
                      <div className="large-12 columns ">
                        <MetricSelector
                          value={alertBuilder.selectedMetric}
                          onChange={newValue => {
                            this.updateForm("selectedMetric", newValue)
                          }}
                        />
                        <IntervalSelector
                          value={alertBuilder.interval}
                          onChange={newValue => {
                            this.updateForm("interval", newValue)
                          }}
                        />
                        <TriggerSelector
                          value={alertBuilder.triggerOperand}
                          onChange={newValue => {
                            this.updateForm("triggerOperand", newValue)
                          }}
                        />
                        <ValueSelection
                          operand={`${alertBuilder.triggerOperand.value}`}
                          value={alertBuilder.triggerValue}
                          onChange={newValue => {
                            this.updateForm("triggerValue", newValue)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="medium-12 columns">
                        <AlertPreview />
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="medium-12 columns">
                        <CountSelector
                          value={alertBuilder.minimumCount}
                          onChange={(newValue: number) => {
                            this.updateForm("minimumCount", newValue)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="medium-12 columns">
                        <Filters
                          filters={alertBuilder.filters}
                          onChange={newFilters => {
                            this.updateForm("filters", newFilters)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  alertBuilder: store.alertBuilder,
}))(AlertBuilder)
