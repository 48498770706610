import moment from "moment"
import getColor, { MATERIALS_COLORS } from "../Boards/charts/colors"

export function abbreviateNumber(number: number): string {
  const SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"]
  const tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier === 0) return number.toString()
  const postfix = SI_POSTFIXES[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale
  let formatted = `${scaled.toFixed(1)}`
  if (/\.0$/.test(formatted)) formatted = formatted.substr(0, formatted.length - 2)
  return formatted + postfix
}
export function parseDates(
  start: number,
  to: number,
): {
  from: number
  to: number
  timespan: string
  timezone: string
} {
  start = start || Date.now() / 1000
  to = to || Date.now() / 1000
  const startDate = moment.unix(start)
  const endDate = moment.unix(to)
  let timespan = "daily"

  if (endDate.diff(startDate, "hours") < 72) {
    timespan = "hourly"
  }

  if (endDate.diff(startDate, "days") > 31) {
    timespan = "weekly"
  }

  if (endDate.diff(startDate, "days") > 90) {
    timespan = "monthly"
  }

  if (startDate.isSame(endDate)) {
    startDate.set("hour", 0)
    endDate.set("hour", 23)
    start = startDate.unix()
    to = endDate.unix()
  }

  return {
    from: start,
    to,
    timespan,
    timezone: moment().format("Z"),
  }
}

export type KeyColor = {
  key: string
  color: string
}

export function generateKeysColors(keys: Array<string>): KeyColor[] {
  const result: Array<{
    key: string
    color: string
  }> = []
  let index = 0

  for (const key of keys) {
    // First we check if this key has already an assigned color
    if (result.findIndex(r => r.key === key) > -1) continue

    // Then we check if there exists a static color for this key
    if (STATIC_COLOR_KEYS[key]) {
      result.push({
        key,
        color: STATIC_COLOR_KEYS[key],
      })
      continue
    }

    // Then we retrieve the next color for the chart by making sure we haven't already assigned it
    let nextColor = getColor(index++)

    while (result.findIndex(r => r.color === nextColor) > -1) {
      if (index > 1000) {
        break
      }

      nextColor = getColor(index++)
    }

    result.push({
      key,
      color: nextColor,
    })
  }

  return result
}
export function computeMapChartColor(minAmount: number, maxAmount: number, data: any): any {
  return lerpColor("#9fa8da", "#304ffe", data.country.value / (maxAmount - minAmount))
}

/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
function lerpColor(a, b, amount) {
  const ah = parseInt(a.replace(/#/g, ""), 16)
  const ar = ah >> 16
  const ag = (ah >> 8) & 0xff
  const ab = ah & 0xff
  const bh = parseInt(b.replace(/#/g, ""), 16)
  const br = bh >> 16
  const bg = (bh >> 8) & 0xff
  const bb = bh & 0xff
  const rr = ar + amount * (br - ar)
  const rg = ag + amount * (bg - ag)
  const rb = ab + amount * (bb - ab)
  return `#${(((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)}`
}

const STATIC_COLOR_KEYS: Record<string, string> = {
  single: MATERIALS_COLORS.indigo[300],
  visa: MATERIALS_COLORS.indigo[300],
  mastercard: MATERIALS_COLORS.purple[300],
  "carte bancaire": MATERIALS_COLORS.blue[600],
  "american express": MATERIALS_COLORS.deeppurple[300],
  maestro: MATERIALS_COLORS.red[300],
  "diners club international": MATERIALS_COLORS.pink[300],
  vpay: MATERIALS_COLORS.blue[300],
  elo: MATERIALS_COLORS.teal[300],
  discover: MATERIALS_COLORS.green[300],
  uatp: MATERIALS_COLORS.lime[300],
  "private label": MATERIALS_COLORS.yellow[300],
  rupay: MATERIALS_COLORS.orange[300],
  "local brand": MATERIALS_COLORS.deeporange[300],
  troy: MATERIALS_COLORS.bluegrey[300],
  jcb: MATERIALS_COLORS.indigo[600],
  "china union pay": MATERIALS_COLORS.indigo[600],
  completed: MATERIALS_COLORS.blue[300],
  success: MATERIALS_COLORS.blue[300],
  authenticating: MATERIALS_COLORS.purple[300],
  failed: MATERIALS_COLORS.red[300],
  authorized: MATERIALS_COLORS.indigo[300],
  voided: MATERIALS_COLORS.deeporange[300],
  waiting: MATERIALS_COLORS.bluegrey[300],
  refunded: MATERIALS_COLORS.deeppurple[300],
  reversed: MATERIALS_COLORS.orange[300],
  pending: MATERIALS_COLORS.bluegrey[600],
  blocked: MATERIALS_COLORS.bluegrey[600],
  "fraud-notification": MATERIALS_COLORS.red[600],
  "chargeback-initiated": MATERIALS_COLORS.pink[900],
  solved: MATERIALS_COLORS.blue[300],
  "retrieval-request": MATERIALS_COLORS.purple[600],
}
