import { call, put, select, takeLatest } from "redux-saga/effects"
import uniqid from "uniqid"
import type { $Surface } from "./consts"
import { REQUEST_SURFACES_FETCH } from "./consts"
import * as ProcessOut from "../../../util/ProcessOut"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { getStartDateFromTimespan } from "^/features/Telescope/Sagas/common"
// return true if we want to display surfaces
export function* validateSurfaces(surfaces: Array<$Surface>): Generator<any, any, any> {
  const telescope: $TelescopeState = yield select<any>(store => store.telescope)
  const multiplier = {
    weekly: 52,
    monthly: 12,
    quarterly: 4,
    annually: 1,
  }
  const globalRevenue =
    telescope.standard.data.authorized_amount * multiplier[telescope.standard.timespan]
  const globalOpti =
    surfaces.reduce((acc, cur) => acc + cur.total_money_impact, 0) *
    multiplier[telescope.standard.timespan]
  let valid = false

  // Series of tests to check if we want to display surfaces or not
  if (globalOpti >= 100000) {
    // global revenue is less than 100k$ we skip
    valid = true
  }

  if (globalOpti >= globalRevenue * 0.001) {
    // optimization < .1% of global revenue
    valid = true
  }

  return valid
}
type $RequestSurfaceAction = {
  type: string
  payload: {
    timespan: string
    type?: "authorization-rate" | "chargeback-rate"
  }
}
export function* requestSurfacesFetch(action: $RequestSurfaceAction): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_SURFACES_FETCH),
    })
    const startDate = getStartDateFromTimespan(action.payload.timespan)
    const httpResult = yield ProcessOut.APIcallPromise(
      `/analytics/telescope/surfaces?start_at=${startDate}&rate=${
        action.payload.type || "authorization-rate"
      }`,
      "GET",
    )

    if (httpResult.data.generating) {
      // surfaces are still being generated
      yield put({
        type: typeFulfilled(REQUEST_SURFACES_FETCH),
        payload: {
          generating: true,
        },
      })
      return
    }

    const surfaces = httpResult.data.surfaces.surfaces.map(surface => {
      let tag

      if (surface.type === "soft.automatically_detected_surface") {
        if (surface.recuperable) tag = "recuperable"
        else tag = "medium"
      } else if (surface.recuperable) tag = "medium"
      else if (surface.type !== "soft.leftovers") tag = "hard"
      else tag = "leftovers"

      return { ...surface, tag, clickable: tag === "recuperable" || tag === "medium", id: uniqid() }
    })
    const high = surfaces
      .filter(surface => surface.tag === "recuperable")
      .sort((a, b) => b.count - a.count)
    const medium = surfaces
      .filter(surface => surface.tag === "medium")
      .sort((a, b) => b.count - a.count)
    const hard = surfaces
      .filter(surface => surface.tag === "hard")
      .sort((a, b) => b.count - a.count)
    const leftovers = surfaces.filter(surface => surface.tag === "leftovers")
    // Wait for telescope to be fetched
    // yield take(typeFulfilled(PREPARE_TELESCOPE));
    // We only validate using actionnable surfaces
    const validated = yield call(validateSurfaces, high.concat(medium))
    const payload = {
      ...httpResult.data.surfaces,
      surfaces: high.concat(medium).concat(hard).concat(leftovers),
      validated,
    }
    yield put({
      type: typeFulfilled(REQUEST_SURFACES_FETCH),
      payload,
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_SURFACES_FETCH),
      payload: error,
    })
  }
}
export default function* watchForSurfacesSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_SURFACES_FETCH, requestSurfacesFetch)
}
