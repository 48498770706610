import type { $Action, $State } from "../../../util/Types"
import type { $Board } from "./consts"
import { CLEAR_BOARS_LIST, FETCH_BOARDS } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $BoardsState = {
  boards: Array<$Board>
} & $State
const defaultState: $BoardsState = {
  fetched: false,
  fetching: false,
  boards: [],
}
export default function (state: $BoardsState = defaultState, action: $Action): $BoardsState {
  switch (action.type) {
    case typePending(FETCH_BOARDS): {
      return { ...state, boards: [], fetching: true, fetched: false, error: null }
    }

    case typePending(`${FETCH_BOARDS}_SILENT`): {
      return { ...state, fetching: true, fetched: false, error: null }
    }

    case typeFulfilled(`${FETCH_BOARDS}_SILENT`):
    case typeFulfilled(FETCH_BOARDS): {
      if (!action.payload) return state
      return { ...state, fetched: true, fetching: false, ...action.payload }
    }

    case typeFailed(`${FETCH_BOARDS}_SILENT`):
    case typeFailed(FETCH_BOARDS): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }

    case CLEAR_BOARS_LIST: {
      return { ...defaultState }
    }
  }

  return state
}
