import React from "react"
import Select, { components } from "react-select"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import CallToAction from "./CallToAction"
import MonitoringPlusPopup from "./MonitoringPlusPopup"
import type { $Dispatcher, $Project } from "../../../util/Types"
import { hasFeature } from "../../FeatureFlags/FeatureFlags"
type $Value = "custom" | "general" | "card_scheme" | "card_type"
export type $ProviderCompareOption = {
  value: $Value
  label: string
  isMulti: boolean | null | undefined
}
type Props = {
  value: $ProviderCompareOption
  onChange: (arg0: $ProviderCompareOption) => void
  currentProject: {
    project: $Project
  }
} & $Dispatcher
const OPTIONS = [
  {
    value: "general",
    label: "Provider",
  },
  {
    value: "card_scheme",
    label: "Card scheme",
  },
  {
    value: "card_type",
    label: "Card type",
  },
  {
    value: "custom",
    label: "Custom ...",
  },
]

class ProviderCompareSelector extends React.Component<
  Props,
  {
    showMonitoringPlusPopup: boolean
  }
> {
  constructor() {
    super()
    this.state = {
      showMonitoringPlusPopup: false,
    }
  }

  render() {
    return (
      <div>
        {this.state.showMonitoringPlusPopup ? (
          <MonitoringPlusPopup
            close={() => {
              this.setState({
                showMonitoringPlusPopup: false,
              })
            }}
          />
        ) : null}
        <div className="row text-left">
          <div
            className="large-12 columns"
            style={{
              marginTop: "-.5em",
            }}
          >
            <Select
              className="react-select"
              value={this.props.value}
              options={OPTIONS}
              onChange={(newValue: $ProviderCompareOption) => {
                if (newValue.value !== "custom") this.props.onChange(newValue)
                else if (
                  hasFeature(this.props.currentProject.project.feature_flags, "analytics-v2")
                ) {
                  this.props.dispatch(
                    push(`/projects/${this.props.currentProject.project.id}/boards`),
                  )
                } else {
                  this.setState({
                    showMonitoringPlusPopup: true,
                  })
                }
              }}
              isClearable={false}
              components={{
                ValueContainer,
                Option: LabelContainer,
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const ValueContainer = ({ children, ...props }) => {
  const { value, label } = props.getValue()[0]
  return (
    <components.ValueContainer {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {value === "general" ? (
          <div>
            <span className="greyed">per</span> provider
          </div>
        ) : (
          <div
            style={{
              textTransform: "lowercase",
              whiteSpace: "nowrap",
            }}
          >
            <span className="greyed">per </span>
            provider <span className="greyed">& </span>
            {label}
          </div>
        )}
        {children[1]}
      </div>
    </components.ValueContainer>
  )
}

const LabelContainer = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {props.data.value === "custom" ? (
        <CallToAction>
          <span className="text">{children}</span>
        </CallToAction>
      ) : (
        children
      )}
    </components.Option>
  )
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(ProviderCompareSelector)
