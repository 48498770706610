import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $Dispatcher } from "../../util/Types"
import type { $Resource, $SearchBarState } from "../SearchBar/reducer"
import CardItem from "./CardItem"
import CustomerItem from "./CustomerItem"
import * as Helpers from "./helpers"
import ProjectItem from "./ProjectItem"
import TransactionItem from "./TransactionItem"
type Props = $Dispatcher & {
  details: $SearchBarState
  selectResource: () => void
  filter: string
  projectsDetails: {
    projects: Array<{
      name: string
      id: string
    }>
  }
  params: {
    project: string
  }
  cursor: number
  getResourcesSearched: () => void
}
export class SearchByResources extends React.Component<Props> {
  renderSwitch = (type: string, item: $Resource, first: boolean) => {
    switch (type) {
      case "transaction":
        return <TransactionItem transaction={item} isFirst={first} />

      case "customer":
        return <CustomerItem customer={item} isFirst={first} />

      case "card":
        return <CardItem card={item} isFirst={first} />

      case "project":
        return <ProjectItem project={item} isFirst={first} />
    }
  }
  getResourceType = (item: $Resource, index: number, type: string, resources: $Resource) => {
    const { cursor, selectResource } = this.props
    const isFirst =
      resources
        .filter(r => Helpers.getResourceTypeFromId(r.id) === type)
        .findIndex(r => r.id === item.id) === 0
    return (
      <div
        onClick={() => selectResource(item)}
        className={cursor === index ? "highlighted" : ""}
        id={`resources-${index}`}
        key={item.id}
      >
        {this.renderSwitch(type, item, isFirst)}
      </div>
    )
  }

  render() {
    const { details, filter, projectsDetails, params, getResourcesSearched } = this.props
    const { resources } = details
    let allResources = []
    // Append projects
    allResources = allResources.concat(
      projectsDetails.projects.filter(
        p =>
          filter &&
          filter.length > 3 &&
          p.name.toLocaleLowerCase().includes(filter.toLowerCase()) &&
          p.id !== params.project,
      ),
    )

    if (resources.length > 0) {
      allResources = allResources.concat(resources)
    }

    getResourcesSearched(allResources)
    const items = []
    allResources.forEach((item, i) => {
      const resourceType = Helpers.getResourceTypeFromId(item.id)

      if (resourceType === "transaction") {
        items.push(this.getResourceType(item, i, resourceType, allResources))
      } else if (resourceType === "card") {
        items.push(this.getResourceType(item, i, resourceType, allResources))
      } else if (resourceType === "customer") {
        items.push(this.getResourceType(item, i, resourceType, allResources))
      } else if (resourceType === "project") {
        items.push(this.getResourceType(item, i, resourceType, allResources))
      }
    })
    return <div>{items}</div>
  }
}
export default connect(store => ({
  details: store.searchBar,
  projectsDetails: store.projects,
}))(withRouter(SearchByResources))
