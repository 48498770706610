import React from "react"
import { $TelescopeMetricData } from "../consts"
import TelescopeCard from "./TelescopeCard"
type Props = {
  data: Array<$TelescopeMetricData>
  loading: boolean
}

function TelescopeCards({ data, loading }: Props) {
  return (
    <>
      {data.map<React.ReactElement<React.ComponentProps<"div">, "div">>((metric, index) => (
        <div key={metric.id} className={`columns medium-3 ${index - 1 && "end"}`}>
          <TelescopeCard
            title={metric.title}
            value={metric.value}
            dashboardUrl={metric.dashboardUrl}
            id={metric.id}
            tooltipContent={metric.tooltipContent}
            percentageEvolution={metric.percentageEvolution}
            valueStyle={metric.valueStyle}
            loading={loading}
            chart={metric.chart}
          />
        </div>
      ))}
    </>
  )
}

export default TelescopeCards
