import React from "react"
import type { $Project } from "../../util/Types"
import "./ProjectRegionFlag.scss"
type Props = {
  project: $Project
}
const regions = {
  "aws-eu-central-1": "EU2",
  "eu-central-1": "EU2",
  "aws-eu-west-1": "EU",
  "eu-west-1": "EU",
  "aws-us-east-1": "US",
  "us-east-1": "US",
}
export class ProjectRegionFlag extends React.Component<Props> {
  render() {
    const { project } = this.props
    return <div className="project-region-flag">{regions[project.region_name]}</div>
  }
}
export default ProjectRegionFlag
