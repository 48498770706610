/**
 * Created by jeremylejoux on 12/09/2017.
 */
import Wildcard from "wildcard"
import type { Feature } from "./consts"
export function hasFeature(flags: Array<string>, feature: Feature): boolean {
  for (const flag of flags) {
    if (Wildcard(flag, feature)) return true
  }

  return false
}
