import type { $Action } from "../../util/Types"
import { UPDATE_CURRENT_URL } from "./consts"
type State = {
  currentUrl: string
}
const defaultState: State = {
  currentUrl: "",
}
export default function (state: State = defaultState, action: $Action): State {
  switch (action.type) {
    case UPDATE_CURRENT_URL: {
      if (!action.payload) return state
      return { ...state, currentUrl: action.payload.url }
    }
  }

  return state
}
