import React from "react"
import Select from "react-select"
import type { $Operand } from "./consts"
import { NORMALIZATION_FIELDS } from "../../consts"
type $Option = {
  label: string
  value: string
}
type Props = {
  onChange: (arg0: $Operand) => void
  defaultValue?: $Operand | null | undefined
  options: Array<$Option>
  path: string
  isMulti: boolean
}
type State = {
  operand: $Operand
  path: string
}

class Operand extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      operand: "==",
      path: "",
    }
  }

  componentDidMount() {
    this.setDefaultValue(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      path: nextProps.path,
    })

    if (nextProps.defaultValue !== this.props.defaultValue) {
      this.setDefaultValue(nextProps)
    }
  }

  setDefaultValue = (props: Props) => {
    if (props.defaultValue)
      this.setState({
        operand: props.defaultValue,
      })
  }
  valueChanged = (option: { value: $Operand }) => {
    const { onChange } = this.props
    if (onChange) onChange(option.value)
    this.setState({
      operand: option.value,
    })
  }
  getOptions = () => {
    const { path, operand } = this.state
    const { isMulti } = this.props

    if (NORMALIZATION_FIELDS.includes(path)) {
      if (operand === "==" || operand === "!=") {
        return [
          {
            label: "===",
            value: "===",
          },
          {
            label: "!==",
            value: "!==",
          },
          {
            label: "==",
            value: "==",
          },
          {
            label: "!=",
            value: "!=",
          },
        ]
      }

      return [
        {
          label: "===",
          value: "===",
        },
        {
          label: "!==",
          value: "!==",
        },
      ]
    }

    if (path === "sca_exemption_reason" || path === "challenge_indicator") {
      return [
        {
          label: "==",
          value: "==",
        },
        {
          label: "!=",
          value: "!=",
        },
        {
          label: "is not null",
          value: "is not null",
        },
        {
          label: "is null",
          value: "is null",
        },
      ]
    }

    if (path === "rand") {
      return [
        {
          label: "<",
          value: "<",
        },
        {
          label: "<=",
          value: "<=",
        },
        {
          label: ">",
          value: ">",
        },
        {
          label: ">=",
          value: ">=",
        },
      ]
    }

    if (isMulti && !path.includes("metadata")) {
      return [
        {
          label: "==",
          value: "==",
        },
        {
          label: "!=",
          value: "!=",
        },
      ]
    }

    return [
      {
        label: "==",
        value: "==",
      },
      {
        label: "!=",
        value: "!=",
      },
      {
        label: "<",
        value: "<",
      },
      {
        label: "<=",
        value: "<=",
      },
      {
        label: ">",
        value: ">",
      },
      {
        label: ">=",
        value: ">=",
      },
      {
        label: "is null",
        value: "is-null",
      },
      {
        label: "is not null",
        value: "is-not-null",
      },
    ]
  }

  render() {
    const { operand } = this.state
    const options = this.getOptions()
    const selectedValue = options.filter(option => option.value === operand)
    return (
      <div className="row">
        <div className="large-12 columns text-center">
          <Select
            className="react-select"
            options={this.getOptions()}
            isSearcheable={false}
            value={selectedValue}
            isClearable={false}
            onChange={this.valueChanged}
          />
        </div>
      </div>
    )
  }
}

export default Operand
