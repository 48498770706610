import { call, put, select } from "redux-saga/effects"
import moment from "moment"
import { APIcallPromise } from "../../../util/ProcessOut"
import { GLOBAL_CHARGEBACK_RATE } from "../consts"
import * as Actions from "../actions"
import { REQUEST_CHARGEBACK_DATA_FETCH } from "../actions"
import type { $ChargebackLimit, $ChargebackProgram, $ChargebackProgramData } from "../reducer"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { fetchTelescopeChartData } from "^/features/Telescope/Sagas/common"

/**
 * Load chargeback program details for all gateway configurations
 * @param timespan
 */
export function* fetchChargebackConfigurations(timespan: string): Generator<any, any, any> {
  try {
    const startDate = getChargebackDataStartinDate()
    const apiReturn = yield call(
      APIcallPromise,
      `/analytics/telescope/chargebacks?start_at=${startDate}`,
      "GET",
    )
    // Generate the result according to reducer types
    const gatewayConfigurationsData = Object.keys(apiReturn.data.chargebacks).map(key => ({
      id: key,
      belongsToProgram: apiReturn.data.chargebacks[key],
    }))
    // Retrieve configurations names
    const processorsConfigurations = yield select<any>(store => store.processorsConfigurations)

    if (gatewayConfigurationsData.length !== 1) {
      // Dispatch the result
      return {
        gatewayConfigurationsData,
      }
    }

    // We only have 1 config, retrieve the corresponding configuration
    const correspondingConfig = processorsConfigurations.configurations.find(
      c => c.id === gatewayConfigurationsData[0].id,
    )
    // Config not found, we return the result
    if (!correspondingConfig)
      return {
        gatewayConfigurationsData,
      }
    const option = {
      value: correspondingConfig.id,
      label: correspondingConfig.name,
    }
    // Auto select the config in the dropdown
    yield put(Actions.selectChargebackGatewayConfiguration(option))
    // We directly fetch the corresponding data
    yield call(fetchChargebackData, {
      payload: {
        configId: option.value,
      },
    } as any)
    // Finally return the result
    return {
      gatewayConfigurationsData,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
type $FetchChargebackDataAction = {
  type: typeof REQUEST_CHARGEBACK_DATA_FETCH
  payload: {
    configId: string
    timespan?: string
  }
}
export function* fetchChargebackData(action: $FetchChargebackDataAction): Generator<any, any, any> {
  try {
    const startDate = getChargebackDataStartinDate()
    const apiReturn = yield call(
      APIcallPromise,
      `/analytics/telescope/chargebacks/${action.payload.configId}/details?start_at=${startDate}`,
      "GET",
    )
    const apiData: {
      global_chargeback_rate: number
      global_chargeback_volume: number
      global_chargeback_count: number
      global_requests_for_information: number
      global_notifications_of_fraud: number
      visa_chargeback_rate: number
      visa_chargeback_volume: number
      visa_chargeback_count: number
      visa_requests_for_information: number
      visa_notifications_of_fraud: number
      mastercard_chargeback_rate: number
      mastercard_chargeback_volume: number
      mastercard_chargeback_count: number
      mastercard_requests_for_information: number
      mastercard_notifications_of_fraud: number
      chargeback_programs: Array<$ChargebackProgram>
      chargeback_limits: Record<string, $ChargebackLimit>
      default_chargeback_programs: {
        visa: string
        mastercard: string
      }
      belongs_to_programs: boolean
    } = apiReturn.data.chargebacks_details
    // Detect the current program
    const programs: {
      visa: $ChargebackProgramData | null | undefined
      mastercard: $ChargebackProgramData | null | undefined
    } = {
      visa: null,
      mastercard: null,
    }

    if (apiData.chargeback_programs) {
      // Transform the limits into array
      // $FlowFixMe
      const limits: Array<$ChargebackLimit> = Object.values(apiData.chargeback_limits)
      // Find visa corresponding program
      const visaLimits = limits.filter(p => p.card_scheme === "visa")
      const visaLimitIndex = visaLimits.findIndex(
        p => apiData.chargeback_programs.findIndex(v => v.limit_name === p.name) > -1,
      )
      // For mastercard
      const mastercardLimits = limits.filter(p => p.card_scheme === "mastercard")
      const mastercardLimitIndex = mastercardLimits.findIndex(
        p => apiData.chargeback_programs.findIndex(v => v.limit_name === p.name) > -1,
      )

      if (visaLimitIndex < 0) {
        // No visa program
        const visaLimit: $ChargebackLimit =
          apiData.chargeback_limits[apiData.default_chargeback_programs.visa]
        programs.visa = {
          name: visaLimit.name,
          card_scheme: visaLimit.card_scheme,
          metrics: visaLimit.metrics,
          count: visaLimit.count,
          rate: visaLimit.rate,
          volume: visaLimit.volume,
          fines: [],
          belong_since: 0,
        }
      } else {
        const currentVisaProgram: $ChargebackProgram | null | undefined =
          apiData.chargeback_programs.find(p => p.limit_name === visaLimits[visaLimitIndex].name)
        programs.visa = {
          name: (currentVisaProgram && currentVisaProgram.limit_name) || "",
          card_scheme: visaLimits[visaLimitIndex].card_scheme,
          metrics: visaLimits[visaLimitIndex].metrics,
          count: visaLimits[visaLimitIndex].count,
          rate: visaLimits[visaLimitIndex].rate,
          volume: visaLimits[visaLimitIndex].volume,
          fines: (currentVisaProgram && currentVisaProgram.fines) || [],
          belong_since: (currentVisaProgram && currentVisaProgram.belong_since) || 0,
        }
      }

      if (mastercardLimitIndex < 0) {
        // No mastercard program
        const masterCardLimit: $ChargebackLimit =
          apiData.chargeback_limits[apiData.default_chargeback_programs.mastercard]
        programs.mastercard = {
          name: masterCardLimit.name,
          card_scheme: masterCardLimit.card_scheme,
          metrics: masterCardLimit.metrics,
          count: masterCardLimit.count,
          rate: masterCardLimit.rate,
          volume: masterCardLimit.volume,
          fines: [],
          belong_since: 0,
        }
      } else {
        const currentMastercardProgram: $ChargebackProgram | null | undefined =
          apiData.chargeback_programs.find(
            p => p.limit_name === mastercardLimits[mastercardLimitIndex].name,
          )
        programs.mastercard = {
          name: (currentMastercardProgram && currentMastercardProgram.limit_name) || "",
          card_scheme: mastercardLimits[mastercardLimitIndex].card_scheme,
          metrics: mastercardLimits[mastercardLimitIndex].metrics,
          count: mastercardLimits[mastercardLimitIndex].count,
          rate: mastercardLimits[mastercardLimitIndex].rate,
          volume: mastercardLimits[mastercardLimitIndex].volume,
          fines: (currentMastercardProgram && currentMastercardProgram.fines) || [],
          belong_since: (currentMastercardProgram && currentMastercardProgram.belong_since) || 0,
        }
      }
    } else {
      // FIXME: programs.mastercard is not an string
      // @ts-ignore
      programs.visa = apiData.default_chargeback_programs.visa
      // FIXME: programs.mastercard is not an string
      // @ts-ignore
      programs.mastercard = apiData.default_chargeback_programs.mastercard
    }

    const data = {
      chargebacksData: {
        ready: true,
        globalChargebackRate: apiData.global_chargeback_rate,
        globalChargebackVolume: apiData.global_chargeback_volume,
        globalChargebackCount: apiData.global_chargeback_count,
        globalRequestsForInformation: apiData.global_requests_for_information,
        globalNotificationsOfFraud: apiData.global_notifications_of_fraud,
        visaChargebackRate: apiData.visa_chargeback_rate,
        visaChargebackVolume: apiData.visa_chargeback_volume,
        visaChargebackCount: apiData.visa_chargeback_count,
        visaRequestsForInformation: apiData.visa_requests_for_information,
        visaNotificationsOfFraud: apiData.visa_notifications_of_fraud,
        mastercardChargebackRate: apiData.mastercard_chargeback_rate,
        mastercardChargebackVolume: apiData.mastercard_chargeback_volume,
        mastercardChargebackCount: apiData.mastercard_chargeback_count,
        mastercardRequestsForInformation: apiData.mastercard_requests_for_information,
        mastercardNotificationsOfFraud: apiData.mastercard_notifications_of_fraud,
        chargebackThresholds: apiData.chargeback_limits,
        chargebackPrograms: programs,
        belongsToProgram: apiData.belongs_to_programs,
        defaultChargebackPrograms: apiData.default_chargeback_programs,
      },
    }
    yield put({
      type: typeFulfilled(REQUEST_CHARGEBACK_DATA_FETCH),
      payload: {
        data,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_CHARGEBACK_DATA_FETCH),
      payload: error,
    })
  }
}

const getChargebackDataStartinDate = () =>
  moment().utc().set("date", 1).set("hour", 0).set("minute", 0).set("second", 0).unix()

export function* fetchGlobalChargeback(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, GLOBAL_CHARGEBACK_RATE, timespan)
    return {
      global_chargeback_rate: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
