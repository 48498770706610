import React from "react"
import { withRouter } from "react-router"
import { datadogRum } from "@datadog/browser-rum"
type Props = {
  params: {
    project: string
  }
}
type State = {
  waiting: boolean
  done: boolean
  childPage: any | null | undefined
}
export class StripeConnect extends React.Component<Props, State> {
  /*:: receiveMessage: () => void; */
  constructor() {
    super()
    this.state = {
      waiting: false,
      done: false,
    }
    this.receiveMessage = this.receiveMessage.bind(this)
    this.publicKeyRef = React.createRef()
    this.privateKeyRef = React.createRef()
    this.gatewayMerchantIdRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("message", this.receiveMessage, false)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false)
  }

  receiveMessage(event) {
    this.setState({
      waiting: false,
    })
    let data

    try {
      data = JSON.parse(event.data)
    } catch (e) {
      return
    }

    if (data.namespace !== "processout.gateway-configuration.oauth") return
    if (this.state.childPage) this.state.childPage.close()

    if (data.action === "success") {
      this.setState({
        done: true,
      })
      this.publicKeyRef.current.value = data.data.public_key
      this.privateKeyRef.current.value = data.data.private_key
      this.gatewayMerchantIdRef.current.value = data.data.gateway_merchant_id
    } else {
      this.setState({
        done: false,
      })
    }
  }

  loadStripeOauth(event) {
    event.preventDefault()
    this.setState({
      waiting: true,
    })
    const win = window.open(
      // $FlowFixMe
      `${process.env.API_HOST}/handlers/oauth/${this.props.params.project}/prepare/stripe`,
      "_blank",
    )
    this.setState(
      {
        childPage: win,
      },
      () => {
        const timer = setInterval(() => {
          try {
            win.postMessage("processout.ping-ready", "*")
          } catch (error) {
            datadogRum.addError(error)
          }

          if (win.closed) {
            this.setState({
              waiting: false,
            })
            clearInterval(timer)
          }
        }, 500)
      },
    )
  }

  render() {
    let content

    if (this.state.done) {
      content = (
        <div
          className="large-12 columns large-centered text-center greyed"
          style={{
            fontSize: "1.2em",
          }}
        >
          <span
            style={{
              color: "#27ae60",
              marginRight: "0.5em",
            }}
          >
            <img
              alt="Check icon"
              src="../../images/success.png"
              style={{
                height: "20px",
                width: "auto",
              }}
            />
          </span>{" "}
          Stripe keys successfully retrieved.
        </div>
      )
    } else {
      content = (
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h3 className="greyed bold">How does it work?</h3>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <p className="greyed">
                Stripe connect allows us to securely access your payment flow without requesting
                your Stripe public and private keys.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns large-centered text-center">
              <button onClick={this.loadStripeOauth.bind(this)}>
                <img
                  alt="Link your Stripe account"
                  src="../../images/powered-stripe-blurple.svg"
                  style={{
                    cursor: "pointer",
                    width: "190px",
                    height: "43px",
                    backgroundSize: "cover",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {content}
        <input type="hidden" ref={this.publicKeyRef} name="public_key" />
        <input type="hidden" ref={this.privateKeyRef} name="private_key" />
        <input type="hidden" ref={this.gatewayMerchantIdRef} name="gateway_merchant_id" />
      </div>
    )
  }
}
export default withRouter(StripeConnect)
