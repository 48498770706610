import { call, put, select, takeLatest } from "redux-saga/effects"
import { replace } from "react-router-redux"
import type { $Board, $FetchParams } from "./consts"
import { REQUEST_BOARD_EDITION } from "./consts"
import * as Actions from "./actions"
import type { $ActionType } from "../../Preparator/consts"
import * as BoardsSagas from "./Board/sagas"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
type Action = {
  payload: {
    newBoard: $Board
    remount: boolean | null | undefined
  }
} & $ActionType

function* editBoard(action: Action): Generator<any, any, any> {
  const { newBoard, remount } = action.payload

  try {
    const editResult = yield put.resolve(Actions.editBoard(newBoard.id, newBoard))
    const params: $FetchParams = yield select<any>(store => store.analytics.params)
    // FIXME: missing action type here!
    // @ts-ignore
    yield call(BoardsSagas.prepareBoard, {
      payload: {
        board: newBoard.id,
        fetchParams: params,
      },
    })
    const currentProject = yield select<any>(store => store.currentProject)

    if (remount) {
      yield put(replace(`/projects/${currentProject.project.id}/boards?redirect=${newBoard.id}`))
    }

    yield put({
      type: typeFulfilled(REQUEST_BOARD_EDITION),
    })
  } catch (e) {
    yield put({
      type: typeFailed(REQUEST_BOARD_EDITION),
      payload: {
        e,
      },
    })
    datadogRum.addError(e)
  }
}

export default function* watchBoardsEditSaga(): Generator<any, any, any> {
  yield takeLatest(REQUEST_BOARD_EDITION, editBoard)
}
