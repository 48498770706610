import { combineReducers } from "redux"
import type { $State } from "../../util/Types"
import type { $Collaborator, $PermissionGroup } from "./consts"
import { PREPARE_PERMISSIONS } from "./consts"
import type { $PermissionGroupsState } from "./PermissionGroupsReducer"
import permissionGroups from "./PermissionGroupsReducer"
import type { $CollaboratorsState } from "./CollaboratorsReducer"
import collaborators from "./CollaboratorsReducer"
import type {
  $DeletePermissionGroupAction,
  $PreparePermissionsFullfilledAction,
  $SetCollaboratorPermissionGroupAction,
  $SetPermissionGroupValuesAction,
  PreparePermissionsAction,
} from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $FetchedCollaboratorsState = {
  collaborators: Array<$Collaborator>
  permissionGroups: Array<$PermissionGroup>
}
export type $PermissionsState = {
  permissionGroups: $PermissionGroupsState
  collaborators: $CollaboratorsState
  fetchedState: $FetchedCollaboratorsState | null | undefined
  global: $State
}
const defaultState: $State = {
  fetching: false,
  fetched: false,
  error: null,
}
export const globalReducer = (
  state: $State = defaultState,
  action:
    | $SetCollaboratorPermissionGroupAction
    | $SetPermissionGroupValuesAction
    | $PreparePermissionsFullfilledAction
    | PreparePermissionsAction
    | $DeletePermissionGroupAction,
): $State => {
  switch (action.type) {
    case PREPARE_PERMISSIONS: {
      return { ...state, ...defaultState, fetching: true, fetched: false }
    }

    case typeFulfilled(PREPARE_PERMISSIONS): {
      const { payload } = action
      return { ...state, fetched: true, fetching: false }
    }

    case typeFailed(PREPARE_PERMISSIONS): {
      return { ...state, fetched: true, fetching: false, error: action.payload as any }
    }
  }

  return state
}
export default combineReducers({
  global: globalReducer,
  permissionGroups,
  collaborators,
})
