import { EventEmitter } from "events"
import Dispatcher from "../Dispatcher"
import Auth from "../util/Auth"
import { FETCH_USER, RECEIVE_NOTIFICATION, USER_EDITED } from "^/Actions/UserActions"

class UserStore extends EventEmitter {
  constructor() {
    super()
    this.user = null
    this.fetching = true
    this.twoFactorData = null
  }

  getAll() {
    return {
      user: this.user,
      fetching: this.fetching,
      twoFactorData: this.twoFactorData,
    }
  }

  getError() {
    const { error } = this
    this.error = null
    return error
  }

  handleAction(action) {
    switch (action.type) {
      case FETCH_USER: {
        break
      }

      case "RECEIVE_USER": {
        this.user = action.user
        this.fetching = false
        this.emit("change")
        break
      }

      case USER_EDITED: {
        this.user = action.user
        this.emit("change")
        break
      }

      case "USER_ERROR": {
        this.error = action.error
        this.emit("error")
        break
      }

      case "RECEIVE_TWO_FACTOR_IMAGE": {
        this.twoFactorData = action.data
        this.emit("change")
        break
      }

      case "TWO_FACTOR_ENABLED": {
        this.twoFactorData = {
          enabled: true,
        }
        setTimeout(() => {
          Dispatcher.dispatch({
            type: RECEIVE_NOTIFICATION,
            notification: "Two factor authentication enabled",
            level: "success",
          })
        }, 0)
        this.emit("change")
        break
      }

      case "TWO_FACTOR_DISABLED": {
        this.twoFactorData = {
          enabled: false,
        }
        setTimeout(() => {
          Dispatcher.dispatch({
            type: RECEIVE_NOTIFICATION,
            notification: "Two factor authentication disabled",
            level: "success",
          })
        }, 0)
        Auth.getTwoFactorData()
        this.emit("change")
        break
      }
    }
  }
}

const userStore = new UserStore()
Dispatcher.register(userStore.handleAction.bind(userStore))
export default userStore
