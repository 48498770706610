import React from "react"
import { FormattedDate } from "react-intl"
export default class Data extends React.Component {
  constructor() {
    super()
  }

  render() {
    const { event } = this.props

    if (event.id == 0) {
      return <div />
    }

    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom greyed">
            <div className="large-2 medium-3 columns">ID</div>
            <div className="large-10 medium-9 columns font-console">{event.id}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-2 medium-3 columns greyed">Name</div>
            <div className="large-10 medium-9 columns font-console">{event.name}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-2 medium-3 columns greyed">Date</div>
            <div className="large-10 medium-9 columns">
              <FormattedDate
                value={event.fired_at}
                day="numeric"
                month="long"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                seconds="2-digit"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
