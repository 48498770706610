/**
 * Created by jeremylejoux on 08/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import ContentLayout from "../../../pages/ContentLayout"
import * as Store from "../../../stores/Store"
import Error from "../../Error"
import Loader from "../../Loader"
import AlternativeCertificates from "./AlternativeCertificates/AlternativeCertificates"
import ApplePayDomainNames from "./ApplePayDomainNames"
import ApplePayState from "./ApplePayState"
export class ApplePayOverview extends React.Component {
  props: Props
  static defaultProps = {
    dispatch: null,
  }

  /*:: _unsubscribe: () => void; */
  componentDidMount() {
    const { currentProject, dispatch, params } = this.props
    this._unsubscribe = Store.store.subscribe(this.storeDidUpdate.bind(this))

    if (currentProject.fetched && !currentProject.project.applepay_settings) {
      if (this._unsubscribe) this._unsubscribe()
      dispatch(push(`/projects/${params.project}/settings/apple-pay/step-1`))
    }
  }

  storeDidUpdate() {
    const state = Store.store.getState()
    const { dispatch, params } = this.props

    if (state.currentProject.fetched && !state.currentProject.project.applepay_settings) {
      if (this._unsubscribe) this._unsubscribe()
      dispatch(push(`/projects/${params.project}/settings/apple-pay/step-1`))
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    const { currentProject } = this.props
    if (currentProject.error) return <Error />
    if (!currentProject.fetched || currentProject.fetching)
      return (
        <ContentLayout title={<h4>Apple Pay</h4>}>
          <Loader />
        </ContentLayout>
      )
    return (
      <div>
        <ApplePayState />
        <AlternativeCertificates />
        <ApplePayDomainNames />
      </div>
    )
  }
}
type Props = {
  params: {
    project: string
  }
  currentProject: {
    error: boolean
    fetched: boolean
    fetching: boolean
    project?: {
      id: string
    }
  }
  dispatch: any | (() => void)
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(ApplePayOverview)
