import React from "react"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Creatable, Option, Select } from "^/vNext/Select/Select"
import { OptionContext } from "../OptionContext"
import { getOperandOptions, isMulti, toReactSelectValue } from "../utils"

type Props = {
  condition: $Filter
  operand: Option[]
  value: unknown
  onOperandChange: (change: Option) => void
  onValueChange: (change: Option[]) => void
}

export const GenericSelect = ({
  condition,
  operand,
  value,
  onOperandChange,
  onValueChange,
}: Props) => {
  const optionLookup = React.useContext(OptionContext)

  const showValue =
    operand[0].value !== "is-not-null" &&
    operand[0].value !== "is-null" &&
    operand[0].value !== "is not null" &&
    operand[0].value !== "is null"

  return (
    <>
      <Select
        aria-label="operand"
        value={operand}
        options={getOperandOptions(condition.path)}
        onChange={onOperandChange}
      />
      {showValue && (
        <Creatable
          aria-label="value"
          isMulti={isMulti(condition.path)}
          value={toReactSelectValue(value)}
          options={optionLookup[condition.path]}
          onChange={onValueChange}
        />
      )}
    </>
  )
}
