/**
 * Created by jeremylejoux on 09/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import Serialize from "form-serialize"
import { replace } from "react-router-redux"
import * as ApplePayActions from "../../../Actions/ApplePayActions"
import ContentLayout from "../../../pages/ContentLayout"
export class DomainNameFlow extends React.Component {
  props: Props
  static defaultProps = {
    dispatch: null,
  }

  componentDidMount() {
    this.isApplePayReady(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.isApplePayReady(nextProps)
  }

  isApplePayReady(props: Props) {
    const { dispatch, currentProject } = props
    if (currentProject.fetched && !currentProject.project.applepay_settings)
      dispatch(replace(`/projects/${currentProject.project.id}/settings/apple-pay`))
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  submit(event: Event) {
    event.preventDefault()
    const data = Serialize(event.target, {
      hash: true,
    })
    const { currentProject, dispatch, params } = this.props
    dispatch(
      ApplePayActions.addDomain(
        params.project,
        data.merchant_domain_name,
        currentProject.project.applepay_settings.merchant_domain_names || [],
      ),
    )
  }

  render() {
    const separator = (
      <div className="row">
        <div className="large-12 columns">
          <hr />
        </div>
      </div>
    )
    return (
      <div className="row">
        <form onSubmit={this.submit.bind(this)}>
          <div className="large-12 columns large-centered">
            <ContentLayout
              title={
                <div>
                  <div className="row small-margin-bottom">
                    <div className="large-12 columns">
                      <h4>Apple Pay domain name setup</h4>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="row margin-bottom">
                <div className="medium-6 columns">
                  <p>
                    Scroll down to &quot;Apple Pay on the Web&quot;. Next to &quot;Merchant
                    Domains&quot;, click &quot;Add Domain&quot;.
                  </p>
                </div>
                <div className="medium-5 medium-offset-1 columns">
                  <img
                    alt="Merchant domain for specific merchant ID"
                    src="//dashboard.processout.ninja/images/applepay-4.jpg"
                  />
                </div>
              </div>
              {separator}
              <div className="row margin-bottom">
                <div className="medium-6 columns">
                  <p>
                    On the next screen, enter the domain name where the payment page will be hosted.
                    This must be the same domain entered in the ProcessOut Dashboard. Click
                    &quot;Continue&quot;.
                  </p>
                </div>
                <div className="medium-5 medium-offset-1 columns">
                  <img
                    alt="Merchant domain name"
                    src="//dashboard.processout.ninja/images/applepay-5.jpg"
                  />
                </div>
              </div>
              {separator}
              <div className="row margin-bottom">
                <div className="medium-6 columns">
                  <p>Download the verification file provided by Apple.</p>
                </div>
                <div className="medium-5 medium-offset-1 columns">
                  <img
                    alt="Instructions to verify the ownership of your domain"
                    src="//dashboard.processout.ninja/images/applepay-6.jpg"
                  />
                </div>
              </div>
              {separator}
              <div className="row margin-bottom">
                <div className="medium-6 columns">
                  <p>Enter the domain name.</p>
                </div>
                <div className="medium-5 medium-offset-1 columns">
                  <label>Domain name</label>
                  <input
                    type="text"
                    name="merchant_domain_name"
                    placeholder="Domain name"
                    required
                  />
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="medium-12 columns text-right">
                  <input type="submit" className="button round border small" value="Add domain" />
                </div>
              </div>
            </ContentLayout>
          </div>
        </form>
      </div>
    )
  }
}
type Props = {
  params: {
    project: string
  }
  applePay: {
    fetching: boolean
    fetched: boolean
    keys: any
  }
  currentProject: {
    fetched: boolean
    fetching: boolean
    project: {
      id: string
      applepay_settings: {
        merchant_domain_names: Array<string>
      }
    }
  }
  dispatch: any | (() => void)
}
export default connect(store => ({
  currentProject: store.currentProject,
  applePay: store.applePay,
}))(DomainNameFlow)
