import React from "react"
import { FormattedNumber } from "react-intl"
import Loading from "^/components/Loader"
import Tooltip from "^/util/Tooltip"
import SummaryMainValue from "./SummaryMainValue"
import { getEvolution, getFontColor } from "../../util"
import "./SummaryCard.scss"
import SummaryMoreDetails from "./SummaryMoreDetails"

type Props = {
  title: string
  value: number
  id: string
  tooltipContent: string
  percentageEvolution: number
  valueStyle: string
  loading: boolean
  moreDetails?: boolean
  count?: number
  amount?: number
}
export default function SummaryCard({
  title,
  value,
  id,
  tooltipContent,
  percentageEvolution,
  valueStyle,
  loading,
  moreDetails,
  count,
  amount,
}: Props) {
  const evolutionUpdated = getEvolution(percentageEvolution)
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>{title}</div>
        <Tooltip content={tooltipContent} id={id} size="large" place="top">
          <div
            className="info"
            data-tip
            data-for={id}
            style={{
              display: "inline-block",
            }}
          >
            i
          </div>
        </Tooltip>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="summary-container">
          <div className="summary-main-value">
            <SummaryMainValue valueStyle={valueStyle} value={value} />
            {valueStyle === "percent" && (
              <div
                className="summary-increase-value"
                style={{
                  color: getFontColor(evolutionUpdated),
                }}
              >
                {evolutionUpdated > 0 && "+"}
                {evolutionUpdated === 0 ? (
                  "No change"
                ) : (
                  <FormattedNumber
                    value={evolutionUpdated}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style="percent"
                  />
                )}
              </div>
            )}
          </div>
          {moreDetails && <SummaryMoreDetails count={count} amount={amount} />}
        </div>
      )}
    </>
  )
}
