import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
import type { $WebhooksState } from "./reducer"
import Hook from "./Hook"
import NotPermitted from "../Permissions/NotPermitted"
import Error from "../../components/Error"
import Pagination from "../../components/Pagination"

const serialize = require("form-serialize")

type Props = {
  webhooksDetails: $WebhooksState
} & $Dispatcher

class Webhooks extends React.Component<Props> {
  componentDidMount(): void {
    const { dispatch, location } = this.props
    const afterItem = location.query.webhooks_after_item
    const beforeItem = location.query.webhooks_before_item
    dispatch(Actions.fetchWebhooks(!!afterItem, afterItem || beforeItem))
  }

  create(event) {
    const { dispatch } = this.props
    event.preventDefault()
    const invoiceForm = document.querySelector("#hook-form")
    const data = serialize(invoiceForm, {
      hash: true,
    })
    dispatch(Actions.createWebhook(data.url))
  }

  render() {
    const { webhooksDetails } = this.props
    if (webhooksDetails.fetching || !webhooksDetails.fetched) return null

    if (webhooksDetails.error) {
      if (webhooksDetails.error.notPermitted) return <NotPermitted />
      return <Error />
    }

    let hooksText
    let hooks = <p className="margin-bottom">You havent set any webhook endpoint yet.</p>

    if (webhooksDetails.webhook_endpoints && webhooksDetails.webhook_endpoints.length > 0) {
      let tmp = "one webhook endpoint"
      if (webhooksDetails.webhook_endpoints.length > 1)
        tmp = `${webhooksDetails.total_count} webhook endpoints`
      hooksText = <p>You currently have {tmp}:</p>
      hooks = webhooksDetails.webhook_endpoints.map(hook => <Hook hook={hook} key={hook.id} />)
    }

    return (
      <div>
        <div className="large-3 medium-4 columns">
          <h5>Webhook endpoints</h5>
          <p className="greyed smaller0-8">
            Get programmatically notified directly on your servers using{" "}
            <a href="http://www.webhooks.org/">webhooks</a>.
          </p>
        </div>
        <div className="large-9 medium-8 columns">
          <div className="row">
            <div className="large-12 columns">
              <form id="hook-form" onSubmit={this.create.bind(this)}>
                <div className="row">
                  <div className="large-10 medium-9 columns">
                    <input
                      type="text"
                      name="url"
                      className="bottom-border"
                      placeholder="https://your.site/webhook"
                      required
                    />
                  </div>
                  <div className="large-2 medium-9 columns text-right">
                    <button type="submit" className="button expanded small round border">
                      Create
                    </button>
                  </div>
                </div>
                <div className="row" />
              </form>
            </div>
          </div>
          <div className="row greyed">
            <div className="large-12 columns">
              {hooksText}
              {hooks}
              <Pagination
                name="webhooks"
                reloadFunction={Actions.fetchWebhooks}
                itemsArray={webhooksDetails.fetched ? webhooksDetails.webhook_endpoints : []}
                hasMore={webhooksDetails.fetched ? webhooksDetails.has_more : false}
                hide={webhooksDetails.fetching || !webhooksDetails.fetched}
                location={this.props.location}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  webhooksDetails: store.webhooks,
}))(withRouter(Webhooks))
