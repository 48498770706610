import React, { useContext } from "react"
import { $VelocityRule, RULES_FILTERS } from "^/features/RoutingRules/consts"
import type {
  $Filter,
  $Operand,
} from "../../../features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { RoutingContext } from "../RoutingContext"
import { isBooleanValueType, isFraudServicesValueType } from "../utils"
import Card from "./Card"
import { Pill } from "./Pill"

export type Props = {
  filter: $Filter | $VelocityRule
  onClick: (e: React.MouseEvent, name: string) => void
}

function getReadableOperand(operand: $Operand) {
  if (operand === "===" || operand === "==") {
    return "is"
  } else if (operand === "!==" || operand === "!=") {
    return "is not"
  }
  return operand
}

/**
 * Condition decorates Card component to display necessary information for different rule types.
 */
export const Condition = ({ filter, onClick }: Props) => {
  const [state] = useContext(RoutingContext)

  let name = RULES_FILTERS[filter.path]?.label || filter.path
  // fraud service filter
  if (isFraudServicesValueType(filter.path)) {
    name = "Fraud service"
  }

  let pills: React.ReactNode[] = []
  const pillNames = [name]

  if (!isBooleanValueType(filter.path) && filter.operand) {
    const operand = getReadableOperand(filter.operand)
    pillNames.push(operand)
    pills.push(<Pill key="operand">{operand}</Pill>)
  }

  if (Array.isArray(filter.value)) {
    pills = [
      ...pills,
      filter.value.map(v => {
        pillNames.push(v.toString())
        return <Pill key={v.toString()}>{v.toString()}</Pill>
      }),
    ]
  } else {
    pillNames.push(filter.value.toString())
    pills.push(<Pill key={filter.value.toString()}>{filter.value.toString()}</Pill>)
  }

  // velocity filter
  if ("velocityPath" in filter) {
    pillNames.concat([
      filter.velocityPath,
      getReadableOperand(filter.operand),
      filter.value[0],
      filter.interval,
    ])
    pills = [
      <Pill key="velocityPath">{filter.velocityPath}</Pill>,
      <Pill key="operand">{getReadableOperand(filter.operand)}</Pill>,
      <Pill key="value">{filter.value[0]}</Pill>,
      <Pill key="interval">{filter.interval}</Pill>,
    ]
  }

  return (
    <Card
      cardId={filter.id}
      name={name}
      onClick={e => {
        onClick(e, name)
      }}
      selected={state.selectedCardId === filter.id}
      tooltip={pillNames.join(" ")}
    >
      {pills}
    </Card>
  )
}
export default Condition
