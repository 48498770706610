import * as React from "react"
import Header from "../components/layout/Header"
import NotificationCenter from "../features/NotificationBar/NotificationCenter"
type Props = {
  children: React.ReactNode
}
export default class LayoutNoSideBar extends React.Component<Props> {
  render() {
    const containerStyle = {
      paddingTop: "60px",
    }
    return (
      <div className="colorful main-wrapper">
        <Header isTransparent={false} />
        <div className="row main-content shadowed rounded-top">
          <NotificationCenter />
          <div className="wrapper large-12 columns shadowed rounded-top" style={containerStyle}>
            <div className="panel-content">{this.props.children}</div>
          </div>
        </div>
      </div>
    )
  }
}
