import React from "react"
type Props = {
  title: any
  content: any | null | undefined
  children?: any | null | undefined
}

class Section extends React.PureComponent<Props> {
  render() {
    const { title, content } = this.props
    return (
      <div
        className="row"
        style={{
          paddingLeft: "10px",
        }}
      >
        <div className="large-12 columns">
          <div className="row">
            <div className="medium-4 columns text-left">
              <h4>{title}</h4>
            </div>
            <div className="medium-8 columns text-right">{content}</div>
          </div>
          <div className="row">
            <div className="large-12 columns">{this.props.children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Section
