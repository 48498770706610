import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { CSS } from "react-spring"
import "./Modal.scss"
export type Props = {
  title: string
  children?: React.ReactNode
  open: boolean
  container?: HTMLElement | null
  onClose?: () => void
  onBeforeClose?: () => boolean
  subtitle?: string
  control?: JSX.Element | JSX.Element[]
} & React.HTMLAttributes<HTMLElement>

/**
 * A Modal to handle generic keyboard (focus trapping when using tabs) and rendering logic.
 */
export const Modal = ({
  title,
  children,
  open,
  container = document.body,
  style,
  onClose,
  onBeforeClose = () => true,
  subtitle,
  control,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const restoreFocusTarget = useRef<HTMLElement>(null)

  const [visibility, setVisibility] = useState<CSS.Property.Visibility>(open ? "visible" : "hidden")
  useEffect(() => {
    setVisibility(open ? "visible" : "hidden")
  }, [open])

  // closed by outside via props restore the original focus
  useLayoutEffect(() => {
    if (!open) {
      restoreFocusTarget.current?.focus()
    }
  }, [open])

  if (open) {
    if (!ref.current?.contains(document.activeElement))
      restoreFocusTarget.current = document.activeElement as HTMLElement
  }

  useEffect(() => {
    // Focus and trap keyboard inside modal element when mount so user won't focus anything else outside of the modal
    ref.current?.focus()
  })

  if (!container) return null

  //
  function close() {
    setVisibility("hidden")
    if (restoreFocusTarget.current) restoreFocusTarget.current.focus()
    if (onClose) onClose()
  }

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      tabIndex={0}
      className="modal"
      data-testid="modal"
      style={{
        visibility: visibility,
        ...style,
      }}
      onKeyDown={evt => {
        if (evt.key === "Escape") {
          if (onBeforeClose()) {
            close()
          }
        } else if (evt.key === "Tab") {
          // get all the focusable elements in modal
          const focusableElements = ref.current.querySelectorAll<HTMLElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          )

          if (evt.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === focusableElements[0]) {
              focusableElements[focusableElements.length - 1].focus() // add focus for the last focusable element
              evt.preventDefault()
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === focusableElements[focusableElements.length - 1]) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              focusableElements[0].focus() // add focus for the first focusable element
              evt.preventDefault()
            }
          }
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className="overlay"
        onClick={() => {
          if (onBeforeClose()) {
            close()
          }
        }}
      ></div>
      <div className="window">
        <header className="heading">
          <h1>{title}</h1>
          {subtitle && <p role="doc-subtitle">{subtitle}</p>}
        </header>
        <div className="content">{children}</div>
        {control && <div className="control">{control}</div>}
      </div>
    </div>,
    container,
  )
}
