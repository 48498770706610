import React from "react"
type Props = {
  word: string
}

class PolangValueItem extends React.Component<Props> {
  render() {
    const { word } = this.props
    return (
      <div className="row collapse item-resource-row highlight">
        <div className="medium-12 columns">
          <div className="filter">{word}</div>
        </div>
      </div>
    )
  }
}

export default PolangValueItem
