import { Creatable, Option, Select } from "^/vNext/Select/Select"
import React from "react"
import { Input } from "../../Input/Input"
import { OptionContext } from "../OptionContext"
import { getOperandOptions } from "../utils"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: $Filter
  onMetaDataKeyChanged: (val: string) => void
  onOperandChange: (val: Option) => void
  onValueChange: (val: Option[]) => void
  operand: Option[]
}

export const MetaDataSelection = ({
  condition,
  onMetaDataKeyChanged,
  onOperandChange,
  onValueChange,
  operand,
}: Props) => {
  const optionLookup = React.useContext(OptionContext)
  const currentValue = (condition.value as Array<string | number | boolean>).map(
    (value: string) => ({ label: value, value }),
  )
  const metadata = condition.path.split(".")[1]

  let valueComponent = (
    <Creatable
      aria-label="metadata value"
      isMulti
      value={currentValue}
      isClearable
      onChange={onValueChange}
      options={optionLookup[condition.path]}
    />
  )

  if (operand[0].value === "is-null" || operand[0].value === "is-not-null") {
    valueComponent = null
  }

  return (
    <>
      <Input
        style={{ width: "100%" }}
        aria-label="metadata name"
        value={metadata}
        onChange={e => onMetaDataKeyChanged((e.target as HTMLInputElement).value)}
      />
      <Select
        aria-label="metadata operand"
        options={getOperandOptions(condition.path)}
        value={operand}
        onChange={onOperandChange}
      />
      {valueComponent}
    </>
  )
}
