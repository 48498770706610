import React from "react"
import Region from "./Region"
import type { $AwsRegion, $ProjectType } from "./consts"
// @ts-expect-error
import "./ProjectWizard.scss"
type Props = {
  onChange: (region: $AwsRegion) => void
  value: $AwsRegion | null | undefined
  projectType: $ProjectType | null | undefined
}
export class RegionChoice extends React.Component<Props> {
  render() {
    const { onChange, value, projectType } = this.props
    return (
      <div className="row small-margin-bottom">
        {projectType === "Analytics" ? (
          <div>
            <Region
              onChange={onChange}
              selected={value === "aws-eu-central-1"}
              regionId="aws-eu-central-1"
              regionName="EU central region"
              imageSrc="//dashboard.processout.com/images/europe-map.png"
            />
          </div>
        ) : (
          <div>
            <Region
              onChange={onChange}
              selected={value === "aws-us-east-1"}
              regionId="aws-us-east-1"
              regionName="US east region"
              imageSrc="//dashboard.processout.com/images/us-map.png"
            />
            <Region
              onChange={onChange}
              selected={value === "aws-eu-west-1"}
              regionId="aws-eu-west-1"
              regionName="EU west region"
              imageSrc="//dashboard.processout.com/images/europe-map.png"
            />
          </div>
        )}
      </div>
    )
  }
}
export default RegionChoice
