import { FETCH_PROJECTS, SET_PROJECT_ID } from "^/Actions/ProjectsActions"
import type { $Action, $Project, $State } from "^/util/Types"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type $ProjectsState = {
  projects: Array<$Project>
  current_project_id?: string
  createdProject?: boolean
} & $State
export default function (
  state: $ProjectsState = {
    fetching: false,
    projects: [],
    fetched: false,
  },
  action: $Action,
) {
  switch (action.type) {
    case SET_PROJECT_ID: {
      return { ...state, current_project_id: action.payload.projectId }
    }

    case typePending(FETCH_PROJECTS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_PROJECTS): {
      return { ...state, fetching: false, fetched: true, projects: action.payload.projects }
    }

    case "PROJECT_CREATE_PENDING": {
      return { ...state, creatingProject: true }
    }

    case "PROJECT_CREATE_FULFILLED": {
      return { ...state, creatingProject: false }
    }
  }

  return state
}
