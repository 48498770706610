import type { $GatewayConfiguration } from "./consts"
import type { $Action } from "../../util/Types"
import * as ProcessOut from "../../util/ProcessOut"
export const UDPDATE_GATEWAY_CONFIGURATION = "UDPDATE_GATEWAY_CONFIGURATION"
export function updateGatewayConfiguration(
  configuration: $GatewayConfiguration,
  callback?: (success: boolean) => void,
): $Action {
  const body = { ...configuration }
  return {
    type: UDPDATE_GATEWAY_CONFIGURATION,
    payload: ProcessOut.APIcallPromise(
      `/gateways/${configuration.gateway.name}/gateway-configurations/${configuration.id}`,
      "PUT",
      body,
    )
      .then(response => {
        if (callback) callback(true)
        return response
      })
      .catch(error => {
        if (callback) callback(false)
        return error
      }),
  }
}
