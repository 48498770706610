import clsx from "clsx"
import React, { InputHTMLAttributes } from "react"
import "./Input.scss"

export type Props = InputHTMLAttributes<HTMLInputElement>

export const Input = ({ style, className, value, onChange, ...props }: Props) => {
  return (
    <input
      className={clsx("input-field", className)}
      value={value}
      onChange={onChange}
      style={style}
      {...props}
    />
  )
}
