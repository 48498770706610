import Dispatcher from "../Dispatcher"
import * as ProcessOut from "../util/ProcessOut"
import "whatwg-fetch"
import persistedDataHandler from "^/vNext/persistedDataHandler"

export const FETCH_USER = "FETCH_USER"
export function loadUser2() {
  return function (dispatch) {
    dispatch({
      type: FETCH_USER,
      payload: ProcessOut.APIcallPromise("/users", "GET", null),
    })
  }
}

export const ENABLE_TWO_FACTOR = "ENABLE_TWO_FACTOR"
export function enableTwoFactor(code: number) {
  const body = {
    two_factor_token: code,
  }
  const bodyJson = JSON.stringify(body, null, 2)
  return function (dispatch) {
    return {
      type: ENABLE_TWO_FACTOR,
      payload: ProcessOut.APIcallPromise("/users/two-factor", "POST", bodyJson).then(response => {
        if (response.data.success) dispatch(getTwoFactorData())
      }),
    }
  }
}
export const DISABLE_TWO_FACTOR = "DISABLE_TWO_FACTOR"
export function disableTwoFactor(code: number) {
  const body = {
    two_factor_token: code,
  }
  const bodyJson = JSON.stringify(body, null, 2)
  return function (dispatch) {
    return {
      type: DISABLE_TWO_FACTOR,
      payload: ProcessOut.APIcallPromise("/users/two-factor", "DELETE", bodyJson).then(response => {
        if (response.data.success) dispatch(getTwoFactorData())
      }),
    }
  }
}
export const UPDATE_USER = "UPDATE_USER"
export const USER_EDITED = "USER_EDITED"
export function updateUser(user: any) {
  Dispatcher.dispatch({
    type: UPDATE_USER,
  })
  ProcessOut.APIcall(
    "/users",
    "PUT",
    user,
    json => {
      if (json.success) {
        Dispatcher.dispatch({
          type: USER_EDITED,
          user: json.user,
        })
        ProcessOut.addNotification("Settings updated.", "success")
      }
    },
    ProcessOut.generateHeaders(""),
  )
}
export const LOG_USER_IN = "LOG_USER_IN"
export function generateToken(email: string, password: string, twoFactor: number) {
  let body

  if (twoFactor) {
    body = JSON.stringify(
      {
        email,
        password,
        two_factor_token: twoFactor,
      },
      null,
      2,
    )
  } else {
    body = JSON.stringify(
      {
        email,
        password,
      },
      null,
      2,
    )
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  return {
    type: LOG_USER_IN,
    payload: ProcessOut.APIcallPromise("/authenticate", "POST", body, headers, true)
      .then(response => {
        const { data } = response

        if (data.success) {
          persistedDataHandler.setAuthTokens({
            accessToken: data.token.token || "",
            accessTokenExpiry: data.token.expires_at || "",
            refreshToken: data.token.refresh_token || "",
            refreshTokenExpiry: data.token.refresh_token_expires_at || "",
          })
        }

        return response
      })
      .catch(response => {
        if (response.status === 401) {
          ProcessOut.addNotification("Invalid email/password combination.", "error")
        } else if (response.status === 412) {
          throw response
        }

        throw response
      }),
  }
}
export const FETCH_TWO_FACTOR_DATA = "FETCH_TWO_FACTOR_DATA"
export function getTwoFactorData() {
  return {
    type: FETCH_TWO_FACTOR_DATA,
    payload: ProcessOut.APIcallPromise("/users/two-factor", "GET"),
  }
}
export const RECEIVE_NOTIFICATION = "RECEIVE_NOTIFICATION"
