import { call, put, select, takeLatest } from "redux-saga/effects"
import { push } from "react-router-redux"
import uniqid from "uniqid"
import type { $UpdateAlertBuilderFormAction } from "./actions"
import {
  REQUEST_ALERT_BUILDER_PREVIEW,
  REQUEST_ALERT_SAVE,
  UPDATE_ALERT_BUILDER_FORM,
} from "./actions"
import * as ProcessOut from "../../../util/ProcessOut"
import type { $AlertBuilderState } from "./reducer"
import {
  computeFilterString,
  computeMergedFormula,
  mergeFilters,
} from "../../analytics/DataExplorer/Utils"
import { formatFormula } from "../../analytics/ChartPreviewer/BuilderEffects/actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"

function* updateAlertBuilderForm(action: $UpdateAlertBuilderFormAction): Generator<any, any, any> {
  yield put({
    type: typeFulfilled(UPDATE_ALERT_BUILDER_FORM),
    payload: action.payload,
  })
}

function* fetchAlertPreview(): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(REQUEST_ALERT_BUILDER_PREVIEW),
    })
    const alertBuilder: $AlertBuilderState = yield select<any>(store => store.alertBuilder)
    const fetchResult = yield call(ProcessOut.APIcallPromise, "/alerts/preview", "POST", {
      formula: yield call(rebuildFormula),
    })
    yield put({
      type: typeFulfilled(REQUEST_ALERT_BUILDER_PREVIEW),
      payload: {
        points: fetchResult.data.datapoints[0].datapoints,
      },
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_ALERT_BUILDER_PREVIEW),
      payload: error,
    })
  }
}

function* saveAlert(): Generator<any, any, any> {
  try {
    const alertBuilder: $AlertBuilderState = yield select<any>(store => store.alertBuilder)
    const currentProject = yield select<any>(store => store.currentProject)
    const formula = yield call(rebuildFormula)
    const triggerCondition = `results(0) ${alertBuilder.triggerOperand.value} ${alertBuilder.triggerValue} AND results_count(0) >= ${alertBuilder.minimumCount}`
    const alert = {
      name: alertBuilder.name,
      formula,
      trigger_condition: triggerCondition,
      notification_url: "",
      check_interval: alertBuilder.interval.value,
      chart_type: alertBuilder.selectedMetric.unit,
    }
    yield call(ProcessOut.APIcallPromise, "/alerts", "POST", alert)
    yield put(push(`/projects/${currentProject.project.id}/alerting/alerts`))
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_ALERT_SAVE),
      payload: error,
    })
  }
}

function* rebuildFormula(): Generator<any, any, any> {
  try {
    const alertBuilder: $AlertBuilderState = yield select<any>(store => store.alertBuilder)
    let formula = ""
    const dimensions = []
    // Deep copy metrics
    const duplicatedMetrics = alertBuilder.selectedMetric.metrics.map(m => ({ ...m }))

    // we need to merge metrics filters with global filters
    for (const metric of duplicatedMetrics) {
      metric.filters = mergeFilters(metric.filters, alertBuilder.filters)
    }

    const dataFormula = computeMergedFormula(
      alertBuilder as unknown as $ChartBuilderState,
      alertBuilder.selectedMetric.generalFormula,
      "",
      duplicatedMetrics,
    )

    // Check if we need to add a special dimension
    if (alertBuilder.selectedMetric.forceDimensionPlotting) {
      dimensions.push({
        id: uniqid(),
        field: alertBuilder.selectedMetric.forceDimensionPlotting,
      })
    }

    for (let i = 0; i < dimensions.length; i++) {
      const strategy = dimensions[i].top
        ? `${dimensions[i].strategy ? `strategy: ${dimensions[i].strategy}` : ""};`
        : ""
      const top = dimensions[i].top ? `top: ${dimensions[i].top};` : ""
      const filterString = computeFilterString(alertBuilder.filters).replace(";", "")
      formula = `plot{path:${dimensions[i].field}; ${strategy} ${top} formula: ${
        dataFormula || ""
      }};`
    }

    if (dimensions.length === 0) {
      // no dimensions: this is a single value
      formula = dataFormula
    }

    const formattedFormula = formatFormula(formula)
    return formattedFormula
  } catch (error) {
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(UPDATE_ALERT_BUILDER_FORM, updateAlertBuilderForm)
  yield takeLatest(REQUEST_ALERT_BUILDER_PREVIEW, fetchAlertPreview)
  yield takeLatest(REQUEST_ALERT_SAVE, saveAlert)
}
