import { FETCH_GATEWAYS_CONFIGURATIONS } from "^/Actions/GatewaysActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_GATEWAYS_CONFIGURATIONS): {
      return { ...state, fetching: true, fetched: false, error: false }
    }

    case typeFulfilled(FETCH_GATEWAYS_CONFIGURATIONS): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        count: action.payload.count,
        total_count: action.payload.total_count,
        limit: action.payload.limit,
        page: action.payload.page,
        configurations: action.payload.gateway_configurations,
      }
    }

    case typeFailed(FETCH_GATEWAYS_CONFIGURATIONS): {
      return {
        fetching: false,
        fetched: false,
        error: action.payload,
      }
    }
  }

  return state
}
