import React from "react"
import UniqId from "uniqid"
import type { $GatewayConfiguration } from "../../PaymentProviders/consts"
import type { $SmartRoutingGateway } from "../consts"
import Gateway from "./Gateway"
import SmartRoutingSelector from "./SmartRoutingSelector"

type Props = {
  gateways: any
  value: Array<
    | {
        id: string
        gateway: string
      }
    | $SmartRoutingGateway
  >
  onChange: (
    arg0: Array<{
      id: string
      gateway: string
    }>,
  ) => void
  processors: Array<$GatewayConfiguration> // contains only cards gateways
}

class GatewaysSelection extends React.Component<Props> {
  onChange = (id: string, newValue: string | $SmartRoutingGateway) => {
    const value = this.props.value.slice()
    const index = value.findIndex(g => g.id === id)
    if (index === -1) return // No matching rule found, should not happen.

    // Duplicate the existing selection
    const newVal = { ...this.props.value[index] }

    // If new value selected is Smart Routing
    if (newValue === "processout" || newValue === "processout1") {
      // Set value to smart-routing and select all SR with all configurations by default
      newVal.gateway = newValue
      newVal.configurations = ["all"]
      // Update the existing array
      value[index] = newVal
      // Submit the update
      this.props.onChange(value)
      return
    }

    // We are already in case of Smart-Routing, we update the selected gateways list
    if (newValue.gateway) {
      value[index] = newValue
      this.props.onChange(value)
      return
    }

    // Selected a simple gateway for the rule
    newVal.gateway = newValue
    value[index] = newVal
    this.props.onChange(value)
  }

  render() {
    return (
      <div>
        {" "}
        {this.props.value.map((gateway, index, array) => (
          <div className="row small-margin-bottom" key={gateway.id}>
            <div className="medium-12 columns">
              <div className="row small-margin-bottom">
                <div className="large-7 columns">
                  <Gateway
                    id={gateway.id}
                    gateway={gateway.gateway}
                    gateways={this.props.gateways}
                    onChange={this.onChange}
                    processors={this.props.processors}
                  />
                  {(gateway.gateway === "processout" || gateway.gateway === "processout1") && (
                    <div
                      style={{
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <div
                        className="field-label text-center"
                        style={{
                          width: "25%",
                          borderRadius: "0 0 0 4px",
                        }}
                      >
                        within
                      </div>
                      <SmartRoutingSelector
                        gateways={gateway.configurations}
                        onChange={(newValues: Array<string>) => {
                          this.onChange(gateway.id, { ...gateway, configurations: newValues })
                        }}
                        configurations={this.props.processors}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="large-5 columns"
                  style={{
                    paddingTop: ".5em",
                  }}
                >
                  {array.length > 1 ? (
                    <a
                      data-auto-tracking="true"
                      data-tracking-label="Remove Gateway Button"
                      style={{
                        marginRight: "1em",
                      }}
                      className="greyed small-font"
                      onClick={e => {
                        const value = this.props.value.slice()
                        const index = value.findIndex(v => v.id === gateway.id)

                        if (index > -1) {
                          value.splice(index, 1)
                          this.props.onChange(value)
                        }
                      }}
                    >
                      Remove
                    </a>
                  ) : null}
                  {index === array.length - 1 && array.length < 3 ? (
                    <a
                      data-auto-tracking="true"
                      data-tracking-label="Add Gateway Button"
                      onClick={e => {
                        const unusedGateway = Object.keys(this.props.gateways).find(
                          g => !this.props.value.includes(g),
                        )

                        if (unusedGateway) {
                          const gateways = this.props.value.slice()
                          gateways.push({
                            id: UniqId(),
                            gateway: unusedGateway,
                          })
                          this.props.onChange(gateways)
                        }
                      }}
                    >
                      &#43; add fallback gateway
                    </a>
                  ) : null}
                </div>
              </div>
              {index < array.length - 1 ? (
                <div className="row">
                  <div className="medium-12 columns greyed">
                    If transaction fails then fallback to
                  </div>
                </div>
              ) : array.length === 3 ? (
                <div className="callout alert">
                  <div>
                    The maximum number of retries is limited to 3 to avoid unnecessary retries and
                    any side effects from banks.
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default GatewaysSelection
