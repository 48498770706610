import React from "react"
import VelocityPath from "./VelocityPath"
import VelocityInterval from "./VelocityInterval"
import type { $VelocityRule } from "../../consts"
type Props = {
  filter: $VelocityRule
  onChange: (arg0: $Filter) => void
}

class Velocity extends React.Component<Props> {
  updatePath = (newPath: string) => {
    const { filter, onChange } = this.props
    const newFilter = { ...filter, velocityPath: newPath }
    onChange(newFilter)
  }
  updateInterval = (newInterval: string) => {
    const { filter, onChange } = this.props
    const newFilter = { ...filter, interval: newInterval }
    onChange(newFilter)
  }

  render() {
    const { filter } = this.props
    return (
      <div className="row">
        <div className="medium-12 columns">
          <div className="row">
            <div className="medium-12 columns">
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div
                  className="field-label text-center"
                  style={{
                    width: "25%",
                  }}
                >
                  of
                </div>
                <VelocityPath
                  onChange={this.updatePath}
                  value={filter.velocityPath}
                  disabled={false}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div
                  className="field-label text-center"
                  style={{
                    width: "25%",
                    borderRadius: "0 0 0 4px",
                  }}
                >
                  every
                </div>
                <VelocityInterval onChange={this.updateInterval} value={filter.interval} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Velocity
