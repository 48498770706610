import { FormattedNumber } from "react-intl"
import React from "react"

export const REQUEST_PAYOUTS_FETCH = "REQUEST_PAYOUTS_FETCH"
export type $RequestPayoutsFetchAction = {
  type: string
  payload: {
    length: number
    after: boolean
    id: string | null | undefined
  }
}
export type $RequestPayoutDetailsFetchAction = {
  type: string
  payload: {
    id: string
  }
}
export type $RequestPayoutItemsFetch = {
  type: string
  payload: {
    id: string
    after: boolean
    length: number
    afterId: string | null | undefined
  }
}
export function requestPayoutsFetch(
  length: number,
  after: boolean,
  id: string | null | undefined,
): $RequestPayoutsFetchAction {
  return {
    type: REQUEST_PAYOUTS_FETCH,
    payload: {
      length,
      after,
      id,
    },
  }
}
export const REQUEST_PAYOUT_DETAILS_FETCH = "REQUEST_PAYOUT_DETAILS_FETCH"
export function requestPayoutDetailsFetch(id: string): $RequestPayoutDetailsFetchAction {
  return {
    type: REQUEST_PAYOUT_DETAILS_FETCH,
    payload: {
      id,
    },
  }
}
export const REQUEST_PAYOUT_ITEMS_FETCH = "REQUEST_PAYOUT_ITEMS_FETCH"
export function requestPayoutItemsFetch(
  payoutId: string,
  length: number,
  after: boolean,
  afterId: string | null | undefined,
): $RequestPayoutItemsFetch {
  return {
    type: REQUEST_PAYOUT_ITEMS_FETCH,
    payload: {
      id: payoutId,
      length,
      after,
      afterId,
    },
  }
}
export function formatCurrency(value: string, currency: string): any {
  // FIXME: miss string to number parsing
  // @ts-ignore
  const content = <FormattedNumber value={Math.abs(value)} currency={currency} style="currency" />
  // FIXME: miss string to number parsing
  // @ts-ignore
  if (value < 0) return <div>({content})</div>
  return content
}
