// @ts-expect-error
import "./permissions.scss"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import type { $PermissionsState } from "./reducer"
import type { $Dispatcher, $RouterLocation } from "../../util/Types"
import ContentLayout from "../../pages/ContentLayout"
import CollaboratorsList from "./CollaboratorsList"
import * as Actions from "./actions"
import PermissionGroups from "./PermissionGroups"
import Error from "../../components/Error"
import NotPermitted from "./NotPermitted"
import Loading from "../../components/Loader"
type Props = {
  permissionsDetails: $PermissionsState
  location: $RouterLocation
} & $Dispatcher
export class Permissions extends React.Component<Props> {
  componentDidMount(): void {
    const { dispatch, location } = this.props
    const { after_item, before_item } = location.query
    dispatch(Actions.preparePermissions(!!after_item, after_item || before_item))
  }

  render() {
    const { permissionsDetails } = this.props
    const title = (
      <div className="row">
        <div className="medium-12 columns">
          <h4>Team settings</h4>
        </div>
      </div>
    )
    return (
      <>
        <div className="medium-12 columns">
          {permissionsDetails.global.fetching && <Loading />}
          {permissionsDetails.global.error ? (
            permissionsDetails.global.error.notPermitted ? (
              <ContentLayout title={title}>
                <NotPermitted />
              </ContentLayout>
            ) : (
              <Error />
            )
          ) : (
            permissionsDetails.global.fetched && (
              <div>
                <ContentLayout title={title}>
                  {permissionsDetails.collaborators.error ? (
                    permissionsDetails.collaborators.error.notPermitted ? (
                      <NotPermitted />
                    ) : (
                      <Error />
                    )
                  ) : (
                    <CollaboratorsList />
                  )}
                </ContentLayout>
                {permissionsDetails.permissionGroups.error ? (
                  permissionsDetails.permissionGroups.error.notPermitted ? (
                    <NotPermitted />
                  ) : (
                    <Error />
                  )
                ) : (
                  <PermissionGroups />
                )}
              </div>
            )
          )}
        </div>
      </>
    )
  }
}
export default connect(store => ({
  permissionsDetails: store.permissions,
}))(withRouter(Permissions))
