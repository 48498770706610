import React from "react"
import Select from "react-select"
import { SelectStyle } from "../../analytics/DataExplorer/ChartBuilder/consts"
import type { $Option } from "../../analytics/DataExplorer/consts"
type Props = {
  value: $Option
  onChange: (arg0: $Option) => void
}
const OPTIONS: Array<$Option> = [
  {
    label: ">",
    value: ">",
  },
  {
    label: ">=",
    value: ">=",
  },
  {
    label: "==",
    value: "==",
  },
  {
    label: "<",
    value: "<",
  },
  {
    label: "<=",
    value: "<=",
  },
]

class TriggerSelector extends React.Component<Props> {
  render() {
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label">trigger when value is</div>
        <div
          className="labeled-field"
          style={{
            width: "80px",
          }}
        >
          <Select
            className="react-select"
            value={this.props.value}
            options={OPTIONS}
            styles={SelectStyle}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    )
  }
}

export default TriggerSelector
