import React from "react"
export class BulletNumber extends React.Component<{
  value: number
}> {
  render() {
    return (
      <div
        data-testid="bullet-number"
        className="bold greyed text-center"
        style={{
          borderRadius: "100%",
          border: "1px solid grey",
          width: "1.5em",
          height: "1.5em",
        }}
      >
        {this.props.value}
      </div>
    )
  }
}
