import { put, select, take, takeEvery } from "redux-saga/effects"
import type { $Action } from "../../../../util/Types"
import type { $FetchParams } from "../consts"
import * as Actions from "./actions"
import { REQUEST_CHART_DATA_FETCH } from "./consts"
import { getChart, replaceForCountries, replaceForCurrencies, replaceForGwayKeys } from "./utils"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"

type Action = {
  payload: {
    board: string
    chartId: string
    params: $FetchParams
  }
} & $Action

/*
 * This fetches the chart data for a given id
 * The results are then dispatched
 */
export function* fetchChartData(action: Action): Generator<any, any, any> {
  const { board, chartId, params } = action.payload

  try {
    yield put({
      type: typePending(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
    })
    // We fetch the chart data
    const chartDataResult = yield put.resolve(Actions.fetchChart(board, chartId, params, false))

    // Checking for errors
    if (
      !!chartDataResult.sucess ||
      (chartDataResult.value && !chartDataResult.value.data.success)
    ) {
      yield put({
        type: typeFailed(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
      })
      return
    }

    // Retrieving the payload object
    const chartData = chartDataResult.value.data
    // we fetch the gateway ids and names list from the store.
    let gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)

    // if we don't have them we wait for it
    while (!gway_configurations_names.fetched) {
      yield take()
      gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)
    }

    // we update the charts keys with gateway names
    chartData.data = replaceForGwayKeys(
      chartData.data,
      gway_configurations_names.gateway_configuration_names,
    )
    // we update the charts keys with full country names
    chartData.data = replaceForCountries(chartData.data)
    // And for currencies
    chartData.data = replaceForCurrencies(chartData.data)

    for (const entry of chartData.data) {
      // we do the same for subkeys
      if (entry.datapoints) {
        entry.datapoints = replaceForGwayKeys(
          entry.datapoints,
          gway_configurations_names.gateway_configuration_names,
        )
        if (chartData.chart.settings.formula.includes("country"))
          entry.datapoints = replaceForCountries(entry.datapoints)
        if (chartData.chart.settings.formula.includes("currency"))
          entry.datapoints = replaceForCurrencies(entry.datapoints)
      }
    }

    const magicResult = getChart(chartData, params)

    yield put({
      type: typeFulfilled(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
      payload: {
        ...chartData,
        data: magicResult.data,
        chart: {
          ...chartData.chart,
          timeCompareAndDimensions: magicResult.timeCompareAndDimensions,
          is_comparison: magicResult.is_comparison,
        },
        hadToTrimData: magicResult.hadToTrim,
      },
    })
  } catch (error) {
    yield put({
      type: typeFailed(`${REQUEST_CHART_DATA_FETCH}_${chartId}`),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

export default function* watchFetchChartRequest(): Generator<any, any, any> {
  yield takeEvery(REQUEST_CHART_DATA_FETCH, fetchChartData)
}
