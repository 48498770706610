import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import ContentLayout from "./ContentLayout"
import * as TransactionsActions from "../Actions/TransactionsActions"
import Transaction from "../components/Transaction"
import Loader from "../components/Loader"
import Empty from "../components/Empty"
import Error from "../components/Error"
import Pagination from "../components/Pagination"
import NotPermitted from "../features/Permissions/NotPermitted"
import TransactionsExporter from "../features/Exports/TransactionsExporter"
import * as Actions from "../features/SearchBar/actions"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
import type { TransactionsState } from "^/stores/Reducers/CustomerDetailsReducer/TransactionsReducer"
import { track } from "^/vNext/tracking"
const transactionsPerPage = 30
type Props = {
  location: $RouterLocation
  transactions: TransactionsState
} & $Dispatcher

class Transactions extends React.Component<Props> {
  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Transactions | ProcessOut"
    // we check that there is an afterItem parameter
    // if not then we are going backward and there should be a before item
    const afterItem = this.props.location.query.after_item
    const beforeItem = this.props.location.query.before_item
    const { filter } = this.props.location.query
    this.applyFilter(filter, false, afterItem || beforeItem, !beforeItem)
    // Set the document for filter button
    this.props.dispatch(Actions.setFilteringDocument("transactions"))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldFilter = this.props.location.query.filter
    const newFilter = nextProps.location.query.filter

    if (oldFilter !== newFilter) {
      this.applyFilter(newFilter, true)
      return
    }

    let firstItem = this.props.location.query.after_item
    if (!firstItem) firstItem = ""
    if (
      !nextProps.transactions.fetching &&
      !nextProps.transactions.fetched &&
      !nextProps.transactions.error
    )
      this.props.dispatch(
        TransactionsActions.loadTransactions(
          this.props.location.query.filter,
          transactionsPerPage,
          true,
          firstItem,
        ),
      )
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentWillUnmount(): void {
    this.props.dispatch(Actions.setFilteringDocument(null))
  }

  applyFilter = (filter: string, reset: boolean, firstItem: string, forward?: boolean) => {
    this.props.dispatch(
      TransactionsActions.loadTransactions(
        filter,
        transactionsPerPage,
        forward !== null ? forward : true,
        reset ? "" : firstItem,
      ),
    )
  }

  render() {
    const { transactions } = this.props.transactions
    let header
    let content

    if (
      (this.props.transactions.fetching || !this.props.transactions.fetched) &&
      !this.props.transactions.error
    ) {
      content = <Loader />
    } else if (this.props.transactions.error) {
      if (this.props.transactions.error.notPermitted) content = <NotPermitted />
      else content = <Error />
    } else {
      let empty

      if (transactions.length === 0) {
        if (this.props.location.query.filter !== "") {
          empty = <Empty text="No transactions match your search." />
        } else {
          empty = <Empty text="You haven't processed any transaction yet." />
        }
      }

      if (transactions.length > 0) {
        header = (
          <div
            className="row capitalize greyed bold small-margin-bottom"
            style={{
              fontSize: "0.8em",
            }}
          >
            <div className="medium-2 columns">Status</div>
            <div className="medium-3 columns">
              <span
                style={{
                  marginLeft: "-1em",
                }}
              >
                Price
              </span>
            </div>
            <div
              className="medium-3 columns"
              style={{
                marginLeft: "-1em",
              }}
            >
              Name
            </div>
            <div className="medium-2 columns">Customer</div>
            <div className="medium-2 columns text-right">Date</div>
          </div>
        )
      }

      content = (
        <div>
          <div className="row">
            <div className="large-12 columns">
              {empty}
              {header}

              <ReactTooltip effect="solid" />
              {transactions.map(transaction => (
                <Transaction transaction={transaction} key={transaction.id} />
              ))}
            </div>
          </div>
        </div>
      )
    }

    const title = (
      <div className="row ">
        <div className="large-9 columns">
          <h4>Transactions</h4>
        </div>
        <div className="large-3 columns text-right">
          <TransactionsExporter />
        </div>
      </div>
    )
    return (
      <ContentLayout title={title}>
        <div className="row">
          <div className="large-12 columns">
            {content}
            <div className="row">
              <div className="large-12 columns">
                <Pagination
                  reloadFunction={TransactionsActions.loadTransactions.bind(
                    null,
                    this.props.location.query.filter,
                    transactionsPerPage,
                  )}
                  itemsArray={this.props.transactions.fetched ? transactions : []}
                  hasMore={
                    !this.props.transactions.fetched ? false : this.props.transactions.has_more
                  }
                  location={this.props.location}
                  hide={this.props.transactions.fetching || !this.props.transactions.fetched}
                  isFirstPage={this.props.location.query.after_item == null}
                  onPrevious={() => track("Transactions", "Click", "Pagination", "Previous")}
                  onNext={() => track("Transactions", "Click", "Pagination", "Next")}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  transactions: store.transactions,
}))(Transactions)
