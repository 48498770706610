import { call } from "redux-saga/effects"
import {
  AUTH_PER_COUNTRY_FORMULA,
  AUTH_PER_PSP_BAR,
  AUTH_PER_PSP_PER_CARD_SCHEME_BAR,
  AUTH_PER_PSP_PER_CARD_TYPE_BAR,
  AUTHORIZED_AMOUNT_FORMULA,
  AUTHORIZED_TRANSACTIONS_FORMULA,
  FAILED_TRANSACTIONS_FORMULA,
  GLOBAL_AUTH_FORMULA,
} from "../consts"
import { datadogRum } from "@datadog/browser-rum"
import { fetchTelescopeChartData, formatBarChart } from "^/features/Telescope/Sagas/common"
export function* fetchGlobalAuth(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, GLOBAL_AUTH_FORMULA, timespan)
    return {
      global_authorization_rate: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthCountry(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, AUTH_PER_COUNTRY_FORMULA, timespan)
    return {
      authPerCountry: chartData.map(country => ({ ...country, authorization: country.value })),
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthorizedTransactions(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, AUTHORIZED_TRANSACTIONS_FORMULA, timespan)
    return {
      transactions_authorized: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthorizedAmount(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, AUTHORIZED_AMOUNT_FORMULA, timespan)
    return {
      authorized_amount: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchFailedTransactions(timespan: string): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, FAILED_TRANSACTIONS_FORMULA, timespan)
    return {
      failed_transactions: chartData[0].value,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthorizationPerPspBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    let chartData = yield call(fetchTelescopeChartData, AUTH_PER_PSP_BAR, timespan)
    chartData = chartData.map(point => {
      const gatewayConfig = gatewayConfigurations.find(config => config.id === point.key)
      if (!gatewayConfig) return null
      return { ...point, key: gatewayConfig.name }
    })
    if (chartData.includes(null)) return null
    return {
      authorization_rate_per_psp_bar: formatBarChart(chartData),
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthorizationPerPspPerCardSchemeBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    const chartData = yield call(
      fetchTelescopeChartData,
      AUTH_PER_PSP_PER_CARD_SCHEME_BAR,
      timespan,
    )

    if (chartData.includes(null)) {
      return null
    }

    return {
      authorization_rate_per_psp_per_card_scheme_bar: chartData,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
export function* fetchAuthorizationPerPspPerCardTypeBar(
  timespan: string,
  gatewayConfigurations: Array<{
    id: string
    name: string
  }>,
): Generator<any, any, any> {
  try {
    const chartData = yield call(fetchTelescopeChartData, AUTH_PER_PSP_PER_CARD_TYPE_BAR, timespan)
    if (chartData.includes(null)) return null
    return {
      authorization_rate_per_psp_per_card_type_bar: chartData,
    }
  } catch (error) {
    datadogRum.addError(error)
    return null
  }
}
