import type {
  $Flags,
  $LaunchDarklyClient,
  $LaunchDarklyIdentifyUserAction,
  $LaunchDarklyInitFailedAction,
  $LaunchDarklyInitFulfilledAction,
  $LaunchDarklyInitPendingAction,
  $LaunchDarklySetFlagsAction,
  $LaunchDarklyUser,
  $LaunchDarklyUserIdentifiedAction,
} from "./types"
import {
  LAUNCHDARKLY_INIT_FAILED,
  LAUNCHDARKLY_INIT_FULFILLED,
  LAUNCHDARKLY_INIT_PENDING,
  LAUNCHDARKLY_SET_FLAGS,
  LAUNCHDARKLY_USER_IDENTIFIED,
  LAUNCHDARKLY_USER_IDENTIFY,
} from "./consts"

export type $LaunchDarklyInitialiseState =
  | "uninitialised"
  | "initialising"
  | "initialised"
  | "failed"
export type $LaunchDarklyState = {
  client: $LaunchDarklyClient | void
  initialiseState: {
    state: $LaunchDarklyInitialiseState
    error?: string
  }
  identifyingUser: boolean
  userIdentified: boolean
  user: $LaunchDarklyUser | void
  flags: $Flags
}
type $LaunchDarklyReducerAction =
  | $LaunchDarklyInitPendingAction
  | $LaunchDarklyInitFulfilledAction
  | $LaunchDarklyInitFailedAction
  | $LaunchDarklyUserIdentifiedAction
  | $LaunchDarklyIdentifyUserAction
  | $LaunchDarklySetFlagsAction
export const defaultState: $LaunchDarklyState = {
  client: undefined,
  initialiseState: {
    state: "uninitialised",
  },
  identifyingUser: false,
  userIdentified: false,
  user: undefined,
  flags: {
    "dashboard-test-flag": false,
    "dashboard-telescope-v2": false,
    "dashboard-routing-rules-v2": false,
    "dashboard-show-jimo": false,
    "new-dash-transactions-page": false,
    "new-dash-customers-page": false,
    "new-dash-exports-page": false,
    "new-dash-payouts-page": false,
    "new-dash-api-setup-page": false,
    "new-dash-events-page": false,
    "new-dash-uploads-page": false,
    "new-dash-providers-page": false,
    "new-dash-payment-links-page": false,
    "new-dash-routing-page": false,
    "new-dash-apple-pay": false,
  },
}
export default function reducer(
  state: $LaunchDarklyState = defaultState,
  action: $LaunchDarklyReducerAction,
): $LaunchDarklyState {
  switch (action.type) {
    case LAUNCHDARKLY_INIT_PENDING:
      return {
        ...state,
        initialiseState: {
          state: "initialising",
        },
      }

    case LAUNCHDARKLY_INIT_FULFILLED:
      return {
        ...state,
        client: action.payload.client,
        initialiseState: {
          state: "initialised",
        },
      }

    case LAUNCHDARKLY_INIT_FAILED:
      return {
        ...state,
        initialiseState: {
          state: "failed",
          error: action.payload.error,
        },
      }

    case LAUNCHDARKLY_USER_IDENTIFY:
      return { ...state, identifyingUser: true }

    case LAUNCHDARKLY_USER_IDENTIFIED:
      return { ...state, identifyingUser: false, userIdentified: true, user: action.payload.user }

    case LAUNCHDARKLY_SET_FLAGS:
      return { ...state, flags: { ...state.flags, ...action.payload.flags } }
  }

  return state
}
