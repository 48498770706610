import React from "react"
import { DYNAMIC_PARAMS } from "^/features/RoutingRules/consts"
import { Option, Select } from "^/vNext/Select/Select"
import { toReactSelectValue } from "../../utils"

type Props = {
  value: unknown
  onValueChange: (change: Option) => void
}

export const ExemptionSelection = ({ value, onValueChange }: Props) => {
  const options = DYNAMIC_PARAMS["sca_exemption_reason"]

  return (
    <Select
      aria-label="exemption reason"
      value={toReactSelectValue(value)}
      options={options}
      onChange={onValueChange}
    />
  )
}
