import React from "react"
import Select from "react-select"
import * as PlansActions from "../../Actions/PlansActions"
import { connect } from "react-redux"

class PlanSelect extends React.Component {
  constructor() {
    super()
    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    this.props.dispatch(PlansActions.fetchPlans())
  }

  planSelected(value) {
    this.setState({
      value,
    })
  }

  render() {
    const { plans } = this.props.plansDetails
    const fetching = !this.props.plansDetails.fetched && this.props.plansDetails.fetching
    let options = []

    if (!fetching && this.props.plansDetails.fetched) {
      options = plans.map(plan => ({
        value: plan.id,
        label: plan.name,
      }))
    }

    return (
      <Select
        className="react-select"
        name="plan_id"
        placeholder="Use an existing plan"
        options={options}
        isLoading={fetching}
        value={this.state.value}
        onChange={this.planSelected.bind(this)}
      />
    )
  }
}

export default connect(store => ({
  plansDetails: store.plans,
}))(PlanSelect)
