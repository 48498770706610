import React from "react"
import { Link } from "react-router"
import { connect } from "react-redux"
import * as ProcessOut from "../util/ProcessOut"
import type { Customer } from "../Types/Customer"
import type { $Project } from "../util/Types"
import { track } from "../vNext/tracking"
import { $LaunchDarklyState } from "../vNext/LaunchDarkly/LaunchDarklyReducer"

const md5 = require("js-md5")

export class CustomerComponent extends React.Component {
  props: {
    customer: Customer
    currentProject: {
      project: $Project
    }
    launchDarkly: $LaunchDarklyState
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { customer, launchDarkly } = this.props

    const linkContent = (
      <div className="box-row padding row">
        <div className="medium-1 columns">
          <img
            alt="Customer`s avatar"
            className="gravatar-icon"
            src={`https://secure.gravatar.com/avatar/${md5(
              customer.email ? customer.email : "",
            )}?s=80&r=g&d=identicon`}
          />
        </div>
        <div className="medium-11 columns">
          {customer.first_name ? customer.first_name : ""}{" "}
          {customer.last_name ? customer.last_name : ""}
          {!customer.last_name && !customer.first_name ? "Anonymous user" : ""}
          <br />
          <span className="greyed">{customer.email ? customer.email : "No email provided"}</span>
        </div>
      </div>
    )

    const onClickHandler = () => {
      track("Customers", "Click", "Row", "Customer table")
    }

    return launchDarkly.flags["new-dash-customers-page"] ? (
      <a
        href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${ProcessOut.config.ProjectId}/customers/${customer.id}`}
        target="_self"
        onClick={onClickHandler}
      >
        {linkContent}
      </a>
    ) : (
      <Link
        to={`/projects/${ProcessOut.config.ProjectId}/customers/${customer.id}`}
        onClick={onClickHandler}
      >
        {linkContent}
      </Link>
    )
  }
}
export default connect(store => ({
  currentProject: store.currentProject,
  launchDarkly: store.launchDarkly,
}))(CustomerComponent)
