import * as React from "react"
import ChartHeader from "../components/ChartHeader"
import type { $ChartDatasFromApi } from "../consts"
import LineChart from "../visualisations/LineChart"
import { getDatasPerDay, getLegend } from "../visualisations/util"

type Props = {
  datas: { data: $ChartDatasFromApi[]; chartTitle: string }[]
}
export default function CaptureAndVoidsLineCharts({ datas }: Props) {
  const formattedData = datas.map(data => getDatasPerDay(data.data))
  return (
    <>
      <ChartHeader
        legend={getLegend(datas)}
        tooltip={{
          content: "Chart comparing the number of voided transactions vs the failed captures",
          id: "captureAndVoids",
        }}
        chartTitle="Capture & voids"
      />
      <LineChart datas={formattedData} valueStyle="decimal" name="Capture & voids" />
    </>
  )
}
