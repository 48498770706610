import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer"
import type { $FeesRecommendation } from "../reducer"
import Advice from "../../../components/Telescope/Advice"
type Props = {
  telescope: $TelescopeState
}

class FeesRecommendations extends React.Component<Props> {
  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          {this.props.telescope.standard.data.fees_recommendations.map(reco => (
            <Recommendation key={uniqid()} recommendation={reco} />
          ))}
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
}))(FeesRecommendations)

class Recommendation extends React.PureComponent<{
  recommendation: $FeesRecommendation
}> {
  render() {
    const { recommendation } = this.props
    return <Advice {...recommendation} />
  }
}
