import clsx from "clsx"
import * as React from "react"
import "./Card.scss"
import { CARD_WIDTH } from "./consts"
import iconPath from "./globe.svg"

export type Props = {
  name: string
  children: React.ReactNode
  onClick: React.MouseEventHandler
  selected: boolean
  className?: string
  cardId: string
  tooltip: string
}

/**
 * Base condition(filter) component to handle layout
 */
const Card = ({ className, children, name, onClick, selected, cardId, tooltip }: Props) => {
  const filterRef = React.useRef<HTMLDivElement>(null)

  return (
    <div className="card-slot">
      <button
        // Only show tooltip when the filters are out of bounds of the card
        // 16 is the padding
        data-card-tooltip={filterRef.current?.scrollWidth > CARD_WIDTH - 16 ? tooltip : undefined}
        tabIndex={0}
        data-card-id={cardId}
        className={clsx("card", className, { selected })}
        onClick={onClick}
        data-testid="card"
      >
        <div className="card-heading">
          <img alt="globe icon" src={iconPath}></img>
          <span>{name}</span>
        </div>
        <div className="filters" ref={filterRef}>
          {children}
        </div>
      </button>
    </div>
  )
}

export default Card
