import * as React from "react"
import type { $FraudConfig } from "^/features/FraudServicesConfiguration/consts"
import { $GatewayConfiguration } from "^/features/PaymentProviders/consts"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { getRoutingRules } from "^/features/RoutingRules/Utils"
import type {
  $Declaration,
  $RoutingRule,
  $RoutingRulesSettings,
} from "../../features/RoutingRules/consts"
import { Option } from "../Select/Select"
import { TabGroup } from "../Tab/TabGroup"
import { Blocking } from "./Blocking"
import { Dynamic3DS } from "./Dynamic3DS"
import { FraudServiceContext } from "./FraudServiceContext"
import { GatewayContext } from "./GatewayContext"
import { OptionContext } from "./OptionContext"
import { Routing } from "./Routing"
import "./Routing.scss"
import { Trigger3DS } from "./Trigger3DS"

type Props = {
  selectedTabIndex: number
  routingRulesSettings: $RoutingRulesSettings
  hasChanges: boolean
  asideCollapsed?: boolean
  fraudServices: Array<$FraudConfig>
  gatewayNames: Record<string, string>
  gatewayConfigurations: Array<$GatewayConfiguration>
  options: Record<string, Option[]>
  onSaveBtnClick?: React.MouseEventHandler
  onCancelValidation?: (callback: (confirmed: boolean) => void) => void
  onAddRuleBtnClick?: (declaration: $Declaration) => void
  onCodeEditorBtnClick?: React.MouseEventHandler
  onUpdateRule?: (rule: $RoutingRule) => void
  onSortEnd?: (oldIndex: number, newIndex: number, declaration: $Declaration) => void
  onRemoveRule?: (id: string) => void
  cloneRule?: (id: string) => void
  onRouteSelection?: (index: number) => void
  onRemoveCondition?: (routingRule: $RoutingRule, filterId: string) => void
  // Allow fetching the condition options when condition is added
  fetchOptions: (filter: $Filter[]) => void
  prepareOptions: (declaration: $Declaration) => void
  onTabChange: (tabIndex) => void
}

const TAB_LABELS = ["Blocking", "Trigger 3DS", "Dynamic 3DS", "Routing"]

const getDeclaration = (tabLabel: string) => {
  switch (tabLabel) {
    case "Routing":
      return "route"
    case "Trigger 3DS":
      return "trigger_3ds"
    case "Dynamic 3DS":
      return "dynamic_3ds"
    case "Blocking":
      return "block"
    default:
      return "route"
  }
}

/**
 * The root container for all the visual elements under routing rule sections, e.g., aside sidebar, main routing lane, etc.
 */
export const App = ({
  selectedTabIndex,
  routingRulesSettings,
  hasChanges,
  gatewayNames,
  gatewayConfigurations,
  options,
  onSaveBtnClick,
  onCancelValidation,
  onAddRuleBtnClick,
  onSortEnd,
  onRemoveRule,
  cloneRule,
  onUpdateRule,
  fetchOptions,
  fraudServices,
  prepareOptions,
  onTabChange,
}: Props) => {
  const [editing, setEditing] = React.useState(false)
  const onModeChanged = (value: boolean) => {
    setEditing(value)
  }
  const getContent = (label: string) => {
    switch (label) {
      case "Routing":
        return (
          <Routing
            routingRules={getRoutingRules(routingRulesSettings, "route")}
            hasChanges={hasChanges}
            onSaveBtnClick={onSaveBtnClick}
            onCancelValidation={onCancelValidation}
            onAddRuleBtnClick={() => onAddRuleBtnClick("route")}
            onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, "route")}
            onRemoveRule={onRemoveRule}
            cloneRule={cloneRule}
            onUpdateRule={onUpdateRule}
            fetchOptions={fetchOptions}
            editing={editing}
            onModeChanged={onModeChanged}
          />
        )
      case "Trigger 3DS":
        return (
          <Trigger3DS
            routingRules={getRoutingRules(routingRulesSettings, "trigger_3ds")}
            hasChanges={hasChanges}
            onSaveBtnClick={onSaveBtnClick}
            onCancelValidation={onCancelValidation}
            onAddRuleBtnClick={() => onAddRuleBtnClick("trigger_3ds")}
            onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, "trigger_3ds")}
            onRemoveRule={onRemoveRule}
            cloneRule={cloneRule}
            onUpdateRule={onUpdateRule}
            fetchOptions={fetchOptions}
            editing={editing}
            onModeChanged={onModeChanged}
          />
        )
      case "Blocking":
        return (
          <Blocking
            routingRules={getRoutingRules(routingRulesSettings, "block")}
            hasChanges={hasChanges}
            onSaveBtnClick={onSaveBtnClick}
            onCancelValidation={onCancelValidation}
            onAddRuleBtnClick={() => onAddRuleBtnClick("block")}
            onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, "block")}
            onRemoveRule={onRemoveRule}
            cloneRule={cloneRule}
            onUpdateRule={onUpdateRule}
            fetchOptions={fetchOptions}
            editing={editing}
            onModeChanged={onModeChanged}
          />
        )
      case "Dynamic 3DS":
        return (
          <Dynamic3DS
            routingRules={getRoutingRules(routingRulesSettings, "dynamic_3ds")}
            hasChanges={hasChanges}
            onSaveBtnClick={onSaveBtnClick}
            onCancelValidation={onCancelValidation}
            onAddRuleBtnClick={() => onAddRuleBtnClick("dynamic_3ds")}
            onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex, "dynamic_3ds")}
            onRemoveRule={onRemoveRule}
            cloneRule={cloneRule}
            onUpdateRule={onUpdateRule}
            fetchOptions={fetchOptions}
            editing={editing}
            onModeChanged={onModeChanged}
          />
        )
      case "Code editor":
        return null
      default:
        return null
    }
  }

  React.useEffect(() => {
    prepareOptions(getDeclaration(TAB_LABELS[selectedTabIndex]))
  }, [selectedTabIndex])

  return (
    <div className="routing-rules routing-v2">
      <OptionContext.Provider value={options}>
        <GatewayContext.Provider value={{ gatewayNames, gatewayConfigurations }}>
          <FraudServiceContext.Provider value={fraudServices}>
            <TabGroup
              defaultSelectedIndex={selectedTabIndex}
              tabLabels={TAB_LABELS}
              onSelection={onTabChange}
            ></TabGroup>
            {getContent(TAB_LABELS[selectedTabIndex])}
          </FraudServiceContext.Provider>
        </GatewayContext.Provider>
      </OptionContext.Provider>
    </div>
  )
}
