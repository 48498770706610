export const REQUEST_FETCH_COLLABORATORS = "REQUEST_FETCH_COLLABORATORS"
export type $RequestFetchCollaboratorsAction = {
  type: "REQUEST_FETCH_COLLABORATORS"
  payload: {
    after: boolean
    id: string
  }
}
export function requestFetchCollaborators(
  after: boolean,
  id: string,
): $RequestFetchCollaboratorsAction {
  return {
    type: REQUEST_FETCH_COLLABORATORS,
    payload: {
      after,
      id,
    },
  }
}
