import React from "react"
import { connect } from "react-redux"
import type { $DataExplorerState } from "./reducer"
import Error from "../../../components/Error"
import Chart from "../Boards/charts/Chart"
import Empty from "../../../components/Empty"
import type { $Dispatcher } from "../../../util/Types"
import * as Actions from "./actions"
import * as Utils from "./Utils"
import type { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"

type Props = {
  dataExplorer: $DataExplorerState
  chartBuilder: $ChartBuilderState
  hideName: boolean | null | undefined
  presetPreviewer: boolean | null | undefined
} & $Dispatcher
export class Previewer extends React.Component<Props> {
  preview = () => {
    const { dispatch, dataExplorer, chartBuilder, analytics } = this.props
    dispatch(
      Actions.requestChartFetch(
        dataExplorer.formula,
        dataExplorer.name || chartBuilder.selectedMetric.name,
        dataExplorer.type,
        dataExplorer.unit,
        analytics.params,
      ),
    )
  }

  render() {
    const { dataExplorer, chartBuilder } = this.props
    const empty = (
      <Empty
        text={
          !chartBuilder.selectedMetric ? (
            <div>
              Click the <a onClick={this.preview}>preview </a>button to start displaying data
            </div>
          ) : (
            "No chart to render, start by selecting a preset"
          )
        }
      />
    )
    const chartOutdated =
      !Utils.areFormulasEqual(dataExplorer.previewedFormula, dataExplorer.formula) ||
      (dataExplorer.chart.fetched &&
        !dataExplorer.chart.fetching &&
        !dataExplorer.chart.error &&
        (dataExplorer.chart.chart.type !== dataExplorer.type ||
          dataExplorer.chart.chart.unit !== dataExplorer.unit))
    return (
      <div className="row small-margin-bottom">
        <div
          className="large-12 columns"
          style={{
            minHeight: "200px",
          }}
        >
          {!this.props.presetPreviewer || dataExplorer.selectedPreset ? (
            dataExplorer.chart.error ? (
              <Error />
            ) : dataExplorer.chart.fetched ? (
              <div>
                {chartOutdated ? (
                  <div
                    className="outdated-warning text-center"
                    onClick={this.preview}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    This chart is outdated, please click here to refresh it.
                  </div>
                ) : null}

                <div className={`previewed-chart ${chartOutdated ? "outdated-chart" : ""}`}>
                  <Chart
                    chart={dataExplorer.chart.chart}
                    board={{
                      board: {
                        id: "board_default-sales",
                      },
                    }}
                    loading={dataExplorer.chart.fetching}
                    preview
                    fetched
                    canDelete={false}
                    canEdit={false}
                    hideName={this.props.hideName}
                  />
                </div>
              </div>
            ) : (
              empty
            )
          ) : (
            empty
          )}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  chartBuilder: store.chartBuilder,
  dataExplorer: store.dataExplorer,
  analytics: store.analytics,
}))(Previewer)
