import * as React from "react"
type Props = {
  children: React.ReactNode
  value: number
  index: number
}
export default function TabPanel({ children, value, index }: Props) {
  return (
    <div
      data-testid={`tab panel ${index}`}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
