import * as ApplePayActions from "../../Actions/ApplePayActions"
import { FETCH_PROJECT_DETAILS, SET_PROJECT_ID } from "^/Actions/ProjectsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  } as any,
  action,
) {
  switch (action.type) {
    case SET_PROJECT_ID: {
      return { ...state, fetching: false, fetched: false, project: null }
    }

    case typePending(FETCH_PROJECT_DETAILS): {
      return { ...state, fetching: true, fetched: false, error: false }
    }

    case typeFulfilled(FETCH_PROJECT_DETAILS): {
      return { ...state, ...action.payload, fetched: true, fetching: false }
    }

    case typeFailed(FETCH_PROJECT_DETAILS): {
      return { ...state, error: true, fetching: false, fetched: false }
    }

    case typeFulfilled(ApplePayActions.CREATE_KEYS_APPLE_PAY): {
      return {
        ...state,
        error: false,
        fetching: false,
        fetched: true,
        project: {
          ...state.project,
          applepay_settings: {
            ...state.project.applepay_settings,
            merchant_csr: action.payload.keys.merchant_csr,
            processing_csr: action.payload.keys.processing_csr,
            merchant_domain_names: state.merchant_domain_names || [],
          },
        },
      }
    }

    case typeFulfilled(ApplePayActions.DISABLE_APPLE_PAY):
    case typeFulfilled(ApplePayActions.ENABLE_APPLE_PAY): {
      return { ...state, project: { ...state.project, ...action.payload.project } }
    }
  }

  return state
}
