import type { $Action, $State } from "^/util/Types"
import { FETCH_DUNNING_SETTINGS } from "^/Actions/ProjectsActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export type $DunningConfiguration = {
  action: string
  delay_in_days: string
}
export type $DunningSettingsState = {
  dunning_configurations: Array<$DunningConfiguration>
} & $State
const defaultState: $DunningSettingsState = {
  fetching: false,
  fetched: false,
  dunning_configurations: [],
}
export default function (state: $DunningSettingsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_DUNNING_SETTINGS): {
      return { ...state, fetched: false, fetching: true }
    }

    case typeFulfilled(FETCH_DUNNING_SETTINGS): {
      return {
        ...state,
        dunning_configurations: action.payload.project.dunning_configuration,
        fetched: true,
        fetching: false,
      }
    }
  }

  return state
}
