import React from "react"
import { connect } from "react-redux"
import type { $TelescopeStateType } from "../reducer"
import ChargebackChart from "../Partials/ChargebackChart"
// @ts-expect-error
import "../telescope.scss"
type Props = {
  telescope: $TelescopeStateType
}

class MastercardChargebackProgram extends React.Component<Props> {
  shouldComponentUpdate = (nextProps: Props) => nextProps.telescope.data.chargebacksData.ready

  render() {
    const chargeback = this.props.telescope.data.chargebacksData
    if (!chargeback) return null
    const program = chargeback.chargebackPrograms.mastercard
    if (!program) return null
    return (
      <div className="row chargeback-panel">
        <div className="large-12 columns">
          <div className="row">
            {chargeback.chargebackThresholds[program.name].metrics.map((tr, index, arr) => (
              <div className={`large-${arr.length === 2 ? 6 : 4} columns`} key={index}>
                <ChargebackChart
                  currentValue={chargeback[`mastercardChargeback${tr}`]}
                  maxValue={chargeback.chargebackThresholds[program.name][tr.toLowerCase()]}
                  name={`Chargeback ${tr.toLowerCase()}`}
                  style={tr.toLowerCase() === "rate" ? "percent" : "decimal"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope.standard,
}))(MastercardChargebackProgram)
