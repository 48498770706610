import React from "react"
import { $Coupon } from "^/stores/Reducers/CouponsReducer"
import MData from "./MData"

type Props = {
  resource: $Coupon
}
export default class Metadata extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.bindData(this.props)
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    this.updateMetaForm()
  }

  componentWillUnmount() {
    this.setState({
      data: [],
    })
  }

  bindData(props) {
    const arr = []
    let data

    if (props.resource) {
      data = Object.keys(props.resource.metadata)

      for (const meta of data)
        arr.push({
          key: meta,
          value: props.resource.metadata[meta],
        })
    }

    this.setState({
      data: arr,
    })
  }

  addData(event) {
    if (event) {
      event.preventDefault()
    }

    const data = this.updateValue()
    data.push({
      key: "",
      value: "",
    })
    this.setState({
      data,
    })
  }

  updateValue() {
    const arr = []
    const metas = document.getElementById("metas")
    const keys = metas.getElementsByClassName("key")
    const values = metas.getElementsByClassName("value")

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].value != "" && values[i].value != "")
        arr.push({
          key: keys[i].value,
          value: values[i].value,
        })
    }

    return arr
  }

  updateMetaForm() {
    const metas = document.getElementById("metas")
    const keys = metas.getElementsByClassName("key")
    const values = metas.getElementsByClassName("value")
    let shouldAddNewRow = this.state.data.length - this.countBasics() != 0

    for (let i = 0; i < keys.length; i++) {
      if (values[i].value === "") {
        shouldAddNewRow = false
      }
    }

    if (shouldAddNewRow) {
      this.addData()
    }
  }

  removeMeta(key) {
    const { data } = this.state
    const newD = []

    for (let i = 0; i < data.length; i++) {
      if (data[i].key !== key) newD.push(data[i])
    }

    this.setState({
      data: newD,
    })
  }

  countBasics() {
    let count = 0

    for (let i = 0; i < this.state.data.length; i++) {
      const { key } = this.state.data[i]
      if (
        key === "taxes_amount" ||
        key === "shipping_amount" ||
        key === "request_shipping" ||
        key === "request_email"
      )
        count++
    }

    return count
  }

  render() {
    let count = 0
    let metas

    if (this.state.data.length - this.countBasics() > 0) {
      metas = (
        <div id="metas">
          {this.state.data.map(prop => {
            count++

            if (
              prop.key !== "shipping_amount" &&
              prop.key !== "taxes_amount" &&
              prop.key !== "request_shipping" &&
              prop.key !== "request_email"
            ) {
              return (
                <MData
                  metadata={prop}
                  updateForm={this.updateMetaForm.bind(this)}
                  remove={this.removeMeta.bind(this)}
                  propKey={count}
                  key={count}
                />
              )
            }
          })}
        </div>
      )
    } else {
      metas = (
        <div id="metas">
          <p>
            No metadata will be stored.{" "}
            <a className="bold" onClick={this.addData.bind(this)}>
              Click here to add one.
            </a>
          </p>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="large-12 medium-8 columns">{metas}</div>
      </div>
    )
  }
}
