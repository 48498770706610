import { RefObject, useEffect } from "react"
import uniqid from "uniqid"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import {
  $Declaration,
  $RoutingRule,
  $VelocityRule,
  NORMALIZATION_FIELDS,
  RULES_FILTERS,
} from "../../features/RoutingRules/consts"
import { Dynamic3dsParam, GatewayInformation } from "../@types/types"
import { Option } from "../Select/Select"
import { NEW_RULES_FILTERS, NEW_RULES_FILTERS_GROUP } from "./consts"
import { $GatewayConfiguration } from "../../features/PaymentProviders/consts"
import { getDefaultGatewayOptions } from "^/vNext/RoutingRules/hooks"

export function getConditionList() {
  return NEW_RULES_FILTERS_GROUP.reduce((result, [groupName, filterNames]: [string, string[]]) => {
    result[groupName] = result[groupName] || []
    const group = result[groupName]
    for (const filterName of filterNames) {
      let data: $VelocityRule | $Filter = {
        id: uniqid(),
        path: filterName,
        value: [],
        operand: getOperandOptions(filterName)[0].value,
      }

      if (filterName === "velocity") {
        data = {
          id: uniqid(),
          path: filterName,
          velocityPath: "",
          interval: "1m",
          operand: "==",
          value: [],
        }
      }

      const filter = {
        ...NEW_RULES_FILTERS[filterName],
        data,
      }
      group.push(filter)
    }
    return result
  }, Object.create(null))
}

export type SidebarList<T> = Array<{
  name: string
  items: Array<{ name: string; data: T }>
}>

export const getInvalidGatewayRuleIndices = (
  routingRules: $RoutingRule[],
  gatewayConfigurations: $GatewayConfiguration[],
): number[] => {
  const result = []

  for (let i = 0; i < routingRules.length; i++) {
    const rule = routingRules[i]
    for (const gateway of rule.gateways) {
      if (gateway.gateway !== "processout") {
        const config = gatewayConfigurations.find(config => config.id === gateway.gateway)
        if (!config || !config.enabled) {
          result.push(i)
          break
        }
      }
    }
  }

  return result
}

export function getAuthorizeWithList(
  gatewayNames: Record<string, string>,
  gatewayConfigurations: $GatewayConfiguration[],
): SidebarList<GatewayInformation> {
  const gatewayOptions = getDefaultGatewayOptions(gatewayNames, gatewayConfigurations)
  return [
    {
      name: "Authorize with",
      items: [
        {
          name: "Payment provider",
          data: {
            id: uniqid(),
            gateway: gatewayOptions.length > 0 ? gatewayOptions[0].value : "",
          },
        },
        {
          name: "Smart routing",
          data: {
            id: uniqid(),
            gateway: "processout",
            configurations: [],
          },
        },
      ],
    },
  ]
}

export function getDynamic3DSPramsList(): SidebarList<Dynamic3dsParam> {
  return [
    {
      name: "Apply Dynamic 3DS",
      items: [
        {
          name: "Exemption",
          data: {
            id: uniqid(),
            path: "sca_exemption_reason",
            value: "low-value",
          },
        },
        {
          name: "Challenge indicator",
          data: {
            id: uniqid(),
            path: "challenge_indicator",
            value: "no-preference",
          },
        },
      ],
    },
  ]
}

export const isMulti = path => {
  return Boolean(RULES_FILTERS[path]?.isMulti)
}

export const getOperandOptions = (path, operand?): Option[] => {
  const isMulti = Boolean(RULES_FILTERS[path]?.isMulti)

  if (NORMALIZATION_FIELDS.includes(path)) {
    if (operand === "==" || operand === "!=") {
      return [
        {
          label: "===",
          value: "===",
        },
        {
          label: "!==",
          value: "!==",
        },
        {
          label: "==",
          value: "==",
        },
        {
          label: "!=",
          value: "!=",
        },
      ]
    }

    return [
      {
        label: "===",
        value: "===",
      },
      {
        label: "!==",
        value: "!==",
      },
    ]
  }

  if (path === "sca_exemption_reason" || path === "challenge_indicator") {
    return [
      {
        label: "==",
        value: "==",
      },
      {
        label: "!=",
        value: "!=",
      },
      {
        label: "is not null",
        value: "is not null",
      },
      {
        label: "is null",
        value: "is null",
      },
    ]
  }

  if (path === "rand") {
    return [
      {
        label: "<",
        value: "<",
      },
      {
        label: "<=",
        value: "<=",
      },
      {
        label: ">",
        value: ">",
      },
      {
        label: ">=",
        value: ">=",
      },
    ]
  }

  if (isMulti && !path.includes("metadata")) {
    return [
      {
        label: "==",
        value: "==",
      },
      {
        label: "!=",
        value: "!=",
      },
    ]
  }

  return [
    {
      label: "==",
      value: "==",
    },
    {
      label: "!=",
      value: "!=",
    },
    {
      label: "<",
      value: "<",
    },
    {
      label: "<=",
      value: "<=",
    },
    {
      label: ">",
      value: ">",
    },
    {
      label: ">=",
      value: ">=",
    },
    {
      label: "is null",
      value: "is-null",
    },
    {
      label: "is not null",
      value: "is-not-null",
    },
  ]
}

export const isBooleanValueType = (path: string) => {
  return RULES_FILTERS[path] && RULES_FILTERS[path].type === "boolean"
}

export const isMetadataType = (path: string) => {
  return path.includes("metadata")
}

export const isFraudServicesValueType = (path: string) => {
  // Fraud service in routing starts with "action(xxx)"
  return path.startsWith("action") || path.includes("fraud_service")
}

export const isChallengeIndicatorType = path => {
  return path.includes("challenge_indicator")
}

export const isExemptionType = path => {
  return path.includes("sca_exemption_reason")
}

export const isRandomType = path => {
  return path === "rand"
}

export const useClickAway = (
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    if (!ref.current) return

    const handler = (e: MouseEvent) => {
      const bound = ref.current.getBoundingClientRect()
      if (
        !(
          e.clientX < bound.right &&
          e.clientX > bound.left &&
          e.clientY < bound.bottom &&
          e.clientY > bound.top
        )
      ) {
        onClickAway(e)
      }
    }

    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  }, [ref, onClickAway])
}

export const DECLARATION_LABELS = ["Blocking", "Trigger 3DS", "Dynamic 3DS", "Routing"]

export const getDeclaration = (tabLabel: string) => {
  switch (tabLabel) {
    case "Routing":
      return "route"
    case "Trigger 3DS":
      return "trigger_3ds"
    case "Dynamic 3DS":
      return "dynamic_3ds"
    case "Blocking":
      return "block"
    default:
      return "route"
  }
}

export const getlabel = (declaration: $Declaration) => {
  switch (declaration) {
    case "route":
      return "Routing"
    case "trigger_3ds":
      return "Trigger 3DS"
    case "dynamic_3ds":
      return "Dynamic 3DS"
    case "block":
      return "Blocking"
    default:
      return "Routing"
  }
}

export const toReactSelectValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.map(v => ({ label: v, value: v }))
  }
  return { value, label: value }
}

export const isValidGateway = (
  gatewayInfo: GatewayInformation,
  gatewayConfigurations: $GatewayConfiguration[],
) => {
  let isGatewayValid = true
  // Smart routing is always valid and enabled
  if (gatewayInfo.gateway !== "processout") {
    const config = gatewayConfigurations.find(g => g.id === gatewayInfo.gateway)
    isGatewayValid = config && config.enabled
  }

  return isGatewayValid
}
