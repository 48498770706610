import React from "react"
import { connect } from "react-redux"
import Partial from "./Partial"
import PanelNumber from "../Components/PanelNumber"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import type { $Project } from "../../../util/Types"
import type { $SurfacesState } from "../Surface/reducer"
import * as Actions from "../actions"
import { REQUEST_SURFACES_FETCH } from "../Surface/consts"
import type { $Dispatcher } from "../../../Util/Types"
type Props = {
  telescope: $TelescopeState
  currentProject: {
    project: $Project
  }
  surfaces: $SurfacesState
} & $Dispatcher

class Authorizations extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(Actions.selectSurfacesType("authorization-rate"))
    dispatch({
      type: REQUEST_SURFACES_FETCH,
      payload: {
        timespan: this.props.telescope.standard.timespan,
        type: "authorization-rate",
      },
    })
  }

  render() {
    const { data } = this.props.telescope.standard
    const { surfaces } = this.props
    const currency = this.props.currentProject.project.default_currency || "USD"
    return (
      <Partial
        leftPanel={
          <div className="row">
            <div className="large-6 columns">
              <PanelNumber
                number={data.authorized_amount}
                name="Authorized"
                style="currency"
                currency={currency}
              />
            </div>
            <div className="large-6 columns text-right">
              <PanelNumber number={data.transactions_authorized} name="Authorized transactions" />
            </div>
          </div>
        }
        rightPanel={
          <div className="row">
            <div className="large-4 columns">
              <PanelNumber number={data.failed_transactions} name="Failed transactions" />
            </div>
            <div className="large-4 columns text-center">
              <img
                alt="Long right arrow icon"
                src="//dashboard.processout.com/images/long-right-arrow.png"
                style={{
                  transform: "scale(.4)",
                  opacity: 0.4,
                }}
              />
            </div>
            <div className="large-4 columns text-right">
              <PanelNumber
                number={
                  surfaces.surfaces.length === 0 || !surfaces.validated
                    ? null
                    : surfaces.surfaces.reduce((value, s) => value + s.total_estimated_gain, 0)
                }
                name="To be recovered"
                disableWarnings
                style="currency"
                currency={currency}
              />
            </div>
          </div>
        }
      />
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  currentProject: store.currentProject,
  surfaces: store.surfaces,
}))(Authorizations)
