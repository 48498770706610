import React from "react"
import { FormattedNumber } from "react-intl"
import type { $DataPoint } from "../../Boards/consts"

const AmountTooltip = (props: { currency: string | null | undefined; point: $DataPoint }) => {
  const { point, currency } = props
  return (
    <div>
      <FormattedNumber value={point.value} style="currency" currency={currency} /> —{" "}
      <span className="greyed">
        <FormattedNumber value={point._count} />
        {" transactions"}
      </span>
    </div>
  )
}

export default AmountTooltip
