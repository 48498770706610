import { put, select, takeLatest } from "redux-saga/effects"
import { push } from "react-router-redux"
import type { $Board } from "./consts"
import { FETCH_BOARDS, REQUEST_BOARDS_FETCH } from "./consts"
import * as Actions from "./actions"
import type { $ActionType } from "../../Preparator/consts"
import * as ProcessOut from "../../../util/ProcessOut"
import * as GwayACtions from "../../GatewaysConfigurations/actions"
import { typeFailed } from "^/util/ActionUtils"
type Action = {
  payload:
    | {
        redirectToBoard?: string
        silent?: boolean
      }
    | null
    | undefined
} & $ActionType
export function* fetchBoards(action: Action): Generator<any, any, any> {
  try {
    yield put.resolve(GwayACtions.fetchGwayConfNames())
    const boardsResult = yield put.resolve(
      Actions.fetchBoards(!!(action.payload && action.payload.silent)),
    )
    const boards: Array<$Board> = boardsResult.value.data.boards.filter(
      board => !board.id.includes("board_default"),
    )
    const location = yield select<any>(store => store.routing.locationBeforeTransitions)

    if (boards.length > 0) {
      const redirection =
        action.payload && action.payload.redirectToBoard
          ? `/${action.payload.redirectToBoard}`
          : null

      if (redirection) {
        yield put(push(`/projects/${ProcessOut.config.ProjectId}/boards${redirection}`))
      }
    }
  } catch (error) {
    yield put({
      type: typeFailed(FETCH_BOARDS),
      payload: error,
    })
  }
}
export default function* watchBoardsSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_BOARDS_FETCH, fetchBoards)
}
