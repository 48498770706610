import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import type { $Dispatcher, $State } from "../../util/Types"
import * as CurrencyAction from "../../Actions/CurrencyActions"
export type $CurrencyOption = {
  label: string
  value: string
}
type Props = {
  currenciesDetails: {
    currencies: Array<{
      code: string
      value: number
    }>
  } & $State
  value: $CurrencyOption | null | undefined
  onChange: (arg0: $CurrencyOption | null | undefined) => void
} & $Dispatcher

class CurrencySelector extends React.Component<Props> {
  componentDidMount() {
    const { dispatch, currenciesDetails } = this.props
    if (!currenciesDetails.fetched && !currenciesDetails.error && !currenciesDetails.fetching)
      dispatch(CurrencyAction.loadCurrencies())
  }

  render() {
    const { value, currenciesDetails } = this.props
    const options =
      currenciesDetails.fetched && !currenciesDetails.error
        ? currenciesDetails.currencies.map(c => ({
            label: c.code,
            value: c.code,
          }))
        : []
    return (
      <div className="row">
        <div className="large-12 columns text-left">
          <Select
            className="react-select"
            value={value}
            isClearable={false}
            isLoading={currenciesDetails.fetching}
            options={options}
            onChange={value => {
              this.props.onChange(value)
            }}
            placeholder="Select your project’s currency"
          />
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currenciesDetails: store.currencies,
}))(CurrencySelector)
