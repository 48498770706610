import React from "react"
import { connect } from "react-redux"
import VisaChargebackProgram from "../Components/VisaChargebackProgram"
import MastercardChargebackProgram from "../Components/MastercardChargebackProgram"
import type { $ChargebackData } from "../reducer"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import ChargebackProgramBelonging from "../Components/ChargebackProgramBelonging"
import Error from "../../../components/Error"
type Props = {
  telescope: $TelescopeState
}

class ChargebacksContent extends React.Component<Props> {
  render() {
    const SCHEME_STYLE = {
      height: "40px",
      borderRadius: "4px",
    }
    const { data } = this.props.telescope.standard

    if (data.error) {
      return <Error />
    }

    const chargeback: $ChargebackData = data.chargebacksData
    return (
      <div className={`text-left row${chargeback.ready ? "" : " loading"}`}>
        <div className="large-12 columns">
          <div className="row text-center">
            <div
              className="large-6 columns"
              style={{
                borderRight: "1px solid #f2f2f2",
                paddingBottom: "2em",
              }}
            >
              <div
                className="row"
                style={{
                  marginLeft: "1em",
                  marginTop: "2em",
                }}
              >
                <div className="large-2 columns">
                  <img
                    alt="Visa`s logo"
                    style={{ ...SCHEME_STYLE, height: "30px" }}
                    src="//dashboard.processout.com/images/visa.png"
                  />
                </div>
                <div
                  className="large-10 columns"
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {chargeback.chargebackPrograms.visa && chargeback.chargebackPrograms.visa.name}
                </div>
              </div>
            </div>
            <div className="large-6 columns">
              <div
                className="row"
                style={{
                  marginLeft: "1em",
                  marginTop: "2em",
                }}
              >
                <div className="large-2 columns">
                  <img
                    alt="Mastercard`s logo"
                    style={SCHEME_STYLE}
                    src="//dashboard.processout.com/images/mastercard.png"
                  />
                </div>
                <div
                  className="large-10 columns"
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {chargeback.chargebackPrograms.mastercard &&
                    chargeback.chargebackPrograms.mastercard.name}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="large-6 columns"
              style={{
                borderRight: "1px solid #f2f2f2",
              }}
            >
              {chargeback.chargebackPrograms.visa && (
                <ChargebackProgramBelonging
                  program={
                    this.props.telescope.standard.data.chargebacksData.chargebackPrograms.visa
                  }
                />
              )}
            </div>
            <div className="large-6 columns">
              {chargeback.chargebackPrograms.mastercard && (
                <ChargebackProgramBelonging
                  program={
                    this.props.telescope.standard.data.chargebacksData.chargebackPrograms.mastercard
                  }
                />
              )}
            </div>
          </div>
          <div className="row">
            <div
              className="large-6 columns"
              style={{
                borderRight: "1px solid #f2f2f2",
              }}
            >
              {chargeback.chargebackPrograms.visa && <VisaChargebackProgram />}
            </div>
            <div className="large-6 columns">
              {chargeback.chargebackPrograms.mastercard && <MastercardChargebackProgram />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
}))(ChargebacksContent)
