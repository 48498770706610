import { goBack } from "react-router-redux"
import * as ProcessOut from "../util/ProcessOut"
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS"
export function loadSubscriptions(filter, length, after, id) {
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  filter = filter || ""
  return {
    type: FETCH_SUBSCRIPTIONS,
    payload: ProcessOut.APIcallPromise(
      `/subscriptions?expand=customer&order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
      "GET",
      null,
    ),
  }
}
export const FETCH_SUBSCRIPTION_DETAILS = "FETCH_SUBSCRIPTION_DETAILS"
export function loadSubscriptionDetails(id) {
  return function (dispatch) {
    dispatch({
      type: FETCH_SUBSCRIPTION_DETAILS,
      payload: ProcessOut.APIcallPromise(
        `/subscriptions/${id}?expand[]=customer&expand[]=plan`,
        "GET",
        null,
      ),
    })
  }
}
export const FETCH_SUBSCRIPTION_TRANSACTIONS = "FETCH_SUBSCRIPTION_TRANSACTIONS"
export function loadSubscriptionTransactions(length, subscriptionId, after, id) {
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return function (dispatch) {
    dispatch({
      type: FETCH_SUBSCRIPTION_TRANSACTIONS,
      payload: ProcessOut.APIcallPromise(
        `/subscriptions/${subscriptionId}/transactions?order=desc&limit=${length}&${bound}=${id}&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=operations`,
        "GET",
        null,
      ),
    })
  }
}
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export function deleteSubscription(id) {
  return function (dispatch) {
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: ProcessOut.APIcallPromise(`/subscriptions/${id}`, "DELETE", {}).then(response => {
        if (response.data.success) {
          dispatch(goBack())
        }
      }),
    })
  }
}
export const START_SUBSCRIPTION_CREATION = "START_SUBSCRIPTION_CREATION"
