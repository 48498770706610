import { useContext } from "react"
import { GatewayContext } from "./GatewayContext"
import { $GatewayConfiguration } from "^/features/PaymentProviders/consts"

export const getDefaultGatewayOptions = (
  gatewayNames: Record<string, string>,
  gatewayConfigurations: $GatewayConfiguration[],
) => {
  return Object.keys(gatewayNames)
    .filter(gateway => {
      const gatewayConfig = gatewayConfigurations.find(config => config.id === gateway)
      // by default, we don't want to consider disabled gateways
      return gatewayConfig && gatewayConfig.enabled
    })
    .map(key => ({
      value: key,
      label: gatewayNames[key],
    }))
}

export const useDefaultGatewayOptions = () => {
  const { gatewayNames, gatewayConfigurations } = useContext(GatewayContext)
  return getDefaultGatewayOptions(gatewayNames, gatewayConfigurations)
}
