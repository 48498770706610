import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../util/Types"
import type { $FeesRecommendation } from "../../features/Telescope/reducer"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
// @ts-expect-error
import "./advice.scss"
import { segmentAnalytics } from "^/vNext/Segment"

const ReactMarkdown = require("react-markdown")

class Advice extends React.Component {
  props: $FeesRecommendation & $Dispatcher

  generateImage(): string {
    switch (this.props.type) {
      case "country":
        return "flag"

      case "fee":
        return "fees"

      default:
        return "fees"
    }
  }

  showAdvice(event) {
    event.preventDefault()
    segmentAnalytics?.track("RECOMMENDATION_OPENED", {
      title: this.props.title,
    })
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: (
          <div className="row">
            <div className="medium-2 columns">
              <img
                alt={`${this.generateImage()} icon`}
                src={`//dashboard.processout.com/images/${this.generateImage()}.png`}
                style={{
                  height: "2.6em",
                  width: "auto",
                  opacity: 0.1,
                }}
              />
            </div>
            <div
              className="medium-10 columns small-font"
              style={{
                marginTop: "0.4em",
              }}
            >
              {this.props.title}
            </div>
          </div>
        ),
        content: (
          <div>
            <div className="row">
              <div className="large-12 columns">
                <ReactMarkdown source={this.props.advice} />
              </div>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="medium-12 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Close
              </a>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return (
      <a onClick={this.showAdvice.bind(this)}>
        <div
          className="row telescope-advice-row"
          style={{
            padding: "15px 22px",
            borderBottom: "1px solid #f3f5f6",
          }}
        >
          <div className="medium-1 columns text-left">
            <img
              alt={`${this.generateImage()} icon`}
              src={`//dashboard.processout.com/images/${this.generateImage()}.png`}
              style={{
                maxHeight: "1.5em",
                width: "auto",
                opacity: 0.1,
              }}
            />
          </div>
          <div
            className="medium-10 columns text-left"
            style={{
              marginTop: "0.1em",
            }}
          >
            {this.props.title}
          </div>
          <div
            className="medium-1 columns text-right"
            style={{
              fontSize: "1.3em",
            }}
          >
            <div className="arrow">
              <span href="#">&rarr;</span>
            </div>
          </div>
        </div>
      </a>
    )
  }
}

export default connect(store => ({
  projects: store.projects,
  user: store.user,
}))(Advice)
