import React from "react"
import { connect } from "react-redux"
import uniqid from "uniqid"
import type { $Dispatcher } from "../../util/Types"
import FraudConfigurations from "./FraudConfigurations"
import { loadFraudServices } from "../../features/FraudServices/actions"
import * as fraudServicesConfigurationActions from "../../features/FraudServicesConfiguration/actions"
import * as ProcessOut from "../../util/ProcessOut"
import { FRAUD_PER_LINE } from "../../features/FraudServices/consts"
import FraudServicesSearch from "../../util/FraudServicesSearch"
import Loader from "../Loader"
import ContentLayout from "../../pages/ContentLayout"
import Empty from "../Empty"
import FraudServicesModal from "./FraudServicesModal"
type Props = {} & $Dispatcher
type State = {
  fraudSearchList: Array<any> | null | undefined
}
export class FraudServices extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      fraudSearchList: null,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(loadFraudServices())
    dispatch(
      fraudServicesConfigurationActions.fetchFraudServicesConfig(ProcessOut.config.ProjectId),
    )
  }

  openModal = processor => {
    // TODO - this needs to be fixed, but is going to take a lot more thought to ensure nothing breaks. Come back to this
    // eslint-disable-next-line react/no-string-refs
    this.refs[processor.name].getWrappedInstance().getWrappedInstance().openModal()
  }
  filter = e => {
    const filter = e.target.value

    if (filter === "") {
      this.setState({
        fraudSearchList: null,
      })
      return
    }

    this.setState({
      fraudSearchList: FraudServicesSearch(this.props.fraudServices.fraud_services, filter),
    })
  }
  renderGroup = (itemsArray): Array<React.ReactElement<React.ComponentProps<"div">, "div">> => {
    const rowsN = itemsArray.length / FRAUD_PER_LINE
    const rows = []

    for (let j = 0; j < rowsN; j++) {
      let count = -1
      const row = (
        <div className="row" key={Math.floor(Math.random() * 100000)}>
          {itemsArray
            .slice(j * FRAUD_PER_LINE, j * FRAUD_PER_LINE + FRAUD_PER_LINE)
            .map(processor => {
              count++
              let last = ""
              if (count + j * FRAUD_PER_LINE === itemsArray.length - 1) last = "end"
              return (
                <div
                  key={count}
                  className={`medium-${12 / FRAUD_PER_LINE} ${last} columns margin-bottom`}
                >
                  <FraudServicesModal
                    modalName="New configuration"
                    fraudService={processor}
                    ref={processor.name}
                  />
                  <a
                    className="payment-processor-wrapper details-box only-img"
                    onClick={() => {
                      this.openModal(processor)
                    }}
                  >
                    <div className="row">
                      <div className="large-12 columns text-center img-wrapper">
                        <img
                          className="payment-processor"
                          src={processor.logo_url}
                          alt={`${processor.display_name}'s logo`}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns text-center description">
                        {processor.display_name}
                      </div>
                    </div>
                  </a>
                </div>
              )
            })}
        </div>
      )
      rows.push(row)
    }

    return rows
  }

  render() {
    const tiles: Array<
      | React.ReactElement<React.ComponentProps<"div">, "div">
      | Array<React.ReactElement<React.ComponentProps<"div">, "div">>
    > = []
    const processorsDetails = this.props.fraudServices
    const fraudServices = this.props.fraudServices.fraud_services
    const { fraudSearchList } = this.state

    if (processorsDetails.fetching || !processorsDetails.fetched) {
      return (
        <ContentLayout title={<h4>Fraud Services</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    if (fraudSearchList) {
      tiles.push(
        <div className="row" key={uniqid()}>
          <div className="large-12 columns margin-bottom">{this.renderGroup(fraudSearchList)}</div>
        </div>,
      )
    } else if (fraudServices.length) {
      tiles.push(
        <div className="row" key={uniqid()}>
          <div className="large-12 columns margin-bottom">{this.renderGroup(fraudServices)}</div>
        </div>,
      )
    }

    let empty

    if (fraudSearchList && fraudSearchList.length === 0) {
      empty = <Empty text="No fraud service matches your search" />
    }

    return (
      <div>
        <FraudConfigurations />
        <ContentLayout title={<h4>Configure a new fraud service</h4>}>
          <div className="row">
            <div className="medium-4 columns">
              <div className="payment-processor-menu">
                <div className="row">
                  <div className="large-12 columns">
                    <input type="text" placeholder="Search" onChange={this.filter} />
                  </div>
                </div>
              </div>
            </div>
            <div className="medium-8 columns">
              {tiles}
              {empty}
            </div>
          </div>
        </ContentLayout>
      </div>
    )
  }
}
export default connect(store => ({
  fraudServices: store.fraudServices,
  fraudServicesConfiguration: store.fraudServiceConfigurationsReducer,
}))(FraudServices)
