import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import React, { useContext } from "react"
import type { $VelocityRule } from "^/features/RoutingRules/consts"
import type { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Option } from "^/vNext/Select/Select"
import { RULES_FILTERS } from "../../../../features/RoutingRules/consts"
import { Button } from "../../../Button/Button"
import { RoutingContext } from "../../RoutingContext"
import {
  getOperandOptions,
  isBooleanValueType,
  isChallengeIndicatorType,
  isExemptionType,
  isFraudServicesValueType,
  isMetadataType,
  isRandomType,
} from "../../utils"
import { BooleanSelection } from "../BooleanSelection"
import { ReactComponent as SvgIcon } from "../caret-icon.svg"
import { ChallengeIndicatorSelection } from "../ChallengeIndicatorSelection"
import { FraudServiceSelectionContext } from "../FraudServiceSelection"
import { GenericSelect } from "../Generic"
import { MetaDataSelection } from "../MetadataSelection"
import { VelocitySelection } from "../VelocitySelection"
import { ExemptionSelection } from "./ExemptionSelection"
import "./RuleCondition.scss"
import { RandomSelection } from "../RandomSelection"

type Props = {
  condition: $Filter | $VelocityRule
  name: string
  onChange: (args: any) => void
  onDeletion: (id: string) => void
}

export function RuleCondition({ condition, onChange, onDeletion }: Props) {
  const [state] = useContext(RoutingContext)
  const operandOptions = getOperandOptions(condition.path)

  const operand = operandOptions.filter(o => o.value === condition.operand)

  let label = RULES_FILTERS[condition.path]?.label

  let content = (
    <GenericSelect
      condition={condition}
      operand={operand}
      value={condition.value}
      onOperandChange={(change: Option) => {
        onChange({ ...condition, operand: change.value })
      }}
      onValueChange={(change: Option[] | Option) => {
        if (Array.isArray(change)) {
          onChange({ ...condition, value: change.map(value => value.value) })
        } else {
          onChange({ ...condition, value: [change.value] })
        }
      }}
    />
  )

  if (isRandomType(condition.path)) {
    content = (
      <RandomSelection
        condition={condition}
        operand={operand}
        value={condition.value[0] as number}
        onOperandChange={(change: Option) => {
          onChange({ ...condition, operand: change.value })
        }}
        onValueChange={(value: number) => {
          onChange({ ...condition, value: [value] })
        }}
      />
    )
  }

  if (isMetadataType(condition.path)) {
    label = condition.path
    content = (
      <>
        <MetaDataSelection
          condition={condition}
          operand={operand}
          onMetaDataKeyChanged={change => {
            onChange({ ...condition, path: `metadata.${change}` })
          }}
          onOperandChange={(change: Option) => {
            onChange({ ...condition, operand: change.value })
          }}
          onValueChange={(change: Option[]) => {
            const newVal = change.map(value => value.value)
            onChange({ ...condition, value: newVal })
          }}
        />
      </>
    )
  }

  if (isBooleanValueType(condition.path)) {
    content = (
      <BooleanSelection
        value={condition.value[0]}
        onChange={(change: Option) => {
          onChange({ ...condition, value: [change.value] })
        }}
      />
    )
  }

  if (isFraudServicesValueType(condition.path)) {
    label = "Fraud service"
    content = (
      <FraudServiceSelectionContext
        condition={condition}
        onFraudServiceChange={change => {
          const newCondition = structuredClone(condition)
          newCondition.path = `action("${change.value}")`
          onChange(newCondition)
        }}
        onValueChange={changes => {
          const newCondition = structuredClone(condition)
          newCondition.value = changes.map(change => change.value)
          onChange(newCondition)
        }}
      />
    )
  }

  if ("velocityPath" in condition) {
    content = (
      <VelocitySelection
        interval={condition.interval}
        operand={condition.operand}
        velocityPath={condition.velocityPath}
        values={condition.value}
        onVelocityPathChange={change => {
          const newCondition = structuredClone(condition)
          newCondition.velocityPath = change.value
          onChange(newCondition)
        }}
        onIntervalChange={change => {
          const newCondition = structuredClone(condition)
          newCondition.interval = change.value
          onChange(newCondition)
        }}
        onOperandChange={change => {
          const newCondition = structuredClone(condition)
          newCondition.operand = change.value
          onChange(newCondition)
        }}
        onValueChange={change => {
          const newCondition = structuredClone(condition)
          newCondition.value = [change]
          onChange(newCondition)
        }}
      />
    )
  }

  if (isChallengeIndicatorType(condition.path)) {
    content = (
      <ChallengeIndicatorSelection
        value={condition.value}
        onValueChange={(change: Option) => {
          onChange({ ...condition, value: change.value })
        }}
      />
    )
  }

  if (isExemptionType(condition.path)) {
    content = (
      <ExemptionSelection
        value={condition.value}
        onValueChange={(change: Option) => {
          onChange({ ...condition, value: change.value })
        }}
      />
    )
  }

  return (
    <Disclosure defaultOpen={state.selectedCardId === condition.id}>
      {({ open }) => (
        <div className={clsx("rule-condition", { open })}>
          <Disclosure.Button
            className={clsx("rule-condition__button", open && "rule-condition__button--open")}
          >
            {label}
            <SvgIcon alt="" className="rule-condition__icon" />
          </Disclosure.Button>
          <Disclosure.Panel
            unmount={false}
            className={clsx("rule-condition__panel", open && "rule-condition__panel--open")}
          >
            {content}
            <div className="RuleCondition__panelFooter">
              <Button
                variant="tertiary"
                className="rule-condition__deleteBtn"
                onClick={() => onDeletion(condition.id)}
                data-auto-tracking="true"
                data-tag-name={label}
              >
                Delete condition
              </Button>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
