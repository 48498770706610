import * as ProcessOut from "^/util/ProcessOut"
import type { $Action } from "^/util/Types"
import {
  ADD_ALTERNATIVE_CERTIFICATE,
  DELETE_ALTERNATIVE_CERTIFICATE,
  FETCH_ALTERNATIVE_CERTIFICATE,
} from "./consts"
export function fetchAlternativeCertificate(): $Action {
  return {
    type: FETCH_ALTERNATIVE_CERTIFICATE,
    payload: ProcessOut.APIcallPromise(
      "/projects/applepay/alternative-merchant-certificates",
      "GET",
    ).then(response => response.data),
  }
}
export function addAlternativeCertificate(
  alternativeCertificate: string,
  resolve: () => void,
): $Action {
  const body = {
    merchant_certificate: alternativeCertificate,
  }
  return {
    type: ADD_ALTERNATIVE_CERTIFICATE,
    payload: ProcessOut.APIcallPromise(
      "/projects/applepay/alternative-merchant-certificates",
      "POST",
      JSON.stringify(body),
    ).then(response => {
      if (response.data.success) resolve()
      return response
    }),
  }
}
export function removeAlternativeCertificate(
  certificateId: string,
  optResolve: () => void,
): $Action {
  return {
    type: DELETE_ALTERNATIVE_CERTIFICATE,
    payload: ProcessOut.APIcallPromise(
      `/projects/applepay/alternative-merchant-certificates/${certificateId}`,
      "DELETE",
    ).then(response => {
      if (response.data.success && optResolve) {
        optResolve()
      }
    }),
  }
}
