import { initialize } from "launchdarkly-js-client-sdk"
import {
  identifyUser as identifyUserAction,
  setFlags as setFlagsAction,
  setInitLaunchDarklyFailed,
  setInitLaunchDarklyPending,
  setLaunchDarklyInitialised,
  userIdentified,
} from "./LaunchDarklyActions"
import { SET_PROJECT_ID } from "^/Actions/ProjectsActions"
import { FETCH_USER } from "^/Actions/UserActions"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { LAUNCHDARKLY_INIT_FULFILLED, LAUNCHDARKLY_INIT_REQUEST } from "./consts"
import type { $Flags, $LaunchDarklyClient, $LaunchDarklyOptions, $LaunchDarklyUser } from "./types"
import uniqid from "uniqid"
import { isInternalUser } from "^/util/Auth"
import { datadogRum } from "@datadog/browser-rum"
import { typeFulfilled } from "^/util/ActionUtils"
let launchDarklyClient: $LaunchDarklyClient

async function waitForInit(): Promise<void> {
  return await launchDarklyClient.waitForInitialization()
}

export function* initLaunchDarkly(): Generator<any, any, any> {
  yield put(setInitLaunchDarklyPending())
  const userId = yield select<any>(store => store.user.details.uniq_id)
  const user: $LaunchDarklyUser = {
    key: userId || uniqid(),
    anonymous: true,
  }
  const options: $LaunchDarklyOptions = {
    privateAttributeNames: ["name", "email"],
  }

  try {
    launchDarklyClient = initialize(process.env.LAUNCHDARKLY_CLIENT_ID, user, options)
    yield call(waitForInit)
    yield put(setLaunchDarklyInitialised(launchDarklyClient))
  } catch (e) {
    yield put(setInitLaunchDarklyFailed(e))
    datadogRum.addError(e)
  }
}

async function waitForIdentify(user: $LaunchDarklyUser): Promise<$Flags> {
  return (await launchDarklyClient.identify(user)) as any
}

// fix from this task: https://checkout.atlassian.net/browse/POF-567
// we want to check if the project ID was previously idientified
// the problem was that before when you change the project, the previous project ID was still present somewhere
// so, LAUNCHDARKLY_USER_IDENTIFIED was called twice, once with the old project ID and once with the new ID.
// it could cause errors when you were in a project with no feature flag enabled and then you switch to a project
// with feature flag enabled (i.e `telescope-V2`).
let previouslyIdentifiedProjectId = null

export function* identifyUser(): Generator<any, any, any> {
  const { initialised, userDetails, projectId } = yield select<any>(store => ({
    initialised: store.launchDarkly.initialiseState.state === "initialised",
    userDetails: store.user.details,
    projectId: store.projects.current_project_id,
  }))

  if (
    initialised &&
    userDetails.uniq_id &&
    userDetails.email &&
    userDetails.name &&
    projectId &&
    projectId !== previouslyIdentifiedProjectId
  ) {
    yield put(identifyUserAction())
    previouslyIdentifiedProjectId = projectId
    const user: $LaunchDarklyUser = {
      key: userDetails.uniq_id,
      email: userDetails.email,
      name: userDetails.name,
      custom: {
        userId: userDetails.uniq_id,
        projectId,
        isInternalUser: isInternalUser(userDetails.email),
      } as any,
    }

    try {
      const flags = yield call(waitForIdentify, user)
      yield put(userIdentified(user))
      yield call(setFlags, flags)
    } catch (e) {
      datadogRum.addError(e)
    }
  }
}
export function* setFlags(flags: $Flags): Generator<any, any, any> {
  try {
    const flagValues = {}

    for (const flag in flags) {
      flagValues[flag] = launchDarklyClient.variation(flag, flags[flag])
    }

    yield put(setFlagsAction(flagValues))
  } catch (e) {
    datadogRum.addError(e)
  }
}
export default function* watchLaunchDarkly(): Generator<any, any, any> {
  yield takeLatest(LAUNCHDARKLY_INIT_REQUEST, initLaunchDarkly)
  yield takeLatest(LAUNCHDARKLY_INIT_FULFILLED, identifyUser)
  yield takeLatest(SET_PROJECT_ID, identifyUser)
  yield takeLatest(typeFulfilled(FETCH_USER), identifyUser)
}
