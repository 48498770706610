import React from "react"
import "./SidebarBlock.scss"
import { ReactComponent as PlusIcon } from "./plus-icon.svg"
import clsx from "clsx"
type Props = {
  title: string
  onAddClick: () => void
  className?: string
  disabled?: boolean
}
export function SidebarBlock({ title, onAddClick, className, disabled = false }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-static-element-interactions
    <div className={clsx("sidebar-block", className, { disabled: disabled })} tabIndex={0}>
      <span>{title}</span>
      <button aria-label={`add ${title}`} onClick={onAddClick}>
        <PlusIcon className="sidebar-block__icon" />
      </button>
    </div>
  )
}
