import React from "react"
import { FormattedDate, FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import moment from "moment"
import ContentLayout from "./ContentLayout"
import Loader from "../components/Loader"
import Empty from "../components/Empty"
import Customer from "../components/TransactionsDetails/Customer"
import Transaction from "../components/Transaction"
import Pagination from "../components/Pagination"
import * as SubscriptionsActions from "../Actions/SubscriptionsActions"
import PlanDetails from "../components/SubscriptionDetails/PlanDetails"
import MetadataDisplay from "../components/MetadataDisplay"
import SubscriptionDetailsLayout from "./SubscriptionDetailsLayout"
import * as CouponsActions from "../Actions/CouponsActions"
import Discount from "../components/Discount"
import type { $Dispatcher } from "^/util/Types"
import type { $DiscountsState } from "^/stores/Reducers/DiscountsReducer.js"
import type { $SubscriptionTransactionsDetailsState } from "^/stores/Reducers/SubscriptionTransactionsDetailsReducer.js"
import type { $SubscriptionsDetailsState } from "^/stores/Reducers/SubscriptionDetailsReducer.js"
const TRANSACTIONS_PER_PAGE = 10
type Props = {
  details: $SubscriptionsDetailsState
  transactions: $SubscriptionTransactionsDetailsState
  discounts: $DiscountsState
  params: {
    subscription: string
  }
} & $Dispatcher

class SubscriptionDetails extends React.Component<Props> {
  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Subscription | ProcessOut"
    this.props.dispatch(
      SubscriptionsActions.loadSubscriptionDetails(this.props.params.subscription),
    )
    this.props.dispatch(
      SubscriptionsActions.loadSubscriptionTransactions(
        TRANSACTIONS_PER_PAGE,
        this.props.params.subscription,
      ),
    )
    this.props.dispatch(CouponsActions.fetchDiscounts(this.props.params.subscription))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.details.fetching && !nextProps.details.fetched)
      this.props.dispatch(
        SubscriptionsActions.loadSubscriptionDetails(this.props.params.subscription),
      )
    if (!nextProps.transactions.fetching && !nextProps.transactions.fetched)
      this.props.dispatch(
        SubscriptionsActions.loadSubscriptionTransactions(
          TRANSACTIONS_PER_PAGE,
          this.props.params.subscription,
        ),
      )
    if (!nextProps.discounts.fetching && !nextProps.discounts.fetched)
      this.props.dispatch(CouponsActions.fetchDiscounts(this.props.params.subscription))
  }

  constructor() {
    super()
  }

  render() {
    const { details } = this.props

    if (details.fetching || !details.fetched) {
      return (
        <ContentLayout title={<h4>Subscription details</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    const { subscription } = this.props.details

    if (subscription.id == 0) {
      return <SubscriptionDetailsLayout title="Subscription details" />
    }

    let status

    if (subscription.activated && subscription.canceled) {
      status = <span className="big-font capitalize bold">Canceled</span>
    } else if (subscription.active) {
      if (subscription.active_trial) {
        const today = moment()
        const ending = moment(subscription.trial_end_at)
        const daysLeft = ending.diff(today, "days")
        status = (
          <div>
            <span className="big-font capitalize bold">Trial</span>
            <span
              style={{
                marginLeft: "5px",
              }}
              className="greyed"
            >
              ({daysLeft} days left)
            </span>
          </div>
        )
      } else {
        status = <span className="big-font capitalize bold">Active</span>
      }
    } else {
      status = <span className="big-font capitalize bold">Not activated</span>
    }

    let customer

    if (subscription.customer) {
      customer = <Customer customer={subscription.customer} />
    }

    let activationDate
    let expireDate

    if (subscription.activated) {
      activationDate = (
        <div className="row small-margin-bottom">
          <div className="large-3 medium-3 columns greyed">Activated on</div>
          <div className="large-9 medium-9 columns">
            <span className="right">
              <FormattedDate
                value={subscription.activated_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                time="numeric"
              />
            </span>
          </div>
        </div>
      )
      expireDate = (
        <div className="row small-margin-bottom">
          <div className="large-4 medium-4 columns greyed">
            {subscription.active_trial ? "Trial end" : "Next iteration"}
          </div>
          <div className="large-8 medium-8 columns">
            <span className="right">
              <FormattedDate
                value={
                  subscription.active_trial ? subscription.trial_end_at : subscription.iterate_at
                }
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                time="numeric"
              />
            </span>
          </div>
        </div>
      )
    }

    let endedDate
    let endReason

    if (subscription.canceled) {
      endedDate = (
        <div className="row small-margin-bottom">
          <div className="large-3 medium-3 columns greyed">Canceled on</div>
          <div className="large-9 medium-9 columns">
            <span className="right">
              <FormattedDate
                value={subscription.canceled_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                time="numeric"
              />
            </span>
          </div>
        </div>
      )
    }

    const pStyle = {
      fontSize: "3.5em",
      letterSpacing: "0.08em",
      opacity: 0.4,
    }
    let transactions

    if (this.props.transactions.fetching || !this.props.transactions.fetched) {
      transactions = <Loader />
    } else if (this.props.transactions.count > 0) {
      transactions = (
        <div className="row">
          <div className="large-12 columns">
            {this.props.transactions.transactions.map(transaction => (
              <div className="row" key={transaction.id}>
                <div className="large-12 columns">
                  <Transaction transaction={transaction} smallDisplay />
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    } else {
      transactions = (
        <div className="row small-margin-bottom">
          <div className="large-12 columns text-center">
            <Empty text="No transaction data available" />
          </div>
        </div>
      )
    }

    let cancelReason

    if (subscription.canceled) {
      cancelReason = (
        <div
          className="large-6 columns"
          style={{
            wordBreak: "break-word",
          }}
        >
          <div className="right">
            <label>Reason</label>
            <span>
              {subscription.cancellation_reason != ""
                ? subscription.cancellation_reason
                : "Unknown"}
            </span>
          </div>
        </div>
      )
    }

    let interval
    if (subscription.interval_days === 365) interval = "yearly"
    else if (subscription.interval_days === 30) interval = "monthly"
    else if (subscription.interval_days === 7) interval = "weekly"
    else if (subscription.interval_days === 1) interval = "daily"
    else interval = `${subscription.interval_days} days`
    let futureCancel

    if (subscription.pending_cancellation) {
      futureCancel = (
        <div
          style={{
            color: "orange",
            marginTop: "15px",
          }}
        >
          <span className="bold">Will cancel on</span>{" "}
          <FormattedDate
            value={subscription.cancel_at}
            day="2-digit"
            month="2-digit"
            year="numeric"
            hour="numeric"
            minute="numeric"
            time="numeric"
          />
        </div>
      )
    }

    let plandetails

    if (subscription.plan) {
      plandetails = (
        <div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6 className="margin-bottom capitalize greyed font-console">Plan</h6>
            </div>
            <div className="large-12 columns">
              <PlanDetails plan={subscription.plan} />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
        </div>
      )
    }

    let discounts

    if (this.props.discounts.fetched && this.props.discounts.discounts.length > 0) {
      discounts = (
        <div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6 className="margin-bottom capitalize greyed bold">Discounts</h6>
            </div>
            <div className="large-12 columns">
              {this.props.discounts.discounts.map(discount => (
                <div className="row" key={discount.id}>
                  <div className="large-12 columns">
                    <Discount discount={discount} currency={subscription.currency} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
        </div>
      )
    }

    return (
      <SubscriptionDetailsLayout title="Subscription details">
        <div className="row margin-bottom">
          <div className="large-5 columns">
            <div className="details-box">
              <div className="row">
                <div className="large-4 columns">
                  <label>Amount</label>
                  <span className="huge-font bold">
                    <FormattedNumber
                      value={subscription.amount}
                      style="currency"
                      currency={subscription.currency}
                    />
                  </span>
                </div>
                <div className="large-4 columns">
                  <div className="right">
                    <label>Interval</label>
                    <span className="big-font">{interval}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="large-7 columns"
            style={{
              paddingRight: 0,
            }}
          >
            <div className="details-box">
              <div className="row">
                <div className={`large-${cancelReason ? 6 : 12} columns`}>
                  <label>Status</label>
                  {status}
                  {futureCancel}
                </div>
                {cancelReason}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="large-5 columns left-border">
            <div className="row">
              <div className="large-12 columns">
                <h6 className="margin-bottom capitalize greyed font-console">Details</h6>
              </div>
              <div className="large-12 columns">
                <div className="row">
                  <div className="large-12 medium-8 columns">
                    <div className="row small-margin-bottom">
                      <div className="large-3 medium-3 columns greyed">Identifier</div>
                      <div className="large-9 medium-9 columns">
                        <span className="right font-console">{subscription.id}</span>
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="large-3 medium-3 columns greyed">Name</div>
                      <div className="large-9 medium-9 columns">
                        <span className="right">{subscription.name}</span>
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="large-3 medium-3 columns greyed">Created on</div>
                      <div className="large-9 medium-9 columns">
                        <span className="right">
                          <FormattedDate
                            value={subscription.created_at}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="numeric"
                            minute="numeric"
                            time="numeric"
                          />
                        </span>
                      </div>
                    </div>

                    {activationDate}
                    {expireDate}
                    {endedDate}

                    <div className="row small-margin-bottom">
                      <div className="large-3 medium-3 columns greyed">Currency</div>
                      <div className="large-9 medium-9 columns">
                        <span className="right">{subscription.currency}</span>
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="large-12 medium-9 columns">
                        {subscription.metadata.length > 0 ? (
                          <MetadataDisplay
                            metadata={subscription.metadata}
                            currency={subscription.currency}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns" />
            </div>
            <div className="row">
              <div className="large-12 columns">
                <hr />
              </div>
            </div>
            {customer}
          </div>
          <div className="large-7 columns">
            {plandetails}
            {discounts}

            <div className="row margin-bottom">
              <div className="large-12 columns">
                <div className="row">
                  <div className="large-12 columns">
                    <h6 className="margin-bottom capitalize greyed font-console">
                      Transactions history
                    </h6>
                  </div>
                </div>
                {transactions}
                <Pagination
                  smallContainer
                  reloadFunction={SubscriptionsActions.loadSubscriptionTransactions.bind(
                    null,
                    TRANSACTIONS_PER_PAGE,
                    this.props.params.subscription,
                  )}
                  hasMore={
                    this.props.transactions.fetched ? this.props.transactions.has_more : false
                  }
                  location={this.props.location}
                  itemsArray={
                    this.props.transactions.fetched ? this.props.transactions.transactions : []
                  }
                  hide={this.props.transactions.fetching || !this.props.transactions.fetched}
                />
              </div>
            </div>
          </div>
        </div>
      </SubscriptionDetailsLayout>
    )
  }
}

export default connect(store => ({
  details: store.subscriptionDetails,
  transactions: store.subscriptionTransactions,
  discounts: store.discounts,
}))(SubscriptionDetails)
