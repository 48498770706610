/**
 * Created by jeremylejoux on 08/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import * as ApplePayActions from "../../../Actions/ApplePayActions"
export class APDomainName extends React.Component {
  props: Props
  static defaultProps = {
    dispatch: null,
    currentProject: null,
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  removeDomain(event: Event) {
    event.preventDefault()
    const { dispatch, currentProject, domainName } = this.props
    if (!currentProject.fetched || !currentProject.project.applepay_settings) return
    const confirmed = confirm(
      "You are about to remove the domain from your domain list. Do you want to continue?",
    )
    if (!confirmed) return
    dispatch(
      ApplePayActions.removeDomain(
        currentProject.project.id,
        domainName,
        currentProject.project.applepay_settings.merchant_domain_names,
      ),
    )
  }

  render() {
    return (
      <div className="row">
        <div className="medium-10 columns">{this.props.domainName}</div>
        <div className="medium-2 columns text-right">
          <a onClick={this.removeDomain.bind(this)}>remove</a>
        </div>
      </div>
    )
  }
}
type Props = {
  domainName: string
  currentProject:
    | any
    | {
        fetched: boolean
        fetching: boolean
        project: {
          id: string
          applepay_settings: any
        }
      }
  dispatch: any | (() => void)
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(APDomainName)
