import React from "react"
import { withRouter } from "react-router"
import Project from "../../util/Project"
export class SandboxIndicator extends React.Component<{
  params: {
    project: string | null | undefined
  }
}> {
  render() {
    const { params } = this.props
    if (!params.project) return null
    const project = new Project(params.project)
    if (!project.isSandbox()) return null
    return (
      <div className="row sandbox-indicator">
        <div className="large-12 columns">Sandbox is active</div>
      </div>
    )
  }
}
export default withRouter(SandboxIndicator)
