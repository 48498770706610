import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import type { $SurfacesState } from "./reducer"
import type { $Project } from "../../../util/Types"
type Props = {
  onHover: (arg0: string | null | undefined) => any
  surfaces: $SurfacesState
  currentProject: {
    project: $Project
  }
  hoveredSurface: string | null | undefined
}

class Header extends React.Component<Props> {
  render() {
    if (!this.props.surfaces.validated) {
      return null
    }

    return (
      <div className="row small-margin-bottom">
        <div className="large-12 columns">
          <div
            className="row small-margin-bottom"
            style={{
              marginTop: "1em",
            }}
          >
            <div className="large-12 columns text-center">
              <div className="action needed" />{" "}
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <div>Actionable problems</div>
                <div className="text-center">
                  <FormattedNumber
                    style="currency"
                    value={this.props.surfaces.surfaces
                      .filter(surface => surface.tag === "recuperable")
                      .reduce((value, surface) => value + surface.total_estimated_gain, 0)}
                    currency={this.props.currentProject.project.default_currency || "USD"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </div>
              <div className="action could" />
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <div>Could be optimized</div>
                <div className="text-center">
                  <FormattedNumber
                    style="currency"
                    value={this.props.surfaces.surfaces
                      .filter(surface => surface.tag === "medium")
                      .reduce((value, surface) => value + surface.total_estimated_gain, 0)}
                    currency={this.props.currentProject.project.default_currency || "USD"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </div>
              <div className="action nothing" />
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <div>Nothing to be done </div>
                <div className="text-center">
                  <FormattedNumber
                    style="currency"
                    value={this.props.surfaces.surfaces
                      .filter(surface => surface.tag === "hard")
                      .reduce((value, surface) => value + surface.total_money_impact, 0)}
                    currency={this.props.currentProject.project.default_currency || "USD"}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="large-12 columns text-right greyed"
              style={{
                paddingRight: "2em",
                marginBottom: ".5em",
                lineHeight: 0,
                transform: "translateY(-2em)",
              }}
            >
              Failed transactions split
            </div>
          </div>
          <div
            className="row transactions"
            style={{
              marginBottom: "1.5em",
            }}
          >
            <div className="large-12 columns">
              {this.props.surfaces.fetched
                ? this.props.surfaces.surfaces
                    .filter(surface => surface.tag !== "leftovers")
                    .map((surface, index, array) => {
                      const total = this.props.surfaces.surfaces
                        .filter(s => s.tag !== "leftovers")
                        .reduce((value, surface) => value + surface.count, 0)
                      return (
                        <a
                          className={`cut ${surface.tag}`}
                          id={`transaction-cut-${surface.id}`}
                          key={surface.id}
                          onMouseOver={() => {
                            for (const s of this.props.surfaces.surfaces) {
                              if (s.id !== surface.id) {
                                const el = document.getElementById(`surface-${s.id}`)

                                if (el) {
                                  el.className = `${el.className.replace(/ fade/g, "")} fade`
                                }
                              } else {
                                const el = document.getElementById(`surface-${s.id}`)

                                if (el) {
                                  el.className = el.className.replace(/ fade/g, "")
                                }
                              }
                            }
                          }}
                          onMouseOut={() => {
                            for (const s of this.props.surfaces.surfaces) {
                              if (this.props.hoveredSurface && s.id !== this.props.hoveredSurface) {
                                const el = document.getElementById(`surface-${s.id}`)
                                if (el) el.className = `${el.className.replace(/ fade/g, "")} fade`
                              } else {
                                const el = document.getElementById(`surface-${s.id}`)

                                if (el) {
                                  el.className = el.className.replace(/ fade/g, "")
                                }
                              }
                            }
                          }}
                          onClick={() => {
                            this.props.onHover(
                              this.props.hoveredSurface === surface.id ? null : surface.id,
                            )

                            if (surface.tag !== "recuperable") {
                              setTimeout(() => {
                                this.props.onHover("")
                              }, 400)
                            }
                          }}
                          style={{
                            width:
                              surface.tag !== "leftovers"
                                ? `${(surface.count * 100) / total}%`
                                : "50px",
                            left:
                              index > 0
                                ? `${array
                                    .slice(0, index)
                                    .reduce(
                                      (value, surface) => value + (surface.count * 100) / total,
                                      0,
                                    )}%`
                                : null,
                          }}
                        />
                      )
                    })
                : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  surfaces: store.surfaces,
  currentProject: store.currentProject,
}))(Header)
