import React from "react"
import { connect } from "react-redux"
import Loading from "../../../components/Loader"
import type { $Dispatcher, $State } from "../../../util/Types"
import { REQUEST_BOARD_PREPARATION } from "./Board/consts"
import Error from "../../../components/Error"
import type { $FetchParams } from "./consts"
import NotPermitted from "../../Permissions/NotPermitted"
type Props = {
  boardDetails: $State
  params: {
    board: string
  }
  fetchParams: $FetchParams
} & $Dispatcher

class BoardFetcher extends React.Component {
  props: Props

  componentDidMount() {
    this.fetch(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      (nextProps.params.board && nextProps.params.board !== this.props.params.board) ||
      nextProps.fetchParams.filter !== this.props.fetchParams.filter ||
      nextProps.fetchParams.interval.from !== this.props.fetchParams.interval.from ||
      nextProps.fetchParams.interval.to !== this.props.fetchParams.interval.to ||
      nextProps.fetchParams.timeCompare.from !== this.props.fetchParams.timeCompare.from ||
      nextProps.fetchParams.timeCompare.to !== this.props.fetchParams.timeCompare.to ||
      nextProps.fetchParams.timeCompare.comparing !==
        this.props.fetchParams.timeCompare.comparing ||
      nextProps.fetchParams.timeInterval !== this.props.fetchParams.timeInterval
    ) {
      // we just want to re-fetch the board we're not on the data explorer (creating/editing a chart)
      if (!nextProps.location.pathname.includes("new-chart")) this.fetch(nextProps)
    }
  }

  fetch(props: Props) {
    const { dispatch, fetchParams, params } = props
    dispatch({
      type: REQUEST_BOARD_PREPARATION,
      payload: {
        board: params.board,
        fetchParams,
      },
    })
  }

  render() {
    const { boardDetails } = this.props

    if (boardDetails.fetching || !boardDetails.fetched) {
      return <Loading />
    }

    if (boardDetails.error)
      if (boardDetails.error.notPermitted) return <NotPermitted />
      else
        return (
          <Error text="An error occured while fetching your board. Please refresh the page or try again later." />
        )
    if (boardDetails.fetched) return this.props.children
  }
}

export default connect(store => ({
  boardDetails: store.analytics_v2.boardDetails,
  fetchParams: store.analytics.params,
}))(BoardFetcher)
