import { FETCH_API_VERSIONS } from "^/Actions/ProjectsActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_API_VERSIONS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_API_VERSIONS): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        versions: action.payload.versions,
        current: action.payload.current_version,
        latest: action.payload.latest_version,
      }
    }
  }

  return state
}
