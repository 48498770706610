import React from "react"
type $Color = {
  name: string
  // Key name
  value: string // Color HEX
}
type Props = {
  colors: Array<$Color>
}
export class ChartLegend extends React.Component<Props> {
  render() {
    const { colors } = this.props
    return (
      <div
        className="row small-margin-bottom"
        style={{
          position: "relative",
          top: "-.5em",
        }}
      >
        <div className="large-10 large-centered columns text-center chart-legend">
          {colors
            .filter((_, index) => index < 10)
            .map(color => (
              <LegendUnit color={color} key={color.name} />
            ))}
        </div>
      </div>
    )
  }
}

class LegendUnit extends React.Component<{
  color: $Color
}> {
  render() {
    const { color } = this.props
    return (
      <div
        style={{
          width: "fit-content",
          display: "inline-block",
        }}
      >
        <div
          style={{
            display: "inline-block",
            marginRight: ".5em",
            marginLeft: "1em",
            height: ".5em",
            width: "1.5em",
            backgroundColor: color.value,
          }}
        />
        <div
          className="small-font"
          style={{
            display: "inline-block",
          }}
        >
          {color.name}
        </div>
      </div>
    )
  }
}

export default ChartLegend
