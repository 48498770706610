import React from "react"
import { FormattedNumber } from "react-intl"
import getSymbolFromCurrency from "currency-symbol-map"
import { connect } from "react-redux"
import Tooltip from "^/util/Tooltip"

type Props = {
  value: number | null | undefined
  style: string
  currency?: string
}
export function TelescopeCardValue({ value, style, currency }: Props) {
  const tooltipId = value?.toString() || Math.random().toString()
  let output = ""
  let fullNumber

  if (style === "percent") {
    return (
      <FormattedNumber
        value={value > 1 ? 1 : value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
        style="percent"
      />
    )
  }

  if (style !== "percent") {
    if (style !== "currency") {
      fullNumber = (
        <span
          className="bold"
          style={{
            fontSize: "16px",
          }}
        >
          <FormattedNumber value={value} />
        </span>
      )
    } else {
      fullNumber = (
        <span
          className="bold"
          style={{
            fontSize: "16px",
          }}
        >
          <FormattedNumber value={value} style="currency" currency={currency} />
        </span>
      )
    }
  }

  if (style === "currency") {
    output += getSymbolFromCurrency(currency)
  }

  output += Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value)
  return (
    <Tooltip content={fullNumber} id={tooltipId} size="medium" place="bottom" light>
      <span
        data-tip
        data-for={tooltipId}
        style={{
          display: "inline-block",
          cursor: "pointer",
        }}
      >
        {output.toLowerCase()}
      </span>
    </Tooltip>
  )
}
export default connect(store => ({
  currency: store.currentProject.project.default_currency,
}))(TelescopeCardValue)
