import React from "react"
import moment from "moment"
import { FormattedNumber } from "react-intl"
import type { $Report } from "./reducer"
type Props = {
  report: $Report
}

class Report extends React.Component<Props> {
  render() {
    const { report } = this.props
    const progression =
      report.status === "pending"
        ? 0
        : report.status === "processing" && report.progress_percentage
        ? report.progress_percentage / 100
        : 0
    return (
      <div className="row report-row">
        <div className="medium-3 columns">
          <span>{moment(report.created_at).calendar()}</span>
        </div>
        <div className="medium-7 columns ">
          <span>{report.description}</span>
        </div>

        <div className="medium-2 columns text-right">
          {report.status === "processing" || report.status === "pending" ? (
            <div className="progress-bar">
              <div
                className="content"
                style={{
                  width: `${progression * 100}%`,
                }}
              />
              <div className="progress-label">
                {report.status === "processing" ? (
                  <FormattedNumber value={progression} style="percent" />
                ) : (
                  <span>pending</span>
                )}
              </div>
            </div>
          ) : (
            <div className="medium-3 columns text-right">
              <span className={`log-level ${report.status === "processed" ? "success" : "failed"}`}>
                {report.status}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Report
