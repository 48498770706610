import React from "react"
import { connect } from "react-redux"
import Delete from "../../components/Delete"
import Error from "../../components/Error"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
import type { $PermissionGroup } from "./consts"
import PermissionGroupContent from "./PermissionGroupContent"
import type { $PermissionsState } from "./reducer"
type Props = {
  permissionGroupName: string
  permissions: $PermissionsState
} & $Dispatcher
type State = {
  groupName: string
  permissionGroup: $PermissionGroup | null | undefined
  collapsed: boolean
}
export class PermissionGroup extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      collapsed: true,
      groupName: "",
      permissionGroup: null,
    }
  }

  componentDidMount() {
    const { permissionGroupName, permissions } = this.props
    const permissionGroup = permissions.permissionGroups.permissionGroups.find(
      permissionGroup => permissionGroup.name === permissionGroupName,
    )
    this.setState({
      permissionGroup,
      groupName: permissionGroup.name,
    })
  }

  deleteGroup = () => {
    const { dispatch, permissionGroupName } = this.props
    dispatch(Actions.deletePermissionGroup(permissionGroupName))
  }
  handleChange = event => {
    const { permissionGroup } = this.state
    const { value } = event.target
    this.setState({
      groupName: value,
    })
    const { dispatch } = this.props
    dispatch(Actions.updateGroupName(permissionGroup.id || "", permissionGroup.name, value))
  }

  render() {
    const { permissions, permissionGroupName } = this.props
    const { permissionGroup, groupName, collapsed } = this.state
    const { fetchedState } = permissions.permissionGroups
    if (!permissionGroup) return <Error />
    return (
      <div
        className={`row ${collapsed ? "box-row clickable" : ""} permission-group`}
        onClick={() => {
          if (!collapsed) return
          this.setState({
            collapsed: !collapsed,
          })
        }}
      >
        <div className="medium-12 columns">
          <div className={`row ${!collapsed ? "small-margin-bottom" : ""}`}>
            <div className="medium-6 columns">
              {collapsed &&
              fetchedState.permissionGroups &&
              fetchedState.permissionGroups.find(
                permissionGroup => permissionGroup.name === permissionGroupName,
              ) ? (
                <div
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {permissionGroup.name}
                </div>
              ) : (
                <input type="text" value={groupName} onChange={this.handleChange} />
              )}
            </div>
            <div className="medium-5 columns text-right">
              <button
                type="button"
                onClick={() => {
                  this.setState({
                    collapsed: !collapsed,
                  })
                }}
              >
                {collapsed ? "Edit" : "Collapse"}
              </button>
            </div>
            <div className="medium-1 columns text-right">
              <Delete onDelete={this.deleteGroup} />
            </div>
          </div>
          {!collapsed && (
            <PermissionGroupContent permissions={permissions} group={permissionGroup} />
          )}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  permissions: store.permissions,
}))(PermissionGroup)
