import type { $TelescopeRequestAction } from "./consts"
import { REQUEST_TELESCOPE_PREPARATION } from "./consts"
import type { $Option } from "./Components/ChargebackGatewayConfigSelect"
import type { $Action } from "../../util/Types"
export const RESET_TELESCOPE_STATE = "RESET_TELESCOPE_STATE"

/**
 * Ask to reset all telescope data
 * @returns {{type: string}}
 */
export const resetTelescopeState = (): $Action => ({
  type: RESET_TELESCOPE_STATE,
})
export const FETCH_TELESCOPE_REQUESTED = "FETCH_TELESCOPE_REQUESTED"

/**
 * action to fetch telescope data (/telescope call)
 * @param timespan
 * @returns {{type: string, payload: {timespan: *}}}
 */
export function fetchData(timespan) {
  return {
    type: FETCH_TELESCOPE_REQUESTED,
    payload: {
      timespan,
    },
  }
}
export function requestTelescopePrepare(
  timespan: string,
  silent: boolean,
  tab: "auth" | "capt" | "refu" | "char",
): $TelescopeRequestAction {
  return {
    type: REQUEST_TELESCOPE_PREPARATION,
    payload: {
      timespan,
      silent,
      tab,
    },
  }
}
export const SELECT_SURFACES_TYPE = "SELECT_SURFACES_TYPE"
export const selectSurfacesType = (
  type: "authorization-rate" | "chargeback-rate" | "fees-rate",
) => ({
  type: SELECT_SURFACES_TYPE,
  payload: {
    type,
  },
})
export const REQUEST_CHARGEBACK_DATA_FETCH = "REQUEST_CHARGEBACK_DATA_FETCH"
export const requestChargebackDataFetch = (configId: string, timespan: string) => ({
  type: REQUEST_CHARGEBACK_DATA_FETCH,
  payload: {
    configId,
    timespan,
  },
})
export const SELECT_CHARGEBACK_GATEWAY_CONFIGURATION = "SELECT_CHARGEBACK_GATEWAY_CONFIGURATION"
export const selectChargebackGatewayConfiguration = (config: $Option) => ({
  type: SELECT_CHARGEBACK_GATEWAY_CONFIGURATION,
  payload: {
    config,
  },
})
