import React from "react"
import { connect } from "react-redux"
import ReactToolTip from "react-tooltip"
import { Link } from "react-router"
import moment from "moment"
import type { $Dispatcher } from "../../../../util/Types"
import type { $Board, $BoardDisplay, $DataPoint, $FetchParams } from "../consts"
import { REQUEST_BOARD_EDITION } from "../consts"
import Empty from "../../../../components/Empty"
import { REQUEST_BOARD_DUPLICATION } from "./consts"
import ComparableSelector from "./ComparableSelector"
import getColor from "../charts/colors"
import ModalFooter from "../../../../components/ModalFooter"
import { hasFeature } from "../../../FeatureFlags/FeatureFlags"
import BoardGrid from "../../BoardGrid/BoardGrid"
import ComparisonSelector from "../ComparisonSelector/ComparisonSelector"
import type { $BoardDetailsState } from "./reducer"
import * as Utils from "../../Charts/Utils"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import { track } from "../../../../vNext/tracking"
type Props = {
  board: $Board
  boardDisplay: $BoardDisplay
  fetchParams: $FetchParams
  boardDetails: $BoardDetailsState
} & $Dispatcher
type State = {
  keysMask: Array<string>
}

class Board extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      keysMask: [],
    }
  }

  updateKeysMask = (newSelected: Array<string>) => {
    const keysMask = this.props.boardDetails.selector_datapoints
      .filter(point => !newSelected.includes(point.key))
      .map(point => point.key)
    this.setState({
      keysMask,
    })
  }
  changeComparisonField = (newValue: string) => {
    const { boardDetails, dispatch } = this.props
    track("Analytics", "Select", "Board", "Compare with", {
      value: newValue,
      boardName: boardDetails.board?.name,
    })
    if (newValue === boardDetails.board.comparison_selector.plotted_field) return
    const newBoard = {
      ...boardDetails.board,
      comparison_selector: { ...boardDetails.board.comparison_selector, plotted_field: newValue },
    }
    delete newBoard.charts
    dispatch({
      type: REQUEST_BOARD_EDITION,
      payload: {
        newBoard,
        remount: true,
      },
    })
  }
  switchToNormal = () => {
    const { boardDetails, dispatch } = this.props
    const newBoard = { ...boardDetails.board, comparison_selector: null }
    delete newBoard.charts
    dispatch({
      type: REQUEST_BOARD_EDITION,
      payload: {
        newBoard,
        remount: true,
      },
    })
  }
  setToCompare = (field: string) => {
    const { boardDetails, dispatch } = this.props
    track("Analytics", "Select", "Board", "Compare with", {
      value: field,
      boardName: boardDetails.board?.name,
    })
    const newBoard: $Board = {
      ...boardDetails.board,
      comparison_selector: {
        formula: 'count{path: "transaction_operations";default: 0;}',
        plotted_field: `transaction_operations.${field}`,
      },
    }
    delete newBoard.charts
    dispatch({
      type: REQUEST_BOARD_EDITION,
      payload: {
        newBoard,
        remount: true,
      },
    })
  }

  render() {
    const { params, boardDetails } = this.props
    const { board } = boardDetails
    if (boardDetails.fetching || !boardDetails.fetched) return null
    const colors = boardDetails.selector_datapoints
      ? Utils.generateKeysColors(
          boardDetails.selector_datapoints.map((point: $DataPoint, index, array) => point.key),
        )
      : null
    const newChartButton = (
      <div
        className="row"
        style={{
          marginTop: "2em",
        }}
        data-tracking-location="Board"
      >
        <div className="large-12 columns text-center">
          <div
            style={{
              height: "240px",
              paddingTop: "95px",
              border: "3px dashed rgba(81, 99, 149, 1)",
              borderRadius: "4px",
              opacity: 0.7,
            }}
          >
            <Link
              data-auto-tracking={true}
              data-tracking-label="New chart"
              to={`/projects/${this.props.params.project}/boards/${this.props.params.board}/new-chart`}
              class="round border button"
            >
              <div>New chart</div>
            </Link>
          </div>
        </div>
      </div>
    )
    const empty =
      boardDetails.board.charts.length === 0 ? (
        <Empty
          text={
            <div>
              This board doesn&apos;t contain any chart. Go to the{" "}
              <Link to={`/projects/${params.project}/boards/${board.id}/new-chart`}>
                chart builder page
              </Link>{" "}
              to add one.
            </div>
          }
        />
      ) : null
    return (
      <div className="row">
        <div
          className="large-12 columns"
          style={{
            paddingRight: 0,
          }}
        >
          <ReactToolTip effect="solid" />
          <div className="row">
            <div className="large-12 columns text-center">
              {this.props.fetchParams.timeCompare.comparing ? (
                <div className="row margin-bottom">
                  <div className="large-12 columns text-center">
                    <div className="row">
                      <div className="large-6 columns text-right">
                        <div
                          style={{
                            width: "3em",
                            height: "1em",
                            marginRight: ".5em",
                            backgroundColor: `${getColor(0)}8C`,
                            display: "inline-block",
                          }}
                        />
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {moment.unix(this.props.fetchParams.timeCompare.from).calendar()} →{" "}
                          {moment.unix(this.props.fetchParams.timeCompare.to).calendar()}
                        </div>
                      </div>
                      <div className="large-6 columns text-left">
                        <div
                          style={{
                            width: "3em",
                            height: "1em",
                            marginRight: ".5em",
                            backgroundColor: getColor(0),
                            display: "inline-block",
                          }}
                        />
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {moment.unix(this.props.fetchParams.interval.from).calendar()} →{" "}
                          {moment.unix(this.props.fetchParams.interval.to).calendar()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : boardDetails.board.comparison_selector &&
                this.props.currentProject.fetched &&
                !boardDetails.editing &&
                !boardDetails.board.id.includes("board_default") ? (
                <div className="row margin-bottom">
                  <div className="large-12 columns">
                    <div className="row">
                      <div className="large-12 columns text-left">
                        <div className="row small-margin-bottom">
                          <div
                            className="large-5 columns"
                            style={{
                              paddingTop: "2em",
                            }}
                          >
                            <h5
                              style={{
                                display: "inline-block",
                              }}
                            >
                              Filter values
                            </h5>
                          </div>
                          <div className="large-7 columns text-right">
                            <ComparisonSelector
                              value={boardDetails.board.comparison_selector.plotted_field}
                              onChange={(newField: string | null | undefined) => {
                                // Update the comparison filter
                                if (newField) {
                                  this.changeComparisonField(newField)
                                  return
                                }

                                // Remove comparison
                                this.switchToNormal()
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="large-12 columns">
                            <ComparableSelector
                              points={boardDetails.selector_datapoints}
                              colors={colors}
                              onChange={this.updateKeysMask}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : boardDetails.board.id !== "board_default-customers" &&
                this.props.currentProject.fetched &&
                !this.props.boardDetails.editing &&
                !boardDetails.board.id.includes("board_default") ? (
                <div className="row">
                  <div className="large-12 columns text-right">
                    <ComparisonSelector value={null} onChange={this.setToCompare} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {empty}
          <BoardGrid
            params={{
              project: this.props.params.project,
            }}
            keysMask={this.state.keysMask}
            colors={colors}
          />
          {!this.props.boardDetails.editing ||
          !this.props.currentProject.project ||
          !hasFeature(this.props.currentProject.project.feature_flags, "analytics-v2") ||
          this.props.boardDetails.board.id.includes("board_default") ? null : (
            <div className="row">
              <div className="large-6 columns end">{newChartButton}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect((store, props) => ({
  boardDetails: store.analytics_v2.boardDetails,
  fetchParams: store.analytics.params,
  currentProject: store.currentProject,
  charts: store.charts,
}))(Board)

class DuplicateModal extends React.Component {
  props: {
    board: $Board
  } & $Dispatcher
  state: {
    newBoard: $Board | null | undefined
  }

  constructor() {
    super()
    this.state = {
      newBoard: null,
    }
  }

  duplicate = () => {
    const { newBoard } = this.state
    if (!newBoard) return
    newBoard.duplicate_from = this.props.board.id

    if (newBoard.comparison_selector && newBoard.comparison_selector.plotted_field) {
      newBoard.name = `${newBoard.name} per ${newBoard.comparison_selector.plotted_field.replace(
        "transaction_operations.",
        "",
      )}`
    }

    const { dispatch, currentProject } = this.props
    dispatch({
      type: REQUEST_BOARD_DUPLICATION,
      payload: {
        newBoard,
        projectId: currentProject.project.id,
      },
    })
    dispatch({
      type: CLOSE_MODAL,
    })
  }
  open = (newBoard: $Board) => {
    this.setState({
      newBoard,
    })
    const { dispatch } = this.props
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header: "Duplicate board",
        content: (
          <div className="row">
            <div className="large-10 columns large-centered">
              {this.props.board.name} is a default board. By continuing this board will be
              duplicated to allow you to edit it.
            </div>
          </div>
        ),
        footer: <ModalFooter submitTitle="Duplicate" submitCallback={this.duplicate} />,
      },
    })
  }

  render() {
    return null
  }
}

const DuplicateModalConfirm = connect(
  store => ({
    currentProject: store.currentProject,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(DuplicateModal)
