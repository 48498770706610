import React from "react"
import { connect } from "react-redux"
import _ from "underscore"
import ContentLayout from "./ContentLayout"
import * as EventsDetailsActions from "../Actions/EventsDetailsActions"
import Data from "../components/EventsDetails/Data"
import Webhook from "../components/EventsDetails/Webhook"
import Loader from "../components/Loader"
import Empty from "../components/Empty"
import Error from "../components/Error"
import HighLight from "../components/EventsDetails/Highlight"
import * as ProcessOut from "../util/ProcessOut"
import type { $Dispatcher } from "^/util/Types"
import type { $EventsTransactionState } from "^/stores/Reducers/EventsTransactionReducer"
type Props = {
  details: $EventsTransactionState
  params: {
    event: string
  }
} & $Dispatcher

class EventsDetails extends React.Component<Props> {
  constructor() {
    super()
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Event details | ProcessOut"
    this.props.dispatch(EventsDetailsActions.loadEvent(this.props.params.event))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.details.fetching && !nextProps.details.fetched && !nextProps.details.error) {
      this.props.dispatch(EventsDetailsActions.loadEvent(this.props.params.event))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    const { details } = this.props

    if ((details.fetching || !details.fetched) && !details.error) {
      return (
        <ContentLayout title={<h4>Event details</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    if (details.error) {
      return (
        <ContentLayout title={<h4>Event details</h4>}>
          <Error />
        </ContentLayout>
      )
    }

    const { event } = details
    let content = JSON.stringify(event.data, null, 4)
    // Escape content html to prevent xss
    content = _.escape(content)
    content = content.replace(
      /&quot;(tr_[a-z0-9]+)&quot;/gi,
      `<a class="json-link" target="_blank" href="#/projects/${ProcessOut.config.ProjectId}/transactions/$1">\"$1 &nearr;\"</a>`,
    )
    content = content.replace(
      /&quot;(cust_[a-z0-9]+)&quot;/gi,
      `<a class="json-link" target="_blank" href="#/projects/${ProcessOut.config.ProjectId}/customers/$1">\"$1 &nearr;\"</a>`,
    )
    content = content.replace(
      /&quot;(sub_[a-z0-9]+)&quot;/gi,
      `<a class="json-link" target="_blank" href="#/projects/${ProcessOut.config.ProjectId}/recurring/subscriptions/$1">\"$1 &nearr;\"</a>`,
    )
    let webhooks

    if (details.event.webhooks.length > 0) {
      webhooks = (
        <div className="large-12 columns">
          {event.webhooks.map(hook => (
            <Webhook webhook={hook} key={hook.id} />
          ))}
        </div>
      )
    } else {
      webhooks = <Empty text="No webhook available." />
    }

    return (
      <ContentLayout title={<h4>Event details</h4>}>
        <div className="row">
          <div className="large-5 columns">
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <h6 className="greyed capitalize font-console">Details</h6>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <Data event={event} />
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <hr />
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <h6 className="greyed capitalize font-console">Webhooks</h6>
              </div>
            </div>
            <div className="row">{webhooks}</div>
          </div>
          <div className="large-7 columns">
            <div className="row">
              <div className="large-12 medium-9 columns">
                <HighLight innerHTML>{`<pre><code>${content}</code></pre>`}</HighLight>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  details: store.eventDetails,
}))(EventsDetails)
