import * as ProcessOut from "^/util/ProcessOut"

export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS"
export function loadTransactions(filter, length, after, id) {
  filter = filter ? encodeURIComponent(filter) : ""
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_TRANSACTIONS,
    payload: ProcessOut.APIcallPromise(
      `/transactions?${bound}=${id}&limit=${length}&order=desc&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=card&filter=${filter}`,
      "GET",
      null,
    ),
  }
}
export const FETCH_TRANSACTION_DETAILS = "FETCH_TRANSACTION_DETAILS"
export const CREATE_REFUND = "CREATE_REFUND"
export function createRefund(transactionId, refund, callback, error) {
  return function (dispatch) {
    dispatch({
      type: CREATE_REFUND,
      payload: refundCreationPRomise(transactionId, refund, dispatch).then(response => {
        if ((response as any).data.success) callback()
        else error()
        return response
      }),
    })
  }
}

function refundCreationPRomise(transactionId, refund, dispatch) {
  return new Promise((resolve, reject) => {
    ProcessOut.APIcallPromise(`/transactions/${transactionId}/refunds`, "POST", refund).then(
      response => {
        Promise.all([
          dispatch(getRefunds(transactionId)),
          dispatch(loadTransactionDetails(transactionId)),
        ])
        resolve(response)
      },
    )
  })
}

export const FETCH_REFUNDS = "FETCH_REFUNDS"
export function getRefunds(transactionid) {
  return {
    type: FETCH_REFUNDS,
    payload: ProcessOut.APIcallPromise(`/transactions/${transactionid}/refunds`, "GET", null),
  }
}
export function loadTransactionDetails(id) {
  return function (dispatch) {
    dispatch({
      type: FETCH_TRANSACTION_DETAILS,
      payload: new Promise((resolve, reject) => {
        ProcessOut.APIcallPromise(
          `/transactions/${id}?expand[]=subscription&expand[]=operations.gateway_configuration.gateway&expand[]=gateway_configuration.gateway&expand[]=customer&expand[]=operations&expand[]=card.token&expand[]=invoice`,
          "GET",
          null,
        ).then(response => {
          if (response.data.success && response.data.transaction.gateway_configuration)
            dispatch(getRefunds(id))
          resolve(response)
          return response
        })
      }),
    })
  }
}
export const CAPTURE_TRANSACTION = "CAPTURE_TRANSACTION"
export function captureTransaction(transaction) {
  return function (dispatch) {
    return {
      type: CAPTURE_TRANSACTION,
      payload: ProcessOut.APIcallPromise(
        `/invoices/${transaction.invoice.id}/capture`,
        "POST",
        {},
      ).then(response => {
        if (response.data.success) {
          dispatch(loadTransactionDetails(transaction.id))
        }
      }),
    }
  }
}
export function voidTransaction(transaction) {
  return function (dispatch) {
    return {
      type: CAPTURE_TRANSACTION,
      payload: ProcessOut.APIcallPromise(
        `/invoices/${transaction.invoice.id}/void`,
        "POST",
        {},
      ).then(response => {
        if (response.data.success) {
          dispatch(loadTransactionDetails(transaction.id))
        }
      }),
    }
  }
}
