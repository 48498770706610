import React from "react"
import { connect } from "react-redux"
import isEmpty from "lodash/isEmpty"
import type { $Dispatcher } from "../../util/Types"
import * as fraudServicesConfigurationActions from "../../features/FraudServicesConfiguration/actions"
import * as ProcessOut from "../../util/ProcessOut"
import ModalFooter from "../ModalFooter"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $FraudConfig } from "../../features/FraudServicesConfiguration/consts"

const serialize = require("form-serialize")

type Props = {
  configuration: Array<$FraudConfig> | null | undefined
} & $Dispatcher
export class FraudConfigurationModal extends React.Component<Props> {
  _contentRef: any
  _modalFooterRef: any

  constructor() {
    super()
    this._contentRef = React.createRef()
    this._modalFooterRef = React.createRef()
  }

  getFraudSettings = () => {
    const fraud = this.props.fraudServices.fraud_services
    const { configuration } = this.props
    let fraudSettings
    fraudSettings = fraud.find(fraud => fraud.name === configuration.fraud_service_name)

    if (fraudSettings?.settings_instructions) {
      fraudSettings = fraudSettings.settings_instructions
    }

    return fraudSettings
  }
  handleSave = () => {
    const { configuration_name, settings } = this._contentRef.current.getData()

    const { dispatch } = this.props
    const configuration_id = this.props.configuration.id
    const fraudSettings = this.getFraudSettings()
    const newConfiguration = {
      configuration_name,
    }

    if (
      settings &&
      !isEmpty(settings) &&
      Object.keys(fraudSettings).every(key => settings[key]) &&
      configuration_name
    ) {
      newConfiguration.settings = settings
      dispatch(
        fraudServicesConfigurationActions.updateFraudConfiguration(
          newConfiguration,
          configuration_id,
          success => {
            if (success) {
              dispatch({
                type: CLOSE_MODAL,
              })
              dispatch(
                fraudServicesConfigurationActions.fetchFraudServicesConfig(
                  ProcessOut.config.ProjectId,
                ),
              )
            }
          },
        ),
      )
    } else {
      ProcessOut.addNotification(
        "You must fill in your settings to edit your Fraud Service. ",
        "error",
      )
    }
  }
  openModal = () => {
    const { configuration } = this.props
    const fraudSettings = this.getFraudSettings()
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: (
          <div className="row">
            <div className="medium-12 columns text-center">
              <div className="row small-margin-bottom">
                <div className="medium-12 columns">{configuration.name}</div>
              </div>
              <div className="row">
                <div className="medium-12 columns greyed small-font font-console">
                  {configuration.id}
                </div>
              </div>
            </div>
          </div>
        ),
        content: (
          <ModalContent
            fraudSettings={fraudSettings}
            configuration={configuration}
            ref={this._contentRef}
          />
        ),
        footer: (
          <div className="row">
            <div className="medium-12 columns">
              <ModalFooter
                submitTitle="Save"
                submitCallback={this.handleSave}
                ref={this._modalFooterRef}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return <div id="config-modal" />
  }
}
export default connect(
  store => ({
    fraudServices: store.fraudServices,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(FraudConfigurationModal)
export class ModalContent extends React.Component<
  {
    configuration: Array<$FraudConfig> | null | undefined
    fraudSettings: Record<string, any> | null | undefined
  },
  {
    configuration_name: string
  }
> {
  constructor() {
    super()
    this.state = {
      configuration_name: "",
      settings: {},
      isEditing: false,
    }
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({
      configuration_name: configuration.name,
    })
  }

  editFraudSettings = () => {
    const form = document.querySelector("#fraud-config-form")
    const config = serialize(form, {
      hash: true,
    })
    this.setState({
      settings: config,
    })
  }
  toggleIsEditing = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    })
  }
  getData = () => ({ ...this.state })

  render() {
    const { fraudSettings } = this.props
    const { configuration_name } = this.state
    const fraudName = this.props.configuration.fraud_service_name
    const content = []

    for (const [key, value] of Object.entries(fraudSettings)) {
      content.push(
        <div className="medium-12 columns" key={key}>
          <div className="row small-margin-bottom">
            <label className="text-left greyed">{key}</label>
            <input
              type="text"
              name={key}
              placeholder={value}
              onChange={this.editFraudSettings}
              className="bottom-border processor-input"
            />
          </div>
        </div>,
      )
    }

    return (
      <div className="row">
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="callout alert">
              <div>
                You must fill in your settings to edit your {fraudName.toUpperCase()} fraud service.
              </div>
            </div>
            <div className="medium-4 columns">Name</div>
            <div className="medium-8 columns text-right">
              <input
                type="text"
                value={configuration_name}
                onChange={event => {
                  this.setState({
                    configuration_name: event.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
        <div className="row small-margin-bottom">
          <div
            className="medium-6 columns"
            style={{
              position: "relative",
              top: ".5em",
            }}
          >
            Modify your settings
          </div>
          <div className="medium-6 columns text-right">
            <a className="round border small button" onClick={this.toggleIsEditing}>
              Edit
            </a>
          </div>
        </div>
        {this.state.isEditing && (
          <div className="large-12 columns">
            <hr />
            <form id="fraud-config-form" autoComplete="off">
              {content}
            </form>
          </div>
        )}
      </div>
    )
  }
}
