import type { $Action, $State } from "^/util/Types"
type $Currency = {
  code: string
  value: number
}
export type CurrenciesState = {
  currencies?: Array<$Currency>
} & $State
const defaultState = {
  fetching: false,
  fetched: false,
}
export default function (state: CurrenciesState = defaultState, action: $Action) {
  switch (action.type) {
    case "FETCH_CURRENCIES_PENDING": {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case "FETCH_CURRENCIES_FULFILLED": {
      return { ...state, fetching: false, fetched: true, currencies: action.payload.currencies }
    }

    case "FETCH_CURRENCIES_REJECTED": {
      return { ...state, error: true, fetched: false, fetching: false }
    }
  }

  return state
}
