import type { $CurrentProject, $Dispatcher, $RouterLocation } from "^/util/Types"
import type {
  $ChartDatasFromApi,
  $PerCountryTableData,
  $Period,
  $TelescopeMetricData,
} from "../consts"

import ContentLayout from "^/pages/ContentLayout"
import Loading from "../../../components/Loader"
import { prepareTelescopeV2Page } from "../actions"
import BarChartTooltip from "../components/BarChartTooltip"
import PerCountryTable from "../components/PerCountryTable"
import SummaryCards from "../components/SummaryCards/SummaryCards"
import TelescopeHeader from "../components/TelescopeHeader"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"
import DomainsSecureLineCharts from "./DomainsSecureLineCharts"

import * as React from "react"
import { useEffect } from "react"
import { connect } from "react-redux"

export type Metrics = {
  triggered_3ds_amount: number
  triggered_3ds_count: number
  authentication_transaction_amount: number
  authentication_transaction_count: number
  failed_3ds_amount: number
  failed_3ds_count: number
  declined_rate: $ChartDatasFromApi[]
  domains_secure_trigger_rate: number
  domains_secure_trigger_rate_increase: number
  success_3ds_rate: number
  success_3ds_rate_increase: number
  success_3ds_rate_chart: $ChartDatasFromApi[]
  authentication_rate_by_gateway: $ChartDatasFromApi[]
  authentication_rate_by_card_scheme: $ChartDatasFromApi[]
  authentication_rate_by_payment_method: $ChartDatasFromApi[]
  tableData?: Array<$PerCountryTableData>
  fetched: boolean
  fetching: boolean
}

type Props = {
  location: $RouterLocation
  currentProject: $CurrentProject
  metricsTelescope: Metrics
} & $Dispatcher

export function DomainsSecure({ metricsTelescope, dispatch, location, currentProject }: Props) {
  useEffect(() => {
    document.title = "3DS Success | ProcessOut"
  }, [])

  React.useEffect(() => {
    dispatch(prepareTelescopeV2Page("domainsSecure", location.query.period as $Period))
  }, [location.query])

  const summaryMetrics: $TelescopeMetricData[] = [
    {
      title: "3DS Success rate",
      id: "authentication_rate_id",
      tooltipContent:
        "Number of successful 3DS transaction checks / number of initiated 3DS checks",
      value: metricsTelescope.success_3ds_rate,
      valueStyle: "percent",
      percentageEvolution: metricsTelescope.success_3ds_rate_increase,
    },
    {
      title: "3DS Trigger rate",
      id: "3ds_trigger_rate_id",
      tooltipContent: "Number of transactions where 3DS has been triggered",
      value: metricsTelescope.domains_secure_trigger_rate,
      valueStyle: "percent",
      percentageEvolution: metricsTelescope.domains_secure_trigger_rate_increase,
    },
    {
      title: "Triggered 3DS transactions",
      id: "triggered_3ds_id",
      tooltipContent: "Total value and volume of transactions where 3DS has been triggered",
      value: metricsTelescope.triggered_3ds_amount,
      valueStyle: "currency",
      count: metricsTelescope.triggered_3ds_count,
      moreDetails: true,
    },
    {
      title: "Authenticated transactions",
      id: "authenticated_transaction_id",
      tooltipContent: "Total value and volume of transactions that have been authenticated",
      value: metricsTelescope.authentication_transaction_amount,
      valueStyle: "currency",
      count: metricsTelescope.authentication_transaction_count,
      moreDetails: true,
    },
    {
      title: "Failed authentication",
      id: "failed_authentication_id",
      tooltipContent:
        "Total value and volume of transactions where 3DS has been triggered, but failed",
      value: metricsTelescope.failed_3ds_amount,
      valueStyle: "currency",
      count: metricsTelescope.failed_3ds_count,
      moreDetails: true,
    },
  ]

  const lineChartData = [
    { data: metricsTelescope.success_3ds_rate_chart, chartTitle: "3DS Success rate" },
    { data: metricsTelescope.declined_rate, chartTitle: "Decline rate" },
  ]

  const barChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.authentication_rate_by_gateway,
      tooltipContent: "Breakdown of authentication rate per payment service provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.authentication_rate_by_card_scheme,
      tooltipContent: "Breakdown of authentication rate per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.authentication_rate_by_payment_method,
      tooltipContent: "Breakdown of authentication rate per card type",
      id: "payment_method_id",
    },
  ]

  const loading = !metricsTelescope.fetched || metricsTelescope.fetching

  return (
    <div data-tracking-sub-page="3DS Success">
      <ContentLayout
        title={<TelescopeHeader title="3DS Success" />}
        noBackground
        padding="0"
        data-tracking-location="Summary"
      >
        <div className="large-margin-bottom small-margin-top">
          <SummaryCards data={summaryMetrics} loading={loading} size={2} />
        </div>
      </ContentLayout>
      {loading ? (
        <Loading />
      ) : (
        <ContentLayout data-tracking-location="Analytics">
          <div className="small-margin-bottom">
            <DomainsSecureLineCharts datas={lineChartData} />
          </div>
          <PerCountryTable
            tableData={metricsTelescope.tableData}
            currentProject={currentProject}
            title="Authentication rate per country"
            tooltipContent="Breakdown of authentication rate per country"
          />
          <div className="large-margin-top row">
            {barChartsData.map(data => (
              <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                <BarChartTooltip value={data} />
                <BarChartHorizontal
                  datas={chartFormattedData(data.value)}
                  axisLeft={150}
                  name={data.name}
                />
              </div>
            ))}
          </div>
        </ContentLayout>
      )}
    </div>
  )
}

export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.domainsSecure,
  currentProject: store.currentProject,
}))(DomainsSecure)
