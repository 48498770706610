import React from "react"
import { connect } from "react-redux"
import qs from "qs"
import MenuButton from "./MenuButton"
import SubButton from "./SubButton"
import * as ProcessOut from "../../util/ProcessOut"
import { hasFeature } from "../../features/FeatureFlags/FeatureFlags"
import "./SideMenuStyle.scss"
import { withRouter } from "react-router"
import SandboxSwitch from "../../features/SandboxSwitch"
import UserTag from "../../features/UserTag/UserTag"

export class SideMenu extends React.Component {
  constructor() {
    super()
    this.state = {
      sandbox: ProcessOut.config.sandbox,
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      currentProject,
      boards,
      analyticsParams,
      location,
      params,
      user,
      sandbox,
      launchDarkly,
    } = this.props
    const oldFeatureFlags = currentProject?.project?.feature_flags
    // we get the current applied filter to generate its query string to insert it into each board's link
    const query = {
      filter: analyticsParams.filter,
    }
    const queryString = qs.stringify(query)
    const analytics = currentProject.project && (
      <MenuButton
        location={location}
        route="boards"
        name="Anlytics"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/monitoring`}
        externalLink
      />
    )

    let settings
    let applePay
    const isNewDashSettingsEnabled = launchDarkly.flags["new-dash-settings-page"]
    const isNewDashApplePayEnabled = launchDarkly.flags["new-dash-apple-pay"]

    if (!sandbox) {
      settings = (
        <MenuButton
          location={location}
          route="settings"
          name="Settings"
          to={
            isNewDashSettingsEnabled
              ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/settings/project`
              : `/projects/${params.project}/settings/general`
          }
          externalLink={isNewDashSettingsEnabled}
          launchDarkly={launchDarkly}
        >
          <SubButton
            location={location}
            route="general"
            name="General"
            to={`/projects/${params.project}/settings/general`}
          />
          <SubButton
            location={location}
            route="team"
            name="Team"
            to={`/projects/${params.project}/settings/team`}
          />
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "subscriptions-activated") && (
              <SubButton
                location={location}
                route="dunning"
                name="Dunning"
                to={`/projects/${params.project}/settings/dunning`}
              />
            )}
        </MenuButton>
      )
      const hasApplePay = !!currentProject.project?.applepay_settings?.merchant_id
      const redirectToApplePayForm = "/add?source=myConnections&gateway=applepay"
      const redirection = hasApplePay ? "?redirectedFrom=oldApplePay" : redirectToApplePayForm
      applePay = !isNewDashApplePayEnabled ? (
        <MenuButton
          location={location}
          route="apple-pay"
          name="Apple Pay"
          to={`/projects/${params.project}/settings/apple-pay`}
        />
      ) : (
        <MenuButton
          location={location}
          externalLink
          route="apple-pay"
          name="Apple Pay"
          to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers${redirection}`}
        />
      )
    }

    // Feature flags might not be ready when Sidemenu is renderred (It actually re-renders multiple times).
    // But it is has less visual impact when a small menu is inserted.
    const routing = (
      <MenuButton
        location={location}
        name="Routing"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/routing`}
        externalLink
      />
    )

    const transactions = (
      <MenuButton
        location={location}
        name="Transactions"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/transactions`}
        externalLink
      />
    )

    const products = (
      <MenuButton
        location={location}
        name="Products"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payment-links`}
        externalLink
      />
    )

    const exports = (
      <MenuButton
        location={location}
        name="Exports"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/exports`}
        externalLink
        className="subButton"
      />
    )

    const payouts = (
      <MenuButton
        location={location}
        name="Payouts"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payouts`}
        externalLink
        className="subButton"
      />
    )

    const uploads = (
      <MenuButton
        location={location}
        name="Uploads"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/uploads`}
        externalLink
        className="subButton"
      />
    )

    let reportUrl = `/projects/${params.project}/reports/payouts`
    let isReportUrlExternal = false

    if (launchDarkly.flags["new-dash-payouts-page"]) {
      reportUrl = `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payouts`
      isReportUrlExternal = true
    }

    const providers = (
      <MenuButton
        location={location}
        externalLink
        name="Payment providers"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers`}
      />
    )

    const fraudServices = (
      <MenuButton
        location={location}
        externalLink
        name="Fraud services"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers`}
      />
    )

    const reports = (
      <MenuButton
        location={location}
        route="reports"
        name="Reports"
        to={reportUrl}
        externalLink={isReportUrlExternal}
      >
        {uploads}
        {payouts}
        {exports}
      </MenuButton>
    )

    return (
      <nav className="main-side side-menu">
        <ButtonGroup name="monitoring">
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "alerts") && (
              <MenuButton
                location={location}
                route="alerting"
                name={
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      Alerting
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#D1C4E9",
                        color: "#512DA8",
                        padding: ".2em .5em .2em .5em",
                        marginLeft: "1em",
                        fontWeight: "bold",
                        fontSize: ".7em",
                        borderRadius: "4px",
                      }}
                    >
                      NEW
                    </div>
                  </div>
                }
                to={`/projects/${params.project}/alerting`}
              >
                <SubButton
                  location={location}
                  route="alerts"
                  name="Alerts"
                  to={`/projects/${params.project}/alerting/alerts`}
                />
                <SubButton
                  location={location}
                  route="events"
                  name="Events"
                  to={`/projects/${params.project}/alerting/events`}
                />
              </MenuButton>
            )}
          {analytics}
        </ButtonGroup>
        <ButtonGroup name="routing & connections">
          {providers}
          {currentProject.fetched && !currentProject.error && routing}
          {fraudServices}
        </ButtonGroup>
        <ButtonGroup name="Payments">
          {transactions}
          {products}

          {!launchDarkly.flags["new-dash-customers-page"] && (
            <MenuButton
              location={location}
              route="customers"
              name="Customers"
              to={`/projects/${params.project}/customers`}
            />
          )}
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "subscriptions-activated") && (
              <MenuButton
                location={location}
                route="recurring"
                name="Recurring billing"
                to={`/projects/${params.project}/recurring/subscriptions`}
              >
                <SubButton
                  location={location}
                  route="subscriptions"
                  name="Subscriptions"
                  to={`/projects/${params.project}/recurring/subscriptions`}
                />
                <SubButton
                  location={location}
                  route="plans"
                  name="Plans"
                  to={`/projects/${params.project}/recurring/plans`}
                />
                <SubButton
                  location={location}
                  route="coupons"
                  name="Coupons"
                  to={`/projects/${params.project}/recurring/coupons`}
                />
              </MenuButton>
            )}
        </ButtonGroup>
        <ButtonGroup name="account">
          {reports}
          <MenuButton
            location={location}
            route="developer"
            name="Developer API"
            to={
              launchDarkly.flags["new-dash-api-setup-page"]
                ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/api-keys`
                : `/projects/${params.project}/developer/api-setup`
            }
            externalLink={launchDarkly.flags["new-dash-api-setup-page"]}
          >
            <SubButton
              location={location}
              route="api-setup"
              name="API Setup"
              to={
                launchDarkly.flags["new-dash-api-setup-page"]
                  ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/api-keys`
                  : `/projects/${params.project}/developer/api-setup`
              }
            />
            <SubButton
              location={location}
              route="events"
              name="Events"
              to={
                launchDarkly.flags["new-dash-events-page"]
                  ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/events`
                  : `/projects/${params.project}/developer/events`
              }
            />
          </MenuButton>
          {settings}
          {applePay}
          <li
            style={{
              marginTop: "2em",
            }}
          >
            <SandboxSwitch />
          </li>
          <li>
            <UserTag />
          </li>
        </ButtonGroup>
      </nav>
    )
  }
}

class ButtonGroup extends React.Component {
  props: {
    name: string
    children: any
  }

  render() {
    const { children, name } = this.props
    if (children.filter(child => child !== null).length === 0) return null
    return (
      <div className="group">
        <div className="row">
          <div className="medium-12 columns">
            <div className="row">
              <div className="medium-12 columns">
                <span className="group-name">{name.toUpperCase()}</span>
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns">
                <ul>{children}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  permissions: store.permissions,
  boards: store.analytics_v2.boards,
  currentProject: store.currentProject,
  analyticsParams: store.analytics.params,
  moderators: store.moderators,
  user: store.user,
  launchDarkly: store.launchDarkly,
}))(withRouter(SideMenu))
