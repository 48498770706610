import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import { Link } from "react-router"
import Check from "../../features/Checks/Check"
import DateDisplay from "../../features/DateDisplay/index"
import type { TransactionModel } from "../../Types/Transaction"
import Tooltip from "../../util/Tooltip"
import AvsCheck from "./AvsCheck"
type Props = {
  projects?: any
  transaction: TransactionModel
}
export class TransactionDetailsComponent extends React.Component<Props> {
  getLine(title: string, value: any) {
    return (
      <div className="row small-margin-bottom">
        <div className="large-4 medium-4 columns greyed">{title}</div>
        <div className="large-8 medium-8 columns">
          <span className="right">{value || <span className="greyed">--</span>}</span>
        </div>
      </div>
    )
  }

  formatInterval(interval: number) {
    if (interval === 1) return "daily"
    if (interval === 7) return "weekly"
    if (interval === 30) return "monthly"
    return (
      <span>
        every <span className="bold">{interval}</span> days
      </span>
    )
  }

  render() {
    const { transaction } = this.props
    const { currency } = transaction

    if (transaction.id === 0) {
      return <div />
    }

    let sub

    if (transaction.subscription) {
      sub = (
        <div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-3 columns greyed">Subscription</div>
            <div className="medium-9 columns">
              <span className="right light-bold">
                <Link
                  to={`/projects/${
                    this.props.projects ? this.props.projects.current_project_id : ""
                  }/recurring/subscriptions/${transaction.subscription.id}`}
                >
                  {transaction.subscription.name}↗
                </Link>
              </span>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-3 columns greyed">Interval</div>
            <div className="medium-9 columns">
              <span className="right">
                {this.formatInterval(transaction.subscription.interval_days)}
              </span>
            </div>
          </div>
        </div>
      )
    }

    let listAvsChecks
    const { avs_name_check, avs_postal_check, avs_street_check } = transaction.card_checks

    if (transaction.card_checks) {
      listAvsChecks = (
        <div>
          <AvsCheck title="Name" check={<Check check={avs_name_check || "UNKNOWN"} />} />
          <br />
          <AvsCheck title="Address" check={<Check check={avs_postal_check || "UNKNOWN"} />} />
          <br />
          <AvsCheck title="ZIP" check={<Check check={avs_street_check || "UNKNOWN"} />} />
        </div>
      )
    }

    return (
      <div className="row">
        <div className="large-12 medium-8 columns">
          {this.getLine(
            "Invoice ID",
            <span className="font-console small">{transaction.invoice_id}</span>,
          )}
          {this.getLine(
            "Available",
            <span className="bold">
              <FormattedNumber
                value={transaction.available_amount}
                style="currency"
                currency={currency}
              />
            </span>,
          )}
          {this.getLine(
            "Created on",
            <DateDisplay value={transaction.created_at} relativeSpan="day" />,
          )}
          {this.getLine(
            "Fees",
            <FormattedNumber
              value={transaction.gateway_fee ? transaction.gateway_fee : 0}
              style="currency"
              currency={currency}
            />,
          )}
          {this.getLine(
            "Shipping",
            <FormattedNumber
              value={
                transaction.metadata.shipping_amount ? transaction.metadata.shipping_amount : 0
              }
              style="currency"
              currency={transaction.currency}
            />,
          )}
          {this.getLine(
            "Taxes",
            <FormattedNumber
              value={transaction.metadata.taxes_amount ? transaction.metadata.taxes_amount : 0}
              style="currency"
              currency={transaction.currency}
            />,
          )}
          {transaction.avs_check && (
            <div className="row small-margin-bottom">
              <div className="medium-4 columns greyed text-left">
                <div
                  style={{
                    display: "inline-block",
                  }}
                >
                  AVS check
                </div>
                <Tooltip
                  content="Address check performed for this transaction specifically."
                  id="avsTransactionCheck"
                  size="large"
                  place="right"
                >
                  <div
                    className="info"
                    data-tip
                    data-for="avsTransactionCheck"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    i
                  </div>
                </Tooltip>
              </div>
              <div className="medium-6 columns text-right">
                <Tooltip content={listAvsChecks} id="detailsAvs" size="medium">
                  <div
                    className="info"
                    data-tip
                    data-for="detailsAvs"
                    style={{
                      display: "inline-block",
                      marginRight: "0.75em",
                    }}
                  >
                    i
                  </div>
                </Tooltip>
                <Check check={transaction.avs_check} />
              </div>
            </div>
          )}
          {transaction.cvc_check && (
            <div className="row small-margin-bottom">
              <div className="medium-4 columns greyed text-left">
                <div
                  style={{
                    display: "inline-block",
                  }}
                >
                  CVC check
                </div>
                <Tooltip
                  content="CVC check performed for this transaction specifically."
                  id="cvcTransactionCheck"
                  size="large"
                  place="right"
                >
                  <div
                    className="info"
                    data-tip
                    data-for="cvcTransactionCheck"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    i
                  </div>
                </Tooltip>
              </div>
              <div className="medium-8 columns text-right">
                <Check check={transaction.cvc_check} />
              </div>
            </div>
          )}
          {this.getLine("Currency", <span className="bold">{transaction.currency}</span>)}
          {this.getLine("Payment type", transaction.payment_type)}
          {this.getLine(
            "Merchant initiator type",
            transaction.invoice && transaction.invoice.merchant_initiator_type,
          )}
          {this.getLine("ECI", transaction.eci)}
          {this.getLine("MCC", transaction.mcc)}
          {this.getLine("Merchant account", transaction.merchant_account)}
          {this.getLine("Acquirer", transaction.acquirer_name)}
          {this.getLine("Is on us", transaction.is_on_us)}
          {sub}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  projects: store.projects,
}))(TransactionDetailsComponent)
