import React, { Component } from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import Second from "./AdyenSecond"
import First from "./AdyenFirst"
import AdyenThird from "./AdyenThird"
import AdyenFlow from "./AdyenFlow"
import type { $GatewayConfiguration } from "../../PaymentProviders/consts"
export class Adyen extends Component<
  {
    configuration?: $GatewayConfiguration
    projectId: string
    isForTelescope?: boolean | null | undefined
    currentProject: {
      project:
        | {
            currency: string
          }
        | null
        | undefined
    }
  },
  {
    gatewayConfId: string
    page: "first" | "second" | "third" | "flow-choice"
    flow: "telescope" | "smart-router"
    allowOnboarding: boolean
  }
> {
  constructor() {
    super()
    this.state = {
      gatewayConfId: "",
      page: "flow-choice",
      flow: "telescope",
      allowOnboarding: false,
    }
  }

  componentDidMount() {
    if (this.props.isForTelescope) {
      this.setState({
        page: "first",
        flow: "telescope",
      })
    }
  }

  togglePages(page: "first" | "second" | "third") {
    this.setState({
      page,
    })
  }

  render() {
    const { configuration } = this.props
    const { page } = this.state
    let button

    switch (page) {
      case "flow-choice": {
        button = null
        break
      }

      case "first": {
        if (
          this.state.allowOnboarding ||
          (this.state.flow !== "telescope" && !this.props.isForTelescope)
        )
          button = (
            <a
              className="small rounded border button"
              onClick={this.togglePages.bind(this, "second")}
            >
              Next
            </a>
          )
        else button = null
        break
      }

      case "second": {
        button = (
          <a className="small rounded border button" onClick={this.togglePages.bind(this, "third")}>
            Next
          </a>
        )
        break
      }

      case "third": {
        button = <input type="submit" className="small rounded border button" value="Let's go!" />
        break
      }
    }

    let back

    if (page === "second" || page === "third") {
      back = (
        <a
          onClick={this.togglePages.bind(this, page === "second" ? "first" : "second")}
          className="small rounded border button"
        >
          Back{" "}
        </a>
      )
    }

    return (
      <div className="large-10 columns large-centered">
        <div className="row">
          <ReactTooltip className="gif-tooltip" place="left" effect="solid" />
          {this.props.isForTelescope ? null : (
            <AdyenFlow
              hide={page !== "flow-choice"}
              onChange={flow => {
                this.setState({
                  flow,
                })
                this.togglePages("first")
              }}
            />
          )}
          <First
            hide={page !== "first"}
            togglePages={this.togglePages.bind(this)}
            allowOnboarding={this.state.allowOnboarding}
            onAllowOnboarding={() => {
              this.setState({
                allowOnboarding: true,
              })
            }}
            isForTelescope={this.state.flow === "telescope" || this.props.isForTelescope}
          />
          <Second
            hide={page !== "second"}
            gatewayId={this.state.gatewayConfId}
            projectId={this.props.projectId}
            configId={configuration ? configuration.id : null}
          />
          <AdyenThird
            hide={page !== "third"}
            isForTelescope={this.state.flow === "telescope" || this.props.isForTelescope}
            currency={
              this.props.currentProject.project ? this.props.currentProject.project.currency : "USD"
            }
          />
          {this.props.isForTelescope || this.state.flow === "telescope" ? (
            <div>
              <input type="hidden" name="merchant_account" value="processout" />
              <input
                type="hidden"
                name="ws_username"
                placeholder="Account username"
                value="processout"
              />
              <input type="hidden" name="ws_password" placeholder="Password" value="processout" />
            </div>
          ) : null}
          <div className="row">
            <div className="large-6 columns margin-bottom">{back}</div>
            <div className="large-6 columns margin-bottom text-right">{button}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(Adyen)
