import React from "react"
import { connect } from "react-redux"
import * as GatewaysActions from "../../Actions/GatewaysActions"
import * as ConfirmActions from "../ConfirmModal/actions"
import ConfigurationModal from "./ConfigurationModal"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $GatewayConfiguration } from "./consts"
import type { RoutingRulesState } from "^/features/RoutingRules/reducer"
type Props = {
  config: $GatewayConfiguration
  routingRulesSettings: RoutingRulesState
} & $Dispatcher
type State = {
  activated: boolean
  work: string
}

class Configuration extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      activated: false,
      work: "",
    }
    this._configModal = React.createRef()
  }

  componentDidMount() {
    this.setState({
      activated: this.props.config.enabled,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      activated: nextProps.config.enabled,
    })
  }

  switchChanged(config, event) {
    event.preventDefault()
    const checkbox = document.getElementById(config.id)

    if (checkbox.checked) {
      this.setState({
        work: "ENABLING",
      })
      this.props.dispatch(
        GatewaysActions.enableGateway(config.gateway.name, config.id, success => {
          this.setState({
            work: "",
          })

          if (success) {
            this.setState({
              activated: true,
            })
          } else {
            this.setState({
              activated: false,
            })
          }
        }),
      )
    } else {
      const disableGateway = () => {
        this.props.dispatch(
          ConfirmActions.requestConfirmation(
            <div className="row">
              <div className="large-11 columns large-centered">
                Disabling a gateway configuration will prevent transactions from being processed
                with that specific configuration. Are you sure you want to proceed?
              </div>
            </div>,
            () => {
              this.setState({
                work: "DISABLING",
              })
              this.props.dispatch(
                GatewaysActions.disableGateway(config.gateway.name, config.id, success => {
                  this.setState({
                    work: "",
                  })

                  if (success) {
                    this.setState({
                      activated: false,
                    })
                  } else {
                    this.setState({
                      activated: true,
                    })
                  }
                }),
              )
            },
            () => {},
          ),
        )
      }

      if (this.isGatewayUsedInRouting(config)) {
        this.props.dispatch(
          ConfirmActions.requestConfirmation(
            <div
              className="row small-margin-bottom "
              style={{
                paddingTop: "1.5em",
              }}
            >
              <div className="row small-margin-bottom">
                <div className="large-11 large-centered columns">
                  <p>Are you sure you want to disable this gateway configuration?</p>
                </div>
              </div>
              <div className="row">
                <div className="large-11 large-centered columns text-left">
                  <p
                    style={{
                      color: "#e74c3c",
                    }}
                  >
                    <strong>Warning</strong>: This configuration is used in one of your static
                    rules.
                  </p>
                </div>
              </div>
            </div>,
            disableGateway,
            () => {
              // User canceled
            },
          ),
        )
      } else {
        disableGateway()
      }
    }
  }

  isGatewayUsedInRouting = config =>
    this.props.routingRulesSettings.rules.reduce(
      (usedInRouting, rule) =>
        usedInRouting ||
        rule.gateways.reduce(
          (usedInRule, gateway) => usedInRule || gateway.gateway === config.id,
          false,
        ),
      false,
    )

  confirmDelete(config, event) {
    event.preventDefault()
    this.props.dispatch(
      GatewaysActions.deleteGatewayConfig(config.gateway.name, config.id, () => {
        this.props.dispatch({
          type: CLOSE_MODAL,
        })
        this.props.dispatch(GatewaysActions.loadGatewaysConfigurations())
      }),
    )
  }

  deleteConfig(config, event) {
    event.preventDefault()
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: `Delete ${config.gateway.display_name} configuration?`,
        content: (
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div
              className="row small-margin-bottom"
              style={{
                paddingTop: "1.5em",
              }}
            >
              <div className="large-12 columns text-center">
                <p>Are you sure you want to delete this configuration?</p>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">ID:</div>
              <div className="large-10  columns greyed text-right">
                <span className="console-font small-font">{config.id}</span>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">Gateway:</div>
              <div className="large-10  columns greyed text-right">
                {config.gateway.display_name}
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-2 columns">Status:</div>
              <div className="large-10  columns greyed text-right">
                {config.enabled ? <span className="bold">Enabled</span> : <span>Disabled</span>}
              </div>
            </div>
            {this.isGatewayUsedInRouting(config) ? (
              <div
                className="row small-margin-bottom "
                style={{
                  paddingTop: "1.5em",
                }}
              >
                <div className="large-12 columns text-left">
                  <p
                    style={{
                      color: "#e74c3c",
                    }}
                  >
                    <strong>Warning</strong>: This configuration is used in one of your static
                    rules.
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        ),
        footer: (
          <div className="row">
            <div className="large-12 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Cancel
              </a>
              <input
                type="button"
                onClick={this.confirmDelete.bind(this, config)}
                className="round small main button white-text"
                value="Delete"
              />
            </div>
          </div>
        ),
      },
    })
  }

  openModal(event) {
    event.preventDefault()
    const { config } = this.props

    this._configModal.current.getWrappedInstance().openModal()
  }

  render() {
    const { config } = this.props
    let status

    if (this.state.work === "ENABLING") {
      status = "Enabling"
    } else if (this.state.work === "DISABLING") {
      status = "Disabling"
    } else if (this.state.activated) {
      status = "This configuration is currently enabled"
    }

    return (
      <div className="payment-processor-wrapper details-box">
        <ConfigurationModal configuration={config} ref={this._configModal} />
        <a onClick={this.openModal.bind(this)}>
          <div className="row">
            <div className="large-12 columns text-center img-wrapper">
              <img
                alt={`${config.gateway.display_name}'s logo`}
                className={this.state.activated ? "" : "desaturate"}
                src={config.gateway.logo_url}
              />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns text-center description">
              {config.name || config.gateway.display_name}
            </div>
          </div>
        </a>

        <div className="row">
          <div className="medium-6 columns text-left">
            <label
              className="ios7-switch big"
              style={{
                marginTop: ".2em",
              }}
            >
              <input
                id={config.id}
                type="checkbox"
                onChange={this.switchChanged.bind(this, config)}
                checked={this.state.activated}
              />
              <span />
            </label>
          </div>
          <div
            className="medium-6 columns text-right delete"
            style={{
              marginTop: "3px",
            }}
          >
            <a onClick={this.deleteConfig.bind(this, config)}>delete</a>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  routingRulesSettings: store.routingRulesSettings,
}))(Configuration)
