import { LegendOrdinal } from "@visx/legend"
import { scaleOrdinal } from "@visx/scale"
import React from "react"
import { displayValueFromDictionary } from "../dictionary"
import { setFirstLetterToUppercase } from "../util"

export const COLORS = ["#7B88C9", "#1E88E5", "#8E24AA"]

type Props = { legend: string[] }

export default function LegendChart({ legend }: Props) {
  const scaleX = scaleOrdinal({
    domain: legend.map(e => setFirstLetterToUppercase(displayValueFromDictionary(e))),
    range: COLORS.slice(0, legend.length),
  })

  return (
    <div data-testid="legend-visx-id">
      <LegendOrdinal
        direction="row"
        itemMargin="1px 0 0 15px"
        scale={scaleX}
        shape="line"
        style={{
          color: "#78909C",
          fontSize: "17.6px",
          display: "flex", // required in addition to `direction` if overriding styles
        }}
      />
    </div>
  )
}
