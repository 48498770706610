import React from "react"
import { connect } from "react-redux"
import { DateRangePicker } from "react-dates"
import type { $Dispatcher } from "../../util/Types"
import ModalFooter from "../../components/ModalFooter"
import type { $ExportState } from "./reducer"
import * as Actions from "./actions"
import { PAYOUTS_ITEMS_EXPORT_COLUMNS } from "./consts"
import type { $SearchBarState } from "../SearchBar/reducer"
import { OPEN_MODAL } from "^/util/Types"
import Select from "react-select"
import Creatable from "react-select/creatable"
type Props = {
  searchBarDetails: $SearchBarState
  payoutId: string
} & $Dispatcher

class PayoutDetailsExporter extends React.Component<Props> {
  openModal = () => {
    const { dispatch, searchBarDetails, payoutId } = this.props
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header: "Export payout details",
        content: <ConnectedExportModal payoutId={payoutId} />,
        footer: (
          <ModalFooter
            submitCallback={() => {
              const { dispatch } = this.props
              dispatch(Actions.requestExportCreation())
            }}
            submitTitle="Export"
          />
        ),
      },
    })
  }

  render() {
    return (
      <a onClick={this.openModal} className="small round border button">
        Export
      </a>
    )
  }
}

export default connect()(PayoutDetailsExporter)

class ExportModal extends React.Component<
  {
    exportsDetails: $ExportState
    payoutId: string
  } & $Dispatcher,
  {
    dateFocused: any
  }
> {
  constructor() {
    super()
    this.state = {
      dateFocused: null,
    }
  }

  componentDidMount() {
    const { dispatch, payoutId } = this.props
    dispatch(Actions.resetPayoutDetailsExportsForm(payoutId))
  }

  focusedChanged = focused => {
    this.setState({
      dateFocused: focused,
    })
  }

  render() {
    const { exportsDetails } = this.props
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-4 columns">Format</div>
            <div className="large-8 columns">
              <div
                className="labeled-field"
                style={{
                  width: "100%",
                  top: "-.5em",
                }}
              >
                <Select
                  className="react-select"
                  options={[
                    {
                      label: "CSV",
                      value: "csv",
                    },
                    {
                      label: "Excel",
                      value: "xls",
                      disabled: true,
                    },
                  ]}
                  value={{
                    label: exportsDetails.form.format.toUpperCase(),
                    value: exportsDetails.form.format.toUpperCase(),
                  }}
                  onChange={newValue => {
                    const { dispatch } = this.props
                    dispatch(
                      Actions.updateExportForm({
                        form: {
                          format: newValue.value,
                        },
                      }),
                    )
                  }}
                  isOptionDisabled={option => option.disabled}
                />
              </div>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <textarea
                placeholder="Describe your export"
                style={{
                  margin: 0,
                }}
                value={exportsDetails.form.description}
                onChange={event => {
                  const { dispatch } = this.props
                  dispatch(
                    Actions.updateExportForm({
                      form: {
                        description: event.target.value,
                      },
                    }),
                  )
                }}
              />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <div
                className="labeled-field"
                style={{
                  width: "100%",
                }}
              >
                <Creatable
                  isMulti
                  onChange={newValue => {
                    const { dispatch } = this.props
                    dispatch(
                      Actions.updateExportForm({
                        form: {
                          columns: newValue.map(v => v.value),
                        },
                      }),
                    )
                  }}
                  placeholder="Columns to export..."
                  value={exportsDetails.form.columns.map(c => ({
                    value: c,
                    label: c,
                  }))}
                  options={PAYOUTS_ITEMS_EXPORT_COLUMNS}
                />
              </div>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="large-3 columns">
              <span
                style={{
                  position: "relative",
                  top: ".5em",
                }}
              >
                Date range
              </span>
            </div>
            <div className="large-9 columns text-right">
              <div
                className="text-left"
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                }}
              >
                <DateRangePicker
                  startDate={exportsDetails.form.start_at}
                  endDate={exportsDetails.form.end_at}
                  focusedInput={this.state.dateFocused}
                  onDatesChange={dates => {
                    const { dispatch } = this.props
                    dispatch(
                      Actions.updateExportForm({
                        form: {
                          start_at: dates.startDate,
                          end_at: dates.endDate,
                        },
                      }),
                    )
                  }}
                  onFocusChange={this.focusedChanged}
                  isOutsideRange={() => false}
                  minimumNights={0}
                />
              </div>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">Want to be notified when your export is ready?</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <input
                type="text"
                placeholder="john@smith.com"
                value={exportsDetails.form.email}
                onChange={event => {
                  const { dispatch } = this.props
                  dispatch(
                    Actions.updateExportForm({
                      form: {
                        email: event.target.value,
                      },
                    }),
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedExportModal = connect(store => ({
  exportsDetails: store.transactionsExports,
}))(ExportModal)
