import { FETCH_TRANSACTION_DETAILS } from "^/Actions/TransactionsActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_TRANSACTION_DETAILS): {
      return { ...state, error: null, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_TRANSACTION_DETAILS): {
      return { ...state, fetching: false, fetched: true, transaction: action.payload.transaction }
    }

    case typeFailed(FETCH_TRANSACTION_DETAILS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
