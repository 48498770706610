import React from "react"
import { connect } from "react-redux"
import type { $DataExplorerState } from "../reducer"
import type { $Dispatcher } from "../../../../util/Types"
import Loading from "../../../../components/Loader"
import Previewer from "../Previewer"
import BuilderMetricSelector from "./BuilderMetricSelector"
import TypeSelection from "./TypeSelection"
// @ts-expect-error
import "./chart-builder.scss"
import LocalSwitch from "./LocalSwitch"
import * as Actions from "./actions"
import * as DataExplorerActions from "../actions"
import DimensionSelection from "./DimensionSelection/DimensionSelection"
import type { $Dimension } from "./DimensionSelection/consts"
import type { $Filter } from "./Filters/consts"
import Filters from "./Filters/Filters"
import type { $NetMetricType } from "./NetDropdown"
import NetDropdown from "./NetDropdown"
import type { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"
import { track } from "../../../../vNext/tracking"
type Props = {
  dataExplorer: $DataExplorerState
  location: any
  chartBuilder: $ChartBuilderState
} & $Dispatcher
export class ChartBuilder extends React.Component<Props> {
  componentDidMount(): void {
    const { dispatch } = this.props
    dispatch(DataExplorerActions.selectTab("chart-builder"))
  }

  render() {
    const { location, chartBuilder, dataExplorer } = this.props
    return (
      <div className="row chart-builder-v2">
        <div className="large-12 columns">
          {location.query.chart && !chartBuilder.canEditIfChartIdExists ? (
            <div className="chart-builder-building">
              <div className="large-12 columns">
                <div className="row">
                  <div className="medium-12 columns">Rebuilding chart UI</div>
                </div>
                <Loading />
              </div>
            </div>
          ) : null}
          <div className="chart-builder">
            <div className="back">
              <div className="row small-margin-bottom">
                <div className="large-12 columns ">
                  <BuilderMetricSelector />
                  {chartBuilder.dimensions.length > 0 ? (
                    <DimensionSelection
                      dimensions={chartBuilder.dimensions}
                      onChange={(newDimensions: Array<$Dimension>) => {
                        const { dispatch } = this.props
                        dispatch(Actions.updateDimensions(newDimensions))
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div
                className="row small-margin-bottom"
                style={{
                  margin: "0 -1em 0 -1em",
                }}
              >
                <div className="large-12 columns">
                  <Previewer hideName />
                </div>
              </div>
              <div className="row">
                <div className="large-8 columns">
                  <Filters
                    onChange={(filters: Array<$Filter>) => {
                      const { dispatch } = this.props

                      track("Analytics", "Select", "Chart", "Filter", {
                        dimensions: chartBuilder.dimensions?.map(d => {
                          return {
                            field: d.field,
                            top: d.top,
                            strategy: d.strategy,
                          }
                        }),
                        filters: filters.map(f => {
                          return { path: f.path, operand: f.operand }
                        }),
                      })
                      dispatch(Actions.updateFilters(filters))
                    }}
                    filters={chartBuilder.filters}
                  />
                </div>
                <div className="large-4 columns text-right">
                  <div className="row small-margin-bottom">
                    <div className="large-12 columns">
                      <TypeSelection />
                    </div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns text-right">
                      {dataExplorer.unit === "amount" ? (
                        <LocalSwitch
                          onChange={() => {
                            const { dispatch, chartBuilder } = this.props
                            dispatch(
                              Actions.setDisplayLocalCurrency(!chartBuilder.displayInLocalCurrency),
                            )
                          }}
                          displayInLocal={chartBuilder.displayInLocalCurrency}
                        />
                      ) : null}
                      <NetDropdown
                        netMetricType={chartBuilder.displayNetMetrics}
                        onChange={(type: $NetMetricType) => {
                          const { dispatch } = this.props
                          dispatch(Actions.setDisplayNetMetrics(type))
                        }}
                        selectedMetric={chartBuilder.selectedMetric}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  dataExplorer: store.dataExplorer,
  chartBuilder: store.chartBuilder,
}))(ChartBuilder)
