import React from "react"
import { FormattedNumber } from "react-intl"
import { Link } from "react-router"
import { getEvolution, getFontColor } from "../util"
import "./TelescopeCard.scss"
import TelescopeCardValue from "./TelescopeCardValue"
import Loading from "^/components/Loader"
import Sparkline from "../visualisations/Sparkline"
import TelescopeTitle from "^/features/TelescopeV2/components/TelescopeTitle"
import { getDatasPerDay } from "../visualisations/util"
import { ValueStyle } from "../consts"

type Props = {
  title: string
  value: number
  dashboardUrl?: string
  id: string
  tooltipContent: string
  percentageEvolution: number
  valueStyle: ValueStyle
  loading: boolean
  chart: Array<{
    key: string
    value: number
  }>
}

function TelescopeCard({
  title,
  value,
  dashboardUrl,
  id,
  tooltipContent,
  percentageEvolution,
  valueStyle,
  loading,
  chart,
}: Props) {
  const evolutionUpdated = getEvolution(percentageEvolution)
  return (
    <div className="telescope-card" data-tracking-location={title + " Card"}>
      <div className="telescope-card-header">
        <TelescopeTitle title={title} tooltip={{ content: tooltipContent, id }} />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="telescope-card-value-container">
          <div
            style={{
              display: "flex",
            }}
          >
            <span className="telescope-card-value">
              <TelescopeCardValue value={value} style={valueStyle} />
            </span>
            <span
              className="telescope-increase-value"
              style={{
                color: getFontColor(evolutionUpdated),
              }}
            >
              {evolutionUpdated > 0 && "+"}
              {evolutionUpdated === 0 ? (
                "No change"
              ) : (
                <FormattedNumber
                  value={evolutionUpdated}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  style="percent"
                />
              )}
            </span>
          </div>
          {chart && (
            <Sparkline
              datas={getDatasPerDay(chart)}
              valueStyle={valueStyle}
              showFullNumberTooltip={title === "Fees"}
              name={title}
            />
          )}
        </div>
      )}
      <div className="telescope-card-filler" />
      {dashboardUrl && (
        <Link
          style={{
            fontSize: "14px",
          }}
          to={dashboardUrl}
          data-auto-tracking={true}
          data-tracking-label="View Dashboard"
        >
          View dashboard
        </Link>
      )}
    </div>
  )
}

export default TelescopeCard
