import type { $Action } from "../../util/Types"
import type { $Alert, $AlertState } from "./consts"
export const REQUEST_ALERTS_FETCH = "REQUEST_ALERTS_FETCH"
export function requestAlertsFetch(
  length: number,
  after: boolean | null | undefined,
  id: string | null | undefined,
): $Action {
  if (!id) {
    id = ""
  }

  return {
    type: REQUEST_ALERTS_FETCH,
    payload: {
      id,
      after,
      length,
    },
  }
}
export const REQUEST_ALERTS_EVENTS_FETCH = "REQUEST_ALERTS_EVENTS_FETCH"
export function requestAlertsEventsFetch(alertId: string | null | undefined): $Action {
  return {
    type: REQUEST_ALERTS_EVENTS_FETCH,
    payload: {
      alertId,
    },
  }
}
export const REQUEST_ALERT_UPDATE = "REQUEST_ALERT_UPDATE"
export function requestAlertUpdate(alert: $Alert): $Action {
  return {
    type: REQUEST_ALERT_UPDATE,
    payload: {
      alert,
    },
  }
}
export const REQUEST_ALERT_STATE_CHANGE = "REQUEST_ALERT_STATE_CHANGE"
export function requestAlertStateChange(alert: $Alert, state: $AlertState): $Action {
  const newAlert = { ...alert, state }
  return requestAlertUpdate(newAlert)
}
export const REQUEST_ALERT_DELETE = "REQUEST_ALERT_DELETE"
export function requestAlertDelete(alert: string): $Action {
  return {
    type: REQUEST_ALERT_DELETE,
    payload: {
      alert,
    },
  }
}
