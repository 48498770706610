import React from "react"
import { connect } from "react-redux"
import * as CustomerDefailsActions from "../../Actions/CustomerDetailsActions"
import Loader from "../Loader"
import Transaction from "../Transaction"
import Empty from "../Empty"
import Pagination from "../Pagination"
import type { $Dispatcher, $RouterLocation } from "^/util/Types"
import type { TransactionsState } from "^/stores/Reducers/CustomerDetailsReducer/TransactionsReducer"
import { track } from "../../vNext/tracking"
const transactionsPerPage = 10
type Props = {
  filter?: string
  customerId: string
  location: $RouterLocation
  transactions: TransactionsState
} & $Dispatcher

class CustomerTransactions extends React.Component<Props> {
  componentDidMount() {
    let firstItem = this.props.location.query.after_item
    if (!firstItem) firstItem = ""
    this.props.dispatch(
      CustomerDefailsActions.loadCustomerTransactions(
        this.props.filter,
        transactionsPerPage,
        this.props.customerId,
        true,
        firstItem,
      ),
    )
  }

  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    let content

    if (this.props.transactions.fetching || !this.props.transactions.fetched) {
      content = (
        <div
          style={{
            minHeight: "500px",
          }}
        >
          <Loader />
        </div>
      )
    } else {
      let transactions

      if (this.props.transactions.transactions.length == 0) {
        transactions = <Empty text="The customer hasn't processed any transaction yet." />
      } else {
        transactions = (
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              {this.props.transactions.transactions.map(transaction => (
                <div className="row" key={transaction.id}>
                  <div className="large-12 columns">
                    <Transaction transaction={transaction} hideCustomer />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      }

      content = transactions
    }

    return (
      <div>
        {content}
        <Pagination
          reloadFunction={CustomerDefailsActions.loadCustomerTransactions.bind(
            null,
            this.props.filter,
            transactionsPerPage,
            this.props.customerId,
          )}
          hasMore={this.props.transactions.fetched ? this.props.transactions.has_more : false}
          location={this.props.location}
          hide={this.props.transactions.fetching || !this.props.transactions.fetched}
          itemsArray={this.props.transactions.fetched ? this.props.transactions.transactions : []}
          onPrevious={() => track("Customer", "Click", "Pagination", "Previous")}
          onNext={() => track("Customer", "Click", "Pagination", "Next")}
        />
      </div>
    )
  }
}

export default connect(store => ({
  transactions: store.customerDetails.transactions,
}))(CustomerTransactions)
