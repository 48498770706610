import React from "react"
import { FormattedNumber } from "react-intl"
import uniqid from "uniqid"
import Markdown from "react-markdown"
import BarChart from "../../analytics/Charts/BarChart"
import type { $Recommendation, $Surface } from "./consts"
import { segmentAnalytics } from "^/vNext/Segment"
type Props = {
  recommendation: $Recommendation
  currency: string
  onClose: () => void
  surface: $Surface
}

class RecoModal extends React.Component<Props> {
  componentDidMount() {
    segmentAnalytics?.track("RECOMMENDATION_OPENED", {
      title: this.props.recommendation.name,
    })
  }

  getLine = (name: string, value: any, noMargin: boolean | null | undefined) => (
    <div className={`row ${noMargin ? "" : "small-margin-bottom"}`}>
      <div className="large-6 columns">{name}</div>
      <div className="large-6 columns text-right">{value}</div>
    </div>
  )

  render() {
    const { recommendation } = this.props
    return (
      <div className="reco-modal">
        <a className="close-button" onClick={this.props.onClose}>
          <img alt="Close icon" src="//dashboard.processout.com/images/close-purple.png" />
        </a>
        <div className="row">
          <div className="large-6 columns">
            <div className="row margin-bottom">
              <div className="large-12 columns">
                <div className="title">{recommendation.name}</div>
              </div>
            </div>
            <div className="row margin-bottom">
              <div className="large-12 columns">
                <Markdown source={recommendation.description} />
              </div>
            </div>
            {this.getLine(
              "Savable transactions:",
              <FormattedNumber value={recommendation.count} />,
            )}
            <div className="small-margin-bottom" />
            <div className="estimated-recover">
              {this.getLine(
                "Estimated recovery:",
                <div
                  style={{
                    color: "white",
                    fontSize: "1.1em",
                  }}
                >
                  <FormattedNumber
                    value={recommendation.money_impact}
                    currency={this.props.currency}
                    style="currency"
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </div>,
                true,
              )}
            </div>
          </div>
          <div className="large-6 columns">
            <div className="row reco-chart" key={uniqid()}>
              <div className="large-12 columns">
                <div className="row">
                  <div className="large-12 columns text-center chart-title">
                    Authorization rate impact
                  </div>
                </div>
                <div className="row small-margin-bottom">
                  <div
                    className="large-12 columns"
                    style={{
                      height: "250px",
                    }}
                  >
                    <BarChart
                      data={[
                        {
                          key: "Current performance",
                          datapoints: recommendation.datapoints_without_gain.filter(
                            point => point._count > 0,
                          ),
                        },
                        {
                          key: "Expected performance",
                          datapoints: recommendation.datapoints_with_gain.filter(
                            point => point._count > 0,
                          ),
                        },
                      ]}
                      type="percentage"
                      colors={[
                        {
                          key: "Expected performance",
                          color: "rgba(192, 57, 43,.7)",
                        },
                        {
                          key: "Current performance",
                          color: "#5c6bc0",
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="large-offset-1 large-11 columns ">
                    <div className="surface-chart-legend surface" />
                    Current performance for this group of transactions
                  </div>
                </div>
                <div className="row">
                  <div className="large-offset-1 large-11 columns ">
                    <div className="surface-chart-legend benchmark" />
                    Expected performance after applying this action
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RecoModal
