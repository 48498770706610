import Moment from "moment"
import type { $Action } from "../../../util/Types"
import { UPDATE_TOKENS } from "../../../features/NetworkManager/consts"
import { LOG_USER_IN } from "^/Actions/UserActions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import persistedDataHandler from "^/vNext/persistedDataHandler"

type State = {
  loggedIn: boolean
  fetching: boolean
  token: {
    token: string
    expires_at: number
  }
  refreshToken: {
    token: string
    expires_at: number
  }
  waiting_for_two_factor?: boolean
}

const initialAuthTokens = persistedDataHandler.getAuthTokens()
const defaultState: State = {
  loggedIn:
    (initialAuthTokens.accessToken && validateTokenExpiry(initialAuthTokens.accessTokenExpiry)) ||
    (initialAuthTokens.refreshToken && validateTokenExpiry(initialAuthTokens.refreshTokenExpiry)),
  fetching: false,
  token: {
    token: initialAuthTokens.accessToken,
    expires_at: initialAuthTokens.accessTokenExpiry,
  },
  refreshToken: {
    token: initialAuthTokens.refreshToken,
    expires_at: initialAuthTokens.refreshTokenExpiry,
  },
}

export default function (state: State = defaultState, action: $Action): State {
  switch (action.type) {
    case typePending(LOG_USER_IN): {
      return {
        ...state,
        loggedIn: false,
        fetching: true,
        token: {
          token: "",
          expires_at: 0,
        },
        refreshToken: {
          token: "",
          expires_at: 0,
        },
      }
    }

    case UPDATE_TOKENS: {
      if (!action.payload) return state
      return {
        ...state,
        token: {
          token: action.payload.token.token,
          expires_at: action.payload.token.expires_at,
        },
        refreshToken: {
          token: action.payload.token.refresh_token,
          expires_at: action.payload.token.refresh_token_expires_at,
        },
      }
    }

    case typeFulfilled(LOG_USER_IN): {
      if (!action.payload) return state
      return {
        ...state,
        loggedIn: action.payload.waiting_for_two_factor !== true,
        fetching: false,
        token: {
          token: action.payload.token.token,
          expires_at: action.payload.token.expires_at,
        },
        refreshToken: {
          token: action.payload.token.refresh_token,
          expires_at: action.payload.token.refresh_token_expires_at,
        },
      }
    }

    case typeFailed(LOG_USER_IN): {
      if (!action.payload) return state

      if (action.payload.waiting_for_two_factor) {
        return { ...state, fetching: false, waiting_for_two_factor: true }
      }

      return {
        ...state,
        loggedIn: false,
        fetching: false,
        token: {
          token: "",
          expires_at: 0,
        },
        refreshToken: {
          token: "",
          expires_at: 0,
        },
      }
    }

    case "LOGOUT": {
      return {
        ...state,
        fetching: false,
        loggedIn: false,
        token: {
          token: "",
          expires_at: 0,
        },
        refreshToken: {
          token: "",
          expires_at: 0,
        },
      }
    }
  }

  return state
}

function validateTokenExpiry(date) {
  return Moment().isBefore(Moment.unix(date))
}
