import React from "react"
import Select from "react-select"
import { $DynamicFilters, DYNAMIC_PARAMS, DYNAMIC_RULES_FILTER } from "../consts"
import type { $Filter } from "./Filter/consts"
type Props = {
  filter: Array<$Filter>
  filterState: Array<$Filter>
  array: Array<$Filter>
  index: number
  handleDynamicChange: (event: Event, filter: $DynamicFilters) => void
  deleteFilter: (id: string) => void
  addFilter: () => void
}
export class DynamicRuleParam extends React.Component<Props> {
  getDynamicParamsOption = (filter: $DynamicFilters) => {
    const { filterState } = this.props
    const index = filterState.findIndex(item => item.id === filter.id)
    const params = filterState[index].path

    return DYNAMIC_PARAMS[params]
  }
  getAlerts = () => {
    const { filterState, filter } = this.props
    const getDynamicRulesLabel = DYNAMIC_RULES_FILTER.filter(el => el.value === filter.path)[0]

    if (filterState[0].path.length > 0) {
      if (filterState[0].path === filterState[1].path) {
        return (
          <div className="callout alert medium-6">
            <div>
              You have selected <span className="bold">{getDynamicRulesLabel.label}</span> twice,
              that is not allowed. Please, select one.
            </div>
          </div>
        )
      }
    } else {
      return
    }
  }

  render() {
    const { filter, index, array, handleDynamicChange, deleteFilter, addFilter, filterState } =
      this.props
    const addButton = filterState.length <= 1 && (
      <a className="add-button" onClick={addFilter}>
        +
      </a>
    )
    const getDynamicRulesLabel = DYNAMIC_RULES_FILTER.filter(el => el.value === filter.path)[0]
    const getOptions = DYNAMIC_RULES_FILTER.filter(el => el.value !== filterState[0].path)
    return (
      <>
        <div className="row filter-row small-margin-bottom" key={filter.id}>
          <div className="medium-3 column">
            <Select
              className="react-select"
              options={getOptions}
              value={{
                label: getDynamicRulesLabel && getDynamicRulesLabel.label,
                value: getDynamicRulesLabel && getDynamicRulesLabel.label,
              }}
              isClearable={false}
              onChange={event => handleDynamicChange(event, filter)}
            />
          </div>
          <div className="medium-3 column">
            <Select
              className="react-select"
              options={this.getDynamicParamsOption(filter)}
              value={{
                label: filter.value,
                value: filter.value,
              }}
              isClearable={false}
              onChange={event => handleDynamicChange(event, filter)}
            />
          </div>
          <div
            className="large-2 columns end"
            style={{
              paddingTop: ".5em",
            }}
          >
            {index === array.length - 1 && addButton}
            <a onClick={() => deleteFilter(filter.id)}>−</a>
          </div>
        </div>
        {array.length === 2 && index === array.length - 1 && this.getAlerts()}
      </>
    )
  }
}
export default DynamicRuleParam
