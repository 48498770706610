import React from "react"
// @ts-expect-error
import "./style.scss"
import { segmentAnalytics } from "^/vNext/Segment"
type Props = {
  surface: string
}
type State = {
  requested: boolean
}

class TalkToExpertCTA extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      requested: false,
    }
  }

  render() {
    const { surface } = this.props
    const { requested } = this.state
    if (!requested)
      return (
        <a
          className="round button main small"
          onClick={() => {
            segmentAnalytics?.track("REQUESTED_EXPERT_ADVICE", {
              surface,
            })
            this.setState({
              requested: true,
            })
          }}
        >
          Talk to an expert
        </a>
      )
    return (
      <div className="expert-request-done">
        Done ✅! An expert will get back to you as soon as possible!
      </div>
    )
  }
}

export default TalkToExpertCTA
