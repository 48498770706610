import type { $Action, $State } from "../../../../util/Types"
import type { $Board, $ComparisonSelector } from "../consts"
import { CHANGE_EDIT_BOARD_MODE, UPDATE_BOARD_GRID } from "../consts"
import {
  CANCEL_BOARD_EDITION,
  REQUEST_BOARD_PREPARATION,
  REQUEST_SAVE_LOCAL_LAYOUT,
  SET_LOCAL_LAYOUT,
  WIPE_CURRENT_BOARD,
} from "./consts"
import { UPDATE_BOARD_EDITION_NAME } from "../BoardsEditor/actions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $BoardDetailsState = {
  board: $Board | null | undefined
  localLayout: any
  // react-gris-layout after a drag and drop or resize
  editing: boolean
  nameEdition: string
} & $State & {
    comparison_selector: $ComparisonSelector
  }
const defaultState: $BoardDetailsState = {
  fetching: false,
  fetched: false,
  error: undefined,
  board: null,
  editing: false,
  comparison_selector: null,
  localLayout: null,
  nameEdition: "",
}
export default function (
  state: $BoardDetailsState = defaultState,
  action: $Action,
): $BoardDetailsState {
  switch (action.type) {
    case SET_LOCAL_LAYOUT: {
      if (!action.payload) return state
      return { ...state, localLayout: action.payload.localLayout }
    }

    case typePending(UPDATE_BOARD_GRID): {
      return { ...state, fetching: true, fetched: false, error: undefined }
    }

    case typeFulfilled(UPDATE_BOARD_GRID): {
      return { ...state, fetching: false, fetched: true, error: undefined }
    }

    case typeFailed(UPDATE_BOARD_GRID): {
      return { ...state, fetching: false, error: action.payload, fetched: true }
    }

    case REQUEST_SAVE_LOCAL_LAYOUT: {
      return { ...state, editing: false }
    }

    case CHANGE_EDIT_BOARD_MODE: {
      if (!action.payload) return state
      return { ...state, editing: !!action.payload.editing }
    }

    case CANCEL_BOARD_EDITION: {
      return { ...state, editing: false }
    }

    case WIPE_CURRENT_BOARD: {
      return { ...defaultState, fetching: true }
    }

    case REQUEST_BOARD_PREPARATION: {
      return { ...state, ...defaultState, fetching: true }
    }

    case typeFulfilled(REQUEST_BOARD_PREPARATION): {
      if (!action.payload) return state
      return {
        ...state,
        fetching: false,
        fetched: true,
        ...action.payload,
        nameEdition: action.payload.board.name,
      }
    }

    case typeFailed(REQUEST_BOARD_PREPARATION): {
      return { ...state, ...defaultState, fetched: true, error: action.payload }
    }

    case UPDATE_BOARD_EDITION_NAME: {
      if (!action.payload) return state
      return { ...state, nameEdition: action.payload.name }
    }
  }

  return state
}
