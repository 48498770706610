import React from "react"
import type { $DunningConfiguration } from "^/stores/Reducers/DunningReducer"
type Props = {
  configuration: $DunningConfiguration
  addConfiguration: (id: string) => void
  removeConfiguration: (id: string) => void
  updateConfiguration: (id: string) => void
  id: number
  isFirstAction?: boolean
  isLastAction?: boolean
}
export class DunningConfiguration extends React.Component<Props> {
  constructor() {
    super()
    this.delayRef = React.createRef()
  }

  static defaultProps = {
    isFirstAction: false,
  }

  getValue() {
    return this.delayRef.current.value
  }

  render() {
    const { configuration } = this.props
    const isFirst = this.props.isFirstAction
    let intro
    let middle
    let end

    if (isFirst) {
      intro = (
        <div
          className="greyed"
          style={{
            float: "left",
          }}
        >
          When the first payment fails,
        </div>
      )
    } else {
      intro = (
        <div
          className="greyed"
          style={{
            float: "left",
          }}
        >
          then
        </div>
      )
    }

    if (configuration.delay_in_days != null) {
      middle = (
        <div
          style={{
            float: "left",
          }}
        >
          <span
            style={{
              float: "left",
              marginLeft: "5px",
            }}
          >
            wait
          </span>
          <input
            ref={this.delayRef}
            className="bottom-border"
            type="number"
            step="1"
            style={{
              width: "30px",
              height: "20px",
              marginLeft: "5px",
              marginRight: "5px",
              textAlign: "right",
              padding: "0px",
              float: "left",
            }}
            value={configuration.delay_in_days}
            onChange={this.props.updateConfiguration.bind(null, this.props.id)}
          />
          <span
            className="greyed"
            style={{
              float: "left",
              marginLeft: "5px",
            }}
          >
            days
          </span>
        </div>
      )
    }

    if (configuration.action === "retry") {
      end = (
        <div
          style={{
            float: "left",
            marginLeft: "5px",
          }}
        >
          <span className="greyed"> and</span> <span>try again</span>,
        </div>
      )
    } else {
      end = (
        <div
          style={{
            float: "left",
            marginLeft: "5px",
          }}
        >
          <span className="greyed">then</span> cancel the subscription
          <span className="greyed">if the payment fails again.</span>
        </div>
      )
    }

    let addButton

    if (!this.props.isFirstAction) {
      addButton = (
        <div
          className="bold"
          style={{
            float: "left",
            marginTop: "-20px",
            marginLeft: "-30px",
          }}
        >
          <a onClick={this.props.addConfiguration.bind(null, this.props.id)} className="greyed">
            +
          </a>
        </div>
      )
    } else if (this.props.isFirstAction && this.props.isLastAction) {
      addButton = (
        <div
          className="bold"
          style={{
            float: "left",
            marginLeft: "-30px",
          }}
        >
          <a onClick={this.props.addConfiguration.bind(null, this.props.id)} className="greyed">
            +
          </a>
        </div>
      )
    }

    let removeButton

    if (!this.props.isLastAction) {
      removeButton = (
        <div
          style={{
            float: "left",
            marginLeft: "15px",
          }}
        >
          <a
            onClick={this.props.removeConfiguration.bind(null, this.props.id)}
            className="greyed configuration-image"
          >
            remove
          </a>
        </div>
      )
    }

    let content

    if (this.props.isFirstAction && this.props.isLastAction) {
      content = (
        <div>
          Cancel the subscription
          <span className="greyed"> if the first payment fails.</span>
        </div>
      )
    } else if (this.props.isLastAction) {
      content = (
        <div
          style={{
            paddingLeft: !this.props.isFirstAction && !this.props.isLastAction ? "30px" : "0px",
          }}
        >
          {end}
        </div>
      )
    } else {
      content = (
        <div
          style={{
            paddingLeft: !this.props.isFirstAction && !this.props.isLastAction ? "30px" : "0px",
          }}
        >
          {intro}
          {middle}
          {end}
        </div>
      )
    }

    return (
      <div className="row dunning" data-testid="dunning-configuration">
        <div className="large-12 columns text-right">
          {addButton}
          <div
            style={{
              float: "left",
            }}
          >
            {content}
          </div>
          {removeButton}
        </div>
      </div>
    )
  }
}
export default DunningConfiguration
