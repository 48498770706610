import React from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import Loading from "../../../components/Loader"
import type { $Dispatcher, $Project } from "../../../util/Types"
import type { $FetchParams } from "../Boards/consts"
import * as Actions from "./actions"
import { UPDATE_CHART_BUILDER_DETAILS } from "./consts"
import type { $DataExplorerState } from "./reducer"
import SavingModal from "./SavingModal"
import Tabs from "./Tabs"
import TypeSelection from "./TypeSelection"
import Unit from "./Unit/Unit"
import type { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"
import { track } from "../../../vNext/tracking"

type Props = {
  dataExplorer: $DataExplorerState
  analytics: {
    params: $FetchParams
  }
  currentProject: {
    project: $Project
  }
  isChartBuilder: boolean | null | undefined
  chartBuilder: $ChartBuilderState
} & $Dispatcher
export class Header extends React.Component<Props> {
  preview = () => {
    const { dispatch, dataExplorer, chartBuilder, analytics } = this.props
    dispatch(
      Actions.requestChartFetch(
        dataExplorer.formula,
        dataExplorer.name || chartBuilder.selectedMetric.name,
        dataExplorer.type,
        dataExplorer.unit,
        analytics.params,
      ),
    )
  }

  render() {
    const { dataExplorer, currentProject } = this.props
    return (
      <div className="sticky-actions" data-tracking-location="Header">
        <div
          className="row small-margin-bottom"
          style={{
            padding: "0 1em",
          }}
        >
          <div className="large-5 columns">
            <input
              type="text"
              className="name-input"
              placeholder="Chart name..."
              value={dataExplorer.name}
              onChange={event => {
                event.preventDefault()
                track("Analytics", "Input", "Header", "Chart name", { name: event.target.value })
                this.props.dispatch({
                  type: UPDATE_CHART_BUILDER_DETAILS,
                  payload: {
                    name: event.target.value,
                  },
                })
              }}
            />
          </div>
          {/* as part of this task: https://checkout.atlassian.net/browse/POFE-438, we want to hide the dropdown as a quick solution for this fix */}
          {/* this quick fix is temporary and will need a better fix during the revamp of analytics */}
          {/* <div className="large-2 columns">
            {dataExplorer.selectedTab !== "chart-builder" && <TypeSelection />}
          </div> */}
          <div className="large-2 columns">
            {dataExplorer.selectedTab !== "chart-builder" && <Unit value={dataExplorer.unit} />}
          </div>
          <div className="large-3 columns text-right">
            {dataExplorer.chart.fetched &&
            !dataExplorer.chart.error &&
            dataExplorer.previewedFormula &&
            dataExplorer.previewedFormula === dataExplorer.formula ? (
              <SavingModal boardId={dataExplorer.boardId} />
            ) : null}
            {dataExplorer.chart.fetched &&
            !dataExplorer.chart.error &&
            dataExplorer.selectedTab === "presets" ? (
              dataExplorer.selectedPreset ? (
                <Link
                  data-auto-tracking={true}
                  to={`/projects/${currentProject.project.id}/boards/${
                    dataExplorer.boardId ? `${dataExplorer.boardId}/new-chart` : "data-explorer"
                  }/chart-builder`}
                  class="small round main button preview-button fade-button"
                  onClick={() => {
                    this.props.dispatch(Actions.applyPreset(dataExplorer.selectedPreset))
                  }}
                >
                  Customize
                </Link>
              ) : null
            ) : dataExplorer.chart.fetching ? (
              <div className="preview-button">
                <Loading size={15} />
              </div>
            ) : dataExplorer.selectedTab !== "presets" || dataExplorer.selectedPreset ? (
              <a
                data-auto-tracking={true}
                className="small round main button preview-button"
                onClick={this.preview}
              >
                Preview
              </a>
            ) : null}
          </div>
        </div>
        <Tabs isChartBuilder={this.props.isChartBuilder} />
      </div>
    )
  }
}
export default connect(store => ({
  dataExplorer: store.dataExplorer,
  analytics: store.analytics,
  currentProject: store.currentProject,
  chartBuilder: store.chartBuilder,
}))(withRouter(Header))
