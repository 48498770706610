import * as ProcessOut from "../util/ProcessOut"
export const FETCH_COUPONS = "FETCH_COUPONS"
export function fetchCoupons(length?, after?, id?, filter?) {
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  filter = filter || ""
  return {
    type: FETCH_COUPONS,
    payload: ProcessOut.APIcallPromise(
      `/coupons?order=desc&${bound}=${id}&limit=${length}&filter=${filter}`,
      "GET",
    ),
  }
}
export const CREATE_COUPON = "CREATE_COUPON"
export function createCoupon(coupon, resolve) {
  const couponBody = JSON.stringify(coupon)
  return function (dispatch) {
    return {
      type: CREATE_COUPON,
      payload: ProcessOut.APIcallPromise("/coupons", "POST", couponBody).then(response => {
        if (response.data.success) {
          dispatch(fetchCoupons())
          resolve()
        }

        return response
      }),
    }
  }
}
export const EDIT_COUPON = "EDIT_COUPON"
export function editCoupon(coupon, id, resolve) {
  const couponBody = JSON.stringify(coupon)
  return function (dispatch) {
    return {
      type: EDIT_COUPON,
      payload: ProcessOut.APIcallPromise(`/coupons/${id}`, "PUT", couponBody).then(response => {
        if (response.data.success) {
          dispatch(fetchCoupons())
          resolve()
        }

        return response
      }),
    }
  }
}
export const DELETE_COUPON = "DELETE_COUPON"
export function deleteCoupon(id) {
  return function (dispatch) {
    return {
      type: DELETE_COUPON,
      payload: ProcessOut.APIcallPromise(`/coupons/${id}`, "DELETE").then(response => {
        if (response.data.success) {
          dispatch(fetchCoupons())
        }

        return response
      }),
    }
  }
}
export const FETCH_DISCOUNTS = "FETCH_DISCOUNTS"
export function fetchDiscounts(subscription) {
  return {
    type: FETCH_DISCOUNTS,
    payload: ProcessOut.APIcallPromise(
      `/subscriptions/${subscription}/discounts?expand=coupon`,
      "GET",
    ),
  }
}
export const APPLY_COUPON = "APPLY_COUPON"
export function applyCoupon(subscription, coupon) {
  const discountBody = JSON.stringify({
    coupon_id: coupon,
  })
  return function (dispatch) {
    return {
      type: APPLY_COUPON,
      payload: ProcessOut.APIcallPromise(
        `/subscriptions/${subscription}/discounts`,
        "POST",
        discountBody,
      ).then(response => {
        if (response.data.success) {
          dispatch(fetchDiscounts(subscription))
        }

        return response
      }),
    }
  }
}
