/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from "react"
import "./RouteLane.scss"
import type { $RoutingRule, $VelocityRule } from "^/features/RoutingRules/consts"
import Condition from "../cards/Condition"
import type { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Draggable } from "../../DragDrop/Draggable"
import clsx from "clsx"
import {
  DRAG_ASIDE_AUTHORIZATION,
  DRAG_ASIDE_CONDITION,
  DRAG_ROUTING_DYNAMIC3DS,
  DRAG_ROUTING_RULE,
} from "../consts"
import { Authorization } from "../cards/Authorization"
import type { GatewayInformation } from "^/vNext/@types/types"
import handleIconPath from "./routelane-handle.svg"
import { trackTarget } from "^/vNext/tracking"
import { Dynamic3DSParams } from "../cards/Dynamic3DSParams"
import { Gateway3DS } from "../cards/Gateway3DS"
import RoutingRulesDropDown from "../RoutingRulesDropDown"

export const LANE_HEIGHT = 104
export type Props = {
  editing: boolean
  orderIndex: number
  routingRule: $RoutingRule
  onCardSelect: (arg0: $Filter | $VelocityRule | GatewayInformation) => void
  onDrop: (oldIndex: number, newIndex: number) => void
  onFocus: (orderIndex: number) => void
  onBlur: () => void
  onClone?: () => void
  onRemove: () => void
  onUpdateRule: (rule: $RoutingRule) => void
  onAddCondition: (filter: $Filter) => void
  selected?: boolean
}

export const RouteLane = ({
  editing,
  routingRule,
  onCardSelect,
  orderIndex = 0,
  onDrop,
  onFocus,
  onClone,
  onRemove,
  onUpdateRule,
  onAddCondition,
  selected = false,
}: Props) => {
  const ref = React.useRef()
  const selectionHandler = React.useCallback(
    (entry: $Filter | $VelocityRule | GatewayInformation) => {
      if (editing) onCardSelect(entry)
    },
    [onCardSelect, editing],
  )

  const conditionCards = routingRule.conditions[0].filters.map(f => {
    return (
      <Condition
        key={f.id}
        filter={f}
        onClick={(e, name) => {
          selectionHandler(f)

          trackTarget(ref.current, "Select", "Card", {
            editing,
            name,
          })
        }}
      ></Condition>
    )
  })

  const authorization = routingRule.declaration === "route" && (
    <Authorization
      authorizations={routingRule.gateways}
      onGatewaySelection={auth => {
        selectionHandler(auth)

        const idx = routingRule.gateways.findIndex(g => g.id == auth.id)
        trackTarget(ref.current, "Select", "Card", {
          editing,
          name: idx ? "Fallback" : "Authorize",
        })
      }}
    ></Authorization>
  )

  const gateway3ds = routingRule.declaration === "dynamic_3ds" && (
    <Gateway3DS
      gatewayInfo={routingRule.gateways[0]}
      onGatewaySelection={auth => {
        selectionHandler(auth)

        const idx = routingRule.gateways.findIndex(g => g.id == auth.id)
        trackTarget(ref.current, "Select", "Card", {
          editing,
          name: "Gateway",
        })
      }}
    />
  )

  return (
    <Draggable
      category={DRAG_ROUTING_RULE}
      draggable={editing}
      orderIndex={orderIndex}
      onDragStart={(state, dispatch, evt) => {
        evt.dataTransfer.setData(DRAG_ROUTING_RULE, orderIndex.toString())
      }}
      onDrop={(state, _, evt) => {
        if (state.activeCategory === DRAG_ROUTING_RULE) {
          evt.currentTarget.classList.remove("active-before", "active-after", "drag-over")
          const rect = evt.currentTarget.getBoundingClientRect()
          const oldIndex = Number(evt.dataTransfer.getData(DRAG_ROUTING_RULE))

          // Drag item from lower position to high
          if (oldIndex < orderIndex) {
            if (evt.clientY < rect.y + LANE_HEIGHT / 2) {
              onDrop(oldIndex, orderIndex - 1)
            } else {
              onDrop(oldIndex, orderIndex)
            }
          } // Drag item from higher position to low
          // Do nothing if position is not changed
          else if (oldIndex > orderIndex) {
            if (evt.clientY < rect.y + LANE_HEIGHT / 2) {
              onDrop(oldIndex, orderIndex)
            } else {
              // eslint-disable-next-line no-undef
              onDrop(oldIndex, orderIndex + 1)
            }
          }
        } else if (state.activeCategory === DRAG_ASIDE_CONDITION) {
          evt.currentTarget.classList.remove("drag-over")
          const filter = JSON.parse(evt.dataTransfer.getData(DRAG_ASIDE_CONDITION))
          const newRule = structuredClone(routingRule)
          newRule.conditions[0].filters.push(filter)
          onAddCondition(filter)
          onUpdateRule(newRule)

          trackTarget(ref.current, "Drop Add", `Condition`, {
            routeNumber: orderIndex + 1,
            name: (filter as $Filter).path,
          })
        } else if (state.activeCategory === DRAG_ASIDE_AUTHORIZATION) {
          evt.currentTarget.classList.remove("drag-over")
          const auth = JSON.parse(evt.dataTransfer.getData(DRAG_ASIDE_AUTHORIZATION))
          const newRule = structuredClone(routingRule)
          newRule.gateways.push(auth)
          onUpdateRule(newRule)

          trackTarget(ref.current, "Drop Add", `Gateway`, {
            routeNumber: orderIndex + 1,
            name: auth.gateway === "processout" ? "Smart-Routing" : "Authorization",
            "number-authorizations": routingRule.gateways.length,
          })
        } else if (state.activeCategory === DRAG_ROUTING_DYNAMIC3DS) {
          evt.currentTarget.classList.remove("drag-over")
          const dynamic3dsParam = JSON.parse(evt.dataTransfer.getData(DRAG_ROUTING_DYNAMIC3DS))
          const newRule = structuredClone(routingRule)
          if (!newRule.dynamic_3ds_params) newRule.dynamic_3ds_params = []
          newRule.dynamic_3ds_params.push(dynamic3dsParam)
          onUpdateRule(newRule)

          trackTarget(ref.current, "Drop Add", `Dynamic 3DS`, {
            routeNumber: orderIndex + 1,
            name: dynamic3dsParam.path,
          })
        }
      }}
      onDragOver={(state, dispatch, evt) => {
        if (state.activeCategory === DRAG_ROUTING_RULE) {
          const rect = evt.currentTarget.getBoundingClientRect()

          if (evt.clientY > rect.y + rect.height / 2) {
            evt.currentTarget.classList.remove("active-before")
            evt.currentTarget.classList.add("active-after")
          } else {
            evt.currentTarget.classList.add("active-before")
            evt.currentTarget.classList.remove("active-after")
          }
        } else if (state.activeCategory === DRAG_ASIDE_CONDITION) {
          evt.currentTarget.classList.add("drag-over")
        }
      }}
      onDragLeave={(state, dispatch, evt) => {
        evt.currentTarget.classList.remove("active-before", "active-after", "drag-over")
      }}
    >
      <div className="drop-indicator drop-indicator-before"></div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={ref}
        data-testid="route-lane"
        className={clsx("route-lane", {
          selected,
          editing,
        })}
        aria-label="Use arrow to change route order" // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onFocus={() => {
          onFocus(orderIndex)
        }}
        onClick={() => {
          trackTarget(ref.current, "Select", `Route`, { editing, routeNumber: orderIndex + 1 })
        }}
      >
        <div className="head">
          <span className="order-number">{orderIndex + 1}</span>
          <div
            className="handle"
            style={{
              display: editing ? "flex" : "none",
            }}
          >
            <img alt="handle icon" src={handleIconPath}></img>
          </div>
        </div>
        <div className="card-container">
          {conditionCards}
          <Dynamic3DSParams
            filters={routingRule.dynamic_3ds_params}
            onFilterSelection={(f, name) => {
              selectionHandler(f)

              trackTarget(ref.current, "Select", "Card", {
                editing,
                name,
              })
            }}
          />
          {gateway3ds}
          {authorization}
        </div>
        {editing && (
          <RoutingRulesDropDown
            onClone={onClone}
            onRemove={onRemove}
            onUpdateRule={onUpdateRule}
            routingRule={routingRule}
          />
        )}
      </div>
      <div className="drop-indicator drop-indicator-after"></div>
    </Draggable>
  )
}
