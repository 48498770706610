import React from "react"
type Props = {
  onHide: () => void
}

class AdyenOnboardingWarning extends React.Component<Props> {
  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row margin-bottom">
            <div className="large-12 columns">
              Glad to have you on board! Adyen’s set up requires a few steps to be done on your
              Adyen account. Although it can definitely be done on your own, if it’s your initial
              set up we recommend you book a time slot with your Integrations Team to walk you
              through the process!
            </div>
          </div>
          <div className="row">
            <div
              className="medium-3 medium-offset-6 columns"
              style={{
                paddingTop: ".5em",
              }}
            >
              <a
                onClick={() => {
                  this.props.onHide()
                }}
              >
                Go through setup anyway
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdyenOnboardingWarning
