import React from "react"
type Props = {
  colors: Array<{
    key: string
    color: string
  }> // Support maximum 5 keys
}

class Legend extends React.Component<Props> {
  render() {
    const { colors } = this.props
    return (
      <div className="pie-legend">
        {colors.map(color => (
          <div key={color.key} className="pie-legend-row">
            <div
              className="pie-legend-color"
              style={{
                backgroundColor: color.color,
              }}
            />
            <div className="pie-legend-key"> {color.key}</div>
          </div>
        ))}
      </div>
    )
  }
}

export default Legend
