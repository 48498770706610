import React from "react"
import type { $Filter } from "./Filter/consts"
import * as Actions from "../actions"
import type { $Dispatcher } from "../../../util/Types"
import type { $RoutingRule } from "../consts"
import { RULES_FILTERS } from "../consts"
import Filter from "./Filter/Filter"
import { connect } from "react-redux"
type Props = {
  rule: $RoutingRule
} & $Dispatcher
export class BlockingRule extends React.Component<Props> {
  filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
    const newRule = { ...this.props.rule }
    newRule.conditions[conditionIndex].filters = newValue
    this.props.dispatch(Actions.updateRule(newRule.id, newRule))
  }

  render() {
    const { rule } = this.props
    return (
      <div className="row" data-tracking-location="Blocking Rules">
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-8 columns">Block transactions</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              {rule.conditions.map((condition, index) => (
                <div className="row small-margin-bottom" key={`${this.props.rule.id}-${index}`}>
                  <div className="medium-12 columns">
                    <Filter
                      onChange={this.filterChanged.bind(null, index)}
                      options={Object.keys(RULES_FILTERS).map(filter => ({
                        label: RULES_FILTERS[filter].label,
                        value: filter,
                        desc: RULES_FILTERS[filter].desc,
                        isMulti: RULES_FILTERS[filter].isMulti,
                      }))}
                      defaultFilters={condition.filters}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(BlockingRule)
