import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as ProcessOut from "^/util/ProcessOut"
import type { $Dispatcher, $Project, $RouterLocation } from "^/util/Types"
import ContentLayout from "../../../pages/ContentLayout"
import { loadRecommendationFromPeriod, prepareTelescopeV2Page } from "../actions"
import TelescopeCards from "../components/TelescopeCards"
import TelescopeHeader from "../components/TelescopeHeader"
import { $ChartDatasFromApi, $Period, $Recommendations, ValueStyle } from "../consts"
import { PaymentAudit } from "./PaymentAudit"

export type Metrics = {
  authorization_rate: number
  authorization_rate_increase: number
  conversion_rate: number
  conversion_rate_increase: number
  success_3ds_rate: number
  success_3ds_rate_increase: number
  authorization_rate_chart: $ChartDatasFromApi[]
  conversion_rate_chart: $ChartDatasFromApi[]
  success_3ds_rate_chart: $ChartDatasFromApi[]
  fetched: boolean
  fetching: boolean
}
export type Props = {
  metricsTelescope: Metrics
  location: $RouterLocation
  diagnosticRecommendations: {
    periodRecommendations: {
      periodRecommendations: $Recommendations
      fetched: boolean
      fetching: boolean
      error?: {
        details: any
        type: string
      }
    }
  }
  currentProject: {
    project: $Project
  }
} & $Dispatcher

export function Diagnostics({
  metricsTelescope,
  location,
  dispatch,
  diagnosticRecommendations,
  currentProject,
}: Props) {
  useEffect(() => {
    document.title = "Anomaly detection | ProcessOut"
  }, [])

  useEffect(() => {
    dispatch(prepareTelescopeV2Page("anomalyDetection", location.query.period as $Period))
    dispatch(loadRecommendationFromPeriod(location.query.period as $Period))
  }, [location.query])

  const metrics = [
    {
      title: "Conversion rate",
      value: metricsTelescope.conversion_rate,
      percentageEvolution: metricsTelescope.conversion_rate_increase,
      id: "Conversion rate",
      tooltipContent:
        "Total number of authorized transactions / total number of initiated transactions (includes 3DS checks)",
      valueStyle: "percent" as ValueStyle,
      chart: metricsTelescope.conversion_rate_chart,
    },
    {
      title: "Authorization rate",
      value: metricsTelescope.authorization_rate,
      percentageEvolution: metricsTelescope.authorization_rate_increase,
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/authorization`,
      id: "Authorization rate",
      tooltipContent:
        "Number of successful authorizations / total number of authorization requests",
      valueStyle: "percent" as ValueStyle,
      chart: metricsTelescope.authorization_rate_chart,
    },
    {
      title: "3DS Success rate",
      value: metricsTelescope.success_3ds_rate,
      percentageEvolution: metricsTelescope.success_3ds_rate_increase,
      id: "3DS Success rate",
      tooltipContent:
        "3DS success rate: number of successful 3DS check / total number of 3DS check",
      dashboardUrl: `/projects/${ProcessOut.config.ProjectId}/domains-secure`,
      valueStyle: "percent" as ValueStyle,
      chart: metricsTelescope.success_3ds_rate_chart,
    },
  ]
  return (
    <div data-tracking-sub-page="Anomaly detection">
      <ContentLayout title={<TelescopeHeader title="Anomaly detection" />} noBackground padding="0">
        <div className="row">
          <TelescopeCards
            data={metrics}
            loading={!metricsTelescope.fetched || metricsTelescope.fetching}
          />
        </div>
      </ContentLayout>
      <PaymentAudit
        diagnosticRecommendations={diagnosticRecommendations}
        currentProject={currentProject}
        loading={
          !diagnosticRecommendations.periodRecommendations.fetched ||
          diagnosticRecommendations.periodRecommendations.fetching
        }
      />
    </div>
  )
}
export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.anomalyDetection,
  diagnosticRecommendations: store.telescopeRecommendations,
  currentProject: store.currentProject,
}))(Diagnostics)
