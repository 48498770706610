import React from "react"
type Props = {
  metadata: {
    key: string
    value: string
  }
  updateForm: () => void
  remove: (key: string) => void
  propKey: number
}
export default class MData extends React.Component<Props> {
  constructor() {
    super()
    this.keyRef = React.createRef()
    this.valueRef = React.createRef()
  }

  componentDidUpdate() {
    this.onValueEdit()
    this.onKeyEdit()
  }

  onValueEdit() {
    if (this.valueRef.current.value != "") this.keyRef.current.setAttribute("required", "")
    else this.keyRef.current.removeAttribute("required")
    this.props.updateForm()
  }

  onKeyEdit() {
    if (this.keyRef.current.value != "") this.valueRef.current.setAttribute("required", "")
    else this.valueRef.current.removeAttribute("required")

    if (
      this.keyRef.current.value == "shipping_amount" ||
      this.keyRef.current.value == "taxes_amount"
    ) {
      this.valueRef.current.removeAttribute("required")
      this.valueRef.current.setAttribute("disabled", true)
      this.valueRef.current.value = ""
    } else {
      this.valueRef.current.removeAttribute("disabled")
    }

    this.props.updateForm()
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    this.keyRef.current.value = props.metadata.key
    this.valueRef.current.value = props.metadata.value
  }

  deleteMeta() {
    this.props.remove(this.props.metadata.key)
  }

  render() {
    const prop = this.props.metadata
    let deleteButton

    if ((prop.key !== "" && prop.value !== "") || this.props.propKey === 1) {
      deleteButton = (
        <a
          onClick={() => {
            this.deleteMeta()
          }}
          href="javascript:void(0);"
        >
          ✕
        </a>
      )
    }

    return (
      <div className="row">
        <div className="medium-5 columns">
          <input
            ref={this.keyRef}
            type="text"
            placeholder="Key"
            onInput={this.onKeyEdit.bind(this)}
            className="bottom-border key"
            defaultValue={prop.key}
          />
        </div>
        <div className="medium-6 columns">
          <input
            ref={this.valueRef}
            type="text"
            placeholder="Value"
            onInput={this.onValueEdit.bind(this)}
            className="bottom-border value"
            defaultValue={prop.value}
          />
        </div>
        <div
          style={{
            marginTop: "15px",
          }}
          className="medium-1 columns text-right bigger1-2"
        >
          {deleteButton}
        </div>
      </div>
    )
  }
}
