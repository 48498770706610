import React from "react"
import { FormattedDate } from "react-intl"
import { connect } from "react-redux"
import DateDisplay from "../DateDisplay"
import * as Actions from "./actions"
import type { $Dispatcher } from "../../util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
export type $ExportStatus = "pending" | "processing" | "processed"
export type $ExportType = "transaction" | "payout" | "payout_item"
export type $Export = {
  id: string
  start_at: string
  end_at: string
  filter: string
  type: $ExportType
  status: $ExportStatus
  description: string
  created_at: string
  format: any
  notification_on_comletion: any
}
type Props = {
  ex: $Export
} & $Dispatcher
export class Export extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.openDetails = this.openDetails.bind(this)
  }

  openDetails(event) {
    event.preventDefault()
    const { dispatch, ex } = this.props
    const content = (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="large-12 columns">
              <h6
                className="capitalize greyed font-console"
                style={{
                  marginBottom: "0px",
                }}
              >
                Details
              </h6>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Identifier</div>
            <div
              className="large-9 columns small-font font-console text-right"
              style={{
                wordWrap: "break-word",
              }}
            >
              {ex.id}
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Description</div>
            <div className="large-9 columns text-right">{ex.description}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Status</div>
            <div className="large-3 columns text-right">
              <div
                className={`log-level ${
                  ex.status === "pending" || ex.status === "processing" ? "warning" : "success"
                }`}
              >
                {ex.status}
              </div>
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Type</div>
            <div className="large-9 columns text-right">{ex.type}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Format</div>
            <div className="large-9 columns text-right">{ex.format}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-9 columns greyed">Notification on completion</div>
            <div className="large-3 columns text-right">
              <i
                className={`icon ${
                  ex.notification_on_comletion ? "icon-check" : "icon-check-empty"
                }`}
              />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Filters</div>
            <div className="large-12 columns small-font font-console">{ex.filter}</div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Creation on</div>
            <div className="large-9 columns text-right">
              <FormattedDate
                value={ex.created_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="large-3 columns greyed">Date range</div>
            <div className="large-9 columns text-right">
              <FormattedDate value={ex.start_at} day="2-digit" month="2-digit" year="2-digit" /> —{" "}
              <FormattedDate value={ex.end_at} day="2-digit" month="2-digit" year="2-digit" />
            </div>
          </div>
        </div>
      </div>
    )
    dispatch({
      type: OPEN_MODAL,
      payload: {
        header: "Export details",
        content,
        footer: (
          <div className="row">
            <div className="large-6 columns">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  dispatch(Actions.requestExportDownload(ex.id))
                }}
                className="round border small button"
              >
                Download
              </a>
            </div>
            <div className="large-6 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
                className="round border small button"
              >
                Close
              </a>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    const { ex } = this.props
    return (
      <div
        className="export-item row box-row padding"
        onClick={this.openDetails}
        style={{
          cursor: "pointer",
        }}
      >
        <div className="medium-1 columns">
          <div
            className={`log-level ${
              ex.status === "pending" || ex.status === "processing" ? "warning" : "success"
            }`}
          >
            {ex.status}
          </div>
        </div>
        <div className="medium-3 columns">{ex.description}</div>
        <div className="medium-3 columns">
          <FormattedDate value={ex.start_at} day="2-digit" month="2-digit" year="2-digit" /> —{" "}
          <FormattedDate value={ex.end_at} day="2-digit" month="2-digit" year="2-digit" />
        </div>
        <div className="medium-2 columns">{ex.type}</div>
        <div className="medium-3 columns text-right greyed">
          <DateDisplay value={ex.created_at} />
        </div>
      </div>
    )
  }
}
export default connect()(Export)
