import React, { useRef } from "react"
import { Draggable } from "../../DragDrop/Draggable"
import { DRAG_ASIDE_AUTHORIZATION, DRAG_ASIDE_CONDITION, DRAG_ROUTING_DYNAMIC3DS } from "../consts"
import { CategoryCollapse } from "./CategoryCollapse"
import { SidebarBlock } from "./SidebarBlock"
import "./Sidebar.scss"
import { SidebarList } from "../utils"
import { Dynamic3dsParam, GatewayInformation } from "^/vNext/@types/types"
import { trackTarget } from "^/vNext/tracking"
type Props = {
  authorizations: SidebarList<GatewayInformation>
  dynamic3dsParams?: SidebarList<Dynamic3dsParam>
  conditions: Record<string, any>
  onAddCondition: (condition: any) => void
  // For adding authorization steps when + is clicked
  onAddAuthorization?: (auth: GatewayInformation) => void
  // For adding dynamic 3ds when + is clicked
  onAddDynamic3ds?: (param: Dynamic3dsParam) => void
}
export function Sidebar({
  conditions,
  authorizations,
  dynamic3dsParams,
  onAddCondition,
  onAddAuthorization,
  onAddDynamic3ds,
}: Props) {
  const ref = useRef()
  return (
    <div className="Sidebar" ref={ref}>
      <div className="Sidebar__body">
        {/* TODO: wait until Sophie and Harjit have the final categorization information, so we can hardcode it. */}
        {conditions &&
          Object.entries(conditions).map(([key, value]) => (
            <CategoryCollapse key={key} title={key}>
              {value &&
                value.map((condition, idx) => (
                  <Draggable
                    key={idx}
                    onDragStart={(state, dispatch, evt) => {
                      evt.dataTransfer.setData(DRAG_ASIDE_CONDITION, JSON.stringify(condition.data))
                      dispatch({
                        type: "start",
                        activeCategory: DRAG_ASIDE_CONDITION,
                        draggingTarget: evt.currentTarget,
                      })
                    }}
                  >
                    <SidebarBlock
                      title={condition.label}
                      onAddClick={() => {
                        trackTarget(ref.current, "Add Condition", "Plus Button", {
                          name: condition.label,
                        })
                        onAddCondition?.(condition.data)
                      }}
                    />
                  </Draggable>
                ))}
            </CategoryCollapse>
          ))}

        {dynamic3dsParams?.map(group => {
          const content = group.items.map(item => {
            return (
              <Draggable
                key={item.name}
                onDragStart={(state, dispatch, evt) => {
                  evt.dataTransfer.setData(DRAG_ROUTING_DYNAMIC3DS, JSON.stringify(item.data))
                  dispatch({
                    type: "start",
                    activeCategory: DRAG_ROUTING_DYNAMIC3DS,
                    draggingTarget: evt.currentTarget,
                  })
                }}
              >
                <SidebarBlock
                  className="dynamic-3ds"
                  title={item.name}
                  onAddClick={() => {
                    trackTarget(ref.current, "Click", "Plus Button", {
                      // To distinguish from regular 3ds condition
                      name: `Dynamic 3DS ${item.name}`,
                    })
                    // onAddAuthorization(item.data)
                    onAddDynamic3ds?.(item.data)
                  }}
                />
              </Draggable>
            )
          })
          return (
            <CategoryCollapse key={group.name} title={group.name}>
              {content}
            </CategoryCollapse>
          )
        })}

        {authorizations.map(authGroup => {
          const content = authGroup.items.map(item => {
            const isSmartRouting = !!item.data.configurations
            return (
              <Draggable
                key={item.name}
                onDragStart={(state, dispatch, evt) => {
                  evt.dataTransfer.setData(DRAG_ASIDE_AUTHORIZATION, JSON.stringify(item.data))
                  dispatch({
                    type: "start",
                    activeCategory: DRAG_ASIDE_AUTHORIZATION,
                    draggingTarget: evt.currentTarget,
                  })
                }}
              >
                <SidebarBlock
                  className={isSmartRouting ? "smart-routing" : "authorize"}
                  title={item.name}
                  onAddClick={() => {
                    trackTarget(ref.current, "Click", "Plus Button", {
                      name: item.name,
                    })
                    onAddAuthorization?.(item.data)
                  }}
                />
              </Draggable>
            )
          })
          return (
            <CategoryCollapse key={authGroup.name} title={authGroup.name}>
              {content}
            </CategoryCollapse>
          )
        })}
      </div>
    </div>
  )
}
