import React from "react"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL } from "^/util/Types"
import { connect } from "react-redux"
type Props = {
  confirmTimeout?: number
  confirmCallback: (arg0?: any) => any
} & $Dispatcher
type State = {
  remainingTimeout: number
}
export class ConfirmButton extends React.Component<Props, State> {
  private timeoutId: any
  state = {
    remainingTimeout: this.props.confirmTimeout,
  }

  componentDidMount() {
    this.timeoutId = setInterval(() => {
      this.setState({
        remainingTimeout: this.state.remainingTimeout - 1,
      })
      this.state.remainingTimeout <= 0 && clearInterval(this.timeoutId)
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timeoutId)
  }

  render() {
    const { remainingTimeout } = this.state
    return remainingTimeout ? (
      <input
        disabled
        type="button"
        className="confirm-button round small main button white-text"
        value={`Confirm (${remainingTimeout})`}
      />
    ) : (
      <input
        type="button"
        onClick={() => {
          this.props.dispatch({
            type: CLOSE_MODAL,
          })
          this.props.confirmCallback()
        }}
        className="confirm-button round small main button white-text"
        value="Confirm"
      />
    )
  }
}
export default connect()(ConfirmButton)
