import React from "react"
import { Link } from "react-router"
import { FormattedDate, FormattedNumber } from "react-intl"
import * as ProcessOut from "../util/ProcessOut"
import type { $Subscription } from "^/types/Subscription"
type Props = {
  subscription: $Subscription
}
export default class Subscription extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  render() {
    const { subscription } = this.props
    let status

    if (subscription.active) {
      status = (
        <div
          className="log-level success"
          style={{
            margin: "0px",
            marginTop: "0.2em",
          }}
        >
          Active
        </div>
      )
    } else if (subscription.canceled) {
      status = (
        <div
          className="log-level failed"
          style={{
            margin: "0px",
            marginTop: "0.2em",
          }}
        >
          Canceled
        </div>
      )
    } else {
      status = (
        <div
          className="log-level pending"
          style={{
            margin: "0px",
            marginTop: "0.2em",
          }}
        >
          Pending
        </div>
      )
    }

    let interval
    if (subscription.interval_days === 365) interval = <span>every year</span>
    else if (subscription.interval_days === 30) interval = <span>every month</span>
    else if (subscription.interval_days === 7) interval = <span>every week</span>
    else if (subscription.interval_days === 1) interval = <span>every day</span>
    else interval = <span>every {subscription.interval_days} days</span>
    let { name } = subscription

    if (subscription.plan) {
      name = subscription.plan.name
    }

    return (
      <Link
        to={`/projects/${ProcessOut.config.ProjectId}/recurring/subscriptions/${subscription.id}`}
      >
        <div className="box-row padding row">
          <div className="medium-1 columns">{status}</div>
          <div
            className="medium-3 columns clearfix"
            style={{
              marginLeft: "-1em",
            }}
          >
            <span className="big-font left">
              <span className="greyed">{subscription.currency}</span>{" "}
              <FormattedNumber
                value={subscription.amount}
                style="currency"
                currency={subscription.currency}
              />
            </span>
            <span className="right">{interval}</span>
          </div>
          <div className="medium-6 columns">
            <span
              style={{
                marginLeft: "1em",
              }}
            >
              {name}
            </span>
          </div>
          <div className="medium-2 columns greyed text-right">
            <FormattedDate
              value={subscription.created_at}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </div>
        </div>
      </Link>
    )
  }
}
