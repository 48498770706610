import React from "react"
import { FormattedNumber, IntlProvider } from "react-intl"
import type { $CountryMultiDimPoint } from "../Consts"
type Props = {
  point: $CountryMultiDimPoint
}

class PercentMultiDimTooltip extends React.Component<Props> {
  render() {
    const { point } = this.props
    const totalCount = point.datapoints.reduce((count, point) => count + point._count, 0)
    return (
      <IntlProvider locale={navigator ? navigator.language || "en-US" : "en-US"}>
        <ul>
          {point.datapoints
            .sort((a, b) => b._count - a._count)
            .map(point => (
              <li className="small-margin-bottom" key={point.key}>
                <h6
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {point.key}
                </h6>
                <FormattedNumber
                  value={point.value}
                  style="percent"
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                />{" "}
                <div
                  className="greyed"
                  style={{
                    display: "inline-block",
                  }}
                >
                  (
                  <FormattedNumber value={point._count / totalCount} style="percent" />)
                </div>
              </li>
            ))}
        </ul>
      </IntlProvider>
    )
  }
}

export default PercentMultiDimTooltip
