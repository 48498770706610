/**
 * Created by jeremylejoux on 31/08/2017.
 */
import React from "react"
import moment from "moment"
import { FormattedDate, FormattedRelative } from "react-intl"
// if set to "day" DateDisplay will use a relative display if the date is less than 24h back
type Span = "hour" | "day" | "week"
type Props = {
  value: string
  relativeSpan?: Span | null | undefined
}
/*
 * DateDisplay displays a date formatted and can switch to a relative
 * display if the date is in the given range
 */

export default class DateDisplay extends React.Component<Props> {
  render() {
    const { value, relativeSpan } = this.props
    const now = moment()
    const date = moment(value)

    if (relativeSpan && now.diff(date, `${relativeSpan}s`) < 1) {
      return <FormattedRelative value={value} style="best fit" />
    }

    return (
      <FormattedDate
        value={value}
        year="2-digit"
        month="2-digit"
        day="2-digit"
        hour="2-digit"
        minute="2-digit"
        second="2-digit"
      />
    )
  }
}
