import axios from "axios"
import * as ProcessOut from "../../util/ProcessOut"
import { fetchStatus } from "../../util/ProcessOut"
import type { $ErrorContext } from "../ErrorContext/consts"
import type { $Action } from "../../util/Types"
import { LOCK_NETWORK, UNLOCK_NETWORK } from "./consts"
import { POST_NOT_ALLOWED } from "../Permissions/consts"
import { datadogRum } from "@datadog/browser-rum"
import { ProcessOutUrl } from "^/util/ProcessOutUrl"

export const HTTPCall = (
  url: string,
  req: any,
  disableStatusParsing?: boolean | null | undefined,
  disableErrorDisplay?: boolean | null | undefined,
  retryWithRefreshedToken = false,
) => {
  const context: $ErrorContext = {
    contextUrl: document.location.hash,
  }
  return axios(ProcessOutUrl + url, req)
    .then(response => {
      if (!disableStatusParsing) fetchStatus(response.status, response.data)

      if (req.method === "POST" && response.status === 417) {
        // NOT PERMITTED to create a resource
        if (!disableErrorDisplay) ProcessOut.addNotification(POST_NOT_ALLOWED, "error")
      }

      return response
    })
    .catch(error => {
      if (error.response) {
        if (
          error.response.status === 401 &&
          error.response.data.error_type === "request.authentication.invalid" &&
          url !== "/authenticate" && // 401s from trying to login are pointless to retry
          url !== "/authenticate/refresh" && // 401s from trying to login are pointless to retry
          !retryWithRefreshedToken
        ) {
          // If 401 due to token, and is not already retried, retry the call

          datadogRum.addAction("retry-api-call")

          return ProcessOut.APIcallPromise(
            url,
            req.method,
            req.data,
            req.headers,
            disableStatusParsing,
            disableErrorDisplay,
            true,
          )
        }

        if (!disableStatusParsing) fetchStatus(error.response.status)

        if (!error.response.data.success && !disableStatusParsing) {
          if (!disableErrorDisplay) {
            if (error.response.status === 401) ProcessOut.addNotification("Your session expired.")
            else if (error.response.status === 500) {
              ProcessOut.addNotification(
                "An error occurred, please try again in a few minutes.",
                null,
                context,
              )
            } else if (error.response.status !== 417 || req.method !== "GET") {
              ProcessOut.addNotification(error.response.data.message, null)
            }
          }

          if (error.response.status > 500) {
            // the error is unknown
            datadogRum.addError(error)
          }
        }

        throw error.response
      }

      if (
        error.code === "ECONNABORTED" &&
        !new RegExp(/\/analytics\/telescope\/recommendations\/.*\/details.*/).test(url) &&
        !disableErrorDisplay
      ) {
        ProcessOut.addNotification("Request timed-out, please retry later.", null, context)
      } else if (!disableErrorDisplay)
        ProcessOut.addNotification(
          "Could not reach the server, please check your network.",
          null,
          context,
        )

      throw error
    })
}
export function lockNetwork(): $Action {
  return {
    type: LOCK_NETWORK,
  }
}
export function unlockNetwork(): $Action {
  return {
    type: UNLOCK_NETWORK,
  }
}
