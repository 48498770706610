import React, { useContext } from "react"
import type { Dispatch } from "./DragDropContext"
import { DragDropContext } from "./DragDropContext"
import type { DragDropState } from "./types"
export type Props = {
  orderIndex?: number
  children: React.ReactNode
  category?: string
  onDragStart?: (
    state: DragDropState,
    dispatch: Dispatch,
    evt: React.DragEvent<HTMLElement>,
  ) => void
  onDragOver?: (state: DragDropState, dispatch: Dispatch, evt: React.DragEvent<HTMLElement>) => void
  onDrop?: (state: DragDropState, dispatch: Dispatch, evt: React.DragEvent<HTMLElement>) => void
  onDragLeave?: (
    state: DragDropState,
    dispatch: Dispatch,
    evt: React.DragEvent<HTMLElement>,
  ) => void
  draggable?: boolean | null | undefined
}
export const Draggable = ({
  draggable = true,
  category = "default",
  children,
  onDragStart,
  onDragOver,
  onDragLeave,
  onDrop,
}: Props) => {
  const [state, dispatch] = useContext(DragDropContext)
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={"draggable"}
      draggable={draggable}
      onDragStart={evt => {
        evt.stopPropagation()
        evt.dataTransfer.effectAllowed = "move"
        dispatch({
          type: "start",
          activeCategory: category,
          draggingTarget: evt.currentTarget,
        })
        if (onDragStart) onDragStart(state, dispatch, evt)
      }}
      onDragOver={evt => {
        // preventDefault is needed for allowing the drop action
        evt.preventDefault()
        evt.stopPropagation()
        if (onDragOver) onDragOver(state, dispatch, evt)
        dispatch({
          type: "over",
        })
      }}
      onDragLeave={evt => {
        if (onDragLeave) onDragLeave(state, dispatch, evt)
        dispatch({
          type: "leave",
        })
      }}
      onDrop={evt => {
        evt.preventDefault()
        evt.stopPropagation()
        if (onDrop) onDrop(state, dispatch, evt)
        dispatch({
          type: "drop",
        })
      }}
    >
      {children}
    </div>
  )
}
