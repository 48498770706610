import React, { useState } from "react"
import TabsPicker from "./TabsPicker"

export default function TabsState({ tabs }) {
  const [page, setPage] = useState(0)

  const onTabsChange = (newValue: number) => {
    setPage(newValue)
  }

  return <TabsPicker tabs={tabs} onTabsChange={onTabsChange} initalTabIndex={page} />
}
