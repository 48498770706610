import React from "react"
type Props = {
  leftPanel?: any | null | undefined
  rightPanel?: any | null | undefined
  children?: any
}

class Partial extends React.Component<Props> {
  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="partial">
            {this.props.leftPanel || this.props.rightPanel ? (
              <div className="row">
                <div
                  className="large-6 columns"
                  style={{
                    paddingRight: 0,
                  }}
                >
                  <div className="panel left">{this.props.leftPanel}</div>
                </div>
                <div
                  className="large-6 columns"
                  style={{
                    paddingLeft: 0,
                  }}
                >
                  <div className="panel right">{this.props.rightPanel}</div>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="large-12 columns">{this.props.children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Partial
