import * as React from "react"
import type { $RoutingRule } from "^/features/RoutingRules/consts"
import "./Graph.scss"
// This is a temporary class to handle path drawing.
type Offset = {
  x: number
  y: number
}
export type Props = {
  routingRules: Array<$RoutingRule>
  children?: React.ReactNode
  editing: boolean
  asideCollapsed: boolean
}
// Hardcoded layout properties
const columnGap = 40
const rowGap = 32
const cardWidth = 180
const cardHeight = 72
export const createPaths = (
  routingRules: Array<$RoutingRule>,
  offset: Offset,
): Array<React.ReactNode> => {
  // Quick and dirty way to draw lines for now
  const paths = []
  routingRules.forEach((r, rowIndex) => {
    // const entries = r.conditions[0].filters.concat(r.gateways)
    const entries = [...r.conditions[0].filters, ...(r.dynamic_3ds_params || []), ...r.gateways]
    if (entries.length <= 1) return

    for (let i = 1; i < entries.length; ++i) {
      const start = {
        x: offset.x + cardWidth * i + columnGap * (i - 1),
        y: offset.y + rowIndex * (cardHeight + rowGap) + cardHeight * 0.5,
      }
      const end = {
        x: offset.x + cardWidth * i + columnGap * (i - 1) + columnGap,
        y: offset.y + rowIndex * (cardHeight + rowGap) + cardHeight * 0.5,
      }
      paths.push(
        <path
          key={`${rowIndex}-${i}`}
          stroke="#969696"
          strokeWidth="1"
          d={`M${start.x},${start.y} L${end.x},${end.y}`}
        />,
      )
    }
  })
  return paths
}

function getOffset() {
  const graph = document.querySelector(".graph")
  const firstConditionCard = document.querySelector(".card")

  if (firstConditionCard && graph) {
    const rect = firstConditionCard.getBoundingClientRect()
    const containerRect = graph.getBoundingClientRect()
    return {
      x: rect.left - containerRect.left,
      y: rect.top - containerRect.top,
    }
  }

  return {
    x: 0,
    y: 0,
  }
}

/**
 * Draw connection paths for all conditions(filters)
 */
export const Graph = ({ routingRules, editing, asideCollapsed }: Props) => {
  const [offset, setOffset] = React.useState({
    x: 0,
    y: 0,
  })
  React.useEffect(() => {
    setOffset(getOffset())
  }, [editing, asideCollapsed])
  return (
    <div className="graph">
      <svg>{createPaths(routingRules, offset)}</svg>
    </div>
  )
}
