/**
 * Created by jeremylejoux on 03/10/2017.
 */
import hljs from "highlight.js"
import React from "react"

class Highlight extends React.Component {
  constructor() {
    super()
    this.mainRef = React.createRef()
  }

  componentDidMount() {
    this.highlightCode()
  }

  componentDidUpdate() {
    this.highlightCode()
  }

  highlightCode() {
    const nodes = this.mainRef.current.querySelectorAll("pre code")
    let i

    for (i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i])
    }
  }

  render() {
    const { children, className, innerHTML } = this.props

    if (innerHTML) {
      const element = React.createFactory("div")
      return element(
        {
          dangerouslySetInnerHTML: {
            __html: children,
          },
          className: className || null,
          ref: this.mainRef,
        },
        null,
      )
    }

    return (
      <pre ref={this.mainRef}>
        <code className={className}>{children}</code>
      </pre>
    )
  }
}

Highlight.defaultProps = {
  innerHTML: false,
  className: null,
}
export default Highlight
