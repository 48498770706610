import type { $DataPoint } from "../analytics/Boards/consts"

export const FETCH_LATEST_RUN = "FETCH_LATEST_RUN"
export const LOAD_RECOMMENDATIONS_FROM_LATEST_RUN = "LOAD_RECOMMENDATIONS_FROM_LATEST_RUN"
export const LOAD_RECOMMENDATIONS_FROM_PERIOD = "LOAD_RECOMMENDATIONS_FROM_PERIOD"
export const WIPE_TELESCOPE_STATE = "WIPE_TELESCOPE_STATE"
export const GET_METRICS_TELESCOPE = "GET_METRICS_TELESCOPE"
export const REQUEST_PREPARE_TELESCOPE_V2_PAGE = "REQUEST_PREPARE_TELESCOPE_V2_PAGE"
export const SELECT_CHARGEBACK_GATEWAY_CONFIGURATION = "SELECT_CHARGEBACK_GATEWAY_CONFIGURATION"
export const SWITCH_TAB = "SWITCH_TAB"
export const METRICS_TEMPLATE = {
  name: "preview",
  description: "preview",
  type: "single-value",
  unit: "currency",
  preview: true,
  size: 12,
}
export const METRICS_FORMULA = [
  {
    name: "Conversion rate",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "request"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n};',
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "request"\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n}',
    unit: "percentage",
  },
  {
    name: "Authorization rate",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;\n}\n/ count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;\n}',
    unit: "percentage",
  },
  {
    // this formula is also equal to authentication rate
    name: "3DS Success rate",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_last_payment_attempt == true;\n    }\n};',
    formula:
      'count{\n   path:transaction_operations;\n  default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n   AND is_successful == true\n     AND is_last_payment_attempt == true;\n}\n/ count{\n path:transaction_operations;\n  default: 0;\n   (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_last_payment_attempt == true;\n}',
    unit: "percentage",
  },
  {
    name: "Authenticated transaction amount",
    formula:
      'sum{\npath:transaction_operations.amount_local;\ndefault: 0;\n(type == "three_d_s_check" or type == "three_d_s_2_check")\nAND is_successful == true\nAND is_last_payment_attempt == true;\n}\n',
    unit: "currency",
  },
  {
    name: "Authenticated transaction count",
    formula:
      'count{\npath:transaction_operations;\ndefault: 0;\n(type == "three_d_s_check" or type == "three_d_s_2_check")\nAND is_successful == true\nAND is_last_payment_attempt == true;\n}\n',
    unit: "decimal",
  },
  {
    name: "Triggered 3DS transactions amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    is_last_payment_attempt == true\n    AND is_user_retry == false\n    AND is_last_operation == true\n    AND (payment_type == "three-d-s-2" or payment_type == "three-d-s");}',
    unit: "currency",
  },
  {
    name: "Triggered 3DS transactions count",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND is_user_retry == false\n    AND is_last_operation == true\n    AND (payment_type == "three-d-s-2" or payment_type == "three-d-s");}\n',
    unit: "decimal",
  },
  {
    name: "Failed authentication amount",
    formula:
      'sum{\npath:transaction_operations.amount_local;\ndefault: 0;\n(type == "three_d_s_check" or type == "three_d_s_2_check")\nAND is_successful == false\nAND is_last_payment_attempt == true;\n}',
    unit: "currency",
  },
  {
    name: "Failed authentication count",
    formula:
      'count{\npath:transaction_operations;\ndefault: 0;\n(type == "three_d_s_check" or type == "three_d_s_2_check")\nAND is_successful == false\nAND is_last_payment_attempt == true;\n}',
    unit: "decimal",
  },
  {
    name: "Total transaction amount",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "authorization"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n};',
    formula:
      'sum{\npath:transaction_operations.amount_local;\ndefault: 0;\ntype == "authorization"\nAND is_last_payment_attempt == true\nAND is_user_retry == false;}',
    unit: "currency",
  },
  {
    name: "Number of transactions",
    chartFormula:
      "plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND is_last_operation == true\n        AND is_user_retry == false;\n    }\n};",
    formula:
      "count{\n    path:transaction_operations;\n    default: 0;\n    is_last_payment_attempt == true\n    AND is_last_operation == true;}",
    unit: "decimal",
  },
  {
    name: "Capture & Fees",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    formula:
      'sum{\npath:transaction_operations.gateway_fee;\ndefault: 0;\ntype == "capture"\nAND gateway_fee != null;\n;\n}\n/ sum{\npath:transaction_operations.amount;\ndefault: 0;\ntype == "capture"\nAND gateway_fee != null;\n;\n}',
    unit: "percentage",
  },
  {
    name: "Refunds & Voids",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "refund"\n        AND is_successful == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND is_successful == true;\n    }\n};',
    formula:
      'count{\npath:transaction_operations;\ndefault: 0;\ntype == "refund"\nAND is_successful == true;\n}\n/ count{\npath:transaction_operations;\ndefault: 0;\ntype == "capture"\nAND is_successful == true;\n}',
    unit: "percentage",
  },
  {
    name: "Chargebacks",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "chargeback";\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND is_successful == true;\n    }\n};',
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "chargeback";\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}',
    unit: "percentage",
  },
  {
    name: "Authorized amount",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true;\n    }\n};',
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true;}',
    unit: "currency",
  },
  {
    name: "Authorized transactions",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND is_successful == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;}',
    unit: "decimal",
  },
  {
    name: "Declined transactions",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n    AND has_failed == true\n    AND is_user_retry == false\n    AND is_last_payment_attempt == true;}',
    unit: "decimal",
  },
  {
    name: "Declined transactions amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "authorization"\n    AND has_failed == true\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;}',
    unit: "decimal",
  },
  {
    name: "Declined rate",
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == false\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authorization rate by gateway",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n  top: 250;\n  formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authorization rate by card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n  top: 250;\n  formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authorization rate by card type",
    chartFormula:
      'plot{\n    path:card_type;\n    strategy: value_descending;\n   top: 250;\n  formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authorization rate by payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n   top: 250;\n  formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authorization rate by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_user_retry == false\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "number of transaction by country",
    chartFormula:
      "plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND is_last_operation == true\n        AND is_user_retry == false;\n    }\n};",
    unit: "decimal",
  },
  {
    name: "amount of transaction by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "authorization"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false;\n    }\n};',
    unit: "currency",
  },
  {
    name: "3DS trigger rate",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    (type == "three_d_s_check" or type == "three_d_s_2_check")\n    AND is_last_payment_attempt == true\n    AND is_user_retry == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "request"\n    AND is_last_payment_attempt == true\n    AND is_card_payment == true\n    AND is_user_retry == false;}',
    unit: "percentage",
  },
  {
    name: "Authentication rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authentication rate per card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authentication rate per gateway",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Authentication rate per payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_successful == true\n        AND is_last_payment_attempt == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        (type == "three_d_s_check" or type == "three_d_s_2_check")\n        AND is_last_payment_attempt == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "number of transaction by country triggered by 3DS",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        is_last_payment_attempt == true\n        AND is_last_operation == true\n        AND (payment_type == "three-d-s-2" or payment_type == "three-d-s");\n    }\n};',
    unit: "decimal",
  },
  {
    name: "amount of transaction by country triggered by 3DS",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n        path:transaction_operations.amount_local;\n        default: 0;\n        type == "authorization"\n        AND is_last_payment_attempt == true\n        AND is_user_retry == false\n        AND (payment_type == "three-d-s-2" or payment_type == "three-d-s");\n    }\n};',
    unit: "currency",
  },
  {
    name: "Captured transactions rate",
    formula:
      'count{\n  path:transaction_operations;\n    default: 0;\n   type == "capture"\n    AND is_successful == true;\n  } /\ncount{\n   path:transaction_operations;\n      default: 0;\n     type == "authorization"\n      AND is_successful == true;\n    }',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n  path:transaction_operations;\n    default: 0;\n   type == "capture"\n    AND is_successful == true;\n  } /\ncount{\n   path:transaction_operations;\n      default: 0;\n     type == "authorization"\n      AND is_successful == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Captured transactions amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}',
    unit: "currency",
  },
  {
    name: "Captured transactions count",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}',
    unit: "decimal",
  },
  {
    name: "Failed captures rate",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND has_failed == true\n    ;}\n    / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n  AND is_successful == true\n  ;}',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND has_failed == true\n}  / count{\n    path:transaction_operations;\n    default: 0;\n    type == "authorization"\n  AND is_successful == true\n  ;}\n};',
    unit: "percentage",
  },
  {
    name: "Failed captures rate by gateway",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n    };\n count{\n path:transaction_operations;\n default: 0;\n type == "capture"\n AND has_failed == true    \n;}/ count{\n path:transaction_operations;    \n default: 0;\n type == "authorization"  \n AND is_successful == true \n;}\n}',
    unit: "percentage",
  },
  {
    name: "Failed captures rate by card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n    };\n count{\n path:transaction_operations;\n default: 0;\n type == "capture"\n AND has_failed == true    \n;}/ count{\n path:transaction_operations;    \n default: 0;\n type == "authorization"  \n AND is_successful == true \n;}\n}',
    unit: "percentage",
  },
  {
    name: "Failed captures rate by payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n    };\n count{\n path:transaction_operations;\n default: 0;\n type == "capture"\n AND has_failed == true    \n;}/ count{\n path:transaction_operations;    \n default: 0;\n type == "authorization"  \n AND is_successful == true \n;}\n}',
    unit: "percentage",
  },
  {
    name: "Failed captures amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "capture"\n    AND has_failed == true\n    ;}',
    unit: "currency",
  },
  {
    name: "Failed captures count",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND has_failed == true\n    ;}',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND has_failed == true;\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Voided transactions rate",
    formula:
      'count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    } \n',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Voided transactions amount",
    formula:
      'sum{\n   path:transaction_operations.amount_local;\n    default: 0;\n     type == "void"\n   AND is_successful == true\n    }\n',
    unit: "currency",
  },
  {
    name: "Voided transactions count",
    formula:
      'count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    }\n',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Failed captures rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n    };\n count{\n path:transaction_operations;\n default: 0;\n type == "capture"\n AND has_failed == true    \n;}/ count{\n path:transaction_operations;    \n default: 0;\n type == "authorization"  \n AND is_successful == true \n;}\n}',
    unit: "percentage",
  },
  {
    name: "Number of failed captures by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND has_failed == true\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Amount of failed captures by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "capture"\n    AND has_failed == true\n    ;}\n};',
    unit: "currency",
  },
  {
    name: "Voided transactions rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n    };\n count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    }\n}',
    unit: "percentage",
  },
  {
    name: "Number of voided transactions by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Amount of voided transactions by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n   path:transaction_operations.amount_local;\n    default: 0;\n     type == "void"\n   AND is_successful == true\n    }\n};',
    unit: "currency",
  },
  {
    name: "Voided transactions rate by gateway",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    ;}\n}',
    unit: "percentage",
  },
  {
    name: "Voided transactions rate by card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    ;}\n}',
    unit: "percentage",
  },
  {
    name: "Voided transactions rate by payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "void"\n        AND is_successful == true\n    } /\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "authorization"\n        AND is_successful == true\n    ;}\n}',
    unit: "percentage",
  },
  {
    name: "Fees rate",
    formula:
      'sum{\n    path:transaction_operations.gateway_fee;\n    default: 0;\n    type == "capture"\n    AND gateway_fee != null;\n    ;\n} / sum{\n    path:transaction_operations.amount;\n    default: 0;\n    type == "capture"\n    AND gateway_fee != null;\n    ;}',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Fees amount",
    formula:
      'sum{\n    path:transaction_operations.gateway_fee_local;\n    default: 0;\n    type == "capture"\n    AND gateway_fee != null;\n    ;\n}',
    unit: "currency",
  },
  {
    name: "Fees rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Number of fees by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Amount of fees by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n    path:transaction_operations.gateway_fee_local;\n    default: 0;\n    type == "capture"\n    AND gateway_fee != null;\n    ;\n}\n};',
    unit: "currency",
  },
  {
    name: "Refunds rate",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n        path:transaction_operations;\n        default: 0;\n        type == "refund"\n        AND is_successful == true;\n    } / count{\n        path:transaction_operations;\n        default: 0;\n        type == "capture"\n        AND is_successful == true;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Refunds amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;}',
    unit: "currency",
  },
  {
    name: "Refunds number",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;}',
    unit: "decimal",
  },
  {
    name: "Failed refunds rate",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}',
    chartFormula:
      'plot{\n    path:created_at;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}\n};',
    unit: "percentage",
  },
  {
    name: "Failed refunds amount",
    formula:
      'sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;}',
    unit: "currency",
  },
  {
    name: "Failed refunds number",
    formula:
      'count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;}',
    unit: "decimal",
  },
  {
    name: "Refunds rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}\n};',
    unit: "percentage",
  },
  {
    name: "Number of refunds by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path:transaction_operations;\n        default: 0;\n        type == "refund"\n        AND is_successful == true;\n    }\n};',
    unit: "decimal",
  },
  {
    name: "Amount of refunds by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;}\n};',
    unit: "currency",
  },
  {
    name: "Refunds rate per payment provider",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}\n};',
    unit: "percentage",
  },
  {
    name: "Refunds rate per card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}\n};',
    unit: "percentage",
  },
  {
    name: "Refunds rate per payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == true;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "capture"\n    AND is_successful == true;}\n};',
    unit: "percentage",
  },
  {
    name: "Failed refunds rate per country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}\n};',
    unit: "percentage",
  },
  {
    name: "Number of failed refunds by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;}\n};',
    unit: "decimal",
  },
  {
    name: "Amount of failed refunds by country",
    chartFormula:
      'plot{\n    path:card_country;\n    strategy: value_descending;\n    top: 250;\n    formula: sum{\n    path:transaction_operations.amount_local;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;}\n};',
    unit: "currency",
  },
  {
    name: "Failed refunds rate per payment provider",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}\n};',
    unit: "percentage",
  },
  {
    name: "Failed refunds rate per card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}\n};',
    unit: "percentage",
  },
  {
    name: "Failed refunds rate per payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund"\n    AND is_successful == false;\n} / count{\n    path:transaction_operations;\n    default: 0;\n    type == "refund";}\n};',
    unit: "percentage",
  },
  {
    name: "Fees rate per payment provider",
    chartFormula:
      'plot{\n    path:gateway_name;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Fees rate per card scheme",
    chartFormula:
      'plot{\n    path:card_scheme;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    unit: "percentage",
  },
  {
    name: "Fees rate per payment method",
    chartFormula:
      'plot{\n    path:payment_type;\n    strategy: value_descending;\n    top: 250;\n    formula: count{\n        path: transaction_operations;\n        \n    };\n    sum{\n        path:transaction_operations.gateway_fee;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    } / sum{\n        path:transaction_operations.amount;\n        default: 0;\n        type == "capture"\n        AND gateway_fee != null;\n        ;\n    }\n};',
    unit: "percentage",
  },
]
export type $TimespanMetrics = "weekly" | "monthly" | "quarterly"
export type $Period = "1w" | "1m" | "3m"
export type $TelescopeV2Page =
  | "overview"
  | "anomalyDetection"
  | "authorization"
  | "domainsSecure"
  | "captureAndVoids"
  | "refunds"
  | "fees"
  | "chargebacks"
export type $LastestRun = {
  updated_time: string
  latest_run_id: string
  analysis_period: $AnalysisPeriod
  previous_runs: Array<$PreviousRuns>
  success: boolean
}
export type $AnalysisPeriod = {
  start_time: string
  end_time: string
}
export type $PreviousRuns = {
  updated_time: string
  id: string
}
export type $TelescopeRecommendations = {
  recommendations: $Recommendations
  success: boolean
}
export type $Recommendations = {
  authentication_kpi: $DatasKpi
  authorization_kpi: $DatasKpi
  profiles: Array<$Profile>
}
export type $Profile = {
  profile: $RecommendationProfile
  target: number
  target_deduplicated: number
  transaction_count: number
  transaction_count_deduplicated: number
  benchmark: number
  benchmark_deduplicated: number
  profile_text: $ProfileText
  amount_to_gain: number
  confidence: number
  confidence_count: Array<string>
  new_profile: boolean
  actions: Array<$RecommendationAction>
  errors: Array<$RecommendationError>
  success: boolean
}
export type $ProfileText = {
  "3ds": string
}
export type $RecommendationError = {
  type: string
  merchant_percentage: number
  merchant_percentage_deduplicated: number
  benchmark_exceed_percentage: number
  benchmark_exceed_percentage_deduplicated: number
  text: string
}
export type $RecommendationAction = {
  type: string
  target_uplift: number
  target_uplift_deduplicated: number
  already_have: boolean
  to_do: string
  text: string
}

// https://github.com/processout/api/blob/master/utils/telescope/telescopeV2-store.go#L67
export type $RecommendationProfile = {
  op_type: string
  transaction_type?: string
  issuer_country_iso_3_code?: string
  issuer_country_name?: string
  three_ds?: string
  ip_country_name?: string
  issuer_ip_country?: boolean
  issuer_ip_region?: boolean
  card_scheme?: string
  issuer_name?: string
  card_bin?: string
  currency_iso_3_code?: string
  currency_country_match?: boolean
  card_type?: string
  card_product_type?: string
  card_category_description?: string
  tds_acs_challenge?: boolean
  cvv_check?: string
  avs_postal_check?: string
  avs_street_check?: string
  avs_name_check?: string
  hours_of_day?: number
  amount?: number
  amount_bucket?: string
  tr_device_channel?: string
  routing_rule?: boolean
  tds_trigger_rule?: boolean
}
export type $DatasKpi = {
  number_of_profiles: number
  number_of_profiles_with_b: number
  surface_cover: number
  wavg_ar_increase_total: number
  dedup_wavg_ar_increase_total: number
  money_impact: number
}
export type ValueStyle = "currency" | "decimal" | "percent"
export type $TelescopeMetricData = {
  moreDetails?: boolean
  title: string
  value?: number
  dashboardUrl?: string
  tooltipContent: string
  percentageEvolution?: number
  valueStyle?: ValueStyle
  chart?: Array<{
    key: string
    value: number
  }>
  id: string
  count?: number
  amount?: number
  legendChartTitle?: string
}
export type $ChartDatasTelescope = {
  legendChartTitle: string
  chart: $DataPoint
}
export type $ChartDatasFromApi = {
  key: string
  value: number | null
  _count: number
  _corresponding_filter: number
}
export type $PerCountryTableData = {
  country: string
  rate?: number
  amount?: number
  count?: number
  rateEvolution?: number
}
export type $MonitoringBarCharts = Array<{
  name: string
  value: $ChartDatasFromApi[]
  tooltipContent: string
  id: string
}>

export type $ChargebackData = {
  chargebacks_details: $ChargebackDataDetails
  success: boolean
}

type $ChargebackDataDetails = {
  global_chargeback_count: number
  global_chargeback_rate: number
  global_chargeback_volume: number
  global_notifications_of_fraud: number
  global_requests_for_information: number
  mastercard_chargeback_count: number
  mastercard_chargeback_rate: number
  mastercard_chargeback_volume: number
  mastercard_notifications_of_fraud: number
  mastercard_requests_for_information: number
  visa_chargeback_count: number
  visa_chargeback_rate: number
  visa_chargeback_volume: number
  visa_notifications_of_fraud: number
  visa_requests_for_information: number
}

export type $ChargebackGateway = {
  id: string
  belongsToProgram: boolean
  gatewayConfigurationName: string
}

export type $BarchartTelescope = {
  name: string
  value?: number | $ChartDatasFromApi[]
  tooltipContent: string
  id: string
  valueStyle?: string
  limit?: { key: string; value: number }
}
