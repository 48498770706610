import React from "react"
import ReactSelect from "react-select"
import ReactCreatable from "react-select/creatable"

/**
 * A wrapper class wraps react-select. Just in case there are changes you want to applied globally, e.g., react-select class name
 */
export const Select: React.FC<any> = React.forwardRef((props, ref) => {
  return <ReactSelect className="react-select" {...props} />
})
Select.displayName = "Select"

/**
 * A wrapper class wraps react-select. Just in case there are changes you want to applied globally, e.g., react-select class name
 */
export const Creatable: React.FC<any> = React.forwardRef((props, ref) => {
  return <ReactCreatable className="react-select" {...props} ref={ref} />
})
Creatable.displayName = "Creatable"

/**
 * A label value pair represents the Select component options.
 */
export type Option = {
  label: string
  value: any
}
