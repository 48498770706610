import React from "react"
// @ts-expect-error
import "./welcome.scss"
import moment from "moment"
import { Link, withRouter } from "react-router"
import { connect } from "react-redux"
import ContentLayout from "../../../../pages/ContentLayout"
import { BOARDS_WELCOME_TEXT, BOARDS_WELCOME_TITLE } from "./consts"
import LineChart from "../../Charts/LineChart"
import BarChart from "../../Charts/BarChart"
import { hasFeature } from "../../../FeatureFlags/FeatureFlags"
import type { $Project } from "../../../../util/Types"
import CallToAction from "../../../Telescope/Components/CallToAction"

class Welcome extends React.PureComponent {
  props: {
    params: {
      project: string
    }
    currentProject: {
      project: $Project
    }
  }

  render() {
    const authBankAData = []
    const authBankBData = []

    for (let i = 13; i >= 0; i--) {
      // We generate a point for the banks
      const date = moment().subtract(i, "day").format()
      const bankAPoint = {
        key: date,
        value: Math.random() * 0.3 + 0.7,
      }
      const bankBPoint = {
        key: date,
        value: Math.random() * 0.3 + 0.7,
      }
      authBankAData.push(bankAPoint)
      authBankBData.push(bankBPoint)
    }

    const feesPerProviders = [
      {
        key: "Provider A",
        value: 0.023,
      },
      {
        key: "Provider B",
        value: 0.017,
      },
      {
        key: "Provider C",
        value: 0.025,
      },
    ]
    return (
      <div className="row">
        <div className="large-12 columns">
          <ContentLayout
            title={
              <div className="row">
                <div className="medium-12 columns">
                  <h4>Monitoring</h4>
                </div>
              </div>
            }
          >
            <div className="row margin-bottom">
              <div className="large-12 columns">
                <span className="welcome-title">{BOARDS_WELCOME_TITLE}</span>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <p>{BOARDS_WELCOME_TEXT}</p>
              </div>
            </div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                {hasFeature(this.props.currentProject.project.feature_flags, "analytics-v2") ? (
                  <Link
                    to={`/projects/${this.props.params.project}/boards/new`}
                    class="round main button small"
                  >
                    Get started now
                  </Link>
                ) : (
                  <CallToAction>
                    <a className="round main button small">Get started now</a>
                  </CallToAction>
                )}
              </div>
            </div>
          </ContentLayout>
          <div className="monitoring-sample">
            <ContentLayout
              title={
                <div className="row">
                  <div className="medium-12 columns">
                    <h4>My business payment performance</h4>
                  </div>
                </div>
              }
            >
              <div className="row">
                <div className="large-6 columns">
                  <div className="row margin-bottom">
                    <div className="large-12 columns">Authorization rate per issuing bank</div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">
                      <LineChart
                        data={[
                          {
                            key: "Bank A",
                            datapoints: authBankAData,
                          },
                          {
                            key: "Bank B",
                            datapoints: authBankBData,
                          },
                        ]}
                        type="percentage"
                        colors={[
                          {
                            key: "Bank A",
                            color: "#dfe6e9",
                          },
                          {
                            key: "Bank B",
                            color: "#dfe6e9",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="large-6 columns">
                  <div className="row margin-bottom">
                    <div className="large-12 columns">Fees per payment provider</div>
                  </div>
                  <div className="row">
                    <div className="large-12 columns">
                      <BarChart
                        data={[
                          {
                            key: "single",
                            datapoints: feesPerProviders,
                          },
                        ]}
                        type="percentage"
                        colors={[
                          {
                            key: "single",
                            color: "#dfe6e9",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ContentLayout>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(withRouter(Welcome))
