import React from "react"
import Select from "react-select"
import type { $Filter } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
type Props = {
  value: $Filter
  onChange: (arg0: $Filter) => void
}

class MetadataFilter extends React.Component<Props> {
  render() {
    const { value, onChange } = this.props
    return (
      <div className="row collapse">
        <div className="medium-4 columns">
          <input
            type="text"
            value={value.path.replace("metadata.", "")}
            onChange={event => {
              const newFilter = { ...value, path: `metadata.${event.target.value}` }
              onChange(newFilter)
            }}
            placeholder="Metadata"
          />
        </div>
        <div className="medium-3 columns">
          <div
            className="labeled-field"
            style={{
              width: "100%",
            }}
          >
            <Select
              className="react-select"
              options={[
                {
                  label: "==",
                  value: "==",
                },
                {
                  label: "!=",
                  value: "!=",
                },
              ]}
              value={{
                value: value.operand,
                label: value.operand,
              }}
              onChange={newValue => {
                const newFilter = { ...value, operand: newValue.value }
                onChange(newFilter)
              }}
            />
          </div>
        </div>
        <div className="medium-5 columns">
          <input
            type="text"
            value={value.value[0]}
            onChange={event => {
              const newFilter = { ...value, value: [event.target.value] }
              onChange(newFilter)
            }}
            placeholder="value"
          />
        </div>
      </div>
    )
  }
}

export default MetadataFilter
