import type { $Action } from "../../util/Types"
import { LOCK_NETWORK, UNLOCK_NETWORK } from "./consts"
type $NetworkManagerState = {
  locked: boolean
}
const defaultState: $NetworkManagerState = {
  locked: false,
}
export default (
  state: $NetworkManagerState = defaultState,
  action: $Action,
): $NetworkManagerState => {
  switch (action.type) {
    case LOCK_NETWORK: {
      return { ...state, locked: true }
    }

    case UNLOCK_NETWORK: {
      return { ...state, locked: false }
    }
  }

  return state
}
