import "brace/mode/text"
export class CustomHighlightRules extends window.ace.acequire("ace/mode/text_highlight_rules")
  .TextHighlightRules {
  constructor() {
    super()
    this.$rules = {
      start: [
        {
          token: "support-class",
          regex: /(block|trigger_3ds|route|dynamic_3ds)/,
          // String or RegExp: the regexp to match
          next: "rule", // [Optional] String: next state to enter
        },
      ],
      rule: [
        {
          token: "support-opening",
          regex: /{/,
          next: "ruleInner",
        },
      ],
      ruleInner: [
        {
          token: "support-function-constant",
          regex: /([a-z_])/,
          next: "ruleInner",
        },
        {
          token: "support-opening",
          regex: /:/,
          next: "ruleParam",
        },
        {
          token: "support-closing",
          regex: /}/,
          next: "start",
        },
      ],
      ruleParam: [
        {
          token: "support-double-quotes",
          regex: /(\".*\")/,
          next: "ruleParam",
        },
        {
          token: "keyword.operator",
          regex: /(OR|AND|\+|\s-\s|\/|\*|>|>=|<|<=|==|!=|=)/,
          next: "ruleParam",
        },
        {
          token: "support-opening",
          regex: /{/,
          next: "ruleInner",
        },
        {
          token: "support-class",
          regex: /(block|trigger_3ds|route|dynamic_3ds)/,
          next: "ruleParam",
        },
        {
          token: "support-constant",
          regex: /(([a-z_]+\.)?[a-zA-Z0-9_,.\-]+)/,
          next: "ruleParam",
        },
        {
          token: "support-closing",
          regex: /;/,
          next: "ruleInner",
        },
        {
          token: "support-braces-brackets",
          regex: /\(|\)|\[|\]/,
          next: "ruleParam",
        },
        {
          token: "support-closing",
          regex: /}/,
          next: "ruleParam",
        },
      ],
    }
  }
}
export default class CustomRoutingRulesMode extends window.ace.acequire("ace/mode/text").Mode {
  constructor() {
    super()
    this.HighlightRules = CustomHighlightRules
  }
}
