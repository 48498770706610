import { FETCH_FRAUD_SERVICES } from "^/features/FraudServices/actions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetched: false,
    fetching: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_FRAUD_SERVICES): {
      return { ...state, fetching: true, fetched: false, error: false }
    }

    case typeFulfilled(FETCH_FRAUD_SERVICES): {
      return {
        ...state,
        ...action.payload,
        fraud_services: action.payload.fraud_services,
        fetching: false,
        fetched: true,
      }
    }
  }

  return state
}
