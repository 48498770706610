import React from "react"
import { Link } from "react-router"
import CountryData from "country-data"
import * as ProcessOut from "../../util/ProcessOut"
import Empty from "../Empty"
import * as Store from "../../stores/Store"
import { CLOSE_MODAL } from "^/util/Types"
import { track } from "^/vNext/tracking"
import { connect } from "react-redux"
import { $LaunchDarklyState } from "../../vNext/LaunchDarkly/LaunchDarklyReducer"

type Props = {
  customer: any
  launchDarkly: $LaunchDarklyState
}

class Customer extends React.Component<Props> {
  getLine(key, value) {
    return (
      <div className="row small-margin-bottom">
        <div className="large-4 columns">
          <span className="greyed">{key}</span>
        </div>
        <div className="large-8 columns">
          <span className="right">
            {value && value !== "" ? value : <span className="greyed">--</span>}
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { customer, launchDarkly } = this.props

    if (!customer) {
      return <Empty text="No customer data available." />
    }

    const linkContent = "See customer page ↗"
    function onClickHandler(event) {
      Store.store.dispatch({
        type: CLOSE_MODAL,
      })
      track("Transaction Details", "Click", "Customer", "See Customer Page")
    }

    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-7 columns">
              <h6 className="margin-bottom greyed capitalize font-console">Customer</h6>
            </div>
            <div className="large-5 columns text-right">
              {launchDarkly.flags["new-dash-customers-page"] ? (
                <a
                  href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${ProcessOut.config.ProjectId}/customers/${customer.id}`}
                  target="_self"
                  onClick={onClickHandler}
                  className="greyed"
                >
                  {linkContent}
                </a>
              ) : (
                <Link
                  onClick={onClickHandler}
                  to={`/projects/${ProcessOut.config.ProjectId}/customers/${customer.id}`}
                  class="greyed"
                >
                  {linkContent}
                </Link>
              )}
            </div>
          </div>
          {this.getLine("First name", customer.first_name)}
          {this.getLine("Last name", customer.last_name)}
          {this.getLine("Email", customer.email)}
          {this.getLine("Address1", customer.address1)}
          {this.getLine("Address2", customer.address2)}
          {this.getLine("City", customer.city)}
          {this.getLine("Zip", customer.zip)}
          {this.getLine(
            "Country Code",
            customer.country_code
              ? CountryData.countries[customer.country_code]
                ? CountryData.countries[customer.country_code].name
                : customer.country_code
              : null,
          )}
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  launchDarkly: store.launchDarkly,
}))(Customer)
