import { connect } from "react-redux"
import { replace } from "react-router-redux"
import React from "react"
import Auth from "^/util/Auth"
import type { $Dispatcher } from "../../util/Types"
type Props = {
  user: {
    details: {
      email?: string
    }
  }
  children?: React.ReactNode
  redirectPath: string
}
export const RequireAdminRights = ({
  user,
  children,
  redirectPath,
  dispatch,
}: Props & $Dispatcher) => {
  if (user.details?.email && !Auth.isAdminUser()) {
    dispatch(replace(redirectPath))
  }

  return <>{user.details?.email && children}</>
}
export default connect(store => ({
  user: store.user,
}))(RequireAdminRights)
