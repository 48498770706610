import React from "react"
import { Link } from "react-router"
import { connect } from "react-redux"
import AuthStore from "../stores/AuthStore"
import Header from "../components/layout/Header"
import * as UserActions from "../Actions/UserActions"
import NotificationCenter from "../features/NotificationBar/NotificationCenter"
import * as Store from "../stores/Store"
import Background from "../features/Background/Background"
import * as ProcessOut from "../util/ProcessOut"

const serialize = require("form-serialize")

class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      twoFactorNeeded: false,
    }
    this.signupPopupRef = React.createRef()
  }

  UNSAFE_componentWillMount() {
    document.title = "ProcessOut | Login"
    this.loggedIn = this.loggedIn.bind(this)
    this.twoFactor = this.twoFactor.bind(this)
    AuthStore.on("two-factor", this.twoFactor)
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    this.unsubscribeLoginWatcher = Store.store.subscribe(this.loggedIn)
  }

  componentWillUnmount() {
    AuthStore.removeListener("two-factor", this.twoFactor)
  }

  loginClicked(event) {
    event.preventDefault()
    const loginForm = document.querySelector("#login-form")
    const login = serialize(loginForm, {
      hash: true,
    })
    this.props.dispatch(
      UserActions.generateToken(login.email, login.password, login.two_step_auth_code),
    )
  }

  twoFactor() {
    this.setState({
      twoFactorNeeded: true,
      fetching: false,
    })
  }

  loggedIn() {
    const nextState = Store.store.getState()

    if (nextState.user.auth.loggedIn) {
      this.unsubscribeLoginWatcher()

      const redirectUrl = new URLSearchParams(window.location.search).get("redirectUrl")
      if (redirectUrl) {
        // TODO: check url is a valid processout domain, only redirect if it is whitelisted
        const url = new URL(redirectUrl)
        window.location.replace(url)
      } else {
        window.location.href = `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${
          ProcessOut.config.ProjectId || ""
        }`
      }
    }
  }

  redirectToContactUsPage = () => {
    this.signupPopupRef.current.className = this.signupPopupRef.current.className.replace(
      "loaded",
      "disappear",
    )
    window.location.href = "https://processout.com/contact-us/"
  }

  render() {
    let twoFactorCode

    if (this.props.user.auth.waiting_for_two_factor) {
      twoFactorCode = (
        <div className="row margin-bottom">
          <div className="large-10 large-centered columns">
            <input
              type="text"
              className="bottom-border"
              name="two_step_auth_code"
              placeholder="Two step code"
              hidden="true"
              required
            />
          </div>
        </div>
      )
    }

    let button = <input type="submit" className="round border button expanded" value="SIGN IN" />

    if (this.props.user.auth.fetching) {
      button = (
        <a className="round border button expanded">
          <div className="loading-spinner" />
        </a>
      )
    }

    return (
      <div className="colorful">
        <Background />
        <Header redirectToWebsite isTransparent />
        <div className="row huge-margin-top">
          <form id="login-form" onSubmit={this.loginClicked.bind(this)}>
            <div className="large-12 columns">
              <div className="row">
                <div className="large-4 medium-4 small-centered columns">
                  <NotificationCenter />
                </div>
              </div>
              <div
                data-testid="signup-popup"
                ref={this.signupPopupRef}
                className="row signup-popup loaded"
              >
                <div className="large-4 medium-4 small-centered columns">
                  <div className="normal-content rounded shadowed">
                    <div className="row margin-bottom">
                      <div className="large-12 columns centered">
                        <h3 className="title no-spacing">Sign In</h3>
                      </div>
                    </div>
                    <a href={`${process.env.DASHBOARD_NEXT_BASE_URL}/auth/sign-in`}>
                      <div className="row margin-bottom rounded alert-row">
                        <div className="large-10 medium-11 medium-centered columns ">
                          <p>
                            If your organization is using the new authentication mechanism, please
                            use <span style={{ textDecoration: "underline" }}>this form</span>{" "}
                            instead.
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="row">
                      <div className="large-10 medium-11 medium-centered columns">
                        <input
                          type="text"
                          name="email"
                          className="bottom-border"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="large-10 medium-11 medium-centered columns">
                        <input
                          type="password"
                          name="password"
                          className="bottom-border"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    {twoFactorCode}
                    <div className="row large-margin-bottom">
                      <div className="large-10 medium-11 medium-centered columns small-font text-right margin-bottom">
                        <Link
                          to="/reminder"
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Forgot your password ?
                        </Link>
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="medium-5 columns medium-centered text-center">{button}</div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns centered margin-bottom text-center">
                        Don&apos;t have an account?{" "}
                        <a
                          data-testid="contact-us-link"
                          onClick={this.redirectToContactUsPage}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Join us now!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  user: store.user,
}))(Login)
