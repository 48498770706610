import React from "react"
import { connect } from "react-redux"
import MonitoringPlusPopup from "./MonitoringPlusPopup"
import { hasFeature } from "../../FeatureFlags/FeatureFlags"
import type { $Project } from "../../../util/Types"
type Props = {
  children?: any
  hideWhenAuthorized: boolean
  // if true, will not display when the user has `analytics-v2` flag
  currentProject: {
    project: $Project
  }
}
type State = {
  show: boolean
}

class CallToAction extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      show: false,
    }
  }

  close = () => {
    this.setState({
      show: false,
    })
  }

  render() {
    if (
      this.props.hideWhenAuthorized &&
      hasFeature(this.props.currentProject.project.feature_flags, "analytics-v2")
    ) {
      return null
    }

    if (!this.state.show)
      return (
        <a
          onClick={() => {
            this.setState({
              show: true,
            })
          }}
        >
          {this.props.children}
        </a>
      )
    return (
      <a
        style={{
          cursor: "initial",
        }}
      >
        {this.props.children}
        <MonitoringPlusPopup close={this.close} />
      </a>
    )
  }
}

export default connect(store => ({
  currentProject: store.currentProject,
}))(CallToAction)
