import React from "react"
import type { $AwsRegion } from "./consts"
type Props = {
  onChange: (region: $AwsRegion) => void
  selected: boolean
  regionId: $AwsRegion
  regionName: string
  imageSrc: string
}
export class Region extends React.Component<Props> {
  render() {
    const { onChange, selected, regionId, regionName, imageSrc } = this.props
    return (
      <div className="medium-6 columns">
        <a
          onClick={() => {
            onChange(regionId)
          }}
        >
          <div className={`region-choice-button${selected ? " selected" : ""}`}>
            <div className="row small-margin-bottom text-center">
              <div className="medium-12 columns">
                <img alt={`${regionName} map`} src={imageSrc} />
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns greyed">{regionName}</div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}
export default Region
