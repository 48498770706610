import type { $Action } from "../../../../util/Types"
import type { $LastestRun } from "../../consts"
import { FETCH_LATEST_RUN } from "../../consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $TelescopeLatestRun = {
  fetching: boolean
  fetched: boolean
  latestRun: $LastestRun
}
const defaultState: $TelescopeLatestRun = {
  fetching: true,
  fetched: false,
  latestRun: {} as $LastestRun,
}
export default function (state: $TelescopeLatestRun = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_LATEST_RUN): {
      if (!action.payload) return state
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_LATEST_RUN): {
      if (!action.payload) return state
      return {
        fetching: false,
        fetched: true,
        latestRun: action.payload.latest_runs,
      }
    }

    case typeFailed(FETCH_LATEST_RUN): {
      return { ...state, ...defaultState, fetched: true, error: action.payload }
    }
  }

  return state
}
