import React from "react"
import { connect } from "react-redux"
import type { $RoutingRulesSettings } from "../consts"
import { getRoutingRules } from "../Utils"
import BlockingRules from "./BlockingRules"
import DynamicRules from "./DynamicRules"
import RoutingRules from "./RoutingRules"
import "./routingRules.scss"
import TriggerRules from "./TriggerRules"
type Props = {
  routingRulesSettings: $RoutingRulesSettings
}
export class RoutingRulesBuilder extends React.Component<Props> {
  render() {
    return (
      <div>
        <BlockingRules rules={getRoutingRules(this.props.routingRulesSettings, "block")} />
        <TriggerRules rules={getRoutingRules(this.props.routingRulesSettings, "trigger_3ds")} />
        <DynamicRules rules={getRoutingRules(this.props.routingRulesSettings, "dynamic_3ds")} />
        <RoutingRules rules={getRoutingRules(this.props.routingRulesSettings, "route")} />
      </div>
    )
  }
}
export default connect(store => ({
  routingRulesSettings: store.routingRulesSettings,
}))(RoutingRulesBuilder)
