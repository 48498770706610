import React from "react"
import Select from "react-select"
import { connect } from "react-redux"
import { UNIT_LIST } from "./consts"
import { UPDATE_CHART_BUILDER_DETAILS } from "../consts"
import type { $Dispatcher } from "../../../../util/Types"
type Props = {
  onChange: (arg0: string) => void
  unit: string
  disabled: boolean
} & $Dispatcher
type $Option = {
  label: string
  value: string
}

class Unit extends React.Component {
  props: Props
  updateValue = (newValue: $Option) => {
    this.props.dispatch({
      type: UPDATE_CHART_BUILDER_DETAILS,
      payload: {
        unit: newValue.value,
      },
    })
  }

  render() {
    const options = UNIT_LIST.map(unit => ({
      value: unit,
      label: unit,
    }))
    return (
      <div
        className="row collapse"
        style={{
          position: "relative",
          zIndex: 5,
        }}
      >
        <div className="medium-12 columns">
          {/* Used to be Creatable which is wrong, unit options are static and we are not creating new units */}
          <Select
            className="react-select"
            name="unit"
            placeholder="Unit ..."
            options={options}
            isDisabled={this.props.disabled}
            value={{
              label: this.props.unit,
              value: this.props.value,
            }}
            onChange={this.updateValue}
            isClearable={false}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  unit: store.dataExplorer.unit,
})

export default connect(mapStateToProps)(Unit)
