/**
 * Created by jeremylejoux on 08/06/17.
 */
import React from "react"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import ContentLayout from "../../../pages/ContentLayout"
import * as ApplePayActions from "../../../Actions/ApplePayActions"
import * as Store from "../../../stores/Store"
import * as ProcessOut from "../../../util/ProcessOut"
export class ApplePayState extends React.Component {
  props: Props
  state: State
  static defaultProps = {
    dispatch: null,
    currentProject: null,
  }

  /*:: _unsubscribe: ?() => void; */
  constructor() {
    super()
    this.state = {
      inReset: false,
    }
  }

  componentDidMount() {
    this._unsubscribe = Store.store.subscribe(this.storeDidUpdate.bind(this))
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  changeStatus(event: SwitchEvent) {
    if (!this.props.currentProject.project) return
    const enabled = event.target.checked
    const projectId = this.props.currentProject.project.id

    if (!enabled) {
      this.props.dispatch(ApplePayActions.disableApplePay(projectId))
    } else {
      this.props.dispatch(ApplePayActions.enableApplePay(projectId))
    }
  }

  componentWillUnmount() {
    if (this._unsubscribe) this._unsubscribe()
  }

  storeDidUpdate() {
    const state = Store.store.getState()
    const { dispatch, currentProject } = this.props

    if (
      this.state.inReset &&
      state.applePay.keys.fetched &&
      this._unsubscribe &&
      currentProject.project
    ) {
      this._unsubscribe()

      this.setState({
        inReset: false,
      })

      if (!currentProject.project) {
        this.projectError()
        return
      }

      dispatch(push(`/projects/${currentProject.project.id}/settings/apple-pay/step-2`))
    }
  }

  toggleReset() {
    const { currentProject } = this.props
    const confirmed = confirm(
      "This will erase all your apple pay configuration. Do you want to continue?",
    )

    if (confirmed) {
      if (!currentProject.project) {
        this.projectError()
        return
      }

      this.props.dispatch(ApplePayActions.resetApplePay(currentProject.project.id))
    }
  }

  shouldUploadCertificates(event: Event) {
    event.preventDefault()
    this.setState({
      inReset: true,
    })
    const { dispatch, currentProject } = this.props

    if (!currentProject.project) {
      this.projectError()
      return
    }

    dispatch(push(`/projects/${currentProject.project.id}/settings/apple-pay/step-2`))
  }

  projectError() {
    ProcessOut.addNotification("An error occured. Could not find your current project.")
    this.setState({
      inReset: false,
    })
  }

  render() {
    let { currentProject } = this.props
    if (!currentProject.project || !currentProject.project.applepay_settings) return null
    currentProject = currentProject.project
    let certificatesButton

    if (!currentProject.applepay_settings.has_certificates) {
      certificatesButton = (
        <a
          style={{
            marginLeft: "1em",
          }}
          className="button small round border"
          onClick={this.shouldUploadCertificates.bind(this)}
        >
          Upload certificates
        </a>
      )
    }

    if (this.state.inReset) {
      certificatesButton = (
        <div
          className="button border round small"
          style={{
            minWidth: "60px",
            marginLeft: "1em",
          }}
        >
          <div className="loading-spinner" />
        </div>
      )
    }

    return (
      <ContentLayout
        title={
          <div className="row">
            <div
              className="large-6 columns"
              style={{
                padding: "0",
              }}
            >
              <h4>Apple Pay</h4>
            </div>
            <div
              className="large-6 columns text-right"
              style={{
                paddingRight: "0",
              }}
            >
              <button className="button round small border" onClick={this.toggleReset.bind(this)}>
                Reset apple pay
              </button>
              {certificatesButton}
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="medium-6 columns">
            <label>Merchant ID</label>
            <h4>{currentProject.applepay_settings.merchant_id}</h4>
          </div>
          <div className="medium-6 columns text-right">
            <label>
              Apple Pay is currently{" "}
              {currentProject.applepay_settings.enabled ? "enabled" : "disabled"} on your project.
            </label>
            <label
              className="ios7-switch big"
              style={{
                fontSize: "1.6em",
              }}
            >
              <input
                type="checkbox"
                onChange={this.changeStatus.bind(this)}
                checked={currentProject.applepay_settings.enabled}
              />
              <span />
            </label>
          </div>
        </div>
      </ContentLayout>
    )
  }
}
type Props = {
  currentProject:
    | any
    | {
        fetched: boolean
        fetching: boolean
        project?: {
          id: string
          applepay_settings: any
        }
      }
  dispatch: any | (() => void)
}
type State = {
  inReset: boolean
}
type SwitchEvent = {
  target: {
    checked: boolean
  }
}
export default connect(store => ({
  currentProject: store.currentProject,
}))(ApplePayState)
