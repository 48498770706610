import type { $Filter } from "./RoutingRulesBuilder/Filter/consts"
import type { $Operand } from "../analytics/DataExplorer/ChartBuilder/Filters/consts"
import type { $GatewayConfiguration } from "../PaymentProviders/consts"

export type $Declaration = "route" | "block" | "trigger_3ds" | "dynamic_3ds"

export const ROUTING_RULES_MODES = {
  BUILDER: "builder",
  EDITOR: "editor",
} as const
export type $RoutingRulesModes = typeof ROUTING_RULES_MODES[keyof typeof ROUTING_RULES_MODES]
export type $RoutingRule = {
  id: string
  conditions: Array<$RoutingFilterGroup>
  gateways:
    | Array<{
        id: string
        gateway: string
        configurations?: Array<string> | null | undefined
      }>
    | null
    | undefined
  declaration: $Declaration
  tags: Array<string>
  new?: boolean
  run_for_card_verifications?: boolean
  dynamic_3ds_params?: Array<$DynamicFilters> | null | undefined
}
export type $DynamicFilters = {
  id: string
  path?: string
  value?: string
}
export type $RoutingRulesSettings = {
  routingRulesMode: $RoutingRulesModes
  rules: Array<$RoutingRule>
  rulesRaw: string
  totalTransactions: number
  saving: boolean
  initialRules: Array<$RoutingRule>
  initialRulesRaw: string
  rulesHaveBeenEdited: boolean
  ui: {
    selectedTabIndex: number
  }
}
export type $VelocityRule = {
  velocityPath: string
  interval: string
  operand: $Operand
  value: [number]
} & $Filter
export type $RoutingFilterGroup = {
  subGroup: $RoutingFilterGroup | null | undefined
  filters: Array<$Filter | $VelocityRule>
  logical: "or" | "and"
}
export type $ProcessorConfiguration = {
  configurations: Array<$GatewayConfiguration>
}
export const UPDATE_ROUTING_RULES_FORMULA = "UPDATE_ROUTING_RULES_FORMULA"
export const FETCH_ROUTING_RULES = "FETCH_ROUTING_RULES"
export const COMPILE_RULES = "COMPILE_RULES"
export const PREPARE_ROUTING_RULES_SETTINGS = "PREPARE_ROUTING_RULES_SETTINGS"
export const ADD_RULE = "ADD_RULE"
export const REMOVE_RULE = "REMOVE_RULE"
export const UPDATE_RULE = "UPDATE_RULE"
export const RULES_SET_TOTAL_TRANSACTIONS = "RULES_SET_TOTAL_TRANSACTIONS"
export const REQUEST_RULES_SAVING = "REQUEST_RULES_SAVING"
export const SAVE_ROUTING_RULES = "SAVE_ROUTING_RULES"
export const RESET_ROUTING_RULES = "RESET_ROUTING_RULES"
export const CLONE_RULE = "CLONE_RULES"
export const SELECT_TAB = "SELECT_TAB"
export const DYNAMIC_RULES_FILTER = [
  {
    value: "sca_exemption_reason",
    label: "Exemption",
  },
  {
    value: "challenge_indicator",
    label: "Challenge indicator",
  },
]
export const DYNAMIC_PARAMS = {
  sca_exemption_reason: [
    {
      label: "low-value",
      value: "low-value",
    },
    {
      label: "trusted-beneficiary",
      value: "trusted-beneficiary",
    },
    {
      label: "transaction-risk-analysis",
      value: "transaction-risk-analysis",
    },
  ],
  challenge_indicator: [
    {
      label: "no-preference",
      value: "no-preference",
    },
    {
      label: "no-challenge-requested",
      value: "no-challenge-requested",
    },
    {
      label: "challenge-requested",
      value: "challenge-requested",
    },
    {
      label: "challenge-requested-mandate",
      value: "challenge-requested-mandate",
    },
    {
      label: "no-challenge-requested-tra-performed",
      value: "no-challenge-requested-tra-performed",
    },
    {
      label: "no-challenge-requested-sca-performed",
      value: "no-challenge-requested-sca-performed",
    },
    {
      label: "no-challenge-requested-whitelist-exemption",
      value: "no-challenge-requested-whitelist-exemption",
    },
    {
      label: "challenge-requested-whitelist-prompt",
      value: "challenge-requested-whitelist-prompt",
    },
    {
      label: "cb-scoring",
      value: "cb-scoring",
    },
  ],
}
export const RULES_FILTERS = {
  name: {
    label: "Name",
    desc: "Transaction name",
    isMulti: true,
    type: "string",
  },
  currency: {
    label: "Currency",
    desc: "Currency used for the transactions: USD, EUR, CAD…",
    isMulti: true,
    type: "string",
  },
  card_country: {
    label: "Card country",
    desc: "Country in which the card was emitted: FR, US, UK…",
    isMulti: true,
    type: "string",
  },
  card_scheme: {
    label: "Card scheme",
    desc: "Card scheme: Visa, Mastercard, American Express…",
    isMulti: true,
    type: "string",
  },
  card_co_scheme: {
    label: "Card co-scheme",
    desc: "Card co-schemes: CB…",
    isMulti: true,
    type: "string",
  },
  card_iin: {
    label: "Card IIN/BIN",
    desc: "Card BIN/IIN",
    isMulti: true,
    type: "string",
  },
  card_type: {
    label: "Card type",
    desc: "Card types: Debit, Credit…",
    isMulti: true,
    type: "string",
  },
  card_category: {
    label: "Card category",
    desc: "Card categories: Consumer, Business…",
    isMulti: true,
    type: "string",
  },
  card_last4: {
    label: "Card last 4 digits",
    desc: "Last 4 digits of the card",
    isMulti: true,
    type: "string",
  },
  card_bank: {
    label: "Card issuing bank",
    desc: "Bank that issued the card used for the transactions",
    isMulti: true,
    type: "string",
  },
  amount: {
    label: "Amount",
    desc: "Transaction’s amount requested at its creation.",
    type: "number",
  },
  check_3ds: {
    label: "Is 3DS flow",
    desc: "Whether or not we are currently using a 3DS flow.",
    type: "boolean",
  },
  sca_exemption_reason: {
    label: "Exemption",
    desc: "Optional reason for requesting SCA exemption.",
    type: "string",
  },
  challenge_indicator: {
    label: "Challenge indicator",
    desc: "Optional challenge indicator field when requesting 3DS2.",
    type: "string",
  },
  rand: {
    label: "Random number (0-1)",
    desc: "i.e. match X% of transcations",
    type: "number",
  },
  velocity: {
    label: "Velocity",
    desc: "Filter by fields velocity",
    type: "number",
  },
  cvc_check: {
    label: "CVC check",
    desc: "Whether or not a CVC check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_postal_check: {
    label: "AVS postal check",
    desc: "Whether or not a postal check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_street_check: {
    label: "AVS street check",
    desc: "Whether or not a street check has been performed.",
    isMulti: true,
    type: "string",
  },
  avs_name_check: {
    label: "AVS name check",
    desc: "Whether or not a name check has been performed.",
    isMulti: true,
    type: "string",
  },
  merchant_initiated: {
    label: "Is merchant initiated",
    desc: "Whether or not a transaction is flagged as merchant initiated.",
    isMulti: false,
    type: "boolean",
  },
  fraud_services: {
    label: "Fraud services",
    desc: "Whether a specified fraud service should be allowed or denied.",
    isMulti: false,
    type: "fraud_service",
  },
  card_fingerprint: {
    label: "Card fingerprint",
    desc: "Card fingerprint is a unique identifier for the physical card.",
    isMulti: true,
    type: "string",
  },
  card_tokenization_method: {
    label: "Card tokenization method",
    desc: "Method based on how the card is tokenized",
    isMulti: true,
    type: "string",
  },
}
export const CONFIRM_TIMEOUT = 5
export const NORMALIZATION_FIELDS = [
  "card_scheme",
  "card_co_scheme",
  "card_type",
  "card_category",
  "card_bank",
  "cvc_check",
  "avs_postal_check",
  "avs_street_check",
  "avs_name_check",
]
