const RESOURCE_FIELDS = {
  tr: "id",
  iv: "invoice_id",
  sub: "subscription_id",
  card: "card_id",
  cust: "cust_id",
  proj: "project_id",
}

/**
 * Returns true if the string is a valid resource ID
 * @param id ID to validate
 * @returns {*|boolean|boolean}
 */
export function isValidResourceId(id: string): boolean {
  if (!id.includes("_")) return false
  const filterParts = id.split("_")
  const prefix = filterParts[0]
  const idPart = filterParts[1]
  return RESOURCE_FIELDS[prefix] && idPart.length === 32
}
export type $ResourceType = "transaction" | "invoice" | "card" | "customer" | "project"
export function getResourceTypeFromId(id: string): $ResourceType | null | undefined {
  if (!isValidResourceId(id)) return null
  if (id.includes("tr_")) return "transaction"
  if (id.includes("iv_")) return "invoice"
  if (id.includes("cust_")) return "customer"
  if (id.includes("card_")) return "card"
  if (id.includes("proj_")) return "project"
}
export function filterIsPolang(filter: string): boolean {
  return new RegExp(/.*[\=\=|!\=|<\=|>\=|<|>].*/).test(filter)
}
