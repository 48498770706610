import React from "react"
import AceEditor from "react-ace"
import "brace/mode/json"
type Props = {
  value: Record<string, string | null | undefined>
}
export class JSONViewer extends React.Component<Props> {
  render() {
    return (
      <AceEditor
        width="100%"
        height="250px"
        readOnly
        fontSize="14px"
        mode="json"
        value={JSON.stringify(this.props.value, null, "\t")}
        showPrintMargin={false}
        editorProps={{
          $blockScrolling: true,
        }}
        setOptions={{
          tabSize: 2,
        }}
      />
    )
  }
}
export default JSONViewer
