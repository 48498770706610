import React from "react"
import { connect } from "react-redux"
import ModalFooter from "../ModalFooter"
import * as CustomerDetailsActions from "../../Actions/CustomerDetailsActions"
import * as ProcessOutUtil from "../../util/ProcessOut"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $ProjectsState } from "^/stores/Reducers/ProjectsReducer"
import PaymentForm from "./PaymentForm"
import type { ScriptsState } from "^/stores/Reducers/JSReducer"
import { track } from "../../vNext/tracking"
type Props = {
  customerId: string
  projects: $ProjectsState
  scripts: ScriptsState
} & $Dispatcher

class TokenModal extends React.Component<Props> {
  constructor() {
    super()
    this._client = null
  }

  componentDidMount() {
    if (this.props.scripts.processout.loaded)
      this._client = new ProcessOut.ProcessOut(this.props.projects.current_project_id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this._client && nextProps.scripts.processout.loaded)
      this._client = new ProcessOut.ProcessOut(this.props.projects.current_project_id)
  }

  closeModal() {
    this.props.dispatch({
      type: CLOSE_MODAL,
    })
  }

  submit(event) {
    event.preventDefault()

    track("Customer", "Click", "New card token", "Save button")

    this._modalFooter.getWrappedInstance().lock()

    this._form.startLoading()

    const form = this._form.getProcessOutForm()

    const name = document.getElementById("card-holder-name").value

    this._client.tokenize(
      form,
      {
        name,
      },
      token => {
        this.props.dispatch(
          CustomerDetailsActions.addToken(this.props.customerId, token, () => {
            this._form.stopLoading()

            this.closeModal()
          }),
        )
      },
      err => {
        this._modalFooter.getWrappedInstance().unlock()

        this._form.stopLoading()

        ProcessOutUtil.addNotification(err.message)
      },
    )
  }

  openModal() {
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: "New card token",
        content: (
          <PaymentForm
            ref={m => (this._form = m)}
            processOutClient={this._client}
            project={this.props.projects.current_project_id}
          />
        ),
        footer: (
          <div className="row">
            <div className="medium-12 columns text-right">
              <ModalFooter
                ref={m => (this._modalFooter = m)}
                submitTitle="Save"
                submitCallback={this.submit.bind(this)}
                cancelCallback={() => track("Customer", "Click", "New card token", "Cancel button")}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}

export default connect(
  store => ({
    projects: store.projects,
    scripts: store.scripts,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(TokenModal)
