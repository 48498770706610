import React from "react"
import { arrayMove } from "react-sortable-hoc"
import { connect } from "react-redux"
import * as Actions from "../actions"
import type { $RoutingRule } from "../consts"
import type { $Dispatcher } from "../../../util/Types"
import TriggerRulesListing from "./TriggerRulesListing"
import { track } from "^/vNext/tracking"

type Props = {
  rules: Array<$RoutingRule>
} & $Dispatcher
export class TriggerRules extends React.Component<Props> {
  reorderedRules = (position: { oldIndex: number; newIndex: number }) => {
    const { dispatch, rules } = this.props
    dispatch(
      Actions.changeRoutingRulesOrder(
        "trigger_3ds",
        arrayMove(rules, position.oldIndex, position.newIndex),
      ),
    )

    track("Routing Rules", "Reorder", "Trigger 3DS", "Rule", position)
  }

  render() {
    return (
      <div className="row" data-tracking-location="Trigger 3DS">
        <div className="medium-2 columns">
          <span className="rule-type-title">Trigger 3DS</span>
        </div>
        <div className="medium-10 columns rule-linear-background">
          <TriggerRulesListing
            rules={this.props.rules}
            onSortEnd={this.reorderedRules}
            useDragHandle
          />
        </div>
      </div>
    )
  }
}
export default connect()(TriggerRules)
