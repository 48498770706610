import type { $ChartDisplay } from "../consts"
import { UPDATE_BOARD_GRID } from "../consts"
import type { $State } from "../../../../util/Types"
import { REQUEST_CHART_DATA_FETCH, SELECT_CHART_FOR_DELETION } from "./consts"
import { CANCEL_BOARD_EDITION } from "../Board/consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
type State = $ChartDisplay &
  $State & {
    selectedForDeletion: boolean
  }
const defaultState: State = {
  fetching: false,
  fetched: false,
  error: undefined,
  selectedForDeletion: false,
} as any

type Action = {
  type: string
  payload: {
    chart_target: string
  } & any
}
export function createChartReducerWithId(targetId: string): (arg0: State, arg1: Action) => State {
  return function (state: State = defaultState, action: Action): State {
    switch (action.type) {
      case `INIT_CHART_${targetId}`: {
        return { ...state, ...defaultState, ...action.payload }
      }

      case typeFulfilled(UPDATE_BOARD_GRID): {
        // the grid has been updated, wee look for the current chart
        const chart = action.payload.charts.find(chart => chart.id === targetId)

        if (chart) {
          // the chart has been found, we update its position
          const newChart = {
            ...state,
            position_x: chart.position_x,
            position_y: chart.position_y,
            size: chart.size,
            height: chart.height,
          }
          return { ...state, ...newChart }
        }

        break
      }

      case typePending(`${REQUEST_CHART_DATA_FETCH}_${targetId}`): {
        return { ...state, ...defaultState, fetching: true }
      }

      case typeFulfilled(`${REQUEST_CHART_DATA_FETCH}_${targetId}`): {
        return {
          ...state,
          fetching: false,
          fetched: true,
          data: action.payload.data,
          ...action.payload.chart,
          is_comparison: action.payload.is_comparison,
          hadToTrimData: action.payload.hadToTrimData,
        }
      }

      case typeFailed(`${REQUEST_CHART_DATA_FETCH}_${targetId}`): {
        return { ...state, fetching: false, fetched: true, error: action.payload }
      }

      case `${SELECT_CHART_FOR_DELETION}_${targetId}`: {
        return { ...state, selectedForDeletion: true }
      }

      case CANCEL_BOARD_EDITION: {
        return { ...state, selectedForDeletion: false }
      }
    }

    return state
  }
}
