import clsx from "clsx"
import * as React from "react"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import type { $RoutingRule } from "../../features/RoutingRules/consts"
import { DragDrop } from "../DragDrop/DragDrop"
import { Aside } from "./Aside/Aside"
import { CriteriaMenuWithContext } from "./CriteriaMenu/CriteriaMenuWithContext"
import { EditControl } from "./EditControl/EditControl"
import { KeyboardHandler } from "./KeyboardHandler"
import "./Routing.scss"
import { actions, initialState, RoutingContext, RoutingState } from "./RoutingContext"
import { ScrollPane } from "./ScrollPane/ScrollPane"
import { Sidebar } from "./Sidebar/Sidebar"
import { getConditionList } from "./utils"

export function reducer(state: RoutingState, action: actions): RoutingState {
  switch (action.type) {
    case "select.routing.rule":
      return { ...state, selectedRuleIndex: action.index }

    case "select.routing.card":
      return { ...state, selectedCardId: action.id }
  }

  return state
}

type Props = {
  routingRules: Array<$RoutingRule>
  hasChanges: boolean
  editing: boolean
  asideCollapsed?: boolean
  onSaveBtnClick?: React.MouseEventHandler
  onCancelValidation?: (callback: (confirmed: boolean) => void) => void
  onAddRuleBtnClick?: React.MouseEventHandler
  onUpdateRule?: (rule: $RoutingRule) => void
  onSortEnd?: (oldIndex: number, newIndex: number) => void
  onRemoveRule?: (id: string) => void
  cloneRule?: (id: string) => void
  onRouteSelection?: (index: number) => void
  onRemoveCondition?: (routingRule: $RoutingRule, filterId: string) => void
  // Allow fetching the condition options when condition is added
  fetchOptions: (filter: $Filter[]) => void
  onModeChanged: (value: boolean) => void
}

/**
 * The root container for all the visual elements under routing rule sections, e.g., aside sidebar, main routing lane, etc.
 */
export const Blocking = ({
  routingRules,
  hasChanges,
  editing,
  asideCollapsed: initAsideCollapsed = false,
  onSaveBtnClick,
  onCancelValidation,
  onAddRuleBtnClick,
  onSortEnd,
  onRemoveRule,
  cloneRule,
  onUpdateRule,
  fetchOptions,
  onModeChanged,
}: Props) => {
  // Only have effect when in edit mode, describe whether aside is collapsed
  const [asideCollapsed, setAsideCollapsed] = React.useState(initAsideCollapsed)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const selectedRule = routingRules[state.selectedRuleIndex]

  return (
    <DragDrop>
      <RoutingContext.Provider value={[state, dispatch]}>
        <KeyboardHandler
          routingRules={routingRules}
          editing={editing}
          onUpdateRule={onUpdateRule}
          onSortEnd={onSortEnd}
          onRemoveRule={onRemoveRule}
        >
          <EditControl
            editing={editing}
            disableSave={!hasChanges}
            onAddRuleBtnClick={onAddRuleBtnClick}
            onSaveBtnClick={onSaveBtnClick}
            onCancelValidation={onCancelValidation}
            onModeChanged={onModeChanged}
          />
          <div className="content">
            <Aside
              collapsed={asideCollapsed}
              editing={editing}
              onChange={collapsed => setAsideCollapsed(collapsed)}
            >
              <Sidebar
                conditions={getConditionList()}
                dynamic3dsParams={[]}
                authorizations={[]}
                onAddCondition={filter => {
                  const newRule = structuredClone(selectedRule)
                  newRule.conditions[0].filters.push(filter)
                  fetchOptions([filter])
                  onUpdateRule(newRule)
                }}
                onAddAuthorization={auth => {
                  const newRule = structuredClone(selectedRule)
                  newRule.gateways.push(auth)
                  onUpdateRule(newRule)
                }}
              ></Sidebar>
            </Aside>
            <main
              data-tracking-location="Block"
              className={clsx({
                editing: editing,
                "aside-collapsed": asideCollapsed,
              })}
            >
              <ScrollPane
                routingRules={routingRules}
                editing={editing}
                asideCollapsed={asideCollapsed}
                onRemoveRule={onRemoveRule}
                cloneRule={cloneRule}
                onSortEnd={onSortEnd}
                onUpdateRule={onUpdateRule}
                onAddCondition={filter => fetchOptions([filter])}
              ></ScrollPane>

              <CriteriaMenuWithContext
                onUpdateRule={onUpdateRule}
                routingRules={routingRules}
                editing={editing}
              ></CriteriaMenuWithContext>
            </main>
          </div>
        </KeyboardHandler>
      </RoutingContext.Provider>
    </DragDrop>
  )
}
