import type { $Input } from "^/util/Types"
export const PROVIDERS_PER_LINE = 4
export type $GatewaySubMenu = {
  name: string
  id: string
  description?: string
  not?: Array<string>
}
export type $GatewayMenu = {
  name: string
  id: string
  submenus: Array<$GatewaySubMenu>
}
export const PAYMENT_PROVIDERS_MENU: Array<$GatewayMenu> = [
  {
    name: "Accept cards",
    id: "credit-card",
    submenus: [],
  },
  {
    name: "Alternative payment solutions",
    id: "alternative",
    submenus: [
      {
        name: "E-Wallets",
        id: "e-wallet",
        description:
          "E-Wallet payment providers, such as PayPal, offer customers with an easy way to pay online",
      },
      {
        name: "Alt-Coins",
        id: "alt-coin",
        description: "Coins, like Bitcoins, can be used to pay online in a decentralized way",
      },
      {
        name: "Other",
        id: "",
        not: ["e-wallet", "alt-coin"],
      },
    ],
  },
]
export type $Gateway = {
  can_pull_transaction: boolean
  can_refund: boolean
  config: Array<$Input>
  display_name: string
  name: string
  flows: Array<any> | null | undefined
  identifier_fields: Array<string>
  instructions: Array<$Instruction>
  is_oauth_authentication: boolean
  logo_url: string
  public_fields: Array<string>
  sub_accounts: Array<string>
  tags: Array<string>
  url: string
  beta: boolean
}
export type $GatewayConfiguration = {
  created_at: string
  default_currency: string
  enabled: boolean
  gateway_id: number
  id: string
  is_pulling: boolean
  merchant_account_country_code: string | null | undefined
  name: string
  project_id: string
  public_keys: Record<string, string>
  sub_accounts_enabled: Array<string>
  gateway: $Gateway
  config_entries?: Array<$ConfigEntry>
  gatewayConfigurationName: string
}
export type $ConfigEntry = {
  has_data: boolean
  key: string
}
export type $Instruction = {
  fields: Array<string>
  text: string
  title: string
}
