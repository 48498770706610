import React from "react"
import Select from "react-select"
import * as ProcessOut from "^/util/ProcessOut"
import type { $Input } from "^/util/Types"
import type { $ConfigEntry, $Instruction } from "^/features/PaymentProviders/consts"

const ReactMarkdown = require("react-markdown")

const FIELD_FILLED = "*********"
type Props = {
  instruction: $Instruction
  configs: Array<$Input>
  configEntries?: Array<$ConfigEntry>
  projectID: string
  confID: string
  showInputs?: boolean
  showTexts?: boolean
}
type State = {}
export default class Instruction extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    const { instruction, configs, configEntries } = this.props
    const entriesWithData = configEntries ? this.getConfigEntriesWithData(configEntries) : []
    if (this.props.showInputs === false) return null
    instruction.fields.forEach(field => {
      configs.forEach(config => {
        if (config.key === field) {
          this.populateField(config, field, entriesWithData)
        }
      })
    })
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  getConfigEntriesWithData = (
    configEntries: Array<$ConfigEntry>,
  ): Array<string | null | undefined> =>
    configEntries.map(entry => {
      if (entry.has_data === true) {
        return entry.key
      }
    })
  displayCurrentPlaceholder = (e: Event, field: string) => {
    let newState = {
      [field]: {
        placeholder: "",
        options: [],
      },
    }

    if (this.state[field].placeholder === FIELD_FILLED) {
      this.props.configs.map(config => {
        if (field === config.key) {
          newState[field].placeholder = config.value

          if (config.value.includes("options")) {
            newState = this.populateOptions(config.value, field)
          }
        }
      })
      this.setState(newState)
    }
  }
  replacePlaceholderWithFilledState = (e: Event, field: string) => {
    const { configEntries } = this.props
    const entriesWithData = configEntries ? this.getConfigEntriesWithData(configEntries) : []

    if (entriesWithData.includes(field)) {
      if (!this.state[`${field}_selected`])
        this.setState({
          ...this.state[field],
          [field]: {
            placeholder: FIELD_FILLED,
          },
        })
    }
  }
  populateOptions = (value: string, field: string) => {
    try {
      const parsedField = JSON.parse(value)
      const options = parsedField.options.map(option => ({
        value: option.value,
        label: option.value,
      }))
      return {
        [field]: {
          placeholder: parsedField.placeholder,
          options,
        },
      }
    } catch (e) {
      ProcessOut.addNotification("Error while fetching authentication methods.", "error")
    }
  }
  populateField = (
    config: $Input,
    field: string,
    entriesWithData: Array<string | null | undefined>,
  ) => {
    let newState = {
      [field]: {
        placeholder: "",
        options: [],
      },
    }

    if (config.key === field) {
      newState[field].placeholder = config.value
    }

    // check if it's a dropdown field and populate options
    if (config.value.includes("options")) {
      newState = this.populateOptions(config.value, field)
    }

    if (entriesWithData.includes(field)) {
      newState[field].placeholder = FIELD_FILLED
    }

    this.setState(newState)
  }

  render() {
    const { instruction } = this.props
    instruction.text = instruction.text.replace("%%PROJECTID%%", this.props.projectID)
    instruction.text = instruction.text.replace("%%GATEWAYCONFIGURATIONID%%", this.props.confID)
    return (
      <div className="row">
        <div className="large-12 columns">
          {this.props.showTexts === false ? null : (
            <div className="row">
              <div className="large-12 columns">
                <h4>{instruction.title}</h4>
              </div>
            </div>
          )}
          {this.props.showTexts === false ? null : <ReactMarkdown source={instruction.text} />}
          {instruction.fields.map(field => {
            if (this.state[field]) {
              if (this.state[field].options && this.state[field].options.length > 0) {
                return (
                  <div
                    onMouseEnter={event => this.displayCurrentPlaceholder(event, field)}
                    onMouseLeave={event => this.replacePlaceholderWithFilledState(event, field)}
                    className="row"
                    key={field}
                  >
                    <div
                      className="large-12 columns"
                      style={{
                        marginBottom: "1em",
                      }}
                    >
                      <Select
                        className="react-select"
                        name={field}
                        options={this.state[field].options}
                        placeholder={this.state[field].placeholder}
                        value={this.state[`${field}_selected`]}
                        onChange={selectedValue =>
                          this.setState({
                            [`${field}_selected`]: selectedValue,
                          })
                        }
                      />
                    </div>
                  </div>
                )
              }

              return (
                <div className="row" key={field}>
                  <div className="large-12 columns">
                    <input
                      type="text"
                      name={field}
                      placeholder={this.state[field].placeholder}
                      onMouseEnter={event => this.displayCurrentPlaceholder(event, field)}
                      onMouseLeave={event => this.replacePlaceholderWithFilledState(event, field)}
                      className="bottom-border processor-input"
                      required
                    />
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
}
