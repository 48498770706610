import React from "react"
import Select from "react-select"
import Loading from "^/components/Loader"
import type { $Option } from "../consts"
import MetadataInput from "./MetadataInput"
import FraudServicesInput from "./FraudServicesInput"

type Props = {
  options?: Array<$Option> | null | undefined
  disabled: boolean | null | undefined
  renderOption: (arg0: $Option) => React.ReactElement<React.ComponentProps<"div">, "div">
  updateValue: (arg0: $Option) => void
  rtl?: boolean | null | undefined
  value: $Option
  metadataValue: string
  updateMetadataValue: (arg0: Event) => void
  fraudServicesValue: string
  updateFraudServicesValue: (arg0: Event) => void
  onSubmit: (arg0: Event) => void
  defaultMetadataValue?: string
  shouldDisplayApplyButton?: boolean | null | undefined
}
export class PlottedFieldInput extends React.Component<Props> {
  hasAdditionalField(value: string) {
    const valuesWithAdditionalField = ["metadata", "fraud_services"]
    return valuesWithAdditionalField.includes(value)
  }

  render() {
    const {
      options,
      disabled,
      renderOption,
      value,
      updateValue,
      rtl,
      metadataValue,
      updateMetadataValue,
      onSubmit,
      defaultMetadataValue,
      shouldDisplayApplyButton,
      fraudServicesValue,
      updateFraudServicesValue,
    } = this.props
    return !options ? (
      <Loading />
    ) : (
      <div className="plotted-field row" data-testid="plotted-field-input">
        <div className={`medium-${this.hasAdditionalField(value.value) ? 5 : 12} columns`}>
          <Select
            className="react-select"
            name="plotted_field"
            placeholder="Plotted field..."
            options={options}
            isDisabled={disabled}
            components={{
              Option: renderOption,
            }}
            value={value}
            onChange={updateValue}
            isClearable={false}
            menuStyle={{
              height: "700px",
            }}
            isRtl={rtl}
          />
        </div>
        {value.value.includes("metadata") && (
          <MetadataInput
            value={metadataValue}
            updateValue={updateMetadataValue}
            onSubmit={onSubmit}
            defaultValue={defaultMetadataValue}
            shouldDisplayApplyButton={shouldDisplayApplyButton}
          />
        )}
        {value.value === "fraud_services" && (
          <FraudServicesInput value={fraudServicesValue} updateValue={updateFraudServicesValue} />
        )}
      </div>
    )
  }
}
export default PlottedFieldInput
