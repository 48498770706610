import React from "react"
import { FormattedNumber } from "react-intl"
import moment from "moment"
import CardSchemeImage from "../../util/CardSchemeImage"
import type { TransactionModel } from "../../Types/Transaction"
import * as Helpers from "../../components/Helpers"
type Props = {
  transaction: TransactionModel
  isFirst: boolean // is first of its category
}

class TransactionItem extends React.Component<Props> {
  render() {
    const { transaction, isFirst } = this.props
    let customerEmail
    if (transaction.customer && transaction.customer.email)
      customerEmail = transaction.customer.email
    else if (transaction.buyer && transaction.buyer.email) customerEmail = transaction.buyer.email
    const date = moment(transaction.created_at)
    const isToday = date.isSame(moment(), "d")
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          {isFirst && (
            <div className="row collapse item-search-category">
              <div className="medium-12 columns">Transactions</div>
            </div>
          )}
          <div className="row collapse item-resource-row highlight">
            <div className="medium-12 columns">
              <div className="row collapse small-margin-bottom">
                <div className="medium-2 columns ">
                  <div
                    style={{
                      maxWidth: "50px",
                    }}
                  >
                    {transaction.card && CardSchemeImage(transaction.card)}
                  </div>
                </div>
                <div
                  className="medium-7 columns"
                  style={{
                    position: "relative",
                    top: ".25em",
                  }}
                >
                  {transaction.name}
                </div>
                <div
                  className="medium-3 columns text-right"
                  style={{
                    position: "relative",
                    top: ".25em",
                    fontSize: "1.1em",
                  }}
                >
                  <FormattedNumber
                    value={transaction.amount}
                    currency={transaction.currency}
                    style="currency"
                  />
                </div>
              </div>
              <div className="row collapse">
                <div
                  className="medium-2 columns"
                  style={{
                    fontSize: ".85em",
                  }}
                >
                  {isToday && date.format("HH:mm:ss")}
                  {!isToday && date.format("MM/DD/YYYY")}
                </div>
                <div className="medium-8 columns greyed">
                  {customerEmail || "No customer email available."}
                </div>
                <div className="medium-2 columns text-right">
                  {Helpers.generateTransactionStatusTag(transaction)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TransactionItem
