import type { $Action, $State } from "^/util/Types"
import {
  FETCH_CUSTOMER_TRANSACTIONS,
  FETCH_CUSTOMER_TRANSACTIONS_NORELOAD,
} from "^/Actions/CustomerDetailsActions"
import type { TransactionModel } from "^/Types/Transaction"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type TransactionsState = {
  transactions?: Array<TransactionModel>
  has_more?: boolean
  total_count?: number
} & $State
const defaultState = {
  fetching: false,
  fetched: false,
}
export default function (state: TransactionsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_CUSTOMER_TRANSACTIONS): {
      return { ...state, fetched: false, fetching: true }
    }

    case typeFulfilled(FETCH_CUSTOMER_TRANSACTIONS_NORELOAD): {
      return formatReducer(state, action)
    }

    case typeFulfilled(FETCH_CUSTOMER_TRANSACTIONS): {
      return formatReducer(state, action)
    }

    case typeFailed(FETCH_CUSTOMER_TRANSACTIONS): {
      return { ...state, fetched: true, fetching: false, error: action.payload }
    }
  }

  return state
}

function formatReducer(state, action) {
  return {
    ...state,
    fetched: true,
    fetching: false,
    has_more: action.payload.has_more,
    total_count: action.payload.total_count,
    transactions: action.payload.transactions,
  }
}
