import React from "react"
import { connect } from "react-redux"
import Delete from "../../components/Delete"
import Tooltip from "../../util/Tooltip"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
import type { $Collaborator } from "./consts"
import { PRIMARY_OWNER } from "./consts"
import MfaStatusIndicator from "./MfaStatusIndicator"
import PermissionGroupSelection from "./PermissionGroupSelection"

const md5 = require("js-md5")

type Props = {
  collaborator: $Collaborator
} & $Dispatcher
export class Collaborator extends React.Component<Props> {
  deleteModerator = () => {
    const { collaborator, dispatch } = this.props
    dispatch(Actions.deleteModerator(collaborator.id))
  }

  render() {
    const { collaborator } = this.props
    const role = collaborator ? collaborator.role : ""
    let email

    if (!collaborator.editing) {
      email = (
        <span className="greyed">
          <img
            alt="Customer`s avatar"
            className="gravatar-icon"
            style={{
              marginRight: "1em",
              display: "inline-block",
            }}
            src={`https://secure.gravatar.com/avatar/${md5(
              collaborator.user.email,
            )}?s=80&r=g&d=identicon`}
          />
          <div
            style={{
              display: "inline-block",
              width: "90%",
              textOverflow: "ellipsis",
            }}
          >
            {collaborator.user.email}
          </div>
        </span>
      )
    } else {
      email = (
        <input
          type="email"
          className="bottom-border"
          name="user_email"
          value={collaborator.user.email}
          onChange={event => {
            const { dispatch } = this.props
            dispatch(Actions.updateCollaboratorEmail(collaborator.id, event.target.value))
          }}
          placeholder="Account email"
          required
        />
      )
    }

    let submitButton

    if (role !== PRIMARY_OWNER) {
      submitButton = <Delete onDelete={this.deleteModerator} verticalMiddle />
    }

    const dataFor = "roleDisplay"
    let roleDisplay

    if (role === PRIMARY_OWNER) {
      roleDisplay = (
        <div
          className="large-12 columns greyed"
          style={{
            marginBottom: ".5em",
          }}
        >
          Primary owner of this project.
        </div>
      )
    } else if (collaborator) {
      roleDisplay = (
        <div
          className="medium-12 columns"
          style={{
            paddingRight: "0px",
          }}
        >
          <PermissionGroupSelection collaborator={collaborator} />
          <Tooltip
            content="Setup your own permissions using a permission group below"
            size="large"
            id={dataFor}
            place="left"
          >
            <div
              className="info"
              data-tip
              data-for={dataFor}
              style={{
                display: "inline-block",
                marginRight: "0.75em",
              }}
            >
              i
            </div>
          </Tooltip>
        </div>
      )
    }

    return (
      <div className="row small-margin-bottom">
        <div
          className="medium-3 columns"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {email}
        </div>
        <div className="medium-4 columns">
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#cacaca",
              position: "relative",
              top: "1em",
              opacity: 0.7,
            }}
          />
        </div>
        <div className="medium-4 columns">
          <div
            className="row"
            style={{
              marginTop: collaborator ? 0 : "0.5em",
            }}
          >
            {roleDisplay}
          </div>
        </div>
        <div className="medium-1 columns text-right">
          <div className="row huge-font">
            <span className="small-margin-right vertical-middle greyed">
              <MfaStatusIndicator isMfaEnabled={collaborator.user.two_step_enabled} />
            </span>
            {submitButton}
          </div>
        </div>
      </div>
    )
  }
}
export default connect()(Collaborator)
