import React from "react"
type Props = {}
type State = {
  name: string
  type: "sandbox" | "production"
}

class NewKeyModal extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      name: "",
      type: "production",
    }
  }

  getValues = () => ({ ...this.state })

  render() {
    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-4 columns">Name</div>
            <div className="medium-8 columns">
              <input
                type="text"
                value={this.state.name}
                onChange={e => {
                  this.setState({
                    name: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewKeyModal
