import React from "react"
import PolangValueItem from "./PolangValueItem"
type Props = {
  value: {
    value: string
    label: string
  }
  cursor: boolean
  selectPolangValue: () => void
}
export class SearchPolangValue extends React.Component<Props> {
  render() {
    const { value, cursor, selectPolangValue } = this.props
    const content = value
      .filter(item => !!item.value)
      .map((item, i) => (
        <div
          key={item.value}
          role="button"
          onClick={() => {
            selectPolangValue(item)
          }}
          className={cursor === i ? "highlighted" : ""}
          id={`polang-value-${i}`}
          tabIndex={0}
        >
          <PolangValueItem word={item.value} />
        </div>
      ))
    return <div>{content}</div>
  }
}
export default SearchPolangValue
