import React, { useState } from "react"
import clsx from "clsx"
import "./NewDashPopup.scss"
import { Button } from "^/vNext/Button"
import { trackTarget } from "^/vNext/tracking"

export type NewDashPopupProps = {
  // should be relative to the base url
  newDashRelativeUrl: string
  className?: string
}

const NewDashPopup: React.FC<NewDashPopupProps> = ({ newDashRelativeUrl, className }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const onViewNewVersion = event => {
    trackTarget(event.target, "Click", "View new version")
    window.location.href = `${process.env.DASHBOARD_NEXT_BASE_URL}${newDashRelativeUrl}`
  }

  const onClose = event => {
    trackTarget(event.target, "Click", "Close")
    setIsVisible(false)
  }

  return (
    isVisible && (
      <div
        className={clsx("new-dash-popup v-next", className)}
        data-tracking-location="New Dash Popup"
        data-testid="new-dash-popup"
      >
        <div className="content">
          <span className="message">Did you know, we&apos;ve updated this page?</span>
          <div className="buttons">
            <Button variant="tertiary" onClick={onClose}>
              Close
            </Button>
            <Button variant="secondary" onClick={onViewNewVersion}>
              View new version
            </Button>
          </div>
        </div>
      </div>
    )
  )
}

export default NewDashPopup
