import JSCookie from "js-cookie"
import { parseDomain, ParseResultType } from "parse-domain"
import { datadogRum } from "@datadog/browser-rum"

export const ACCESS_TOKEN_KEY = "token"
export const ACCESS_TOKEN_EXPIRY_KEY = "token-expiry"
export const REFRESH_TOKEN_KEY = "refresh-token"
export const REFRESH_TOKEN_EXPIRY_KEY = "refresh-token-expiry"
export const PROJECT_ID_KEY = "projectid"

export type AuthTokens = {
  accessToken: string
  accessTokenExpiry: number
  refreshToken: string
  refreshTokenExpiry: number
}

export const getCookieDomain = (hostName: string): string | undefined => {
  const result = parseDomain(hostName)

  switch (result.type) {
    case ParseResultType.Listed:
      return "." + result.domain + "." + result.topLevelDomains.join(".")
    case ParseResultType.Ip:
      return result.hostname
    case ParseResultType.Reserved:
      return result.labels.join(".")
    case ParseResultType.NotListed:
      return "." + result.labels.join(".")
    case ParseResultType.Invalid:
    default:
      return undefined
  }
}

const Cookies = JSCookie.withAttributes({
  path: "/",
  domain: getCookieDomain(window.location.hostname),
  expires: 365,
  sameSite: "strict",
  secure: ["production", "pre-prod", "staging"].includes(process.env.TARGET_ENV),
})

class CookieSettingError extends Error {
  private cookieKey: string
  constructor(cookieKey: string) {
    super(`Failed to set cookie with key "${cookieKey}"`)
    this.name = "CookieSettingError"
    this.cookieKey = cookieKey
  }
}

const setCookie = <T>(key: string, value: T, options?: JSCookie.CookieAttributes): void => {
  Cookies.set(key, JSON.stringify(value), options)
  const cookieOject = cookieInformation(key)
  // the limit for a cookie is approximately 4000 bytes, but we want to be safe
  // and get a warning at 3500 bytes so we can act in time
  if (cookieOject.size > 3500) {
    datadogRum.addAction("cookie-size-limit", {
      "cookie-name": cookieOject.cookieName,
      size: cookieOject.size,
    })
  }

  const storedValue = getCookie<T>(key)
  if (storedValue !== value) {
    datadogRum.addError(new CookieSettingError(key))
  }
}

const getCookie = <T>(key: string): T | null => {
  return JSON.parse(Cookies.get(key) ?? null)
}

const removeCookie = (key: string, options?: JSCookie.CookieAttributes): void =>
  Cookies.remove(key, options)

const getAuthTokens = (): Partial<AuthTokens> => {
  return {
    accessToken: getCookie<string>(ACCESS_TOKEN_KEY),
    accessTokenExpiry: getCookie<number>(ACCESS_TOKEN_EXPIRY_KEY),
    refreshToken: getCookie<string>(REFRESH_TOKEN_KEY),
    refreshTokenExpiry: getCookie<number>(REFRESH_TOKEN_EXPIRY_KEY),
  }
}

const setAuthTokens = (authTokens: AuthTokens): void => {
  setCookie<string>(ACCESS_TOKEN_KEY, authTokens.accessToken)
  setCookie<number>(ACCESS_TOKEN_EXPIRY_KEY, authTokens.accessTokenExpiry)
  setCookie<string>(REFRESH_TOKEN_KEY, authTokens.refreshToken)
  setCookie<number>(REFRESH_TOKEN_EXPIRY_KEY, authTokens.refreshTokenExpiry)
}

const deleteAuthTokens = (): void => {
  removeCookie(ACCESS_TOKEN_KEY)
  removeCookie(ACCESS_TOKEN_EXPIRY_KEY)
  removeCookie(REFRESH_TOKEN_KEY)
  removeCookie(REFRESH_TOKEN_EXPIRY_KEY)
}

const getProjectId = (): string | null => getCookie<string>(PROJECT_ID_KEY)

const setProjectId = (projectId: string): void => {
  setCookie<string>(PROJECT_ID_KEY, projectId)
}

const deleteProjectId = (): void => {
  removeCookie(PROJECT_ID_KEY)
}

const cookieInformation = (key: string): { cookieName: string; size: number } => {
  const byteSize = new TextEncoder().encode(Cookies.get(key)).length
  return {
    cookieName: key,
    size: byteSize,
  }
}

export default {
  getAuthTokens,
  setAuthTokens,
  deleteAuthTokens,
  getProjectId,
  setProjectId,
  deleteProjectId,
}
