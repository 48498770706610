import type { Dispatch, SetStateAction } from "react"
import React from "react"

type Props = {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  totalPages: number
}
export default function PaginationV2({ currentPage, setCurrentPage, totalPages }: Props) {
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const previousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  return (
    <div>
      <button
        disabled={currentPage === 1}
        onClick={previousPage}
        data-testid="previous-page-id"
        data-auto-tracking={true}
        data-tracking-label="Previous Page"
      >
        ← previous
      </button>
      <button
        disabled={currentPage === totalPages}
        onClick={nextPage}
        style={{
          marginLeft: "5rem",
        }}
        data-testid="next-page-id"
        data-auto-tracking={true}
        data-tracking-label="Next Page"
      >
        next &rarr;
      </button>
    </div>
  )
}
