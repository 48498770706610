import { put, takeLatest } from "redux-saga/effects"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { APIcallPromise } from "^/util/ProcessOut"
export const REQUEST_DECLINE_EXPLANATION_FETCH = "REQUEST_DECLINE_EXPLANATION_FETCH"
export type $FetchDeclineExplainationAction = {
  type: "REQUEST_DECLINE_EXPLANATION_FETCH"
  payload: {
    transaction: string
  }
}

function* fetchDeclineExplaination(
  action: $FetchDeclineExplainationAction,
): Generator<any, any, any> {
  try {
    const { transaction } = action.payload
    const explainationResult = yield APIcallPromise(
      `/transactions/${transaction}/decline-explainer`,
      "GET",
      null,
      null,
      true,
      true,
    )
    yield put({
      type: typeFulfilled(REQUEST_DECLINE_EXPLANATION_FETCH),
      payload: explainationResult.data.explanation,
    })
  } catch (error) {
    yield put({
      type: typeFailed(REQUEST_DECLINE_EXPLANATION_FETCH),
      payload: {
        error,
      },
    })
    datadogRum.addError(error)
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_DECLINE_EXPLANATION_FETCH, fetchDeclineExplaination)
}
