import Tooltip, { TooltipProps } from "^/util/Tooltip"
import * as React from "react"
import "./TelescopeTitle.scss"

export type TelescopeTitleProps = {
  title: string
  tooltip: TooltipProps
}

const TelescopeTitle = ({ title, tooltip }: TelescopeTitleProps) => {
  return (
    <div className="telescope-title">
      <div className="small-margin-bottom title-text">{title}</div>
      <Tooltip content={tooltip.content} id={tooltip.id} place="top" size="large">
        <div className="info tooltip-icon" data-tip data-for={tooltip.id}>
          i
        </div>
      </Tooltip>
    </div>
  )
}

export default TelescopeTitle
