import React, { useRef, useState } from "react"
import Table, { $Column, $SortDirection, TableProps } from "./Table"
import clsx from "clsx"
import { trackTarget } from "^/vNext/tracking"

export type AccordionTableProps<T> = {
  getExpandedContent: (data: T) => React.ReactNode
  collapseComponent?: React.ReactNode
  expandComponent?: React.ReactNode
} & TableProps<T>

export default function AccordionTable<T>({
  columns,
  datas,
  sort,
  getExpandedContent,
  name,
  collapseComponent = "Hide details",
  expandComponent = "Show details",
}: AccordionTableProps<T>) {
  const [expandedData, setExpandedData] = useState<T | null>(null)
  const ref = useRef()

  const onSort = (direction: $SortDirection, id: string) => {
    setExpandedData(null)
    sort(direction, id)
  }

  const expandCollapseColumn: $Column<T> = {
    id: "expand-collapse",
    header: "",
    renderCell: (data: T) => (data === expandedData ? collapseComponent : expandComponent),
    hideSort: true,
    align: "right",
  }

  const onSetExpandedRow = (data: T) => {
    setExpandedData(expandedData === data ? null : data)
    trackTarget(ref.current, "Click", "Accordion Row", {
      tableName: name,
      collapsed: expandedData === data,
    })
  }

  const tableColumns = [...columns, expandCollapseColumn]

  return (
    <div ref={ref}>
      <Table
        data-testid="accordion-table"
        columns={tableColumns}
        datas={datas}
        sort={onSort}
        rowRenderer={getAccordionTableRowRender(
          getExpandedContent,
          expandedData,
          onSetExpandedRow,
          tableColumns.length,
        )}
        name={name}
      />
    </div>
  )
}

function getAccordionTableRowRender<T>(
  getExpandedContent: (data: T) => React.ReactNode,
  expandedData: T,
  onSetExpandedRow: (data: T) => void,
  columnCount: number,
) {
  const toggle = (data: T) => {
    onSetExpandedRow(data)
  }

  return function (data: T, dataIndex: number, columns: $Column<T>[]) {
    return (
      <ExpandableTableRow
        expandedContentComponent={getExpandedContent(data)}
        key={dataIndex}
        toggle={toggle}
        isExpanded={expandedData === data}
        data={data}
        columnCount={columnCount}
      >
        {columns.map(column => (
          <td key={column.header} className={clsx({ "align-right": column.align === "right" })}>
            {column.renderCell(data)}
          </td>
        ))}
      </ExpandableTableRow>
    )
  }
}

type ExpandableTableRowProps<T> = {
  expandedContentComponent: React.ReactNode
  toggle: (data: T) => void
  isExpanded: boolean
  data: T
  columnCount: number
}

function ExpandableTableRow<T>({
  children,
  expandedContentComponent,
  toggle,
  isExpanded,
  data,
  columnCount,
}: React.PropsWithChildren<ExpandableTableRowProps<T>>) {
  return (
    <>
      <tr onClick={() => toggle(data)} style={{ cursor: "pointer" }}>
        {children}
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={columnCount}>{expandedContentComponent}</td>
        </tr>
      )}
    </>
  )
}
