import React from "react"
import { TooltipProps } from "^/util/Tooltip"
import LegendChart from "../visualisations/LegendChart"
import TelescopeTitle from "./TelescopeTitle"

type Props = {
  legend: string[]
  tooltip: TooltipProps
  chartTitle: string
}

export default function ChartHeader({ legend, chartTitle, tooltip }: Props) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <TelescopeTitle title={chartTitle} tooltip={tooltip} />
      <LegendChart legend={legend} />
    </div>
  )
}
