import React from "react"
import { $Filter } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Creatable, Option, Select } from "^/vNext/Select/Select"
import { OptionContext } from "../OptionContext"
import { getOperandOptions, isMulti, toReactSelectValue } from "../utils"

type Props = {
  condition: $Filter
  operand: Option[]
  value: number
  onOperandChange: (change: Option) => void
  onValueChange: (value: number) => void
}

export const RandomSelection = ({
  condition,
  operand,
  value,
  onOperandChange,
  onValueChange,
}: Props) => {
  return (
    <>
      <Select
        aria-label="operand"
        value={operand}
        options={getOperandOptions(condition.path)}
        onChange={onOperandChange}
      />
      <input
        aria-label="value"
        type="number"
        step={0.1}
        value={value || 0}
        onChange={e => {
          onValueChange(Number(e.target.value))
        }}
      ></input>
    </>
  )
}
