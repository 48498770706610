import React from "react"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import Prices from "./InvoiceEdit/Prices"
import Metadata from "./Metadata"
import { createPlan, deletePlan, editPlan, START_PLAN_CREATION } from "../Actions/PlansActions"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $Plan } from "^/stores/Reducers/PlansReducer"
import type { CurrenciesState } from "^/stores/Reducers/CurrenciesReducer.js"

const serialize = require("form-serialize")

type Props = {
  modalName: string
  plan?: $Plan
  currenciesDetails: CurrenciesState
} & $Dispatcher
type State = {
  create: boolean
  showDetails: boolean
}
export class PlansModal extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      create: false,
      showDetails: false,
    }
  }

  componentDidMount() {
    if (this.props.plan) {
      this.id = this.props.plan.id
    }

    this.create = !this.props.plan

    if (this.create) {
      this.props.dispatch({
        type: START_PLAN_CREATION,
      })
    }

    this.setState({
      create: this.create,
      recurring: this.recurring,
    })
  }

  created() {
    this.props.dispatch({
      type: CLOSE_MODAL,
    })
  }

  save(event) {
    event.preventDefault()
    const planForm = document.querySelector("#plan-form")
    const data = serialize(planForm, {
      hash: true,
    })
    if (!data.metadata) data.metadata = {}

    const metas = this._meta.updateValue()

    for (let i = 0; i < metas.length; i++) {
      data.metadata[metas[i].key] = metas[i].value
    }

    if (data.metadata && Object.keys(data.metadata).length == 0) {
      data.metadata = {}
    }

    if (!data.amount) {
      data.amount = 0
    }

    if (this.state.create) {
      data.amount = parseFloat(data.amount)
      data.amount += ""
    } else {
      delete data.amount
    }

    const plan = JSON.stringify(data)

    if (this.state.create) {
      this.props.dispatch(createPlan(plan, this.created.bind(this)))
    } else {
      this.props.dispatch(editPlan(plan, this.props.plan.id, this.created.bind(this)))
    }
  }

  deletePlan() {
    if (confirm("Are you sure you want to delete this plan?")) {
      this.props.dispatch(deletePlan(this.props.plan.id))
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }
  }

  openModal() {
    const { plan } = this.props
    let planUrl
    let deleteButton

    if (plan) {
      planUrl = (
        <a target="_blank" href={plan.url} className="greyed" rel="noreferrer">
          Payment link ↗
        </a>
      )
      deleteButton = (
        <a
          onClick={this.deletePlan.bind(this)}
          className="round border small button"
          style={{
            opacity: 0.5,
          }}
        >
          Delete
        </a>
      )
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: this.props.modalName,
        content: (
          <div className="row">
            <ReactTooltip effect="solid" />
            <div className="large-12 columns">
              <form id="plan-form" onSubmit={this.save.bind(this)}>
                <div className="row small-margin-bottom">
                  <div
                    className="medium-12 medium-centered columns"
                    style={{
                      padding: "25px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Details</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <div className="row">
                          <div className="large-6 columns">
                            <label className="greyed">Name</label>
                            <input
                              type="text"
                              className="bottom-border"
                              name="name"
                              placeholder="Name"
                              defaultValue={plan ? plan.name : ""}
                              required
                            />
                          </div>
                          <div className="large-6 columns">
                            <label className="greyed">Identifier</label>
                            <input
                              type="text"
                              className="bottom-border"
                              name="id"
                              defaultValue={plan ? plan.id : ""}
                              placeholder="Identifier"
                              disabled={plan != null}
                              required
                            />
                          </div>
                        </div>
                        <div className="row small-margin-bottom">
                          <div className="large-6 medium-6 columns">
                            <label className="greyed">
                              Charging interval{" "}
                              <span
                                className="info"
                                data-tip="Format: 1d1w1m1y. 5d for every 5 days, 1m for every month."
                              >
                                i
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Interval"
                              name="interval"
                              defaultValue={plan ? plan.interval : ""}
                              disabled={plan != null}
                              className="bottom-border"
                              required
                            />
                          </div>
                          <div className="large-6 medium-6 columns">
                            <label className="greyed">
                              Trial period{" "}
                              <span
                                className="info"
                                data-tip="Format: 1d1w1m1y. 5d for every 5 days, 1m for every month."
                              >
                                i
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Trial period"
                              name="trial_period"
                              defaultValue={plan ? plan.trial_period : ""}
                              className="bottom-border"
                              required
                            />
                          </div>
                        </div>
                        <Prices
                          plan={plan}
                          displayLabel
                          currencies={this.props.currenciesDetails.currencies}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Additional data</h6>
                      </div>
                    </div>
                    <div className="row small-margin-bottom">
                      <div className="large-12 columns">
                        <Metadata resource={plan} ref={m => (this._meta = m)} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns text-right">{planUrl}</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="medium-4 columns">{deleteButton}</div>
            <div className="medium-8 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Cancel
              </a>
              <input
                type="button"
                onClick={this.save.bind(this)}
                className="round small main button white-text"
                value={this.props.plan ? "Save" : "Create"}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(
  store => ({
    currenciesDetails: store.currencies,
    planDetails: store.planDetails,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(PlansModal)
