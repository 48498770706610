import React from "react"
import { replace } from "react-router-redux"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Project from "../../util/Project"
import type { $Dispatcher } from "../../util/Types"
import "./sandbox.scss"
type Props = {
  params: {
    project: string | null | undefined
  }
} & $Dispatcher
export class SandboxSwitch extends React.Component<Props> {
  updateSandbox = () => {
    const { params } = this.props
    if (!params.project) return
    const project = new Project(params.project)
    const { dispatch } = this.props
    dispatch(
      replace(
        `/projects/${
          !project.isSandbox() ? `test-${project._id}` : project._id.replace("test-", "")
        }/`,
      ),
    )
  }

  render() {
    const { params } = this.props
    if (!params.project) return null
    const project = new Project(params.project)
    return (
      <div
        className="row"
        style={{
          padding: ".5em 30px",
          fontWeight: "500",
        }}
      >
        <div className="medium-9 columns">Sandbox mode</div>
        <div className="medium-3 columns text-right">
          <label className="sandbox-switch sandbox">
            <input type="checkbox" checked={project.isSandbox()} onChange={this.updateSandbox} />
            <span />
          </label>
        </div>
      </div>
    )
  }
}
export default connect()(withRouter(SandboxSwitch))
