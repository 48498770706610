import React from "react"
import { connect } from "react-redux"
import Metadata from "./Metadata"
import CurrencyField from "./CurrencyField"
import * as CouponsActions from "../Actions/CouponsActions"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import type { $Coupon } from "^/stores/Reducers/CouponsReducer.js"

const serialize = require("form-serialize")

export type Props = {
  modalName: string
  coupon: $Coupon
} & $Dispatcher
export class CouponModal extends React.Component<Props> {
  private _meta: any
  created() {
    this.props.dispatch({
      type: CLOSE_MODAL,
    })
  }

  save(event) {
    event.preventDefault()
    const couponForm = document.querySelector("#coupon-form")
    const data = serialize(couponForm, {
      hash: true,
    })
    data.percent_off = parseInt(data.percent_off)
    data.iteration_count = parseInt(data.iteration_count)
    data.max_redemptions = parseInt(data.max_redemptions)
    if (!data.metadata) data.metadata = {}

    const metas = this._meta.updateValue()

    for (let i = 0; i < metas.length; i++) {
      data.metadata[metas[i].key] = metas[i].value
    }

    if (data.metadata && Object.keys(data.metadata).length == 0) {
      delete data.metadata
    }

    if (!this.props.coupon) {
      this.props.dispatch(CouponsActions.createCoupon(data, this.created.bind(this)))
    } else {
      this.props.dispatch(
        CouponsActions.editCoupon(data, this.props.coupon.id, this.created.bind(this)),
      )
    }
  }

  deleteCoupon() {
    if (confirm("Are you sure you want to delete this coupon?")) {
      this.props.dispatch(CouponsActions.deleteCoupon(this.props.coupon.id))
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }
  }

  openModal() {
    const { coupon } = this.props as any
    const name = this.props.modalName
    let couponUrl
    let deleteButton

    if (coupon) {
      deleteButton = (
        <a
          onClick={this.deleteCoupon.bind(this)}
          className="round border small button"
          style={{
            opacity: 0.5,
          }}
        >
          Delete
        </a>
      )
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: name,
        content: (
          <div className="row">
            <div className="large-12 columns">
              <form id="coupon-form" onSubmit={this.save.bind(this)}>
                <div className="row small-margin-bottom">
                  <div
                    className="medium-12 medium-centered columns"
                    style={{
                      padding: "25px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Details</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <div className="row">
                          <div className="large-6 columns">
                            <label className="greyed">Identifier</label>
                            <input
                              type="text"
                              className="bottom-border"
                              name="id"
                              defaultValue={coupon ? coupon.id : ""}
                              placeholder="coupon-id"
                              disabled={coupon != null}
                              required
                            />
                          </div>
                          <div className="large-6 columns">
                            <label className="greyed">Percent off</label>
                            <input
                              type="text"
                              placeholder="0%"
                              name="percent_off"
                              defaultValue={coupon ? coupon.percent_off : ""}
                              className="bottom-border"
                              disabled={coupon != null}
                              required
                            />
                          </div>
                        </div>
                        <div className="row small-margin-bottom">
                          <div className="large-6 medium-6 columns">
                            <label className="greyed">Amount off</label>
                            <input
                              type="text"
                              placeholder="0.00"
                              name="amount_off"
                              defaultValue={coupon ? coupon.amount_off : ""}
                              disabled={coupon != null}
                              className="bottom-border"
                              required
                            />
                          </div>
                          <div className="large-6 medium-6 columns">
                            <CurrencyField
                              defaultValue={coupon ? coupon.currency : "USD"}
                              disabled={this.props.coupon != null}
                            />
                          </div>
                        </div>
                        <div className="row small-margin-bottom">
                          <div className="large-6 medium-6 columns">
                            <label className="greyed">Number of iterations</label>
                            <input
                              type="number"
                              step="1"
                              placeholder="0 (unlimited)"
                              name="iteration_count"
                              defaultValue={coupon ? coupon.iteration_count : ""}
                              disabled={coupon != null}
                              className="bottom-border"
                              required
                            />
                          </div>
                          <div className="large-6 medium-6 columns">
                            <label className="greyed">Max redemptions</label>
                            <input
                              type="number"
                              step="1"
                              placeholder="0 (unlimited)"
                              name="max_redemptions"
                              defaultValue={coupon ? coupon.max_redemptions : ""}
                              disabled={coupon != null}
                              className="bottom-border"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <h6 className="capitalize greyed font-console">Additional data</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="large-12 columns">
                        <Metadata resource={coupon} ref={m => (this._meta = m)} />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="medium-4 columns">{deleteButton}</div>
            <div className="medium-8 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Cancel
              </a>
              <input
                type="button"
                onClick={this.save.bind(this)}
                className="round small main button white-text"
                value={this.props.coupon ? "Save" : "Create"}
              />
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(
  store => ({
    currenciesDetails: store.currencies,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(CouponModal)
