import React from "react"
import { Button, Variant } from "../Button"
import { Modal } from "../Modal/Modal"

export type ButtonConfig = {
  visible: boolean
  label: string
  variant: Variant
}

export type SimpleModalProps = {
  title: string
  subtitle?: string
  open: boolean
  children?: React.ReactNode | string
  buttonConfigs: ButtonConfig[]
  // Closed handler
  onClose?: () => void
  // Button click handler
  onButtonClick: (button: string) => void
}

export const SimpleModal = ({
  open,
  children,
  onClose,
  buttonConfigs,
  onButtonClick,
  title = "Confirmation",
  subtitle,
}: SimpleModalProps) => {
  const buttons = buttonConfigs
    .filter(config => config.visible)
    .map(({ variant, label }) => {
      return (
        <Button
          key={label}
          variant={variant}
          onClick={() => onButtonClick(label)}
          data-auto-tracking="true"
          data-tag-title={title}
        >
          {label}
        </Button>
      )
    })

  return (
    <Modal
      title={title}
      subtitle={subtitle}
      style={{
        zIndex: 3,
      }}
      open={open}
      onClose={onClose}
      control={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 20,
          }}
        >
          {buttons}
        </div>
      }
    >
      <div
        style={{ display: "flex", flex: 1, flexDirection: "column", gap: 18 }}
        data-tracking-sub-page="Routing Rules V2"
        data-tracking-location="Modal"
      >
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </Modal>
  )
}
