import { combineReducers } from "redux"
export type ScriptsState = {
  processout: {
    loaded: boolean
  }
}
const defaultState = {
  loaded: false,
}

const processoutJS = function (state = defaultState, action) {
  switch (action.type) {
    case "PROCESSOUT_JS_LOADED": {
      return { ...state, loaded: true }
    }
  }

  return state
}

export default combineReducers({
  processout: processoutJS,
})
