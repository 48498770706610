import React from "react"
import { FormattedNumber } from "react-intl"
import { connect } from "react-redux"
type Props = {
  currency: string
  valueStyle: string
  value: number
}

function SummaryMainValue({ currency, value, valueStyle }: Props) {
  const getValueStyle = (valueStyle: string) => {
    let style = {}

    if (valueStyle === "percent") {
      style = {
        fontSize: "40px",
      }
    } else {
      style = {
        fontSize: "24px",
        marginTop: "0.35rem",
      }
    }

    return style
  }

  return (
    <div style={getValueStyle(valueStyle)}>
      {valueStyle === "percent" ? (
        <FormattedNumber
          value={value > 1 ? 1 : value}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
          style={"percent"}
        />
      ) : (
        <FormattedNumber value={value} style="currency" currency={currency} />
      )}
    </div>
  )
}

export default connect(store => ({
  currency: store.currentProject.project.default_currency,
}))(SummaryMainValue)
