import React from "react"
import Select from "react-select"
import type { $Dispatcher } from "^/util/Types"
import type { $Filter, $Operand } from "./consts"
import Operand from "./Operand"
import PlottedField from "^/features/analytics/ChartPreviewer/PlottedField/PlottedField"
import Velocity from "../Velocity/Velocity"
import type { $ProviderCompareOption } from "^/features/Telescope/Components/ProviderCompareSelector"
import type { $VelocityRule } from "^/features/RoutingRules/consts"
import { NORMALIZATION_FIELDS, RULES_FILTERS } from "^/features/RoutingRules/consts"
import ValueSelection from "./ValueSelection"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "^/features/SearchBar/consts"

export type FilterInputProps = {
  filterId: string
  filterValues: any
  defaultValue?: ($Filter | null | undefined) | $VelocityRule
  options: Array<$ProviderCompareOption>
  onChange: (arg0: $Filter) => void
} & $Dispatcher

type State = {
  path: string
  operand: $Operand
  value: Array<string | number | boolean> | string | number | boolean
  velocityPath: string | null | undefined
  interval: string | null | undefined
  isMulti: boolean
}

export class FilterInput extends React.Component<FilterInputProps, State> {
  constructor() {
    super()
    this.state = {
      path: "",
      operand: "==",
      value: [""],
      velocityPath: null,
      interval: null,
      isMulti: false,
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props

    if (defaultValue) {
      this.setState({
        path: defaultValue.path,
        operand: defaultValue.operand,
        value: defaultValue.value,
        velocityPath: defaultValue.velocityPath,
        interval: defaultValue.interval,
        isMulti:
          (RULES_FILTERS[defaultValue.path] && RULES_FILTERS[defaultValue.path].isMulti) ||
          (defaultValue.path.includes("metadata.") &&
            (defaultValue.operand === "==" || defaultValue.operand === "!=")) ||
          this.isFraudServicesValueType(defaultValue.path),
      })
    }
  }

  getDocument = (path: string) => {
    const transactionOperationsPaths = ["sca_exemption_reason", "challenge_indicator"]
    return transactionOperationsPaths.includes(path) ? "transaction_operations" : "transactions"
  }
  pathChanged = (newValue: string) => {
    if (!this.isFraudServicesValueType(newValue)) {
      this.props.dispatch({
        type: REQUEST_FETCH_VALUES_FOR_FIELD,
        payload: {
          document: this.getDocument(newValue),
          field: newValue,
        },
      })
    }

    if (newValue === "check_3ds") {
      this.setState(
        {
          path: newValue,
          value: true,
          isMulti: false,
        },
        () => {
          this.change()
        },
      )
    } else {
      const correspondingValue = this.props.options.find(o => o.value === newValue)
      this.setState(
        {
          path: newValue,
          isMulti:
            (correspondingValue && correspondingValue.isMulti) ||
            newValue.includes("metadata") ||
            this.isFraudServicesValueType(newValue),
        },
        () => {
          this.change()
        },
      )
    }

    this.valueWasSelected(null)
  }
  operandChanged = (newValue: $Operand) => {
    const isMulti =
      (this.state.isMulti || this.state.path.includes("metadata")) &&
      (newValue === "==" || newValue === "!=" || newValue === "===" || newValue === "!==")
    this.setState(
      {
        operand: newValue,
        isMulti,
        value:
          this.state.isMulti && !isMulti && this.state.value
            ? [this.state.value[0]]
            : this.state.value, // We switch from multi to non multi so we only keep the first array element as value
      },
      () => {
        this.change()
      },
    )
  }
  valueWasSelected = (newValue: Array<string> | null | undefined) => {
    this.setState(
      {
        value: newValue,
      },
      () => {
        this.change()
      },
    )
  }
  change = () => {
    this.props.onChange({ ...this.state, id: this.props.filterId })
  }
  velocityChanged = (newFilter: $VelocityRule) => {
    this.setState(
      {
        velocityPath: newFilter.velocityPath,
        interval: newFilter.interval,
      },
      () => {
        this.change()
      },
    )
  }

  isFraudServicesValueType(path: string) {
    return path.includes("action") || path.includes("fraud_service")
  }

  isBooleanValueType(path: string) {
    return RULES_FILTERS[this.state.path] && RULES_FILTERS[this.state.path].type === "boolean"
  }

  isSpecialValueType(path: string) {
    return this.isFraudServicesValueType(path) || this.isBooleanValueType(path)
  }

  getBooleanFieldOptions() {
    return [
      {
        label: "True",
        value: "true",
      },
      {
        label: "False",
        value: "false",
      },
    ]
  }

  getDefaultValue = () => {
    const { path, operand } = this.state

    if (NORMALIZATION_FIELDS.includes(path) && operand === "==") {
      return "==="
    }

    return operand
  }

  render() {
    const { filterValues, options } = this.props
    const { path, value, isMulti, operand } = this.state
    let booleanSelect = null

    if (this.isBooleanValueType(path)) {
      const options = this.getBooleanFieldOptions()
      const selectedValue = options.find(option => {
        if (value && typeof value === "object") {
          return value[0].toString() === option.value
        } else {
          return value?.toString() === option.value
        }
      })
      booleanSelect = (
        <div data-testid="boolean-select">
          <Select
            className="react-select"
            options={options}
            isClearable={false}
            value={selectedValue}
            onChange={(value: { label: string; value: boolean }) => {
              this.setState(
                {
                  value: value.value,
                },
                () => {
                  this.change()
                },
              )
            }}
          />
        </div>
      )
    }

    return (
      <div className="row small-margin-bottom" data-testid="filter-input">
        <div className={`large-${path && this.isSpecialValueType(path) ? 9 : 6} columns`}>
          <div className="row">
            <div className="medium-12 columns">
              <PlottedField
                onChange={this.pathChanged}
                options={options}
                defaultValue={path}
                allowMetadata
              />
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              {path === "velocity" && (
                <Velocity
                  filter={{ ...this.state }}
                  onChange={(filter: $Filter) => {
                    this.velocityChanged(filter)
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {path && this.isSpecialValueType(path) ? (
          <div className="medium-3 columns end">
            {booleanSelect}
            {this.isFraudServicesValueType(path) && (
              <ValueSelection onChange={this.valueWasSelected} value={value} isMulti={isMulti} />
            )}
          </div>
        ) : (
          <div>
            <div className="large-2 columns">
              <Operand
                isMulti={isMulti}
                path={path}
                onChange={this.operandChanged}
                defaultValue={this.getDefaultValue()}
              />
            </div>
            <div className="large-4 columns ">
              {operand.includes("null") ? (
                <div className="medium-4 columns end" />
              ) : (
                <ValueSelection
                  onChange={this.valueWasSelected}
                  options={filterValues[path]}
                  value={value}
                  isMulti={isMulti}
                  lading={filterValues[path] !== null}
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default FilterInput
