import React from "react"
import Creatable from "react-select/creatable"
import { SelectStyle } from "../../analytics/DataExplorer/ChartBuilder/consts"
type Props = {
  value: number
  onChange: (arg0: number) => void
}

class CountSelector extends React.Component<Props> {
  render() {
    const { value, onChange } = this.props
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label round-left">Trigger only if interval contains a minimum of</div>
        <div
          className="labeled-field"
          style={{
            width: "100px",
          }}
        >
          <Creatable
            value={{
              label: value,
              value,
            }}
            options={[
              {
                label: 0,
                value: 0,
              },
              {
                label: 1,
                value: 1,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 50,
                value: 50,
              },
              {
                label: 100,
                value: 100,
              },
              {
                label: 500,
                value: 500,
              },
              {
                label: 1000,
                value: 1000,
              },
            ]}
            styles={SelectStyle}
            onChange={newValue => {
              onChange(newValue.value)
            }}
            formatCreateLabel={label => label}
          />
        </div>
        <div className="field-label round-right">transactions</div>
      </div>
    )
  }
}

export default CountSelector
