import type { $Action, $State } from "^/util/Types"
import { REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH } from "../../../features/CustomerDetails/actions"
import { EDITING_CUSTOMER } from "^/Actions/CustomerActions"
import { FETCH_CUSTOMER_DETAILS } from "^/Actions/CustomerDetailsActions"
import type { Customer } from "^/Types/Customer"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type DetailsState = {
  customer: Customer
} & $State
const defaultState = {
  fetched: false,
  fetching: false,
  customer: null,
}
export default function (state: DetailsState = defaultState, action: $Action) {
  switch (action.type) {
    case typePending(FETCH_CUSTOMER_DETAILS): {
      return { ...defaultState, ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_CUSTOMER_DETAILS): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        customer: { ...state.customer, ...action.payload.customer },
      }
    }

    case typeFailed(FETCH_CUSTOMER_DETAILS): {
      return { ...state, fetching: false, fetched: true, error: action.payload }
    }

    case typeFulfilled(EDITING_CUSTOMER): {
      return { ...state, customer: { ...state.customer, ...action.payload.customer } }
    }

    case typeFulfilled(REQUEST_CUSTOMER_MONTHLY_REVENUE_FETCH): {
      return {
        ...state,
        customer: { ...state.customer, monthlyRevenue: action.payload.monthlyRevenue },
      }
    }
  }

  if (action.type.includes("@@router")) {
    return {
      fetching: false,
      fetched: false,
    }
  }

  return state
}
