import React from "react"
import { connect } from "react-redux"
import Markdown from "react-markdown"
import type { $Dispatcher } from "../../util/Types"
import type { $DeclineExplainerState } from "./reducer"
import Loading from "../../components/Loader"
import Error from "../../components/Error"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
type Props = {
  error_code: string
  categry: "ml"
  declineExplainer: $DeclineExplainerState
} & $Dispatcher

class DeclineExplainerModal extends React.Component {
  props: Props
  openModal = () => {
    const { dispatch, error_code, declineExplainer } = this.props
    dispatch({
      type: OPEN_MODAL,
      payload: {
        size: 6,
        header: (
          <div className="row">
            <div className="medium-12 columns text-center">
              <div className="decline-explainer-new-tag">NEW</div>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                Decline Explainer
              </div>
            </div>
          </div>
        ),
        content: <ConnectedModalContent error_code={error_code} />,
        footer: (
          <div className="row">
            <div className="medium-12 columns text-right">
              <a
                className="round border small button"
                onClick={() => {
                  dispatch({
                    type: CLOSE_MODAL,
                  })
                }}
              >
                close
              </a>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}

export default connect(
  store => ({
    declineExplainer: store.declineExplainer,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(DeclineExplainerModal)

class ModalContent extends React.Component {
  props: Props

  render() {
    const { dispatch, error_code, declineExplainer } = this.props
    return declineExplainer.fetching ? (
      <Loading />
    ) : declineExplainer.error ? (
      <Error />
    ) : (
      <div className="row">
        <div className="medium-12 columns">
          <div className="row small-margin-bottom">
            <div className="medium-12 columns">
              <div className="row">
                <div className="medium-12 columns">
                  <div className="small-margin-bottom font-console">{error_code}</div>
                </div>
              </div>
              <div className="row">
                <div
                  className="medium-12 columns"
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <img
                    alt={`${declineExplainer.tag} icon`}
                    className="decline-origin-class"
                    style={{
                      width: "10%",
                      verticalAlign: "middle",
                    }}
                    src={`//dashboard.processout.com/images/${declineExplainer.tag}.png`}
                  />
                  <p
                    style={{
                      fontSize: ".9em",
                      textAlign: "justify",
                      display: "inline-block",
                      width: "80%",
                      verticalAlign: "middle",
                      marginBottom: 0,
                    }}
                  >
                    <Markdown source={declineExplainer.description} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedModalContent = connect(store => ({
  declineExplainer: store.declineExplainer,
}))(ModalContent)
