import React from "react"
import Creatable from "react-select/creatable"
import type { $Dimension } from "./consts"
import { DIMENSIONS_MAP } from "./consts"
import TopSelection from "./TopSelection"
import StrategySelection from "./StrategySelection"
import SplitSelection from "./SplitSelection"
import { SelectStyle } from "../consts"
import { groupBy } from "../../Utils"
import { track } from "../../../../../vNext/tracking"
type Props = {
  dimensions: Array<$Dimension>
  onChange: (arg0: Array<$Dimension>) => void
}

class DimensionSelection extends React.Component<Props> {
  onChange = (
    newValue:
      | {
          label: string
          value: string
          top: number | null | undefined
          strategy: string | null | undefined
        }
      | null
      | undefined,
  ) => {
    if (!newValue) return
    const { onChange, dimensions } = this.props

    track("Analytics", "Select", "Dimension", "Group by", { field: newValue.value })

    const newDimensions = dimensions.slice(0)

    if (!DIMENSIONS_MAP.find(d => newValue && d.field === newValue.value)) {
      // metadata
      newDimensions[0].field = `metadata.${newValue.value.replace("metadata.", "")}`
      newDimensions[0].top = 10
      newDimensions[0].strategy = "value_descending"
    } else {
      newDimensions[0].field = newValue.value
      newDimensions[0].top = newValue.top
      newDimensions[0].strategy = newValue.strategy
    }

    onChange(newDimensions)
  }
  onStrategyChange = (newStrategy: "value_descending" | "value_ascending") => {
    const { onChange, dimensions } = this.props
    const newDimensions = dimensions.slice(0)

    track("Analytics", "Select", "Dimension", "Strategy", { strategy: newStrategy })

    for (let i = 0; i < newDimensions.length; i++) {
      newDimensions[i].strategy = newStrategy
    }

    onChange(newDimensions)
  }
  onTopChange = (newTop: number) => {
    const { onChange, dimensions } = this.props
    const newDimensions = dimensions.slice(0)

    track("Analytics", "Select", "Dimension", "Top", { top: newTop })

    for (let i = 0; i < newDimensions.length; i++) {
      newDimensions[i].top = newTop
    }

    onChange(newDimensions)
  }
  onSplitChange = (dimension: $Dimension | null | undefined) => {
    const { dimensions, onChange } = this.props
    const newDimensions = dimensions.slice(0)

    if (newDimensions.length > 1) {
      if (!dimension) newDimensions.splice(1, 1)
      else newDimensions[1] = dimension
    } else if (dimension) newDimensions.push(dimension)

    onChange(newDimensions)
  }

  render() {
    const { dimensions } = this.props
    let currentValue = DIMENSIONS_MAP.find(
      d => d.field === dimensions[0].field.replace("transaction_operations.", ""),
    )
    if (currentValue)
      currentValue = {
        label: currentValue.name,
        value: currentValue.field,
      }
    else
      currentValue = {
        label: dimensions[0].field.replace("metadata.", ""),
        value: dimensions[0].field,
      }
    const shouldDisplayStratAndTop = !new RegExp(/.*_at/).test(dimensions[0].field)
    const groupByGroupName = groupBy("group")
    const groupedByGroupName = groupByGroupName(DIMENSIONS_MAP)
    const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
      label: group,
      options: groupedByGroupName[group].map(d => ({
        value: d.field,
        label: d.name,
        top: d.top,
        strategy: d.strategy,
      })),
    }))
    return (
      <div className="dimensions-selection">
        <div className="field-label">group by</div>
        <div
          className="labeled-field"
          style={{
            width: "200px",
          }}
        >
          <Creatable
            options={OPTIONS}
            value={currentValue}
            clearable={false}
            onChange={this.onChange}
            styles={SelectStyle}
            valueComponent={props => <ValueComponent {...props} />}
            formatGroupLabel={formatGroupLabel}
            formatCreateLabel={(option: string) => `metadata.${option.replace("metadata.", "")}`}
          />
        </div>
        {shouldDisplayStratAndTop && (
          <div
            style={{
              display: "inline-block",
            }}
          >
            <div className="field-label">limit to</div>
            <StrategySelection value={dimensions[0].strategy} onChange={this.onStrategyChange} />
            <TopSelection value={dimensions[0].top} onChange={this.onTopChange} />
          </div>
        )}
        <SplitSelection
          baseDimension={dimensions[0]}
          dimension={dimensions[1]}
          onChange={this.onSplitChange}
        />
      </div>
    )
  }
}

class ValueComponent extends React.Component<{
  value: {
    label: string
    value: string
  }
  disabled: boolean
}> {
  render() {
    const { value } = this.props
    return (
      <div className="Select-value" title={value.value}>
        <span
          style={{
            color: "black",
          }}
        >
          {value.label}
        </span>
      </div>
    )
  }
}

export default DimensionSelection
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)
