import React from "react"
import { FormattedNumber } from "react-intl"
import type { $DataPoint } from "../../Boards/consts"

const PercentTooltip = (props: { point: $DataPoint }) => {
  const { point } = props
  return (
    <div>
      <FormattedNumber
        value={point.value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
        style="percent"
      />{" "}
      —{" "}
      <span className="greyed">
        <FormattedNumber value={point._count} />
        {" transactions"}
      </span>
    </div>
  )
}

export default PercentTooltip
