import React from "react"
import Select from "react-select"
import * as CountryActions from "../../Actions/CountryActions"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../util/Types"
type Props = {
  defaultValue?: string
} & $Dispatcher

class CountrySelector extends React.Component {
  props: Props
  state: {
    value: string | null | undefined
  }

  constructor() {
    super()
    this.state = {
      value: null,
    }
  }

  componentDidMount() {
    const { dispatch, defaultValue } = this.props
    dispatch(CountryActions.fetchCountries())
    if (defaultValue)
      this.setState({
        value: defaultValue,
      })
  }

  countrySelected(value) {
    this.setState({
      value,
    })
  }

  getSelected() {
    return this.state.value
  }

  render() {
    const { countries } = this.props.countries
    const fetching = !this.props.countries.fetched && this.props.countries.fetching
    let options = []

    if (!fetching && this.props.countries.fetched) {
      options = countries.map(country => ({
        value: country.code,
        label: country.name,
      }))
    }

    const selectedValue = options.find(option => option.value === this.state.value)
    return (
      <Select
        className="react-select"
        id="country-selector"
        name="country_code"
        placeholder="Country"
        options={options}
        isLoading={fetching}
        value={selectedValue}
        onChange={this.countrySelected.bind(this)}
      />
    )
  }
}

export default connect(store => ({
  countries: store.countries,
}))(CountrySelector)
