import React, { useRef, useState } from "react"
import Error from "^/components/Error"
import "./Table.scss"
import clsx from "clsx"
import { trackTarget } from "^/vNext/tracking"

export type $Column<T> = {
  id: string
  header: string
  renderCell: (field: T) => React.ReactNode
  align?: "left" | "right"
  hideSort?: boolean
}

export type TableProps<T> = {
  title?: string | React.ReactElement<React.ComponentProps<any>, any>
  name: string
  columns: $Column<T>[]
  datas: Array<T>
  sort: (direction: $SortDirection, id: string) => void
  rowRenderer?: (data: T, dataIndex: number, columns: $Column<T>[]) => React.ReactNode
  [otherProps: string]: any
}

export type $SortDirection = "asc" | "dsc"

function defaultRowRender<T>(data: T, dataIndex: number, columns: $Column<T>[]): React.ReactNode {
  return (
    <tr key={dataIndex}>
      {columns.map(column => (
        <td key={column.header} className={clsx({ "align-right": column.align === "right" })}>
          {column.renderCell(data)}
        </td>
      ))}
    </tr>
  )
}

export default function Table<T>({
  title,
  columns,
  datas,
  sort,
  name,
  rowRenderer = defaultRowRender,
  ...props
}: TableProps<T>) {
  const ref = useRef<HTMLDivElement>()
  const [sortDirection, setSortDirection] = useState<$SortDirection | null>(null)
  const [sortColumnKey, setSortColumnKey] = useState<string | null>(null)

  return (
    <div {...props} className="table" ref={ref}>
      <div data-testid="table-title">{title}</div>
      {datas.length === 0 ? (
        <div style={{ padding: "3rem" }}>
          <Error text="Our algorithm is still working on your data…" />
        </div>
      ) : (
        <div className="row">
          <table>
            <thead>
              <tr>
                {columns.map(column => (
                  <th
                    className={clsx("greyed bold margin-bottom", {
                      "align-right": column.align === "right",
                    })}
                    key={column.id}
                  >
                    <div className="sort-container">
                      {column.header}
                      {!column.hideSort && (
                        <div className="arrow-container">
                          <span
                            data-testid={`arrow_up_${column.id}`}
                            className={`arrow-up ${
                              sortDirection === "asc" && sortColumnKey === column.id ? "active" : ""
                            }`}
                            onClick={() => {
                              sort("asc", column.id)
                              setSortDirection("asc")
                              setSortColumnKey(column.id)
                              trackTarget(ref.current, "Click", "Sort", {
                                direction: "asc",
                                columnId: column.id,
                                tableName: name,
                              })
                            }}
                          />
                          <span
                            data-testid={`arrow_down_${column.id}`}
                            className={`arrow-down ${
                              sortDirection === "dsc" && sortColumnKey === column.id ? "active" : ""
                            }`}
                            onClick={() => {
                              sort("dsc", column.id)
                              setSortDirection("dsc")
                              setSortColumnKey(column.id)
                              trackTarget(ref.current, "Click", "Table Sort", {
                                direction: "dsc",
                                columnId: column.id,
                                tableName: name,
                              })
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{datas.map((data, index) => rowRenderer(data, index, columns))}</tbody>
          </table>
        </div>
      )}
    </div>
  )
}
