import React from "react"
import Tooltip from "../../util/Tooltip"
type Props = {
  isMfaEnabled: boolean
}
export class MfaStatusIndicator extends React.Component<Props> {
  render() {
    const { isMfaEnabled } = this.props
    const dataFor = "multiFactors"
    return (
      <Tooltip content="This user has MFA enabled!" size="small" id={dataFor} place="left">
        <span className={isMfaEnabled ? "icon icon-lock" : ""} data-tip data-for={dataFor} />
      </Tooltip>
    )
  }
}
export default MfaStatusIndicator
