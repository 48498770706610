import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { push } from "react-router-redux"
import type { $RequestExportDownload, $RequestExportFetchAction } from "./actions"
import { REQUEST_EXPORT_CREATION, REQUEST_EXPORT_DOWNLOAD, REQUEST_EXPORTS_FETCH } from "./actions"
import type { $ExportState } from "./reducer"
import Project from "../../util/Project"
import type { $SearchBarState } from "../SearchBar/reducer"
import type { $Action } from "../../util/Types"
import { CLOSE_MODAL } from "^/util/Types"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
import { addNotification, APIcallPromise } from "../../util/ProcessOut"

function* fetchTransactionsExports(action: $RequestExportFetchAction): Generator<any, any, any> {
  try {
    let { after, id } = action.payload
    const bound = after ? "start_after" : "end_before"

    if (!id) {
      id = ""
    }

    const exportsResults = yield call(
      APIcallPromise,
      `/exports?${bound}=${id}&limit=${10}&order=desc`,
      "GET",
    )
    yield put({
      type: typeFulfilled(REQUEST_EXPORTS_FETCH),
      payload: exportsResults.data,
    })
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_EXPORTS_FETCH),
      payload: error,
    })
  }
}

function* createTransactionsExport(action: $Action): Generator<any, any, any> {
  try {
    const exportsDetails: $ExportState = yield select<any>(store => store.transactionsExports)
    const currentProject = yield select<any>(store => store.projects.current_project_id)
    const project: Project = new Project(currentProject)
    const { payload } = action
    yield call(APIcallPromise, "/exports", "POST", {
      format: exportsDetails.form.format,
      description: exportsDetails.form.description,
      columns: exportsDetails.form.columns,
      start_at: exportsDetails.form.start_at,
      end_at: exportsDetails.form.end_at,
      notify_email_when_processed: exportsDetails.form.email,
      type: exportsDetails.form.type,
      filter:
        exportsDetails.form.type === "transaction"
          ? payload.filter
          : exportsDetails.form.type === "payout-item"
          ? exportsDetails.form.payout_id
          : null,
    })
    yield put({
      type: CLOSE_MODAL,
    })
    yield put(push(`/projects/${project._id}/reports/exports`))
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_EXPORT_CREATION),
      payload: error,
    })
  }
}

function* downloadExport(action: $RequestExportDownload): Generator<any, any, any> {
  try {
    // retrieve the download URL
    const urlResult = yield call(APIcallPromise, `/exports/${action.payload.id}`, "GET")

    if (!urlResult.data.download_url) {
      addNotification("This report is not ready yet. Please try again later", "error")
    } else {
      window.open(urlResult.data.download_url)
    }
  } catch (error) {
    datadogRum.addError(error)
    yield put({
      type: typeFailed(REQUEST_EXPORT_DOWNLOAD),
    })
  }
}

export default function* watchForSagas(): Generator<any, any, any> {
  yield takeLatest(REQUEST_EXPORTS_FETCH, fetchTransactionsExports)
  yield takeLatest(REQUEST_EXPORT_CREATION, createTransactionsExport)
  yield takeEvery(REQUEST_EXPORT_DOWNLOAD, downloadExport)
}
