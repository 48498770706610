import React from "react"
import UniqId from "uniqid"
import { connect } from "react-redux"
import {
  TRANSACTION_AMOUNT_FIELDS,
  TRANSACTION_FIELDS,
} from "../../../analytics/ChartPreviewer/PlottedField/consts"
import type { $Dispatcher } from "../../../../util/Types"
import FilterInput from "./FilterInput"
import { trackTarget } from "^/vNext/tracking"

type State = {
  filters: Array<$Filter>
}
type Props = {
  onChange: (filters: Array<$Filter>) => void
  defaultFilters?: Array<$Filter> | null | undefined
  filterValues: any
  options?: Array<{
    label: string
    value: string
  }>
} & $Dispatcher

class Filter extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filters: [],
    }
  }

  componentDidMount() {
    const { defaultFilters } = this.props
    if (defaultFilters)
      this.setState({
        filters: defaultFilters,
      })
  }

  addFilter = (event: Event) => {
    event.preventDefault()

    const { filters } = this.state
    filters.push({
      id: UniqId(),
      path: "",
      operand: "==",
      value: "",
    })
    this.setState({
      filters,
    })
    this.props.onChange(filters)

    trackTarget(event.target, "Click", "Plus Button")
  }
  filterUpdated = (filter: $Filter) => {
    const { filters } = this.state
    const index = filters.findIndex(item => item.id === filter.id)

    if (filters[index].path !== "velocity" && filter.path === "velocity") {
      // Filter wasn't a velocity, we set default values
      filter.interval = "5m"
      filter.velocityPath = "card_fingerprint"
    }

    filters[index] = filter
    this.setState({
      filters,
    })
    this.props.onChange(filters)
  }
  deleteFilter = (filterId: string) => {
    const { filters } = this.state
    const newFilters = filters.filter(item => item.id !== filterId)
    this.setState({
      filters: newFilters,
    })
    this.props.onChange(newFilters)
  }

  render() {
    const { filters } = this.state
    const emptyAddButton =
      filters.length === 0 ? (
        <div className="greyed">
          Only apply to specific transactions by{" "}
          <a onClick={this.addFilter}>&#43; adding a filter</a>
        </div>
      ) : null
    const addButton =
      filters.length !== 0 ? (
        <a className="add-button" onClick={this.addFilter}>
          +
        </a>
      ) : null
    // We need to mmrge all the possible fees for this dropdown as we also want _amount and _fee fields
    const options =
      this.props.options ||
      Object.keys(TRANSACTION_FIELDS)
        .map(field => ({
          value: field,
          label: field,
          desc: TRANSACTION_FIELDS[field],
        }))
        .concat(
          Object.keys(TRANSACTION_AMOUNT_FIELDS).map(field => ({
            value: field,
            label: field,
            desc: TRANSACTION_AMOUNT_FIELDS[field],
          })),
        )
        .sort((a, b) => {
          if (b.label > a.label) return -1
          return 1
        })
    return (
      <div className="row">
        {filters.length !== 0 ? (
          <div
            className="large-1 columns"
            style={{
              paddingTop: ".5em",
            }}
          >
            where
          </div>
        ) : null}
        <div className="large-12 columns">
          {emptyAddButton}
          {filters.map((filter, index, array) => (
            <div className="row filter-row" key={filter.id}>
              <div className="large-10 columns">
                <FilterInput
                  filterId={filter.id}
                  key={filter.id}
                  options={options}
                  dispatch={this.props.dispatch}
                  filterValues={this.props.filterValues}
                  onChange={this.filterUpdated}
                  defaultValue={filter}
                  filter={filter}
                />
              </div>
              <div
                className="large-2 columns"
                style={{
                  paddingTop: ".5em",
                }}
              >
                {index === array.length - 1 ? addButton : null}
                <a
                  onClick={e => {
                    this.deleteFilter(filter.id)
                    trackTarget(e.target, "Click", "Minus Button")
                  }}
                  className="remove-button"
                >
                  −
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  filterValues: store.searchBar,
}))(Filter)
