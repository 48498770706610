import React from "react"
import { FormattedDate, FormattedNumber } from "react-intl"
import { connect } from "react-redux"
import ContentLayout from "./ContentLayout"
import * as CustomerDefailsActions from "../Actions/CustomerDetailsActions"
import * as InvoicesActions from "../Actions/InvoicesActions"
import * as CurrencyActions from "../Actions/CurrencyActions"
import Token from "../components/CustomerDetails/Token"
import DetailsModal from "../components/CustomerDetails/DetailsModal"
import CustomerChargeModal from "../components/CustomerChargeModal"
import CustomerDetailsContent from "../components/CustomerDetailsContent"
import Loader from "../components/Loader"
import SubscriptionModal from "../components/SubscriptionModal"
import Pagination from "../components/Pagination"
import MetadataDisplay from "../components/MetadataDisplay"
import Empty from "../components/Empty"
import TokenModal from "../components/CustomerDetails/TokenModal"
import CustomerTransactions from "../components/CustomerDetails/CustomerTransactions"
import * as DetailsActions from "../features/CustomerDetails/actions"
import NotPermitted from "../features/Permissions/NotPermitted"
import Error from "../components/Error"
import { hasFeature } from "^/features/FeatureFlags/FeatureFlags"
import { track } from "../vNext/tracking"
import NewDashPopup from "../vNext/NewDashPopup"

const SUBS_PER_PAGE = 10

class CustomerDetails extends React.Component {
  componentDidMount() {
    if (!this.props.currenciesDetails.fetching && !this.props.currenciesDetails.fetched) {
      this.props.dispatch(CurrencyActions.loadCurrencies())
    }

    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    document.title = "Customer | ProcessOut"

    if (this.props.params.customer === "create") {
      this.setState({
        creating: true,
        fetchingInvoices: false,
      })
    } else {
      this.props.dispatch(CustomerDefailsActions.loadCustomerDetails(this.props.params.customer))
      this.props.dispatch(DetailsActions.fetchCustomerMonthRevenue(this.props.params.customer))
      this.props.dispatch(CustomerDefailsActions.fetchTokens(this.props.params.customer))
      this.props.dispatch(
        InvoicesActions.loadRecurringInvoices(
          this.props.params.customer,
          this.props.location.query.subsfilter,
          SUBS_PER_PAGE,
        ),
      )
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.customerDetails.details.fetching &&
      !nextProps.customerDetails.details.fetched &&
      this.props.params.customer !== "create"
    ) {
      this.props.dispatch(CustomerDefailsActions.loadCustomerDetails(nextProps.params.customer))
      this.props.dispatch(DetailsActions.fetchCustomerMonthRevenue(nextProps.params.customer))
    }
  }

  edited() {
    this.props.dispatch(CustomerDefailsActions.loadCustomerDetails(this.props.params.customer))
  }

  constructor() {
    super()
    this.state = {
      filter: "",
      creating: false,
    }
  }

  openModal(event) {
    event.preventDefault()

    this._modal.getWrappedInstance().openModal()
  }

  openTokenModal(event) {
    event.preventDefault()
    track("Customer", "Click", "Add a new card", "Add a new card button")
    this._tokenModal.getWrappedInstance().openModal()
  }

  openChargeModal(event) {
    event.preventDefault()
    track("Customer", "Click", "New payment", "New payment button")
    this._chargeModal.getWrappedInstance().openModal()
  }

  filter() {
    const filter = ""
    this.props.dispatch(
      CustomerDefailsActions.loadCustomerTransactions(filter, 10, this.props.params.customer, true),
    )
  }

  checkVar(obj) {
    return !obj || obj === "" ? <span className="greyed">--</span> : obj
  }

  getLine(title, value) {
    return (
      <div className="row small-margin-bottom">
        <div className="large-4 columns">
          <span className="greyed">{title}</span>
        </div>
        <div className="large-8 columns">
          <span className="right">{this.checkVar(value)}</span>
        </div>
      </div>
    )
  }

  edit() {
    track("Customer", "Click", "Edit", "Edit button")
    this._detailsModal.getWrappedInstance().openModal()
  }

  render() {
    const { customerDetails, currentProject } = this.props
    const featureFlags = currentProject?.project?.feature_flags

    if (
      !this.props.customerDetails.details.fetched ||
      !this.props.customerDetails.tokens.fetched ||
      !this.props.subscriptions.fetched
    ) {
      return (
        <ContentLayout title={<h4>{this.state.creating ? "New customer" : "Customer"}</h4>}>
          <Loader />
        </ContentLayout>
      )
    }

    if (customerDetails.details.error) {
      if (customerDetails.details.error.notPermitted)
        return (
          <ContentLayout title={<h4>Customer</h4>}>
            <NotPermitted />
          </ContentLayout>
        )
      return (
        <ContentLayout title={<h4>Customer</h4>}>
          <Error />
        </ContentLayout>
      )
    }

    const { customer } = customerDetails.details
    let invoices
    let transactions
    let createButton

    if (
      !this.state.creating &&
      this.props.customerDetails.details.fetched &&
      !this.props.subscriptions.fetching &&
      this.props.subscriptions.fetched
    ) {
      let subs = <Loader />

      if (!this.props.subscriptions.fetching && this.props.subscriptions.fetched) {
        if (this.props.subscriptions.subscriptions.length > 0)
          subs = <CustomerDetailsContent invoices={this.props.subscriptions.subscriptions} />
        else subs = <Empty text="The customer hasn't activated any subscription yet." />
      }

      createButton = (
        <div className="row">
          <div className="large-12 columns text-center">
            <a
              onClick={this.openModal.bind(this)}
              className={`round expanded border small button${
                this.props.subscriptions.subscriptions &&
                this.props.subscriptions.subscriptions.length == 0
                  ? ""
                  : " right"
              }`}
            >
              New subscription
            </a>
          </div>
        </div>
      )
      invoices = (
        <div className="row">
          <div className="large-12 columns">
            <div
              className="row margin-bottom"
              style={{
                marginBottom: "20px",
              }}
            >
              <div className="medium-3 columns">
                <h5>Active subscriptions</h5>
              </div>
              <div className="medium-6 columns margin-bottom" />
              <div className="medium-3 columns">{createButton}</div>
            </div>
            {subs}
          </div>
        </div>
      )
      const paymentButton = (
        <button
          onClick={this.openChargeModal.bind(this)}
          className="expanded round border small button right"
          type="submit"
          style={{
            marginLeft: "15px",
          }}
        >
          New payment
        </button>
      )
      transactions = (
        <div>
          <div className="row small-margin-bottom">
            <div className="medium-3 columns">
              <h5>Customer transactions</h5>
            </div>
            <div className="medium-6 columns margin-bottom" />
            <div className="medium-3 columns">{paymentButton}</div>
          </div>
          <CustomerTransactions
            location={this.props.location}
            filter={this.state.filter}
            customerId={this.props.params.customer}
          />
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <hr />
            </div>
          </div>
        </div>
      )
    }

    let iModal

    if (customer) {
      iModal = (
        <SubscriptionModal
          modalName="New subscription"
          ref={m => (this._modal = m)}
          customer={customer.id}
        />
      )
    }

    let name
    let date
    let mrr
    let details

    if (this.props.customerDetails.details.fetched) {
      if (!customer.first_name && !customer.last_name) name = "Anonymous user"
      else name = `${customer.first_name || ""} ${customer.last_name || ""}`
      date = (
        <FormattedDate value={customer.created_at} day="2-digit" month="2-digit" year="numeric" />
      )
      mrr = (
        <FormattedNumber
          value={customer.monthlyRevenue}
          style="currency"
          currency={this.props.currentProject.project.default_currency}
        />
      )
      const editButton = (
        <div className="row text-right">
          <div className="large-12 columns">
            <button
              onClick={this.edit.bind(this)}
              className="round border small button right"
              type="submit"
              style={{
                marginLeft: "15px",
              }}
            >
              Edit
            </button>
          </div>
        </div>
      )
      details = (
        <div>
          <div className="row small-margin-bottom">
            <div className="large-12 end columns">
              {this.getLine("First name", customer.first_name)}
              {this.getLine("Last name", customer.last_name)}
              {this.getLine("Email", customer.email)}
              {this.getLine(
                "Created at",
                <FormattedDate
                  value={customer.created_at}
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                />,
              )}
              {this.getLine("Address1", customer.address1)}
              {this.getLine("Address2", customer.address2)}
              {this.getLine("City", customer.city)}
              {this.getLine("Zip", customer.zip)}
              {this.getLine("Country", customer.country_code)}
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <MetadataDisplay metadata={customer.metadata} />
            </div>
          </div>
          {editButton}
        </div>
      )
      var tokens
      var emptyTokens

      if (this.props.customerDetails.tokens.fetched) {
        tokens = this.props.customerDetails.tokens.tokens
          .filter(token => token.is_chargeable)
          .map(token => <Token key={token.id} token={token} />)

        if (this.props.customerDetails.tokens.tokens.length === 0) {
          emptyTokens = <Empty text="This customer does not have any payment method yet." />
        }
      }
    }

    let newCardbutton

    if (this.props.scripts.processout.loaded) {
      newCardbutton = (
        <button
          onClick={this.openTokenModal.bind(this)}
          className="round border small button right"
          type="submit"
          style={{
            marginLeft: "15px",
          }}
        >
          Add a new card
        </button>
      )
    }

    return (
      <ContentLayout title={<h4>{this.state.creating ? "New customer" : "Customer"}</h4>}>
        <DetailsModal ref={m => (this._detailsModal = m)} customer={customer} creating={false} />
        <TokenModal
          customerId={customer.id}
          ref={function (m) {
            this._tokenModal = m
          }.bind(this)}
        />
        <CustomerChargeModal
          customerId={customer.id}
          ref={function (m) {
            this._chargeModal = m
          }.bind(this)}
        />
        <div className="row margin-bottom">
          <div className="large-5 columns">
            <div className="details-box">
              <div className="row">
                <div className="large-6 columns">
                  <label>Name</label>
                  <span className="huge-font bold">{name}</span>
                </div>
                <div className="large-6 columns text-right">
                  <label>Created at</label>
                  <span className="bold">{date}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="large-7 columns">
            <div className="details-box">
              <div className="row small-margin-bottom">
                <div className="medium-6 columns">
                  <label>Subscriptions</label>
                  <span className="greyed bold">—</span>
                </div>
                <div className="medium-6 columns text-right">
                  <label>Last 30-days revenue</label>
                  <span className="bold">{mrr}</span>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <label>Balance</label>
                  <FormattedNumber
                    value={customer.balance}
                    style="currency"
                    currency={
                      customer.currency || this.props.currentProject.project.default_currency
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="medium-5 columns">
            <div className="row">
              <div className="large-12 columns">
                <h6 className="margin-bottom capitalize greyed font-console">Details</h6>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">{details}</div>
            </div>
          </div>
          <div className="medium-7 columns">
            <div className="row">
              <div className="large-12 columns">
                <h6 className="margin-bottom capitalize greyed font-console">Payment methods</h6>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                {tokens}
                {emptyTokens}
                <div
                  className="row text-right"
                  style={{
                    marginTop: "1.5em",
                  }}
                >
                  <div className="large-12 columns">{newCardbutton}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {iModal}
        <div className="row margin-bottom margin-top">
          <div className="large-12 columns">{this.state.creating ? "" : <hr />}</div>
        </div>
        {transactions}

        {hasFeature(featureFlags, "subscriptions-activated") && invoices}
        <Pagination
          itemsArray={
            this.props.subscriptions.fetched ? this.props.subscriptions.subscriptions : []
          }
          hide={!this.props.subscriptions.fetched || this.props.subscriptions.fetching}
          location={this.props.location}
          disableURLUpdate
          hasMore={this.props.subscriptions.fetched ? this.props.subscriptions.has_more : false}
          reloadFunction={InvoicesActions.loadRecurringInvoices.bind(
            null,
            this.props.params.customer,
            this.state.filter,
            SUBS_PER_PAGE,
          )}
        />
        {this.props.launchDarkly.flags["new-dash-customers-page"] && (
          <NewDashPopup
            newDashRelativeUrl={`/projects/${this.props.params.project}/customers/${this.props.params.customer}`}
            className="margin-top"
          />
        )}
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  customerDetails: store.customerDetails,
  subscriptions: store.customers_subscriptions,
  currenciesDetails: store.currencies,
  currentProject: store.currentProject,
  projects: store.projects,
  permissions: store.permissions,
  scripts: store.scripts,
  launchDarkly: store.launchDarkly,
}))(CustomerDetails)
