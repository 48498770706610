import React from "react"

class ReportingBugWidget extends React.Component {
  render() {
    const atlassianLink = "https://processout.atlassian.net/servicedesk/customer/portal/1"
    return (
      <div>
        <a className="chat-button" target="_blank" href={atlassianLink} rel="noreferrer">
          CONTACT OUR SUPPORT
        </a>
      </div>
    )
  }
}

export default ReportingBugWidget
