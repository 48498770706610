import React from "react"
import { connect } from "react-redux"
import * as UserActions from "../Actions/UserActions"
import Loader from "../components/Loader"
import * as ProcessOut from "../util/ProcessOut"
import type { $Dispatcher } from "^/util/Types"
import type { TwoFactor, UserState } from "^/Types/User"

const serialize = require("form-serialize")

type Props = {
  twoFactor: TwoFactor
  user: UserState
} & $Dispatcher
type State = {
  confirm_email: boolean
  confirm_password: boolean
}

class UserUpdate extends React.Component<Props, State> {
  componentDidMount() {
    document.title = "Settings | ProcessOut"
    this.props.dispatch(UserActions.getTwoFactorData())
  }

  constructor() {
    super()
    this.state = {
      confirm_email: false,
      confirm_password: false,
    }
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  passwordTyped() {
    const passwordField = document.getElementById("password").value
    this.setState({
      confirm_password: passwordField != "",
    })
  }

  emailTyped() {
    const emailField = document.getElementById("email").value
    this.setState({
      confirm_email: emailField != "",
    })
  }

  submit(event) {
    event.preventDefault()
    const form = document.querySelector("#update-form")
    const data = serialize(form, {
      hash: true,
    })
    let check = true

    if (data.password && data.password != data.password_confirmation) {
      ProcessOut.addNotification.bind(this)("Passwords are differents", "error")
      check = false
    }

    if (data.email && data.email != data.email_confirmation) {
      ProcessOut.addNotification.bind(this)("Emails are differents", "error")
      check = false
    }

    if (data.email == "") data.email = this.props.user.details.email
    if (data.name == "") data.name = this.props.user.details.name

    if (data.password == null) {
      data.password = data.old_password
      data.password_confirmation = data.old_password
    }

    if (check) {
      const json = JSON.stringify(data)
      UserActions.updateUser(json)
    }
  }

  twoFactorSubmit(event) {
    event.preventDefault()
    const data = serialize(event.target, {
      hash: true,
    })

    if (this.props.twoFactor.data.enabled) {
      this.props.dispatch(UserActions.disableTwoFactor(data.two_factor_token))
    } else {
      this.props.dispatch(UserActions.enableTwoFactor(data.two_factor_token))
    }
  }

  render() {
    const { user } = this.props
    let password

    if (this.state.confirm_password) {
      password = (
        <div className="row">
          <div className="large-12 columns">
            <input
              name="password_confirmation"
              type="password"
              placeholder="Confirm password"
              className="bottom-border"
              required
            />
          </div>
        </div>
      )
    }

    const email = (
      <div className="row">
        <div className="large-12 columns">
          <input
            type="text"
            name="email_confirmation"
            placeholder="Confirm new email"
            className="bottom-border"
          />
        </div>
      </div>
    )
    let twoFactor = <Loader />

    if (this.props.twoFactor.fetched) {
      if (this.props.twoFactor.data.enabled) {
        twoFactor = (
          <form onSubmit={this.twoFactorSubmit.bind(this)} id="two-factor-form">
            <div className="row">
              <div className="large-12 columns">
                <p>
                  Two factor authentication is currently enabled on your account. We strongly advise
                  you not to disable it.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-9 columns">
                <input type="password" placeholder="Two factor code" name="two_factor_token" />
              </div>
              <div className="large-3 columns">
                <input
                  type="submit"
                  value="Disable"
                  className="button small round border red right expand"
                />
              </div>
            </div>
          </form>
        )
      } else {
        twoFactor = (
          <form onSubmit={this.twoFactorSubmit.bind(this)} id="two-factor-form">
            <div className="row">
              <div className="large-4 medium-8 medium-centered large-uncentered columns margin-bottom">
                <img
                  alt="Two-step verification QR code"
                  style={{
                    marginTop: "5px",
                  }}
                  src={this.props.twoFactor.data.qr_code ? this.props.twoFactor.data.qr_code : ""}
                />
              </div>
              <div className="large-8 columns">
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Two-step verification prevents access to your account except for yourself by
                  adding a second layer to the authentication. All you need to do is input the code
                  provided in your favorite authentication application (ex: Google Authenticator) on
                  your smartphone while logging in! Enabling this feature is strongly recommended.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <p>
                  Please scan the QR code in your application on your smartphone and input the given
                  code.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="medium-9 columns">
                <input type="number" placeholder="Two factor code" name="two_factor_token" />
              </div>
              <div className="medium-3 columns">
                <input
                  type="submit"
                  value="Enable"
                  className="button small round border right expand"
                />
              </div>
            </div>
          </form>
        )
      }
    }

    return (
      <div className="large-7 medium-8 small-centered columns">
        <form id="update-form" onSubmit={this.submit.bind(this)}>
          <div className="row">
            <div className="large-3 medium-4 columns">
              <h5>Update information</h5>
            </div>
            <div className="large-9 medium-8 columns">
              <div className="row">
                <div className="large-12 columns">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={this.emailTyped.bind(this)}
                    placeholder="New email (optional)"
                    className="bottom-border"
                  />
                </div>
              </div>
              {email}
            </div>
            <div className="large-9 medium-8 columns">
              <div className="row">
                <div className="large-12 columns">
                  <input
                    name="password"
                    id="password"
                    onChange={this.passwordTyped.bind(this)}
                    type="password"
                    placeholder="New password (optional)"
                    className="bottom-border"
                  />
                </div>
              </div>
              {password}
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="large-3 medium-4 columns">
              <h5>Confirmation</h5>
            </div>
            <div className="large-9 medium-8 columns">
              <div className="row">
                <div className="large-12 columns">
                  <input
                    name="old_password"
                    type="password"
                    placeholder="Current password"
                    className="bottom-border"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns clearfix">
                  <input type="submit" className="button small round border" value="Update" />
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />

        <div className="row">
          <div className="large-3 medium-4 columns">
            <h5>Two factor authentication</h5>
          </div>
          <div className="large-9 medium-8 columns">{twoFactor}</div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  twoFactor: store.user.two_factor,
}))(UserUpdate)
