class Project {
  _id: string
  _userRole: string
  _defaultCurrency: string
  _name: string

  constructor(
    id: string,
    userRole?: string | null | undefined,
    defaultCurrency?: string | null | undefined,
    name?: string | null | undefined,
  ) {
    this._id = id
    if (userRole) this._userRole = userRole
    if (defaultCurrency) this._defaultCurrency = defaultCurrency
    if (name) this._name = name
  }

  isSandbox(): boolean {
    return new RegExp(/^test-.*/).test(this._id)
  }
}

export default Project
