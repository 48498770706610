import React from "react"
import { connect } from "react-redux"
import ReactCSSTransitionGroup from "react-addons-css-transition-group" // ES6

import { REQUEST_CLEAR_NOTIFICATION } from "./consts"
import "./bar.scss"

class NotificationCenter extends React.Component {
  props: {
    notification: any
    source: string
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    let notif = <div />
    const hidden =
      this.props.notification.displayed || (this.props.source !== "modal" && this.props.modal.open)

    if (!hidden) {
      notif = (
        <div
          key={!this.props.notification.id}
          className={`notification-bar ${this.props.notification.type} large-12 columns${
            hidden ? " hide" : ""
          }${this.props.source === "modal" ? " margin-bottom" : ""}`}
        >
          <div className="medium-11 columns">{this.props.notification.message}</div>
          <div className="medium-1 column text-right">
            <a
              onClick={() => {
                this.props.dispatch({
                  type: REQUEST_CLEAR_NOTIFICATION,
                  payload: {
                    id: this.props.notification.id,
                  },
                })
              }}
            >
              <img
                alt="Close icon"
                src="//dashboard.processout.com/images/close.png"
                style={{
                  height: "1em",
                }}
              />
            </a>
          </div>
        </div>
      )
    }

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionName="notification-bar-animation"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {notif}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

export default connect(store => ({
  notification: store.notification,
  modal: store.modal,
}))(NotificationCenter)
