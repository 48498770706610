import debounce from "lodash.debounce"
import qs from "qs"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { push } from "react-router-redux"
import { segmentAnalytics } from "^/vNext/Segment"
import type { $Dispatcher, $State } from "../../util/Types"
import * as Actions from "../SearchBar/actions"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "../SearchBar/consts"
import type { $SearchBarState } from "../SearchBar/reducer"
import type { $SearchBuilderOption } from "../SearchBuilder/consts"
import { SEARCH_BUILDER_TOKENS } from "../SearchBuilder/consts"
import DocumentationItem from "./DocumentationItem"
import * as Helpers from "./helpers"
import PolangItem from "./PolangItem"
import "./searchbar.scss"
import SearchByResources from "./SearchByResources"
import SearchPolangValue from "./SearchPolangValue"
import SearchPolangWord from "./SearchPolangWord"
import { $LaunchDarklyState } from "../../vNext/LaunchDarkly/LaunchDarklyReducer"
import * as ProcessOut from "../../util/ProcessOut"

type Props = $Dispatcher & {
  location: {
    query: Record<string, string>
  }
  details: $SearchBarState
  launchDarkly: $LaunchDarklyState
  params: {
    project: string
  } & $State
}
type State = {
  polangWords: Array<$SearchBuilderOption>
  inputFocused: boolean
  showSuggestions: boolean
  filter: string
  lastPolangWordSelected: string
  cursor: number
  resourcesSearched: any
  showResources: boolean
}
export class SearchBar extends React.Component<Props, State> {
  clickOutside: {
    current: null | HTMLDivElement
  }

  constructor() {
    super()
    this.state = {
      polangWords: [],
      inputFocused: false,
      showSuggestions: false,
      filter: "",
      lastPolangWordSelected: "",
      cursor: 0,
      resourcesSearched: [],
      showResources: false,
    }
    this.clickOutside = React.createRef()
  }

  componentDidMount() {
    const {
      location: {
        query: { filter },
      },
      dispatch,
    } = this.props
    document.addEventListener("mousedown", this.handleClickOutside)
    if (filter)
      this.setState({
        filter: filter || "",
      })
    const transaction = "transactions"
    const polangWords = SEARCH_BUILDER_TOKENS[transaction]
    this.setState({
      polangWords: polangWords.filter(key => key.type !== "date"),
    })
    dispatch(Actions.wipeSearchResources())
  }

  componentDidUpdate(prevProps: Props) {
    const { cursor } = this.state
    const {
      location: {
        query: { filter },
      },
    } = this.props
    const newFilter = filter
    const currentFilter = prevProps.location.query.filter
    if (newFilter !== currentFilter)
      this.setState({
        filter: newFilter || "",
      })
    const polangWordElement = document.getElementById(`polang-word-${cursor}`)
    const polangValueElement = document.getElementById(`polang-value-${cursor}`)
    const resourcesElement = document.getElementById(`resources-${cursor}`)
    const searchBarSelectElement = document.getElementById("search-bar-select")

    if (searchBarSelectElement) {
      if (polangWordElement) {
        const topPolangWordPos = polangWordElement.offsetTop
        searchBarSelectElement.scrollTop = topPolangWordPos
      } else if (polangValueElement) {
        const topPolangValuePos = polangValueElement.offsetTop
        searchBarSelectElement.scrollTop = topPolangValuePos
      } else if (resourcesElement) {
        const topResourceValuePos = resourcesElement.offsetTop
        searchBarSelectElement.scrollTop = topResourceValuePos
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    document.removeEventListener("mousedown", this.handleClickOutside)
    dispatch(Actions.wipeSearchResources())
  }

  handleClickOutside = (e: Event) => {
    const searchBuilder = document.getElementsByClassName(`search-builder-content`)

    if (
      !this.clickOutside.current.contains(e.target) &&
      this.clickOutside &&
      !searchBuilder.length
    ) {
      this.setState({
        inputFocused: false,
        showSuggestions: false,
        lastPolangWordSelected: "",
        cursor: 0,
        showResources: false,
      })
    }
  }
  onFocus = () => {
    const { filter, resourcesSearched } = this.state

    if (filter) {
      this.setState({
        showSuggestions: true,
      })
    }

    if (resourcesSearched) {
      this.setState({
        showResources: true,
      })
    }

    this.setState({
      inputFocused: true,
    })
  }

  triggerDebounce = debounce(text => this.props.dispatch(Actions.startResourcesSearch(text)), 500)

  onChange = e => {
    e.preventDefault()
    const { dispatch } = this.props
    const { value } = e.target
    const newValue = value

    if (value) {
      this.triggerDebounce(value)
      this.setState({
        showResources: true,
        showSuggestions: true,
      })
    }

    if (!newValue) {
      dispatch(Actions.wipeSearchResources())
      this.setState({
        showSuggestions: false,
      })
    }

    if (value && this.state.lastPolangWordSelected) {
      this.setState({
        lastPolangWordSelected: "",
      })
    }

    this.setState({
      showSuggestions: true,
      filter: newValue,
    })
  }
  updatePolangWords = (value: string) => {
    const { polangWords } = this.state
    const suggestions = []
    polangWords.forEach(res => res.value.includes(value) && suggestions.push(res))
    return suggestions
  }
  selectPolandWord = (item: { value: string }) => {
    const { dispatch } = this.props
    const newValue = item.value.concat(" ", "== ")
    this.setState({
      filter: newValue,
      lastPolangWordSelected: item.value,
    })
    dispatch({
      type: REQUEST_FETCH_VALUES_FOR_FIELD,
      payload: {
        document: "transactions",
        field: item.value,
      },
    })
    this.focusInput.focus()
  }
  onSubmit = (value: string) => {
    const { dispatch, params, launchDarkly } = this.props
    const nextQuery = {
      filter: value,
    }
    const builtQuery = qs.stringify(nextQuery)

    if (location.pathname.includes(`${params.project}/boards`)) {
      dispatch(push(`${location.pathname}?${builtQuery}`))
    } else {
      dispatch(push(`/projects/${params.project}/transactions?${builtQuery}`))
    }

    this.setState({
      showSuggestions: false,
    })
  }
  onKeyDown = e => {
    const {
      filter,
      cursor,
      polangWords,
      showSuggestions,
      lastPolangWordSelected,
      resourcesSearched,
    } = this.state
    const { details } = this.props
    const pressTab = e.keyCode === 9
    const pressEnter = e.keyCode === 13
    const pressKeyDown = e.keyCode === 40
    const pressKeyUp = e.keyCode === 38
    const filterIsPolang = Helpers.filterIsPolang(filter)

    if (pressTab && !e.target.value) {
      this.setState({
        showSuggestions: true,
      })
      e.preventDefault()
    }

    if (pressEnter && e.target.value && !lastPolangWordSelected && filterIsPolang) {
      this.onSubmit(filter)
      e.preventDefault()
    }

    if (pressEnter) {
      if (
        lastPolangWordSelected &&
        filter.includes(lastPolangWordSelected) &&
        details[lastPolangWordSelected]
      ) {
        const suggestions = details[lastPolangWordSelected][cursor]

        if (suggestions) {
          this.selectPolangValue(suggestions)
        } else {
          this.onSubmit(filter)
        }

        this.setState({
          cursor: 0,
        })
      } else if (resourcesSearched.length > 0) {
        this.selectResource(resourcesSearched[cursor])
        this.setState({
          cursor: 0,
        })
      } else if (!filterIsPolang) {
        const suggestions = this.updatePolangWords(filter)
        this.selectPolandWord(suggestions[cursor])
        this.setState({
          cursor: 0,
        })
      }
    }

    if (pressKeyDown && showSuggestions) {
      if (
        lastPolangWordSelected &&
        filter.includes(lastPolangWordSelected) &&
        details[lastPolangWordSelected]
      ) {
        const suggestions = details[lastPolangWordSelected].filter(item => !!item.value)
        this.setState({
          cursor: Math.min(cursor + 1, suggestions.length - 1),
        })
      } else if (resourcesSearched.length > 0) {
        this.setState({
          cursor: Math.min(cursor + 1, resourcesSearched.length - 1),
        })
      } else {
        this.setState({
          cursor: Math.min(cursor + 1, polangWords.length - 1),
        })
      }

      e.preventDefault()
    }

    if (pressKeyUp && showSuggestions) {
      this.setState({
        cursor: Math.max(cursor - 1, 0),
      })
      e.preventDefault()
    }
  }
  selectPolangValue = (item: { value: string }) => {
    const { filter } = this.state
    const newFilter = `${filter}"${item.value}"`
    const filterIsPolang = Helpers.filterIsPolang(filter)
    this.setState({
      filter: newFilter,
      lastPolangWordSelected: "",
    })

    if (!filterIsPolang) {
      this.setState({
        filter: `${filter} == "${item.value}"`,
        lastPolangWordSelected: "",
      })
    }

    this.focusInput.focus()
  }
  selectResource = (item: any) => {
    const { dispatch, params, launchDarkly } = this.props
    const resourceType = Helpers.getResourceTypeFromId(item.id)
    segmentAnalytics?.track("USED_SEARCH_BAR", {
      resource: resourceType,
    })

    switch (resourceType) {
      case "transaction":
        if (launchDarkly.flags["new-dash-transactions-page"]) {
          window.location.href = `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/transactions/${item.id}`
        } else {
          dispatch(push(`/projects/${params.project}/transactions/${item.id}`))
          dispatch(Actions.wipeSearchResources())
        }
        break

      case "card":
        dispatch(
          push(
            `/projects/${params.project}/vault/?filter=${encodeURIComponent(`id == "${item.id}"`)}`,
          ),
        )
        dispatch(Actions.wipeSearchResources())
        break

      case "customer":
        if (launchDarkly.flags["new-dash-customers-page"]) {
          window.location.href = `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/customers/${item.id}`
        } else {
          dispatch(push(`/projects/${params.project}/customers/${item.id}`))
          dispatch(Actions.wipeSearchResources())
        }
        break

      case "project":
        dispatch(push(`/projects?target=${item.id}`))
        dispatch(Actions.wipeSearchResources())
        break

      default:
    }
  }
  getResourcesSearched = (resources: any) => {
    this.setState({
      resourcesSearched: resources,
    })
  }
  clearInput = (e: Event) => {
    e.preventDefault()
    const { dispatch } = this.props
    this.setState({
      filter: "",
      lastPolangWordSelected: "",
      cursor: 0,
    })
    dispatch(Actions.wipeSearchResources())
    this.focusInput.focus()
  }

  render() {
    const { filter, inputFocused, showSuggestions, lastPolangWordSelected, cursor, showResources } =
      this.state
    const { details } = this.props
    return (
      <div className="search-bar-container" ref={this.clickOutside}>
        {filter && !details.resourcesFetching && (
          <button type="button" className="search-clearer" onClick={this.clearInput}>
            &#10005;
          </button>
        )}
        {details.resourcesFetching && (
          <div className="search-icon-container">
            <i title="Search icon" className="search-loader" />
          </div>
        )}
        <input
          ref={input => {
            this.focusInput = input
          }}
          className="search-bar-input"
          onFocus={this.onFocus}
          value={filter}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          placeholder="Search for resource ID, customer email... Or press tab to display fields"
          onSubmit={this.onSubmit}
        />
        <div className="search-bar-select" id="search-bar-select">
          {inputFocused && showSuggestions && filter && details.resources.length < 1 && (
            <PolangItem key={filter} filter={filter} />
          )}
          {inputFocused && !showSuggestions && !filter && <DocumentationItem />}
          {lastPolangWordSelected &&
            filter.includes(lastPolangWordSelected) &&
            details[lastPolangWordSelected] && (
              <SearchPolangValue
                value={details[lastPolangWordSelected]}
                cursor={cursor}
                selectPolangValue={this.selectPolangValue}
              />
            )}
          {inputFocused && showSuggestions && (
            <SearchPolangWord
              suggestions={this.updatePolangWords(filter)}
              selectPolandWord={this.selectPolandWord}
              cursor={cursor}
            />
          )}
          {showResources && (
            <SearchByResources
              selectResource={this.selectResource}
              filter={filter}
              cursor={cursor}
              getResourcesSearched={this.getResourcesSearched}
            />
          )}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  details: store.searchBar,
  projectsDetails: store.projects,
  launchDarkly: store.launchDarkly,
}))(withRouter(SearchBar))
