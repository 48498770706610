import React from "react"
type Options = {
  backgroundColor?: string | null | undefined
  textColor?: string | null | undefined
  customClassName?: string | null | undefined
}
type Props = {
  info: string
  options?: Options | null | undefined
}

class InformationContainer extends React.Component<Props> {
  render() {
    const { info, options } = this.props
    const style = {
      backgroundColor: options ? options.backgroundColor || "lightslategrey" : "lightslategrey",
      color: options ? options.textColor || "white" : "white",
      padding: "1em",
      borderRadius: "4px",
    }
    return (
      <div className="row margin-bottom">
        <div className="medium-12 columns">
          <div style={style} className={options ? options.customClassName || "" : ""}>
            {info}
          </div>
        </div>
      </div>
    )
  }
}

export default InformationContainer
