import moment from "moment"
import React from "react"
import { FormattedDate } from "react-intl"
import { withRouter } from "react-router"
import Check from "../../features/Checks/Check"
import CountryFlag from "../../features/CountryFlag/CountryFlag"
import type { $Check, $ChecksAvs } from "../../Types/Transaction"
import CardSchemeImage from "../../util/CardSchemeImage"
import Tooltip from "../../util/Tooltip"
import AvsCheck from "./AvsCheck"
import { track } from "^/vNext/tracking"
import { connect } from "react-redux"
import { $LaunchDarklyState } from "../../vNext/LaunchDarkly/LaunchDarklyReducer"

export type $Card = {
  id: string
  fingerprint: string
  city: string | null | undefined
  country: string | null | undefined
  exp_year: number
  exp_month: number
  last_4_digits: number
  iin: number | null | undefined
  card_scheme: string | null | undefined
  bank_name: string | null | undefined
  category: string | null | undefined
  type: string | null | undefined
  name: string | null | undefined
  brand: string | null | undefined
  co_scheme: string | null | undefined
  cvc_check: $Check
  avs_check: $Check
  country_code: string
  scheme: string
  checks: $ChecksAvs
  token: {
    verification_status: $VerificationStatus
  }
}
type $VerificationStatus = "failed" | "not-requested" | "pending" | "success" | "unknown"

const getVerificationStatusClassModifier = (status: $VerificationStatus): string => {
  switch (status) {
    case "failed":
    case "success":
    case "pending":
      return status
    default:
      // this is purple and neutral... used for refund and authorized statuses so seems sensible as a default
      return "warning"
  }
}

type Props = {
  card: $Card
  token_id: string | null | undefined
  params: {
    project: string
  }
  launchDarkly: $LaunchDarklyState
}
export class CardDetails extends React.Component<Props> {
  getLine = (title: string, value: any): any => (
    <div className="row small-margin-bottom">
      <div className="large-4 columns">
        <span className="greyed">{title}</span>
      </div>
      <div className="large-8 columns">
        <span className="right">{this.checkVar(value)}</span>
      </div>
    </div>
  )
  missingField = () => <span className="greyed">--</span>
  checkVar = (obj: any): any => (!obj || obj === "" ? this.missingField() : obj)
  formatAdress = (): any => {
    const { card } = this.props
    const country = card.country_code

    if (!card.city || !country || card.city === "" || country === "") {
      if (card.city && card.city !== "") return card.city
      if (country && country !== "") return country
      return this.missingField()
    }

    return `${card.city}, ${country}`
  }

  render() {
    const { card, params, token_id, launchDarkly } = this.props

    if (!card) {
      return null
    }

    const expiry = moment(new Date(card.exp_year, card.exp_month - 1))
    let listAvsChecks
    const { avs_name_check, avs_postal_check, avs_street_check } = card.checks

    if (card.checks) {
      listAvsChecks = (
        <div>
          <AvsCheck title="Name" check={<Check check={avs_name_check || "UNKNOWN"} />} />
          <br />
          <AvsCheck title="Address" check={<Check check={avs_postal_check || "UNKNOWN"} />} />
          <br />
          <AvsCheck title="ZIP" check={<Check check={avs_street_check || "UNKNOWN"} />} />
        </div>
      )
    }

    return (
      <div className="row">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-12 columns">
              <h6 className="capitalize greyed font-console margin-bottom">Card details</h6>
            </div>
          </div>
          {this.getLine(
            "Card fingerprint",
            card.fingerprint ? (
              <a
                href={
                  launchDarkly.flags["new-dash-transactions-page"]
                    ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/transactions?card_fingerprint=${card.fingerprint}`
                    : `/projects/${params.project}/transactions?filter=${encodeURIComponent(
                        `card_fingerprint == "${card.fingerprint}"`,
                      )}`
                }
                className="small bold greyed"
                onClick={() => {
                  track("Transaction Details", "Click", "Card Details", "List Card Transactions")
                }}
              >
                List card transactions ↗
              </a>
            ) : null,
          )}
          {this.getLine(
            "Card ID",
            card.id ? <span className="small font-console">{card.id}</span> : null,
          )}
          {this.getLine(
            "Token ID",
            token_id ? <span className="small font-console">{token_id}</span> : null,
          )}
          {this.getLine("Name on card", card.name)}
          {this.getLine(
            "Last 4 digits",
            <span className="font-console">{card.last_4_digits}</span>,
          )}
          {this.getLine("IIN", <span className="font-console">{card.iin}</span>)}
          {this.getLine("Bank name", card.bank_name)}
          {this.getLine("Type", card.type)}
          {this.getLine("Category", card.category)}
          {this.getLine(
            "Expiration date",
            card.exp_month && card.exp_year ? (
              <span className="font-console">
                <FormattedDate value={expiry.toISOString()} month="2-digit" year="numeric" />
              </span>
            ) : (
              <span className="greyed">Unknown expiration</span>
            ),
          )}
          {this.getLine("Scheme", CardSchemeImage(card))}
          {this.getLine("Co-scheme", card.co_scheme)}
          {this.getLine("Brand", card.brand)}
          {this.getLine(
            "Country",
            card.country_code ? (
              <div
                style={{
                  float: "right",
                }}
              >
                <CountryFlag country_code={card.country_code} />
              </div>
            ) : (
              this.missingField()
            ),
          )}
          {this.getLine("Address", this.formatAdress())}
          <div className="row small-margin-bottom">
            <div className="medium-4 columns greyed text-left">
              <div
                style={{
                  display: "inline-block",
                }}
              >
                AVS check
              </div>
              <Tooltip
                content="Best result of an address check performed on this card."
                id="avsCardCheck"
                size="large"
                place="right"
              >
                <div
                  className="info"
                  data-tip
                  data-for="avsCardCheck"
                  style={{
                    display: "inline-block",
                  }}
                >
                  i
                </div>
              </Tooltip>
            </div>
            <div className="medium-8 columns text-right">
              <Tooltip content={listAvsChecks} id="cardDetailsAvs" size="medium">
                <div
                  className="info"
                  data-tip
                  data-for="cardDetailsAvs"
                  style={{
                    display: "inline-block",
                    marginRight: "0.75em",
                  }}
                >
                  i
                </div>
              </Tooltip>
              <Check check={card.avs_check} />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-4 columns greyed text-left">
              <div
                style={{
                  display: "inline-block",
                }}
              >
                CVC check
              </div>
              <Tooltip
                content="Best result of a CVC check performed on this card."
                id="cvcCardCheck"
                size="large"
                place="right"
              >
                <div
                  className="info"
                  data-tip
                  data-for="cvcCardCheck"
                  style={{
                    display: "inline-block",
                  }}
                >
                  i
                </div>
              </Tooltip>
            </div>
            <div className="medium-8 columns text-right">
              <Check check={card.cvc_check} />
            </div>
          </div>
          <div className="row small-margin-bottom">
            <div className="medium-4 columns greyed text-left">
              <div
                style={{
                  display: "inline-block",
                }}
              >
                Verification status
              </div>
            </div>
            <div className="medium-8 columns text-right">
              {card.token?.verification_status ? (
                <span
                  className={`log-level ${getVerificationStatusClassModifier(
                    card.token.verification_status,
                  )}`}
                >
                  {card.token.verification_status}
                </span>
              ) : (
                this.missingField()
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  launchDarkly: store.launchDarkly,
}))(withRouter(CardDetails))
