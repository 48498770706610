import levenshtein from "fast-levenshtein"
export default function getFraudServicesList(fraudServices, filter) {
  const results = []

  for (let i = 0; i < fraudServices.length; i++) {
    const fraudServicesName = fraudServices[i].name

    if (
      fraudServicesName.includes(filter) ||
      fraudServices[i].display_name?.includes(filter) ||
      fraudServices[i].fraud_service_name?.includes(filter) ||
      levenshtein.get(fraudServicesName, filter) <= 2
    ) {
      results.push(fraudServices[i])
    }
  }

  return results
}
