import React from "react"
type Props = {
  word: string
  description?: string
}

class PolangWordItem extends React.Component<Props> {
  render() {
    const { word, description } = this.props
    return (
      <div className="row collapse item-resource-row highlight">
        <div className="medium-12 columns">
          <div className="filter inline-block">{word}:</div>
          <div
            className="inline-block greyed"
            style={{
              marginLeft: ".5em",
            }}
          >
            {description}
          </div>
        </div>
      </div>
    )
  }
}

export default PolangWordItem
