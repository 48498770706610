import type { $DataExplorerPreset, $DataExplorerTab, $PresetCategory } from "./consts"
import { DEFAULT_FORMULA, UPDATE_CHART_BUILDER_DETAILS } from "./consts"
import type { $Action } from "../../../util/Types"
import {
  APPLY_PRESET_DATA_EXPLORER,
  PREVIEW_CHART,
  REQUEST_CHART_FETCH,
  RESET_DATA_EXPLORER,
  SELECT_DATA_EXPLORER_PRESET,
  SELECT_TAB,
  SET_DATA_EXPLORER_BOARD_ID,
  SET_DATA_EXPLORER_CHART_EDITION,
  SET_EDITOR_HAS_BEEN_EDITED,
  SET_METRIC_TAB,
  SET_SELECTED_PRESET_CATEGORY,
  UPDATE_DATA_EXPLORER_FORMULA,
  UPDATE_DATA_EXPLORER_TYPE,
  UPDATE_DATA_EXPLORER_UNIT,
  UPDATE_PRESETS_SEARCH,
} from "./actions"
import type { $Chart, $ChartType } from "../Boards/consts"
import { SELECT_BUILDER_METRIC, SELECT_TYPE } from "./ChartBuilder/actions"
import { BUILDER_METRICS } from "./ChartBuilder/consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $DataExplorerState = {
  selectedTab: $DataExplorerTab
  selectedPresetCategory: $PresetCategory
  selectedPreset: $DataExplorerPreset | null | undefined
  presetsSearch: string
  formula: string
  type: $ChartType
  unit: string
  chart: {
    fetching: boolean
    fetched: boolean
    error: boolean
    chart: $Chart | null | undefined
  }
  name: string
  previewedFormula: string
  boardId: string | null | undefined
  editingChartId: string | null | undefined
  editorHasBeenEdited: boolean
  selectedMetricTab: string
}
const defaultState: $DataExplorerState = {
  selectedTab: "presets",
  selectedPresetCategory: "business-intel",
  selectedPreset: null,
  presetsSearch: "",
  selectedMetricTab: "custom",
  formula: DEFAULT_FORMULA,
  type: "line-chart",
  unit: BUILDER_METRICS[0].unit,
  chart: {
    fetching: false,
    fetched: false,
    error: false,
    chart: null,
  },
  name: "",
  previewedFormula: "",
  boardId: null,
  editingChartId: null,
  editorHasBeenEdited: false,
}
export default function (
  state: $DataExplorerState = defaultState,
  action: $Action,
): $DataExplorerState {
  switch (action.type) {
    case RESET_DATA_EXPLORER: {
      return { ...state, ...defaultState, selectedTab: state.selectedTab }
    }

    case SET_SELECTED_PRESET_CATEGORY: {
      if (!action.payload) return state
      return { ...state, selectedPresetCategory: action.payload.category }
    }

    case UPDATE_PRESETS_SEARCH: {
      if (!action.payload) return state
      return { ...state, presetsSearch: action.payload.search }
    }

    case SELECT_TAB: {
      if (!action.payload) return state
      return { ...state, selectedTab: action.payload.tab }
    }

    case typePending(PREVIEW_CHART): {
      return { ...state, chart: { ...state.chart, fetching: true } }
    }

    case typeFailed(PREVIEW_CHART): {
      return {
        ...state,
        chart: {
          fetching: false,
          fetched: true,
          error: true,
          chart: null,
        },
      }
    }

    case typeFulfilled(PREVIEW_CHART): {
      if (!action.payload) return state
      return {
        ...state,
        chart: {
          fetched: true,
          error: false,
          fetching: false,
          chart: action.payload.chart,
        },
      }
    }

    case UPDATE_DATA_EXPLORER_FORMULA: {
      if (!action.payload) return state
      return { ...state, formula: action.payload.formula }
    }

    case SELECT_DATA_EXPLORER_PRESET: {
      if (!action.payload) return state
      return { ...state, selectedPreset: action.payload.preset }
    }

    case UPDATE_DATA_EXPLORER_TYPE: {
      if (!action.payload) return state
      return { ...state, type: action.payload.type }
    }

    case UPDATE_DATA_EXPLORER_UNIT: {
      if (!action.payload) return state
      return { ...state, unit: action.payload.unit }
    }

    case SET_METRIC_TAB: {
      if (!action.payload) return state
      return { ...state, selectedMetricTab: action.payload.tab }
    }

    case UPDATE_CHART_BUILDER_DETAILS: {
      const { payload } = action
      if (!payload) return state
      return {
        ...state,
        name: payload.name || payload.name === "" ? payload.name : state.name,
        type: payload.type || state.type,
        unit: payload.unit || state.unit,
      }
    }

    case APPLY_PRESET_DATA_EXPLORER: {
      const { payload } = action
      if (!payload) return state
      return {
        ...state,
        type: payload.chartBuilder.type || state.type,
        unit: payload.chartBuilder.selectedMetric.unit || state.unit,
        name: payload.name || state.name,
        chart: defaultState.chart,
      }
    }

    case REQUEST_CHART_FETCH: {
      const { payload } = action
      if (!payload) return state
      return { ...state, previewedFormula: payload.formula }
    }

    case SET_DATA_EXPLORER_BOARD_ID: {
      const { payload } = action
      if (!payload) return state
      return { ...state, boardId: payload.id }
    }

    case SET_DATA_EXPLORER_CHART_EDITION: {
      const { payload } = action
      if (!payload) return state
      return { ...state, editingChartId: payload.chartId }
    }

    case SET_EDITOR_HAS_BEEN_EDITED: {
      const { payload } = action
      if (!payload) return state
      return { ...state, editorHasBeenEdited: payload.editorHasBeenEdited }
    }

    case SELECT_TYPE: {
      return { ...state, type: action.payload.type }
    }

    case SELECT_BUILDER_METRIC: {
      return { ...state, unit: action.payload.metric.unit }
    }
  }

  return state
}
