import React from "react"
import type { $Project } from "../../util/Types"
import ProjectRegionFlag from "../../components/layout/ProjectRegionFlag"
type Props = {
  project: $Project
  isFirst: boolean // is first of its category
}

class ProjectItem extends React.Component<Props> {
  render() {
    const { project, isFirst } = this.props
    return (
      <div className="row collapse">
        <div className="medium-12 columns">
          {isFirst && (
            <div className="row collapse item-search-category">
              <div className="medium-12 columns">Projects</div>
            </div>
          )}
          <div className="row collapse item-resource-row highlight">
            <div className="medium-12 columns">
              <div className="row collapse">
                <div className="medium-1 columns">
                  <ProjectRegionFlag project={project} />
                </div>
                <div className="medium-11 columns">{project.name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectItem
