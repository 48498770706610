import * as React from "react"
import ChartHeader from "../components/ChartHeader"
import type { $ChartDatasFromApi } from "../consts"
import LineChart from "../visualisations/LineChart"
import { getDatasPerDay, getLegend } from "../visualisations/util"

type Props = {
  datas: { data: $ChartDatasFromApi[]; chartTitle: string }[]
}
export default function AuthLineCharts({ datas }: Props) {
  const formattedData = datas.map(data => getDatasPerDay(data.data))
  const authAndDeclinedTooltip = "Chart comparing the authorization rate VS the decline rate"
  return (
    <>
      <ChartHeader
        legend={getLegend(datas)}
        tooltip={{ content: authAndDeclinedTooltip, id: "authAndDeclined" }}
        chartTitle="Authorized and declined transactions"
      />
      <LineChart
        datas={formattedData}
        valueStyle="percent"
        name="Authorized and declined transactions"
      />
    </>
  )
}
