import React from "react"
import { Link } from "react-router"
export default class NotFoundPage extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="row margin-bottom">
          <div className="large-9 columns large-centered text-center">
            <div className="error-image" />
          </div>
        </div>
        <div className="row">
          <div className="large-7 large-centered columns text-center">
            <h6 className="capitalize bold">
              The resource you are looking for could not be found.
            </h6>
            <Link to="/projects">&larr;Go back to your projects</Link>
          </div>
        </div>
      </div>
    )
  }
}
