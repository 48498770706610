import * as React from "react"
import { Disclosure } from "@headlessui/react"
import clsx from "clsx"
import { ReactComponent as CaretIcon } from "./caret-icon.svg"
import "./CategoryCollapse.scss"
type Props = {
  title: string
  children: React.ReactNode
}
export function CategoryCollapse({ title, children }: Props) {
  return (
    <div className="CategoryCollapse">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="CategoryCollapse__button">
              {title}
              <CaretIcon
                className={clsx("CategoryCollapse__icon", open && "CategoryCollapse__icon--open")}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="CategoryCollapse__panel">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
