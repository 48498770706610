import type { $Action } from "../../util/Types"
import type { $Collaborator, $PermissionFeature, $PermissionGroup } from "./consts"
import { PREPARE_PERMISSIONS } from "./consts"

export type $PreparePermissionsFullfilledAction = {
  type: "PREPARE_PERMISSIONS_FULFILLED"
  payload: {
    collaborators: Array<$Collaborator>
    permissionGroups: Array<$PermissionGroup>
  }
}
export type PreparePermissionsAction = {
  type: typeof PREPARE_PERMISSIONS
  payload: {
    collaborators: Array<$Collaborator>
    permissionGroups: Array<$PermissionGroup>
  }
}
export function preparePermissions(
  collaboratorsAfter?: boolean,
  collaboratorsId?: string,
): $Action {
  return {
    type: PREPARE_PERMISSIONS,
    payload: {
      collaboratorsAfter,
      collaboratorsId,
    },
  }
}
export const REQUEST_FETCH_PERMISSION_GROUPS = "REQUEST_FETCH_PERMISSION_GROUPS"
export type $RequestFetchPermissionGroupsAction = {
  type: "REQUEST_FETCH_PERMISSION_GROUPS"
  payload: {}
}
export function requestFetchPermissionGroups(): $Action {
  return {
    type: REQUEST_FETCH_PERMISSION_GROUPS,
    payload: {},
  }
}
export const SET_COLLABORATOR_PERMISSION_GROUP = "SET_COLLABORATOR_PERMISSION_GROUP"
export type $SetCollaboratorPermissionGroupAction = {
  type: "SET_COLLABORATOR_PERMISSION_GROUP"
  payload: {
    collaborator: $Collaborator
    group: string
  }
}
export const setCollaboratorPermissionGroup = (
  collaborator: $Collaborator,
  group: string,
): $SetCollaboratorPermissionGroupAction => ({
  type: SET_COLLABORATOR_PERMISSION_GROUP,
  payload: {
    collaborator,
    group,
  },
})
export const SET_COLLABORATOR_ROLE = "SET_COLLABORATOR_ROLE"
export type $SetCollaboratorRoleAction = {
  type: "SET_COLLABORATOR_ROLE"
  payload: {
    collaborator: $Collaborator
    role: "owner" | "permission-group"
  }
}
export const setCollaboratorRole = (
  collaborator: $Collaborator,
  role: "owner" | "permission-group",
): $SetCollaboratorRoleAction => ({
  type: SET_COLLABORATOR_ROLE,
  payload: {
    collaborator,
    role,
  },
})
export const SET_PERMISSION_GROUP_VALUES = "SET_PERMISSION_GROUP_VALUES"
export type $SetPermissionGroupValuesAction = {
  type: "SET_PERMISSION_GROUP_VALUES"
  payload: {
    groupName: string
    feature: $PermissionFeature
    action: string
    category: "allow" | "deny"
  }
}
export const setPermissionGroupValues = (
  groupName: string,
  feature: $PermissionFeature,
  action: string,
  category: "allow" | "deny",
): $SetPermissionGroupValuesAction => ({
  type: SET_PERMISSION_GROUP_VALUES,
  payload: {
    groupName,
    feature,
    action,
    category,
  },
})
export const SAVE_TEAM_SETTINGS = "SAVE_TEAM_SETTINGS"
export type SaveTeamSettingsAction = {
  type: typeof SAVE_TEAM_SETTINGS
}
export const saveTeamSettings = (): $Action => ({
  type: SAVE_TEAM_SETTINGS,
})
export const ADD_PERMISSION_GROUP = "ADD_PERMISSION_GROUP"
export type AddPermissionGroupAction = {
  type: typeof ADD_PERMISSION_GROUP
}
export const addPermissionGroup = (): $Action => ({
  type: ADD_PERMISSION_GROUP,
})
export const REMOVE_PERMISSION_FROM_GROUP = "REMOVE_PERMISSION_FROM_GROUP"
export type RemovePermissionFromGroupAction = {
  type: typeof REMOVE_PERMISSION_FROM_GROUP
  payload: {
    groupName: string
    feature: $PermissionFeature
    category: "allow" | "deny"
  }
}
export const removePermissionFromGroup = (
  groupName: string,
  feature: $PermissionFeature,
  category: "allow" | "deny",
): $Action => ({
  type: REMOVE_PERMISSION_FROM_GROUP,
  payload: {
    groupName,
    feature,
    category,
  },
})
export const UPDATE_GROUP_NAME = "UPDATE_GROUP_NAME"
export type $UpdateGroupNameAction = {
  type: "UPDATE_GROUP_NAME"
  payload: {
    id: string
    name: string
    newName: string
  }
}
export const updateGroupName = (
  id: string,
  name: string,
  newName: string,
): $UpdateGroupNameAction => ({
  type: UPDATE_GROUP_NAME,
  payload: {
    id,
    // name is to send the old name in the PUT request and NOT the new one
    name,
    // TODO: permission API does handle / at the moment.
    // For now replace / with .
    // Related to this ticket: https://checkout.atlassian.net/jira/software/c/projects/POS/boards/902?modal=detail&selectedIssue=POS-190
    newName: newName.replace(new RegExp("/", "g"), "."),
  },
})
export const DELETE_PERMISSION_GROUP = "DELETE_PERMISSION_GROUP"
export type $DeletePermissionGroupAction = {
  type: "DELETE_PERMISSION_GROUP"
  payload: {
    name: string
  }
}
export const deletePermissionGroup = (name: string): $DeletePermissionGroupAction => ({
  type: DELETE_PERMISSION_GROUP,
  payload: {
    name,
  },
})
export const SAVE_PERMISSION_GROUPS = "SAVE_PERMISSION_GROUPS"
export type SavePermissionGroupsAction = {
  type: typeof SAVE_PERMISSION_GROUPS
}
export const savePermissionGroups = (): $Action => ({
  type: SAVE_PERMISSION_GROUPS,
})
export const ADD_COLLABORATOR = "ADD_COLLABORATOR"
export type AddCollaboratorAction = {
  type: typeof ADD_COLLABORATOR
}
export const addCollaborator = (): $Action => ({
  type: ADD_COLLABORATOR,
})
export const UPDATE_COLLABORATOR_EMAIL = "UPDATE_COLLABORATOR_EMAIL"
export type $UpdateCollaboratorEmailAction = {
  type: "UPDATE_COLLABORATOR_EMAIL"
  payload: {
    id: string
    email: string
  }
}
export const updateCollaboratorEmail = (
  id: string,
  email: string,
): $UpdateCollaboratorEmailAction => ({
  type: UPDATE_COLLABORATOR_EMAIL,
  payload: {
    id,
    email,
  },
})
export const DELETE_MODERATOR = "DELETE_MODERATOR"
export type DeleteModeratorAction = {
  type: typeof DELETE_MODERATOR
  payload: {
    id: string
  }
}
export const deleteModerator = (id: string) => ({
  type: DELETE_MODERATOR,
  payload: {
    id,
  },
})
