export type $ApplepaySettings = {
  alternative_certificates_count: number
  enabled: boolean
  has_certificates: boolean
  merchant_csr: string
  merchant_id: string
  processing_csr: string
  merchant_domain_names: Array<any>
}
export type $ApplepayCertificate = {
  ApplepayCertificate?: $ApplepayAlternativeCertificate
  fetched: boolean
  fetching: boolean
}
type $ApplepayAlternativeCertificate = {
  alternative_merchant_certificates: Array<{
    id: string
  }>
  count: number
}
export const ADD_ALTERNATIVE_CERTIFICATE = "ADD_ALTERNATIVE_CERTIFICATE"
export const FETCH_ALTERNATIVE_CERTIFICATE = "FETCH_ALTERNATIVE_CERTIFICATE"
export const DELETE_ALTERNATIVE_CERTIFICATE = "DELETE_ALTERNATIVE_CERTIFICATE"
