import uniqid from "uniqid"
import type { $Collaborator } from "./consts"
import type { $State } from "../../util/Types"
import type {
  $SetCollaboratorPermissionGroupAction,
  $SetCollaboratorRoleAction,
  $UpdateCollaboratorEmailAction,
  AddCollaboratorAction,
  DeleteModeratorAction,
  SaveTeamSettingsAction,
} from "./actions"
import {
  ADD_COLLABORATOR,
  DELETE_MODERATOR,
  SAVE_TEAM_SETTINGS,
  SET_COLLABORATOR_PERMISSION_GROUP,
  SET_COLLABORATOR_ROLE,
  UPDATE_COLLABORATOR_EMAIL,
} from "./actions"
import {
  $RequestFetchCollaboratorsAction,
  REQUEST_FETCH_COLLABORATORS,
} from "./CollaboratorsActions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $CollaboratorsState = {
  collaborators: Array<$Collaborator>
  fetchedState: {
    collaborators: Array<$Collaborator> | null | undefined
  }
  saving: boolean
  has_more?: boolean
} & $State
const defaultState: $CollaboratorsState = {
  fetched: false,
  fetching: false,
  collaborators: [],
  fetchedState: {
    collaborators: null,
  },
  error: null,
  saving: false,
}
export default function (
  state: $CollaboratorsState = defaultState,
  action:
    | $SetCollaboratorPermissionGroupAction
    | $SetCollaboratorRoleAction
    | $UpdateCollaboratorEmailAction
    | SaveTeamSettingsAction
    | AddCollaboratorAction
    | DeleteModeratorAction
    | $RequestFetchCollaboratorsAction,
): $CollaboratorsState {
  switch (action.type) {
    case REQUEST_FETCH_COLLABORATORS: {
      return { ...state, ...defaultState, fetching: true }
    }

    case typeFulfilled(REQUEST_FETCH_COLLABORATORS): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ...(action as any).payload,
        fetchedState: {
          // FIXME: collaborators does not exist in the action type
          // @ts-ignore
          collaborators: action.payload.collaborators.slice(0),
        },
      }
    }

    case SAVE_TEAM_SETTINGS: {
      return { ...state, saving: true }
    }

    case typeFulfilled(SAVE_TEAM_SETTINGS): {
      return { ...state, saving: false }
    }

    case typeFailed(SAVE_TEAM_SETTINGS): {
      return {
        ...state,
        saving: false,
        collaborators: state.fetchedState.collaborators
          ? state.fetchedState.collaborators.slice(0)
          : state.collaborators,
      }
    }

    case SET_COLLABORATOR_PERMISSION_GROUP: {
      const collabIndex = state.collaborators.findIndex(
        c => c.id === action.payload.collaborator.id,
      )
      if (collabIndex < 0) return state
      const newArray = state.collaborators.slice(0)
      newArray[collabIndex] = {
        ...newArray[collabIndex],
        permission_group_name: action.payload.group,
        role: "permission-group",
      }
      return { ...state, collaborators: newArray }
    }

    case SET_COLLABORATOR_ROLE: {
      const collabIndex = state.collaborators.findIndex(
        c => c.user.email === action.payload.collaborator.user.email,
      )
      if (collabIndex < 0) return state
      const newArray = state.collaborators.slice()
      newArray[collabIndex] = { ...newArray[collabIndex] } // Deep copy the collaborator

      newArray[collabIndex].permission_group_name = null
      newArray[collabIndex].role = action.payload.role
      return { ...state, collaborators: newArray }
    }

    case ADD_COLLABORATOR: {
      const collaborators = state.collaborators.slice(0)
      collaborators.push({
        id: uniqid(),
        role: "owner",
        user: {
          email: "",
        } as any,
        editing: true,
      })
      return { ...state, collaborators }
    }

    case UPDATE_COLLABORATOR_EMAIL: {
      const collaborators = state.collaborators.slice(0)
      const cIndex = collaborators.findIndex(c => c.id === action.payload.id)
      if (cIndex < 0) return state
      const newCollab: $Collaborator = {
        ...collaborators[cIndex],
        user: {
          email: action.payload.email,
        } as any,
      }
      collaborators[cIndex] = newCollab
      return { ...state, collaborators }
    }

    case DELETE_MODERATOR: {
      const collaborators = state.collaborators.slice(0)
      const cIndex = collaborators.findIndex(c => c.id === action.payload.id)

      if (cIndex < 0) {
        return state
      }

      collaborators.splice(cIndex, 1)
      return { ...state, collaborators }
    }
  }

  return state
}
