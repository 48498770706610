import { replace } from "react-router-redux"
import Dispatcher from "../Dispatcher"
import "whatwg-fetch"
import * as Store from "../stores/Store"
import { datadogRum } from "@datadog/browser-rum"
import { ProcessOutUrl } from "^/util/ProcessOutUrl"
import persistedDataHandler from "^/vNext/persistedDataHandler"

const ProcessOutEmailDomains = ["@processout.com", "@checkout.com"]
export function isInternalUser(email: string) {
  return email ? ProcessOutEmailDomains.some(domain => email.includes(domain)) : false
}

class Auth {
  isAdminUser() {
    const userEmail = Store.store.getState().user.details.email
    return isInternalUser(userEmail)
  }

  isLogged() {
    return Store.store.getState().user.auth.loggedIn
  }

  logout() {
    persistedDataHandler.deleteAuthTokens()
    Store.store.dispatch({
      type: "LOGOUT",
    })
    Store.store.dispatch(replace("/login"))
  }

  askResetPassword(email, password, token) {
    let body = JSON.stringify(
      {
        email,
      },
      null,
      2,
    )

    if (token && password) {
      body = JSON.stringify(
        {
          email,
          password,
          password_token: token,
        },
        null,
        2,
      )
    }

    fetch(`${ProcessOutUrl}/users/password-reminders`, {
      method: token && password ? "PUT" : "POST",
      headers: {
        Accept: "application/json",
        "API-Version": "1.2.0.0",
        "Content-Type": "application/json",
      },
      body,
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          Dispatcher.dispatch({
            type: "ASK_RESET_PASSWORD",
          })
        } else {
          Dispatcher.dispatch({
            type: "ASK_RESET_PASSWORD_REJECTED",
            error: json.message,
          })
        }
      })
      .catch(ex => {
        datadogRum.addError(ex)
      })
  }
}

const authManager = new Auth()
export default authManager
