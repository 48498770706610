import clsx from "clsx"
import React, { ReactEventHandler } from "react"
import "./Tab.scss"

export type Props = {
  onClick: ReactEventHandler
  selected: boolean
}

export const Tab = ({ selected, children, onClick }: React.PropsWithChildren<Props>) => {
  return (
    <button data-auto-tracking={true} className={clsx("tab", { selected })} onClick={onClick}>
      {children}
    </button>
  )
}
