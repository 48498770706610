import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../util/Types"
import * as Actions from "./actions"
// @ts-expect-error
import "./declineexplainer.scss"
import DeclineExplainerModal from "./DeclineExplainerModal"
import type { $DeclineExplainerState } from "./reducer"
type Props = {
  transaction: {
    id: string
    error_code: string
  }
  declineExplainer: $DeclineExplainerState
} & $Dispatcher

class DeclineExplainer extends React.Component {
  props: Props
  _modal: any

  componentDidMount(): void {
    const { dispatch, transaction } = this.props
    dispatch(Actions.requestDeclineExplainationFetch(transaction.id))
  }

  componentWillUnmount(): void {
    const { dispatch } = this.props
    dispatch(Actions.resetDeclineExplainer())
  }

  openModal = () => {
    this._modal.getWrappedInstance().openModal()
  }

  render() {
    const { transaction, declineExplainer } = this.props
    if (declineExplainer.fetching || (declineExplainer.fetched && declineExplainer.error))
      return null
    return (
      <div className="row decline-explainer">
        <DeclineExplainerModal ref={e => (this._modal = e)} error_code={transaction.error_code} />
        <div className="medium-12 columns text-right">
          <a onClick={this.openModal}>
            <div className="decline-explainer-new-tag ">NEW</div>
            <span className="text">What happened?</span>
          </a>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  declineExplainer: store.declineExplainer,
}))(DeclineExplainer)
