import React, { CSSProperties } from "react"

type Props = {
  title?: any
  style?: any | null | undefined
  children?: any | null | undefined
  noBackground?: boolean
  noPadding?: boolean
  padding?: string | null | undefined
}
export default class ContentLayout extends React.Component<Props> {
  render() {
    const { children, noPadding, padding, noBackground, title, ...props } = this.props
    let titleRenderer

    if (title) {
      titleRenderer = (
        <div className={`row category-title ${padding || noPadding ? "" : "collapse"}`}>
          <div
            className="large-12 columns"
            style={{
              paddingRight: "22px",
            }}
          >
            {title}
          </div>
        </div>
      )
    }

    const style: CSSProperties = {
      padding: "35px 22px",
    }

    if (noBackground) {
      style.background = "none"
      style.boxShadow = "none"
    }

    if (noPadding) {
      style.padding = 0
      style.boxShadow = "none"
    } else if (padding) {
      style.padding = padding
    }

    return (
      <div {...props}>
        {titleRenderer}
        <div className="row main-content-bg" style={style}>
          <div className="large-12 columns content">{children}</div>
        </div>
      </div>
    )
  }
}
