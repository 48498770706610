import React from "react"
import type { $Card } from "^/Types/Card"
import visa from "../../images/visa.png"
import mastercard from "../../images/mastercard.png"
import amex from "../../images/amex.png"
import jcb from "../../images/jcb.png"
import discover from "../../images/discover.png"
import diners from "../../images/diners.png"
import maestro from "../../images/maestro.png"
import union from "../../images/union.png"
import cofinoga from "../../images/cofinoga.png"
import elo from "../../images/elo.png"

export default function (card: $Card) {
  if (card.scheme.includes("visa"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={visa}
      />
    )
  if (card.scheme.includes("mastercard"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={mastercard}
      />
    )
  if (card.scheme.includes("american express"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={amex}
      />
    )
  if (card.scheme.includes("jcb"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={jcb}
      />
    )
  if (card.scheme.includes("discover"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={discover}
      />
    )
  if (card.scheme.includes("diners"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={diners}
      />
    )
  if (card.scheme.includes("maestro"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={maestro}
      />
    )
  if (card.scheme.includes("union"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={union}
      />
    )
  if (card.scheme.includes("cofinoga"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={cofinoga}
      />
    )
  if (card.scheme.includes("elo"))
    return (
      <img
        alt={card.scheme}
        style={{
          height: "20px",
        }}
        src={elo}
      />
    )
  return <div>&nbsp;</div>
}
