import React from "react"
import { connect } from "react-redux"
import CardDetails from "./TransactionsDetails/CardDetails"
import Customer from "./TransactionsDetails/Customer"
import * as Actions from "../Actions/CustomerDetailsActions"
import { anonymizeCard } from "../Actions/VaultActions"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
import { REQUEST_CONFIRMATION } from "^/features/ConfirmModal/consts"
import type { $Card } from "^/Types/Card"
import { track } from "../vNext/tracking"

const serialize = require("form-serialize")

export type Props = {
  card: $Card
  customerId: string
  tokenId: string
} & $Dispatcher
export class CardModal extends React.Component<Props> {
  openModal() {
    const { dispatch, customerId, tokenId } = this.props
    const { card } = this.props
    const isToken = customerId && tokenId
    const customer = <Customer customer={card.token ? card.token.customer : null} />
    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        size: !isToken && 5,
        header: (
          <div className="row greyed">
            <div className="medium=12 small-font">{card.id}</div>
          </div>
        ),
        content: (
          <div>
            <CardDetails card={card} token_id={null} />
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <hr />
              </div>
            </div>
            {customer}
          </div>
        ),
        footer: (
          <div className="row">
            <div className="large-6 columns">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  isToken
                    ? track("Customer", "Click", "Payment method modal", "Delete button")
                    : track("Customer", "Click", "Payment method modal", "Anonymise button")
                  dispatch({
                    type: REQUEST_CONFIRMATION,
                    payload: {
                      content: (
                        <div className="row">
                          <div className="large-12 columns text-center">
                            {isToken ? (
                              "Do you really want to delete this token?"
                            ) : (
                              <div>
                                <div>Do you really want to anonymise this card?</div>
                                <div>
                                  Anonymising this card will remove the name associated with it
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ),
                      resolve: () => {
                        track("Vault", "Click", "Anonymise confirmation modal", "Confirm button")
                        dispatch(
                          isToken
                            ? Actions.deleteToken(customerId, tokenId, success => {
                                if (success)
                                  dispatch({
                                    type: CLOSE_MODAL,
                                  })
                              })
                            : anonymizeCard(card.id),
                        )
                      },
                      reject: () => {
                        track("Vauld", "Click", "Anonymise confirmation modal", "Cancel button")
                        dispatch({
                          type: CLOSE_MODAL,
                        })
                      },
                    },
                  })
                }}
                className="round border small button"
              >
                {isToken ? "Delete" : "Anonymise"}
              </a>
            </div>
            <div className="large-6 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  track("Customer", "Click", "Payment method modal", "Close button")
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="main round small button"
              >
                Close
              </a>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(
  store => ({
    projects: store.projects,
  }),
  null,
  null,
  {
    withRef: true,
  },
)(CardModal)
