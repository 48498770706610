import type { $Action } from "^/util/Types"
import type { $ApplepayCertificate } from "./consts"
import { FETCH_ALTERNATIVE_CERTIFICATE } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"

type ApplepayCertificateState = $ApplepayCertificate & { error: any }

const defaultState: ApplepayCertificateState = {
  fetched: false,
  fetching: false,
  ApplepayCertificate: null,
  error: null,
}
export default function (
  state: ApplepayCertificateState = defaultState,
  action: $Action,
): ApplepayCertificateState {
  switch (action.type) {
    case typePending(FETCH_ALTERNATIVE_CERTIFICATE): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_ALTERNATIVE_CERTIFICATE): {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ApplepayCertificate: action.payload.applepay_certificates,
      }
    }

    case typeFailed(FETCH_ALTERNATIVE_CERTIFICATE): {
      return { ...state, fetching: false, fetched: true, error: action.payload }
    }
  }

  return state
}
