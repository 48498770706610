import * as ProcessOut from "../util/ProcessOut"
export const FETCH_EVENTS = "FETCH_EVENTS"
export function loadEvents(length, after, id) {
  const bound = after ? "start_after" : "end_before"

  if (!id) {
    id = ""
  }

  return {
    type: FETCH_EVENTS,
    payload: ProcessOut.APIcallPromise(
      `/events?${bound}=${id}&order=desc&limit=${length}&expand=webhooks`,
      "GET",
      null,
    ),
  }
}
