import { FETCH_TWO_FACTOR_DATA } from "^/Actions/UserActions"
import { typeFulfilled, typePending } from "^/util/ActionUtils"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_TWO_FACTOR_DATA): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_TWO_FACTOR_DATA): {
      return { ...state, fetching: false, fetched: true, data: action.payload.user }
    }
  }

  return state
}
