import sum from "lodash/sum"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import * as ProcessOut from "^/util/ProcessOut"
import type { $Dispatcher, $Project, $RouterLocation } from "^/util/Types"
import Error from "../../../components/Error"
import Loader from "../../../components/Loader"
import Tooltip from "../../../util/Tooltip"
import { loadRecommendationFromPeriod } from "../actions"
import "../components/TelescopeCard.scss"
import type { $Period, $Recommendations } from "../consts"
import { getEvolution } from "../util"
import DiagnosticsMetrics from "./DiagnosticsMetrics"

type DiagnosticRecommendations = {
  periodRecommendations: {
    periodRecommendations: $Recommendations
    fetched: boolean
    error: boolean
  }
}

export type DiagnosticsCardProps = {
  diagnosticRecommendations: DiagnosticRecommendations
  currentProject: {
    project: $Project
  }
  location: $RouterLocation
} & $Dispatcher

export const DiagnosticsCard: React.FC<DiagnosticsCardProps> = ({
  dispatch,
  location,
  diagnosticRecommendations,
  currentProject,
}) => {
  useEffect(
    () => dispatch(loadRecommendationFromPeriod(location.query.period as $Period)),
    [dispatch, location],
  )

  const currency = currentProject.project.default_currency
  let metricsOptimization

  if (diagnosticRecommendations.periodRecommendations.error) {
    metricsOptimization = (
      <div>
        <Error text="Our algorithm is still working on your data…" />
      </div>
    )
  } else if (diagnosticRecommendations.periodRecommendations.fetched) {
    metricsOptimization = (
      <>
        <div className="medium-6 column">
          <DiagnosticsMetrics
            title="Number of anomalies"
            contentTooltip="The number of transaction profiles where the conversion rate has dropped compared to the previous period"
            id="profiles-id"
            diagnosticOptimization={getNumber(diagnosticRecommendations)}
            currency="nil"
          />
        </div>
        <div className="medium-6 column">
          <DiagnosticsMetrics
            title="Amount impacted"
            contentTooltip="This amount is calculated based on the average amount of the transaction profile"
            id="amount-id"
            diagnosticOptimization={getAmount(diagnosticRecommendations)}
            currency={currency}
          />
        </div>
      </>
    )
  } else {
    metricsOptimization = <Loader />
  }

  const diagnosticTooltipContent =
    "Detecting conversion rate drops, quantifying them and providing the transaction profile associated"
  return (
    <div
      className="telescope-card"
      style={{
        minHeight: "202px",
      }}
      data-tracking-location="Diagnostics Card"
    >
      <div
        className="small-margin-bottom"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "25px",
          }}
        >
          Anomaly detection
        </div>
        <Tooltip content={diagnosticTooltipContent} id="diagnostic-id" size="large" place="top">
          <div
            className="info"
            data-tip
            data-for="diagnostic-id"
            style={{
              display: "inline-block",
            }}
          >
            i
          </div>
        </Tooltip>
      </div>
      <div className="row">{metricsOptimization}</div>
      <div
        style={{
          textAlign: "end",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/projects/${ProcessOut.config.ProjectId}/anomaly-detection`}
          data-tracking-label="View Dashboard"
          data-auto-tracking={true}
        >
          View dashboard
        </Link>
      </div>
    </div>
  )
}

const getAmount = (diagnosticRecommendations: DiagnosticRecommendations): number => {
  const diagnostics = diagnosticRecommendations.periodRecommendations.periodRecommendations
  const arrayOfAmounts = []
  diagnostics.profiles.forEach(profile => arrayOfAmounts.push(profile.amount_to_gain))
  return sum(arrayOfAmounts)
}

const getNumber = (diagnosticRecommendations: DiagnosticRecommendations): number => {
  return diagnosticRecommendations.periodRecommendations.periodRecommendations.profiles.length
}

export default connect(store => ({
  diagnosticRecommendations: store.telescopeRecommendations,
  currentProject: store.currentProject,
}))(withRouter(DiagnosticsCard))
