import { FETCH_PLANS } from "^/Actions/PlansActions"
import type { $Action, $State } from "../../util/Types"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $Plan = {
  name: string
  id: string
  amount: number
  currency: string
}
export type $PlansState = {
  plans: Array<$Plan>
} & $State
const defaultState: $PlansState = {
  fetching: false,
  fetched: false,
  error: undefined,
  plans: [],
}
export default function (state: $PlansState = defaultState, action: $Action): $PlansState {
  switch (action.type) {
    case typePending(FETCH_PLANS): {
      return { ...state, ...defaultState, fetching: true }
    }

    case typeFulfilled(FETCH_PLANS): {
      if (!action.payload.success) {
        return { ...state, fetching: false, fetched: true, plans: [] }
      }

      return { ...state, ...action.payload, fetching: false, fetched: true }
    }

    case typeFailed(FETCH_PLANS): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
