import React from "react"
import { FormattedNumber } from "react-intl"
import Warning from "./Warning"
type Props = {
  number: number | null | undefined
  name: string
  style?: "currency" | "percent"
  currency?: string
  warning?: string | null | undefined
  disableWarnings?: boolean
}

class PanelNumber extends React.Component<Props> {
  render() {
    const { style, number, currency, name, disableWarnings, warning } = this.props
    return (
      <div>
        <div className="panel-number">
          {!isNaN(number) && number !== null && number !== undefined ? (
            <FormattedNumber
              value={number}
              style={style}
              currency={currency}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          ) : (
            <div>
              {!number && !disableWarnings ? <Warning text="Data could not be fetched" /> : null}
              <span className="greyed">—</span>
            </div>
          )}
        </div>
        <div className="panel-number-name">{name}</div>
        {warning && !disableWarnings ? <Warning text={warning} /> : null}
      </div>
    )
  }
}

export default PanelNumber
