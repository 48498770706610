import type { $Action, $State } from "../../../util/Types"
import type { $AlertEvent } from "../consts"
import { REQUEST_ALERTS_EVENTS_FETCH } from "../actions"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $AlertsEventsState = {
  events: Array<$AlertEvent>
} & $State
const defaultState: $AlertsEventsState = {
  fetching: false,
  fetched: false,
  error: null,
  events: [],
}
export default function (
  state: $AlertsEventsState = defaultState,
  action: $Action,
): $AlertsEventsState {
  switch (action.type) {
    case typePending(REQUEST_ALERTS_EVENTS_FETCH): {
      return { ...state, ...defaultState, fetching: true }
    }

    case typeFailed(REQUEST_ALERTS_EVENTS_FETCH): {
      return { ...state, ...defaultState, fetched: true, error: action.payload }
    }

    case typeFulfilled(REQUEST_ALERTS_EVENTS_FETCH): {
      return { ...state, fetched: true, fetching: false, ...action.payload }
    }
  }

  return state
}
