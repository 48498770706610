import React from "react"
import type { $Option } from "../analytics/DataExplorer/consts"
type Props = {
  option: $Option
  onSelect: () => void
} // Component displayed when a filter path is inactive

class FilterPathSelector extends React.Component<Props> {
  render() {
    return (
      <div className="row collapse path-row unactive" onClick={this.props.onSelect}>
        <div className="medium-1 columns">
          <input type="checkbox" checked={false} onChange={this.props.onSelect} />
        </div>
        <div className="medium-11 columns">{this.props.option.label}</div>
      </div>
    )
  }
}

export default FilterPathSelector
