import type { Context } from "react"
import { createContext } from "react"
import type { actions, DragDropState } from "./types"
export const initialState: DragDropState = {
  dragging: false,
  activeCategory: null,
  draggingTarget: null,
}
export type Dispatch = (value: actions) => void
export const DragDropContext: Context<[DragDropState, Dispatch]> = createContext<
  [DragDropState, Dispatch]
>([initialState, () => {}])
