import type { $Action } from "../../util/Types"
import type { $ApiKeyModel, $ApiSetupState } from "./consts"
import {
  CREATE_NEW_PRIVATE_KEY,
  DELETE_PRIVATE_KEY,
  PREPARE_API_SETUP,
  SAVE_PRIVATE_KEY_UPDATE,
} from "./consts"
export function requestApiSetupPreparation(): $Action {
  return {
    type: PREPARE_API_SETUP,
    payload: null,
  }
}
export function updateApiVersion(apiDetails: $ApiSetupState, newVersion: string): $Action {
  return {
    // FIXME: no UPDATE_API_DETAILS
    // @ts-ignore
    type: UPDATE_API_DETAILS,
    payload: { ...apiDetails, currentApiVersion: newVersion },
  }
}
export function createApiKey(
  name: string,
  callback: (secret: string) => void,
  onError: () => void,
): $Action {
  return {
    type: CREATE_NEW_PRIVATE_KEY,
    payload: {
      name,
      callback,
      onError,
    },
  }
}
export function deleteApiKey(name: string, callback: () => void): $Action {
  return {
    type: DELETE_PRIVATE_KEY,
    payload: {
      name,
      callback,
    },
  }
}
export function updatePrivateKey(privateKey: $ApiKeyModel, callback: () => void): $Action {
  return {
    type: SAVE_PRIVATE_KEY_UPDATE,
    payload: {
      privateKey,
      callback,
    },
  }
}
