import React from "react"
import { connect } from "react-redux"
import { FormattedDate } from "react-intl"
import type { $Dispatcher } from "^/util/Types"
import { CLOSE_MODAL, OPEN_MODAL } from "^/util/Types"
type Props = {
  configuration: {}
  subscriptionOnly: boolean
} & $Dispatcher
export class GatewayConfModal extends React.Component<Props> {
  checkVar(obj) {
    return !obj || obj === "" ? <span className="greyed">--</span> : obj
  }

  getLine(title, value) {
    return (
      <div className="row small-margin-bottom">
        <div className="large-4 columns">
          <span className="greyed">{title}</span>
        </div>
        <div className="large-8 columns">
          <span className="right">{this.checkVar(value)}</span>
        </div>
      </div>
    )
  }

  openModal() {
    const config = this.props.configuration
    let subOnly

    if (this.props.subscriptionOnly) {
      subOnly = (
        <div
          className="row"
          style={{
            marginTop: "1em",
          }}
        >
          <div className="large-12 columns text-center bold">
            <p>Can only be used on the subscription it was created for.</p>
          </div>
        </div>
      )
    }

    this.props.dispatch({
      type: OPEN_MODAL,
      payload: {
        header: `${config.gateway.display_name} token`,
        content: (
          <div>
            <div className="row small-margin-bottom">
              <div className="large-12 columns">
                <h6 className="capitalize greyed font-console">Payment token</h6>
              </div>
            </div>
            <div className="row margin-bottom">
              <div className="large-12 columns text-right font-console greyed">
                <input type="text" disabled defaultValue={config.id} />
              </div>
            </div>
            {this.getLine("Name", config.gateway.display_name)}
            {this.getLine("Currency", config.default_currency)}
            {this.getLine(
              "Created",
              <FormattedDate
                value={config.created_at}
                day="2-digit"
                month="2-digit"
                year="numeric"
              />,
            )}
            {subOnly}
            <div
              className="row"
              style={{
                marginTop: "2em",
              }}
            >
              <div className="large-12 columns text-center small-font">
                <p>
                  This payment method was created for this specific gateway, and may not be used to
                  process a payment on another gateway.
                </p>
              </div>
            </div>
          </div>
        ),
        footer: (
          <div className="row">
            <div className="large-12 columns text-right">
              <a
                onClick={function (event) {
                  event.preventDefault()
                  this.props.dispatch({
                    type: CLOSE_MODAL,
                  })
                }.bind(this)}
                className="round border small button"
              >
                Cancel
              </a>
            </div>
          </div>
        ),
      },
    })
  }

  render() {
    return null
  }
}
export default connect(null, null, null, {
  withRef: true,
})(GatewayConfModal)
