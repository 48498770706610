import React, { useContext } from "react"
import Card from "./Card"
import { GatewayContext } from "../GatewayContext"
import { GatewayInformation } from "^/vNext/@types/types"
import { RoutingContext } from "../RoutingContext"
import clsx from "clsx"
import { Pill } from "./Pill"
import { isValidGateway } from "^/vNext/RoutingRules/utils"

export type Props = {
  gatewayInfo: GatewayInformation
  onGatewaySelection: (auth: GatewayInformation) => void
}

/**
 * The container for authorization steps: one authorization step and others are fallbacks, maximum three steps.
 */
export const Gateway3DS = ({ gatewayInfo, onGatewaySelection }: Props) => {
  const { gatewayNames, gatewayConfigurations } = useContext(GatewayContext)
  const [state] = useContext(RoutingContext)

  if (!gatewayInfo) return null

  const gatewayName = gatewayNames[gatewayInfo.gateway]

  const tooltip = `On gateway ${gatewayName}`

  const isGatewayInvalid = !isValidGateway(gatewayInfo, gatewayConfigurations)

  return (
    <Card
      cardId={gatewayInfo.id}
      className={clsx("gateway", {
        invalid: isGatewayInvalid,
      })}
      selected={state.selectedCardId === gatewayInfo.id}
      key={gatewayInfo.id}
      name="On gateway"
      onClick={() => onGatewaySelection(gatewayInfo)}
      tooltip={tooltip}
    >
      <Pill key={gatewayInfo.id}>{gatewayName}</Pill>
    </Card>
  )
}
