import { typeFulfilled, typePending } from "^/util/ActionUtils"
import { CREATE_REFUND, FETCH_REFUNDS } from "^/Actions/TransactionsActions"
export default function (
  state = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_REFUNDS): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_REFUNDS): {
      return { ...state, fetching: false, fetched: true, refunds: action.payload.refunds }
    }

    case typePending(CREATE_REFUND): {
      return { ...state, fetching: true, fetched: false }
    }

    case typeFulfilled(CREATE_REFUND): {
      return { ...state, fetching: false, fetched: true }
    }
  }

  return state
}
