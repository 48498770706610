import type { $Action } from "../../util/Types"
import type { $GwayConfNames } from "./consts"
import { FETCH_GWAY_CONF } from "./consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
type State = {
  names?: $GwayConfNames
} & {
  fetching: boolean
  fetched: boolean
  error?: boolean
  notPermitted?: true
}
const defaultState: State = {
  fetched: false,
  fetching: false,
}
export default function (state: State = defaultState, action: $Action): State {
  switch (action.type) {
    case typePending(FETCH_GWAY_CONF): {
      return { ...state, fetching: true, fetched: false, names: undefined, error: false }
    }

    case typeFulfilled(FETCH_GWAY_CONF): {
      return { ...state, ...action.payload, fetching: false, fetched: true, error: false }
    }

    case typeFailed(FETCH_GWAY_CONF): {
      return { ...state, fetching: false, fetched: true, error: true, names: undefined }
    }
  }

  return state
}
