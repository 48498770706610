import * as ProcessOut from "^/util/ProcessOut"
import moment from "moment"
export function formatBarChart(
  data: Array<{
    key: string
    value: string
  }>,
): Array<{
  key: string
  datapoints: Array<{
    key: string
    value: string
  }>
}> {
  return [
    {
      key: "single",
      datapoints: data,
    },
  ]
}
export const CHART_PREVIEW_TEMPLATE = {
  name: "preview",
  description: "preview",
  type: "bar-chart",
  unit: "currency",
  preview: true,
  size: 12,
}
export const getStartDateFromTimespan = (timespan: string): number => {
  switch (timespan) {
    case "weekly": {
      return moment().subtract(1, "weeks").unix()
    }

    case "monthly": {
      return moment().subtract(1, "months").unix()
    }

    case "quarterly": {
      return moment().subtract(1, "quarters").unix()
    }

    case "annually": {
      return moment().subtract(1, "years").unix()
    }

    default: {
      return moment().subtract(1, "weeks").unix()
    }
  }
}
export const computeUrl = (url: string, timespan: string): string => {
  const startDate = getStartDateFromTimespan(timespan)
  return `${url}?start_at=${startDate}&end_at=${moment().unix()}`
}
export function* fetchTelescopeChartData(
  formula: string,
  timespan: string,
): Generator<any, any, any> {
  const chart = {
    ...CHART_PREVIEW_TEMPLATE,
    settings: {
      formula,
    },
    type: "single-value",
  }
  const httpResult = yield ProcessOut.APIcallPromise(
    computeUrl("/boards/board_default-sales/charts", timespan),
    "POST",
    JSON.stringify(chart),
    null,
    null,
    true,
  )

  if (!httpResult.data.success) {
    return null
  }

  return httpResult.data.data
}
