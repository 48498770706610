import * as React from "react"
export type SelectRoutingRule = {
  type: "select.routing.rule"
  index: number
}
export type SelectCard = {
  type: "select.routing.card"
  id: string | null
}
export type RoutingState = {
  selectedRuleIndex: number
  selectedCardId: string | null
}
export type actions = SelectRoutingRule | SelectCard
export const initialState: RoutingState = {
  selectedRuleIndex: 0,
  selectedCardId: null,
}
export type Dispatch = (value: actions) => void
export const RoutingContext: React.Context<[RoutingState, Dispatch]> = React.createContext<
  [RoutingState, Dispatch]
>([initialState, () => {}])
