import type { $Option } from "../../analytics/DataExplorer/consts"
import { BUILDER_METRICS } from "../../analytics/DataExplorer/ChartBuilder/consts"
import type { $Action, $State } from "../../../util/Types"
import {
  REQUEST_ALERT_BUILDER_PREVIEW,
  RESET_ALERT_BUILDER_FORM,
  UPDATE_ALERT_BUILDER_FORM,
} from "./actions"
import type { $DataPoint } from "../../analytics/Boards/consts"
import type { $Filter } from "../../analytics/DataExplorer/ChartBuilder/Filters/consts"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
import type { $BuilderMetric } from "../../analytics/DataExplorer/ChartBuilder/consts"
export type $AlertBuilderState = {
  name: string
  selectedMetric: $BuilderMetric
  interval: $Option
  triggerOperand: $Option
  triggerValue: number
  previewPoints: Array<$DataPoint>
  filters: Array<$Filter>
  minimumCount: number
} & $State
const defaultState: $AlertBuilderState = {
  name: "",
  selectedMetric: BUILDER_METRICS[3],
  // Authorization rate
  interval: {
    label: "5 minutes",
    value: 300,
  },
  triggerOperand: {
    label: "<",
    value: "<",
  },
  triggerValue: 0.9,
  fetched: false,
  fetching: false,
  error: null,
  previewPoints: [],
  filters: [],
  minimumCount: 5,
}
export default function (
  state: $AlertBuilderState = defaultState,
  action: $Action,
): $AlertBuilderState {
  switch (action.type) {
    case RESET_ALERT_BUILDER_FORM: {
      return { ...state, ...defaultState }
    }

    case typeFulfilled(UPDATE_ALERT_BUILDER_FORM): {
      return { ...state, [action.payload.field]: action.payload.value }
    }

    case typePending(REQUEST_ALERT_BUILDER_PREVIEW): {
      return { ...state, fetching: true, fetched: false, error: null, previewPoints: [] }
    }

    case typeFailed(REQUEST_ALERT_BUILDER_PREVIEW): {
      return { ...state, fetching: false, fetched: true, error: action.payload, previewPoints: [] }
    }

    case typeFulfilled(REQUEST_ALERT_BUILDER_PREVIEW): {
      return { ...state, fetching: false, fetched: true, previewPoints: action.payload.points }
    }

    default:
      return state
  }
}
