/**
 * Created by jeremylejoux on 07/06/17.
 */
import React from "react"
export default class SettingsContainer extends React.Component {
  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  render() {
    const { children } = this.props
    return <div>{children}</div>
  }
}
