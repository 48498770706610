export default function (
  state = {
    open: false,
    header: null,
    content: null,
    footer: null,
  } as any,
  action,
) {
  switch (action.type) {
    case "OPEN_MODAL": {
      document.body.classList.add("show-modal")
      state = { ...state, open: true, ...action.payload }
      return state
    }

    case "CLOSE_MODAL": {
      document.body.classList.remove("show-modal")
      window.dispatchEvent(new CustomEvent("resize-dashboard"))
      state = { ...state, open: false, large: false }
      return state
    }
  }

  return state
}
