import React from "react"
import { Link } from "react-router"
import { FormattedNumber } from "react-intl"
import * as ProcessOut from "../util/ProcessOut"
import type { TransactionModel } from "../Types/Transaction"
import CardSchemeImage from "../util/CardSchemeImage"
import DateDisplay from "../features/DateDisplay"
import * as Helpers from "./Helpers"
import { track } from "^/vNext/tracking"
import { getDaysSinceToday } from "^/util/DateUtils"

export default class Transaction extends React.Component<{
  transaction: TransactionModel
  smallDisplay?: boolean
}> {
  render() {
    const { transaction } = this.props
    let name = ""
    let isNameEmpty = false

    if (transaction.customer && transaction.customer.first_name) {
      name = `${transaction.customer.first_name} ${
        transaction.customer.last_name ? transaction.customer.last_name : ""
      }`
    } else if (transaction.buyer && transaction.buyer.first_name) {
      name = `${transaction.buyer.first_name} ${
        transaction.buyer.last_name ? transaction.buyer.last_name : ""
      }`
    } else if (transaction.buyer && transaction.buyer.email) {
      name = transaction.buyer.email
    } else {
      name = "--"
      isNameEmpty = true
    }

    let transactionName = transaction.name
    let nameIsEmpty = false

    if (typeof transactionName === "string" && transactionName === "") {
      transactionName = "--"
      nameIsEmpty = true
    }

    if (transactionName.length > 40) transactionName = `${transactionName.substring(0, 40)}...`
    let pendingClass: string = ""
    const status = Helpers.generateTransactionStatusTag(transaction)
    if (transaction.status === "pending" && transaction.three_d_s_status !== "failed")
      pendingClass = "greyed;"
    let planName

    if (!this.props.smallDisplay) {
      planName = (
        <div className={`medium-${this.props.smallDisplay ? 6 : 3} columns`}>
          {nameIsEmpty ? <span className="greyed">{transactionName}</span> : transactionName}
        </div>
      )
    }

    let custName

    if (!this.props.smallDisplay) {
      custName = (
        <div className="medium-2 columns">
          {isNameEmpty ? <span className="greyed">{name}</span> : name}
        </div>
      )
    }

    return (
      // Will not redirect this to new dash on purpose, so that if users end up on old Transactions list page, they
      // stay within the old dashboard
      <Link
        to={`/projects/${ProcessOut.config.ProjectId}/transactions/${transaction.id}`}
        onClick={() => {
          track("Transactions", "Click", "List", "Row", {
            transactionStatus: transaction.status,
            transaction3dsStatus: transaction.three_d_s_status,
            transactionGateway: transaction.gateway_configuration
              ? transaction.gateway_configuration.gateway?.display_name ||
                transaction.gateway_configuration.name
              : "none",
            daysSinceTransactionCreated: getDaysSinceToday(transaction.created_at),
          })
        }}
      >
        <div className={`box-row padding row ${pendingClass}`}>
          <div className={`medium-${this.props.smallDisplay ? 2 : 1} columns`}>{status}</div>
          <div className="medium-1 columns text-center">
            {transaction.card ? CardSchemeImage(transaction.card) : <div>&nbsp;</div>}
          </div>
          <div
            className={`medium-${this.props.smallDisplay ? "5" : "3"} columns clearfix`}
            style={{
              marginLeft: "-15px",
            }}
          >
            <span className="big-font left">
              <span className="greyed">{transaction.currency}</span>{" "}
              <FormattedNumber
                value={transaction.amount}
                style="currency"
                currency={transaction.currency}
              />
            </span>
            <span className="right transaction-processor">
              {transaction.gateway_configuration ? (
                <img
                  alt={`${transaction.gateway_configuration.gateway.name}'s logo`}
                  className="right"
                  style={{
                    opacity: transaction.status.includes("pending") ? 0.3 : 1,
                  }}
                  src={transaction.gateway_configuration.gateway.logo_url}
                />
              ) : (
                ""
              )}
            </span>
          </div>
          {planName}
          {custName}
          <div className={`medium-${this.props.smallDisplay ? "4" : "2"} columns greyed`}>
            <span className="right">
              <DateDisplay value={transaction.created_at} />
            </span>
          </div>
        </div>
      </Link>
    )
  }
}
