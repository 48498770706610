import React, { useCallback, useRef, useState } from "react"
import Select from "react-select"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import { withRouter } from "react-router"
import type { $Dispatcher, $RouterLocation } from "^/util/types"
import type { $Option } from "../../analytics/DataExplorer/consts"
import { getEndDateUtcMoment, getStartDateUtcMomentFromTimespan } from "^/features/TelescopeV2/util"
import { $Period, $TimespanMetrics } from "^/features/TelescopeV2/consts"
import { trackTarget } from "^/vNext/tracking"

export type PeriodSelectorProps = {
  location: $RouterLocation
} & $Dispatcher

type Period = {
  label: string
  timespan: $TimespanMetrics
  value: $Period
}

export const PeriodSelector: React.FC<PeriodSelectorProps> = ({ dispatch, location }) => {
  const ref = useRef()
  const options = generateOptions()
  const initialOption = options.find(option => option.value === location.query.period) || options[0]

  const [selectedOption, setSelectedOption] = useState<$Option>(initialOption)
  const onChange = useCallback(
    (value: $Option) => {
      setSelectedOption(value)
      dispatch(push(`${location.pathname}?period=${value.value}`))
      trackTarget(ref.current, "Click", "Item", {
        value: value.value,
      })
    },
    [dispatch, location, ref],
  )
  const customStyles = {
    valueContainer: provided => ({ ...provided, height: 48, width: 350 }),
    option: styles => ({ ...styles, cursor: "pointer" }),
    control: styles => ({ ...styles, cursor: "pointer" }),
  }

  return (
    <div ref={ref}>
      <Select
        className="react-select"
        value={selectedOption}
        options={options}
        onChange={onChange}
        styles={customStyles}
      />
    </div>
  )
}

const PERIODS: Period[] = [
  {
    label: "1 week",
    timespan: "weekly",
    value: "1w",
  },
  {
    label: "1 month",
    timespan: "monthly",
    value: "1m",
  },
  {
    label: "3 months",
    timespan: "quarterly",
    value: "3m",
  },
]

const generateOptions = (): $Option[] => {
  const DATE_FORMAT = "D MMM YYYY"
  const options: Array<$Option> = PERIODS.map(period => {
    const BEGINNING_DATE = getStartDateUtcMomentFromTimespan(period.timespan).format(DATE_FORMAT)
    const END_DATE = getEndDateUtcMoment().format(DATE_FORMAT)
    return {
      label: `${period.label} (${BEGINNING_DATE} to ${END_DATE})`,
      value: period.value,
    }
  })
  return options
}

export default connect()(withRouter(PeriodSelector))
