import type { $CurrentProject, $Dispatcher, $RouterLocation } from "^/util/Types"
import type { $ChartDatasFromApi, $PerCountryTableData, $Period, ValueStyle } from "../consts"

import Loading from "^/components/Loader"
import ContentLayout from "^/pages/ContentLayout"
import { prepareTelescopeV2Page } from "../actions"
import BarChartTooltip from "../components/BarChartTooltip"
import PerCountryTable from "../components/PerCountryTable"
import SummaryCards from "../components/SummaryCards/SummaryCards"
import TelescopeHeader from "../components/TelescopeHeader"
import BarChartHorizontal from "../visualisations/BarChartHorizontal"
import { chartFormattedData } from "../visualisations/util"
import AuthLineCharts from "./AuthLineCharts"

import React, { useEffect } from "react"
import { connect } from "react-redux"

export type Metrics = {
  authorization_rate: number
  authorization_rate_increase: number
  declined_chart: number
  authorization_rate_chart: $ChartDatasFromApi[]
  total_transaction_amount: number
  authorized_amount_transactions: number
  number_of_transactions: number
  authorized_transactions: number
  declined_amount_transactions: number
  declined_transactions: number
  declined_rate: $ChartDatasFromApi[]
  authorization_rate_by_gateway: $ChartDatasFromApi[]
  authorization_rate_by_card_scheme: $ChartDatasFromApi[]
  authorization_rate_by_payment_method: $ChartDatasFromApi[]
  authorization_rate_by_card_type: $ChartDatasFromApi[]
  fetched: boolean
  fetching: boolean
  tableData: Array<$PerCountryTableData>
}

type Props = {
  metricsTelescope: Metrics
  location: $RouterLocation
  currentProject: $CurrentProject
} & $Dispatcher

export function Authorization({ metricsTelescope, location, dispatch, currentProject }: Props) {
  useEffect(() => {
    document.title = "Authorization | ProcessOut"
  }, [])

  useEffect(() => {
    dispatch(prepareTelescopeV2Page("authorization", location.query.period as $Period))
  }, [location.query])

  const summaryMetrics = [
    {
      title: "Authorization rate",
      value: metricsTelescope.authorization_rate,
      percentageEvolution: metricsTelescope.authorization_rate_increase,
      id: "authorization_rate",
      tooltipContent:
        "Number of successful authorizations / total number of authorization requests",
      valueStyle: "percent" as ValueStyle,
    },
    {
      title: "Total transactions",
      value: metricsTelescope.total_transaction_amount,
      id: "number_transactions",
      tooltipContent:
        "Total value and volume of the authorization operations, regardless of their status (failed or success)",
      valueStyle: "currency" as ValueStyle,
      moreDetails: true,
      count: metricsTelescope.number_of_transactions,
    },
    {
      title: "Authorized transactions",
      value: metricsTelescope.authorized_amount_transactions,
      id: "authorized_transactions",
      tooltipContent: "Total value and volume of transactions that have been authorized",
      valueStyle: "currency" as ValueStyle,
      count: metricsTelescope.authorized_transactions,
      moreDetails: true,
    },
    {
      title: "Declined transactions",
      value: metricsTelescope.declined_amount_transactions,
      id: "declined_transactions",
      tooltipContent: "Total value and volume of transactions that have been declined",
      valueStyle: "currency" as ValueStyle,
      count: metricsTelescope.declined_transactions,
      moreDetails: true,
    },
  ]

  const barChartsData = [
    {
      name: "Payment provider",
      value: metricsTelescope.authorization_rate_by_gateway,
      tooltipContent: "Breakdown of authorization rate per payment service provider",
      id: "paymentProvider_id",
    },
    {
      name: "Card scheme",
      value: metricsTelescope.authorization_rate_by_card_scheme,
      tooltipContent: "Breakdown of authorization rate per card scheme",
      id: "cardScheme_id",
    },
    {
      name: "Payment method",
      value: metricsTelescope.authorization_rate_by_payment_method,
      tooltipContent: "Breakdown of authorization rate per payment method",
      id: "paymentMethod_id",
    },
    {
      name: "Card type",
      value: metricsTelescope.authorization_rate_by_card_type,
      tooltipContent: "Breakdown of authorization rate per card type",
      id: "cardType_id",
    },
  ]

  const lineChartData = [
    {
      data: metricsTelescope.authorization_rate_chart ?? [],
      chartTitle: "Authorization rate",
    },
    { data: metricsTelescope.declined_rate ?? [], chartTitle: "Decline rate" },
  ]

  const loading = !metricsTelescope.fetched || metricsTelescope.fetching

  return (
    <div data-tracking-sub-page="Authorization">
      <ContentLayout
        title={<TelescopeHeader title="Authorization" />}
        noBackground
        padding="0"
        data-tracking-location="Summary"
      >
        <div className="large-margin-bottom small-margin-top">
          <SummaryCards data={summaryMetrics} loading={loading} />
        </div>
      </ContentLayout>
      {loading ? (
        <Loading />
      ) : (
        <ContentLayout data-tracking-location="Analytics">
          <div className="small-margin-bottom">
            <AuthLineCharts datas={lineChartData} />
          </div>
          <PerCountryTable
            tableData={metricsTelescope.tableData}
            currentProject={currentProject}
            title="Authorization rate per country"
            tooltipContent="Breakdown of authorization rate per country"
          />
          <div className="large-margin-top row">
            {barChartsData.map(data => (
              <div className="columns medium-6 large-margin-bottom end" key={data.id}>
                <BarChartTooltip value={data} />
                <BarChartHorizontal
                  datas={chartFormattedData(data.value)}
                  axisLeft={150}
                  name={data.name}
                />
              </div>
            ))}
          </div>
        </ContentLayout>
      )}
    </div>
  )
}
export default connect(store => ({
  metricsTelescope: store.metricsTelescopeReducer.authorization,
  currentProject: store.currentProject,
}))(Authorization)
