import React from "react"
import { connect } from "react-redux"
import Partial from "./Partial"
import PanelNumber from "../Components/PanelNumber"
import type { $TelescopeState } from "../../../stores/Reducers/TelescopeReducer/Index"
import type { $Project } from "../../../util/Types"
import type { $ChargebackData } from "../reducer"
import * as Actions from "../actions"
import { REQUEST_SURFACES_FETCH } from "../Surface/consts"
import type { $Dispatcher } from "../../../Util/Types"
type Props = {
  telescope: $TelescopeState
  currentProject: {
    project: $Project
  }
} & $Dispatcher

class Chargebacks extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(Actions.selectSurfacesType("chargeback-rate"))
    dispatch({
      type: REQUEST_SURFACES_FETCH,
      payload: {
        timespan: this.props.telescope.standard.timespan,
        type: "chargeback-rate",
      },
    })
  }

  render() {
    const { data } = this.props.telescope.standard
    const currency = this.props.currentProject.project.default_currency || "USD"
    const cD: $ChargebackData = data.chargebacksData
    return (
      <Partial
        leftPanel={
          <div className={`row${data.chargebacksData.ready ? "" : " loading"}`}>
            <div className="large-6 columns">
              <PanelNumber
                number={cD.globalChargebackVolume}
                name="Chargeback volume"
                style="currency"
                currency="USD"
              />
            </div>
            <div className="large-6 columns text-right">
              <PanelNumber number={cD.globalChargebackCount} name="Chargebacks" />
            </div>
          </div>
        }
        rightPanel={
          <div className={`row${data.chargebacksData.ready ? "" : " loading"}`}>
            <div className="large-6 columns">
              <div
                data-multiline
                data-delay-show={500}
                data-tip="A transaction is flagged as Request for Information when a customer ask explanations about a transaction on his bank account."
              >
                <PanelNumber
                  name="Request for information"
                  number={cD.mastercardRequestsForInformation + cD.visaRequestsForInformation}
                />
              </div>
            </div>
            <div className="large-6 columns text-right">
              <div
                data-multiline
                data-delay-show={500}
                data-tip="A transaction is declared as Notification of Fraud is when a customer ask to dispute a transaction that he saw on his bank account."
              >
                <PanelNumber name="Notifications of fraud" number={cD.globalNotificationsOfFraud} />
              </div>
            </div>
          </div>
        }
      />
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  currentProject: store.currentProject,
}))(Chargebacks)
