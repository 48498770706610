import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import ContentLayout from "./ContentLayout"
import * as BoardsActions from "../features/analytics/Boards/actions"
import type { $Board } from "../features/analytics/Boards/consts"
import { CHANGE_EDIT_BOARD_MODE } from "../features/analytics/Boards/consts"
import type { $Params } from "../features/analytics/ChartPreviewer/consts"
import type { $Dispatcher, $State } from "../util/Types"
import Loading from "../components/Loader"
import DatePicker from "../features/analytics/DatePicker/DatePicker"
import { CANCEL_BOARD_EDITION } from "../features/analytics/Boards/Board/consts"
import BoardMenu from "../features/analytics/Boards/BoardsEditor/BoardMenu"
import * as BoardEditionActions from "../features/analytics/Boards/BoardsEditor/actions"
import * as Actions from "../features/SearchBar/actions"
import { ANALYTICS_FILTER_CHANGE } from "^/Actions/AnalyticsActions"
import { track } from "../vNext/tracking"
type Props = {
  location: any
  boardId: string
  boardsData?: {
    boards: Array<$Board>
  } & $State
  boardDetails: {
    editing: boolean
    localLayout: any
  }
  projectId: string
  analytics?: {
    params: $Params
  }
  noBackground: boolean | null | undefined
  style: any | null | undefined
} & $Dispatcher
type State = {
  filter: string
}

class AnalyticsLayout extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      filter: "",
    }
  }

  componentDidMount() {
    document.title = "Analytics | ProcessOut"
    const { location } = this.props
    const { filter } = location.query

    if (filter) {
      this.setState({
        filter,
      })
      // there is a filter in the url, we nned to update the app filter
      this.props.dispatch({
        type: ANALYTICS_FILTER_CHANGE,
        payload: {
          filter,
        },
      })
    }

    // Set the document for filter button
    this.props.dispatch(Actions.setFilteringDocument("analytics"))
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.query.filter !== this.props.location.query.filter) {
      // the url changed and the filter are different so we update the app filter with the url query
      this.props.dispatch({
        type: ANALYTICS_FILTER_CHANGE,
        payload: {
          filter: nextProps.location.query.filter || "",
        },
      })
    }

    // If we changed board we cancel the edit mode
    if (nextProps.params.board !== this.props.params.board) {
      this.props.dispatch({
        type: CANCEL_BOARD_EDITION,
      })
    }
  }

  componentWillUnmount() {
    // Remove document to hide filter button
    this.props.dispatch(Actions.setFilteringDocument(null))
  }

  filter = (filter: string) => {
    this.props.dispatch({
      type: ANALYTICS_FILTER_CHANGE,
      payload: {
        filter,
      },
    })
  }
  deleteBoard = (event: Event) => {
    event.preventDefault()
    const { dispatch, projectId, boardId } = this.props

    if (window.confirm("Deleting a board cannot be undone! You will lose all your charts.")) {
      dispatch(BoardsActions.requestBoardDeletion(projectId, boardId))
    }
  }
  triggerBoardEdit = (event: Event) => {
    event.preventDefault()
    const { dispatch, boardDetails } = this.props

    // we update the store so that other components know we're editing a board
    if (boardDetails.editing) {
      dispatch({
        type: CANCEL_BOARD_EDITION,
      })
    }

    dispatch({
      type: CHANGE_EDIT_BOARD_MODE,
      payload: {
        editing: !boardDetails.editing,
      },
    })
  }

  render() {
    const { boardId, boardsData, boardDetails } = this.props
    const currentBoard = boardDetails.board
    const editingBoard = boardDetails.editing && !this.props.location.pathname.includes("new-chart")
    const header = (
      <div className="row medium" data-tracking-location="Header">
        {editingBoard ? (
          <div className="medium-7 columns">
            <input
              type="text"
              value={boardDetails.nameEdition}
              style={{
                marginBottom: 0,
              }}
              onChange={event => {
                track("Analytics", "Change", "Header", "Board name input")
                this.props.dispatch(BoardEditionActions.updateBoardName(event.target.value))
              }}
            />
          </div>
        ) : (
          <div className="medium-10 columns">
            <div className="row">
              <div className="medium-9 columns">
                <h4
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  title={currentBoard ? currentBoard.name : ""}
                >
                  {this.props.location.pathname.includes("data-explorer") ? (
                    <span>Data Explorer</span>
                  ) : null}
                  {!this.props.location.pathname.includes("data-explorer") &&
                  (this.props.title || currentBoard)
                    ? currentBoard.name
                    : ""}
                </h4>
              </div>
              <div className="medium-3 columns text-right">
                <DatePicker />
              </div>
            </div>
          </div>
        )}
        <div className={`medium-${editingBoard ? "3 medium-offset-2" : "2"} columns text-right`}>
          {this.props.location.pathname.includes("new-chart") ? null : (
            <BoardMenu boardId={this.props.boardId} />
          )}
        </div>
      </div>
    )

    if (boardsData && boardsData.editing) {
      return (
        <ContentLayout title={header} data-tracking-sub-page="Analytics">
          <Loading />
        </ContentLayout>
      )
    }

    return (
      <ContentLayout
        data-tracking-sub-page="Analytics"
        title={
          this.props.params.board || this.props.location.pathname.includes("data-explorer")
            ? header
            : null
        }
        noBackground={
          !this.props.params.board ||
          this.props.location.pathname.includes("data-explorer") ||
          this.props.location.pathname.includes("chart-builder")
        }
        noPadding={
          !this.props.params.board ||
          this.props.location.pathname.includes("data-explorer") ||
          this.props.location.pathname.includes("chart-builder")
        }
      >
        {this.props.children}
      </ContentLayout>
    )
  }
}

export default connect(store => ({
  analytics: store.analytics,
  boardsData: store.analytics_v2.boards,
  boardDetails: store.analytics_v2.boardDetails,
}))(withRouter(AnalyticsLayout))
