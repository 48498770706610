import React from "react"
import { connect } from "react-redux"
import Select from "react-select"
import type { $Dispatcher } from "../../../../util/Types"
import type { $BuilderMetric, $ChartBuilderState } from "./consts"
import { BUILDER_METRICS, SelectStyle } from "./consts"
import * as Actions from "./actions"
import { groupBy } from "../Utils"
import { track } from "../../../../vNext/tracking"

type Props = {
  chartBuilder: $ChartBuilderState
} & $Dispatcher
export class BuilderMetricSelector extends React.Component<Props> {
  selectMetric = (newValue: { metric: $BuilderMetric }) => {
    const { dispatch } = this.props
    track("Analytics", "Select", "Data explorer", "Display", { metric: newValue?.metric?.name })
    dispatch(Actions.selectBuilderMetric(newValue.metric))
  }

  render() {
    const { chartBuilder } = this.props
    const groupByGroupName = groupBy("group")
    const groupedByGroupName = groupByGroupName(BUILDER_METRICS)
    const OPTIONS = Object.keys(groupedByGroupName).map(group => ({
      label: group,
      options: groupedByGroupName[group].map(metric => ({
        label: metric.name,
        value: metric.name,
        metric,
      })),
    }))
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div className="field-label round-left">Display</div>
        <div className="labeled-field">
          <Select
            className="react-select"
            value={{
              label: chartBuilder.selectedMetric.name,
              value: chartBuilder.selectedMetric.name,
            }}
            options={OPTIONS}
            onChange={this.selectMetric}
            formatGroupLabel={formatGroupLabel}
            styles={SelectStyle}
          />
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  chartBuilder: store.chartBuilder,
}))(BuilderMetricSelector)
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)
