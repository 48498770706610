import Base64 from "base-64"
import { push } from "react-router-redux"
import { DISPLAY_ERROR } from "../features/NotificationBar/consts"
import Dispatcher from "../Dispatcher"
import Auth from "./Auth"
import * as Store from "../stores/Store"
import type { $ErrorContext } from "../features/ErrorContext/consts"
import { REQUEST_HTTP_CALL } from "../features/NetworkManager/consts"
import { datadogRum } from "@datadog/browser-rum"
import { segmentAnalytics } from "^/vNext/Segment"
import { ProcessOutUrl } from "^/util/ProcessOutUrl"

export const APIVersion = "1.4.0.0"

export const config = {
  ProjectId: undefined,
  sandbox: false,
}

export function generateHeaders(projectId?) {
  const currentState = Store.store.getState()
  let auth

  if (!projectId) {
    projectId = currentState.projects.current_project_id
  }

  if (currentState.user.auth.loggedIn) {
    auth = `Basic ${Base64.encode(`${projectId}:${currentState.user.auth.token.token}`)}`
  }

  return {
    Accept: "application/json",
    "X-ProcessOut-Dashboard-Version": process.env.VERSION,
    "Content-Type": "application/json",
    "API-Version": APIVersion,
    "Disable-Logging": "true",
    Authorization: auth,
  }
}

/**
 * Displays an error
 * @param message
 * @param type
 * @param errorContext
 */
export function addNotification(
  message,
  type?: string | null | undefined,
  errorContext?: $ErrorContext | null | undefined,
) {
  Store.store.dispatch({
    type: DISPLAY_ERROR,
    payload: {
      message,
      errorContext,
      type: type || "error",
    },
  })
  if (type === "error")
    segmentAnalytics?.track("DISPLAYED_ERROR", {
      message,
    })
}
export function APIcall(url, method, body, callback, headers) {
  fetch(ProcessOutUrl + url, {
    method,
    headers: headers || generateHeaders(),
    body,
  })
    .then(response => {
      let json = response.json()
      json = fetchStatus(response.status, json)
      return json
    })
    .then(json => {
      if (!json.success && json.message) {
        addNotification(json.message, "error")
      }

      callback(json)
    })
    .catch(ex => {
      datadogRum.addError(ex)
    })
}

export function APIcallPromise<T = any>(
  url,
  method,
  body?,
  headers?,
  disableStatusParsing?,
  disableErrorDisplay?,
  retryWithRefreshedToken = false,
) {
  const req = {
    method,
    timeout: 30000,
    headers,
    data: body,
  }

  // we remove test- from url
  if (new RegExp(/.*\/test-proj_\/*/).test(url)) {
    url = url.replace("test-", "")
  }

  return new Promise<T>((resolve, reject) => {
    Store.store.dispatch({
      type: REQUEST_HTTP_CALL,
      payload: {
        url,
        request: req,
        forceTokenRefresh: retryWithRefreshedToken,
        retryWithRefreshedToken,
        resolve,
        reject,
        disableStatusParsing,
        disableErrorDisplay,
      },
    })
  })
}
export function fetchStatus(status, json?) {
  switch (status) {
    case 200: {
      return json
    }

    case 401: {
      Auth.logout()
      return json
    }

    case 412: {
      Dispatcher.dispatch({
        type: "TWOFACTOR_NEEDED",
      })
      return json
    }

    case 404: {
      Store.store.dispatch(push("/404"))
      return json
    }

    case 417: {
      return { ...json, not_permitted: true }
    }

    default:
      return json
  }
}
export const NETWORK_ERROR = "Network Error"
