import UniqId from "uniqid"
import DeepMerge from "../../util/DeepMerge"
import type { $Declaration, $RoutingRule, $RoutingRulesSettings, $VelocityRule } from "./consts"
import { $Filter } from "./RoutingRulesBuilder/Filter/consts"

/**
 * Computes a tag list from conditions
 *
 * @param conditions
 */
export function computeRuleTags(
  conditions: Array<{
    filters: Array<$Filter & $VelocityRule>
    logical: "and" | "or"
  }>,
): Array<string> {
  const tags: Array<string> = []

  for (const condition of conditions) {
    const { filters } = condition

    for (const filter of filters) {
      tags.push(
        `${filter.path}${
          filter.path === "velocity" ? `(${filter.velocityPath} within ${filter.interval})` : ""
        } ${filter.operand} ${filter.value}`,
      )
    }
  }

  return tags
}
export function parseSmartRoutingGateway(gateway: string): any {
  let g = "processout"
  if (gateway.includes("processout1")) {
    g = "processout1"
  }

  if (gateway.includes("gway_")) {
    // Case where only some gateways are checked
    const gatewaysString = gateway.replace(g, "").replace("[", "").replace("]", "")
    const gateways = gatewaysString.split(" ")
    return {
      id: UniqId(),
      gateway: g,
      configurations: gateways,
    }
  }

  // Basic smart routing
  return {
    id: UniqId(),
    gateway: g,
    configurations: ["all"],
  }
}
export function areRulesEqual(ruleA: $RoutingRule, ruleB: $RoutingRule): boolean {
  // Check tags
  if (ruleA.tags.join("") !== ruleB.tags.join("")) return false

  // Check route types
  if (ruleA.declaration !== ruleB.declaration) return false
  // Check if trigger rule and run for card verifications
  if (
    ruleA.declaration === "trigger_3ds" &&
    ruleA.run_for_card_verifications !== ruleB.run_for_card_verifications
  )
    return false
  // Check global filters length
  if (ruleA.conditions.length !== ruleB.conditions.length) return false
  const filtersA = ruleA.conditions[0].filters as any
  const filtersB = ruleB.conditions[0].filters as any
  // Check numbers of filters
  if (filtersA.length !== filtersB.length) return false

  // Check each filter
  for (let i = 0; i < filtersA.length; i++) {
    if (filtersA[i].interval) {
      // Velocity rule
      if (filtersA[i].interval !== filtersB[i].interval) return false
      if (filtersA[i].velocityPath !== filtersB[i].velocityPath) return false
      if (filtersA[i].value[0] !== filtersB[i].value[0]) return false
      if (filtersA[i].operand !== filtersB[i].operand) return false
      continue
    }

    // Normal filters
    if (filtersA[i].path !== filtersB[i].path || filtersA[i].operand !== filtersB[i].operand)
      return false
    // Check filter values
    if (filtersA[i].value.length !== filtersB[i].value.length) return false

    for (let j = 0; j < filtersA[i].value.length; j++) {
      if (filtersA[i].value[j] !== filtersB[i].value[j]) return false
    }
  }

  // Check gateways
  if (ruleA.gateways.length !== ruleB.gateways.length) return false

  for (let i = 0; i < ruleA.gateways.length; i++) {
    if (ruleA.gateways[i].gateway === "processout") {
      // Smart routing
      if (ruleB.gateways[i].gateway !== "processout") return false
      if (ruleA.gateways[i].configurations.length !== ruleB.gateways[i].configurations.length)
        return false

      for (let j = 0; j < ruleA.gateways[i].configurations.length; j++) {
        if (ruleA.gateways[i].configurations[j] !== ruleB.gateways[i].configurations[j])
          return false
      }

      continue
    }
    if (ruleA.gateways[i].gateway === "processout1") {
      // Smart routing
      if (ruleB.gateways[i].gateway !== "processout1") return false
      if (ruleA.gateways[i].configurations.length !== ruleB.gateways[i].configurations.length)
        return false

      for (let j = 0; j < ruleA.gateways[i].configurations.length; j++) {
        if (ruleA.gateways[i].configurations[j] !== ruleB.gateways[i].configurations[j])
          return false
      }

      continue
    }

    // Static gateway
    if (ruleA.gateways[i].gateway !== ruleB.gateways[i].gateway) return false
  }

  return true
}
export function getDynamicRulesFormatted(dynamicRules: any, from: string) {
  if (from === "save") {
    let arrayOfDynamicRulesObjectFormatted = []

    if (dynamicRules.filter(el => el.path === "sca_exemption_reason").length) {
      const sca_exemption_reason = dynamicRules.find(el => el.path === "sca_exemption_reason").value
      arrayOfDynamicRulesObjectFormatted.push({
        sca_exemption_reason,
      })
    }

    if (dynamicRules.filter(el => el.path === "challenge_indicator").length) {
      const challenge_indicator = dynamicRules.find(el => el.path === "challenge_indicator").value
      arrayOfDynamicRulesObjectFormatted.push({
        challenge_indicator,
      })
    }

    let newObject = {}
    arrayOfDynamicRulesObjectFormatted.forEach(obj => {
      newObject = DeepMerge(newObject, obj)
    })
    return newObject
  } else if (from === "fetch") {
    let arrayOfDynamicRulesObjectUnformatted = []

    for (const [key, value] of Object.entries(dynamicRules)) {
      if (key === "sca_exemption_reason") {
        arrayOfDynamicRulesObjectUnformatted.push({
          id: UniqId(),
          path: key,
          value: value,
        })
      }

      if (key === "challenge_indicator") {
        arrayOfDynamicRulesObjectUnformatted.push({
          id: UniqId(),
          path: key,
          value: value,
        })
      }
    }

    return arrayOfDynamicRulesObjectUnformatted
  }
}

export const getRoutingRules = (
  routingRulesSettings: $RoutingRulesSettings,
  declaration: string,
) => {
  return routingRulesSettings.rules.filter(rule => rule.declaration === declaration)
}

export function getFilterUpdateDetails(
  newRoutingRule: $RoutingRule,
  oldRoutingRule: $RoutingRule,
): { updated: boolean; added: boolean; removed: boolean } {
  let updated = false
  let added = false
  let removed = false

  if (newRoutingRule.tags.join("") !== oldRoutingRule.tags.join("")) updated = true

  // Check route types
  if (newRoutingRule.declaration !== oldRoutingRule.declaration) {
    updated = true
  }

  // Check if trigger rule and run for card verifications
  if (
    newRoutingRule.declaration === "trigger_3ds" &&
    newRoutingRule.run_for_card_verifications !== oldRoutingRule.run_for_card_verifications
  ) {
    updated = true
  }

  // Check added filter
  added = newRoutingRule.conditions[0].filters.some(n => {
    return oldRoutingRule.conditions[0].filters.findIndex(o => o.id === n.id) === -1
  })

  // Check removed filter
  removed = oldRoutingRule.conditions[0].filters.some(n => {
    return newRoutingRule.conditions[0].filters.findIndex(o => o.id === n.id) === -1
  })

  // Check filter update
  newRoutingRule.conditions[0].filters.forEach((filterA: any) => {
    const filterB = oldRoutingRule.conditions[0].filters.find(f => f.id === filterA.id) as any
    if (filterB) {
      // Velocity rule
      if (filterA.interval !== filterB.interval) updated = true
      if (filterA.velocityPath !== filterB.velocityPath) updated = true
      if (filterA.value[0] !== filterB.value[0]) updated = true
      if (filterA.operand !== filterB.operand) updated = true

      // Normal filters
      if (filterA.path !== filterB.path || filterA.operand !== filterB.operand) updated = true
      // Check filter values
      if (filterA.value.length !== filterB.value.length) updated = true

      for (let j = 0; j < filterA.value.length; j++) {
        if (filterA.value[j] !== filterB.value[j]) updated = true
      }
    }
  })

  // Check gateways
  if (newRoutingRule.gateways.length !== oldRoutingRule.gateways.length) updated = true

  for (let i = 0; i < newRoutingRule.gateways.length; i++) {
    if (newRoutingRule.gateways[i]?.gateway === "processout") {
      // Smart routing
      if (oldRoutingRule.gateways[i]?.gateway !== "processout") updated = true
      if (
        newRoutingRule.gateways[i]?.configurations?.length !==
        oldRoutingRule.gateways[i]?.configurations?.length
      )
        updated = true

      for (let j = 0; j < newRoutingRule.gateways[i]?.configurations.length; j++) {
        if (
          newRoutingRule.gateways[i]?.configurations?.[j] !==
          oldRoutingRule.gateways[i]?.configurations?.[j]
        )
          updated = true
      }

      continue
    }
    if (newRoutingRule.gateways[i]?.gateway === "processout1") {
      // Smart routing
      if (oldRoutingRule.gateways[i]?.gateway !== "processout1") updated = true
      if (
        newRoutingRule.gateways[i]?.configurations?.length !==
        oldRoutingRule.gateways[i]?.configurations?.length
      )
        updated = true

      for (let j = 0; j < newRoutingRule.gateways[i]?.configurations.length; j++) {
        if (
          newRoutingRule.gateways[i]?.configurations?.[j] !==
          oldRoutingRule.gateways[i]?.configurations?.[j]
        )
          updated = true
      }

      continue
    }

    // Static gateway
    if (newRoutingRule.gateways[i]?.gateway !== oldRoutingRule.gateways[i]?.gateway) updated = true
  }

  return { updated, added, removed }
}

/**
 * This function checks whether the removed rules are continuously trailing in routingRulesSettings.initialRules.
 * @param initialRules
 * @param removedRules
 * @returns True if removed rules contains intermediate rule
 */
const hasPositionShift = (initialRules: $RoutingRule[], removedRules: $RoutingRule[]) => {
  if (removedRules.length === 0) return false
  for (let i = initialRules.length - 1, trailingTrack = 0; i >= 0; --i) {
    // If the removed rules are not trailing continuously, it will causing other rule shifting, so no need to check further
    if (removedRules.findIndex(removedRule => removedRule.id == initialRules[i].id) === -1) {
      return true
    }
    // Early circuit break if we have checked all the removed rules
    if (++trailingTrack >= removedRules.length) return false
  }
  return false
}

/**
 * This function compare two sets of rules and produce the detail information: rule updated, rule added, rule removed, filter added, filter removed and position changed
 * Note that filter update is captured by rule updated.
 * @returns Any changes happens to the specific route type
 */
export function getRoutingRulesChangeDetails(rules: $RoutingRule[], initialRules: $RoutingRule[]) {
  const ruleAdded = new Array<$RoutingRule>()
  const ruleUpdated = new Array<$RoutingRule>()
  const filterAdded = new Array<$RoutingRule>()
  const filterRemoved = new Array<$RoutingRule>()
  const ruleRemoved = new Array<$RoutingRule>()

  for (const rule of rules) {
    const initialIndex = initialRules.findIndex(r => r.id === rule.id)
    if (initialIndex < 0) ruleAdded.push(rule)
    else {
      const detail = getFilterUpdateDetails(rule, initialRules[initialIndex])
      if (detail.updated) ruleUpdated.push(rule)
      if (detail.added) filterAdded.push(rule)
      if (detail.removed) filterRemoved.push(rule)
    }
  }

  for (const rule of initialRules) {
    const index = rules.findIndex(r => r.id === rule.id)
    if (index < 0) ruleRemoved.push(rule)
  }

  // Check order changes
  let positionChanged = rules.some((rule, idx) => {
    const foundIndex = initialRules.findIndex(r => r.id === rule.id)
    return foundIndex !== -1 && foundIndex !== idx
  })
  // If order change is true we need to further check all the removed rules are continuously trailing in initialRules.
  // Because remove intermediate rules will cause other rules shifting its position therefore it is not a real order change.
  if (positionChanged) positionChanged = !hasPositionShift(initialRules, ruleRemoved)

  return {
    ruleAdded,
    ruleUpdated,
    filterAdded,
    filterRemoved,
    ruleRemoved,
    positionChanged,
  }
}
