import React from "react"
import { connect } from "react-redux"
import { Responsive, WidthProvider } from "react-grid-layout"
import { withRouter } from "react-router"
import type { $BoardCreatorState } from "./reducer"
import type { $Chart, $DataChart } from "../Boards/consts"
import { SINGLE_VALUE } from "../Boards/consts"
import Chart from "../Boards/charts/Chart"
import Loading from "../../../components/Loader"
import Error from "../../../components/Error"
const ResponsiveGridLayout = WidthProvider(Responsive)
type Props = {
  boardCreator: $BoardCreatorState
  params: {
    project: string
  }
}

class BoardPreviewer extends React.Component {
  props: Props

  render() {
    const { boardCreator } = this.props
    if (boardCreator.fetching) return <Loading />
    if (boardCreator.error) return <Error />
    const layout = boardCreator.previewedCharts.map(
      (
        chart: {
          data: $DataChart
          chart: $Chart
        },
        index: number,
      ) => {
        // the height will simply be different if this is a single value (not compared)
        let isEmpty = false

        if (chart.data instanceof Object && Object.keys(chart.data).length === 0) {
          isEmpty = true
        }

        const height = !isEmpty && chart.chart.type === SINGLE_VALUE ? 3 : 6
        return {
          i: chart.chart.id,
          x: chart.chart.position_x,
          y: chart.chart.position_y,
          w: chart.chart.size,
          h: height,
          minH: height,
          maxH: height,
          minW: 3,
          maxW: 12,
          isDraggable: false,
          isResizable: false,
        }
      },
    )
    // For Firefox and IE we disable the use of css transforms
    // $FlowFixMe
    const isFirefox = typeof InstallTrigger !== "undefined"
    const isIE =
      /* @cc_on!@ */
      false || !!document.documentMode
    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={{
          lg: layout,
        }}
        cols={{
          lg: 12,
          md: 0,
          sm: 0,
          xs: 0,
          xxs: 0,
        }}
        breakpoints={{
          lg: 1,
          md: 0,
          sm: 0,
          xs: 0,
          xxs: 0,
        }}
        rowHeight={30}
        margin={[30, 17]}
        containerPadding={[0, 0]}
        useCSSTransforms={!isFirefox && !isIE}
        compactType="vertical"
      >
        {boardCreator.previewedCharts.map((chart: { chart: $Chart; data: $DataChart }) => (
          <div
            key={chart.chart.id}
            style={{
              maxHeight: "250px",
            }}
          >
            <Chart
              chart={{
                ...chart.chart,
                is_comparison: chart.is_comparison,
                data: chart.data,
                fetched: true,
              }}
              board={{
                board: {
                  id: "board_default-sales",
                },
              }}
              preview
              fetched
              canDelete={false}
              canEdit={false}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    )
  }
}

export default connect(store => ({
  boardCreator: store.boardCreator,
}))(withRouter(BoardPreviewer))
