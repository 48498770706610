import moment from "moment"
import { CHANGE_COMPARE_DATE, SET_COMPARING } from "../../../features/analytics/DatePicker/consts"
import type { $Action } from "../../../util/Types"
import { ANALYTICS_FILTER_CHANGE } from "^/Actions/AnalyticsActions"
type State = {
  filter: string
  interval: {
    from: any
    to: any
  }
  restriction: {
    enabled: boolean
    show: Array<string>
  }
  timeCompare: {
    from: any
    to: any
    comparing: boolean
  }
  timeInterval?: any
}
const defaultState: State = {
  filter: "",
  interval: {
    from: moment()
      .subtract(1, "month")
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .unix(),
    to: moment()
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .unix(),
  },
  restriction: {
    enabled: false,
    show: [],
  },
  timeCompare: {
    from: moment()
      .subtract(1, "month")
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .unix(),
    to: moment()
      .subtract(2, "month")
      .set({
        hour: 23,
        minute: 59,
        second: 59,
      })
      .unix(),
    comparing: false,
  },
}
export default function (
  state: State = defaultState,
  action: $Action & {
    payload: any
  },
) {
  switch (action.type) {
    case "ANALYTICS_INTERVAL_CHANGE": {
      state = {
        ...state,
        interval: {
          from: action.payload.interval.from,
          to: action.payload.interval.to,
        },
        timeInterval: action.payload.timeInterval,
      }
      break
    }

    case ANALYTICS_FILTER_CHANGE: {
      state = { ...state, filter: action.payload.filter }
      break
    }

    case "HIDE_CHARTS_EXCEPT": {
      state = {
        ...state,
        restriction: {
          enabled: true,
          show: action.payload.show,
        },
      }
      break
    }

    case "SHOW_ALL_CHARTS": {
      state = {
        ...state,
        restriction: {
          enabled: false,
          show: [],
        },
      }
      break
    }

    case CHANGE_COMPARE_DATE: {
      return { ...state, timeCompare: { ...state.timeCompare, ...action.payload } }
    }

    case SET_COMPARING: {
      return {
        ...state,
        timeCompare: { ...state.timeCompare, comparing: action.payload.comparing },
      }
    }
  }

  return state
}
