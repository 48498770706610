import { $Period, $TelescopeMetricData, $TimespanMetrics, METRICS_FORMULA } from "./consts"
import moment from "moment/moment"

export function getTimespanMetricFromPeriod(period?: $Period): $TimespanMetrics {
  switch (period) {
    case "1w":
      return "weekly"

    case "1m":
      return "monthly"

    case "3m":
      return "quarterly"

    default:
      return "weekly"
  }
}

export function getPercentageEvolution(oldNumber: number, newNumber: number, unit: string) {
  const difference = newNumber - oldNumber

  if (unit === "percentage") {
    if (difference > 0) {
      return Math.abs(difference)
    }

    if (difference < 0) {
      return -Math.abs(difference)
    }

    return difference
  }

  return difference / oldNumber
}

export function getFontColor(number: number) {
  if (number > 0) {
    return "#327B71"
  }

  if (number < 0) {
    return "#DA3116"
  }

  return "#565656"
}

// return 0 if 0.00001 because formattedNumber return 0.00% while the value is not equal 0
export function getEvolution(value: number) {
  const numberToString = String(value)
  const regex = numberToString.match(new RegExp(/\.0{4,}/))

  if (!value || regex) {
    return 0
  }

  return value
}

export function getFormula(metric: string) {
  if (metric) {
    return METRICS_FORMULA.find(metrics => metrics.name === metric)
  }
}

export function setFirstLetterToUppercase(word: string) {
  if (word === undefined) return word
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function sliceIntoChunks(arr: Array<$TelescopeMetricData>, chunkSize: number, size: number) {
  const res = []

  if (!size) {
    res.push(arr)
  } else {
    res.push(arr.slice(0, chunkSize), arr.slice(chunkSize))
  }

  return res
}

export const sortAsc = (field: string) => {
  return (a, b) => {
    if (a[field] > b[field]) {
      return 1
    } else if (a[field] < b[field]) {
      return -1
    }

    return 0
  }
}

export const sortDesc = (field: string) => {
  return (a, b) => {
    if (a[field] < b[field]) {
      return 1
    } else if (a[field] > b[field]) {
      return -1
    }

    return 0
  }
}

export const getStartOfCurrentDayUtcMoment = () => moment().utc().startOf("day")

export const getEndOfPreviousDayUtcMoment = (): moment.Moment =>
  moment().utc().subtract(1, "day").endOf("day")

export const getStartDateUtcUnixFromTimespan = (timespan: $TimespanMetrics): number => {
  return getStartDateUtcMomentFromTimespan(timespan).unix()
}

export const getEndDateUtcUnix = (): number => {
  return getEndDateUtcMoment().unix()
}

export const getStartDateUtcMomentFromTimespan = (timespan: $TimespanMetrics): moment.Moment => {
  switch (timespan) {
    case "weekly": {
      return getStartOfCurrentDayUtcMoment().subtract(1, "weeks")
    }

    case "monthly": {
      return getStartOfCurrentDayUtcMoment().subtract(1, "months")
    }

    case "quarterly": {
      return getStartOfCurrentDayUtcMoment().subtract(1, "quarters")
    }
  }
}

export const getEndDateUtcMoment = (): moment.Moment => getEndOfPreviousDayUtcMoment()

export const getStartDateUtcFromPreviousPeriod = (timespan: $TimespanMetrics): number => {
  switch (timespan) {
    case "weekly": {
      return getStartOfCurrentDayUtcMoment().subtract(2, "weeks").unix()
    }
    case "monthly": {
      return getStartOfCurrentDayUtcMoment().subtract(2, "months").unix()
    }
    case "quarterly": {
      return getStartOfCurrentDayUtcMoment().subtract(2, "quarters").unix()
    }
  }
}

export const getEndDateUtcFromPreviousPeriod = (timespan: $TimespanMetrics): number => {
  switch (timespan) {
    case "weekly": {
      return getEndOfPreviousDayUtcMoment().subtract(1, "weeks").unix()
    }
    case "monthly": {
      return getEndOfPreviousDayUtcMoment().subtract(1, "months").unix()
    }
    case "quarterly": {
      return getEndOfPreviousDayUtcMoment().subtract(1, "quarters").unix()
    }
  }
}

export const getStartOfTheMonthUtcUnix = () => moment().utc().startOf("month").unix()
