import React from "react"
import { connect } from "react-redux"
import { replace } from "react-router-redux"
import Auth from "../util/Auth"
import Loader from "../components/Loader"
import {
  REQUEST_DASHBOARD_PREPARATION,
  REQUEST_DASHBOARD_PREPARATOR_RESET,
} from "../features/Preparator/consts"
import Error from "../components/Error"
import type { $Dispatcher, $RouterLocation, $State } from "^/util/Types"
import type { UserState } from "^/Types/User"
import persistedDataHandler from "../vNext/persistedDataHandler"

type Props = {
  location: $RouterLocation
  user: UserState
  preparator: $State
} & $Dispatcher

class Projects extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    document.title = "Projects | ProcessOut"

    if (this.props.location.query.logout) {
      Auth.logout()
      this.context.router.replace("/login")
    }
  }

  componentDidMount() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
    this.props.dispatch({
      type: REQUEST_DASHBOARD_PREPARATOR_RESET,
    })
    this.requestDashboardPreparation(this.props)
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.requestDashboardPreparation(nextProps)
  }

  requestDashboardPreparation(props: Props) {
    const { user, preparator, dispatch } = props
    if (!user.details.fetched || preparator.error || preparator.fetching || preparator.fetched)
      return
    dispatch({
      type: REQUEST_DASHBOARD_PREPARATION,
      payload: {
        projectId: this.props.location.query.target || persistedDataHandler.getProjectId(),
        email: user.details.email,
      },
    })
  }

  render() {
    const { preparator } = this.props
    let content
    if (preparator.error) content = <Error />
    else {
      content = (
        <div className="row">
          <div className="large-12 columns">
            <div
              className="row large-margin-bottom"
              style={{
                marginTop: "2em",
              }}
            >
              <div className="large-12 columns text-center">
                <Loader size={48} />
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns text-center">
                <h4 className="bold greyed">Preparing your dashboard</h4>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <div>{content}</div>
  }
}

export default connect(store => ({
  user: store.user,
  projects: store.projects,
  replace,
  currentProject: store.currentProject,
  preparator: store.preparator,
}))(Projects)
