import React from "react"
import { connect } from "react-redux"
import ShortId from "shortid"
import NotificationCenter from "../../features/NotificationBar/NotificationCenter"
import { CLOSE_MODAL } from "^/util/Types"
export class Modal extends React.Component {
  constructor() {
    super()
    this.state = {
      hide: true,
      id: ShortId.generate(), // we generate a unique id for this modal as multiple instances can be run simultaneously
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      hide: !nextProps.modal.open,
    })
    this.updateModalId()
  }

  componentDidUpdate(prevProps, prevState) {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick)
  }

  handleOutsideClick = event => {
    if (
      this._modal &&
      !this._modal.contains(event.target) &&
      !this.state.hide &&
      !event.target.className.includes("Select")
    ) {
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }
  }

  /*
   * updateModalId is called when the content of the modal changes (i.e. another modal has been opened)
   */
  updateModalId() {
    this.setState({
      id: ShortId.generate(),
    })
  }

  closeModal(event) {
    if (event.target.className.includes("modal-closing")) {
      this.props.dispatch({
        type: CLOSE_MODAL,
      })
    }
  }

  render() {
    const { modal } = this.props
    return (
      <div key={this.state.id} className="modal-window">
        <div className="row collapse">
          <div
            className={`medium-${
              modal.size ? modal.size : modal.large ? 9 : 4
            } medium-centered columns window`}
            ref={el => {
              this._modal = el
            }}
          >
            <div className="row collapse window-header">{modal.header}</div>
            <div className="row collapse window-content">
              <div className="large-12 columns">
                <div className="row">
                  <div className="large-12 columns">
                    <NotificationCenter source="modal" />
                  </div>
                </div>
                <div className="row">
                  <div className="large-12 columns">{modal.content}</div>
                </div>
              </div>
            </div>
            <div className="row collapse window-footer">{modal.footer}</div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  modal: store.modal,
}))(Modal)
