import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { push } from "react-router-redux"
import type { $Dispatcher, $RouterLocation } from "../../util/Types"
import { requestConfirmation } from "../ConfirmModal/actions"
import * as Actions from "./actions"
type Props = {
  urlBase: string
  isEditorMode: boolean
  rulesHaveBeenEdited: boolean
  location: $RouterLocation
} & $Dispatcher
export class RoutingRulesModeSwitcher extends React.Component<Props> {
  onButtonClick = () => {
    const { dispatch, location, urlBase, isEditorMode, rulesHaveBeenEdited } = this.props
    const newUrl = isEditorMode ? urlBase : `${urlBase}/editor`

    const redirect = () => {
      dispatch(Actions.resetRoutingRules())
      dispatch(push(newUrl))
    }

    rulesHaveBeenEdited
      ? dispatch(
          requestConfirmation(
            <div className="row">
              <div className="large-11 columns large-centered">
                Leaving the page will reset your changes. Are you sure you want to proceed?
              </div>
            </div>,
            () => {
              redirect()
            },
            () => {},
          ),
        )
      : redirect()
  }

  render() {
    const { location, isEditorMode } = this.props
    const buttonText = isEditorMode ? "Use routing rules builder" : "Edit routing rules as code"
    return (
      <a onClick={this.onButtonClick} className="round border button small right">
        {buttonText}
      </a>
    )
  }
}
export default connect(store => ({
  routingRulesMode: store.routingRulesSettings.routingRulesMode,
  rulesHaveBeenEdited: store.routingRulesSettings.rulesHaveBeenEdited,
}))(withRouter(RoutingRulesModeSwitcher))
