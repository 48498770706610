import React from "react"
import { connect } from "react-redux"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import Markdown from "react-markdown"
import type { $BoardCreatorState } from "./reducer"
import type { $Dispatcher } from "../../../util/Types"
import { BOARDS_PRESETS } from "./consts"
import * as Actions from "./actions"
import SearchBar from "./SearchBar"
import Loading from "../../../components/Loader"
import type { $BoardPreset } from "./consts"
import { track } from "../../../vNext/tracking"
type Props = {
  boardCreator: $BoardCreatorState
} & $Dispatcher

class Presets extends React.Component {
  props: Props
  selectPreset = (preset: $BoardPreset) => {
    if (
      !this.props.boardCreator.selectedPreset ||
      this.props.boardCreator.selectedPreset.name !== preset.name
    ) {
      this.props.dispatch(Actions.selectBoardPreset({ ...preset }))
    }
  }
  savePreset = () => {
    this.props.dispatch(Actions.saveBoardPreset(this.props.boardCreator.selectedPreset))
  }

  render() {
    const { boardCreator } = this.props
    return (
      <div className="row boards-presets">
        <div className="large-12 columns">
          <div className="row">
            <div className="large-12 columns no-padding">
              <SearchBar />
            </div>
          </div>
          <div className="row">
            <div
              className="medium-6 columns"
              style={{
                borderRight: "1px solid #eaeaea",
                background: "#f2f2f2",
              }}
            >
              <ReactCSSTransitionGroup
                transitionName="preset-transition"
                transitionEnterTimeout={150}
                transitionLeaveTimeout={150}
              >
                {BOARDS_PRESETS.filter(
                  preset =>
                    !boardCreator.presetSearch ||
                    preset.name
                      .toLocaleLowerCase()
                      .includes(boardCreator.presetSearch.toLocaleLowerCase()),
                ).map(preset => (
                  <div
                    className={`row board-preset${
                      boardCreator.selectedPreset &&
                      boardCreator.selectedPreset.name === preset.name
                        ? " active"
                        : ""
                    }`}
                    key={preset.name}
                    onClick={() => {
                      track("Analytics", "Click", "Preset", "Create a brand new board", {
                        presetName: preset.name,
                      })
                      this.selectPreset(preset)
                    }}
                  >
                    <div className="merdium-12 columns">{preset.name}</div>
                  </div>
                ))}
              </ReactCSSTransitionGroup>
            </div>
            <div className="medium-6 columns description">
              {boardCreator.selectedPreset ? (
                <div className="row">
                  <div className="medium-12 columns">
                    <div className="row small-margin-bottom">
                      <div className="medium-12 columns preset-name">
                        <h4>{boardCreator.selectedPreset.name}</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="large-12 columns"
                        style={{
                          height: "10em",
                        }}
                      >
                        <Markdown source={boardCreator.selectedPreset.description} />
                      </div>
                    </div>
                    <div className="row">
                      {boardCreator.saving ? (
                        <div className="medium-3 medium-offset-9 columns text-right">
                          <Loading size={16} />
                        </div>
                      ) : (
                        <div className="medium-12 columns text-right">
                          <a
                            data-auto-tracking={true}
                            className="round main button small"
                            onClick={this.savePreset}
                            style={{
                              margin: 0,
                              position: "relative",
                              bottom: "1em",
                            }}
                          >
                            Use this preset →
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  boardCreator: store.boardCreator,
}))(Presets)
