/**
 * Created by jeremylejoux on 24/04/17.
 */
import React from "react"
type Props = {
  text?: string
}
export default class Error extends React.Component {
  props: Props

  render() {
    const { text } = this.props
    return (
      <div
        className="loader opacity0-8"
        style={{
          textAlign: "center",
        }}
        data-testid="error"
      >
        <div className="row margin-bottom">
          <div className="large-6 columns large-centered">
            <img
              alt="Warning cone icon"
              src="//dashboard.processout.com/images/cone.png"
              style={{
                height: "50px",
                opacity: 0.4,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="large-6 columns large-centered greyed bold">
            {text || (
              <span>
                An error occured while fetching data.
                <br />
                Please try again later.
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}
