import { put, select, take, takeEvery, takeLatest } from "redux-saga/effects"
import type { $Chart, $ChartType, $FetchParams } from "../Boards/consts"
import type { $Params } from "../ChartPreviewer/consts"
import {
  FETCH_CHART_PREVIEW,
  REQUEST_CHART_PREVIEW,
  REQUEST_CHART_SAVE,
} from "../ChartPreviewer/consts"
import * as Actions from "../ChartPreviewer/actions"
import { replaceForGwayKeys } from "../Boards/charts/utils"
import * as BoardActions from "../Boards/actions"
import { datadogRum } from "@datadog/browser-rum"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"

type Action = {
  type: string
  payload: {
    name: string
    boardId: string
    formula: string
    description: string
    type: $ChartType
    unit: string
    size: number
    plotted: string
    params: $FetchParams
  }
}
export function* fetchChartPreview(action: Action): Generator<any, any, any> {
  try {
    yield put({
      type: typePending(FETCH_CHART_PREVIEW),
    })
    const { name, description, boardId, formula, unit, size, type, plotted } = action.payload
    const chart: $Chart = {
      name,
      description,
      type,
      settings: {
        formula,
        // FIXME: should be plotted_fields
        // @ts-ignore
        plotted_field: plotted,
      },
      size,
      unit,
      preview: true,
    }
    const chartResult = yield put.resolve(
      Actions.fetchPreviewedChart(chart, boardId, action.payload.params),
    )
    const chartData = chartResult.value.data
    // we fetch the gateway ids and names list from the store.
    let gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)

    // if we don't have them we wait for it
    while (!gway_configurations_names.fetched) {
      yield take()
      gway_configurations_names = yield select<any>(store => store.gateway_configurations_names)
    }

    // we update the charts keys with gateway names
    chartData.data = replaceForGwayKeys(
      chartData.data,
      gway_configurations_names.gateway_configuration_names,
    )
    const payload = {
      chart: {
        ...chartResult.value.data.chart,
        fetched: true,
        data: chartData.data,
        is_comparison: chartResult.value.data.is_comparison,
      },
    }
    yield put({
      type: chartResult.value.data.success
        ? typeFulfilled(FETCH_CHART_PREVIEW)
        : typeFailed(FETCH_CHART_PREVIEW),
      payload,
    })
  } catch (error) {
    yield put({
      type: typeFailed(FETCH_CHART_PREVIEW),
    })
  }
}
type $SaveAction = {
  type: string
  payload: {
    projectId: string
    name: string
    boardName: string
    boardId: string
    formula: string
    type: $ChartType
    unit: string
    size: number
    description: string | null | undefined
    plotted: string
    params: $Params
    id?: string | null | undefined
    // if non null the chart will be edited
    position?: {
      x: number
      y: number
    }
    height: number
  }
}

function* saveChart(action: $SaveAction): Generator<any, any, any> {
  try {
    const { name, description, boardId, formula, unit, size, type, plotted, height, id, position } =
      action.payload
    const localLayout = yield select<any>(store => store.analytics_v2.boardDetails.localLayout)
    let positionY = 0
    let positionX = 0

    if (!position) {
      for (const entry in localLayout) {
        if (localLayout[entry].y > 0) positionY = localLayout[entry].y
      }

      positionY += size
    } else {
      positionY = position.y
      positionX = position.x
    }

    const chart = {
      id: id || "",
      name,
      description: description || "",
      type,
      settings: {
        formula,
        plotted_field: plotted,
      },
      size,
      position_x: positionX,
      position_y: positionY,
      margin_left: 0,
      on_new_line: false,
      unit,
      preview: false,
      is_comparison: undefined,
      created_at: undefined,
      board_id: boardId,
      x_axis_format: undefined,
      height,
    }
    yield put.resolve(Actions.saveChart(chart, boardId, action.payload.params))
    yield put(BoardActions.requestBoardsFetch(boardId))
  } catch (error) {
    datadogRum.addError(error)
  }
}

export default function* watchChartPreviewRequest(): Generator<any, any, any> {
  yield takeLatest(REQUEST_CHART_PREVIEW, fetchChartPreview)
  yield takeEvery(REQUEST_CHART_SAVE, saveChart)
}
