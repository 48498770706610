import React from "react"
import type { $PermissionGroup } from "./consts"
import PermissionFeature from "./PermissionFeature"
import type { $PermissionsState } from "./reducer"
type Props = {
  permissions: $PermissionsState
  group: $PermissionGroup
}
export class PermissionGroupContent extends React.Component<Props> {
  render() {
    const { permissions, group } = this.props
    return (
      <div className="row">
        <div className="medium-4 columns">
          <h5 className="greyed">This group can</h5>
        </div>
        <div className="medium-8 columns">
          <div className="row small-margin-bottom greyed">
            <div className="medium-4 columns">URL</div>
            <div className="medium-8 columns">
              <div className="row">
                <div className="medium-6 columns text-center">Read</div>
                <div className="medium-6 columns text-center">Edit</div>
              </div>
            </div>
          </div>
          {permissions.permissionGroups.permissionFeatures
            .sort((a, b) => (b.name < a.name ? 1 : -1))
            .map(feature => (
              <PermissionFeature
                key={`${group.name}—${feature.id}`}
                feature={feature}
                permissionGroupName={group.name}
                permissions={group.permissions.allow}
              />
            ))}
        </div>
      </div>
    )
  }
}
export default PermissionGroupContent
