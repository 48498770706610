import { call, put, select, take, takeEvery } from "redux-saga/effects"
import moment from "moment"
import Base64 from "base-64"
import * as ProcessOut from "../../util/ProcessOut"
import * as Actions from "./actions"
import {
  NETWORK_BAD_REQUEST,
  NETWORK_REQUEST_FAILURE,
  NETWORK_VALIDATION_ERROR,
  REFRESH_TOKEN,
  REQUEST_HTTP_CALL,
  UPDATE_TOKENS,
} from "./consts"
import type { $Action } from "../../util/Types"
import Auth from "../../util/Auth"
import { datadogRum } from "@datadog/browser-rum"
import persistedDataHandler from "^/vNext/persistedDataHandler"

type $HTTPPayload = {
  disableStatusParsing: boolean | null | undefined
  disableErrorDisplay: boolean | null | undefined
  retryWithRefreshedToken: boolean
  url: string
  request: any
  resolve: (arg: any) => any
  reject: (arg: any) => any
}

/**
 * Make the call to refresh tokens from a valid refreshToken
 * @param refreshToken
 */
export function* refreshToken(refreshToken: string): Generator<any, any, any> {
  try {
    yield put({
      type: REFRESH_TOKEN,
    })
    const projectId = yield select<any>(store => store.projects.current_project_id)
    const headers = {
      Accept: "application/json",
      "X-ProcessOut-Dashboard-Version": process.env.VERSION,
      "Content-Type": "application/json",
      "API-Version": ProcessOut.APIVersion,
      "Disable-Logging": "true",
      Authorization: `Basic ${Base64.encode(`${projectId}:${refreshToken}`)}`,
      "X-ProcessOut-region": "aws-us-east-1",
    }
    const req = {
      method: "POST",
      timeout: 30000,
      headers,
      data: JSON.stringify({
        token: refreshToken,
      }),
    }
    const tokens = yield Actions.HTTPCall("/authenticate/refresh", req)
    persistedDataHandler.setAuthTokens({
      accessToken: tokens.data.token.token || "",
      accessTokenExpiry: tokens.data.token.expires_at || "",
      refreshToken: tokens.data.token.refresh_token || "",
      refreshTokenExpiry: tokens.data.token.refresh_token_expires_at || "",
    })
    yield put({
      type: UPDATE_TOKENS,
      payload: tokens.data,
    })
    return tokens.data
  } catch (error) {
    // refreshing the token might be a specific case as it can happen while the computer is asleep
    if (error.type) {
      switch (error.type) {
        case NETWORK_BAD_REQUEST:
        case NETWORK_REQUEST_FAILURE: {
          // In that case it will most likely fail because of NO_NETWORK which we want to retry
          return null
        }

        case NETWORK_VALIDATION_ERROR: {
          // The API responded with > 299
          Auth.logout()
          return null
        }

        default: {
          return null
        }
      }
    }

    datadogRum.addError(error)
  }
}

/**
 * Make the correct call when the request handler requests it
 * @param payload
 * @param newToken
 */
export function* makeHTTPCall(payload: $HTTPPayload, newToken?: string): Generator<any, any, any> {
  let { headers } = payload.request

  if (!headers || newToken) {
    const authDetails = yield select<any>(store => store.user.auth)
    const projectId = yield select<any>(store => store.projects.current_project_id)
    if (!headers) headers = ProcessOut.generateHeaders()
    headers.Authorization = `Basic ${Base64.encode(
      `${projectId}:${newToken || authDetails.token.token}`,
    )}`
  }

  if (headers["X-ProcessOut-region"] === "auto") {
    delete headers["X-ProcessOut-region"]
  }

  if (payload.url.includes("authenticate")) {
    headers["X-ProcessOut-region"] = "aws-us-east-1"
  }

  const request = { ...payload.request, headers }
  yield Actions.HTTPCall(
    payload.url,
    request,
    payload.disableStatusParsing,
    payload.disableErrorDisplay,
    payload.retryWithRefreshedToken,
  )
    .then(response => {
      payload.resolve(response)
    })
    .catch(error => {
      // Error
      if (error.data) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        payload.reject({
          type: NETWORK_VALIDATION_ERROR,
          details: error,
          status: error.status,
          notPermitted: error.status === 417,
        })
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        payload.reject({
          type: NETWORK_REQUEST_FAILURE,
          details: error,
        })
      } else {
        // Something happened in setting up the request that triggered an Error
        payload.reject({
          type: NETWORK_BAD_REQUEST,
          details: error,
        })
      }
    })
}

/**
 * Receives a request for a HTTP call and handles it
 * @param action
 */
export function* handleNetworkCall(action: $Action): Generator<any, any, any> {
  const { payload } = action

  // We make sure we have everything to do the call
  if (!payload) {
    return
  }

  // We wait for the store to be unlocked
  let network = yield select<any>(store => store.network)

  while (network.locked) {
    yield take()
    network = yield select<any>(store => store.network)
  }

  // We retrieve all tokens and expiry dates
  const authDetails = yield select<any>(store => store.user.auth)

  // We now check for the token expiry date
  if (payload.forceTokenRefresh || moment().isAfter(moment.unix(authDetails.token.expires_at))) {
    // Token expired or need to force refresh, we should now check for the refresh token expiry
    if (moment().isAfter(moment.unix(authDetails.refreshToken.expires_at))) {
      // Expiry token expired, user should be logged out
      // We make the call but it'll end up throwing a 401 and logging out the user
      yield makeHTTPCall(payload)
    } else {
      // Refresh token is still valid, we should use it to get a new token
      yield put(Actions.lockNetwork())
      const newTokens = yield call(refreshToken, authDetails.refreshToken.token)
      yield put(Actions.unlockNetwork())

      if (newTokens) {
        yield makeHTTPCall(payload, newTokens.token.token)
      } else {
        // Couldn't refresh the token but an error was displayed, waiting for next call
      }
    }
  } else {
    // Token is still valid
    yield makeHTTPCall(payload)
  }
}
export default function* watchForSagas(): Generator<any, any, any> {
  yield takeEvery(REQUEST_HTTP_CALL, handleNetworkCall)
}
