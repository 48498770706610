import React, { CSSProperties } from "react"
import Select from "react-select"
export type $Option = {
  label: string
  // Gateway config name
  value: string // Gateway config ID
}
type $OptionGroup = {
  label: string
  options: Array<$Option>
}
type Props = {
  options: Array<$OptionGroup>
  onChange: (arg0: $Option | null | undefined) => void
  value: Array<$Option> | null | undefined
}

class ChargebackGatewayConfigSelect extends React.Component<Props> {
  onChange = (newValue: $Option | null | undefined) => {
    this.props.onChange(newValue)
  }

  render() {
    const { value, options } = this.props
    return (
      <div
        className="labeled-field text-left"
        style={{
          width: "100%",
        }}
      >
        <Select
          className="react-select"
          formatGroupLabel={formatGroupLabel}
          isMulti={false}
          isSearchable
          onChange={this.onChange}
          options={options}
          placeholder="Select a gateway configuration"
          value={value}
          styles={{
            group: base => ({ ...base, padding: 0, marginTop: ".5em" }),
            menuList: base => ({ ...base, padding: 0 }),
          }}
        />
      </div>
    )
  }
}

export default ChargebackGatewayConfigSelect
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}
const baseGroupStyle: CSSProperties = {
  borderRadius: "2em",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
}
const programGroupStyle = {
  ...baseGroupStyle,
  backgroundColor: "white",
  border: "1px solid red",
  color: "red",
}
const safeGroupStyle = {
  ...baseGroupStyle,
  backgroundColor: "white",
  border: "1px solid green",
  color: "green",
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={data.label.includes("Belongs") ? programGroupStyle : safeGroupStyle}>
      {data.options.length}
    </span>
  </div>
)
