import groupBy from "lodash/groupBy"
import { call, select } from "redux-saga/effects"
import { isoCountries } from "^/util/ISOCountries"
import type { $ChartDatasFromApi, $PerCountryTableData, $TimespanMetrics } from "../consts"
import { getEvolution, getFormula, getPercentageEvolution } from "../util"
import { fetchTelescopeMetricDatas, fetchTelescopeMetricEvolutionDatas, loadCharts } from "./index"
import { datadogRum } from "@datadog/browser-rum"

export function* fetchAuthorizationRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorization rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      authorization_rate: chartData[0].value ?? 0,
      authorization_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      authorization_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchConversionRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Conversion rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      conversion_rate: chartData[0].value ?? 0,
      conversion_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      conversion_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetch3dsSuccessRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("3DS Success rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      success_3ds_rate: chartData[0].value ?? 0,
      success_3ds_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      success_3ds_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTotalTransactionAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Total transaction amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      total_transaction_amount: chartData[0].value ?? 0,
      total_transaction_amount_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      total_transaction_amount_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchNumberOfTransactions(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Number of transactions")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      number_of_transactions: chartData[0].value ?? 0,
      number_of_transactions_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      number_of_transactions_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchCapturesAndFees(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Capture & Fees")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      captures_and_fees: chartData[0].value ?? 0,
      captures_and_fees_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      captures_and_fees_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsAndVoids(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds & Voids")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      refunds_and_voids: chartData[0].value ?? 0,
      refunds_and_voids_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      refunds_and_voids_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchChargebacks(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Chargebacks")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      chargebacks: chartData[0].value ?? 0,
      chargebacks_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      chargebacks_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizedAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorized amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      authorized_amount_transactions: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizedTransactions(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorized transactions")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      authorized_transactions: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchDeclinedTransactions(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Declined transactions")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      declined_transactions: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchDeclinedTransactionsAmount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Declined transactions amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      declined_amount_transactions: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchDeclinedRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Declined rate")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      declined_rate: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizationRateByGateway(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorization rate by gateway")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authorization_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizationRateByCardScheme(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorization rate by card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authorization_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizationRateByCardType(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorization rate by card type")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authorization_rate_by_card_type: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthorizationRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authorization rate by payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authorization_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableAuthorizationDatas(period: $TimespanMetrics): Generator<
  any,
  {
    tableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const authorizationRateFormula = getFormula("Authorization rate by country")
    const numberOfTransactionFormula = getFormula("number of transaction by country")
    const amountOfTransactionFormula = getFormula("amount of transaction by country")
    const params = yield select<any>(store => store.analytics.params)
    const authorizationRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      authorizationRateFormula.chartFormula,
      params,
      period,
    )
    const authorizationRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      authorizationRateFormula.chartFormula,
      period,
    )
    const numberOfTransactionChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfTransactionFormula.chartFormula,
      params,
      period,
    )
    const amountOfTransactionChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfTransactionFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = authorizationRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = authorizationRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfTransactionChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfTransactionChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const tableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          authorizationRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      tableData: tableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetch3DSTriggerRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("3DS trigger rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      domains_secure_trigger_rate: chartData[0].value ?? 0,
      domains_secure_trigger_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableDomainsSecureDatas(period: $TimespanMetrics): Generator<
  any,
  {
    tableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const authenticationRateFormula = getFormula("Authentication rate per country")
    const numberOfTransaction3DSFormula = getFormula(
      "number of transaction by country triggered by 3DS",
    )
    const amountOfTransaction3DSFormula = getFormula(
      "amount of transaction by country triggered by 3DS",
    )
    const params = yield select<any>(store => store.analytics.params)
    const authorizationRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      authenticationRateFormula.chartFormula,
      params,
      period,
    )
    const authorizationRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      authenticationRateFormula.chartFormula,
      period,
    )
    const numberOfTransactionChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfTransaction3DSFormula.chartFormula,
      params,
      period,
    )
    const amountOfTransactionChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfTransaction3DSFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = authorizationRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = authorizationRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfTransactionChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfTransactionChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const tableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          authenticationRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      tableData: tableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchCapturedTransactionsRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Captured transactions rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      captured_transactions_rate: chartData[0].value ?? 0,
      captured_transactions_rate_evolution:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      captured_transactions_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchCapturedTransactionsCount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Captured transactions count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      captured_transactions_count: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchCapturedTransactionsAmount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Captured transactions amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      captured_transactions_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      failed_captures_rate: chartData[0].value ?? 0,
      failed_captures_rate_evolution:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      failed_captures_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesCount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_captures_count: chartData[0].value ?? 0,
      failed_captures_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      failed_captures_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      voided_transactions_rate: chartData[0].value ?? 0,
      voided_transactions_rate_evolution:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      voided_transactions_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsCount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      voided_transactions_count: chartData[0].value ?? 0,
      voided_transactions_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      voided_transactions_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableFailedCapturesDatas(period: $TimespanMetrics): Generator<
  any,
  {
    failedCapturesTableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const failedCapturesByCountryRateFormula = getFormula("Failed captures rate per country")
    const numberOfFailedCapturesByCountryFormula = getFormula(
      "Number of failed captures by country",
    )
    const amountOfFailedCapturesByCountryFormula = getFormula(
      "Amount of failed captures by country",
    )
    const params = yield select<any>(store => store.analytics.params)
    const failedCapturesRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      failedCapturesByCountryRateFormula.chartFormula,
      params,
      period,
    )
    const failedCapturesRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      failedCapturesByCountryRateFormula.chartFormula,
      period,
    )
    const numberOfFailedCapturesChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfFailedCapturesByCountryFormula.chartFormula,
      params,
      period,
    )
    const amountOfFailedCapturesChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfFailedCapturesByCountryFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = failedCapturesRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = failedCapturesRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfFailedCapturesChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfFailedCapturesChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const failedCapturesTableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          failedCapturesByCountryRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      failedCapturesTableData: failedCapturesTableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesRateByGateway(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures rate by gateway")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_captures_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesRateByCardScheme(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures rate by card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_captures_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedCapturesRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed captures rate by payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_captures_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableVoidedTransactionsDatas(period: $TimespanMetrics): Generator<
  any,
  {
    voidedTransactionsTableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const voidedTransactionsByCountryRateFormula = getFormula(
      "Voided transactions rate per country",
    )
    const numberOfVoidedTransactionsByCountryFormula = getFormula(
      "Number of voided transactions by country",
    )
    const amountOfVoidedTransactionsByCountryFormula = getFormula(
      "Amount of voided transactions by country",
    )
    const params = yield select<any>(store => store.analytics.params)
    const voidedTransactionsRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      voidedTransactionsByCountryRateFormula.chartFormula,
      params,
      period,
    )
    const voidedTransactionsRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      voidedTransactionsByCountryRateFormula.chartFormula,
      period,
    )
    const numberOfVoidedTransactionsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfVoidedTransactionsByCountryFormula.chartFormula,
      params,
      period,
    )
    const amountOfVoidedTransactionsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfVoidedTransactionsByCountryFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = voidedTransactionsRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = voidedTransactionsRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfVoidedTransactionsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfVoidedTransactionsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const voidedTransactionsTableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          voidedTransactionsByCountryRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      voidedTransactionsTableData: voidedTransactionsTableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsRateByGateway(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions rate by gateway")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      voided_transactions_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsRateByCardScheme(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions rate by card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      voided_transactions_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchVoidedTransactionsRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Voided transactions rate by payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      voided_transactions_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFeesRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Fees rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      fees_rate: chartData[0].value ?? 0,
      fees_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      fees_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFeesAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Fees amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      fees_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableFeesDatas(period: $TimespanMetrics): Generator<
  any,
  {
    feesTableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const feesByCountryRateFormula = getFormula("Fees rate per country")
    const numberOfFeesByCountryFormula = getFormula("Number of fees by country")
    const amountOfFeesByCountryFormula = getFormula("Amount of fees by country")
    const params = yield select<any>(store => store.analytics.params)
    const feesRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      feesByCountryRateFormula.chartFormula,
      params,
      period,
    )
    const feesRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      feesByCountryRateFormula.chartFormula,
      period,
    )
    const numberOfFeesChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfFeesByCountryFormula.chartFormula,
      params,
      period,
    )
    const amountOfFeesChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfFeesByCountryFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = feesRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = feesRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfFeesChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfFeesChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const feesTableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          feesByCountryRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      feesTableData: feesTableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      refunds_rate: chartData[0].value ?? 0,
      refunds_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      refunds_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      refunds_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsnumber(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds number")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      refunds_number: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsRate(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds rate")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    const chartDataEvolution = yield call(
      fetchTelescopeMetricEvolutionDatas,
      formula.formula,
      period,
    )
    return {
      failed_refunds_rate: chartData[0].value ?? 0,
      failed_refunds_rate_increase:
        getPercentageEvolution(chartDataEvolution[0].value, chartData[0].value, formula.unit) ?? 0,
      failed_refunds_rate_chart: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsAmount(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      failed_refunds_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsnumber(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds number")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      failed_refunds_number: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableRefundsDatas(period: $TimespanMetrics): Generator<
  any,
  {
    refundsTableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const refundsByCountryRateFormula = getFormula("Refunds rate per country")
    const numberOfRefundsByCountryFormula = getFormula("Number of refunds by country")
    const amountOfRefundsByCountryFormula = getFormula("Amount of refunds by country")
    const params = yield select<any>(store => store.analytics.params)
    const refundsRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      refundsByCountryRateFormula.chartFormula,
      params,
      period,
    )
    const refundsRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      refundsByCountryRateFormula.chartFormula,
      period,
    )
    const numberOfRefundsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfRefundsByCountryFormula.chartFormula,
      params,
      period,
    )
    const amountOfRefundsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfRefundsByCountryFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = refundsRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = refundsRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfRefundsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfRefundsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const refundsTableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          refundsByCountryRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      refundsTableData: refundsTableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsRateByGateway(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds rate per payment provider")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      refunds_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsRateByCardScheme(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds rate per card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      refunds_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchRefundsRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Refunds rate per payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      refunds_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchTableFailedRefundsDatas(period: $TimespanMetrics): Generator<
  any,
  {
    failedRefundsTableData: Array<$PerCountryTableData>
  },
  any
> {
  try {
    const failedRefundsByCountryRateFormula = getFormula("Failed refunds rate per country")
    const numberOfFailedRefundsByCountryFormula = getFormula("Number of failed refunds by country")
    const amountOfFailedRefundsByCountryFormula = getFormula("Amount of failed refunds by country")
    const params = yield select<any>(store => store.analytics.params)
    const failedRefundsRateChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      failedRefundsByCountryRateFormula.chartFormula,
      params,
      period,
    )
    const failedRefundsRateChartEvolution: $ChartDatasFromApi[] = yield call(
      fetchTelescopeMetricEvolutionDatas,
      failedRefundsByCountryRateFormula.chartFormula,
      period,
    )
    const numberOfFailedRefundsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      numberOfFailedRefundsByCountryFormula.chartFormula,
      params,
      period,
    )
    const amountOfFailedRefundsChart: $ChartDatasFromApi[] = yield call(
      loadCharts,
      amountOfFailedRefundsByCountryFormula.chartFormula,
      params,
      period,
    )
    const rates: Array<{
      country: string
      rate: number
    }> = failedRefundsRateChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rate: value ? value : 0,
    }))
    const ratesEvolution: Array<{
      country: string
      rateEvolution: number
    }> = failedRefundsRateChartEvolution.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      rateEvolution: value ? value : 0,
    }))
    const counts: Array<{
      country: string
      count: number
    }> = numberOfFailedRefundsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      count: value ? value : 0,
    }))
    const amounts: Array<{
      country: string
      amount: number
    }> = amountOfFailedRefundsChart.map(({ key, value }) => ({
      country: isoCountries[key]?.full_name ?? key,
      amount: value ? value : 0,
    }))
    const groupToData = groupBy([...rates, ...counts, ...amounts, ...ratesEvolution], "country")
    const failedRefundsTableData: Array<$PerCountryTableData> = Object.values(groupToData).map(
      ([rate, count, amount, rateEvolution]) => {
        const percentageEvotution = getPercentageEvolution(
          rateEvolution?.["rateEvolution"] ?? 0,
          rate?.["rate"] ?? 0,
          failedRefundsByCountryRateFormula.unit,
        )
        const formatEvolution = getEvolution(percentageEvotution)
        return {
          ...rate,
          ...count,
          ...amount,
          rate: rate?.["rate"] ?? 0,
          count: count?.["count"] ?? 0,
          amount: amount?.["amount"] ?? 0,
          rateEvolution: formatEvolution,
        }
      },
    )
    return {
      failedRefundsTableData: failedRefundsTableData ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsRateByGateway(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds rate per payment provider")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_refunds_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsRateByCardScheme(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds rate per card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_refunds_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFailedRefundsRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed refunds rate per payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      failed_refunds_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFeesRateByGateway(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Fees rate per payment provider")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      fees_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFeesRateByCardScheme(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Fees rate per card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      fees_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchFeesRateByPaymentMethod(period: $TimespanMetrics): Generator<any, any, any> {
  try {
    const formula = getFormula("Fees rate per payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      fees_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchAuthenticationRateByGateway(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authentication rate per gateway")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authentication_rate_by_gateway: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
export function* fetchAuthenticationRateByCardScheme(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authentication rate per card scheme")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authentication_rate_by_card_scheme: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchAuthenticationRateByPaymentMethod(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authentication rate per payment method")
    const params = yield select<any>(store => store.analytics.params)
    const chart = yield call(loadCharts, formula.chartFormula, params, period)
    return {
      authentication_rate_by_payment_method: chart ?? [],
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchAuthenticatedTransactionAmount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authenticated transaction amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      authentication_transaction_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchAuthenticatedTransactionCount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Authenticated transaction count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      authentication_transaction_count: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchTriggered3dsTransactionAmount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Triggered 3DS transactions amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      triggered_3ds_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchTriggered3dsTransactionCount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Triggered 3DS transactions count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      triggered_3ds_count: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchFailedAuthenticationTransactionAmount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed authentication amount")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      failed_3ds_amount: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}

export function* fetchFailedAuthenticationTransactionCount(
  period: $TimespanMetrics,
): Generator<any, any, any> {
  try {
    const formula = getFormula("Failed authentication count")
    const chartData = yield call(fetchTelescopeMetricDatas, formula.formula, period)
    return {
      failed_3ds_count: chartData[0].value ?? 0,
    }
  } catch (error) {
    datadogRum.addError(error)
  }
}
