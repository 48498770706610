export const DIMENSIONS_MAP: Array<{
  name: string
  description: string
  field: string
  top: number | null | undefined
  strategy: string
  group: string
}> = [
  {
    name: "Error code",
    field: "error_code",
    description: "Transaction error codes: do-not-honor…",
    top: 10,
    strategy: "value_descending",
    group: "Results",
  },
  {
    name: "Payment type",
    field: "payment_type",
    description: "Transaction payment types: Card, Paypal…",
    top: 10,
    strategy: "value_descending",
    group: "Transaction parameter",
  },
  {
    name: "Currency",
    field: "currency",
    description: "Currency used for the transactions: USD, EUR, CAD…",
    top: 10,
    strategy: "value_descending",
    group: "Transaction parameter",
  },
  {
    name: "Status",
    field: "status",
    description: "Transaction status: completed, pending, failed…",
    top: 10,
    strategy: "value_descending",
    group: "Results",
  },
  {
    name: "Country",
    field: "country",
    description: "Country in which the transactions occured: FR, US, UK…",
    top: 75,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Continent",
    field: "continent",
    description: "Continent in which the transactions occured",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card scheme",
    field: "card_scheme",
    description: "Card scheme: Visa, Mastercard, Amex…",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card co-scheme",
    field: "card_co_scheme",
    description: "Card co-schemes: CB…",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card IIN/BIN",
    field: "card_iin",
    description: "Card BIN/IIN",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card type",
    field: "card_type",
    description: "Card types: Debit, Credit…",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card category",
    field: "card_category",
    description: "Card categories: ",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "Card bank",
    field: "card_bank",
    description: "Bank that issued the card used for the transactions",
    top: 10,
    strategy: "value_descending",
    group: "Card parameter",
  },
  {
    name: "CVC check status",
    field: "cvc_check",
    description: "CVC status for transactions: passed, checked…",
    top: 10,
    strategy: "value_descending",
    group: "Results",
  },
  {
    name: "Acquirer name",
    field: "acquirer_name",
    description: "Name of acquirers that handled the transactions",
    top: 10,
    strategy: "value_descending",
    group: "Gateway",
  },
  {
    name: "Gateway configuration ID",
    field: "gateway_configuration_id",
    description: "",
    top: 10,
    strategy: "value_descending",
    group: "Gateway",
  },
  {
    name: "Gateway name",
    field: "gateway_name",
    description: "Name of the gateways used: Stripe, Adyen, Braintree…",
    top: 10,
    strategy: "value_descending",
    group: "Gateway",
  },
  {
    name: "3DS status",
    field: "three_d_s_status",
    description: "Whether or not the transactions went through 3DS",
    top: 10,
    strategy: "value_descending",
    group: "Results",
  },
  {
    name: "Origin",
    field: "origin",
    description: "Origin of the transaction",
    top: 10,
    strategy: "value_descending",
    group: "Transaction parameter",
  },
  {
    name: "AVS check status",
    field: "avs_street_check",
    description: "Status of the address verification system",
    top: 10,
    strategy: "value_descending",
    group: "Results",
  },
]
