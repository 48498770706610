import { connect } from "react-redux"
import React from "react"
import type { $ChartType } from "../../Boards/consts"
import { BAR_CHART, LINE_CHART, MAP_CHART, PIE_CHART, SINGLE_VALUE } from "../../Boards/consts"
import type { $DataExplorerState } from "../reducer"
import type { $Dispatcher } from "../../../../util/Types"
import * as Actions from "./actions"
import type { $ChartBuilderState } from "^/features/analytics/DataExplorer/ChartBuilder/consts"
import { track } from "../../../../vNext/tracking"
type Props = {
  chartBuilder: $ChartBuilderState
  dataExplorer: $DataExplorerState
} & $Dispatcher
export class TypeSelection extends React.Component<Props> {
  selectType = (type: $ChartType) => {
    const { dispatch, chartBuilder, dataExplorer } = this.props
    track("Analytics", "Click", "Type selection", "Chart Type", { type })
    dispatch(Actions.selectType(type, dataExplorer.type))
  }

  render() {
    const { chartBuilder, dataExplorer } = this.props
    const canUseLineChart =
      chartBuilder.dimensions.length === 0 ||
      new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field)
    return (
      <div className="type-selection">
        <div className="row">
          <div className="medium-12 columns">
            <div
              onClick={() => {
                this.selectType(SINGLE_VALUE)
              }}
              className={`image-contain${dataExplorer.type === SINGLE_VALUE ? " selected" : ""}`}
            >
              10
            </div>

            <div
              onClick={() => {
                if (canUseLineChart) this.selectType(LINE_CHART)
              }}
              className={`image-contain${dataExplorer.type === LINE_CHART ? " selected" : ""}${
                canUseLineChart ? "" : " disabled"
              }`}
            >
              <img alt="Line chart icon" src="//dashboard.processout.com/images/line-chart2.png" />
            </div>

            <div
              onClick={() => {
                this.selectType(BAR_CHART)
              }}
              className={`image-contain${dataExplorer.type === BAR_CHART ? " selected" : ""}`}
            >
              <img alt="Bar chart icon" src="//dashboard.processout.com/images/bar-chart.png" />
            </div>

            <div
              onClick={() => {
                if (
                  chartBuilder.dimensions.length > 0 &&
                  chartBuilder.dimensions[0].field.includes("country")
                )
                  this.selectType(MAP_CHART)
              }}
              className={`image-contain${dataExplorer.type === MAP_CHART ? " selected" : ""}${
                chartBuilder.dimensions.length > 0 &&
                chartBuilder.dimensions[0].field.includes("country")
                  ? ""
                  : " disabled"
              }`}
            >
              <img alt="Map chart icon" src="//dashboard.processout.com/images/map-chart.png" />
            </div>

            <div
              onClick={() => {
                if (
                  chartBuilder.dimensions.length === 0 ||
                  !new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field)
                ) {
                  this.selectType(PIE_CHART)
                }
              }}
              className={`image-contain${dataExplorer.type === PIE_CHART ? " selected" : ""} ${
                chartBuilder.dimensions.length > 0 &&
                new RegExp(/.*_at$/).test(chartBuilder.dimensions[0].field)
                  ? "disabled"
                  : ""
              }`}
            >
              <img
                alt={`${chartBuilder.dimensions.length > 1 ? "Stacked chart" : "Pie chart"} icon`}
                src={`//dashboard.processout.com/images/${
                  chartBuilder.dimensions.length > 1 ? "stacked-chart" : "pie-chart"
                }.png`}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  dataExplorer: store.dataExplorer,
  chartBuilder: store.chartBuilder,
}))(TypeSelection)
