import { select, takeLatest } from "redux-saga/effects"
import { FETCH_USER } from "^/Actions/UserActions"
import { typeFulfilled } from "^/util/ActionUtils"

export function* identifyUser(): Generator<any, any, any> {
  const { userDetails } = yield select<any>(store => ({
    userDetails: store.user.details,
  }))

  if (userDetails.uniq_id) {
    window.jimo?.push(["set", "user:id", [userDetails.uniq_id]])
  }
}

export default function* watchJimo(): Generator<any, any, any> {
  yield takeLatest(typeFulfilled(FETCH_USER), identifyUser)
}
