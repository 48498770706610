import React from "react"
import { components } from "react-select"
import { $Operand } from "^/features/RoutingRules/RoutingRulesBuilder/Filter/consts"
import { Input } from "^/vNext/Input/Input"
import {
  VELOCITY_FIELDS,
  VELOCITY_INTERVAL_OPTIONS,
} from "../../../features/RoutingRules/RoutingRulesBuilder/Velocity/consts"
import { Select } from "../../Select/Select"
import { getOperandOptions } from "../utils"

const ValueContainer = props => {
  const { children, prefix } = props
  return (
    <components.ValueContainer {...props}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginRight: "2px",
            color: "#666568",
          }}
        >
          {prefix}
        </span>
        {children}
      </div>
    </components.ValueContainer>
  )
}

export type Props = {
  velocityPath: string
  interval: string
  operand: $Operand
  values?: any[]
  onVelocityPathChange?: (newValue: any, actionMeta: any) => void
  onIntervalChange?: (newValue: any, actionMeta: any) => void
  onOperandChange?: (newValue: any, actionMeta: any) => void
  onValueChange?: (newValue: number) => void
}

export const VelocitySelection = ({
  velocityPath,
  interval,
  operand,
  values = [],
  onVelocityPathChange,
  onIntervalChange,
  onOperandChange,
  onValueChange,
}: Props) => {
  const operandOptions = getOperandOptions("velocity", operand)
  const displayValue = operand !== "is-null" && operand !== "is-not-null"
  return (
    <>
      <Select
        aria-label={
          "velocity of " + VELOCITY_FIELDS.find(option => velocityPath === option.value)?.label
        }
        options={VELOCITY_FIELDS}
        value={VELOCITY_FIELDS.find(option => velocityPath === option.value)}
        onChange={onVelocityPathChange}
        components={{
          ValueContainer: props => <ValueContainer {...props} prefix="of " />,
        }}
      ></Select>

      <Select
        placeholder="1 minute..."
        aria-label={
          "velocity every " +
          VELOCITY_INTERVAL_OPTIONS.find(option => interval === option.value)?.label
        }
        options={VELOCITY_INTERVAL_OPTIONS}
        value={VELOCITY_INTERVAL_OPTIONS.find(option => interval === option.value)}
        onChange={onIntervalChange}
        components={{
          ValueContainer: props => <ValueContainer {...props} prefix="every " />,
        }}
      ></Select>

      <Select
        placeholder="operand..."
        aria-label={operandOptions.find(option => operand === option.value)?.label}
        options={operandOptions}
        value={operandOptions.find(option => operand === option.value)}
        onChange={onOperandChange}
      ></Select>

      {displayValue && (
        <Input
          type="number"
          placeholder="Type for custom value"
          aria-label={values[0]}
          value={values[0]}
          onChange={evt => onValueChange(Number(evt.target.value))}
        />
      )}
    </>
  )
}
