import { FETCH_INVOICES } from "^/Actions/InvoicesActions"
import type { $State } from "^/util/Types"
import type { Product } from "^/types/Product"
import { typeFailed, typeFulfilled, typePending } from "^/util/ActionUtils"
export type $InvoicesState = {
  invoices: Array<Product>
} & $State
export default function (
  // FIXME: missing invoices, but seems like it is only set when it is initialized
  // @ts-ignore
  state: $InvoicesState = {
    fetching: false,
    fetched: false,
  },
  action,
) {
  switch (action.type) {
    case typePending(FETCH_INVOICES): {
      return { ...state, error: false, fetching: true, fetched: false }
    }

    case typeFulfilled(FETCH_INVOICES): {
      if (!action.payload.success) {
        return { ...state, fetching: false, fetched: true, invoices: [] }
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        invoices: action.payload.products,
        has_more: action.payload.has_more,
      }
    }

    case typeFailed(FETCH_INVOICES): {
      return { ...state, error: action.payload, fetched: false, fetching: false }
    }
  }

  return state
}
