import React from "react"
import ReactMarkdown from "react-markdown"
import { connect } from "react-redux"
import type { $Chart } from "../../Boards/consts"
import * as ChartActions from "../../ChartPreviewer/actions"
import type { $Params } from "../../ChartPreviewer/consts"
import type { $Dispatcher } from "../../../../util/Types"
import "./textcharts.scss"
import { SELECT_CHART_FOR_DELETION } from "../../Boards/charts/consts"
type Props = {
  chart: $Chart
  content: string
  currentProject: {
    project: {
      id: string
    }
  }
  params: $Params
} & $Dispatcher
type State = {
  editing: boolean
  editedContent: string | null | undefined
}
export class TextChart extends React.Component<Props, State> {
  _container: {
    current: null | HTMLDivElement
  }

  constructor() {
    super()
    this._container = React.createRef()
    this.state = {
      editing: false,
      editedContent: null,
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick)
    document.addEventListener("dblclick", this.handleInsideClick)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick)
    document.removeEventListener("dblclick", this.handleInsideClick)
  }

  handleOutsideClick = (event: Event) => {
    // $FlowFixMe
    if (this._container.current && !this._container.current.contains(event.target)) {
      // clicked outside text area
      this.setState({
        editing: false,
        editedContent: null,
      })
    }
  }
  handleInsideClick = (event: Event) => {
    // $FlowFixMe
    if (!this._container.current || this._container.current.contains(event.target)) {
      this.setState({
        editing: true,
        editedContent: this.props.content,
      })
    }
  }
  saveChart = () => {
    const { editedContent } = this.state
    const { dispatch, chart, currentProject, params } = this.props
    dispatch(
      ChartActions.requestChartSave(
        chart.id,
        currentProject.project.id,
        chart.name,
        chart.description,
        editedContent || "",
        "text",
        chart.unit,
        chart.size,
        chart.board_id,
        chart.boardName,
        params,
        {
          x: chart.position_x,
          y: chart.position_y,
        },
        chart.height,
      ),
    )
  }
  onEdit = event => {
    this.setState({
      editedContent: event.target.value,
    })
  }
  delete = (event: Event) => {
    event.preventDefault()
    const { dispatch, chart } = this.props
    dispatch({
      type: `${SELECT_CHART_FOR_DELETION}_${chart.id}`,
    })
  }

  render() {
    const { content, boardDetails } = this.props
    const { editing, editedContent } = this.state
    return (
      <div
        className={`large-12 columns chart text-chart${
          editing || boardDetails.editing ? " editing" : ""
        }`}
        style={{
          height: "100%",
        }}
      >
        {boardDetails.editing ? (
          <div className="row text-right">
            <div className="medium-12 columns">
              <a className="chart-action" onClick={this.delete}>
                <img
                  alt="Trash icon"
                  src="//dashboard.processout.com/images/delete.png"
                  style={{
                    height: "17px",
                    opacity: 0.7,
                    position: "relative",
                    top: "1em",
                  }}
                />
              </a>
            </div>
          </div>
        ) : null}
        <div
          className="row small-margin-bottom"
          style={{
            height: "100%",
          }}
        >
          <div
            className="large-12 columns"
            style={{
              height: "100%",
            }}
          >
            <div
              ref={this._container}
              style={{
                height: "100%",
              }}
            >
              {!editing && (
                <div
                  className={`text-chart__md${boardDetails.editing ? "editing" : ""}`}
                  style={{
                    height: "100%",
                  }}
                >
                  <ReactMarkdown source={content} />
                </div>
              )}
              {editing && (
                <textarea
                  style={{
                    height: "90%",
                    width: "100%",
                    resize: "none",
                  }}
                  onChange={this.onEdit}
                  value={editedContent}
                />
              )}
            </div>
          </div>
        </div>
        {editing && editedContent !== content && (
          <div
            className="text-right"
            style={{
              position: "relative",
              top: "-6em",
              right: "2em",
            }}
          >
            <a className="small main button round" onClick={this.saveChart}>
              Save
            </a>
          </div>
        )}
      </div>
    )
  }
}
export default connect(store => ({
  params: store.analytics.params,
  currentProject: store.currentProject,
  boardDetails: store.analytics_v2.boardDetails,
}))(TextChart)
