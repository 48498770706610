import React from "react"
import Creatable from "react-select/creatable"
import { SelectStyle } from "../consts"
type $Strategy = "value_descending" | "value_ascending"
const OPTIONS = [
  {
    label: "top",
    value: "value_descending",
  },
  {
    label: "worst",
    value: "value_ascending",
  },
]
type Props = {
  value: string | null | undefined
  onChange: (newValue: $Strategy) => void
}

class StrategySelection extends React.Component<Props> {
  onChange = (newValue: { label: string; value: $Strategy }) => {
    const { onChange } = this.props
    onChange(newValue.value)
  }

  render() {
    const { value } = this.props
    return (
      <div
        className="labeled-field"
        style={{
          width: "95px",
        }}
      >
        <Creatable
          options={OPTIONS}
          value={
            OPTIONS.find(op => op.value === value) || {
              label: value,
              value,
            }
          }
          onChange={this.onChange}
          styles={SelectStyle}
        />
      </div>
    )
  }
}

export default StrategySelection
