import { combineReducers } from "redux"
import type { $TelescopeStateType } from "^/features/Telescope/reducer"
import Reducer from "../../../features/Telescope/reducer"
import Analytics, { State as $AnalyticsState } from "./AnalyticsReducer"
const dataReducer = combineReducers({
  standard: Reducer,
  analytics: Analytics,
})
export type $TelescopeState = {
  standard: $TelescopeStateType
  analytics: $AnalyticsState
}
export default dataReducer
