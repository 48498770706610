import React from "react"
import { BulletNumber } from "^/features/TelescopeOnboarding/processors/BulletNumber"
type Props = {
  configId?: string
  hide: boolean
  gatewayId: string
  projectId: string
  ext?: any
}
type State = {
  hmacKey: string
  gatewayId: string
}
export default class Second extends React.Component<Props, State> {
  constructor() {
    super()
    let c = ""

    for (; c.length < 32; ) c += Math.random().toString(36).substr(2, 1)

    this.state = {
      hmacKey: "",
      gatewayId: `gway_conf_${c}`,
    }
  }

  componentDidMount() {
    if (this.props.configId)
      this.setState({
        gatewayId: this.props.configId,
      })
  }

  hmacUpdated(event: any) {
    this.setState({
      hmacKey: event.target.value,
    })
  }

  render() {
    const { hide, projectId, configId } = this.props
    const { gatewayId } = this.state
    let input

    if (!hide) {
      input = (
        <input
          onChange={this.hmacUpdated.bind(this)}
          type="text"
          defaultValue={this.state.hmacKey}
          name="hmac_key"
          placeholder="Adyen webhook HMAC key"
          className="bottom-border processor-input"
          required
        />
      )
    } else {
      input = (
        <div>
          <input type="hidden" name="conf_id" value={gatewayId} />
          <input
            type="hidden"
            name="hmac_key"
            placeholder="Adyen webhook HMAC key"
            className="bottom-border processor-input"
            value={this.state.hmacKey}
          />
        </div>
      )
    }

    let domain = "api.processout.com"
    if (this.props.ext) domain = "transaction-intelligence.com"
    let content

    if (!hide) {
      content = (
        <div className="medium-12 columns">
          <div className="row margin-bottom">
            <div className="large-12 columns">
              <h4>Adyen configuration setup</h4>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="medium-1 columns">
              <BulletNumber value={4} />
            </div>
            <div className="medium-11 columns greyed">
              <label className="bold greyed margin-bottom">Setup the ProcessOut webhook</label>
              Within the Adyen dashboard, go to <span className="bold">Account</span>, then{" "}
              <span className="bold">Webhooks</span>. There, click on the{" "}
              <span className="bold">+ Webhooks</span> button in the{" "}
              <span className="bold">Standard Notification</span> section, press the{" "}
              <span className="bold">Add</span> button. In the <span className="bold">URL</span>{" "}
              field, add the following webhook URL:
              <pre>
                <code
                  className="webhook-url"
                  style={{
                    overflow: "auto",
                    border: "1px solid #cacaca",
                    backgroundColor: "#f4f5fb",
                  }}
                >
                  https://{domain}/handlers/webhooks/project/{projectId}/{gatewayId}
                </code>
              </pre>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="medium-1 columns">
              <BulletNumber value={5} />
            </div>
            <div className="medium-11 columns greyed">
              <label className="bold greyed margin-bottom">Make sure that</label>
              <ul>
                <li>the active checkbox is checked</li>
                <li>the Method dropbox is set to JSON.</li>
                <li>SSL Version is left to TLSv1.2</li>
                <li>Service Version is left to 1</li>
                <li>Populate SOAP action header is left unchecked.</li>
                <li>you have pressed Select All in Additional Settings.</li>
              </ul>
            </div>
          </div>
          <div className="row ">
            <div className="medium-1 columns">
              <BulletNumber value={6} />
            </div>
            <div className="medium-11 columns greyed">
              <label className="bold greyed margin-bottom">Specify your HMAC key</label>
              <div className="row margin-bottom">
                <div className="large-12 columns greyed">
                  In additional Settings, Click on Generate HMAC key, and enter it below.
                </div>
              </div>
              <div className="row margin-bottom">
                <div className="large-12 columns greyed">{input}</div>
              </div>
            </div>
          </div>
          <div className="row margin-bottom">
            <div className="medium-1 columns">
              <BulletNumber value={7} />
            </div>
            <div className="medium-11 columns greyed">
              <label className="bold greyed margin-bottom">Setup the notification webhooks</label>
              <p>
                In <span className="bold">Account</span>, go to{" "}
                <span className="bold">Webhooks</span> again, click on the{" "}
                <span className="bold">+ Webhooks</span> button, then{" "}
                <span className="bold">Report notification</span> and put the same link as given in
                step 4. Make sure that active is checked and leave everything else by default.
              </p>
            </div>
          </div>
        </div>
      )
    } else {
      content = <div>{input}</div>
    }

    return <div className="row">{content}</div>
  }
}
