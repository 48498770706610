import type { $Action } from "../../util/Types"
import { REQUEST_FETCH_VALUES_FOR_FIELD } from "./consts"
import type { $UpdateSearchBarAppliedFilterAction, $UpdateSearchBarFilterAction } from "./actions"
import {
  RESET_SEARCH_BAR_FILTER,
  SET_FILTERING_DOCUMENT,
  START_RESOURCES_SEARCH,
  UPDATE_SEARCH_BAR_APPLIED_FILTER,
  UPDATE_SEARCH_BAR_FILTER,
  WIPE_SEARCH_RESOURCES,
} from "./actions"
import { typeFailed, typeFulfilled } from "^/util/ActionUtils"
export type $Resource = {
  id: string
  name: string
}
export type $SearchBarState = {
  filter: string
  appliedFilter: string
  resources: Array<$Resource>
  resourcesFetching: boolean
  filteringDocument: string | null | undefined
  card_tokenization_method: Array<{ label: string; value: string }>
}
const defaultState: $SearchBarState = {
  appliedFilter: "",
  filter: "",
  resources: [],
  resourcesFetching: false,
  filteringDocument: null,
  card_tokenization_method: [
    {
      label: "Apple Pay",
      value: "applepay",
    },
    {
      label: "Google Pay",
      value: "googlepay",
    },
  ],
}
export default function (
  state: $SearchBarState = defaultState,
  action: $Action | $UpdateSearchBarFilterAction | $UpdateSearchBarAppliedFilterAction,
): $SearchBarState {
  switch (action.type) {
    case typeFulfilled(REQUEST_FETCH_VALUES_FOR_FIELD): {
      if (!action.payload || !action.payload.field) return state
      const { values } = action.payload
      const fields = {
        ...state,
        [action.payload.field]: values.map(value => {
          const isString = typeof value === "string"
          return {
            label: String(value),
            value: String(value),
          }
        }),
      }
      return { ...state, ...fields }
    }

    case UPDATE_SEARCH_BAR_FILTER: {
      const { payload } = action
      return { ...state, filter: payload.filter }
    }

    case UPDATE_SEARCH_BAR_APPLIED_FILTER: {
      return { ...state, appliedFilter: action.payload.filter }
    }

    case RESET_SEARCH_BAR_FILTER: {
      return { ...state, ...defaultState }
    }

    case WIPE_SEARCH_RESOURCES: {
      return { ...state, resources: [] }
    }

    case START_RESOURCES_SEARCH: {
      return { ...state, resourcesFetching: true }
    }

    case typeFulfilled(START_RESOURCES_SEARCH): {
      return { ...state, resources: action.payload.resources, resourcesFetching: false }
    }

    case typeFailed(START_RESOURCES_SEARCH): {
      return { ...state, resources: [], resourcesFetching: false }
    }

    case SET_FILTERING_DOCUMENT: {
      return { ...state, filteringDocument: action.payload.document }
    }
  }

  return state
}
