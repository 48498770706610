import React from "react"
import { connect } from "react-redux"
import { SortableElement, SortableHandle } from "react-sortable-hoc"
import type { $Dispatcher } from "../../../util/Types"
import * as Actions from "../actions"
import type { $RoutingRule } from "../consts"
import { fetchPathValues } from "./actions"
import BlockingRule from "./BlockingRule"
import DynamicRule from "./DynamicRule"
import type { $Filter } from "./Filter/consts"
import RoutingRule from "./RoutingRule"
import RuleContainer from "./RuleContainer"
import TriggerRule from "./TriggerRule"
import { track } from "^/vNext/tracking"

type Props = {
  position: number
  rule: $RoutingRule
  ruleWontMatch: boolean
  onChange: (arg0: $RoutingRule) => void
  gateway_configurations_names: {
    gateway_configuration_names: any
  }
  rulesHaveBeenEdited: boolean
} & $Dispatcher
type State = {
  collapsed: boolean
}
const DragHandle = SortableHandle(() => (
  <div className="drag-handle">
    <img alt="Drag card icon" src="//dashboard.processout.com/images/drag.png" />
    <span className="greyed">Drag to order</span>
  </div>
))

function getTrackingLocation(declaration: "route" | "block" | "trigger_3ds" | "dynamic_3ds") {
  switch (declaration) {
    case "route":
      return "Route"
    case "block":
      return "Blocking Rules"
    case "trigger_3ds":
      return "Trigger 3DS"
    case "dynamic_3ds":
      return "Dynamic Rules"
  }
}

export class Rule extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      collapsed: true,
    }
  }

  componentDidMount() {
    if (this.props.rule.new)
      this.setState({
        collapsed: false,
      })
  }

  filterChanged = (conditionIndex: number, newValue: Array<$Filter>) => {
    const newRule = { ...this.props.rule }
    newRule.conditions[conditionIndex].filters = newValue
    this.props.dispatch(Actions.updateRule(newRule.id, newRule))
  }
  uncollapse = () => {
    this.props.rule.conditions.map(condition => {
      condition.filters
        .filter(filter => {
          if (filter.path) {
            const regex = /action\("[^"]+"\)/
            const isPathMatchingActionExpression = regex.test(filter.path)
            return !isPathMatchingActionExpression
          }
        })
        .filter(filter => filter.path !== "card_tokenization_method")
        .map(filter => {
          if (filter.path) {
            this.props.dispatch(fetchPathValues(filter.path))
          }
        })
    })
    this.setState({
      collapsed: false,
    })

    track(
      "Routing Rules",
      "Click",
      getTrackingLocation(this.props.rule.declaration),
      "Collapse Button",
      {
        declaration: this.props.rule.declaration,
        rulesHaveBeenEdited: this.props.rulesHaveBeenEdited,
        collapse: false,
      },
    )
  }
  collapse = () => {
    this.setState({
      collapsed: true,
    })

    track(
      "Routing Rules",
      "Click",
      getTrackingLocation(this.props.rule.declaration),
      "Collapse Button",
      {
        declaration: this.props.rule.declaration,
        rulesHaveBeenEdited: this.props.rulesHaveBeenEdited,
        collapse: true,
      },
    )
  }

  render() {
    const { rule, position, gateway_configurations_names } = this.props
    const { collapsed } = this.state
    return (
      <div className="row" key={`rule-${rule.id}`}>
        <div className="medium-12 columns">
          {collapsed ? (
            <div
              className={`row routing-rule collapsed ${rule.declaration}`}
              onClick={this.uncollapse}
            >
              <div className="medium-9 columns">
                {rule.tags &&
                  rule.tags.map(tag => (
                    <div className="rule-tag" key={tag}>
                      {tag}
                    </div>
                  ))}
                {rule.gateways.map(gway => (
                  <div className="rule-tag gateway" key={gway.gateway}>
                    {gway.gateway === "processout1"
                      ? "Smart Routing (no retry)"
                      : gway.gateway === "processout"
                      ? "Smart Routing"
                      : gateway_configurations_names.gateway_configuration_names[gway.gateway]}
                  </div>
                ))}
                {(!rule.tags || rule.tags.length === 0) && rule.gateways.length === 0 && (
                  <div className="greyed">
                    This rule doesn&apos;t contain any tag yet. Click here to add one.
                  </div>
                )}
              </div>
              <div className="medium-3 columns text-right ">
                <DragHandle />
                <hr
                  style={{
                    margin: "0px",
                  }}
                />
                <div className="greyed">{position}</div>
              </div>
            </div>
          ) : rule.declaration === "block" ? (
            <RuleContainer rule={rule} collapse={this.collapse}>
              <BlockingRule rule={rule} />
            </RuleContainer>
          ) : rule.declaration === "route" ? (
            <RuleContainer rule={rule} collapse={this.collapse}>
              <RoutingRule rule={rule} />
            </RuleContainer>
          ) : rule.declaration === "dynamic_3ds" ? (
            <RuleContainer rule={rule} collapse={this.collapse}>
              <DynamicRule rule={rule} />
            </RuleContainer>
          ) : rule.declaration === "trigger_3ds" ? (
            <RuleContainer rule={rule} collapse={this.collapse}>
              <TriggerRule rule={rule} />
            </RuleContainer>
          ) : null}
        </div>
      </div>
    )
  }
}
const ConnectedRule = connect(store => ({
  gateway_configurations_names: store.gateway_configurations_names,
  rulesHaveBeenEdited: store.routingRulesSettings.rulesHaveBeenEdited,
}))(Rule)
export default SortableElement(props => <ConnectedRule {...props} />)
