import React from "react"
import Select from "react-select"
import { connect } from "react-redux"
import type { $SurfacesState } from "./reducer"
import * as Actions from "../actions"
import { REQUEST_SURFACES_FETCH } from "./consts"
import type { $Dispatcher } from "../../../util/Types"
type Props = {
  surfaces: $SurfacesState
} & $Dispatcher
type State = {
  value:
    | {
        label: string
        value: string
      }
    | null
    | undefined
}

class SurfaceSelector extends React.Component<Props, State> {
  valueChanged = (newValue: { label: string; value: string }) => {
    this.props.dispatch(Actions.selectSurfacesType(newValue.value))
    this.props.dispatch({
      type: REQUEST_SURFACES_FETCH,
      payload: {
        timespan: this.props.telescope.standard.timespan,
        type: newValue.value,
      },
    })
  }

  render() {
    const OPTIONS = [
      {
        label: "Authorization rate",
        value: "authorization-rate",
      },
      {
        label: "Chargeback rate",
        value: "chargeback-rate",
      },
    ]
    const { surfaces } = this.props
    return (
      <div className="row text-left">
        <div className="medium-4 columns medium-offset-8">
          <div
            style={{
              position: "relative",
              zIndex: 5,
              paddingRight: "1em",
            }}
          >
            <Select
              className="react-select"
              options={OPTIONS}
              isSearcheable={false}
              value={OPTIONS.find(o => o.value === surfaces.type)}
              onChange={this.valueChanged}
              isClearable={false}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  telescope: store.telescope,
  surfaces: store.surfaces,
}))(SurfaceSelector)
