import React from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router"
import ReactTooltip from "react-tooltip"
import type { $BoardDisplay, $ChartDisplay } from "../consts"
import { BAR_CHART, LINE_CHART, MAP_CHART, PIE_CHART, SINGLE_VALUE } from "../consts"
import LineChart from "../../Charts/LineChart"
import BarChart from "../../Charts/BarChart"
import SingleValue from "../../Charts/SingleValue"
import type { $Dispatcher, $State } from "../../../../util/Types"
import Error from "../../../../components/Error"
import ErrorBoundary from "../../../ErrorBoundary/index"
import Loading from "../../../../components/Loader"
import getColor from "./colors"
import Empty from "../../../../components/Empty"
import { REQUEST_CHART_DATA_FETCH, SELECT_CHART_FOR_DELETION } from "./consts"
import MapChart from "../../Charts/MapChart/MapChart"
import PieStackChart from "../../Charts/PieStackChart"
import NotPermitted from "../../../Permissions/NotPermitted"
import TextChart from "../../Charts/TextChart/TextChart"
import * as Utils from "../../Charts/Utils"
import { REQUEST_CHART_DUPLICATE } from "../Board/consts"
import { requestConfirmation } from "../../../ConfirmModal/actions"
import { track } from "../../../../vNext/tracking"

type Props = {
  chart: $ChartDisplay & $State
  board: $BoardDisplay
  project?: string | null | undefined
  preview?: boolean
  colors: Array<string>
  collapsed?: boolean
  // true for single-value will set the height to minimum. otherwise same height as other charts
  keysMask?: Array<string> | null | undefined
  // keys to be masked
  fetched?: boolean | null | undefined
  // true if the chart data is already there
  canDelete?: boolean | null | undefined
  canEdit?: boolean | null | undefined
  currentProject: {
    project: {
      default_currency: string
    }
  } & $State
  hideName: boolean | null | undefined
  loading?: boolean
} & $Dispatcher
export class Chart extends React.Component<
  Props,
  {
    colors: Array<{
      key: string
      color: string
    }>
  }
> {
  props: Props

  constructor() {
    super()
    this.state = {
      colors: [],
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    const previousChart = this.props.chart
    const nextChart = nextProps.chart
    const { keysMask } = this.props
    if (this.props.boardDetails.editing !== nextProps.boardDetails.editing) return true
    // we check if the keysmask changed
    if (keysMask !== nextProps.keysMask) return true // was null or will be null

    if (keysMask && nextProps.keysMask) {
      if (keysMask.length !== nextProps.keysMask.length) return true

      for (let i = 0; i < keysMask.length; i++) {
        if (keysMask[i] !== nextProps.keysMask[i]) return true
      }
    }

    return (
      previousChart.id !== nextChart.id ||
      (!previousChart.fetched && nextChart.fetched) ||
      this.props.loading !== nextProps.loading ||
      previousChart.type !== nextChart.type
    )
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild()
  }
  delete = (event: Event) => {
    event.preventDefault()
    const { dispatch, board, chart, params } = this.props
    dispatch({
      type: `${SELECT_CHART_FOR_DELETION}_${chart.id}`,
    })
  }
  duplicate = (event: Event) => {
    event.preventDefault()
    const { dispatch, board, chart } = this.props
    if (
      dispatch(
        requestConfirmation(
          "Do you want to duplicate this chart? This'll conveniently create an exact copy of the chart and put it in the same board.",
          () => {
            track("Analytics", "Duplicate", "Chart", "Confirm")
            dispatch({
              type: REQUEST_CHART_DUPLICATE,
              payload: {
                chart,
                board,
              },
            })
          },
          () => {
            track("Analytics", "Duplicate", "Chart", "Cancel")
          },
        ),
      )
    );
  }

  componentDidMount() {
    const { dispatch, chart, board, timeParams, fetched } = this.props
    if (chart.error) return
    if (!fetched)
      dispatch({
        type: REQUEST_CHART_DATA_FETCH,
        payload: {
          board: board.board.id,
          params: timeParams,
          chartId: chart.id,
        },
      })
    else if (!board.board) {
      const colors = Utils.generateKeysColors(chart.data.map(entry => entry.key))
      this.setState({
        colors,
      })
    }
  }

  render() {
    const { chart, board, currentProject } = this.props
    let content
    let dataEmpty = false

    if (chart.fetching || !chart.fetched || !currentProject.fetched || currentProject.fetching) {
      content = <Loading />
    } else if (chart.error) {
      if (chart.error.notPermitted) content = <NotPermitted />
      else content = <Error text="An error occured while loading your chart." />
    } else if (chart.is_comparison) {
      if (chart.data.reduce((value, item) => item.datapoints.length === 0 && value, true)) {
        dataEmpty = true
        content = <Empty text="No data available" />
      }
    } else if (Object.keys(chart.data).length === 0) {
      dataEmpty = true
      content = <Empty text="No data available" />
    }

    if (chart.fetched && chart.timeCompareAndDimensions) {
      // REMOVE THIS AND SUPPORT MULTI DIM TIME COMPARISON
      content = (
        <div className="row">
          <div
            className="large-12 columns text-center"
            style={{
              paddingTop: "5em",
            }}
          >
            <span className="greyed">
              Time comparison is not yet supported on multi dimensional charts.
            </span>
          </div>
        </div>
      )
    }

    if (chart.type === "text") {
      return (
        <div
          className="large-12 columns chart"
          style={{
            height: "100%",
          }}
        >
          <div
            className="row"
            style={{
              height: "100%",
            }}
          >
            <div
              className="large-12 columns"
              style={{
                height: "100%",
              }}
            >
              <TextChart content={chart.settings.formula} chart={chart} />
            </div>
          </div>
        </div>
      )
    }

    let displayedType = chart.type

    if (!content) {
      const colors = this.props.timeParams.timeCompare.comparing
        ? chart.data.map((entry, index) => ({
            key: entry.key,
            color: `${getColor(0)}${index === 0 ? "8C" : ""}`,
          }))
        : this.props.colors || this.state.colors

      switch (chart.type) {
        case LINE_CHART: {
          content = (
            <LineChart
              data={
                chart.is_comparison
                  ? chart.data
                  : [
                      {
                        key: "single",
                        datapoints: chart.data,
                      },
                    ]
              }
              currency={currentProject.project.default_currency}
              error={chart.error}
              comparison={chart.is_comparison}
              type={chart.unit}
              keysMask={this.props.keysMask}
              colors={colors}
              canUpdateAnalyticsFilter
            />
          )
          break
        }

        case PIE_CHART: {
          content = (
            <PieStackChart
              data={chart.data}
              currency={currentProject.project.default_currency}
              error={chart.error}
              project={currentProject.project.id}
              type={chart.unit}
              keysMask={this.props.keysMask}
            />
          )
          break
        }

        case BAR_CHART: {
          content = (
            <BarChart
              data={
                chart.is_comparison
                  ? chart.data
                  : [
                      {
                        key: "single",
                        datapoints: chart.data,
                      },
                    ]
              }
              canUpdateAnalyticsFilter
              currency={currentProject.project.default_currency}
              comparison={chart.is_comparison}
              type={chart.unit}
              format={chart.x_axis_format}
              plotted_field={chart.settings.plotted_field}
              keysMask={this.props.keysMask}
              error={chart.error}
              colors={colors}
            />
          )
          break
        }

        case SINGLE_VALUE: {
          // We check if the array contains the value
          if (
            chart.is_comparison ||
            (!this.props.location.pathname.includes("new") &&
              !this.props.location.pathname.includes("data-explorer") &&
              this.props.boardDetails.board &&
              this.props.boardDetails.board.comparison_selector) ||
            this.props.timeParams.timeCompare.comparing
          ) {
            displayedType = BAR_CHART
            content = (
              <BarChart
                canUpdateAnalyticsFilter
                data={
                  this.props.timeParams.timeCompare.comparing
                    ? chart.data // Single values were already formatted so that the Barchart component can handle it
                    : chart.data.map(
                        (
                          entry, // Comparison board were not processed
                        ) => ({
                          key: entry.key,
                          datapoints: [{ ...entry, key: "" }],
                        }),
                      )
                }
                singleValue
                currency={currentProject.project.default_currency}
                type={chart.unit}
                format={chart.x_axis_format}
                comparison
                colors={colors}
                keysMask={this.props.keysMask}
                error={chart.error}
              />
            )
          } else {
            content = (
              <SingleValue
                data={chart.data}
                value={chart.data ? chart.data[0].value : ""}
                unit={chart.unit}
                comparison={chart.is_comparison}
                error={chart.error}
                correspondingFilter={chart.data ? chart.data[0]._corresponding_filter : ""}
              />
            )
          }

          break
        }

        case MAP_CHART: {
          content = (
            <MapChart
              data={chart.data}
              currency={currentProject.project.default_currency}
              type={chart.unit}
            />
          )
          break
        }
      }
    }

    const topTitle = (
      <div
        className="row"
        style={{
          height: "2em",
          marginTop: ".5em",
        }}
      >
        <div
          className="large-7 columns"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {displayedType !== SINGLE_VALUE ? (
            <div>
              <h5
                style={{
                  display: "inline-block",
                }}
              >
                {chart.name.length > chart.size * 6
                  ? `${chart.name.slice(0, chart.size * 6)}...`
                  : chart.name}
              </h5>
              {chart.description && chart.description !== "" ? (
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "-.5em",
                  }}
                  className="info"
                  data-tip={chart.description}
                >
                  i
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="large-5 columns text-right shrink">
          {this.props.canEdit ? (
            <div className={`edit-buttons ${this.props.boardDetails.editing ? "editing" : ""}`}>
              <button
                data-auto-tracking="true"
                data-tracking-label="Duplicate chart"
                className="chart-action"
                style={{
                  marginRight: "1em",
                }}
                onClick={this.duplicate}
                title="Duplicate chart"
              >
                <img
                  alt="Duplicate icon"
                  src="/images/duplicate.png"
                  style={{
                    height: "17px",
                    opacity: 0.7,
                  }}
                />
              </button>
              <Link
                to={`projects/${this.props.project}/boards/${chart.board_id}/new-chart/chart-builder?chart=${chart.id}`}
                class="chart-action"
                style={{
                  marginRight: "1em",
                }}
                title="Edit chart in Chart Builder"
                data-auto-tracking="true"
                data-tracking-label="Edit chart in Chart Builder"
              >
                <img
                  alt="Edit icon"
                  src="/images/edit.png"
                  style={{
                    height: "17px",
                    opacity: 0.7,
                  }}
                />
              </Link>
              <Link
                to={`projects/${this.props.project}/boards/${chart.board_id}/new-chart/editor?chart=${chart.id}`}
                class="chart-action"
                style={{
                  marginRight: "1em",
                }}
                title="Edit chart in Code Editor"
                data-auto-tracking="true"
                data-tracking-label="Edit chart in Code Editor"
              >
                <img
                  alt="Code editor icon"
                  src="/images/code.png"
                  style={{
                    height: "17px",
                    opacity: 0.7,
                  }}
                />
              </Link>
              {this.props.boardDetails.editing && this.props.canDelete ? (
                <a
                  className="chart-action"
                  onClick={this.delete}
                  data-auto-tracking="true"
                  data-tracking-label="Delete chart"
                >
                  <img
                    alt="Trash icon"
                    src="/images/delete.png"
                    style={{
                      height: "17px",
                      opacity: 0.7,
                    }}
                  />
                </a>
              ) : null}
            </div>
          ) : this.props.chart.hadToTrimData ? (
            <div
              className="greyed small-font"
              data-tip="There were too many points, we trimmed data"
            >
              <img
                alt="Warning cone icon"
                src="/images/cone.png"
                style={{
                  height: "15px",
                  opacity: 0.4,
                  marginRight: "1em",
                }}
              />
              Datapoints trimmed
            </div>
          ) : null}
        </div>
      </div>
    )
    const bottomTitle =
      displayedType === SINGLE_VALUE ? (
        <div className="row">
          <div className="column text-center">
            <div
              className="legend text-center"
              style={{
                fontWeight: 500,
                fontSize: "1.1rem",
              }}
            >
              {chart.name}
            </div>
          </div>
        </div>
      ) : null
    const chartHeight =
      chart.type === MAP_CHART
        ? "400px"
        : chart.type !== SINGLE_VALUE || chart.is_comparison
        ? "200px"
        : ""
    return (
      <div className="large-12 columns chart">
        {!this.props.hideName ? topTitle : null}
        <div className="row">
          <div
            className="large-12 columns"
            style={{
              height: chartHeight,
            }}
          >
            {this.props.loading && (
              <div className="row">
                <div
                  className="large-12 columns"
                  style={{
                    width: "calc(100% - 2em)",
                    height: chartHeight,
                    position: "absolute",
                    background: "rgba(255, 255, 255, .5)",
                  }}
                >
                  <Loading />
                </div>
              </div>
            )}
            {content}
          </div>
        </div>
        {bottomTitle}
      </div>
    )
  }
}

class BoundedChart extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Chart {...this.props} />
      </ErrorBoundary>
    )
  }
}

export default connect(store => ({
  timeParams: store.analytics.params,
  currentProject: store.currentProject,
  boardDetails: store.analytics_v2.boardDetails,
}))(withRouter(BoundedChart))
